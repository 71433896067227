import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Box, Button, Grid, Typography } from "@material-ui/core"
import { useStripe } from "@stripe/react-stripe-js"
import { addCreditCardSource } from "store/user/userStripeActions"
import CreditCardForm from "./CreditCardForm"
import { StripeCardElement } from "@stripe/stripe-js"

interface AddCreditCardProps {
  handleFinishAddCreditCard: (success: boolean, errorMessage: string) => void
}

const AddCreditCard: React.FC<AddCreditCardProps> = ({ handleFinishAddCreditCard }): React.ReactElement => {
  const history = useHistory()
  const stripe = useStripe()
  const [success, setSuccess] = useState(false)

  const addCreditCard = async (stripeCardElement: StripeCardElement): Promise<string> => {
    if (!stripe) {
      console.error("Stripe null")
      return "Stripe null"
    }

    const stripeResponse = await stripe.createToken(stripeCardElement)
    if (stripeResponse.error) {
      console.log(stripeResponse.error.message)
      if (stripeResponse.error.message) {
        return stripeResponse.error.message
      }
      return "Error adding Credit Card"
    }

    try {
      await addCreditCardSource(stripeResponse.token.id)
    } catch (error) {
      console.error("Error adding Credit Card")
      return "Error adding Credit Card"
    }
    setSuccess(true)
    handleFinishAddCreditCard(true, "")
    return ""
  }

  return (
    <Box mt={2} textAlign="center" key={`add-credit-card-form`}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          {!success ? (<>
            <Typography variant="h2">Add a Credit Card</Typography>
            <CreditCardForm buttonText="Add Credit Card" successText="Card added successfully" handleCreateCardElement={addCreditCard} />
          </>) : (<>
            <Typography variant="h2">Credit Card Added Successfully</Typography>
            <Button variant="outlined" onClick={() => history.push("/ManagePaymentMethods")}>{"Back to Payment Methods"}</Button>
          </>)}
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddCreditCard
