import { TextField } from "@material-ui/core";

interface TextPrboxAreaProps {
    element: any;
    handleTextFieldChange: Function;
    selectedTextboxValue: any;
    index?: number;
    type?: string;
}

const TextboxAreaComponent: React.FC<TextPrboxAreaProps> = ({
    element,
    handleTextFieldChange,
    selectedTextboxValue,
    type, 
    index = 0
}) => {
    return (
        <TextField
            variant="outlined"
            value={selectedTextboxValue || '' }
            fullWidth
            multiline
            rows="2"
            rowsMax="2"
            required={element.required}
            id={element.key}
            name={element.key}
            label={element.title}
            inputProps={{
                autoComplete: 'off',
                tabIndex: -1,
            }}
            onChange={(event) => handleTextFieldChange(event, index, type)}
            style={{ width: '92%' }}
        />
    );
};


export default TextboxAreaComponent;