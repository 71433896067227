/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Transition, useStyles } from "lib/theme"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {
  Box,
  Button,Checkbox,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid,
  IconButton, InputAdornment, List, ListItem, ListItemIcon, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip,
  Typography,
  FormControlLabel,
  Card,
  CardContent,
  GridSize,
} from '@material-ui/core'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete'
import themis_common from 'store/themis_common_pb'
import { IAppState } from "../../../store/store"
import { selectBrandJudges, selectCurrentEventDates, selectCurrentEventFloors } from "../../../store/producer/producerSelectors"
import { format, parse } from "date-fns"
import { Clear as ClearIcon, Add as AddIcon, AccessTime as AccessTimeIcon, Delete as DeleteIcon, Edit as EditIcon,CallSplit,AddCircleOutline,RemoveCircleOutline,CloseOutlined, MergeType as MergeIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, CheckBox as CheckBoxIcon, Visibility as VisibilityIcon, Save as SaveIcon, Visibility } from "@material-ui/icons"
import { eventDivisionSortFunction } from "../../../lib/validators"
import { KeyboardTimePicker } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import {
  addPanelJudgeShift,
  addEventJudgeShifts,
  getEventJudgeShifts, deleteEventJudgeShift,
  addPanelJudgeShiftDivision,
  copyFromPreviousDay,
  deletePanelJudgeShift,
  deletePanelJudgeShiftDivision,
  editPanelJudgeShift,
  fixSchedule,
  getEventDivisionUses,
  sortPanelJudgeShiftDivision,
  getCurrentEvent,
  getEvents,
  mergeEventDivision,
  updateEventTeamName,
  sortEventDivisionShiftTeam,
  getJudgeTypeByProducerID,
  addUpdateEventJudgeShiftJudgeTypeByJudgeId,
  IAddJudgeType,
  removeSingleJudgeTypeByJudgeId,
  IDeleteJudgeType,
  deleteEventJudgeShiftByJudgeId,
  splitEventDivisions
} from "../../../store/producer/eventActions"
import { EventScheduleReportTableHeaderCell } from 'lib/constants'
import { calculateDivisionHeight, calculateOverallTimeRange, convertTo12HourFormat, filterPanelsByShifts, findMaxEventTeamsAndDivision, formatDateString, calculateMaxTeamTrCountForRow, nestedCalculateOverallTimeRange, revampedPanelWithEmptyTeamsAndDivisionsObject, revampedPanelsSplitEventDivisionIntoObject, } from 'lib/functions'
import { useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import Loader from 'components/util/Loader'
import SubmitButton from 'components/util/SubmitButton'
import { formateTime } from 'lib/functions'
import YesNoDialog from 'components/util/YesNoModal'
import { addMinutes, getMinutesDiff } from 'lib/functions'
import FloorDateSelect from 'components/util/FloorDateSelect'
import { AUTOCOMPLETEREASON } from 'lib/constants'
interface eventScheduleProps {
  producerId: number,
  brandId: number,
  eventId: number,
  eventDivisions: themis_common.EventDivision.AsObject[]
  eventDivisionUses: themis_common.EventDivisionUsage.AsObject[],
  setEventDivisionUses: Function,
  userCanViewEvent: boolean,
  userCanEditEvent: boolean,
  event: themis_common.Event.AsObject,
}
interface ISplitDivision {
  panelDivisionId:number | null
  divisionDetails:themis_common.EventDivision.AsObject;
  isOpen:boolean;
}
interface ISplitDivisions {
  divisionName:string;
  teams:number[]
}
interface SelectJudgesTypesParams {
  reason: AutocompleteChangeReason;
  details:
    | AutocompleteChangeDetails<themis_common.ProducerJudgeType.AsObject>
    | undefined;
  selectedValues: themis_common.ProducerJudgeType.AsObject[] | undefined;
  judgeId: number;
  eventJudgeShiftId: number;
}
export const initialSplitDivision: themis_common.EventDivision.AsObject = {
  id: 0,
  division: undefined,
  divisionSplit: undefined,
  event: undefined,
  eventTeamsList: [],
  divisionsList: [],
  paid: 0,
  remainingAmount: 0,
  remainingDeposit: 0,
  sortOrder: 0,
  nonCrossover: false,
  rubricId: 0,
  name: "",
  eventShiftsList: [],
  newdescription: "",
}
const initialShiftInfo:themis_common.EventFloorDateJudgingPanelShift.AsObject = {
  endTs: '',
  eventDivisionsList: [],
  eventFloorDateJudgingPanelId: 0,
  id: 0,
  judgesList: [],
  startTs: "",
  isBreak: false,
  breakDescription: ''
}
interface IBreak{
  isCheck:boolean;
  description:string;
  error:string
}
const isBreakValue = {
  isCheck:false,
  description:"",
  error:""
}

const EventSchedule: React.FC<eventScheduleProps> = ({ producerId, brandId, eventId, eventDivisions, eventDivisionUses, userCanEditEvent, userCanViewEvent, setEventDivisionUses, event }): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const eventDates = useSelector((state: IAppState) => selectCurrentEventDates(state))
  const eventFloors = useSelector((state: IAppState) => selectCurrentEventFloors(state))
  const [currentDate, setCurrentDate] = useState<themis_common.EventDate.AsObject | undefined >(undefined)
  const [currentFloor, setCurrentFloor] = useState<themis_common.EventFloor.AsObject | undefined >(undefined)
  const [currentFloorDate, setCurrentFloorDate] = useState<themis_common.EventFloorDate.AsObject | undefined >(undefined)
  const [currentPanel, setCurrentPanel] = useState<themis_common.EventFloorDateJudgingPanel.AsObject | undefined>(undefined)
  const [currentShift, setCurrentShift] = useState<themis_common.EventFloorDateJudgingPanelShift.AsObject | undefined>(undefined)
  const [currentFilter, setCurrentFilter] = useState("")  // This is the primary filter
  const [filterArray, setFilterArray] = useState<string[]>(Array<string>(eventFloors?.length || 0)) // This is the filter for each separate panel
  const [showAddShift, setShowAddShift] = useState(false)
  const [selectedStartTime, setSelectedStartTime] = useState<MaterialUiPickersDate|undefined>(undefined)
  const [selectedEndTime, setSelectedEndTime] = useState<MaterialUiPickersDate|undefined>(undefined)
  const [onlyTeams, setOnlyTeams] = useState(false)
  const [shiftEdit, setShiftEdit] = useState(false)
  const [isInPreviewMode,setIsInPreviewMode]=useState<boolean>(false)
  const [generatePublicLink,setGeneratePublicLink]=useState<string>("")
  const [eventJudgeShifts, setEventJudgeShifts] = useState<themis_common.EventJudgeShift.AsObject[]>([])
  const [eventJudgeShiftsDialogOpen, setEventJudgeShiftsDialogOpen] = useState<boolean>(false)
  const [judgesToAdd, setJudgesToAdd] = useState<themis_common.Judge.AsObject[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [getEvent,setEvent]=useState<themis_common.Event.AsObject>()
  const [currentEvent,setCurrentEvent]=useState<themis_common.Event.AsObject>()
  const [isLoading,setIsLoading]=useState<boolean>(true)
  const teamRef = useRef<any>({})
  const teamRowRef = useRef<any>({})
  const [isSplitDivision,setIsSplitDivision]= useState<ISplitDivision>({ panelDivisionId:null,isOpen:false,divisionDetails:initialSplitDivision })
  const [divisionCount,setDivisionCount]=useState(3)
  const [teamsRemain,setTeamsRemain]=useState(0)
  const [isFormSubmit,setIsFormSubmit]=useState(false)
  const [isFormValid,setIsValid]=useState({ divisionName:"",isOpen:false,isError:false })
  const [splitDivisions,setSplitDivisions]=useState<ISplitDivisions[]>([])
  const [showAddMergeDivision, setShowAddMergeDivision] = useState(false)
  const [divisionName, setDivisionName] = useState<string>("")
  const [division, setDivision] = useState<any[]>([])
  const [mergeDivisionOptions, setMergeDivisionOptions] = useState<themis_common.EventDivision.AsObject[]>([])
  const [divisionNameError, setDivisionNameError] = useState<string>('')
  const [divisionError, setDivisionError] = useState<string>('')
  const [totalDivisions, setTotalDivisions] = useState<any[]>([])
  const [selectableDivisions, setSelectableDivisions] = useState<any[]>([])
  const [selectedShift,setSelectedShift] = useState<themis_common.EventFloorDateJudgingPanelShift.AsObject>()
  const [showEventTeam, setShowEventTeam] = useState(false)
  const [editingTeamId, setEditingTeamId] = useState<number>(0)
  const [editedTeamName, setEditedTeamName] = useState<string>("")
  const [editedTeamNameError, setEditedTeamNameError] = useState<string | null>(null)
  const [isBreak,setIsBreak]=useState<IBreak>(isBreakValue)
  const [getError,setError]=useState<string | null>("")
  const [getEndTimeError,setEndTimeError]=useState<string | null>("")
  const [shiftTime, setShiftTime] = useState<number>()
  const [isAutoExistFill, setIsAutoExistFill] = useState<boolean>(false)
  const [updateDivisionToPanelData, setupdateDivisionToPanelData]=useState<any>({})
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const [splitDivisionError,setSplitDivisionError]=useState(['',''])
  const [shift,setShift]=useState<themis_common.EventFloorDateJudgingPanelShift.AsObject>(initialShiftInfo)
  const [getJudgeTypes,setJudgeTypes]=useState<themis_common.ProducerJudgeType.AsObject[]>([])
  const [maxTeamTrCountForRow, setMaxTeamTrCountForRow] = useState<any>({})

  useEffect(() => {
    if (isSplitDivision) {
      const divisionName = isSplitDivision?.divisionDetails?.name ? isSplitDivision?.divisionDetails?.name : isSplitDivision?.divisionDetails?.division?.name
      const initialDivisionNameValue = [{ divisionName:`${divisionName}#${1}` },{ divisionName:`${divisionName}#${2}` }]
      const divisions = [
        {
          divisionName: initialDivisionNameValue[0].divisionName,
          teams: [],
        },
        {
          divisionName: initialDivisionNameValue[1].divisionName,
          teams: [],
        },
      ]
      setSplitDivisions(divisions)
    }
  },[isSplitDivision])

  useEffect(() => {
    const eventProducer = event.brand?.producer
    const eventBrandId = event.brand?.id
    const isEventJudgeShiftsDialogOpen = eventJudgeShiftsDialogOpen
    if (eventProducer && isEventJudgeShiftsDialogOpen) {
      try {
        const fetchJudgeTypes =
          async (): Promise<themis_common.ProducerJudgeType> => {
            const judgeTypes: any = await getJudgeTypeByProducerID(
              eventBrandId,
              eventProducer
            )
            const producerJudgeTypesList = judgeTypes["producerJudgeTypesList"]
            setJudgeTypes(producerJudgeTypesList)
            return judgeTypes
          }
        fetchJudgeTypes()
      } catch (error) {
        console.error("Error fetching judge types:", error)
      }
    }
  }, [event, eventJudgeShiftsDialogOpen])

  const divisionUsesMap = new Map()
  const [rowHeights,setRowHeight]=useState<any>({})
  eventDivisionUses.forEach(use => {
    divisionUsesMap.set(use.eventDivisionId, use.count)
  })

  const sortedEventDivisions = useMemo(() => { // They are also filtered here
    return eventDivisions
      .filter(item => { // filter to only divisions with teams
        return onlyTeams ? item.eventTeamsList.length : true
      })
      .filter(item => {
        if (!item.division?.divisionRestriction?.maxDays) return true
        if (!divisionUsesMap.has(item.id) || divisionUsesMap.get(item.id) === undefined) return true
        return item.division.divisionRestriction.maxDays > divisionUsesMap.get(item.id)
      })
      .filter(item => { // filter out any that are already placed on this day on any floor
        return !eventFloors?.find((floor: themis_common.EventFloor.AsObject) => {
          const filteredDays = floor.daysList.filter(floorDay => {
            return floorDay.eventDateId === currentDate?.id
          })
          return filteredDays.find(day => day.judgingPanelsList.find(panel => panel.shiftsList.find(shift => shift.eventDivisionsList.find(div => div.id === item.id))))
        })
      })
      .filter((item) => { // This filters on the user input selection
        const splitFilter = currentFilter.split(" ").filter((item) => item.length > 0).map(item => item.toLowerCase())
        return splitFilter.reduce<boolean>((prev, curr) => prev && (item.division?.tier?.name.toLowerCase().includes(curr) || item.division?.name.toLowerCase().includes(curr) || false), true)
      })
      .sort(eventDivisionSortFunction)
  }, [eventDivisions, onlyTeams, divisionUsesMap, eventFloors, currentDate?.id, currentFilter])

  const onDragEnd = async (result: any) => {

    if (!result.destination) {
      return
    }

    if (result.draggableId === "filteredDivisions" && result.destination.droppableId !== "divisionList" && currentFloorDate) {
      // Filtered Divisions
      setCurrentFilter("")
      const shiftId = result.destination.droppableId.split('-').pop()
      const toIndex = result.destination.index
      let index = 0
      for (const division of sortedEventDivisions) {
        const divisionId = division.id
        const toThisIndex = toIndex + index
        if (shiftId && divisionId) {
          await addPanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId, toThisIndex)
        }
        index++
      }
    }
    else if (result.destination.droppableId === result.source.droppableId) {
      const divisionName = result.destination.droppableId.substring(0, result.destination.droppableId.indexOf('-'))
      if (divisionName === 'divisionTeams') {
        const divisionId = +result.destination.droppableId.split('-')[1]
        const parts = result.draggableId.split('-')
        const teamId = parts[1]
        const shiftId = parts[2]
        const fromIndex = result.source.index
        let toIndex = result.destination.index + 1
        if (fromIndex >= toIndex && toIndex !== 0) {
          toIndex -= 1
        }
        if (teamId && divisionId && currentFloorDate) {
          //sort Team within a division
          await sortEventDivisionShiftTeam(dispatch, currentFloorDate, +shiftId, +divisionId, +teamId, +toIndex, eventId, producerId, brandId)
          const eventdata = await getEvents(dispatch, brandId, producerId)
          const foundEvent = eventdata?.find((event: themis_common.Event.AsObject) => event.id === eventId)
          if (foundEvent) {
            const foundFloor = foundEvent.eventFloorsList?.find((floor: themis_common.EventFloor.AsObject) => currentFloor && floor.id === currentFloor.id)
            if (foundFloor) {
              const foundDay = foundFloor.daysList.find((day: themis_common.EventFloorDate.AsObject) => currentDate && day.eventDateId === currentDate.id)
              if (foundDay) {
                foundDay.judgingPanelsList.find((panel: themis_common.EventFloorDateJudgingPanel.AsObject) => {
                  const foundShift = panel.shiftsList.find((shift: themis_common.EventFloorDateJudgingPanelShift.AsObject) => selectedShift && shift.id === selectedShift.id)
                  if (foundShift) {
                    setSelectedShift(foundShift)
                    return true
                  }
                  return false
                })
              }
            }
          }
        }
      } else {
        // sort within a shift
        const divisionId = result.draggableId.split('-').pop()
        const shiftId = +result.destination.droppableId.split('-')[1]
        const fromIndex = result.source.index
        let toIndex = result.destination.index + 1
        if (fromIndex >= toIndex && toIndex !== 0) {
          toIndex -= 1
        }
        if (shiftId && divisionId && currentFloorDate) {
          await sortPanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId, +toIndex)
        }
      }
    }
    else if (result.source.droppableId === "divisionList" && result.destination.droppableId !== "divisionList" && currentFloorDate) {
      // Drag from available to shift
      const divisionId = result.draggableId.split('-').pop()
      const shiftId = result.destination.droppableId.split('-').pop()
      const toIndex = result.destination.index
      let shiftData: any = {}
      const movedDivisionData: any = sortedEventDivisions.find((item: themis_common.EventDivision.AsObject) => item.id == divisionId)
      const foundValue = currentFloor?.daysList?.find(day => day.eventDateId === currentDate?.id)
      foundValue?.judgingPanelsList?.forEach(panel => (
        panel.shiftsList.forEach(shift => {
          if (shift.id == shiftId) {
            shiftData = shift
          }
        })
      ))
      const startTSTime: string = shiftData?.startTs?.split('|')[1]
      const endTSTime: string = shiftData?.endTs?.split('|')[1]
      const allDivisionList = await findAllAvailableDivision(eventFloorDayPanels)
      let totalMinutes = 0
      let remainingTime = 0
      const existingTeams: number = allDivisionList[`${startTSTime}_${endTSTime}`] || 0
      const newDivisionTeams: number = movedDivisionData?.eventTeamsList?.length
      totalMinutes = getMinutesDiff(shiftData?.startTs.replace('|', ' '), shiftData?.endTs.replace('|', ' '))
      const maxPerformances: number = Math.floor(totalMinutes / (currentFloorDate.rotationTime / 60))
      if (allDivisionList && allDivisionList[`${startTSTime}_${endTSTime}`]) {
        if (totalMinutes) {
          remainingTime = totalMinutes - (allDivisionList[`${startTSTime}_${endTSTime}`] * (currentFloorDate.rotationTime / 60))
          if (((newDivisionTeams * (currentFloorDate.rotationTime / 60)) > remainingTime)) {
            remainingTime = Math.abs(totalMinutes - ((newDivisionTeams + allDivisionList[`${startTSTime}_${endTSTime}`]) * (currentFloorDate.rotationTime / 60)))
            setShiftTime(remainingTime)
          }
        }
        if (newDivisionTeams !== 0 && remainingTime !== 0 && shiftTime !== 0) {
          if (allDivisionList && ((existingTeams + newDivisionTeams) > maxPerformances)) {
            setIsAutoExistFill(true)
          } else {
            setIsAutoExistFill(false)
            await addPanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId, toIndex)
          }
        } else {
          setIsAutoExistFill(false)
          await addPanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId, toIndex)
        }
      } else if (allDivisionList && allDivisionList[`${startTSTime}_${endTSTime}`] == 0) {
        if ((newDivisionTeams * (currentFloorDate.rotationTime / 60)) > totalMinutes) { // initial drag team length and rotationTime time check
          remainingTime = (newDivisionTeams * (currentFloorDate.rotationTime / 60)) - totalMinutes
          setShiftTime(remainingTime)
          setIsAutoExistFill(true)
        } else {
          await addPanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId, toIndex)
        }
      }
      if (shiftId && divisionId) {
        const updatedEndTime = addMinutes(shiftData?.endTs, remainingTime)
        setupdateDivisionToPanelData({ currentFloorDate, shiftId, divisionId, toIndex, startTs: shiftData?.startTs, endTime: updatedEndTime, endTs: shiftData?.endTs, totalMinutes, remainingTime })
      }
    }
    else if (result.source.droppableId !== "divisionList" && result.destination.droppableId === "divisionList" && currentFloorDate) {
      // Drag from shift to available
      const divisionId = result.draggableId.split('-').pop()
      const shiftId = result.source.droppableId.split('-').pop()
      if (shiftId && divisionId) {
        await deletePanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId)
      }
    }
    else if (result.source.droppableId !== "divisionList" && result.destination.droppableId !== "divisionList" && currentFloorDate) {
      // Drag from shift to shift
      const divisionId = result.draggableId.split('-').pop()
      const fromShiftId = result.source.droppableId.split('-').pop()
      const toShiftId = result.destination.droppableId.split('-').pop()
      const toIndex = result.destination.index
      if (fromShiftId && toShiftId && divisionId) {
        await deletePanelJudgeShiftDivision(dispatch, currentFloorDate, +fromShiftId, +divisionId)
        await addPanelJudgeShiftDivision(dispatch, currentFloorDate, +toShiftId, +divisionId, toIndex)
      }
    }
    const foundEventDivUses = await getEventDivisionUses(eventId, brandId, producerId)
    setEventDivisionUses(foundEventDivUses)

  }

  const handleUpdateDivisionToPenal = async () => {
    const { currentFloorDate, shiftId, divisionId, toIndex, startTs, endTime, endTs, remainingTime } = updateDivisionToPanelData
    setIsAutoExistFill(false)
    if (currentFloorDate?.judgingPanelsList) {
      for (const panels of currentFloorDate.judgingPanelsList) {
        if (panels && panels.shiftsList) {
          for (const shift of panels.shiftsList) {
            if (shift.startTs === startTs && shift.endTs === endTs) {
              const isBreakTime = isBreak.isCheck ? isBreak.isCheck : false
              const breakDescription = isBreak.description ? isBreak.description : ""
              await editPanelJudgeShift(dispatch, currentFloorDate, panels, shift, startTs, endTime,isBreakTime,breakDescription)
            } else {
              // compare shift time is not before start time
              const givenTime = new Date(endTs.replace("|", "T"))
              const currentTime = new Date(shift.startTs.replace("|", "T"))
              if (givenTime <= currentTime) {
                const updatedEndTime = addMinutes(shift.endTs, remainingTime)
                const updatedStartTime = addMinutes(shift.startTs, remainingTime)
                const isBreakTime = isBreak.isCheck ? isBreak.isCheck : false
                const breakDescription = isBreak.description ? isBreak.description : ""
                await editPanelJudgeShift(dispatch, currentFloorDate, panels, shift, updatedStartTime, updatedEndTime,isBreakTime,breakDescription)
              }
            }
          }
        }
      }
    }
    await addPanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId, toIndex)
    await getEvents(dispatch, brandId, producerId)
  }

  const formateShiftTime = (date: MaterialUiPickersDate): string => {
    return date ? format(date, "HH:mm") : ""
  }

  const isShiftWithInRange = (
    shift: themis_common.EventFloorDateJudgingPanelShift.AsObject,
    newStartTime: string,
    newEndTime: string
  ) => {
    const { startTs, endTs } = shift
    const existingStartTime = formateTime(startTs)
    const existingEndTime = formateTime(endTs)
    return (
      (existingStartTime <= newStartTime && newStartTime < existingEndTime) ||
      (existingStartTime < newEndTime && newEndTime <= existingEndTime) ||
      (newStartTime <= existingStartTime && newEndTime >= existingEndTime)
    )
  }

  const areEqualTime = (newStartTime:string,newEndTime:string):boolean => {
    return newStartTime === newEndTime
  }

  const handelErrors = (hasConflict: boolean, isValidTime: boolean,areEqualTime:boolean) => {
    if (isValidTime) {
      setEndTimeError("End time must be grater than start time!")
    } else if (areEqualTime) {
      setError("Start time and end time cannot be the same! Please select different times")
      setEndTimeError(null)
    } else if (hasConflict) {
      setError("Time conflict detected within panel!")
      setEndTimeError(null)
    } else {
      setEndTimeError(null)
      setError(null)
    }
  }

  const isValidTimeSelectedEndTime = (
    newStartTime: string,
    newEndTime: string
  ): boolean => {
    return newEndTime >= newStartTime
  }

  const timeConflicts = (newStartTime: string, newEndTime: string) => {
    let hasConflict = false
    currentPanel?.shiftsList.forEach((shift) => {
      // Check for time conflicts
      if (
        shiftEdit &&
        shift !== currentShift &&
        isShiftWithInRange(shift, newStartTime, newEndTime)
      ) {
        hasConflict = true
      } else if (
        !shiftEdit &&
        isShiftWithInRange(shift, newStartTime, newEndTime)
      ) {
        hasConflict = true
      } else {
        hasConflict = false
      }
      // No time conflicts,
    })
    return hasConflict
  }

  const handleStartShiftChange = (e: MaterialUiPickersDate) => {
    const newStartTime: string = formateShiftTime(e)
    const newEndTime: string = selectedEndTime
      ? format(selectedEndTime, "HH:mm")
      : ""
    const hasConflict = timeConflicts(newStartTime, newEndTime)
    const isValid = !isValidTimeSelectedEndTime(newStartTime, newEndTime)
    const isEqualTime = areEqualTime(newStartTime,newEndTime)
    handelErrors(hasConflict, isValid,isEqualTime)
    setSelectedStartTime(e)
  }

  const handleEndShiftChange = (e: MaterialUiPickersDate) => {
    const newEndTime = formateShiftTime(e)
    const newStartTime = selectedStartTime
      ? format(selectedStartTime, "HH:mm")
      : ""
    const hasConflict = timeConflicts(newStartTime, newEndTime)
    const isValid = !isValidTimeSelectedEndTime(newStartTime, newEndTime)
    const isEqualTime = areEqualTime(newStartTime,newEndTime)
    handelErrors(hasConflict, isValid,isEqualTime)
    setSelectedEndTime(e)
  }

  const deleteShift = useCallback((shiftId: number, panelId: number) => {
    if (currentFloorDate) deletePanelJudgeShift(dispatch, currentFloorDate, shiftId, panelId)
  }, [dispatch, currentFloorDate])

  const addPanelShift = useCallback(() => {
    if (!selectedStartTime || !selectedEndTime || !currentDate?.startClockTime?.formattedDateTime || !currentFloorDate || !currentPanel ) return
    const datePart = `${currentDate.startClockTime.formattedDateTime.year}-${currentDate.startClockTime.formattedDateTime?.monthLeadingNumber}-${currentDate.startClockTime.formattedDateTime?.dayLeadingNumber}`

    const startTime = datePart + "|" + format(selectedStartTime, "HH:mm")
    const endTime = datePart + "|" + format(selectedEndTime, "HH:mm")
    setSelectedStartTime(selectedEndTime)
    const isBreakTime = isBreak.isCheck ? isBreak.isCheck : false
    const breakDescription = isBreak.description ? isBreak.description : ""

    addPanelJudgeShift(dispatch, currentFloorDate, currentPanel, startTime, endTime,isBreakTime,breakDescription)
    setShowAddShift(false)
  }, [selectedStartTime, selectedEndTime, currentDate?.startClockTime?.formattedDateTime, currentFloorDate, currentPanel, dispatch,isBreak])

  const editPanelShift = useCallback(() => {
    if (!selectedStartTime || !selectedEndTime || !currentDate?.startClockTime?.formattedDateTime || !currentFloorDate || !currentPanel || !currentShift ) return
    const datePart = `${currentDate.startClockTime.formattedDateTime.year}-${currentDate.startClockTime.formattedDateTime?.monthLeadingNumber}-${currentDate.startClockTime.formattedDateTime?.dayLeadingNumber}`

    const startTime = datePart + "|" + format(selectedStartTime, "HH:mm")
    const endTime = datePart + "|" + format(selectedEndTime, "HH:mm")
    const isBreakTime = isBreak.isCheck ? isBreak.isCheck : false
    const breakDescription = isBreak.description ? isBreak.description : ""

    editPanelJudgeShift(dispatch, currentFloorDate, currentPanel, currentShift, startTime, endTime,isBreakTime,breakDescription)

    setShowAddShift(false)
  }, [selectedStartTime, selectedEndTime, currentDate?.startClockTime?.formattedDateTime, currentFloorDate, currentPanel, dispatch,isBreak])

  const handleDivisionNameChange = (value: string) => {
    setDivisionName(value)
    if (value.trim() === '') {
      setDivisionNameError('Division Name is required')
    } else {
      setDivisionNameError('')
    }
  }

  const validateHandleChange = (division: any) => {
    if (division.length === 0) setDivisionError('Select at least one division')
    else if (division.length < 2) setDivisionError('Minimum two division required')
    else setDivisionError('')

    const selected = [...totalDivisions.filter((obj: any) => obj.id === division[0]?.id)]
    const unselected = totalDivisions.filter((totalDivision: any) =>
      !selected.some((sel: any) => sel.id === totalDivision.id)
    )

    const matchingUnselected = unselected.filter(
      (unselectedObj: any) =>
        selected.some(
          (sel: any) =>
            sel.division.divisionRestriction.allowFemaleMax === unselectedObj.division.divisionRestriction.allowFemaleMax &&
            sel.division.divisionRestriction.allowFemaleMin === unselectedObj.division.divisionRestriction.allowFemaleMin &&
            sel.division.divisionRestriction.allowMaleMax === unselectedObj.division.divisionRestriction.allowMaleMax &&
            sel.division.divisionRestriction.allowMaleMin === unselectedObj.division.divisionRestriction.allowMaleMin &&
            sel.division.divisionRestriction.allowedGender === unselectedObj.division.divisionRestriction.allowedGender &&
            sel.division.divisionRestriction.birthEnd === unselectedObj.division.divisionRestriction.birthEnd &&
            sel.division.divisionRestriction.birthStart === unselectedObj.division.divisionRestriction.birthStart &&
            sel.division.divisionRestriction.isFemaleLimited === unselectedObj.division.divisionRestriction.isFemaleLimited &&
            sel.division.divisionRestriction.isMaleLimited === unselectedObj.division.divisionRestriction.isMaleLimited &&
            sel.division.divisionRestriction.maxDays === unselectedObj.division.divisionRestriction.maxDays &&
            sel.division.divisionRestriction.maximum === unselectedObj.division.divisionRestriction.maximum &&
            sel.division.divisionRestriction.minimum === unselectedObj.division.divisionRestriction.minimum &&
            sel.division.divisionRestriction.notValidateAge === unselectedObj.division.divisionRestriction.notValidateAge
        )
    )
    setSelectableDivisions(matchingUnselected)
  }

  const mergeDivisions = useCallback(async () => {
    validateHandleChange(division)
    if (divisionName.trim() === '') {
      setDivisionNameError('Division Name is required')
    } else {
      const isDuplicate = mergeDivisionOptions.some((item: any) => {
        const name = item.name ? item.name : item.division?.name
        return name === divisionName.trim()
      })
      if (isDuplicate) {
        setDivisionNameError('Division name should be unique')
      } else {
        const divisionIdList = division?.map(div => div.id)

        //api call
        await mergeEventDivision(dispatch, divisionName, divisionIdList, eventId, producerId, brandId, currentShift ? currentShift.id : 0)

        await getEvents(dispatch, brandId, producerId)

        setShowAddMergeDivision(false)
      }
    }
  }, [divisionName, division, dispatch])

  const handleCopyPreviousDay = useCallback((prevDateId: number) => {
    if (currentFloorDate) copyFromPreviousDay(dispatch, currentFloorDate.id, prevDateId)
  }, [currentFloorDate, dispatch])


  useEffect(() => {
    if (!currentDate && eventDates?.length) {
      setCurrentDate(eventDates[0])
    }
    if (eventFloors?.length) {
      if (currentFloor) setCurrentFloor(currentFloor)
      else setCurrentFloor(eventFloors.find(floor => floor.daysList.find(day => day.eventDateId === currentDate?.id)))
    }

    setCurrentFloor(floor => {
      if (!floor && eventFloors) {
        return eventFloors[0]
      }
      if (floor && eventFloors) {
        return eventFloors.find(thisFloor => thisFloor.id === floor.id)
      }
      return floor
    })
  }, [eventDates, currentDate, eventFloors])

  useEffect(() => {
    if (!currentFloor || !currentDate) {
      setCurrentFloorDate(undefined)
    } else {
      const foundValue = currentFloor.daysList.find(day => day.eventDateId === currentDate.id)
      setCurrentFloorDate(foundValue)
    }
  }, [currentFloor?.id, currentDate?.id, eventDates,eventFloors,event]) // Do not remove eventFloors from this list

  const findAllAvailableDivision = (data: any) => {
    const totalPossibleDivision: any = {}
    data?.forEach((panel: themis_common.EventFloorDateJudgingPanel.AsObject) => {
      panel.shiftsList.forEach((shift: themis_common.EventFloorDateJudgingPanelShift.AsObject) => {
        const startTSTime = shift?.startTs?.split('|')[1]
        const endTSTime = shift?.endTs?.split('|')[1]
        shift.eventDivisionsList.forEach((division: themis_common.EventDivision.AsObject) => {
          const teamsLength = division.eventTeamsList.length
          if (totalPossibleDivision[`${startTSTime}_${endTSTime}`]) {
            totalPossibleDivision[`${startTSTime}_${endTSTime}`] += teamsLength
          } else {
            totalPossibleDivision[`${startTSTime}_${endTSTime}`] = teamsLength
          }
        })
        if (shift.eventDivisionsList.length == 0 && !totalPossibleDivision[`${startTSTime}_${endTSTime}`]) {
          totalPossibleDivision[`${startTSTime}_${endTSTime}`] = 0
        }
      })
    })
    return totalPossibleDivision
  }

  const filteredEventDates = useMemo<themis_common.EventDate.AsObject[]>(() => {
    if (!eventDates?.length || !currentFloor?.daysList?.length) return []
    const filteredDates = eventDates.filter(eventDate => currentFloor.daysList.find(day => eventDate.id === day.eventDateId && day.active))

    return filteredDates ?? []
  }, [eventDates, currentFloor?.daysList])

  const filteredEventFloors = useMemo<themis_common.EventFloor.AsObject[]>(() => {
    const filteredFloors = eventFloors?.filter((floor) => floor.daysList.find(day => day.eventDateId === currentDate?.id && day.active))

    if (!filteredFloors?.find(eventFloor => eventFloor.id === currentFloor?.id)) {
      if (filteredFloors?.length) {
        setCurrentFloor(filteredFloors[0])
      }
    }

    return filteredFloors ?? []
  }, [currentDate?.id, currentFloor?.id, eventFloors])

  useEffect(() => {
    const fetchAndUpdateCurrentEvent = async () => {
      try {
        if (event && isInPreviewMode) {
          const eventId = event?.id || null
          const eventFloorsData: { event: themis_common.Event.AsObject } | any = await getCurrentEvent(eventId)
          const updatedEvent: themis_common.Event.AsObject = eventFloorsData.event
          setCurrentEvent(updatedEvent)
          setEvent(updatedEvent)
        }
      } catch (error) {
        console.error("Error fetching and updating current event:", error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchAndUpdateCurrentEvent()
  }, [event,isInPreviewMode])

  useEffect(() => {
    if (event) {
      const originURL = window.location.origin
      const eventId = event?.id
      const urlParams = `Event/${eventId}`
      const fullURL = `${originURL}/ScheduleReport/${urlParams}`
      setGeneratePublicLink(fullURL)
    }
  }, [history, event])

  const eventFloorDayPanels = useMemo(() => {
    return currentFloor?.daysList
      .find(day => day.eventDateId === currentDate?.id && day.active)?.judgingPanelsList
      .map((panel: themis_common.EventFloorDateJudgingPanel.AsObject) => { return { ...panel, shiftsList: panel.shiftsList
        .map(shift => { return { ...shift, eventDivisionsList: shift.eventDivisionsList.sort((a, b) => {
          if (a.sortOrder < b.sortOrder) return -1
          return 1
        })
        }})
        .sort((a, b) => { if (a.startTs < b.startTs ) return -1; return 1} )
      } } ) || []
  }, [currentDate, currentFloor])  // Do not remove doRefresh from this list.

  useEffect(() => {
    if (currentShift) {
      const { breakDescription,isBreak } = currentShift
      setIsBreak((prev) => ({
        ...prev,
        description:breakDescription ? breakDescription : "",
        isCheck:isBreak,
        error:"",
      }))
    }
  },[eventFloorDayPanels,currentShift])

  const setCallScheduler = useCallback(() => {
    console.log("The button calls the schedule function", eventId, currentDate?.id, producerId, brandId)
    if (currentDate?.id && currentDate.id > 0) {
      fixSchedule(eventId, currentDate.id, producerId, brandId)
    }

  }, [])

  const handelClickIsInPreviewMode = () => {
    setIsInPreviewMode(true)
  }

  const handelCloseIsInPreviewModeClick = () => {
    setIsInPreviewMode(false)
    setMaxTeamTrCountForRow({})
  }

  const handelIsBreak = (checked: boolean) => {
    setIsBreak((prev) => ({
      ...prev,
      isCheck: checked,
      description: "",
      error: checked ? "Break Description Required*" : "Break Description Required*",
    }))
  }

  const handelBreakDescription = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target
    setIsBreak((prev) => ({ ...prev, description: value, error: value.trim() === "" && prev.isCheck ? "Break Description Required*" : "" }))
  }

  const addFloorDatePanelShiftDialog = (
    <Dialog
      open={showAddShift}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setShowAddShift(false)
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {shiftEdit ? "Edit" : "Add"} Shift
      </DialogTitle>
      <DialogContent>
        <form className={classes.dateForm} noValidate>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.gridForm}>
              <KeyboardTimePicker
                placeholder="08:00 AM"
                variant="inline"
                margin="normal"
                autoOk={true}
                id={`start_time`}
                label="Start Time"
                value={selectedStartTime}
                required={true}
                inputVariant="outlined"
                onChange={handleStartShiftChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                keyboardIcon={<AccessTimeIcon />}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      handelIsBreak(e.target.checked)
                    }}
                    checked={isBreak.isCheck}
                    disabled={shiftEdit ? currentShift?.isBreak : false}
                  />
                }
                label="Is it a break?"
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridForm}>
              <KeyboardTimePicker
                placeholder="05:00 PM"
                variant="inline"
                margin="normal"
                autoOk={true}
                id={`end_time`}
                label="End Time"
                value={selectedEndTime}
                required={true}
                inputVariant="outlined"
                onChange={handleEndShiftChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                keyboardIcon={<AccessTimeIcon />}
              />
              {isBreak.isCheck ? (
                <React.Fragment>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="description"
                    error={isBreak.isCheck && isBreak.error !== ""}
                    label="Break Description*"
                    name="description"
                    value={isBreak.description}
                    autoFocus
                    onChange={(e) => {
                      handelBreakDescription(e)
                    }}
                  />
                  <Typography style={{ color: "red" }}>
                    {isBreak.error}
                  </Typography>
                </React.Fragment>
              ) : null}
            </Grid>
          </Grid>
          {selectedStartTime && selectedEndTime && getEndTimeError ? (
            <Typography
              style={{ color: "red", marginLeft: "10px", marginTop: "10px" }}
            >
              {getEndTimeError}
            </Typography>
          ) : (
            getError && (
              <Typography
                style={{ color: "red", marginLeft: "10px", marginTop: "10px" }}
              >
                {getError}
              </Typography>
            )
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowAddShift(false),
            setError(null),
            setEndTimeError(null)
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            shiftEdit ? editPanelShift() : addPanelShift()
          }}
          color="primary"
          disabled={isBreak.isCheck && !isBreak.description.length ? true : false || !getEndTimeError?.length && !getError?.length ? false : true }
        >
          {shiftEdit ? "Edit" : "Add"} Panel Shift
        </Button>
      </DialogActions>
    </Dialog>
  )

  const mergeDivisionDialog = (

    <Dialog
      open={showAddMergeDivision}
      TransitionComponent={Transition}
      keepMounted
      onEnter={() => { validateHandleChange(division) }}
      onClose={() => { setShowAddMergeDivision(false) }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      onLoadedDataCapture={() => { validateHandleChange(division) }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogTitle id="alert-dialog-slide-title">Merge Divisions</DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => { setShowAddMergeDivision(false) }}
          aria-label="close"
          style={{ marginRight: '3%' }}
        >
          <ClearIcon />
        </IconButton>
      </div>

      <DialogContent>
        <form className={classes.dateForm} noValidate>
          <Grid container className={classes.gridForm}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="divisionName"
              label="Division Name"
              name="divisionName"
              autoComplete="divisionName"
              value={divisionName}
              onChange={(e) => handleDivisionNameChange(e.target.value)}
              autoFocus
              inputProps={{
                maxLength: 100
              }}
              error={!!divisionNameError}
              helperText={divisionNameError}
            />

            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={mergeDivisionOptions}
              disableCloseOnSelect
              getOptionLabel={(option) => (
                `${option.division?.tier?.name} - ${option.name ? option.name : option.division?.name} (${option.eventTeamsList.length})`
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              value={division}
              getOptionDisabled={(option) => division.length == 0 ? false
                : division.filter(x => x.division.id === option.division.id).length > 0 ? false
                  : selectableDivisions.length == 0 ? true
                    : selectableDivisions.filter(x => x.division.id == option.division.id).length > 0 ? false
                      : true}
              renderOption={(option: any, { selected }: { selected: any; }) => (
                <li>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={division === option ? division : selected}
                  />
                  {`${option.division?.tier?.name} - ${option.name ? option.name : option.division?.name} (${option.eventTeamsList.length})`}
                </li>
              )}
              style={{ width: 550 }}
              className={classes.programSelect}
              renderInput={(params) => (
                <TextField {...params} label="Divisions" placeholder="Divisions" />
              )}
              onChange={(event, selectedOptions) => {
                setDivision(selectedOptions)
                validateHandleChange(selectedOptions)
              }}
            />
            {divisionError && <div style={{ color: 'red' }}>{divisionError}</div>}

          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={mergeDivisions} color="primary" className={classes.submit} style={{ marginRight: "4%" }}>
          Merge
        </Button>
      </DialogActions>
    </Dialog>
  )

  const brandJudges = useSelector((state: IAppState) => selectBrandJudges(state))

  const availableJudges = useMemo(() => {
    return brandJudges.filter((bJudge: themis_common.Judge.AsObject) => !eventJudgeShifts.find((eJShift) => bJudge.id === eJShift.judgeId))
  }, [eventJudgeShifts])

  const validateTeamName = (value: string) => {
    if (!value.trim()) {
      setEditedTeamNameError('Team name is required.')
    } else if (value.length > 100) {
      setEditedTeamNameError('Team name should not exceed 100 characters.')
    } else {
      setEditedTeamNameError(null)
    }
  }

  const handleSaveTeam = async (teamId: number, updatedTeamName: string) => {
    // Prevent saving if there is a validation error
    if (editedTeamNameError) {
      return
    }

    await updateEventTeamName(dispatch, teamId, updatedTeamName, eventId, producerId, brandId)
    await getEvents(dispatch, brandId, producerId)

    if (selectedShift) {
      const updatedShift = { ...selectedShift }
      updatedShift.eventDivisionsList.forEach((division: themis_common.EventDivision.AsObject) => {
        const teamToUpdate = division.eventTeamsList.find((team: themis_common.EventTeam.AsObject) => team.id === teamId)
        if (teamToUpdate) {
          teamToUpdate.name = updatedTeamName // Update the team name
        }
      })
      // Update the state with the modified selectedShift
      setSelectedShift(updatedShift)
    }

    setEditingTeamId(0)
    setEditedTeamName('')
    setEditedTeamNameError(null)
  }

  const showEventTeamsDialog = (
    <Dialog
      open={showEventTeam}
      TransitionComponent={Transition}
      keepMounted
      onEnter={() => { validateHandleChange(division) }}
      onClose={() => { setShowEventTeam(false); setEditingTeamId(0); setEditedTeamName(''); setEditedTeamNameError(null) }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      onLoadedDataCapture={() => { validateHandleChange(division) }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '500px' }}>
        <DialogTitle id="alert-dialog-slide-title">Edit Teams</DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => { setShowEventTeam(false); setEditingTeamId(0); setEditedTeamName(''); setEditedTeamNameError(null) }}
          aria-label="close"
          style={{ marginRight: '3%' }}
        >
          <ClearIcon />
        </IconButton>
      </div>

      <DialogContent>
        <DragDropContext onDragEnd={onDragEnd}>
          {selectedShift?.eventDivisionsList?.map((division: any, index) => {
            return (
              <div key={index}>
                <Droppable droppableId={`divisionTeams-${division.id}`} key={`divisionTeams-${division.id}`}>
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={{ border: '1px solid white', padding: 10 }}>
                      {`${division?.division?.tier?.name} - ${division?.division.name ? division?.division?.name : division?.name} (${division.eventTeamsList.length})`}

                      {division?.eventTeamsList?.sort((a: any, b: any) => { return a.order.toString().localeCompare(b.order) })?.map((team: themis_common.EventTeam.AsObject, d: number) => {
                        const isCurrentlyEditing = editingTeamId === team.teamId
                        return (
                          <Draggable key={team.teamId} draggableId={`divisionTeams-${team.teamId}-${selectedShift.id}`} index={d}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={classes.dndItem}
                                style={{
                                  ...provided.draggableProps.style,
                                  marginLeft: '2%',
                                }}
                              >
                                {isCurrentlyEditing ? (
                                  <div>
                                    <TextField
                                      value={editedTeamName}
                                      onChange={(e) => { setEditedTeamName(e.target.value); validateTeamName(e.target.value) }}
                                      label="Team Name"
                                      error={!!editedTeamNameError}
                                      helperText={editedTeamNameError}
                                      style={{ width: 300 }}
                                    />
                                    <IconButton onClick={() => handleSaveTeam(team.teamId, editedTeamName)}>
                                      <SaveIcon />
                                    </IconButton>
                                    <IconButton onClick={() => { setEditingTeamId(0); setEditedTeamName('') }}>
                                      <ClearIcon />
                                    </IconButton>
                                  </div>
                                ) : (
                                  <div>
                                    {`${team.name} (${team.athletesList.length})`}
                                    <IconButton onClick={() => { setEditingTeamId(team.teamId); setEditedTeamName(team.name) }}>
                                      <EditIcon />
                                    </IconButton>
                                  </div>
                                )}
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            )
          })}
        </DragDropContext>
      </DialogContent>
    </Dialog>
  )

  const handleAddEventJudgeShifts = useCallback(async() => {
    const judges: { id: number, name: string }[] = []
    judgesToAdd.forEach((judge) => {
      judges.push({ id: judge.id, name: judge.name })
    })

    if (currentShift && currentPanel) {
      const judgeShifts = await addEventJudgeShifts(judges, currentPanel.id, currentShift.id, producerId, brandId, eventId)
      setEventJudgeShifts(judgeShifts)
    }
    setJudgesToAdd([])
  }, [judgesToAdd, currentShift, currentPanel, eventJudgeShifts, addEventJudgeShifts])

  const handleGetEventJudgeShifts = useCallback(async(panelShiftId: number) => {
    const judgeShifts = await getEventJudgeShifts(panelShiftId, producerId, brandId, eventId)
    setEventJudgeShifts(judgeShifts)
  }, [getEventJudgeShifts, eventJudgeShifts])

  const handleDeleteEventJudgeShift = useCallback(async(eventJudgeShiftId: number, panelShiftId: number) => {
    // const judgeShifts = await deleteEventJudgeShift(eventJudgeShiftId, panelShiftId, producerId, brandId, eventId)
    try {
      const judgeShifts = await deleteEventJudgeShiftByJudgeId({
        brandId,
        eventId,
        judgeId:eventJudgeShiftId,
        producerId,
        eventFloorDateJudgingPanelShiftId:panelShiftId
      })
      setEventJudgeShifts(judgeShifts)
    } catch (error) {
      console.log("error while delete event judge shift",error)
    }
  }, [deleteEventJudgeShift, eventJudgeShifts])

  const handleSelectJudgesTypes = async ({
    reason,
    details,
    selectedValues,
    judgeId,
    eventJudgeShiftId,
  }: SelectJudgesTypesParams): Promise<void> => {
    try {
      const eventProducerId = event.brand?.producer?.id
      const eventId = event?.id ?? null
      const brandId = event?.brand?.id ?? null
      const lastRemovedTypeId = details?.option.id || ""
      const panelShiftId = currentShift?.id ?? null

      const createAddJudgeTypePayload = (judgeTypeId: number): IAddJudgeType => ({
        judgeId,
        producerId: eventProducerId as number,
        eventJudgeShiftId,
        judgeTypeId,
      })

      let lastPayload: IAddJudgeType | {} = {};
      (selectedValues ?? []).forEach((judgeType) => {
        const typeId = judgeType.id
        lastPayload = createAddJudgeTypePayload(typeId)
      })

      const removePayload: IDeleteJudgeType = {
        judgeId,
        eventId,
        brandId,
        producerId: eventProducerId,
        judgeTypeId: +lastRemovedTypeId,
      }

      switch (reason) {
        case AUTOCOMPLETEREASON.SELECT:
          await addUpdateEventJudgeShiftJudgeTypeByJudgeId(
            lastPayload as IAddJudgeType
          )
          const judgeShifts = await getEventJudgeShifts(
            panelShiftId as number,
            eventProducerId as number,
            brandId as number,
            eventId as number
          )
          setEventJudgeShifts(judgeShifts)
          break
        case AUTOCOMPLETEREASON.REMOVE:
          await removeSingleJudgeTypeByJudgeId(removePayload)
          const updatedJudgeShifts = await getEventJudgeShifts(
            panelShiftId as number,
            eventProducerId as number,
            brandId as number,
            eventId as number
          )
          setEventJudgeShifts(updatedJudgeShifts)
          break
      }
    } catch (error) {
      console.error("Error while adding judge type", error)
    }
  }

  useEffect(() => {
    if (getEvent) {
      getEvent.eventFloorsList.forEach((floor) => {
        floor.daysList.forEach((days) => {
          days.judgingPanelsList.forEach((panel) => {
            panel.shiftsList
          })
        })
      })
    }
  },[getEvent])

  useEffect(() => {
    const fetchDivisionHeight = () => {
      setIsLoading(true)
      if (
        teamRef.current !== undefined ||
        isInPreviewMode,
        teamRowRef !== undefined ||
        getEvent !== undefined
      ) {
        const divisionHeight = calculateDivisionHeight(teamRef, getEvent)
        const TeamTrCountForRow = calculateMaxTeamTrCountForRow(teamRowRef, getEvent)
        setMaxTeamTrCountForRow(TeamTrCountForRow)
        setRowHeight(divisionHeight)
      }
      setIsLoading(false)
    }
    fetchDivisionHeight()
  }, [teamRef,isInPreviewMode, teamRowRef, getEvent])

  // Split Division Section

  const handelSplitPanelDivisionDialogState = (
    isOpen: boolean,
    panelDivisionId: number | null,
    divisionDetails: themis_common.EventDivision.AsObject
  ) => {
    if (isOpen) {
      setIsSplitDivision({
        ...isSplitDivision,
        isOpen,
        panelDivisionId,
        divisionDetails,
      })
    } else {
      setIsSplitDivision({
        ...isSplitDivision,
        isOpen,
        panelDivisionId,
        divisionDetails: initialSplitDivision,
      })
    }
  }

  const handelNewSplitDivision = (
    splitDivisions: ISplitDivisions[],
  ) => {
    const divisionName = isSplitDivision?.divisionDetails?.name ? isSplitDivision?.divisionDetails?.name : isSplitDivision?.divisionDetails?.division?.name
    const newDivisionName = `${divisionName}#${divisionCount}`
    setDivisionCount(divisionCount + 1)
    const updateDivision = [
      ...splitDivisions,
      { divisionName: newDivisionName, teams: [] },
    ]
    setSplitDivisions(updateDivision)
  }

  const handelRemoveNewSplitDivision = (
    splitDivisions: ISplitDivisions[],
    divisionIndex: number
  ) => {
    const updatedDivision = [...splitDivisions]
    updatedDivision.splice(divisionIndex, 1)
    setSplitDivisions(updatedDivision)
  }

  const handelDivisionNameChange = (
    divisionIndex: number,
    divisionName: string
  ) => {
    const updatedDivision = [...splitDivisions]
    updatedDivision[divisionIndex].divisionName = divisionName
    const checkDuplicateDivisionName = () => {
      const splitDivisionNames = splitDivisions.map((division) =>
        division?.divisionName?.toLowerCase()
      )
      const sortedDivisionNames = sortedEventDivisions.map((divisionInfo) =>
        divisionInfo?.division?.name?.toLowerCase()
      )
      const duplicateInSplitDivisions =
        new Set(splitDivisionNames).size !== splitDivisionNames.length
      const existingSortedDivisionName = splitDivisionNames.some((name) =>
        sortedDivisionNames.includes(name?.toLowerCase())
      )
      return {
        duplicateInSplitDivisions,
        existingSortedDivisionName,
      }
    }
    const { duplicateInSplitDivisions, existingSortedDivisionName } =
      checkDuplicateDivisionName()
    const divisionErrorMessages = [...splitDivisionError]
    const isEmptyDivisionName = divisionName.trim() === ""
    if (isEmptyDivisionName) {
      divisionErrorMessages[divisionIndex] = "Division name is required*"
    } else if (!isEmptyDivisionName) {
      if (duplicateInSplitDivisions) {
        divisionErrorMessages[divisionIndex] = "Division name already exists"
      } else if (existingSortedDivisionName) {
        divisionErrorMessages[divisionIndex] = "Division name already exists"
      } else {
        divisionErrorMessages[divisionIndex] = ""
      }
    } else {
      divisionErrorMessages[divisionIndex] = ""
    }
    setSplitDivisions(updatedDivision)
    setSplitDivisionError(divisionErrorMessages)
  }


  const isTeamIdSelectedInOtherDivisions = (
    divisionIndex: number | string,
    teamId: string | number
  ) => {
    return splitDivisions.some(
      (division, index) =>
        index !== divisionIndex && division.teams.includes(teamId as number)
    )
  }

  useEffect(() => {
    if (isSplitDivision) {
      const isSelectedAllTeamsInsideDivisions = () => {
        const totalTeamsLength =
          isSplitDivision.divisionDetails.eventTeamsList.length
        const totalSelectedTeams = splitDivisions.reduce(
          (total, division) => total + division.teams.length,
          0
        )
        if (splitDivisions.map((division) => division.teams.length)) {
          setTeamsRemain(totalTeamsLength - totalSelectedTeams)
        }
        const requiredTotalTeams = totalTeamsLength
        return totalSelectedTeams === requiredTotalTeams
      }
      isSelectedAllTeamsInsideDivisions()
    }
  }, [isSplitDivision, splitDivisions])

  useEffect(() => {
    if (isFormValid) {
      handelSplitPanelDivisionDialogState(false, null, initialSplitDivision)
      setSplitDivisions(splitDivisions)
      setSplitDivisionError(["", ""])
      setDivisionCount(3)
      setTeamsRemain(0)
      setIsFormSubmit(false)
    }
  },[isFormValid])

  const handleCheckboxChange = (
    divisionIndex: number | string,
    teamId: number
  ) => {
    const updatedDivisions = [...splitDivisions]
    updatedDivisions.forEach((division, index) => {
      if (index !== divisionIndex) {
        // Remove teamId from other divisions
        division.teams = division.teams.filter(
          (team) => team !== (teamId as unknown as number)
        )
      } else {
        // Toggle team selection in the current division
        const teamIndex = division.teams.indexOf(teamId as unknown as number)
        if (teamIndex !== -1) {
          division.teams.splice(teamIndex, 1)
        } else {
          division.teams.push(teamId as unknown as number)
        }
      }
      // Disable checkboxes for selected teams in other divisions
      division.teams.forEach((team, teamIndex) => {
        if (
          divisionIndex !== index &&
          isTeamIdSelectedInOtherDivisions(team, index)
        ) {
          division.teams[teamIndex] = null as unknown as number // Set team to null to disable the checkbox
        }
      })
      // Filter out disabled teams (null values)
      division.teams = division.teams.filter((team) => team !== null)
    })
    setSplitDivisions(updatedDivisions)
  }

  const submitForm: (syntheticEvent: FormEvent) => Promise<void> = async (syntheticEvent) => {
    syntheticEvent.preventDefault()
    setIsFormSubmit(!isFormSubmit)
    if (splitDivisions.length > 0) {
      const eventDivisions = splitDivisions.map(
        ({ divisionName, teams }) => ({
          eventTeamId: teams,
          name: divisionName,
        })
      )
      const shiftId = shift.id
      const rubricId = isSplitDivision.divisionDetails.rubricId
      const eventDivisionId = isSplitDivision.divisionDetails.id
      const divisionId = isSplitDivision?.divisionDetails?.division?.id || null
      const payload = {
        eventDivisions: eventDivisions,
        producerId: producerId,
        brandId: brandId,
        eventId: eventId,
        divisionId: divisionId,
        eventDivisionId: eventDivisionId,
        rubricId: rubricId,
        nonCrossover: false,
        shiftId: shiftId,
      }
      const hasDivisionError = splitDivisionError.some((error) => error)
      const divisionName: string | undefined =
        isSplitDivision?.divisionDetails?.division?.name
      if (!teamsRemain && !hasDivisionError) {
        try {
          const splitDivision = await splitEventDivisions(payload)
          splitDivision(dispatch)
          setIsValid(() => ({
            ...isFormValid,
            divisionName: divisionName as string,
            isOpen: true,
            isError: false,
          }))
        } catch (error) {
          setIsValid(() => ({
            ...isFormValid,
            divisionName: divisionName as string,
            isOpen: true,
            isError: true,
          }))
          console.log("error", error)
          // Handle error (e.g., show a notification to the user)
        }
      }
    } else {
      console.warn("No divisions to submit.") // Handle this case according to your requirements
    }
  }

  const showSelectedTeams = (divisionIndex: number) => {
    const { eventTeamsList } = isSplitDivision.divisionDetails
    const selectedTeams = eventTeamsList
      .filter((info) =>
        splitDivisions[divisionIndex].teams
          .map((teamInfo) => teamInfo)
          .includes(info.id)
      )
      .map((team) => team.programName)
    return (
      <Typography>
        {!selectedTeams.length ? "No Team Selected" : selectedTeams.join(" , ")}
      </Typography>
    )
  }

  const splitEventDivisionErrorDialog = useMemo(() => {
    const handelCloseErrorDialog = () => {
      setIsValid(() => ({ ...isFormValid,divisionName:"",isOpen:false,isError:false }))
    }
    return (
      <YesNoDialog
        title={`${isFormValid.isError ? 'Error Splitting Division' : 'Successfully Splitting Division'}`}
        question={`${isFormValid.isError ? `There was an error splitting a division. Please contact us if this continues to be a problem.` : `Event Division Successfully Split: ${isSplitDivision?.divisionDetails?.division?.name}`}`}
        isOpen={isFormValid.isOpen}
        onClose={handelCloseErrorDialog}
        buttonActions={[
          { name: "Okay", color: "primary", callback: handelCloseErrorDialog },
        ]}
      />
    )
  }, [isFormValid])

  const eventJudgeShiftsDialog = useMemo(() => {
    return (
      <Dialog
        open={eventJudgeShiftsDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setEventJudgeShiftsDialogOpen(false)
          setJudgesToAdd([])
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
        style={{ overflowY: "scroll", height: "600px" }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          Panel Shift Judges
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <>
              <Grid container item xs={12} sm={12} md={6}>
                <Autocomplete
                  value={judgesToAdd}
                  multiple
                  id="add-multiple-judges"
                  limitTags={1}
                  options={availableJudges}
                  disableCloseOnSelect
                  getOptionLabel={(judge: themis_common.Judge.AsObject) =>
                    (judge.name || "No Name Found") +
                    " " +
                    (judge.person?.nickName
                      ? " (" + judge.person?.nickName + ")"
                      : "")
                  }
                  style={{ width: 300 }}
                  renderOption={(judge, { selected }) => (
                    <React.Fragment>
                      <Checkbox checked={selected} size="small" />
                      {judge.person?.name}
                    </React.Fragment>
                  )}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Judges to Add"
                    />
                  )}
                  onChange={(e, v) => {
                    if (setJudgesToAdd) setJudgesToAdd(v)
                  }}
                />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    handleAddEventJudgeShifts()
                  }}
                  disabled={!judgesToAdd.length}
                  color="primary"
                  style={{ marginLeft: "5px", maxHeight: "42px" }}
                >
                  Add
                </Button>
                {eventJudgeShifts.map((judgeShift) => {
                  return (
                    <Grid item xs={12} key={judgeShift.judgeId}>
                      <List dense={true}>
                        <ListItem>
                          <ListItemIcon>
                            <IconButton
                              size="small"
                              onClick={() => {
                                if (currentShift) {
                                  handleDeleteEventJudgeShift(
                                    judgeShift.judgeId,
                                    currentShift.id
                                  )
                                }
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemIcon>
                          {judgeShift.judgeName}
                        </ListItem>
                      </List>
                    </Grid>
                  )
                })}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography style={{ marginBottom: "30px", marginLeft: "10px" }}>
                  Judge Types
                </Typography>
                {eventJudgeShifts?.length && eventJudgeShifts.map((judgeShift, index) => {
                  const originalJudgeTypes = judgeShift.judgeTypeList.map(
                    (type) => type.producerJudgeType
                  )
                  const judgeType = originalJudgeTypes.length
                    ? originalJudgeTypes
                    : []
                  return (
                    <Grid
                      item
                      xs={12}
                      key={`judge_id${index}`}
                      style={{ padding: "14px" }}
                    >
                      <Autocomplete
                        multiple
                        id={`judge-types-${judgeShift.id}`}
                        limitTags={1}
                        value={judgeType}
                        options={getJudgeTypes}
                        disableCloseOnSelect
                        disableClearable
                        getOptionSelected={(option, value) =>
                          (option?.id ?? null) === (value?.id ?? null)
                        }
                        style={{ width: 300 }}
                        getOptionLabel={(option) => (option ? option?.name : "")}
                        renderOption={(
                          option: any,
                          { selected }: { selected: any }
                        ) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              size="small"
                              style={{ marginRight: 8 }}
                              checked={
                                judgeType === option ? judgeType : selected
                              }
                            />
                            {option ? option?.name : ""}
                          </React.Fragment>
                        )}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Select Judge Types"
                          />
                        )}
                        onChange={(event, selectedValues, reason, details) => {
                          if (currentShift) {
                            const judgeId = judgeShift.judgeId
                            const eventJudgeShiftId = judgeShift?.id
                            const detailsObject = details || null
                            const selectedValuesArray = selectedValues || []
                            handleSelectJudgesTypes({
                              reason,
                              details:
                                detailsObject as AutocompleteChangeDetails<themis_common.ProducerJudgeType.AsObject>,
                              selectedValues:
                                selectedValuesArray as themis_common.ProducerJudgeType.AsObject[],
                              judgeId,
                              eventJudgeShiftId,
                            })
                          }
                        }}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEventJudgeShiftsDialogOpen(false)
              setEventJudgeShifts([])
              setJudgesToAdd([])
            }}
            color="primary"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [
    eventJudgeShiftsDialogOpen,
    judgesToAdd,
    brandJudges,
    eventJudgeShifts,
    getJudgeTypes,
  ])

  const splitEventDivisionDialog = useMemo(() => {
    const handelCloseButtonClick = () => {
      handelSplitPanelDivisionDialogState(false, null, {
        id: 0,
        eventTeamsList: [],
        divisionsList: [],
        paid: 0,
        remainingAmount: 0,
        remainingDeposit: 0,
        sortOrder: 0,
        nonCrossover: false,
        rubricId: 0,
        name: "",
        eventShiftsList: [],
        newdescription:""
      })
      setSplitDivisions(splitDivisions)
      setSplitDivisionError(["", ""])
      setDivisionCount(3)
      setTeamsRemain(0)
      setIsFormSubmit(false)
      setShift(initialShiftInfo)
    }
    return (
      <form className={classes.form}>
        <Dialog
          open={isSplitDivision.isOpen}
          TransitionComponent={Transition}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="md"
          style={{ overflowY: "scroll", height: "600px" }}
        >
          <Grid container justify="flex-end">
            <IconButton
              size="small"
              style={{ marginTop: "10px" }}
              onClick={handelCloseButtonClick}
            >
              <CloseOutlined style={{ marginRight: "3px" }} />
            </IconButton>
          </Grid>
          <DialogContent>
            <form>
              <Grid container justify="center">
                <Typography>Split Division</Typography>
              </Grid>
              {splitDivisions.map((divisionInfo, divisionIndex: number) => {
                const { eventTeamsList } = isSplitDivision.divisionDetails
                const selectedTeams = eventTeamsList
                  .filter((info) =>
                    splitDivisions[divisionIndex].teams
                      .map((teamInfo) => teamInfo)
                      .includes(info.id)
                  )
                  .map((team) => team.programName)
                return (
                  <div key={`split_division${divisionIndex}`}>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                      <Grid item xs={4} spacing={2}>
                        <TextField
                          label="Division Name"
                          variant="outlined"
                          error={splitDivisionError[divisionIndex] ? true : false}
                          placeholder={`Division Name ${divisionIndex + 1}`}
                          name="divisionName"
                          value={divisionInfo.divisionName}
                          onChange={(e) => {
                            handelDivisionNameChange(
                              divisionIndex,
                              e.target.value
                            )
                          }}
                        />
                        <Typography style={{ display: "flex", color: "red" }}>
                          {splitDivisionError[divisionIndex]}
                        </Typography>
                        <Grid item xs={10}>
                          <Typography
                            style={{ marginTop: "5px", fontSize: "15px" }}
                          >
                            Selected Teams: {selectedTeams.length}
                          </Typography>
                          <Card
                            className={classes.divider}
                            style={{ border: "1px solid" }}
                          >
                            <CardContent
                              style={{ height: "61px", overflowY: "scroll" }}
                            >
                              {showSelectedTeams(divisionIndex)}
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Card
                          className={classes.divider}
                          style={{ border: "1px solid" }}
                        >
                          <CardContent
                            style={{ height: "150px", overflowY: "scroll" }}
                          >
                            {isSplitDivision?.divisionDetails?.eventTeamsList?.map(
                              (teamInfo, teamIndex) => {
                                const isSelected = splitDivisions[
                                  divisionIndex
                                ].teams.includes(
                                  teamInfo.id as unknown as number
                                )
                                return (
                                  <FormControlLabel
                                    key={`isCheckTeam_${teamIndex}`}
                                    style={{ display: "flex" }}
                                    disabled={isTeamIdSelectedInOtherDivisions(
                                      divisionIndex,
                                      teamInfo?.id
                                    )}
                                    name={teamInfo?.programName}
                                    control={
                                      <Checkbox
                                        checked={isSelected}
                                        onChange={(e) => {
                                          handleCheckboxChange(
                                            divisionIndex,
                                            teamInfo?.id
                                          )
                                        }}
                                      />
                                    }
                                    label={teamInfo?.programName}
                                  />
                                )
                              }
                            )}
                          </CardContent>
                        </Card>
                        {isFormSubmit &&
                        !splitDivisions[divisionIndex].teams.length ? (
                            <Typography style={{ color: "red" }}>
                            Please select at least one team in this division
                            </Typography>
                          ) : null}
                      </Grid>
                      <Grid item xs={2}>
                        {isSplitDivision.divisionDetails.eventTeamsList.length !==
                        2 ? (
                            (splitDivisions.length > 2 &&
                            divisionIndex < splitDivisions.length - 1) ||
                          isSplitDivision.divisionDetails.eventTeamsList
                            .length === splitDivisions.length ? (
                                <IconButton
                                  size="small"
                                  style={{ marginTop: "10px" }}
                                  onClick={() => {
                                    handelRemoveNewSplitDivision(
                                      splitDivisions,
                                      divisionIndex
                                    )
                                  }}
                                >
                                  <RemoveCircleOutline />
                                </IconButton>
                              ) : (
                                divisionIndex === splitDivisions.length - 1 &&
                            isSplitDivision.divisionDetails.eventTeamsList
                              .length !== splitDivisions.length && (
                                  <IconButton
                                    size="small"
                                    style={{ marginTop: "10px" }}
                                    onClick={() => {
                                      handelNewSplitDivision(splitDivisions)
                                    }}
                                  >
                                    <AddCircleOutline />
                                  </IconButton>
                                )
                              )
                          ) : null}
                      </Grid>
                    </Grid>
                  </div>
                )
              })}
            </form>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={11}>
                {isFormSubmit && teamsRemain ? (
                  <Typography
                    style={{
                      color: "red",
                      marginTop: "28px",
                      marginLeft: "15px",
                    }}
                  >
                    {`${teamsRemain} ${teamsRemain === 1 ? `team`: `teams`} remaining. Please select all division`}
                    teams
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={1}>
                <SubmitButton
                  submitForm={submitForm}
                  title={`Split`}
                  ariaLabel="submit-event-form"
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </form>
    )
  }, [isSplitDivision?.isOpen, splitDivisions, submitForm,isFormValid])

  const dragDropStuff = useMemo(() => {
    return <DragDropContext onDragEnd={onDragEnd}>
      <Grid item container xs={2} alignContent='flex-start'>
        <Typography variant="h2" align='center' style={{ width: '100%' }}>Available Divisions</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={"Filter Divisions"}
          id={`Filter Divisions`}
          value={currentFilter}
          name={"FilterDivisions"}
          onChange={(e) => {
            setCurrentFilter(e.target.value)
          }}
          InputProps={{
            endAdornment: (<InputAdornment position="end"><ClearIcon style={{ cursor: "pointer" }} onClick={() => { setCurrentFilter("") }} /> </InputAdornment>)
          }}
        />
        <Droppable droppableId="divisionList">
          {(provided, snapshot) => (
            <Grid container item xs={12} ref={provided.innerRef} {...provided.droppableProps} style={{ border: '1px solid white', padding: 10 }} >
              {
                sortedEventDivisions.length > 0 && currentFilter.length > 0 ?
                  <Draggable key={"filteredDivisions"} draggableId={"filteredDivisions"} index={-1}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={classes.dndItem}>
                        {"[All Filtered Divisions]"}
                      </div>
                    )}
                  </Draggable>
                  : []
              }
              { sortedEventDivisions.map((div, i) =>
                <Draggable key={"eventDivision-" + div.id} draggableId={"eventDivision-Unused-" + div.id} index={i}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={classes.dndItem}>
                      {`${div.division?.tier?.name} - ${div.name ? div.name : div.division?.name} (${div.eventTeamsList.length})`}
                    </div>
                  )}
                </Draggable>
              )}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </Grid>
      <Grid item container xs={10} spacing={1}>
        { eventFloorDayPanels.map((panel, i) => <Grid key={`panel-${i}`} item container xs={6} sm={4} lg={3} xl={2} alignContent='flex-start'>
          <Grid container>
            <Grid item xs={2} />
            <Grid item xs={8} >
              <Typography variant="h2" align='center' style={{ width: '100%' }}>{panel.name}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Add Shift"><IconButton onClick={() => { setSelectedStartTime(undefined); setShiftEdit(false); setCurrentPanel(panel); setShowAddShift(true),setIsBreak(isBreakValue) }}><AddIcon fontSize="small" /></IconButton></Tooltip>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={"Filter Divisions"}
            id={`Filter_Divisions_${panel.id}`}
            value={filterArray[i]}
            name={`Filter_Divisions_${panel.id}`}
            onChange={(e) => {
              setCurrentFilter(e.target.value)
            }}
            InputProps={{
              endAdornment: (<InputAdornment position="end"><ClearIcon style={{ cursor: "pointer" }} onClick={() => { setCurrentFilter("") }} /> </InputAdornment>)
            }}
          />
          <Grid container spacing={1}>
            { panel.shiftsList.map((shift: themis_common.EventFloorDateJudgingPanelShift.AsObject) => {
              const baseStartTime = parse(shift.startTs.split('|')[1], "HH:mm", new Date())
              const startTime = format(baseStartTime, "hh:mm a")
              const baseEndTime = parse(shift.endTs.split('|')[1], "HH:mm", new Date())
              const endTime = format(baseEndTime, "hh:mm a")
              return <Grid key={`shift-${shift.id}`} item xs={12}>
                <Typography>{startTime} - {endTime}
                  <IconButton onClick={() => { deleteShift(shift.id, panel.id) }}><DeleteIcon/></IconButton>
                  <IconButton onClick={() => { setSelectedStartTime(baseStartTime); setSelectedEndTime(baseEndTime); setCurrentPanel(panel); setShiftEdit(true); setCurrentShift(shift); setShowAddShift(true) }}><EditIcon/></IconButton>
                  <Tooltip title="Edit Teams"><IconButton onClick={() => { setSelectedShift(shift); setShowEventTeam(true) }}><VisibilityIcon/></IconButton></Tooltip>
                  <Button size="small" variant="outlined" onClick={() => {setEventJudgeShiftsDialogOpen(true); setCurrentPanel(panel); setCurrentShift(shift); handleGetEventJudgeShifts(shift.id)}}><EditIcon fontSize="small" />&nbsp;Judges</Button>
                </Typography>
                <Typography style={{ fontSize:"1rem" }}>{shift && shift.breakDescription ? shift.breakDescription : null}</Typography>
                <Droppable droppableId={`panelShift-${shift.id}`} key={`panelShift-${shift.id}`}>
                  {(provided, snapshot) => (
                    <Grid container item xs={12} ref={provided.innerRef} {...provided.droppableProps}
                      style={{ border: '1px solid white', padding: 10 }}>
                      { shift.eventDivisionsList
                        .filter(item => { // filter to only divisions with teams
                          return onlyTeams ? item.eventTeamsList.length : true
                        })
                        .map((div: themis_common.EventDivision.AsObject, d: number) => (
                          <Draggable key={"eventDivision-" + div.id} draggableId={`eventDivision-${shift.id}-${div.id}`} index={d}>
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={classes.dndItem}>
                                <Typography style={{ fontSize:"1rem" }}>
                                  {`${div.division?.tier?.name} - ${div.newdescription ? div.newdescription : div.name ? div.name : div.division?.name} (${div.eventTeamsList.length})`}
                                </Typography>
                                {!event?.registrationLive && div.eventTeamsList.length > 1 ?
                                  <Tooltip title="Split Division">
                                    <IconButton onClick={() => { handelSplitPanelDivisionDialogState(true, div.id, div),setShift({ ...shift }) } }><CallSplit  fontSize='small'/></IconButton>
                                  </Tooltip>
                                  : null}
                                {shift.eventDivisionsList.length >= 2 && <Tooltip title="Merge Division"><IconButton onClick={() => {
                                  setDivisionName(div.name || (div.division && div.division.name) || ''); setDivision([]); setShowAddMergeDivision(true); setDivisionError(''); setDivisionNameError(''); setMergeDivisionOptions(shift.eventDivisionsList); setDivision([div])
                                  setTotalDivisions(() => {
                                    const newDataArray = eventDivisions.filter((eventDiv) =>
                                      shift.eventDivisionsList.find((div: any) => div.id === eventDiv.id)
                                    )
                                    return newDataArray
                                  })
                                  setCurrentShift(shift)
                                }}><MergeIcon fontSize="small" /></IconButton></Tooltip>}
                              </div>
                            )}
                          </Draggable>
                        )
                        )}
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </Grid>
            }
            )}
          </Grid>
        </Grid>
        )}
        <Grid>
          <Button onClick={() => setCallScheduler()} variant="outlined">
            Check Day&apos;s Schedule
          </Button>
        </Grid>
      </Grid>
    </DragDropContext>

  }, [classes.dndItem, currentFilter, deleteShift, eventFloorDayPanels, filterArray, onDragEnd, onlyTeams, sortedEventDivisions])

  const EventScheduleReport = () => {
    {
      /* Header Information */
    }
    const headerSection = (
      floors: themis_common.EventFloor.AsObject,
      days: themis_common.EventFloorDate.AsObject
    ) => {
      const overallTimeRange = days?.judgingPanelsList?.map((panel) => {
        return calculateOverallTimeRange(panel.shiftsList)
      })
      const timesRange = overallTimeRange.filter(
        (time, index) =>
          time !== null && overallTimeRange?.indexOf(time) === index
      )
      const time = nestedCalculateOverallTimeRange(timesRange)
      const logo =  currentEvent ? (currentEvent?.logo || currentEvent?.brand?.logo || currentEvent?.brand?.producer?.logo) : "/themislogo.png"
      return (
        <Grid
          container
          key={`header_${days.id}`}
          style={{
            background: "black",
            color: "white",
            height: "90px",
            alignItems: "center",
            width: "99.7%",
            marginLeft: "2px",
          }}
        >
          {/* Event Information */}
          <Grid item xs={4}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <img
                key="eventLogo"
                src={logo}
                className={classes.previewEventScheduleReportHeaderLogo}
              />
              <Typography style={{ marginLeft: "20px" }}>
                {currentEvent?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            {/* Current Floor Dates */}
            <Typography style={{ display: "flex", justifyContent: "center" }}>
              {/* Additional Info 1 */}
              {formatDateString(days?.startDayTime)}
            </Typography>
          </Grid>
          {/* Current Floor Name Shift OverRollTime */}
          <Grid item xs={4}>
            <Typography style={{ display: "flex", justifyContent: "center" }}>
              {floors?.name} -{" "}
              {time?.map((time, index) => {
                return (
                  <React.Fragment key={`timing-${index}`}>
                    {time.start} - {time.end}
                  </React.Fragment>
                )
              })}
            </Typography>
          </Grid>
        </Grid>
      )
    }

    {
      /* Panel Division Teams  */
    }
    const panelDivisionTeams = (
      panelIndex: number,
      division: themis_common.EventDivision.AsObject,
      shiftIndex: number,
      splitPanelsByEventDivision:themis_common.EventFloorDateJudgingPanel.AsObject[]
    ) => {
      const values: any = Object.values(maxTeamTrCountForRow)[shiftIndex]
      const noOfTeamAdd = values?.length ? values[panelIndex] : []
      // Create a new empty object with the same structure as other objects in eventTeamsList
      const emptyTeamObject = {
        programName: "",
        name: "",
        warmUpTime: "",
        performanceTime: "",
        // Add other properties if needed
      }

      // Add the empty object to eventTeamsList
      const updatedEventTeamsList = [
        ...((division?.eventTeamsList as any) || []),
        ...Array.from({ length: noOfTeamAdd }, () => emptyTeamObject),
      ]

      return (
        <TableContainer component={Paper}>
          <Table aria-label="teams table">
            <TableBody>
              {updatedEventTeamsList?.map((team, teamIndex) => {
                const teamsWarmUpTime = team?.warmUpTime
                const warmUpTime = teamsWarmUpTime
                  ? convertTo12HourFormat(teamsWarmUpTime)
                  : ""
                const performanceTime = team.performanceTime
                  ? convertTo12HourFormat(team?.performanceTime)
                  : ""
                const teamName = `${
                  team?.programName ? team.programName : ""
                } ${team?.programName ? " - " : "---"}${
                  team?.name ? team.name : "---"
                }`
                const renderNotExistingContent = "---"
                return (
                  <TableRow key={teamIndex}>
                    <TableCell
                      style={{
                        fontSize: "12.5px",
                        width: splitPanelsByEventDivision.length ===3 ? "203px" : "62%",
                        height: "11vh",
                        wordWrap: "break-word",
                      }}
                    >
                      {teamName}
                    </TableCell>
                    <TableCell style={{ fontSize: "12.5px" }}>
                      {warmUpTime}{" "}
                      {!warmUpTime.length && renderNotExistingContent}
                    </TableCell>
                    <TableCell style={{ fontSize: "12.5px" }}>
                      {performanceTime}{" "}
                      {!performanceTime.length && renderNotExistingContent}
                    </TableCell>
                    {/* Add additional table cells for other team information */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    {
      /* Panel & Division & Teams Header  */
    }

    const renderPanelHeader = (splitPanelsByEventDivision:themis_common.EventFloorDateJudgingPanel.AsObject[]) => {
      let widthPercentage = ""
      switch (splitPanelsByEventDivision.length) {
        case (3):
          widthPercentage = "51%"
          break
        case (2):
          widthPercentage = "62%"
          break
        case (1):
          widthPercentage = "62%"
          break
        default:
          // Handle default case here if needed
          break
      }

      return (
        <TableContainer component={Paper}>
          <Table aria-label="teams table">
            <TableHead style={{ border: "3px solid" }}>
              <TableRow>
                {EventScheduleReportTableHeaderCell.map((cell, index) => (
                  <TableCell
                    key={`cell_${index}`}
                    style={{
                      fontWeight: 900,
                      width: cell.id === 1 ? widthPercentage : "",
                      fontSize: "12.5px",
                      lineHeight: "18px",
                    }}
                  >
                    {cell.name}
                  </TableCell>
                ))}
                {/* Add additional table header cells if needed */}
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )
    }

    {
      /* Panel & Division  */
    }
    const panelDivisions = (
      judgingPanelsList: themis_common.EventFloorDateJudgingPanel.AsObject[]
    ) => {
      const filteredPanels = filterPanelsByShifts(judgingPanelsList)

      const maxTeamsInfo = findMaxEventTeamsAndDivision(filteredPanels)

      const panelsWithEmptyDivisions =
        revampedPanelWithEmptyTeamsAndDivisionsObject(
          filteredPanels,
          maxTeamsInfo?.maxDivInShift,
          maxTeamsInfo?.maxTeamsLengthPerStartTime
        )

      const splitPanelsByEventDivision = revampedPanelsSplitEventDivisionIntoObject(
        panelsWithEmptyDivisions
      )
      let gridItem: GridSize
      switch (splitPanelsByEventDivision?.length) {
        case 1:
          gridItem = 12
          break
        case 2:
          gridItem = 6
          break
        default:
          gridItem = 4
          break
      }
      return (
        <Grid
          style={{
            flexGrow: 1,
            marginTop: "15px",
            marginBottom: "10px",
          }}
          container
          spacing={2}
        >
          {splitPanelsByEventDivision?.map((panel, panelIndex) => (
            <React.Fragment key={panelIndex}>
              <Grid key={`floor_panel${panelIndex}`} item xs={gridItem}>
                {renderPanelHeader(splitPanelsByEventDivision)}
                {panel && Object.values(panel).length
                  ? panel.shiftsList.map((shift, shiftIndex) => {
                    const rowIndex =
                      shiftIndex % (panelsWithEmptyDivisions?.length ?? 1)
                    const modifyPanelIndex: number = panelIndex % 3
                    return (
                      <React.Fragment key={`panel_shift${shiftIndex}`}>
                        <div
                          ref={(ref) => {
                            const dynamicKey = `${shiftIndex}`
                            teamRef.current[dynamicKey] = ref
                            teamRowRef.current[dynamicKey] = ref
                          }}
                          className="content-div"
                          style={{
                            border: "1px solid",
                          }}
                          key={`panel_shift${shift.id}`} // Adding a unique key to the div
                        >
                          <div
                            style={{
                              height:
                              rowHeights[`row${shiftIndex || rowIndex}`] ?? "auto",
                            }}
                          >
                            {shift && Object.values(shift)?.length
                              ? shift.eventDivisionsList
                                ?.filter(
                                  (division) => division.eventTeamsList?.length > 0
                                )
                                .map((division, divisionIndex) => {
                                  return (
                                    <React.Fragment key={`divisions_${division.id}`}>
                                      <Typography
                                        style={{
                                          background: "grey",
                                          color: "white",
                                          padding: "10px",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {`${division?.division?.tier?.name} - ${
                                          division?.name
                                            ? division?.name
                                            : division?.division?.name
                                        } (${
                                          (division?.eventTeamsList ?? []).filter(
                                            (obj) => Object.keys(obj).length !== 0
                                          ).length
                                        })`}
                                      </Typography>
                                      {!division?.eventTeamsList?.length ? (
                                        <Typography
                                          style={{
                                            marginBottom: "10px",
                                            marginTop: "5px",
                                          }}
                                        >
                                          No Teams Found!
                                        </Typography>
                                      ) : (
                                        panelDivisionTeams(
                                          modifyPanelIndex,
                                          division,
                                          shiftIndex,
                                          splitPanelsByEventDivision
                                        )
                                      )}
                                    </React.Fragment>
                                  )
                                })
                              : null}
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })
                  : null}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )
    }


    const isExistingShiftList = getEvent?.eventFloorsList
      .flatMap((floors) => floors.daysList)
      .flatMap((days) => days.judgingPanelsList)
      .some((panel) =>
        panel.shiftsList.some((shift) =>
          shift.eventDivisionsList.some(
            (division) => division.eventTeamsList.length > 0
          )
        )
      )

    return (
      <React.Fragment>
        {isLoading|| getEvent === undefined ? (
          <Grid container justify="center">
            <Loader height="3%" width="3%" />{" "}
            <Typography style={{ marginLeft: "20px", marginTop: "3px" }}>
              Loading Schedule Report....
            </Typography>
          </Grid>
        ) : !isExistingShiftList ? (
          <Grid container justify="center">
            <Typography variant="h2">No Schedule Found!</Typography>
          </Grid>
        ) : (
          getEvent?.eventFloorsList.map((floors, floorIndex) => (
            <React.Fragment key={floorIndex}>
              {floors.daysList.map((days, daysIndex) => {
                const isDivisionPanelOrShiftExisting =
                  days?.judgingPanelsList?.some((panel) =>
                    panel?.shiftsList?.some(
                      (shift) => shift?.eventDivisionsList?.length
                    )
                  )
                return (
                  <React.Fragment key={floors.id}>
                    {isDivisionPanelOrShiftExisting ? (
                      <React.Fragment key={days.id}>
                        {headerSection(floors, days)}
                        {panelDivisions(days.judgingPanelsList)}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          ))
        )}
        {/* Event Panels */}
      </React.Fragment>
    )
  }

  const PreviewEventScheduleReport = () => {
    const copyToClipboard = () => {
      try {
        const path = generatePublicLink
        navigator.clipboard.writeText(path)
        return true
      } catch (error) {
        console.log("error", error)
        return false
      }
    }
    const handelPublicLinkGenerate = () => {
      const isCopyLink = copyToClipboard()
      if (isCopyLink) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }
    const isExistingShiftList = getEvent?.eventFloorsList
      .flatMap((floors) => floors.daysList)
      .flatMap((days) => days.judgingPanelsList)
      .some((panel) =>
        panel.shiftsList.some((shift) =>
          shift.eventDivisionsList.some(
            (division) => division.eventTeamsList.length > 0
          )
        )
      )
    return (
      <Dialog
        open={isInPreviewMode}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="lg"
        style={{ overflowY: "scroll", height: "650px" }}
      >
        <Grid container justify="flex-end">
          {isExistingShiftList ? (
            <Grid item style={{ margin: "-49px", marginTop: "0px" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  margin: "10px",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
                disabled={isLoading}
                onClick={handelPublicLinkGenerate}
              >
                Share
              </Button>
              <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={() => setOpen(false)}
                style={{ position: "absolute", top: "-87%", left: "78%" }}
              >
                <Alert
                  severity="success"
                  style={{
                    height: "4%",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  Successfully Link Copy!
                </Alert>
              </Snackbar>
            </Grid>
          ) : null}
          <Grid item xs={1}>
            <IconButton
              size="small"
              style={{ marginTop: "10px", float: "right" }}
              onClick={handelCloseIsInPreviewModeClick}
            >
              <CloseOutlined style={{ marginRight: "3px" }} />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent style={{ marginBottom: "25px" }}>
          {EventScheduleReport()}
        </DialogContent>
      </Dialog>
    )
  }

  return !userCanViewEvent || !currentFloorDate || !eventFloorDayPanels ? (
    <></>
  ) : (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant="h2">Schedule</Typography>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Preview">
          <IconButton
            style={{ height: "40px" }}
            onClick={handelClickIsInPreviewMode}
          >
            <Visibility />
          </IconButton>
        </Tooltip>
      </Grid>
      <FloorDateSelect
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        filteredEventDates={filteredEventDates}
        currentFloor={currentFloor}
        setCurrentFloor={setCurrentFloor}
        filteredEventFloors={filteredEventFloors}
        onlyTeams={onlyTeams}
        setOnlyTeams={setOnlyTeams}
        handleCopyPreviousDay={handleCopyPreviousDay}
        event={event}
        userCanEditEvent={userCanEditEvent}
        currentFloorDate={currentFloorDate}
        setCurrentFloorDate={setCurrentFloorDate}
      />

      <Grid container item xs={12} spacing={1}>
        {dragDropStuff}
      </Grid>
      {addFloorDatePanelShiftDialog}
      {eventJudgeShiftsDialog}
      {splitEventDivisionDialog}
      {splitEventDivisionErrorDialog}
      {mergeDivisionDialog}
      { showEventTeamsDialog }
      {PreviewEventScheduleReport()}
      <YesNoDialog
        title={""}
        question={`The division you are attempting to add will exceed the allotted time of the judging panel shift by ${shiftTime} minutes, would you like to increase the length of this shift to continue?`}
        isOpen={isAutoExistFill}
        onClose={() => setIsAutoExistFill(false)}
        buttonActions={[
          {
            name: "Continue",
            color: "primary",
            callback: () => {
              handleUpdateDivisionToPenal()
            },
          },
          {
            name: "Cancel",
            color: "secondary",
            callback: () => {
              setIsAutoExistFill(false)
            },
          },
        ]}
      />
    </Grid>)
}

export default EventSchedule
