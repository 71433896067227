import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Paper, TextField, Typography } from '@material-ui/core'
import { isValidEmail } from "lib/validators"
import { AsFields, emailFields, passwordStrengthOptions } from "lib/constants"
import { useStyles } from "lib/theme"
import YesNoDialog from "components/util/YesNoModal"
import { passwordStrength } from 'check-password-strength'
import PasswordFields from 'components/util/PasswordFields'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { getCountryDateFormat } from 'lib/functions'
import { isValid, formatISO, format, addYears } from 'date-fns'
import { eula } from "lib/eula"
import HelpIcon from 'components/util/HelpIcon'


interface RegisterProps {
  registration: any
  setRegistration: React.Dispatch<React.SetStateAction<AsFields>>
  setErrorText:React.Dispatch<React.SetStateAction<{[key: string]: string}>>
  errorText: {[key: string]: string}
  setFormReady: React.Dispatch<React.SetStateAction<boolean>>
  validUniqueEmail: boolean
  checkRegister: boolean
  emailAlreadyExists: boolean
  setCheckRegister: React.Dispatch<React.SetStateAction<boolean>>
  dialogOpen: boolean
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  submitForm: any
}


const Register: React.FC<RegisterProps> = ({
  registration, setRegistration, setErrorText, errorText, setFormReady, validUniqueEmail,
  checkRegister, emailAlreadyExists, setCheckRegister, dialogOpen, setDialogOpen, submitForm
}): ReactElement => {

  const classes = useStyles()

  const requiredFields = useMemo(() => {
    return {
      firstName: true,
      lastName: true,
      email: true,
      birthDate: true,
      password: true,
      password2: true
    }
  }, [])

  const passwordFields = useMemo(() => {
    return {
      password: true,
      password2: true
    }
  }, [])


  const [passwordMatch, setPasswordMatch] = useState(true)
  const [passwordStrongEnough, setPasswordStrongEnough] = useState<boolean>(false)
  const [alertSeverity, setAlertSeverity] = useState<'error'| 'info' | 'success' | 'warning' | undefined>('warning')
  const [passwordStrengthText, setPasswordStrengthText] = useState<string>("")
  const [didNotAgreeError, setDidNotAgreeError] = useState(false)

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [dateIsValid, setDateIsValid] = useState<boolean>(false)
  const [overThirteen, setOverThirteen] = useState<boolean>(false)
  const [countryDateFormat, setCountryDateFormat] = useState<string>("MM/dd/yyyy")


  const passStrength: string = useMemo(() => { return passwordStrength(registration.password, passwordStrengthOptions).value }, [registration.password])

  const handleInputChange = (e: any) => {
    const { name, value, checked } = e.target

    if (name === "agreedToTerms") {
      if (checked) {
        setRegistration({ ...registration, agreedToTerms: true })
      } else {
        setRegistration({ ...registration, agreedToTerms: false })
      }
    } else {
      setRegistration({ ...registration, [name]: value })
    }

    if (!value) {
      setErrorText({ ...errorText, [name]: `${name.charAt(0).toUpperCase() + name.slice(1).replace(/([^0-9])([0-9])/g, '$1 $2').replace(/([A-Z])/g, ' $1').trim()} cannot be empty` })
    } else {
      setErrorText({ ...errorText, [name]: "" })
    }
  }

  const handleDateChange = (date?: Date | null): void => {
    handleInputChange({ target: { name: "birthDate", value: date }, requiredFields, registration, setRegistration, errorText, setErrorText })
    if (date) {
      setSelectedDate(date)
      if (!isValid(date)) {
        setErrorText({ ...errorText, birthDate: `Must be a valid date in ${countryDateFormat} format` })
        setDateIsValid(false)
        setOverThirteen(false)
      } else {
        const thirteenYearsAgo = format(addYears(new Date(), -13), "yyyy-MM-dd")
        if (format(date, "yyyy-MM-dd") > thirteenYearsAgo) {
          setErrorText({ ...errorText, birthDate: "You must be 13 or older to register" })
          setDateIsValid(true)
          setOverThirteen(false)
        } else {
          setRegistration({ ...registration, birthDate: formatISO(date) })
          setErrorText({ ...errorText, birthDate: "" })
          setDateIsValid(true)
          setOverThirteen(true)
        }
      }
    }
  }

  useEffect(() => {
    setCountryDateFormat(getCountryDateFormat(new Date()))
  }, [])

  useEffect(() => {
    setFormReady(
      registration.firstName && registration.lastName && validUniqueEmail  && registration.agreedToTerms &&
      passwordMatch && registration.password2.length >= 3 && passwordStrongEnough && dateIsValid && overThirteen
    )
    // errorText should not be included (causes infinite rerender)
  }, [passwordMatch, validUniqueEmail, passwordStrongEnough, setFormReady, registration, dateIsValid, overThirteen])


  // Check fields when user tries to submit
  useEffect(() => {
    if (checkRegister) {
      const errors:{[key: string]: string} = {}

      Object.keys(requiredFields).forEach((key) => {
        if (registration[key] === "") errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1).replace(/([^0-9])([0-9])/g, '$1 $2').replace(/([A-Z])/g, ' $1').trim()} cannot be empty`
      })
      Object.keys(emailFields).forEach((key) => {
        if (emailAlreadyExists) errors[key] = "There is already an account with that email"
        if (registration.email.length >= 1 && !isValidEmail(registration.email)) errors[key] = "Invalid email"
      })
      Object.keys(passwordFields).forEach((key) => {
        if (!passwordMatch) errors[key] = "Passwords do not match"
      })

      if (!passwordStrongEnough) setAlertSeverity("error")

      if (!registration.agreedToTerms) {
        setDidNotAgreeError(true)
      } else {
        setDidNotAgreeError(false)
      }

      if (registration.birthDate) {
        if (!dateIsValid) {
          errors.birthDate = `Must be a valid date in ${countryDateFormat} format`
        } else if (dateIsValid && !overThirteen) {
          errors.birthDate = "You must be 13 or older to register"
        }
      } else {
        errors.birthDate = "Birth date cannot be empty"
      }

      setErrorText(errors)
      setCheckRegister(false)
    }
  }, [checkRegister, requiredFields, registration, passwordMatch, passwordFields, emailAlreadyExists, passwordStrongEnough, setCheckRegister, setErrorText, overThirteen, dateIsValid, countryDateFormat])


  return (
    <Container>
      <CssBaseline/>
      <Grid container>
        <Grid item xs={1}/>
        <Grid item xs={10}>
          <Typography variant="h1">Register</Typography>
          <Typography>Registration is available for Program Owners. Once the program owner registers they can invite a program manager who will be able to manage this system on their behalf.  Program owners and program managers can create locations (gyms), create teams, and invite and assign coaches.  Program owners and program managers can manage all teams in their program and sign up for any events.</Typography>
        </Grid>
      </Grid>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <form className={classes.form} noValidate onSubmit={submitForm}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              error={!!errorText.firstName}
              helperText={errorText.firstName || ""}
              fullWidth
              id="fname"
              label="First Name"
              name="firstName"
              autoComplete="firstName"
              onChange={(e) => {// @ts-ignore
                handleInputChange(e, requiredFields, registration, setRegistration, errorText, setErrorText)}}
              autoFocus
              inputProps={{ autoComplete: "off" }}
              onInput = {(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.slice(0,100)
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              error={!!errorText.lastName}
              helperText={errorText.lastName || ""}
              fullWidth
              id="lname"
              label="Last Name"
              name="lastName"
              autoComplete="lastName"
              onChange={(e) => {// @ts-ignore
                handleInputChange(e, requiredFields, registration, setRegistration, errorText, setErrorText)}}
              inputProps={{ autoComplete: "off" }}
              onInput = {(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.slice(0,100)
              }}
            />
            {/* Utils for snapshot test to run */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={countryDateFormat}
                margin="normal"
                autoOk={true}
                id="birthDate"
                label="Birth Date"
                value={selectedDate}
                name="birthDate"
                required
                error={!!errorText.birthDate}
                helperText={errorText.birthDate || ""}
                inputVariant="outlined"
                onChange={(e) => { handleDateChange(e)}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                fullWidth
              />
            </MuiPickersUtilsProvider>
            <Box position="relative">
              <TextField
                variant="outlined"
                margin="normal"
                required
                error={!!errorText.email}
                helperText={errorText.email || ""}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                inputProps={{ autoComplete: "off" }}
                onChange={(e) => {// @ts-ignore
                  handleInputChange(e, requiredFields, registration, setRegistration, errorText, setErrorText)}}
                onInput = {(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.slice(0,100)
                }}
              />
              <Box position="absolute" top={15} right={0} zIndex="modal">
                <HelpIcon
                  markdownText={`If someone has added you as a user already, use the email address they added`}
                  boxSize="small"
                  iconSize="small"
                />
              </Box>
            </Box>
            <PasswordFields
              errorText={errorText}
              handleInputChange={handleInputChange}
              fields={{ ...requiredFields, password: true, password2: true }}
              password={registration.password}
              registration={registration}
              setObject={setRegistration}
              setErrorText={setErrorText}
              passStrength={passStrength}
              password2={registration.password2}
              setAlertSeverity={setAlertSeverity}
              setPasswordMatch={setPasswordMatch}
              setPasswordStrongEnough={setPasswordStrongEnough}
              setPasswordStrengthText={setPasswordStrengthText}
              passwordMatch={passwordMatch}
              passwordStrongEnough={passwordStrongEnough}
              passwordStrengthText={passwordStrengthText}
            />
            <Paper style={{ background: "#ffffff", color: "black" }} className={classes.eula}>
              <div dangerouslySetInnerHTML={{ __html: eula.replace(/(<? *script)/gi, 'illegalscript') }} >
              </div>
            </Paper>
            <FormControlLabel
              className={classes.fullWidth}
              control={
                <Checkbox
                  checked={registration.agreedToTerms}
                  onChange={handleInputChange}
                  name="agreedToTerms"
                  color="primary"
                />
              }
              label="I have read and accept the terms of the End User License Agreement (EULA)."
            />
            <Typography variant={"subtitle1"} style={{ color: "#ff1744", fontWeight: "bold", fontSize: ".75rem", margin: 5 }} hidden={!didNotAgreeError}>You must agree to the terms</Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Register
            </Button>
          </form>
        </div>
      </Container>
      <YesNoDialog
        title={"Registration Error"}
        question={`There was an error creating your account. Please contact us if this continues to be a problem.`}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setDialogOpen(false) },
        ]}
      />
    </Container>
  )
}

export default Register
