import React from "react";
import { Grid } from "@material-ui/core";

const NotFoundPage: React.FC = () => {
  return (
    <React.Fragment>
      <Grid container justify="center" style={{ marginTop: "80px" }}>
        <h1 style={{ fontSize: "191px" }}>404</h1>
        <h1 style={{ marginLeft: "79px", marginTop: "252px" }}>
          We couldn't find!
        </h1>
      </Grid>
    </React.Fragment>
  );
};

export default NotFoundPage;