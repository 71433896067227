import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Transition, useStyles } from "lib/theme"
import { Link } from "react-router-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { IAppState } from "store/store"
import { selectCurrentEventFloors } from "store/producer/producerSelectors"
import { addEventFloor, deleteEventFloor, sortEventFloor } from "store/producer/eventActions"
import EventFloorDates from "./EventFloorDates"
import themis_common from 'store/themis_common_pb'

interface eventFloorsProps {
  producerId: number,
  brandId: number,
  eventId: number,
  userCanViewEvent: boolean,
  userCanEditEvent: boolean,
}

const EventFloors: React.FC<eventFloorsProps> = ({ producerId, brandId, eventId, userCanEditEvent, userCanViewEvent }): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [addFloorDialogOpen, setAddFloorDialogOpen] = useState(false)
  const [newEventFloorName, setNewEventFloorName] = useState('')

  const eventFloorsOriginal = useSelector((state: IAppState) => selectCurrentEventFloors(state))
  const [eventFloors, setEventFloors] = useState<themis_common.EventFloor.AsObject[] | undefined>(eventFloorsOriginal)

  useEffect(() => {
    setEventFloors(eventFloorsOriginal) // This fires the useEffect below to show the floors
  }, [setEventFloors, eventFloorsOriginal])

  const addEventFloorIcon = useMemo(() => {
    if (userCanEditEvent) {
      return (
        <IconButton className={classes.clickable} aria-label="addFloor" onClick={_e => {setAddFloorDialogOpen(true)}}>
          <AddIcon fontSize="small"/>
        </IconButton>
      )
    } else {
      return []
    }
  }, [classes.clickable, userCanEditEvent])

  const handleDeleteFloor = async (eventFloorId: number) => {
    await deleteEventFloor(dispatch, eventFloorId, producerId, Number(brandId), Number(eventId))
  }

  // useEffect(() => {
  //   const handleSortFloors = async (eventFloors: number[]) => {
  //     await sortEventFloor(dispatch, eventFloors, producerId, Number(brandId), Number(eventId))
  //   }
  //
  //   if (eventFloors && eventFloors.length && producerId > 0 && Number(brandId) > 0 && Number(eventId) > 0) {
  //     const floorIds = eventFloors.map((floor) => floor.id)
  //     handleSortFloors(floorIds)
  //   }
  // }, [dispatch, brandId, eventId, producerId])

  const handleCloseAddFloorDialog = () => {
    setAddFloorDialogOpen(false)
  }

  function onDragEnd(result: any) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const newEventFloors = reorderFloors(
      eventFloors ? eventFloors : [],
      result.source.index,
      result.destination.index
    )

    setEventFloors(newEventFloors)
  }

  const handleAddFloor = async () => {
    await addEventFloor(dispatch, Number(eventId), Number(brandId), producerId, newEventFloorName)
    setAddFloorDialogOpen(false)
  }

  const addFloorDialog = (
    <Dialog
      open={addFloorDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseAddFloorDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Add Floor"}</DialogTitle>
      <DialogContent>
        <form className={classes.dateForm} noValidate onSubmit={handleAddFloor}>
          <TextField
            id="name"
            label="Name"
            type="string"
            required
            defaultValue={newEventFloorName}
            className={classes.dateField}
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              setNewEventFloorName(e.target.value)
            }}
            onInput = {(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.slice(0,50)
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAddFloorDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddFloor} color="primary">
          Add Floor
        </Button>
      </DialogActions>
    </Dialog>
  )

  const reorderFloors = (list: themis_common.EventFloor.AsObject[], startIndex: number, endIndex: number): themis_common.EventFloor.AsObject[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  interface FloorItemObject {floor: themis_common.EventFloor.AsObject, index: number}

  const FloorItem = useCallback(({ floor, index }: FloorItemObject) => {
    return (
      <Draggable draggableId={floor.id+''} index={index} key={floor.id+''}>
        {(provided: any) => (
          <ListItem
            className={classes.tightListItem}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className={classes.grouper}>
              <Grid container>
                <Grid item xs={12}>
                  <ListItemText>
                    <Typography variant="h2">{floor.name}</Typography>
                  </ListItemText>
                  { userCanEditEvent ?
                    (<ListItemIcon aria-label="delete" style={{ padding: '4px' }} onClick={_e => {handleDeleteFloor(floor.id)}}>
                      <DeleteIcon fontSize="small" style={{ cursor: 'pointer' }}/>
                    </ListItemIcon>)
                    : []
                  }
                </Grid>
                <Grid item xs={12}>
                  <EventFloorDates eventId={eventId} brandId={brandId} eventFloorId={floor.id} producerId={producerId}  userCanViewEvent={userCanViewEvent} userCanEditEvent={userCanEditEvent} floor={floor} setEventFloors={setEventFloors} />
                </Grid>
              </Grid>
            </div>
          </ListItem>
        )}
      </Draggable>
    )
  }, [brandId, classes.grouper, classes.tightListItem, eventId, handleDeleteFloor, producerId, userCanEditEvent, userCanViewEvent])

  const DoFloorList = useCallback(() => {
    if (!eventFloors || eventFloors.length === 0) {
      return (<></>)
    }
    return <>
      {eventFloors.map((floor: themis_common.EventFloor.AsObject, index: number) => (
        <Grid key={'floor-'+floor.id} item xs={12} >
          <FloorItem floor={floor} index={index} key={floor.id} />
        </Grid>
      ))}
    </>
  }, [FloorItem, eventFloors])


  return !userCanViewEvent ? <></> : (
    <>
      <Typography variant="h2">
        Floors
        {addEventFloorIcon}
      </Typography>
      <Container maxWidth="xl">
        <Grid container>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {provided => (
                <Grid container item xs={12} ref={provided.innerRef} {...provided.droppableProps}>
                  {DoFloorList()}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </Container>
      {addFloorDialog}
    </>)
}

export default EventFloors
