// Common Imports
import React, { ReactElement, useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from 'lib/theme'
import { FormValues } from 'lib/constants'

// Components Imports
import LabelComponent from '../rubricComponents/Label'
import DropdownComponent from '../rubricComponents/Dropdown'
import SwitchComponent from '../rubricComponents/Switch'
import TextboxAreaComponent from '../rubricComponents/Textboxarea'
import DropdownRangeComponent from '../rubricComponents/DropdownRange'
import TextboxComponent from '../rubricComponents/Textbox'

interface TemplateOneProps {
  mode: string;
  templateData: any;
  responseData: any;
  initialValues?: any;
  judgeType?: string[];
}

const TemplateOne: React.FC<TemplateOneProps> = ({
  mode,
  templateData,
  responseData,
  initialValues,
  judgeType
}): ReactElement => {
  const classes = useStyles()
  const [objectName, setObjectName] = useState<string>(templateData.objectName)
  const [initialData, setInitialData] = useState<any>({})
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<any>(null)
  const [errorText, setErrorText] = useState<string>('')
  const [isComment, setIsComment] = useState<boolean>(mode == 'Edit' ? initialValues?.[objectName]?.['isComment'] : true)
  const [selectedTextboxValue, setSelectedTextboxValue] = useState<string>("")
  const [isdropdownRangeTextValue, setIsDropdownRangeTextValue] = useState<any>()
  const [response, setResponse] = useState<any>({
    [objectName]: {
      isComment: true
    }
  })
  let defaultDropdownRange: any

  useEffect(() => {
    responseData(response)
  }, [response])

  useEffect(() => {
    if (mode == 'Edit') {
      setInitialData(initialValues?.[objectName])
      setIsComment(initialValues?.[objectName]?.['isComment'])
      setSelectedTextboxValue(initialValues?.[objectName]?.['comment'])
      // Set defaultTextboxRange value to the state
      const defaultTextboxRangeValue = initialValues?.[objectName]?.[`${objectName}Value`]
      setIsDropdownRangeTextValue(defaultTextboxRangeValue)

      setResponse({
        [objectName]: initialValues?.[objectName]
      })
    }
  }, [initialValues, objectName, mode])

  useEffect(() => {
    if (defaultDropdownRange) {
      setSelectedDropdownValue(defaultDropdownRange)
    }
  }, [defaultDropdownRange])

  // handles dropdown change event
  const handleDropdownChange = (
    value: any,
    element: any
  ) => {
    const updatedFormValues: any = { ...response }

    // Ensure response[objectName] is initialized
    updatedFormValues[objectName] = updatedFormValues[objectName] || {}
    updatedFormValues[objectName][element.key] = value.value
    setResponse(updatedFormValues)
  }

  // handles switch change event
  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target
    const updatedFormValues: any = { ...response }
    updatedFormValues[objectName][name] = checked
    setResponse(updatedFormValues)
  }

  // handles textfield change event
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setSelectedTextboxValue(value)
    const updatedFormValues: any = { ...response }
    updatedFormValues[objectName][name] = value
    setResponse(updatedFormValues)
  }

  // Validate textbox value which falls selected dropdown range or not
  const validateTextBoxRange = (selectedRange: any, enteredValue: number) => {
    if (selectedRange) {
      if (
        enteredValue < selectedRange.min ||
        enteredValue > selectedRange.max ||
        enteredValue !== parseFloat(enteredValue.toFixed(2))
      ) {
        setErrorText(`Value should be between ${selectedRange.min} and ${selectedRange.max}`)
        return true
      }

      return false
    }
  }

  // handles dropdownRange change event
  const handleDropdownRangeChange = (
    value: any,
    element: any
  ) => {
    const isInvalid = validateTextBoxRange(value, parseFloat(isdropdownRangeTextValue))
    if (!isInvalid) {
      setErrorText('')
    }
    const updatedFormValues: any = { ...response }
    updatedFormValues[objectName][element.key] = value.value
    setResponse(updatedFormValues)
  }

  // handles textboxRange change event
  const handleTextFieldRangeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    const isInvalid = validateTextBoxRange(selectedDropdownValue, parseFloat(value))
    if (!isInvalid) {
      setErrorText('')
    }
    setIsDropdownRangeTextValue(value)
    const updatedFormValues: any = { ...response }
    updatedFormValues[objectName][name] = value
    setResponse(updatedFormValues)
  }

  const renderForm = () => {
    return Object.values(templateData?.data?.rows).map((row: any, index: number) => {
      return (
        <Grid container key={`row_${index}`}>
          {row.map((value: any, key: number) => {
            if (value.visible) {
              switch (value.type) {
                case FormValues.STATIC_LABEL:
                  return (
                    <Grid item xs={12} sm={6} md={1} key={`row_${index}_col_${key}`}>
                      <LabelComponent title={value.title} />
                    </Grid>
                  )

                case FormValues.DROPDOWN:
                  return (
                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                      <DropdownComponent
                        element={value}
                        handleDropdownChange={handleDropdownChange}
                        selectedData={initialValues?.[objectName]?.[value.key]}
                      />
                    </Grid>
                  )

                case FormValues.SWITCH:
                  return (
                    <Grid item xs={12} sm={4} md={2} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                      <SwitchComponent
                        element={value}
                        handleSwitchChange={handleSwitchChange}
                        setIsComment={setIsComment}
                        isComment={isComment}
                      />
                    </Grid>
                  )

                case FormValues.TEXTBOXAREA:
                  return (isComment) && (
                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                      <TextboxAreaComponent
                        element={value}
                        handleTextFieldChange={handleTextFieldChange}
                        selectedTextboxValue={selectedTextboxValue}
                      />
                    </Grid>
                  )

                case FormValues.DROPDOWN_RANGE:
                  if (mode === 'Edit') {
                    const test = initialValues?.[objectName]?.[objectName]
                    defaultDropdownRange = value.enums.find((option: any) => option.value === test)
                  }

                  const mdValueDropdown = selectedDropdownValue ? 2 : 4
                  return (
                    <Grid item xs={12} sm={6} md={mdValueDropdown} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                      <DropdownRangeComponent
                        element={value}
                        handleDropdownChange={handleDropdownRangeChange}
                        options={value.enums}
                        selectedDropdownValue={selectedDropdownValue}
                        setSelectedDropdownValue={setSelectedDropdownValue}
                      />
                    </Grid>
                  )

                case FormValues.TEXTBOX_RANGE:
                  return (selectedDropdownValue) && (
                    <Grid item xs={12} sm={6} md={2} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                      <TextboxComponent
                        element={value}
                        handleTextFieldChange={handleTextFieldRangeChange}
                        values={isdropdownRangeTextValue}
                        errorText={errorText}
                      />
                    </Grid>
                  )

                default:
                  return null
              }
            }
            return null
          })}
        </Grid>
      )
    })
  }

  const renderFormElements = () => {
    const shouldDisplayElement = !templateData.judgeType || judgeType?.includes(templateData.judgeType)
    if (shouldDisplayElement) {
      return (
        <Grid container alignItems="center" className={classes.grouperForm} style={{ padding: '20px' }}>
          {renderForm()}
        </Grid>
      )
    }
  }

  return (
    <>
      {renderFormElements()}
    </>
  )
}

export default TemplateOne
