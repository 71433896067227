import React, { ReactElement } from 'react'
import { Link as RouterLink, Redirect, useLocation } from "react-router-dom"
import { Breadcrumbs, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useStyles } from "lib/theme"
import {
  BrandPermission, JudgeEventPermission, JudgeEventPermissions,
  LocationPermission,
  ProducerPermission,
  ProducerPermissions,
  ProgramPermission, ProgramPermissions
} from "store/user/userReducer"
import { Event, Team, Coach, Personnel, Athlete } from "store/themis_common_pb"

interface HeaderBreadcrumbsProps {
  currentProducer: ProducerPermission | undefined,
  currentBrand: BrandPermission | undefined,
  currentProgram: ProgramPermission | undefined,
  currentLocation: LocationPermission | undefined,
  stateEvent: Event.AsObject | undefined,
  stateTeam: Team.AsObject | undefined,
  stateAthlete: Athlete.AsObject | undefined,
  stateCoach: Coach.AsObject | undefined,
  statePersonnel: Personnel.AsObject | undefined ,
  userState: string,
  programs: ProgramPermissions,
  producers: ProducerPermissions,
  judgeEvents: JudgeEventPermissions
}

const HeaderBreadcrumbs: React.FC<HeaderBreadcrumbsProps> = ({ currentProducer, currentBrand, currentProgram, currentLocation, stateEvent, stateTeam, stateAthlete, stateCoach, statePersonnel, userState, producers, programs, judgeEvents }): ReactElement => {
  const classes = useStyles()
  const location = useLocation()

  const breadcrumbItems: ReactElement[] = []
  if (currentProducer) {
    if (currentProducer.name > "") breadcrumbItems.push(<RouterLink key={`Producer-${currentProducer.id}`} className={classes.breadcrumb} to={`/Producer/${currentProducer.id}`}>{currentProducer.name}</RouterLink>)
    if (currentBrand) {
      breadcrumbItems.push(<RouterLink key={`Brand-${currentBrand.id}`} className={classes.breadcrumb} to={`/Brand/${currentBrand.id}`}>{currentBrand.name}</RouterLink>)
      if (stateEvent?.id) {
        breadcrumbItems.push(<RouterLink key={`Brand-${currentBrand.id}-Event-${stateEvent.id}`} className={classes.breadcrumb} to={`/Brand/${currentBrand.id}/Event/${stateEvent.id}`}><Typography key={`Event-${stateEvent.id}`}>{stateEvent.name}</Typography></RouterLink>)
      }
    }
  }
  else if (currentProgram) {
    if (currentProgram.name > "") breadcrumbItems.push(<RouterLink key={`Program-${currentProgram.id}`} className={classes.breadcrumb} to={`/Program/${currentProgram.id}`}>{currentProgram.name}</RouterLink>)
    if (currentLocation) {
      breadcrumbItems.push(<RouterLink key={`Location-${currentLocation.id}`} className={classes.breadcrumb} to={`/Location/${currentLocation.id}`}>{currentLocation.name}</RouterLink>)
      if (stateTeam?.id) {
        breadcrumbItems.push(<RouterLink key={`Location-${currentLocation.id}-Team-${stateTeam.id}`} className={classes.breadcrumb} to={`/Location/${currentLocation.id}/Team/${stateTeam.id}`}><Typography key={`Team-${stateTeam.id}`}>{stateTeam.name}</Typography></RouterLink>)
        if (stateAthlete?.name) {
          breadcrumbItems.push(<RouterLink key={`Location-${currentLocation.id}-Team-${stateTeam.id}-Athlete-${stateAthlete.id}`} className={classes.breadcrumb} to={`/Location/${currentLocation.id}/Team/${stateTeam.id}/Athlete/${stateAthlete.id}`}><Typography>{stateAthlete.name}</Typography></RouterLink>)
        }
        if (stateCoach?.name) {
          breadcrumbItems.push(<RouterLink key={`Location-${currentLocation.id}-Team-${stateTeam.id}-Coach-${stateCoach.id}`} className={classes.breadcrumb} to={`/Location/${currentLocation.id}/Team/${stateTeam.id}/Coach/${stateCoach.id}`}><Typography>{stateCoach.name}</Typography></RouterLink>)
        }
        if (statePersonnel?.name) {
          breadcrumbItems.push(<RouterLink key={`Location-${currentLocation.id}-Team-${stateTeam.id}-Personnel-${statePersonnel.id}`} className={classes.breadcrumb} to={`/Location/${currentLocation.id}/Team/${stateTeam.id}/Personnel/${statePersonnel.id}`}><Typography>{statePersonnel.name}</Typography></RouterLink>)
        }
      } else if (stateAthlete?.name) {
        breadcrumbItems.push(<RouterLink key={`Location-${currentLocation.id}-Athlete-${stateAthlete.id}`} className={classes.breadcrumb} to={`/Location/${currentLocation.id}/Athlete/${stateAthlete.id}`}><Typography>{stateAthlete.name}</Typography></RouterLink>)
      } else if (stateCoach?.name) {
        breadcrumbItems.push(<RouterLink key={`Location-${currentLocation.id}-Coach-${stateCoach.id}`} className={classes.breadcrumb} to={`/Location/${currentLocation.id}/Coach/${stateCoach.id}`}><Typography>{stateCoach.name}</Typography></RouterLink>)
      } else if (statePersonnel?.name) {
        breadcrumbItems.push(<RouterLink key={`Location-${currentLocation.id}-Personnel-${statePersonnel.id}`} className={classes.breadcrumb} to={`/Location/${currentLocation.id}/Personnel/${statePersonnel.id}`}><Typography>{statePersonnel.name}</Typography></RouterLink>)
      }
    }
  }
  else {
    const locArray = location.pathname.split('/')
    if (!locArray[1]) { // We are at / let's find a better place to be.
      if (userState === 'program') {
        if (programs[0]?.id) {
          return <Redirect to={`/Program/${programs[0].id}`}/>
        }
      } else if (userState === 'producer') {
        if (producers[0]?.id) {
          return <Redirect to={`/Producer/${producers[0].id}`}/>
        }
      } else if (userState === 'judge') {
        if (judgeEvents[0]?.id) {
          return <Redirect to={`/Judge/${judgeEvents[0].id}`}/>
        }
      }
    }
  }
  const lastCrumb = breadcrumbItems.pop()
  if (lastCrumb) breadcrumbItems.push(<RouterLink key={lastCrumb.key} className={classes.lastBreadcrumb} to={lastCrumb.props.to}>{lastCrumb.props.children}</RouterLink>)


  return (<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
    {breadcrumbItems}
  </Breadcrumbs>)
}

export default HeaderBreadcrumbs
