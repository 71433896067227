import { Button } from "@material-ui/core";
import { useStyles } from "lib/theme";

interface ButtonProps {
    element: any;
    handleButtonClick: Function;
    index?: number;
    type?: string;
    buttonType?: string;
}

const ButtonComponent: React.FC<ButtonProps> = ({
    element,
    handleButtonClick,
    index,
    type,
    buttonType
}) => {
    const classes = useStyles();
    const combinedClassNames = `${classes.divisionSubmit} ${classes.textBoxStyle}`;
    return (
        <Button
            className={combinedClassNames}
            onClick={() => { buttonType ? handleButtonClick(buttonType) : handleButtonClick(index, type) }}
        >
            {element.title}
        </Button>
    );
};

export default ButtonComponent;
