import React, {
  CSSProperties,
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { Badge, Box, Button, Container, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import { useStyles } from "../../lib/theme"
import { JudgeEventPermission, JudgeShift } from "../../store/user/userReducer"
import themis_common from 'store/themis_common_pb'
import { getEventDivisionStraScores, getEventDivisionsByShift, getRubricsByProducerId, scoreEventDivisionShift, getRubricRAStraData, submitRubricScore, IScoresByJudgeIdAndDivision, getScoresByJudgeIdAndDivision, getScoreAndJudgeTypes } from "../../store/user/userActions"
import themis_api from "../../store/themis_api_pb"
import { CheckBoxOutlineBlank, CheckBoxOutlined, CloseOutlined, LocalPrintshop, OpenInNew as OpenInFull } from '@material-ui/icons'
import { useHistory } from "react-router"
import { convertToNumber } from 'lib/functions'
import NotificationSnackbar from 'components/util/NotificationSnackbar'
import CompareTeams from './CompareTeams'
import RubricScoringForm from './rubricScoring/RubricScoring'
import JudgeCoordinatorContainer from './JudgeCoordinator'

interface JudgeEventProps {
  event: JudgeEventPermission,
  eventDetails: themis_common.Event.AsObject
  saveScores: Function
  judgeId: number
  currentTeam: themis_common.EventDivisionShiftTeam.AsObject | undefined
  getTeam: Function
  divisionScores: themis_api.TeamScores.AsObject
  setDivisionScores: Function
  isTabulationJudge: boolean
  isLegalityJudge: boolean
  isCoordinatorJudge: boolean
  closeDivision: Function
  setCurrentShift: Function
  currentShift: number
}
export interface IDivisionApprovalStatus {
  divisionIndex:number;
  isApprove:boolean
}
export interface IDivisionOpenStatus {
  divisionIndex:number;
  isOpen:boolean
}

const JudgeEvent: React.FC<JudgeEventProps> = ({
  event,
  eventDetails,
  saveScores,
  judgeId,
  currentTeam,
  getTeam,
  divisionScores,
  setDivisionScores,
  isTabulationJudge,
  isLegalityJudge,
  isCoordinatorJudge,
  closeDivision,
  setCurrentShift,
  currentShift
}): ReactElement => {
  const classes = useStyles()

  const [offsetHeight, setOffsetHeight] = useState(300)
  const [time, setTime] = useState(false)
  const [currentDiv, setCurrentDiv] = useState(0)
  const [currentRubric, setCurrentRubric] = useState(0)
  const [divisionData, setDivisionData] = useState<themis_common.EventDivisionShift.AsObject[]>([])

  const [teamData, setTeamData] = useState<themis_common.EventDivisionShiftTeam.AsObject[]>([])
  const inputRef: MutableRefObject<any> = useRef()
  const [currentDivisionIndex, setCurrentDivisionIndex] = useState(0)
  const [divisionApprovalStatus, setDivisionApprovalStatus] = useState<IDivisionApprovalStatus[]>([])
  const [divisionOpenStatus, setDivisionOpenStatus] = useState<IDivisionOpenStatus[]>([])
  const [rubricData, setRubricData] = useState<themis_api.ProducerDivisionRubricsList.AsObject>()
  const [receivedResponse, setReceivedResponse] = useState<any>({})
  const [initialJsonDataResponse, setInitialJsonDataResponse] = useState<any>([])
  const [judgeTypes, setJudgeTypes] = useState<string[]>([])
  const [judgeCoordinatorData, setJudgeCoordinatorData] = useState<themis_api.ScoreAndJudgeTypeResponse.AsObject>()
  const [notificationData, setNotificationData] = useState({ isNotificationOpen: false, message: "", severity: "success" })
  const [scoresByJudgeIdAndDivisionInfo,setScoresByJudgeIdAndDivisionInfo]= useState<themis_api.ScoresByJudgeIdAndDivision.AsObject[]>([])
  const history = useHistory()

  const setShift = useCallback((shiftId: number) => {
    const doSetDivisions = async () => {
      const divData = await getEventDivisionsByShift(shiftId, eventDetails.brand?.producer?.id || 0, eventDetails.brand?.id || 0, eventDetails.id)
      const sortedDivData = divData.map(div => {
        return { ...div, teamsList: div.teamsList.sort(teamSorter) }
      })
      setDivisionData(sortedDivData)
    }
    doSetDivisions()
    setCurrentShift(shiftId)
  }, [eventDetails.brandId, eventDetails.id, eventDetails.producerId])

  const teamSorter = (a: themis_common.EventDivisionShiftTeam.AsObject, b: themis_common.EventDivisionShiftTeam.AsObject) => {
    if (a.order < b.order) return -1
    return 1
  }

  const getRubricDataByProducerId = async (producerId: number, divisionId: number) => {
    try {
      const apiData = await getRubricsByProducerId(producerId, divisionId)
      setRubricData(apiData)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    const shifts: JudgeShift = event.shifts[currentShift]
    const types = shifts?.judgeTypes.map((judgeType: any) => judgeType?.ProducerJudgeType?.Name)
    setJudgeTypes(types)
  }, [currentShift])

  useEffect(() => {
    const divData = divisionData?.find((division) => division.id === currentDiv)
    if (eventDetails?.brand?.producer && divData?.eventDivision?.division) {
      getRubricDataByProducerId(eventDetails.brand.producer.id, divData.eventDivision.division.id)
    }
  }, [eventDetails, currentDiv, divisionData, currentTeam])

  const getInitialJsonResponse = useCallback(async (producerId: number, brandId: number, eventId: number, judgeId: number) => {
    let data
    if (currentTeam) {
      data = await getRubricRAStraData(currentTeam.id)
      if (data.length > 0) {
        for (const item of data) {
          try {
            const getResponseData = await getEventDivisionStraScores(producerId, brandId, eventId, judgeId, item.id)

            const dynamicJsonState = {}
            getResponseData?.eventDivisionStraScoresList?.forEach((item: any) => {
              // Parse the dynamicFormJsonValues from string to JSON
              const dynamicJson = JSON.parse(item.dynamicFormJsonValues)

              // Merge the dynamicJson values into the state object
              Object.assign(dynamicJsonState, dynamicJson)
            })

            setInitialJsonDataResponse((prevState: any) => [...prevState, dynamicJsonState])
          } catch (error) {
            console.error('Error fetching data:', error)
          }
        }
      }
    }
  }, [currentTeam])

  useEffect(() => {
    if (eventDetails?.brand?.producer?.id && eventDetails?.brand?.id && event?.id) {
      getInitialJsonResponse(eventDetails.brand.producer.id, eventDetails.brand.id, event.id, judgeId)
    }
  }, [eventDetails, currentDiv, currentTeam, judgeId])

  const getScoreAndJudgeType = async (producerId: number, brandId: number, eventId: number, shiftId: number, eventDivisionId: number, eventTeamId: number) => {
    try {
      const data = await getScoreAndJudgeTypes(producerId, brandId, eventId, shiftId, eventDivisionId, eventTeamId)
      setJudgeCoordinatorData(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    const selectedDivision = divisionData?.find(div => div.id === currentDiv)
    if (eventDetails?.brand?.producer?.id && eventDetails?.brand?.id && event?.id && selectedDivision?.eventDivision?.id && currentTeam?.eventTeam?.id) {
      getScoreAndJudgeType(eventDetails.brand.producer.id, eventDetails.brand.id, event.id, currentShift, selectedDivision.eventDivision.id, currentTeam.eventTeam.id)
    }
  }, [currentShift, divisionData, currentDiv, currentTeam])

  const updateScores = async () => {
    const currentDivisionShiftId = currentTeam?.eventDivisionShift?.id
    if (currentDivisionShiftId && judgeId) {
      const scores = await scoreEventDivisionShift(currentDivisionShiftId, judgeId)
      setDivisionScores(scores)
    }
  }

  useEffect(() => {
    updateScores()
  }, [currentTeam])

  useEffect(() => {
    setTimeout(() => {
      setTime(current => !current)
    }, 2000)
  }, []) // Do not change this!

  // Get top offset
  useEffect(() => { // These timeouts adjust for the slow load of the header
    try {
      setOffsetHeight(Math.floor((inputRef.current?.getBoundingClientRect()).top))
    } catch (e) {
      // Do nothing
      console.error(e)
    }
  }, [time])

  useEffect(() => {
    if (currentDiv) {
      console.log("currentDiv", currentDiv)
      const producer_id = eventDetails.brand?.producer?.id
      const brand_id = eventDetails.brand?.id
      const event_id = eventDetails.id
      const eventDivisionInfo = divisionData.find((div) => div.id === currentDiv)
      const event_division_id = eventDivisionInfo?.eventDivision?.id
      const judge_id = judgeId
      const areRequiredIdsAvailable = !!producer_id && !!brand_id && !!event_id && !!event_division_id && !!judge_id
      console.log(producer_id, brand_id, event_id, event_division_id, judge_id, areRequiredIdsAvailable)
      if (areRequiredIdsAvailable) {
        const requestPayload: IScoresByJudgeIdAndDivision = {
          event_division_id,
          judge_id,
          producer_id,
          brand_id,
          event_id,
        }
        const fetchScoresByJudgeIdAndDivision = async () => {
          try {
            const scoresByJudgeIdAndDivisionInfo =
              await getScoresByJudgeIdAndDivision(requestPayload)
            const scoreDivisionInfo = scoresByJudgeIdAndDivisionInfo.responseList
            console.log("myscores", scoreDivisionInfo)
            setScoresByJudgeIdAndDivisionInfo(scoreDivisionInfo)
          } catch (error) {
            console.error("Error", error)
          }
        }
        fetchScoresByJudgeIdAndDivision()
      }
    }
  }, [currentDiv, eventDetails, judgeId, event])

  const setDivision = useCallback((division: themis_common.EventDivisionShift.AsObject) => {
    setCurrentDiv(division.id)
    setTeamData(division.teamsList)
    getTeam(division.teamsList[0]?.id || 0)
    setCurrentRubric(division?.eventDivision?.rubricId || 0)
  }, [getTeam])

  const matchWindowHeight = useMemo<CSSProperties>(() => {
    return {
      height: `calc(100vh - ${offsetHeight}px - 24px)`, // 24px is the Material Tab padding
    }
  }, [offsetHeight])

  const matchScoreAreaHeight = useMemo<CSSProperties>(() => {
    return {
      height: `calc(100vh - ${offsetHeight}px - 60px)`, // 24px is the Material Tab padding
    }
  }, [offsetHeight])
  const handleShiftClick = (shift: JudgeShift) => {
    setShift(shift?.shiftId)
    setDivisionData([])
    setCurrentDiv(0)
    setTeamData([])
  }

  const shifts: ReactElement[] = useMemo(() => {
    if (!event?.shifts) return []
    const retVal: ReactElement[] = []
    for (const shiftId of Object.keys(event.shifts)) {
      const sId = Number(shiftId)
      const shift = event.shifts[sId]
      const bgColor = currentShift === sId ? "red" : "rgb(17, 17, 17)"
      retVal.push(
        <Grid container key={`shift-${shift?.shiftId}`} style={{ height:"30px" }}>
          <Grid item xs={isTabulationJudge ? 10 : 12} style={{ cursor: "pointer", backgroundColor: bgColor,display:"flex",alignItems:"center" }} onClick={() => {
            handleShiftClick(shift)
          }}>
            - {`${shift?.day.substr(0, 3)} ${shift?.startTime} - ${shift?.floorName} ${shift?.panelName} (${shift?.divs} divs)`}
          </Grid>
          { isTabulationJudge ? <Grid item xs={1}><IconButton onClick={() => { window.open('/ShiftReport/' + shiftId) }}>
            <LocalPrintshop  fontSize='small' style={{ height:"15px" }}/>
          </IconButton></Grid> : [] }
        </Grid>)
    }
    return retVal
  }, [event.shifts, setShift, currentShift])

  useEffect(() => {
    if (divisionData) {
      divisionData.forEach((division, divisionIndex) => {
        // Update isDivisionApprove state for each division
        const isApproveDivisionInfo: IDivisionApprovalStatus = { divisionIndex, isApprove: division.approved }
        setDivisionApprovalStatus((prev) => {
          const newIsDivisionApprove = [...prev]
          newIsDivisionApprove[divisionIndex] = isApproveDivisionInfo
          return newIsDivisionApprove
        })

        // Update isDivisionOpen state for each division
        const divisionInfo: IDivisionOpenStatus = { divisionIndex, isOpen: division.approved }
        setDivisionOpenStatus((prev) => {
          const newIsDivisionOpen = [...prev]
          newIsDivisionOpen[divisionIndex] = divisionInfo
          return newIsDivisionOpen
        })
      })
    }
  }, [divisionData])

  const divisions = useMemo(() => {
    return divisionData.map((division, i) => {
      const bgColor = currentDiv === division.id ? "red" : "rgb(17, 17, 17)"
      return (
        <Grid container key={`division-${division.id}`} style={{ height:"30px" }}>
          <Grid item xs={isTabulationJudge ? 8 : 12} style={{ cursor: "pointer", backgroundColor: bgColor,display:"flex",alignItems:"center" }} onClick={() => { setDivision(division),setCurrentDivisionIndex(i) }}>
            {division.eventDivision?.division?.tier?.name} - {division.eventDivision?.name ? division.eventDivision?.name : division.eventDivision?.division?.name}
          </Grid>
          {isTabulationJudge ?
            <Grid item>
              <Tooltip title="Print">
                <IconButton onClick={() => { window.open('/TeamReport/' + division.id) }} >
                  <LocalPrintshop fontSize='small' style={{ height:"15px" ,marginRight: "3px" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={division.approved ? "Open" : "Close"}>
                <IconButton onClick={() => { closeDivision(division.id, !division.approved); setDivisionData(current => { return [ ...current.slice(0, i), { ...current[i], approved: !division.approved }, ...current.slice(i+1)]}) }}>
                  {division.approved ? <OpenInFull style={{ height:"15px" }} fontSize='small'/> : <CloseOutlined style={{ height:"15px" }}  fontSize='small'/>}
                </IconButton>
              </Tooltip>
            </Grid>
            : [] }
        </Grid>)
    })
  }, [divisionData, currentDiv, isTabulationJudge, setDivision,
    closeDivision, history,divisionApprovalStatus,divisionOpenStatus,
    currentDivisionIndex])

  const teams = useMemo(() => {
    return teamData.map(team => {
      // const scores = currentTeamScores.find(scoreSet => scoreSet.teamId === team.id)
      const bgColor = currentTeam?.id === team.id ? "red" : "rgb(17, 17, 17)"
      const score:any = divisionScores.teamScoresList.find(score => score.teamId === team.id)
      return (
        <Grid container item xs={12} spacing={0} key={`team-${team.id}`} style={{ border: "1px solid white",padding:"6px" }}>
          <Grid item xs={8} style={{ cursor: "pointer", backgroundColor: bgColor ,display:"flex",alignItems:"center" }} onClick={() => {
            getTeam(team.id)
          }}>{team.eventTeam?.programName} - {team.eventTeam?.name}</Grid>
          <Grid item xs={4} container>
            <Grid item xs={6}>{score?.score ?
              <Badge invisible={score?.rank > 3} badgeContent={score?.rank} color="secondary"><Box>
                <Typography variant="h6" style={{ fontSize: 16 }}>
                  {Math.round(convertToNumber(score?.score) * 10) / 10}</Typography></Box></Badge> : []} </Grid>
            <Grid item xs={6}><Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
              badgeContent={score?.judgeCount} color="primary">{score?.mescore ?
                <CheckBoxOutlined/> : <CheckBoxOutlineBlank style={{ height:"15px",alignItems:"center" }}/>}</Badge></Grid>
          </Grid>
        </Grid>)
    })
  }, [teamData, currentTeam?.id, getTeam, divisionScores.teamScoresList])

  // Function to receive the response from RubricScoringForm
  const handleReceivedResponse = (response: any) => {
    setReceivedResponse((prevData: any) => ({
      ...prevData,
      ...response
    }))
  }

  const generateScore = (rubricItemName: string, response: any) => {
    if (response?.maxParticipationDriverScoreValue) return String(response.maxParticipationDriverScoreValue)
    else if (response?.[`${rubricItemName}Value`]) return String(response[`${rubricItemName}Value`])
    else if (response?.[rubricItemName]) return String(response[rubricItemName])
    else if (response?.cutOffValue) return String(response.cutOffValue)
    else if (response?.ratings) return String(response.ratings.score)
    else return "0"
  }

  const handleRubricScore = useCallback((selectedJudge: any) => {
    const scores: any = []

    Object.entries(receivedResponse).forEach(([rubricItemName, valObj]: any) => {
      const area = rubricData?.producerDivisionRubricList?.map((item: any) => {
        return (
          item?.rubric?.rubricAreasList.find((rubricArea: any) => {
            return rubricArea?.rubricAreaItemsList?.find((item: any) => item.label.replace(/\s/g, '').toLowerCase() === rubricItemName.toLowerCase())
          }
          )
        )
      })

      if (area) {
        const specificItem = area[0]?.rubricAreaItemsList?.find(
          (item: any) => item.label.replace(/\s/g, '').toLowerCase() === rubricItemName.toLowerCase()
        )

        if (specificItem) {

          const judge = {
            brandId: selectedJudge?.brandId ? selectedJudge.brandId : 0,
            id: selectedJudge?.eventJudgeShift?.judgeId ? selectedJudge.eventJudgeShift.judgeId : judgeId,
            judgeProducersList: [],
            name: selectedJudge?.eventJudgeShift?.judgeName ? selectedJudge.eventJudgeShift.judgeName : "",
            producerId: selectedJudge?.producerId ? selectedJudge.producerId: 0
          }

          const score = {
            rubricAreaId: specificItem.rubricAreaId || 0,
            comment: valObj?.comment ? valObj?.comment : "",
            id: specificItem.id || 0,
            itemsValues: "",
            judge: judge,
            rubricAreaItemId: specificItem?.id || 0,
            score: generateScore(rubricItemName, valObj), //valObj ? String(valObj?.[rubricItemName]) : "",
            dynamicFormJsonValues: JSON.stringify({ [rubricItemName]: valObj })
          }

          scores.push(score)
        }
      }
    })

    const doSaveScores = async () => {
      if (!currentTeam?.id || !currentTeam.eventDivisionShift?.id || !eventDetails?.brand?.producer?.id || !eventDetails?.brand?.id || !event?.id) return
      try {
        await submitRubricScore(scores, currentTeam.id, currentTeam.eventDivisionShift.id, eventDetails.brand.producer.id, eventDetails.brand.id, event.id)

        setNotificationData({
          isNotificationOpen: true,
          message: "Your Scores has been recorded.",
          severity: "success"
        })
      } catch (error) {
        // Handle error from API call
        console.error('Error occurred while saving form:', error)
        setNotificationData({
          isNotificationOpen: true,
          message: "Error occured while saving scores.",
          severity: "error"
        })
      }
    }
    doSaveScores()

  }, [receivedResponse, rubricData, judgeId])

  // Call the api for particular interval
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleRubricScore(null)
    }, 60000) //Calls api after every 1 minute

    // Cleanup function to clear interval when component unmounts or dependencies change
    return () => clearInterval(intervalId)
  }, [handleRubricScore])

  const handleNotificationClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return
    setNotificationData({
      isNotificationOpen: false,
      message: "",
      severity: "success"
    })
  }

  const scoreCardContainer = useMemo(() => {
    return rubricData?.producerDivisionRubricList?.map((item: any) => {
      return item?.rubric?.rubricAreasList.flatMap((rubricArea: any) => {
        return rubricArea?.rubricAreaItemsList.map((rubricAreaItem: any) => {
          const matchedItem = initialJsonDataResponse.find((obj: any) => {
            return Object.keys(obj).some(key => key === rubricAreaItem.label)
          })
          return (
            <RubricScoringForm
              sheetData={JSON.parse(rubricAreaItem.dynamicFormJson)}
              judgeType={judgeTypes}
              initialJsonValues={matchedItem ? matchedItem : {}}
              responseData={handleReceivedResponse}
            />
          )
        })
      })
    })
  }, [rubricData, judgeTypes, currentTeam, initialJsonDataResponse])

  return <Container style={{ maxWidth: "none", fontSize: 12 }}>
    <Grid container spacing={1}>
      <Grid item container xs={5} md={4} lg={3} ref={inputRef} style={{ ...matchWindowHeight }}>
        <Grid item container xs={12} style={{ height: "40%", flexFlow: "column" }}>
          <Typography variant="h3">Divisions</Typography>
          <Grid container spacing={1} alignItems="flex-start"
            style={{ overflowY: "auto", border: "1px solid white",height:"100%" }}>
            {divisions}
          </Grid>
        </Grid>
        <Grid item container xs={12} style={{ height: "30%", flexFlow: "column" }}>
          <Typography variant="h3">Teams</Typography>
          <Grid container spacing={1} alignItems="flex-start"
            style={{ overflowY: "auto", border: "1px solid white",height:"100%" }}>
            {currentTeam && !teams.length ? <Typography variant='subtitle1' style={{ padding:"10px",fontSize:16,margin:'auto' }}>No Teams Found!</Typography> : teams}
          </Grid>
        </Grid>
        <Grid item container xs={12} style={{ height: "20%", flexFlow: "column" }}>
          <Typography variant="h3">Shifts</Typography>
          <Grid container spacing={1} alignItems="flex-start"
            style={{ overflowY: "auto", border: "1px solid white",height:"100%" }}>
            {shifts}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container sm={7} md={8} lg={9} spacing={1}>
        <Grid item container xs={12} style={{ overflowY: "auto", ...matchScoreAreaHeight }}>
          <Container maxWidth="xl">
            {(currentTeam && (teams.length > 1)) ? <CompareTeams scoresByJudgeIdAndDivisionInfo={scoresByJudgeIdAndDivisionInfo} teamData={teamData} /> : null}
            {scoreCardContainer}
            {rubricData && rubricData?.producerDivisionRubricList &&
              <Button
                // type="submit"
                variant="contained"
                className={classes.divisionSubmit}
                style={{ width: '100%', marginLeft: '1%' }}
                onClick={handleRubricScore}
              >
                Submit Score
              </Button>
            }
          </Container>
          {isCoordinatorJudge &&
          <Container maxWidth="xl">
            <JudgeCoordinatorContainer judgeCoordinatorData={judgeCoordinatorData} handleReceivedResponse={handleReceivedResponse} handleRubricScore={handleRubricScore} />
          </Container>
          }
        </Grid>
      </Grid>
      <NotificationSnackbar
        isOpen={notificationData.isNotificationOpen}
        onClose={handleNotificationClose}
        message={notificationData.message}
        severity={notificationData.severity as "warning" | "success" | "error" | "info"}
        autoHideDuration={3000}
      />
    </Grid>
  </Container>
}

export default JudgeEvent
