/* eslint max-lines: "off" */
import React, { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Container, Grid, IconButton, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography
} from '@material-ui/core'
import { useStyles } from 'lib/theme'

import themis_common from 'store/themis_common_pb'
import { Remove as RemoveIcon, Warning as WarningIcon, SwapHorizontalCircle as Crossover, AssignmentLate as NotSignedIcon, People as GuardianIcon } from "@material-ui/icons"
import { format, parseISO } from "date-fns"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { teamDivisionError, validateAthleteOnDivision } from "../../../lib/validators"
import { Alert } from "@material-ui/lab"
import { textGender } from "../../../lib/functions"
import { useHistory } from "react-router"

type eventRegistrationAthleteProps = {
  sortedAthletes: themis_common.Athlete.AsObject[]
  sortedValidAthletes: themis_common.Athlete.AsObject[]
  currentCrossovers: themis_common.Athlete.AsObject[]
  teamErrors: teamDivisionError[]
  addAthlete: Function
  removeAthlete: Function,
  selectedEventDivision: themis_common.EventDivision.AsObject,
  eventTeamSignStatus: themis_common.AthleteSignStatus.AsObject[],
  handleResendEmail: Function,
  eventTeamId: number | undefined
  locationId: number
  checkGuardians: Function
  dateComparison: boolean
  guardianRequired: boolean | undefined
}


const EventRegistrationAthlete: FunctionComponent<eventRegistrationAthleteProps> = ({
  sortedAthletes,
  sortedValidAthletes,
  currentCrossovers,
  teamErrors,
  addAthlete,
  removeAthlete,
  selectedEventDivision,
  eventTeamSignStatus,
  handleResendEmail,
  eventTeamId,
  locationId,
  checkGuardians,
  dateComparison,
  guardianRequired
}): ReactElement => {
  const classes = useStyles()
  const history = useHistory()

  const [rerenderAutocomplete, setRerenderAutoComplete] = useState(false)
  const [needSigs, setNeedSigs] = useState<themis_common.Athlete.AsObject[]>([])
  const [refresh, setRefresh] = useState(false)
  const [allSignStatus, setAllSignStatus] = useState<themis_common.AthleteSignStatus.AsObject[]>([])

  useEffect(() => {
    setAllSignStatus(eventTeamSignStatus)
  }, [eventTeamSignStatus])

  const athleteTable = useMemo(() => {
    return <TableBody>
      {teamErrors.length === 0 || !dateComparison ? <></> :
        <TableRow key="ErrorRow">
          <TableCell colSpan={4}>
            {teamErrors.map((error, i) => <Alert key={`Error-${i}`} severity="error">{error.issue}</Alert>)}            
          </TableCell>
        </TableRow>
      }
      {sortedAthletes.map((athlete) => {
        let gender = athlete.person?.gender
        if (gender === undefined || gender === null) gender = 3
        const isCrossover = !!currentCrossovers.find((crossover) => crossover.id === athlete.id)
        let currentIndex = -1
        const signStatus = allSignStatus.find((athleteSign, i) => {
          currentIndex = i
          return athleteSign.athleteId === athlete.id
        })
        if (!signStatus?.allSigned && signStatus?.canSend) {
          setNeedSigs((athleteList) => {
            if (!athleteList.find(anAthlete => anAthlete.id === athlete.id)) {
              athleteList.push(athlete)
            }
            return athleteList
          })
        }
        let athleteDivisionResult = ""
        if (selectedEventDivision?.division?.divisionRestriction) athleteDivisionResult = validateAthleteOnDivision(athlete, selectedEventDivision?.division?.divisionRestriction)
        return (
          <TableRow key={athlete.id}>
            {dateComparison && <TableCell style={{ width: "150px" }}>
              <IconButton className={classes.clickable} aria-label="remove" onClick={_ => {
                removeAthlete(athlete)
              }}>
                <RemoveIcon fontSize="small" />
              </IconButton>
            </TableCell>}
            <TableCell valign="middle">
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Typography>
                    {athlete.name}{athlete.person?.nickName ? ' (' + athlete.person.nickName + ')' : ''}
                  </Typography>

                </Grid>
                {isCrossover ?
                  <Grid item>
                    <Tooltip title="Crossover" aria-label="Crossover">
                      <Crossover style={{ marginTop: "5px", marginLeft: "10px" }} />
                    </Tooltip>
                  </Grid>
                  : []}
                {!signStatus?.allSigned && eventTeamId ?
                  <Grid item>
                    <Tooltip title={signStatus?.canSend ? "Unsigned Event Forms - Click to Resend Email" : "Sent within the last day"} aria-label="Unsigned Event Forms">
                      <NotSignedIcon style={signStatus?.canSend ? { color: "red", cursor: "pointer" } : { color: "grey", cursor: "pointer" }} onClick={() => {
                        if (signStatus) {
                          signStatus.canSend && handleResendEmail(athlete.id)
                          setAllSignStatus((current) => {
                            const newSignStatus = [...current]
                            newSignStatus[currentIndex].canSend = false
                            return newSignStatus
                          })
                          setNeedSigs(currentSigs => {
                            const newSigs = currentSigs
                            let thisSigIndex = -1
                            currentSigs.find((sig, i) => {
                              if (sig.id === athlete.id) {
                                thisSigIndex = i
                                return true
                              }
                              return false
                            })
                            if (thisSigIndex !== -1) {
                              newSigs.splice(thisSigIndex, 1)
                            }
                            return newSigs
                          })
                        }
                        setRefresh(current => !current)
                      }} />
                    </Tooltip>
                  </Grid>
                  : []}
                {selectedEventDivision?.division?.divisionRestriction ? (athleteDivisionResult.length > 0 ?
                  <Grid item>
                    <Tooltip title={athleteDivisionResult} aria-label={athleteDivisionResult}>
                      <WarningIcon style={{ marginLeft: "4px", color: "red" }} fontSize="small" />
                    </Tooltip>
                  </Grid>
                  : []) : []
                }
                <Grid item>
                  {guardianRequired && !checkGuardians(athlete.athleteGuardiansList) && dateComparison ?
                    <Tooltip title={"Guardian is required to register.  Either this athlete has no guardians or none of them have email addresses.  Click here to edit the athlete."} aria-label={"Guardian Required"}>
                      <GuardianIcon style={{ marginTop: "5px", marginLeft: "10px", color: "red" }} fontSize="small" onClick={() => { history.push(`/Location/${locationId}/Athlete/${athlete.id}`) }} />
                    </Tooltip>
                    : []}
                </Grid>
              </Grid>
            </TableCell>
            <TableCell align="right">{textGender(gender)}</TableCell>
            <TableCell
              align="right">{athlete.person?.birthDate ? format(parseISO(athlete.person.birthDate), 'MMM dd, yyyy') : ""}</TableCell>
          </TableRow>
        )
      })}
    </TableBody>

  }, [classes.clickable, currentCrossovers, allSignStatus, handleResendEmail, removeAthlete, selectedEventDivision?.division?.divisionRestriction, sortedAthletes, teamErrors, refresh])

  const sendButton = useMemo(() => {
    return <Button
      fullWidth
      variant="contained"
      disabled={needSigs.length === 0}
      color="primary"
      onClick={() => {
        needSigs.forEach(athlete => {
          let currentIndex = -1
          const signStatus = allSignStatus.find((athleteSign, i) => {
            currentIndex = i
            return athleteSign.athleteId === athlete.id
          })
          if (signStatus) {
            if (signStatus.canSend) {
              handleResendEmail(athlete.id)
              setAllSignStatus((current) => {
                const newSignStatus = [...current]
                newSignStatus[currentIndex].canSend = false
                return newSignStatus
              })
            }
          }
        })
        setNeedSigs([])
        setRefresh(current => !current)
      }}
    >Send Reminder Emails for Event Documents</Button>
  }, [allSignStatus, handleResendEmail, needSigs, needSigs.length, refresh])

  if (!sortedAthletes) return (<></>)
  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          {(!sortedValidAthletes || sortedValidAthletes.length === 0 || !dateComparison) ?
            <></>
            : (
              <Autocomplete
                key={rerenderAutocomplete ? '1' : '0'}
                id="Add_New_Athlete"
                size="small"
                options={sortedValidAthletes}
                getOptionLabel={(athlete: themis_common.Athlete.AsObject) => (athlete.name || 'No Name Found') + ' ' + (athlete.person?.nickName ? ' (' + athlete.person?.nickName + ')' : '')}
                style={{ width: 300, marginBottom: "15px" }}
                renderInput={(params) => <TextField {...params} label="Add Athlete" variant="outlined" />}
                onChange={(e, v) => {
                  v && addAthlete(v)
                  setRerenderAutoComplete(!rerenderAutocomplete)
                }}
              />)
          }
        </Grid>
        <Grid item sm={6} md={4}>
          {sendButton && dateComparison}
        </Grid>
      </Grid>
      {<TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {dateComparison && <TableCell style={{ width: "150px" }}>Remove from Event Team</TableCell>}
              <TableCell>Name</TableCell>
              <TableCell align="right">Gender</TableCell>
              <TableCell align="right">Birth Date</TableCell>
            </TableRow>
          </TableHead>
          {athleteTable}
        </Table>
      </TableContainer>}
    </Container>

  )
}

export default EventRegistrationAthlete
