import React, { ReactElement, useState } from 'react'
import { useStyles } from "lib/theme"
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import YesNoDialog from "components/util/YesNoModal"
import { userAuthShared } from 'store/user/userActions'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IAppState } from 'store/store'
import { selectCurrentUser } from 'store/user/userSelectors'



const AccessCodeLogin: React.FC = (): ReactElement => {

  const classes = useStyles()
  const dispatch = useDispatch()

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [accessCode, setAccessCode] = useState<string>("")

  const username: string | null = useSelector((state: IAppState) => selectCurrentUser(state))

  const submitForm = useCallback(async () => {
    try {
      if (username) localStorage.setItem('OLD_USERNAME', username)
      await userAuthShared(accessCode, dispatch)
      window.location.replace("/")
    } catch (e) {
      setDialogOpen(true)
      console.error(e)
    }
  }, [accessCode, dispatch, username])


  return (
    <Container>
      {/* Do we want CssBaseline again? */}
      <CssBaseline/>
      <Grid container>
        <Grid item xs={1}/>
        <Grid item xs={10}>
          <Typography variant="h1">
            Remote Account Login
          </Typography>
        </Grid>
      </Grid>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <form className={classes.form} noValidate onSubmit={submitForm}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="code"
              label="Access Code"
              name="code"
              autoComplete="code"
              onChange={e => setAccessCode(e.target.value)}
              autoFocus
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={() => submitForm()}
            >
              Login with Code
            </Button>
          </form>
        </div>
      </Container>
      <YesNoDialog
        title={"Invalid Access Code"}
        question={`The access code you entered is not valid. Please try again.`}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setDialogOpen(false) },
        ]}
      />
    </Container>
  )

}

export default AccessCodeLogin
