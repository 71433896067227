import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch } from "react-redux"
import { useStyles } from "lib/theme"
import themis_common from 'store/themis_common_pb'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Typography,
  Accordion,
  Grid
} from '@material-ui/core'
import { getRegistrationActivityLogByProducer } from "store/producer/producerActions"

interface producerRegistrationActivityProps { producerId: number}

const ProducerRegistrationActivity: React.FC<producerRegistrationActivityProps> = (props: producerRegistrationActivityProps): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const producerId = props.producerId

  const [reportData, setReportData] = useState<themis_common.RegistrationActivityLogItem.AsObject[]>([])
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    setReportData([])
    const getReportData = async () => {
      const reportData = await getRegistrationActivityLogByProducer(producerId)
      setReportData(reportData)
    }
    getReportData()
  }, [producerId])

  const activityList = useMemo(() => {
    return (<div>
      {reportData.map((item) => (
        <Accordion key={item.id} expanded={expanded === item.id} onChange={handleChange(item.id)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid container className={classes.root}>
              <Grid item xs={2}>
                <Typography variant="caption" style={{ "color": "gray", "width": "200px;" }}>{`${item.updatedAt?.formattedDateTime?.year}-${item.updatedAt?.formattedDateTime?.monthLeadingNumber}-${item.updatedAt?.formattedDateTime?.dayLeadingNumber} ${item.updatedAt?.formattedDateTime?.hour24leadingNumber}:${item.updatedAt?.formattedDateTime?.minutesLeadingNumber}`}&nbsp;</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="subtitle2">{item.description}</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <div dangerouslySetInnerHTML={{ __html: item.html.replace(/(<? *script)/gi, 'illegalscript') }}>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <Typography>Displaying last {reportData.length} items - limit (200)</Typography>
    </div>)
  },[reportData, expanded])

  return (
    <>
      <Typography variant="h2">
        Recent Registration Activity
      </Typography>
      {activityList}
    </>)
}

export default ProducerRegistrationActivity
