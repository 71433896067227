import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { Countries, countries, UsStates, usStates, FormFields } from "lib/constants"
import { useStyles } from "lib/theme"
import { buildErrors, capitalizeFirstLetter, handleInputChange, validURL } from "lib/validators"
import { Container, Grid, TextField, Typography } from '@material-ui/core'
import { emptyProgram } from "store/program/programReducer"
import Autocomplete from '@material-ui/lab/Autocomplete'
import YesNoDialog from 'components/util/YesNoModal'
import FormTextField from 'components/util/FormTextField'
import SubmitButton from 'components/util/SubmitButton'
import themis_common from "store/themis_common_pb"
import { AddLogo } from "components/util/AddLogo"
import { logoUploadContext } from "components/util/LogoUploadContext"
import NotificationSnackbar from 'components/util/NotificationSnackbar'

interface AddEditProgramProps {
  updatedProgram: themis_common.Program.AsObject
  program: themis_common.Program.AsObject | undefined
  programId: string | undefined
  setUpdatedProgram: React.Dispatch<React.SetStateAction<themis_common.Program.AsObject>>
  checkProgram: boolean
  setCheckProgram: React.Dispatch<React.SetStateAction<boolean>>
  formFields: FormFields
  isErrorOpen: boolean
  setIsErrorOpen: React.Dispatch<React.SetStateAction<boolean>>
  logoState: { id: number; addLogoType: string; oldLogo: string; fileSelected: boolean; base64File: string; fileName: string; maxFileSizeExceeded: boolean }
  setLogoState: React.Dispatch<React.SetStateAction<{ id: number; addLogoType: string; oldLogo: string; fileSelected: boolean; base64File: string; fileName: string; maxFileSizeExceeded: boolean }>>
  submitForm: (event: { preventDefault: () => void }) => Promise<void>
  isNotificationOpen: boolean
  setIsNotificationOpen: React.Dispatch<React.SetStateAction<boolean>>
  locationResponse: any
}

const AddEditProgram: React.FC<AddEditProgramProps> = ({
  updatedProgram, program, programId, setUpdatedProgram, checkProgram, setCheckProgram,
  formFields, isErrorOpen, setIsErrorOpen, logoState, setLogoState, submitForm,
  isNotificationOpen, setIsNotificationOpen, locationResponse
}): ReactElement => {
  const classes = useStyles()

  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})

  const findState = (stateCode?: string) => {
    return usStates.find((state) => state.abbreviation === stateCode?.slice(3, 5));
  }

  const findCountry = (countryCode?: string) => {
    return countries.find((country) => country.code === countryCode)
  }

  const defaultCountry = useMemo(() => {
    if (!updatedProgram?.country) return countries[0]
    return countries.find((country) => country.code === updatedProgram.country) || countries[0]
  }, [updatedProgram?.country])


  const phoneFields = useMemo(() => {
    return {
      phone: true
    }
  }, [])

  useEffect(() => {
    if (programId && program?.id) {
      setUpdatedProgram(program)
    } else {
      setUpdatedProgram({ ...emptyProgram, country: "US", state: "AL" })
    }
  }, [programId, program])

  // Check fields when user tries to submit
  useEffect(() => {
    if (checkProgram) {
      setErrorText(buildErrors(updatedProgram, formFields, setUpdatedProgram))
      setCheckProgram(false)
    }
  }, [checkProgram, phoneFields, formFields, updatedProgram])



  const create = !programId ? 'Create' : 'Edit'
  const creating = !programId ? 'creating' : 'editing'

  // Display logo
  const logoCheck = useMemo(() => {
    if (updatedProgram.logo > '' && validURL(updatedProgram.logo) && updatedProgram.logo.slice(-5).indexOf(".") >= 0) {
      setLogoState({ ...logoState, oldLogo: updatedProgram.logo })
    }
    return (<></>)
  }, [updatedProgram.logo, classes.logoCheck])

  const headerContents = () => {
    if (program?.name) {
      return `${create} Program - ${program.name}`
    } else {
      return `${create} Program`
    }
  }

  const handleNotificationClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return
    setIsNotificationOpen(false)
  }


  const flashDefault = useMemo(() => {
    if (!updatedProgram.id) {
      if (updatedProgram.postalCode === "") { updatedProgram.postalCode = locationResponse?.postcode ?? "" }
      if (updatedProgram.city === "") { updatedProgram.city = locationResponse?.city ?? "" }
      if (updatedProgram.state === "" || "AL") { updatedProgram.state = findState(locationResponse?.principalSubdivisionCode)?.abbreviation ?? "AL" }
      if (updatedProgram.country === "") { updatedProgram.country = "" + findCountry(locationResponse?.countryCode) }
    }
    return (<></>)
  }, [locationResponse])

  const defaultState = useMemo(() => {
    if (updatedProgram.state) {
      return usStates.find((state) => state.abbreviation === updatedProgram?.state) || usStates[0]
    }
    else {
      return findState(locationResponse?.principalSubdivisionCode) ?? usStates[0]
    }
  }, [updatedProgram.state])


  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{headerContents()}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        {/* Name row */}
        <Grid container>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedProgram}
              setUpdateObject={setUpdatedProgram}
              name="name"
              autoFocus
              sliceSize={100}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedProgram}
              setUpdateObject={setUpdatedProgram}
              name="legalName"
              sliceSize={150}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedProgram}
              setUpdateObject={setUpdatedProgram}
              name="dba"
              sliceSize={100}
            />
          </Grid>
        </Grid>
        {/* Address row */}
        <Grid container>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedProgram}
              setUpdateObject={setUpdatedProgram}
              name="address"
              sliceSize={100}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedProgram}
              setUpdateObject={setUpdatedProgram}
              name="city"
              sliceSize={40}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedProgram}
              setUpdateObject={setUpdatedProgram}
              name="postalCode"
              sliceSize={20}
            />
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
              <FormTextField
                formFields={formFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={updatedProgram}
                setUpdateObject={setUpdatedProgram}
                name="address2"
                sliceSize={100}
              />
            </Grid>
            {updatedProgram.country === 'US' || !updatedProgram?.country ?
              <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
                <Autocomplete
                  key={'2'}
                  options={usStates}
                  fullWidth={true}
                  value={defaultState}
                  disableClearable
                  getOptionLabel={(state: UsStates) => state.name}
                  renderInput={(params) => <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} label={formFields.state.label} required={formFields.state.required} error={!!errorText.address2} helperText={errorText.address2 || ""} variant="outlined" />}
                  onChange={(e, v) => {
                    v && handleInputChange({ target: { name: "state", value: v.abbreviation } }, formFields, updatedProgram, setUpdatedProgram, errorText, setErrorText)
                  }}
                />
              </Grid>
              :
              <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
                <FormTextField
                  formFields={formFields}
                  errorText={errorText}
                  setErrorText={setErrorText}
                  updatedObject={updatedProgram}
                  setUpdateObject={setUpdatedProgram}
                  name="state"
                  sliceSize={30}
                />
              </Grid>
            }
            {!updatedProgram?.country && program?.country ? <></> :
              <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
                <Autocomplete
                  key={'1'}
                  options={countries}
                  fullWidth={true}
                  value={findCountry(updatedProgram.country) ?? defaultCountry}
                  getOptionLabel={(country: Countries) => country.name}
                  disableClearable
                  renderInput={(params) =>
                    <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                      <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} autoComplete="none" label={formFields.country.label} required={formFields.country.required} error={!!errorText.country} helperText={errorText.country || ""} variant="outlined" />
                    </form>
                  }
                  onChange={(e, v) => {
                    v && handleInputChange({ target: { name: "country", value: v.pseudoCode || v.code } }, formFields, updatedProgram, setUpdatedProgram, errorText, setErrorText)
                  }}
                />
              </Grid>
            }

          </Grid>
        </Grid>
        {/* Url row */}
        <Grid container>
          <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
            <logoUploadContext.Provider value={{ logoState, setLogoState }}>
              <AddLogo></AddLogo>
            </logoUploadContext.Provider>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedProgram}
              setUpdateObject={setUpdatedProgram}
              name="website"
              sliceSize={200}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedProgram}
              setUpdateObject={setUpdatedProgram}
              name="phone"
              sliceSize={20}
            />
          </Grid>
        </Grid>
        <SubmitButton title={`${create.toUpperCase()} Program`} ariaLabel="submit-program-form" submitForm={submitForm} />
      </Grid>
      <YesNoDialog
        title={`Error ${capitalizeFirstLetter(creating)} Program`}
        question={`There was an error ${creating} your program. Please contact us if this continues to be a problem.`}
        isOpen={isErrorOpen}
        onClose={() => setIsErrorOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
        ]}
      />
      <NotificationSnackbar
        isOpen={isNotificationOpen}
        onClose={handleNotificationClose}
        message="Your program was connected to the producer."
        severity="success"
        autoHideDuration={5000}
      />
    </Container>
  )
}

export default AddEditProgram
