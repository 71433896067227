import { ACCESS_TOKEN, REFRESH_TOKEN } from "lib/constants"
import { ThemisApiPromiseClient } from "./themis_api_grpc_web_pb"
import environment from "environment"
import { refreshUser } from "./user/userActions"


const handleLogout = () => {
  localStorage.removeItem(ACCESS_TOKEN)
  localStorage.removeItem(REFRESH_TOKEN)
  localStorage.removeItem("CURRENT_EVENT")
  localStorage.removeItem("CURRENT_TEAM")
  localStorage.removeItem('producerConnectLink')
  window.location.href = "/"
}


class AuthInterceptor {
  async intercept(request: any, invoker: any) {
    let token = localStorage.getItem(ACCESS_TOKEN)
    const metadata = request.getMetadata()
    metadata.Authorization = 'Bearer ' + token
    try {
      return await invoker(request)
    } catch (error) {
      if (error.message.indexOf('error validating the JWT') !== -1) {
        console.error('jwt error, maybe expired, refreshing to token.', error.message)
        // Refresh the JWT using the refresh token
        token = localStorage.getItem(ACCESS_TOKEN) || ''
        if (!token.length) {
          handleLogout()
          throw (error)
        }
        if (request.c.name !== "/themis.ThemisApi/Refresh") {
          await refreshUser()
        } else {
          handleLogout()
        }
        //Update the token
        metadata.Authorization = 'Bearer ' + token
        // Get new Response
        return await invoker(request)
      } else {
        throw (error)
      }
    }
  }
}
const authInterceptor = new AuthInterceptor()

const clientOptions = {
  unaryInterceptors: [authInterceptor],
  streamInterceptors: []
}

export const client = new ThemisApiPromiseClient(environment.BASE_URL, null, clientOptions)
