import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import Documents from "./Documents"
import themis_common from 'store/themis_common_pb'
import { getProducerDocuments } from "../../../store/producer/producerActions"
import { getEventDocuments } from "../../../store/producer/eventActions"

interface reportsContainerProps {
  producer: themis_common.Producer.AsObject
  brands: themis_common.Brand.AsObject[]
  events?: themis_common.Event.AsObject[]
  brandId?: number
  eventId?: number
}

const DocumentsContainer: React.FC<reportsContainerProps> = ({ producer, brands, brandId, eventId, events }): ReactElement => {

  const producerId = useMemo(() => {
    return producer?.id
  }, [producer?.id])

  const [documents, setDocuments] = useState<themis_common.EventDocument.AsObject[]>([])
  const [refreshDocuments, setRefreshDocuments] = useState(false)

  useEffect(() => {
    const getDocuments = async () => {
      if (brandId && eventId) {
        const docs = await getEventDocuments(producerId, brandId, eventId)
        setDocuments(docs)
      } else {
        const docs = await getProducerDocuments(producerId)
        setDocuments(docs)
      }
    }
    if (producerId) getDocuments()
  }, [producerId, brandId, refreshDocuments, eventId])

  const addDocument = useCallback(() => {
    setDocuments((localDocuments) => {
      if (localDocuments.find(document => document.id === -1)) return localDocuments // If we already have an unsaved (id = -1) document do not create a new one.
      return [...localDocuments, { id: -1, name: "New Document", producerId: producerId, brandId: brandId || 0, eventId: eventId || 0, status: true, text: "", signed: 0 }]
    })
  }, [brandId, eventId, producerId])

  return <Documents producerId={producerId} brandId={brandId} eventId={eventId} documents={documents} setRefreshDocuments={setRefreshDocuments} producer={producer} brands={brands} events={events} addDocument={addDocument} />
}

export default DocumentsContainer
