import React, { ReactElement, useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { useSelector } from 'react-redux'
import { IAppState } from 'store/store'
import { selectCurrentLoginState } from 'store/user/userSelectors'
import { getStripePK } from 'store/user/userActions'


const LoadStripe: React.FC = ({ children }): ReactElement => {
  const loggedIn = useSelector((state: IAppState) => selectCurrentLoginState(state))

  const [loadStripeProviderPromise, setLoadStripeProviderPromise] = useState<Stripe | null>(null)

  // Load Stripe Provider
  useEffect(() => {
    if (!loggedIn) return
    if (!loadStripeProviderPromise) {
      (async () => {
        const stripePK = await getStripePK()
        const loadStripeProviderPromiseConst = await loadStripe(stripePK)
        // const loadStripeProviderPromiseConst = await loadStripe(addPaymentDataResponse.pk, { stripeAccount: addPaymentDataResponse.producerStripeAccountId })
        setLoadStripeProviderPromise(loadStripeProviderPromiseConst)
      })()
    }
  }, [loggedIn, loadStripeProviderPromise])

  return <Elements stripe={loadStripeProviderPromise}>{children}</Elements>
}

export default LoadStripe
