import React, { ReactElement, useMemo, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Add as AddIcon } from '@material-ui/icons'
import { editorTheme } from "lib/theme"
import { Box, ClickAwayListener, IconButton, List, ListItem, ListItemText, Popover, Typography } from "@material-ui/core"
import { Event } from "store/themis_common_pb"
import { ProgramPermissions } from "store/user/userReducer"
import { validateProgramPermission, validateTeamPermission } from "lib/permissions"
import themis_common from "store/themis_common_pb"

interface teamWeekendProps {
  programId: number
  locationId: number
  teamId: number
  weekendId: string
  scheduleList: themis_common.EventTeam.AsObject[]
  waiverStatus: boolean
  errorStatus: boolean
  availableEvents: Event.AsObject[]
  programPermissions: ProgramPermissions
  superUser: boolean,
  permissionCodes: themis_common.PermissionCodes.AsObject
}

const TeamWeekend: React.FC<teamWeekendProps> = ({ programId, locationId, teamId, weekendId, scheduleList, waiverStatus, errorStatus, availableEvents, superUser, programPermissions, permissionCodes }): ReactElement => {
  const history = useHistory()

  const [anchorSelectEventEl, setAnchorSelectEventEl] = useState<HTMLButtonElement | null>(null)

  const handleSelectEventClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSelectEventEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorSelectEventEl(null)
  }

  const clickAwayHandler = () => {
    if (anchorSelectEventEl) handleClose()
  }

  let scheduleColor = 'grey'
  if (scheduleList && scheduleList.length > 0) {
    const eventTime = scheduleList[scheduleList.length - 1].event?.eventDatesList[availableEvents[0]?.eventDatesList.length - 1]?.endClockTime?.unixTime ?? 0
    if (eventTime < Date.now()) {
      scheduleColor = 'pink' // check if the event of the block is expired if so set color to pink.
    }
    else {
      const paid = scheduleList.reduce((acc, val) => acc && val.allPaid, true)
      const deposit = scheduleList.reduce((acc, val) => acc && val.deposit, true)
      const signed = scheduleList.reduce((acc, val) => acc && (val.signed || !val.event?.brand?.guardianRequired), true)

      let score = 3
      if (!paid) score --
      if (!deposit) score --
      if (!signed) score --
      if (availableEvents.length > 0) {
        switch (score) {
          case 3:
            scheduleColor = 'green'
            break
          case 2:
            scheduleColor = 'yellow'
            break
          case 1:
            scheduleColor = 'orange'
            break
          case 0:
            scheduleColor = 'red'
            break
        }
      }
    }
  }
  if (scheduleList.length === 0 && availableEvents.length > 0) {
    const availableEventsTime = availableEvents[0].eventDatesList[availableEvents[0].eventDatesList.length - 1].endClockTime?.unixTime ?? 0;

    if (availableEventsTime < Date.now()) {
      scheduleColor = "grey"
    }
    else {
      scheduleColor = '#27AAE1'
    }
  }

  const statusBoxStyle: React.CSSProperties = {
    position: "relative",
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    width: 30,
    height: 30,
    backgroundColor: scheduleColor,
    margin: 2,
    cursor: "pointer",
    borderRadius: 5
  }

  const userCanRegisterTeam = useMemo(() => {
    return (locationId && validateTeamPermission(permissionCodes.teamRegister, permissionCodes, superUser, programPermissions, programId, locationId, teamId)) ||
      validateProgramPermission(permissionCodes.teamRegister, permissionCodes, superUser, programPermissions, programId)
  }, [locationId, programId, programPermissions, superUser, teamId, permissionCodes])

  // Edit this to change view based on whether there was an expired event or not.

  const checkAvailableEventDate = useMemo(() => {
    if (availableEvents && availableEvents[0] && availableEvents.length > 0) {
      const availableEventsTime = availableEvents[0]?.eventDatesList[availableEvents[0].eventDatesList.length - 1]?.endClockTime?.unixTime ?? 0
      if (availableEventsTime > Date.now()) {
        return true
      } else {
        return false
      }
    }
  }, [])
  return (
    <div style={statusBoxStyle}>

      {availableEvents.length ?
        <>
          <IconButton onClick={handleSelectEventClick}>

            {scheduleList.length === 0 && checkAvailableEventDate ?
              <AddIcon fontSize="small" /> :
              <></>
            }
          </IconButton>
          <Popover
            id={Boolean(anchorSelectEventEl) ? "SelectEventPopover" : undefined}
            open={Boolean(anchorSelectEventEl)}
            anchorEl={anchorSelectEventEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ClickAwayListener onClickAway={clickAwayHandler}>
              <Box paddingX={2} paddingY={1} >

                <List>
                  <Typography>View or Register to Events:</Typography>
                  {availableEvents.map((event) => {
                    // pull event out of event list, and assigning them to squares.
                    const registrationInfo = event.teamsList.find((eventTeam) => eventTeam.teamId === teamId)
                    if (event && event.eventDatesList[event.eventDatesList.length - 1].endClockTime) {
                      const index = event.eventDatesList.length - 1 ?? 0
                      const comparison = event?.eventDatesList[index]?.endClockTime?.unixTime ?? 0
                      return (
                        comparison > Date.now() ?
                          <ListItem style={{ cursor: "pointer" }} key={event.id}>
                            <ListItemText style={registrationInfo ? { color: editorTheme.palette.error.dark } : {}} onClick={() => {
                              if (userCanRegisterTeam) {
                                if (registrationInfo) {
                                  history.push(`/Registration/${locationId}/team/${teamId}/${event.id}/${registrationInfo.id}`)
                                } else {
                                  history.push(`/Register/${locationId}/team/${teamId}/${event.id}`)
                                }
                              }
                            }}>{event.brand?.name ? `${event.brand.name} - ` : ""}{event.name} {registrationInfo ? "(Registered)" : ""}</ListItemText>
                          </ListItem>
                          : comparison < Date.now() ?
                            <ListItem style={{ cursor: "pointer" }} key={event.id}>
                              <ListItemText style={registrationInfo ? { color: editorTheme.palette.error.dark } : {}} onClick={() => {
                                if (userCanRegisterTeam) {
                                  if (registrationInfo) {
                                    history.push(`/Registration/${locationId}/team/${teamId}/${event.id}/${registrationInfo.id}`)
                                  } else {
                                    history.push(`/Register/${locationId}/team/${teamId}/${event.id}`)
                                  }
                                }
                              }}>{event.brand?.name ? `${event.brand.name} - ` : ""}{event.name} {"(Expired Event)"}</ListItemText>
                            </ListItem>
                            : <></>)
                    }
                  })}
                </List>
              </Box>
            </ClickAwayListener>
          </Popover>
        </>
        :
        <></>
      }
    </div>
  )
}

export default TeamWeekend
