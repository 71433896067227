import React, { ReactElement, useCallback, useState } from 'react'
import { Button, Card, CardActions, CardContent, Grid, IconButton, Typography } from '@material-ui/core'
import { useStyles } from "../../../../lib/theme"
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import ReportsContainer from "../../reports/ReportsContainer"
import AthletePayReport from "./AthletePayReport"

interface EventReportsProps {
  eventId: number
  brandId: number
  producerId: number
}

const EventReports: React.FC<EventReportsProps> = ({eventId, brandId, producerId}): ReactElement => {
  const classes = useStyles()

  const [currentReport, setCurrentReport] = useState(0)

  const reportHolder = useCallback((report, reportName) => <Grid container spacing={3} justify="flex-start">
    <Grid item xs={12} md={3}>
      <Button onClick={() => { setCurrentReport(0) }} startIcon={<ArrowBackIcon/>}>
        Back To Event Reports
      </Button>
    </Grid>
    <Grid item xs={12} md={9}>
      <Typography>{ reportName }</Typography>
    </Grid>
    { report }
  </Grid>, [])

  const reportGrid = <Grid container spacing={3} justify="flex-start">
    <Grid item xs={12} md={6} lg={4} >
      <Card className={classes.divider}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Event Report Teams/Programs
          </Typography>
          <Typography variant="body2" component="p">
            A report showing payments including gross/net per team or program
          </Typography>
        </CardContent>
        <CardActions>
          <Button color="primary" variant="outlined" onClick={() => { setCurrentReport(1) }}>Go</Button>
        </CardActions>
      </Card>
    </Grid>
    <Grid item xs={12} md={6} lg={4} >
      <Card className={classes.divider}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Pay Report
          </Typography>
          <Typography variant="body2" component="p">
            A report detailed by athlete or team showing the pay status of each
          </Typography>
        </CardContent>
        <CardActions>
          <Button color="primary" variant="outlined" onClick={() => { setCurrentReport(2) }}>Go</Button>
        </CardActions>
      </Card>
    </Grid>
  </Grid>

  switch (currentReport) {
    case 0:
      return reportGrid
    case 1:
      return reportHolder(<ReportsContainer producerId={producerId} eventId={eventId} reportId={2} />, "Event Report Teams/Programs")
    case 2:
      return reportHolder(<AthletePayReport producerId={producerId} brandId={brandId} eventId={eventId} /> , "Athlete Pay Report" )
    default:
      return reportGrid
  }
}

export default EventReports
