import React, { ReactElement, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { getCoach, getCoachTeams } from "store/program/coachActions"
import { addCoachToTeam, removeCoachFromTeam } from "store/program/teamActions"
import { Link as RouterLink, useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentLocation, selectCurrentProgram } from "store/program/programSelectors"
import themis_common from "store/themis_common_pb"
import { removeCoachFromLocation } from "store/program/locationActions"
import { selectPermissionCodes, selectSuperUser, selectUserProgramPermissions } from 'store/user/userSelectors'
import Coach from './Coach'

const CoachContainer: React.FC = (): ReactElement => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { coachId: inputCoachId, teamId: inputTeamId, locationId: inLocationId } = useParams<{ coachId?: string | undefined, teamId?: string | undefined, locationId?: string | undefined }>()
    const coachId = Number(inputCoachId)
    const teamId = Number(inputTeamId)
    const locationId = Number(inLocationId)

    const program: themis_common.Program.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProgram(state))
    const location: themis_common.Location.AsObject | undefined = useSelector((state: IAppState) => selectCurrentLocation(state))
    const programId = useMemo(() => program?.id || 0, [program])

    const [coach, setCoach] = useState<themis_common.Coach.AsObject | undefined>(undefined)
    const [teams, setTeams] = useState<number[]>([])
    const [reload, updateReload] = useState<number>(0)
    const [deleteCoachIsOpen, setDeleteCoachIsOpen] = useState<number>(0)

    const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
    const superUser = useSelector((state: IAppState) => selectSuperUser(state))
    const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

    async function updateTeams() {
        const teams = await getCoachTeams(dispatch, coachId, programId, locationId, teamId)
        setTeams(teams.map((team) => team.id))
    }

    const handleRemoveCoach = async (): Promise<void> => {
        if (!deleteCoachIsOpen) return
        await removeCoachFromLocation(dispatch, locationId, programId, coachId)
        history.replace(`/Location/${locationId}`)
    }
    useEffect(() => {
        load()
        async function load() {
            if (!programId || !locationId || !coachId) return
            setCoach(await getCoach(dispatch, coachId, programId, locationId, teamId))
            await updateTeams()
        }
    }, [programId, locationId, coachId, reload, dispatch])

    async function handleToggle(teamId: number, onTeamNow: boolean) {
        if (onTeamNow) {
            await removeCoachFromTeam(dispatch, programId, locationId, teamId, coachId)
        } else {
            await addCoachToTeam(dispatch, programId, locationId, teamId, coachId)
        }
        await updateTeams()
    }
    return (
        <Coach
            coachId={coachId}
            teamId={teamId}
            locationId={locationId}
            program={program}
            location={location}
            programId={programId}
            coach={coach}
            setCoach={setCoach}
            teams={teams}
            setTeams={setTeams}
            reload={reload}
            updateReload={updateReload}
            deleteCoachIsOpen={deleteCoachIsOpen}
            setDeleteCoachIsOpen={setDeleteCoachIsOpen}
            programPermissions={programPermissions}
            superUser={superUser}
            permissionCodes={permissionCodes}
            handleToggle={handleToggle}
            handleRemoveCoach={handleRemoveCoach}
        />
    )
}

export default CoachContainer