import React, { useEffect, useState, ReactElement } from 'react'
import themis_common from 'store/themis_common_pb'
import { getApprovedEvents } from 'store/producer/eventActions'
import { getEventTeamsForEventGridByProgramId, getEventTeamsForEventGridByLocationId, getEventTeamsForEventGridByTeamId } from 'store/program/eventTeamActions'
import { ProgramPermissions } from "store/user/userReducer"
import EventHeader from './EventHeader'
import EventGrid from './EventGrid'
import EventList from './EventList'
import { useSelector } from 'react-redux'
import { IAppState } from 'store/store'
import { selectCurrentCategories } from 'store/user/userSelectors'
import { useMemo } from 'react'
import { useCallback } from 'react'
import { listProducerLink } from 'store/program/programActions'

interface EventGridContainerProps {
  eventGridType: string
  permissionCodes: themis_common.PermissionCodes.AsObject
  programPermissions: ProgramPermissions
  superUser: boolean
  programId: number
  locationId?: number
  teamId?: number
  program: themis_common.Program.AsObject
  location?: themis_common.Location.AsObject
  team?: themis_common.Team.AsObject
}

interface ListTeam {
  teamId: number
  teamName: string
  locationId: number
  locationName: string
  team: themis_common.Team.AsObject
}

const EventGridContainer: React.FC<EventGridContainerProps> = ({
  eventGridType, permissionCodes, programPermissions, superUser,
  programId, locationId, teamId, program, location, team
}): ReactElement => {

  const categories = useSelector((state: IAppState) => selectCurrentCategories(state))

  const [teams, setTeams] = useState<ListTeam[]>([])
  const [eventTeams, setEventTeams] = useState<themis_common.EventTeam.AsObject[]>([])
  const [approvedEvents, setApprovedEvents] = useState<themis_common.Event.AsObject[]>([])
  const [scoringAuthorities, setScoringAuthorities] = useState<themis_common.ScoringAuthority.AsObject[]>([])
  const [programAllowedProducers, setProgramAllowedProducers] = useState<themis_common.ProgramAllowedProducer.AsObject[]>([])
  const [selectedScoringAuthorityId, setSelectedScoringAuthorityId] = useState<number | undefined>(undefined)
  const [selectedProducerId, setSelectedProducerId] = useState<number | undefined>(undefined)
  const [showEventGrid, setShowEventGrid] = useState<boolean>(true)

  const listProducerLinks = useCallback(() => {
    const getConnections = async() => {
      if (program?.id) {
        const producerLinks = await listProducerLink([program.id])
        const producerList: any[] = []
        Object.values(producerLinks).forEach((element) => {
          Object.values(element).forEach((producerLink) => {
            producerList.push(producerLink)
          })
        })
        setProgramAllowedProducers(producerList)
      }
    }
    if (program) {
      getConnections()
    }
  }, [program, setProgramAllowedProducers])

  useEffect(() => {
    listProducerLinks()
  }, [listProducerLinks])


  // Get Events
  useEffect(() => {
    if (!programId) return
    const doGetEvents = async () => {
      // Get ApprovedEvents
      const myEvents = await getApprovedEvents(programId)
      setApprovedEvents(myEvents)
      // Get EventTeams
      switch (eventGridType) {
        case "program":
          if (programId) {
            const programEventTeamsResponse = await getEventTeamsForEventGridByProgramId(programId, "2020-01-01")
            setEventTeams(programEventTeamsResponse)
            const listTeams: ListTeam[] = []
            program?.locationsList.forEach(location => {
              location.teamsList.forEach(team => {
                listTeams.push({ teamId: team.id, teamName: team.name, locationId: location.id, locationName: location.name, team: team })
              })
            })
            setTeams(listTeams)
          }
          break
        case "location":
          if (programId && location?.id) {
            const locationEventTeamsResponse = await getEventTeamsForEventGridByLocationId(programId, location.id, "2020-01-01")
            setEventTeams(locationEventTeamsResponse)
            const listTeams: ListTeam[] = []
            location.teamsList.forEach(team => {
              listTeams.push({ teamId: team.id, teamName: team.name, locationId: location.id, locationName: location.name, team: team })
            })
            setTeams(listTeams)
          }
          break
        case "team":
          if (programId && location && team) {
            const teamEventTeamsResponse = await getEventTeamsForEventGridByTeamId(programId, location.id, team.id, "2020-01-01")
            setEventTeams(teamEventTeamsResponse)
            const listTeams: ListTeam[] = []
            listTeams.push({ teamId: team.id, teamName: team.name, locationId: location.id, locationName: location.name, team: team })
            setTeams(listTeams)
          }
          break
        default:
          break
      }
    }
    doGetEvents()
  }, [eventGridType, programId, locationId, teamId, program, location, team])

  const filteredEvents = useMemo(() => {
    return approvedEvents
      .filter(event => selectedScoringAuthorityId === undefined || !!event.seasonsList.find(season => season.scoringAuthority?.id === selectedScoringAuthorityId))
      .filter(event => selectedProducerId === undefined || event.brand?.producer?.id === selectedProducerId)
  }, [approvedEvents, selectedScoringAuthorityId, selectedProducerId])

  return (
    <>
      <EventHeader
        categories={categories}
        setScoringAuthorities={setScoringAuthorities}
        scoringAuthorities={scoringAuthorities}
        programAllowedProducers={programAllowedProducers}
        setSelectedScoringAuthorityId={setSelectedScoringAuthorityId}
        setSelectedProducerId={setSelectedProducerId}
        showEventGrid={showEventGrid}
        setShowEventGrid={setShowEventGrid}
      />
      {showEventGrid ?
        <EventGrid
          eventGridType={eventGridType}
          permissionCodes={permissionCodes}
          programPermissions={programPermissions}
          superUser={superUser}
          programId={programId}
          locationId={locationId}
          teamId={teamId}
          program={program}
          location={location}
          team={team}
          teams={teams}
          eventTeams={eventTeams}
          setTeams={setTeams}
          setEventTeams={setEventTeams}
          setApprovedEvents={setApprovedEvents}
          approvedEvents={filteredEvents}
          showEventGrid={showEventGrid}
        />
        :
        <EventList
          permissionCodes={permissionCodes}
          programPermissions={programPermissions}
          superUser={superUser}
          programId={programId}
          locationId={locationId}
          teamId={teamId}
          location={location}
          teams={teams}
          eventTeams={eventTeams}
          approvedEvents={filteredEvents}
        />
      }
    </>
  )
}

export default EventGridContainer
