import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"

import { useHistory, useLocation, useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentLocations, selectCurrentProgram } from "store/program/programSelectors"
import { selectCurrentPermissionLists, selectPermissionCodes, selectSuperUser, selectUserProgramPermissions } from "store/user/userSelectors"
import { refreshUser } from "store/user/userActions"

import { deleteLocation } from "store/program/locationActions"
import { deleteProgram, doSetProgram } from "store/program/programActions"
import themis_common from "store/themis_common_pb"

import Program from './Program'

const ProgramContainer: React.FC = (): ReactElement => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const { programId: inProgramId } = useParams<{ programId: string }>()
  const programId = Number(inProgramId)

  const locations = useSelector((state: IAppState) => selectCurrentLocations(state))
  const program = useSelector((state: IAppState) => selectCurrentProgram(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const permissionLists = useSelector((state: IAppState) => selectCurrentPermissionLists(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const [currentTab, setCurrentTab] = useState(0)
  const hashValues = useMemo(() => ['events', 'locations', 'users', 'event_balance', 'actions'], [])

  const [isLocationOpen, setIsLocationOpen] = useState(false)
  const [isProgramOpen, setIsProgramOpen] = useState(false)
  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const [locationToDelete, setLocationToDelete] = useState<themis_common.Location.AsObject | null>(null)

  useEffect(() => {
    const hash = location.hash.toLowerCase().substr(1)
    if (hash === '') {
      history.replace('#' + hashValues[0])
    }
    const tab = hashValues.indexOf(location.hash.toLowerCase().substr(1))
    if (tab >= 0) setCurrentTab(tab)
  }, [location, hashValues, history])

  // Program view permission, redirect
  // Do we want to redirect?

  // useEffect(() => {
  //   (async () => {
  //     const canViewProgram = validateProgramPermission("PV", superUser, programPermissions, programId)
  //     if (programId > 0 && !canViewProgram) {
  //       await doSetProgram(dispatch, 0)
  //       history.replace('/')
  //     }
  //   })()
  // }, [superUser, programPermissions, programId, history])

  const handleChangeTab = (event: React.ChangeEvent<any> | null, newValue: number) => {
    if (hashValues[newValue]) history.push('#' + hashValues[newValue])
    setCurrentTab(newValue)
  }

  const handleDeleteProgram = async () => {
    await deleteProgram(dispatch, program?.id || 0)
    setIsProgramOpen(false)
    await refreshUser(dispatch)
    await doSetProgram(dispatch, 0)
    history.push("/")
  }

  const handleDeleteLocation = async () => {
    if (!locationToDelete?.id || !programId) return
    await deleteLocation(dispatch, locationToDelete.id, programId)
    setIsLocationOpen(false)
  }

  return ( permissionLists && program && locations && permissionCodes?.denyAccess.length ?
    <Program
      superUser={superUser}
      programPermissions={programPermissions}
      permissionLists={permissionLists}
      programId={programId}
      program={program}
      locations={locations}
      currentTab={currentTab} handleChangeTab={handleChangeTab}
      handleDeleteProgram={handleDeleteProgram}
      handleDeleteLocation={handleDeleteLocation}
      isLocationOpen={isLocationOpen}
      isProgramOpen={isProgramOpen}
      isErrorOpen={isErrorOpen}
      setIsLocationOpen={setIsLocationOpen}
      setIsProgramOpen={setIsProgramOpen}
      setIsErrorOpen={setIsErrorOpen}
      permissionCodes={permissionCodes}
    /> : <></>
  )
}

export default ProgramContainer
