import React, { ReactElement, useEffect, useState } from 'react';
import { useStyles } from 'lib/theme';
import {
    Grid,
    TextField,
    Button,
    IconButton,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import Form4 from './Form4';
import { templateTypes } from 'lib/constants';
import { camelCase } from 'lib/functions';
import { addRubric, addRubricArea, addRubricAreaItems, getProducerJudgeTypeByProducerID, getRubricsByProducerId, updateRubric, updateRubricArea, updateRubricAreaItems } from 'store/producer/eventActions';
import themis_common from "../../../../store/themis_common_pb"
import NotificationSnackbar from 'components/util/NotificationSnackbar';
import { useHistory, useParams } from 'react-router-dom';

const types = [
    {
        label: 'Simple',
        key: 'simple',
    },
    {
        label: 'Coed',
        key: 'coed',
    },
];

const defaultTemplates = [
    {
        label: 'Template-1 (Fill in the blanks)',
        key: 'template-1',
    },
    {
        label: 'Template-2 (Skills)',
        key: 'template-2',
    },
    {
        label: 'Template-3 (Reduction)',
        key: 'template-3',
    },
    {
        label: 'Template-4 (Category Assertion)',
        key: 'template-4',
    }
];

const FormBuilder: React.FC = (): ReactElement => {
    const { producerId: inProducerId, rubricId: inRubricId } = useParams<{ producerId?: string | undefined, rubricId?: string | undefined }>();
    const producerId = Number(inProducerId);
    const rubricId = Number(inRubricId);

    const classes = useStyles();
    const history = useHistory();

    const [formData, setFormData] = useState<any[]>([]);
    const [templateCount, setTemplateCount] = useState<number>(0);
    const [selectedTemplateData, setSelectedTemplateData] = useState([{ templateType: {}, objectName: "", judgeType: {}, cutOffValue: 0, allowNegative: true, maxParticipationDriverThresholdValue: 0 }]);
    const [selectedType, setSelectedType] = useState({ label: "", key: "" });
    const [errorText, setErrorText] = useState<string[]>([]);
    const [maxValueError, setMaxValueError] = useState<string[]>([]);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [rubricName, setRubricName] = useState<string>("");
    const [rubricAreaName, setRubricAreaName] = useState<string>("");
    const [defaultJudgeTypes, setDefaultJudgeTypes] = useState<themis_common.ProducerJudgeType.AsObject[]>([]);
    const [notificationData, setNotificationData] = useState({ isNotificationOpen: false, message: "", severity: "success" });
    const [editRubric, setEditRubric] = useState<any>({});

    const getRubricData = async (producerId: number) => {
        const data = await getRubricsByProducerId(producerId);
        if (data && rubricId) {
            const selectedRubric: any = data?.find((rubric: any) => rubric.id === rubricId);
            setEditRubric(selectedRubric);
        }
        return data;
    }

    useEffect(() => {
        getRubricData(producerId);
    }, [producerId, rubricId]);

    useEffect(() => {
        if (rubricId > 0 && editRubric) {
            const flattenedRubricItems = editRubric?.rubricAreasList?.flatMap((rubricArea: any) => {
                return rubricArea?.rubricAreaItemsList.map((rubricAreaItem: any) => {
                    return rubricAreaItem;
                })
            });

            if (flattenedRubricItems) {
                flattenedRubricItems.sort((a: any, b: any) => a.order - b.order);
            }
            
            const dynamicFormJsonArray = flattenedRubricItems?.map((item: any) => {
                const dynamicFormData = JSON.parse(item.dynamicFormJson);

                // Extracting the first object in the array
                const formDataObject = dynamicFormData[0];
                return formDataObject;
            });
            setFormData(dynamicFormJsonArray);
            setTemplateCount(dynamicFormJsonArray?.length)

            const extractedData = flattenedRubricItems?.map((item: any) => {
                const dynamicFormData = JSON.parse(item.dynamicFormJson);
                // Extracting templateType, objectName, and judgeType
                const { templateName, objectName, judgeType, cutOffValue, allowNegative, maxParticipationDriverThresholdValue } = dynamicFormData[0].data.items[0];
                const template = defaultTemplates.find((item) => item.key === templateName);
                const judge = defaultJudgeTypes?.find((item) => item.name === judgeType);
                return { templateType: template, objectName: objectName, judgeType: judge, cutOffValue: cutOffValue, allowNegative: allowNegative, maxParticipationDriverThresholdValue: maxParticipationDriverThresholdValue };
            });
            setSelectedTemplateData(extractedData);
            setRubricName(editRubric.name);
            if (editRubric && editRubric.rubricAreasList && editRubric.rubricAreasList.length > 0) {
                setRubricAreaName(editRubric.rubricAreasList[0].name);
            }
            if (dynamicFormJsonArray && dynamicFormJsonArray && dynamicFormJsonArray.length > 0) {
                let type = dynamicFormJsonArray[0].data.type;
                setSelectedType({ label: type, key: type })
            }
        }
    }, [editRubric, defaultJudgeTypes]);

    const getJudgeTypes = async (producerId: number) => {
        const data: any = await getProducerJudgeTypeByProducerID(producerId);
        setDefaultJudgeTypes(data)
    }

    useEffect(() => {
        //Get judgeType list for current producer
        getJudgeTypes(producerId);
    }, [producerId])

    const handleReceivedResponse = (response: any, index: number) => {
        setFormData((prevData: any) => {
            const updatedItems: any = [...prevData];
            const updatedItem: any = updatedItems[index];
            updatedItem.data.items[0] = {
                ...updatedItem.data.items[0],
                ...response,
            };
            updatedItems[index] = updatedItem;
            return updatedItems;
        });
    };

    const handleInputChange = (name: string, value: any, index: number) => {
        let selectedValue: any;
        if (name === "judgeType")
            selectedValue = value.name
        else if (name === "objectName")
            selectedValue = camelCase(value)
        else
            selectedValue = value;

        if (name === "objectName" || name === "judgeType" || name === "cutOffValue" || name === "allowNegative" || name === "maxParticipationDriverThresholdValue") {
            setSelectedTemplateData((prevData: any[]) => {
                const updatedData: any[] = [...prevData];
                updatedData[index] = {
                    ...updatedData[index],
                    [name]: value
                };
                return updatedData;
            });
        }

        setFormData((prevData) => {
            const updatedTemplates: any = [...prevData];
            const updatedTemplate = { ...updatedTemplates[index] };
            updatedTemplate.data.items[0][name] = selectedValue;
            updatedTemplates[index] = updatedTemplate;
            return updatedTemplates;
        });
    };

    const handleTypeDropdownChange = (value: any, name: any) => {
        const selectedValue = value ? value.key : null;
        setSelectedType(value);
        setFormData((prevData) => {
            return prevData.map((item) => ({
                ...item,
                data: {
                    ...item.data,
                    [name]: selectedValue
                }
            }));
        });
    };

    const handleAddTemplate = () => {
        setTemplateCount((prevCount) => prevCount + 1);

        setFormData((prevData: any[]) => [
            ...prevData,
            {
                data: {
                    type: selectedType.key,
                    items: [
                        {
                            templateName: "",
                            data: {
                                rows: {
                                    '0': []
                                }
                            }
                        }
                    ]
                }
            }
        ]);
    };

    const handleTemplateTypeDropdownChange = (name: string, value: any, index: number) => {
        const selectedValue = value ? value.key : null;

        setSelectedTemplateData((prevData: any[]) => {
            const updatedData: any[] = [...prevData];
            updatedData[index] = {
                ...updatedData[index],
                [name]: value,
            };
            return updatedData;
        });

        setFormData((prevData: any[]) => {
            const updatedTemplates: any[] = [...prevData];
            const updatedTemplate = { ...updatedTemplates[index] };
            if (selectedValue === templateTypes.TEMPLATE_THREE) {
                updatedTemplate.data.items[0].templateName = selectedValue;
                updatedTemplate.data.items[0].allowNegative = true;
            } else {
                updatedTemplate.data.items[0].templateName = selectedValue;
            }
            updatedTemplates[index] = updatedTemplate;
            return updatedTemplates;
        });
    };

    const handleRemoveTemplate = (index: number) => {
        setTemplateCount((prevCount) => prevCount - 1);

        setSelectedTemplateData((prevData: any) => {
            let updatedTemplate = [...prevData];
            updatedTemplate.splice(index, 1);
            return updatedTemplate;
        });

        setFormData((prevData: any) => {
            const updatedTemplates: any = [...prevData];
            updatedTemplates.splice(index, 1);
            return updatedTemplates;
        });
    };

    const handleEmptyForm = () => {
        setTemplateCount(0);
        setSelectedTemplateData([{ templateType: {}, objectName: "", judgeType: {}, cutOffValue: 0, allowNegative: true, maxParticipationDriverThresholdValue: 0 }]);
        setFormData([]);
        setSelectedType({ label: "", key: "" });
        setErrorText([]);
        setMaxValueError([]);
        setHasErrors(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (rubricId > 0) {
            // Update rubric name
            const rubricData = await updateRubric(producerId, rubricId, rubricName);
            const currentRubricData: any = rubricData.find((item) => item.name === rubricName);

            if (currentRubricData) {
                const currentRubricArea = currentRubricData.rubricAreasList[0];

                // Update rubricArea name
                await updateRubricArea(producerId, currentRubricArea.id, rubricAreaName);

                // Update rubric area item
                if (producerId && currentRubricArea?.id) {
                    try {
                        // Iterate over each item in formData array
                        for (let i = 0; i < formData.length; i++) {
                            const item = formData[i];
                            const label = item.data.items[0].objectName;
                            const dynamicJson = JSON.stringify([item]);  //Make it array of object

                            if (currentRubricArea?.rubricAreaItemsList) {
                                currentRubricArea?.rubricAreaItemsList.sort((a: any, b: any) => a.order - b.order);
                            }
                            
                            const currentRubricAreaItem = currentRubricArea?.rubricAreaItemsList[i];

                            // Make API call for each item
                            const data = await updateRubricAreaItems(producerId, label, dynamicJson, currentRubricArea.id, currentRubricAreaItem.id, currentRubricAreaItem.order);
                        }

                        setNotificationData({
                            isNotificationOpen: true,
                            message: "Your Forms has been updated.",
                            severity: "success"
                        })

                        history.replace(`/Producer/${producerId}` + `#scores`)
                    } catch (error) {
                        // Handle error from API call
                        console.error('Error occurred while saving form:', error);
                        setNotificationData({
                            isNotificationOpen: true,
                            message: "Error occured while saving forms.",
                            severity: "error"
                        })
                    }
                }

            }
        }
        else {
            // Add rubric name
            const rubricData = await addRubric(producerId, rubricName);
            const currentRubricData: any = rubricData.find((item) => item.name === rubricName);

            if (currentRubricData) {
                // Add rubricArea name
                await addRubricArea(producerId, currentRubricData.id, rubricAreaName);

                //Get the latest Rubric data and find current rubric and rubricArea object
                const allRubrics: any = await getRubricData(producerId);
                const currentRubric: any = allRubrics.find((item: any) => item.id === currentRubricData?.id);
                const currentRubricArea = currentRubric.rubricAreasList.find((item: any) => item.name === rubricAreaName);

                // Add rubric area item
                if (producerId && currentRubricArea?.id) {
                    try {
                        // Iterate over each item in formData array
                        for (let i = 0; i < formData.length; i++) {
                            const item = formData[i];
                            const label = item.data.items[0].objectName;
                            const dynamicJson = JSON.stringify([item]);  //Make it array of object

                            // Make API call for each item
                            const data = await addRubricAreaItems(producerId, label, dynamicJson, currentRubricArea?.id, i);
                        }

                        setNotificationData({
                            isNotificationOpen: true,
                            message: "Your Forms has been created.",
                            severity: "success"
                        })

                        handleEmptyForm();

                        history.replace(`/Producer/${producerId}` + `#scores`)
                    } catch (error) {
                        // Handle error from API call
                        console.error('Error occurred while saving form:', error);
                        setNotificationData({
                            isNotificationOpen: true,
                            message: "Error occured while saving forms.",
                            severity: "error"
                        })
                    }
                }
            }

            setRubricName("");
            setRubricAreaName("");
        }
    };

    const handleNotificationClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return
        setNotificationData({
            isNotificationOpen: false,
            message: "",
            severity: "success"
        })
    }

    const renderTemplates = () => {
        const templates: any = [];
        for (let i = 0; i < templateCount; i++) {
            templates.push(
                <Grid container alignItems="center" className={classes.grouperForm} key={i}>
                    <Grid item xs={12} sm={6} md={11} className={classes.rubricScoring}></Grid>
                    <Grid item xs={12} sm={6} md={1} className={classes.rubricScoring}>
                        {!(producerId && rubricId) ? <IconButton onClick={() => handleRemoveTemplate(i)}>
                            <DeleteIcon fontSize="small" />
                        </IconButton> : <></>}
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} className={classes.rubricScoring}>
                        {/* Template field */}
                        <Autocomplete
                            options={defaultTemplates}
                            fullWidth
                            getOptionLabel={(obj: any) => obj.label}
                            disableClearable
                            id={`templateName-${i}`}
                            onChange={(event: any, value: any) => {
                                handleTemplateTypeDropdownChange("templateType", value, i);
                            }}
                            value={selectedTemplateData[i]?.templateType ? selectedTemplateData[i]?.templateType : {}}
                            renderInput={(params: any) => (
                                <TextField
                                    style={{ marginTop: 16, marginBottom: 8 }}
                                    {...params}
                                    autoComplete="none"
                                    label={`Template ${i + 1}`}
                                    name={`template${i}`}
                                    required={true}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} className={classes.rubricScoring}>
                        {/* objectName field */}
                        <TextField
                            variant="outlined"
                            fullWidth
                            required={true}
                            id="objectName"
                            name="objectName"
                            label="Rubric Area Item Name"
                            value={selectedTemplateData[i]?.objectName ? selectedTemplateData[i].objectName : null}
                            onChange={(event: any) => {
                                const newTitle = event.target.value;
                                const errorMessage = (newTitle.trim() === '') ? 'Rubric Area Item Name cannot be empty' : '';
                                setHasErrors(() => errorMessage ? true : false)
                                setErrorText((prevErrors) => ({ ...prevErrors, [i]: errorMessage }));
                                handleInputChange(event.target.name, event.target.value, i);
                            }}
                            className={classes.textBoxStyle}
                            error={!!errorText[i]}
                            helperText={errorText[i]}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} className={classes.rubricScoring}>
                        {/* Judge Type field */}
                        <Autocomplete
                            options={defaultJudgeTypes}
                            fullWidth
                            getOptionLabel={(obj: any) => obj.name}
                            disableClearable
                            id="judgeType"
                            value={selectedTemplateData[i]?.judgeType ? selectedTemplateData[i]?.judgeType : {}}
                            onChange={(event: any, value: any) => {
                                handleInputChange("judgeType", value, i);
                            }}
                            renderInput={(params: any) => (
                                <TextField
                                    style={{ marginTop: 16, marginBottom: 8 }}
                                    {...params}
                                    autoComplete="none"
                                    label="Judge Type"
                                    name="judgeType"
                                    required={true}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    {/* Conditionally render the template component based on the selected template */}
                    {
                        (selectedTemplateData[i]?.templateType === defaultTemplates[0] && <Form1 index={i} responseData={handleReceivedResponse} objectName={selectedTemplateData[i].objectName} formData={formData} rubricId={rubricId} />) ||
                        (selectedTemplateData[i]?.templateType === defaultTemplates[1] && (
                            <>
                                <Grid item xs={12} sm={4} md={4} className={classes.rubricScoring}>
                                    {/* Maximum Value field */}
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        id="maxParticipationDriverThresholdValue"
                                        name="maxParticipationDriverThresholdValue"
                                        label="Max Participation Driver Threshold Value"
                                        value={selectedTemplateData[i]?.maxParticipationDriverThresholdValue ? selectedTemplateData[i].maxParticipationDriverThresholdValue : null}
                                        onChange={(event: any) => {
                                            const value = parseFloat(event.target.value);
                                            const errorMessage = isNaN(value) ? 'Value contains only number values.' : '';
                                            setHasErrors(() => errorMessage ? true : false)
                                            setMaxValueError((prevErrors) => ({ ...prevErrors, [i]: errorMessage }));
                                            handleInputChange(event.target.name, value, i);
                                        }}
                                        className={classes.textBoxStyle}
                                        error={!!maxValueError[i]}
                                        helperText={maxValueError[i]}
                                    />
                                </Grid>
                                <Form2 index={i} responseData={handleReceivedResponse} templateType={selectedType.key} objectName={selectedTemplateData[i].objectName} formData={formData} rubricId={rubricId} />
                            </>)
                        ) ||
                        (selectedTemplateData[i]?.templateType === defaultTemplates[2] && (
                            <>
                                <Grid item xs={12} sm={4} md={4} className={classes.rubricScoring}>
                                    {/* Maximum Value field */}
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required={true}
                                        id="cutOffValue"
                                        name="cutOffValue"
                                        label="Cut off Value"
                                        value={selectedTemplateData[i]?.cutOffValue ? selectedTemplateData[i].cutOffValue : null}
                                        onChange={(event: any) => {
                                            const value = parseFloat(event.target.value);
                                            const errorMessage = isNaN(value) ? 'Cut off value contains only number values.' : '';
                                            setHasErrors(() => errorMessage ? true : false);
                                            setMaxValueError((prevErrors) => ({ ...prevErrors, [i]: errorMessage }));
                                            handleInputChange(event.target.name, value, i);
                                        }}
                                        className={classes.textBoxStyle}
                                        error={!!maxValueError[i]}
                                        helperText={maxValueError[i]}
                                    />
                                </Grid>
                                <FormGroup>
                                    <FormControlLabel
                                        label="Allow Negative"
                                        name="allowNegative"
                                        control={<Checkbox checked={selectedTemplateData[i]?.allowNegative ?? true}
                                            onChange={(event: any) => {
                                                handleInputChange(event.target.name, event.target.checked, i);
                                            }}
                                        />}
                                    />
                                </FormGroup>
                                <Form3 index={i} responseData={handleReceivedResponse} objectName={selectedTemplateData[i].objectName} formData={formData} rubricId={rubricId} />
                            </>)
                        ) ||
                        (selectedTemplateData[i]?.templateType === defaultTemplates[3] && (
                            <Form4 index={i} responseData={handleReceivedResponse} objectName={selectedTemplateData[i].objectName} formData={formData} rubricId={rubricId} />
                        ))
                    }
                </Grid>
            );
        }
        return templates;
    };

    const renderForm = () => {
        return (
            <form onSubmit={handleSubmit}>
                <Grid container className={classes.grouperForm}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring}>
                            {/* Type field */}
                            <Autocomplete
                                options={types}
                                fullWidth
                                getOptionLabel={(obj: any) => obj.label}
                                disableClearable
                                id="type"
                                value={selectedType !== null ? selectedType : undefined}
                                onChange={(event: any, value: any) => {
                                    handleTypeDropdownChange(value, 'type');
                                }}
                                renderInput={(params: any) => (
                                    <TextField
                                        style={{ marginTop: 16, marginBottom: 8 }}
                                        {...params}
                                        autoComplete="none"
                                        label="Rubric Type"
                                        name="type"
                                        required={true}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring}>
                            {/* Rubric field */}
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                id="rubricName"
                                name="rubricName"
                                label="Rubric Name"
                                value={rubricName}
                                onChange={(event: any) => { setRubricName(event.target.value) }}
                                className={classes.textBoxStyle}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring}>
                            {/* Rubric Area field */}
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                id="rubricAreaName"
                                name="rubricAreaName"
                                label="Rubric Area Name"
                                value={rubricAreaName}
                                onChange={(event: any) => { setRubricAreaName(event.target.value) }}
                                className={classes.textBoxStyle}
                            />
                        </Grid>
                        {/* } */}
                    </Grid>

                    <Grid container>
                        {/* Template Type field */}
                        <Typography variant="h2">
                            Rubric Area Item
                            {!(producerId && rubricId) ? <IconButton className={classes.clickable} aria-label="add" onClick={handleAddTemplate}>
                                <AddIcon fontSize="small" />
                            </IconButton> : <></>}
                        </Typography>
                    </Grid>

                    <Grid container>{renderTemplates()}</Grid>

                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            {/* Submit ButtonType field */}
                            <Button type="submit" className={classes.divisionSubmit} style={{ marginTop: '10px' }} disabled={hasErrors}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    };

    return (
        <>
            {renderForm()}
            {/* If you want to see JSON in UI. Please uncomment below code */}
            {/* <hr className={classes.horizontalLine} style={{ width: '100%' }} />

            <Grid container>
                <Grid item xs={12} sm={6} md={5} className={classes.grouperForm}>
                    <h1>JSON</h1>
                    <pre>{JSON.stringify(formData, null, 2)}</pre>
                </Grid>
            </Grid> */}

            <NotificationSnackbar
                isOpen={notificationData.isNotificationOpen}
                onClose={handleNotificationClose}
                message={notificationData.message}
                severity={notificationData.severity as "warning" | "success" | "error" | "info"}
                autoHideDuration={3000}
            />
        </>
    );
};

export default FormBuilder;