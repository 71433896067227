import React, { useState, ReactElement, CSSProperties } from "react"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { StripeCardElement, StripeCardElementChangeEvent } from "@stripe/stripe-js"
import { Button, CircularProgress } from '@material-ui/core'

interface CreditCardFormProps {
  buttonText: string
  successText: string
  handleCreateCardElement: (stripeCardElement: StripeCardElement) => Promise<string>
}

const CreditCardForm: React.FC<CreditCardFormProps> = ({ buttonText, successText, handleCreateCardElement }): ReactElement => {
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState("")
  const [processing, setProcessing] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const stripe = useStripe()
  const elements = useElements()

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  }

  const formStyle: CSSProperties = {
    // width: "50vw", // Set this in parent
    alignSelf: "center",
    boxShadow: "0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07)",
    borderRadius: "7px",
    padding: "40px",
  }

  const resultMessageStyle: CSSProperties = {
    lineHeight: "22px",
    fontSize: "16px",
  }

  const hiddenStyle: CSSProperties = {
    display: "none",
  }

  const cardErrorStyle: CSSProperties = {
    color: "rgb(105, 115, 134)",
    fontSize: "16px",
    lineHeight: "20px",
    marginTop: "12px",
    textAlign: "center",
  }

  const cardElementStyle: CSSProperties = {
    borderRadius: '4px 4px 0 0',
    padding: '12px',
    border: '1px solid rgba(50, 50, 93, 0.1)',
    maxHeight: '44px',
    width: '100%',
    background: 'white',
    boxSizing: 'border-box',
  }

  const buttonStyle = {
    // background: '#5469d4',
    fontFamily: 'Arial, sans-serif',
    color: '#ffffff',
    borderRadius: '0 0 4px 4px',
    border: 0,
    padding: '12px 16px',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer',
    display: 'block',
    transition: 'all 0.2s ease',
    boxShadow: '0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)',
    width: '100%',
    "&:hover": {
      filter: 'contrast(115 %)',
    },
    "&::disabled": {
      opacity: 0.5,
      cursor: 'default',
    },
  }

  const handleChange = async (event: StripeCardElementChangeEvent) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }

  const handleSubmit = async (): Promise<void> => {
    setProcessing(true)
    const cardElements = elements?.getElement(CardElement)
    if (stripe && cardElements) {
      const errorMessage = await handleCreateCardElement(cardElements)
      if (errorMessage.length > 0) {
        setError(`${errorMessage}`)
        setProcessing(false)
      } else {
        setError("")
        setProcessing(false)
        setSucceeded(true)
      }
    }
  }

  return (
    <form id="credit-card-form" style={formStyle}>
      <div style={cardElementStyle} >
        <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      </div>
      <Button
        disabled={processing || disabled || succeeded}
        style={buttonStyle}
        id="submit"
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}
      >
        <span id="button-text">
          {processing ? (
            <CircularProgress color="secondary" size={20}/>
          ) : buttonText}
        </span>
      </Button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div style={cardErrorStyle} role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p style={succeeded ? resultMessageStyle : { ...resultMessageStyle, ...hiddenStyle }}>
        {successText}
      </p>
    </form>
  )
}

export default CreditCardForm
