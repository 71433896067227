import React, { ReactElement, useMemo, useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Box, Button, Container, Grid, IconButton, Paper, TextField,
  Typography, Hidden, Tooltip, useTheme, Checkbox
} from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Remove as RemoveIcon, Warning as WarningIcon } from '@material-ui/icons'
import { useStyles } from "lib/theme"
import themis_common, { Athlete } from "store/themis_common_pb"
import { Link as RouterLink } from "react-router-dom"
import { sortAthletes, textGender } from "lib/functions"
import YesNoDialog from 'components/util/YesNoModal'
import { validateLocationPermission, validateTeamPermission } from 'lib/permissions'
import { ProgramPermissions } from 'store/user/userReducer'
import { validateAthleteOnDivision } from "lib/validators"


interface athleteGridProps {
  programId: number,
  locationId: number,
  permissionCodes: themis_common.PermissionCodes.AsObject,
  superUser: boolean,
  teamId?: number,
  athletes?: themis_common.Athlete.AsObject[],
  locationAthletes?: themis_common.Athlete.AsObject[],
  programPermissions?: ProgramPermissions,
  handleDeleteAthlete?: (athleteId: number) => void,
  currentTeam?: themis_common.Team.AsObject,
  currentLocation?: themis_common.Location.AsObject,
  handleAddAthleteDropdown?: (athleteId: number) => void
  handleAddAthletesToTeam?: () => void
  athletesToAdd?: themis_common.Athlete.AsObject[]
  setAthletesToAdd?: React.Dispatch<React.SetStateAction<themis_common.Athlete.AsObject[]>>
}

const AthleteGrid: React.FC<athleteGridProps> = ({
  programId, locationId, teamId, athletes, locationAthletes, superUser, programPermissions,
  handleDeleteAthlete, currentTeam, currentLocation, handleAddAthleteDropdown, permissionCodes,
  handleAddAthletesToTeam, athletesToAdd, setAthletesToAdd
}): ReactElement => {
  const classes = useStyles()
  const theme = useTheme()

  const [athleteToDelete, setAthleteToDelete] = useState<themis_common.Athlete.AsObject | null>(null)
  const [rerenderAutocomplete, setRerenderAutoComplete] = useState(false)
  const [primaryDivision, setPrimaryDivision] = useState<themis_common.Division.AsObject | undefined>(undefined)

  // Sets the team primary division
  // TODO: Change scoringAuthoritiesList to a more fitting name
  useEffect(() => {
    const pDivision = currentTeam?.scoringAuthoritiesList.find(division => division.primary === true)
    setPrimaryDivision(pDivision?.division)
  }, [currentTeam, primaryDivision])

  const availableAthletes = useMemo(() => {
    if (!locationAthletes || !athletes) return
    return locationAthletes.filter((lAthlete: themis_common.Athlete.AsObject) => !athletes.find((athlete: themis_common.Athlete.AsObject) => athlete.id === lAthlete.id))
  }, [athletes, locationAthletes])

  const addAthleteIcon = useMemo(() => {
    if (programPermissions) {
      if (
        (!teamId && validateLocationPermission(permissionCodes.athleteAdd, permissionCodes, superUser, programPermissions, programId, locationId)) ||
        (teamId && validateTeamPermission(permissionCodes.athleteAdd, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
      ) {
        if (teamId && teamId > 0) {
          return (<IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={`/Location/${locationId}/Team/${teamId}/AddAthlete`}>
            <AddIcon fontSize="default" />
          </IconButton>)
        }
        return (
          <IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={`/Location/${locationId}/AddAthlete`}>
            <AddIcon fontSize="default"/>
          </IconButton>
        )
      } return []
    }
  }, [classes.clickable, locationId, programPermissions, programId, superUser, teamId, permissionCodes])

  const importAthletesButton = useMemo(() => {
    if (programPermissions) {
      if (
        (!teamId && validateLocationPermission(permissionCodes.athleteAdd, permissionCodes, superUser, programPermissions, programId, locationId)) ||
        (teamId && validateTeamPermission(permissionCodes.athleteAdd, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
      ) {
        if (teamId && teamId > 0) {
          return (<Button
            id="submit"
            className={classes.submit}
            type="submit"
            fullWidth
            component={RouterLink} to={`/Location/${locationId}/Team/${teamId}/AddTeamAthletes`}
          >
            Import Additional Athletes From CSV
          </Button>)
        }
      } return <></>
    }
  }, [classes.submit, locationId, programPermissions, programId, superUser, teamId, permissionCodes])


  const gridHeader = useMemo(() => {
    return (
      <Grid container spacing={3} className={classes.personGridRow}>
        <Hidden only={['xs']}>
          <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
            <Paper className={classes.paperGridItem}>&nbsp;</Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={4} md={3} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>Name</Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>Birth Date</Paper>
        </Grid>
        <Hidden only={['xs', 'md', 'lg', 'xl']}>
          <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
            <Paper className={classes.paperGridItem}></Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={4} md={1} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>Gender</Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>City</Paper>
        </Grid>
      </Grid>
    )
  }, [classes.personGridRow, classes.paperGridItem, classes.personGridHeader])

  const sortedAthletes = useMemo<Athlete.AsObject[]>(() => athletes ? sortAthletes(athletes) : [], [athletes])

  const [isOpen, setIsOpen] = useState(false)
  const handleDialogOpen = () => {
    setIsOpen(true)
  }
  const handleDialogClose = () => {
    setIsOpen(false)
  }

  function handleDelete() {
    if (!athleteToDelete?.id || !handleDeleteAthlete) return
    handleDeleteAthlete(athleteToDelete.id)
    handleDialogClose()
  }

  const userCanViewAthletes = useMemo(() => {
    if (programPermissions) {
      if (!teamId) return validateLocationPermission(permissionCodes.athleteView, permissionCodes, superUser, programPermissions, programId, locationId)
      if (teamId) return validateTeamPermission(permissionCodes.athleteView, permissionCodes, superUser, programPermissions, programId, locationId, teamId)
    }
  }, [superUser, programPermissions, programId, locationId, teamId, permissionCodes])

  const removeName = useMemo(() => currentTeam?.name ? currentTeam.name : currentLocation?.name, [currentLocation, currentTeam])
  const deleteAthleteTitle = useMemo(() => currentLocation ? `Remove ${athleteToDelete?.name} entirely from ${removeName}?` : `Delete ${athleteToDelete?.name}?`, [currentLocation, removeName, athleteToDelete?.name])
  const deleteAthleteQuestion = useMemo(() => currentLocation ? `This cannot be undone. Are you sure?`: `Are you sure you want to remove ${athleteToDelete?.name} from ${removeName}?`, [currentLocation, removeName, athleteToDelete?.name])

  const theGrid = useMemo(() => {
    if (!sortedAthletes) return (<></>)
    return userCanViewAthletes ? (
      <Container component={Paper}>
        {gridHeader}
        {sortedAthletes.map((athlete) => {
          let gender = athlete.person?.gender
          if (gender === undefined || gender === null) gender = 3
          const athleteLink = teamId ? `/Location/${locationId}/Team/${teamId}/Athlete/${athlete.id}` : `/Location/${locationId}/Athlete/${athlete.id}`
          const editAthleteLink = teamId ? `/Location/${locationId}/Team/${teamId}/EditAthlete/${athlete?.id}` : `/Location/${locationId}/EditAthlete/${athlete?.id}`

          let athleteDivisionResult = ""
          if (primaryDivision?.divisionRestriction) athleteDivisionResult = validateAthleteOnDivision(athlete, primaryDivision.divisionRestriction)

          return (
            <Grid container spacing={1} className={classes.personGridRow} key={athlete.id}>
              <Grid item xs={12} sm={4} md={2} className={classes.personGridItem}>
                <Paper style={{ boxShadow: "none", textAlign: "center" }}>
                  {
                    (!teamId && programPermissions && validateLocationPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
                    (teamId && programPermissions && validateTeamPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
                      ?
                      (<IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={editAthleteLink}>
                        <EditIcon className={classes.personGridButton} />
                      </IconButton>)
                      : []
                  }
                  {
                    (!teamId && programPermissions && validateLocationPermission(permissionCodes.athleteDelete, permissionCodes, superUser, programPermissions, programId, locationId)) ||
                    (teamId && programPermissions && validateTeamPermission(permissionCodes.athleteDelete, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
                      ?
                      (<IconButton className={classes.clickable} aria-label="delete" onClick={e => {setAthleteToDelete(athlete); handleDialogOpen()}}>
                        {currentLocation ?
                          <DeleteIcon className={classes.personGridButton} /> : <RemoveIcon className={classes.personGridButton} />
                        }
                      </IconButton>)
                      : []
                  }
                  {/* These warnings compare an athlete against the team's primary division -- we only want these on a team athlete list for now */}
                  { !currentLocation && primaryDivision?.divisionRestriction ? (athleteDivisionResult.length > 0 ?
                    <Tooltip title={athleteDivisionResult} aria-label={athleteDivisionResult}>
                      <WarningIcon style={{ color: theme.palette.warning.light, marginLeft: 5 }} fontSize="small" />
                    </Tooltip>
                    : []) : [] }
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4} md={3} className={classes.personGridItem}>
                {athlete.person?.usasfProfilePicture ?
                  (
                    <Grid container style={{ justifyContent: "center" }}>
                      <Grid item md={7}>
                        <Paper className={classes.paperGridItem}>
                          <RouterLink to={athleteLink} className={classes.cleanLink}>
                            {athlete.name}{athlete.person.nickName ? ' (' + athlete.person.nickName + ')' : ''}
                          </RouterLink>
                        </Paper>
                      </Grid>
                      <Hidden smDown>
                        <Grid item md={4} style={{ paddingLeft: "15px" }}>
                          {athlete.person.usasfProfilePicture ?
                            (<RouterLink to={athleteLink} className={classes.cleanLink}>
                              <img src={athlete.person.usasfProfilePicture} style={{ width: "50px", border: "1px solid red" }} alt={`${athlete.person.name} USASF Profile`} />
                            </RouterLink>) : <></>}
                        </Grid>
                      </Hidden>
                    </Grid>
                  ) :
                  <Paper className={classes.paperGridItem}>
                    <RouterLink to={athleteLink} className={classes.cleanLink}>
                      {athlete.name}{athlete.person?.nickName ? ' (' + athlete.person.nickName + ')' : ''}
                    </RouterLink>
                  </Paper>
                }
              </Grid>
              <Grid item xs={12} sm={4} md={3} className={classes.personGridItem}>
                <div className={classes.paperGridItem}>
                  {athlete.person?.birthDate ? format(parseISO(athlete.person.birthDate), 'MMM dd, yyyy') : ""}
                </div>
              </Grid>
              <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
                  <Paper className={classes.paperGridItem}></Paper>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={4} md={1} className={classes.personGridItem}>
                <Paper className={classes.paperGridItem}>
                  {textGender(gender)}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4} md={3} className={classes.personGridItem}>
                <Paper className={classes.paperGridItem}>
                  {athlete.person?.city}
                </Paper>
              </Grid>
            </Grid>
          )})}
      </Container>
    ) : <></>
  }, [gridHeader, classes, locationId, sortedAthletes, programPermissions, programId, teamId, superUser, userCanViewAthletes, permissionCodes, primaryDivision, currentLocation])

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item>
          <Typography style={{ marginRight: "25px" }} variant="h2">
            Athletes
          </Typography>
        </Grid>
        <Grid item>
          {addAthleteIcon}
        </Grid>
        {(!availableAthletes || availableAthletes.length === 0) ?
          <></>
          : (
            <>
              <Autocomplete
                value={athletesToAdd}
                multiple
                id="add-multiple-athletes"
                limitTags={1}
                options={availableAthletes}
                disableCloseOnSelect
                getOptionLabel={(athlete: themis_common.Athlete.AsObject) => (athlete.name || 'No Name Found') + ' ' + (athlete.person?.nickName ? ' (' + athlete.person?.nickName + ')' : '')}
                style={{ width: 300 }}
                renderOption={(athlete, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      checked={selected}
                      size="small"
                    />
                    {athlete.person?.name}
                  </React.Fragment>
                )}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select Athletes to Add" />
                )}
                onChange={(e, v) => {
                  if (setAthletesToAdd) setAthletesToAdd(v)
                }}
                disabled={!(programPermissions && validateTeamPermission(permissionCodes.athleteAdd, permissionCodes, superUser, programPermissions, programId, locationId, teamId || 0))}
              />
              {programPermissions && validateTeamPermission(permissionCodes.athleteAdd, permissionCodes, superUser, programPermissions, programId, locationId, teamId || 0) ?
                <Box ml={1} mr={1}>
                  <Button className={classes.submit} onClick={() => {if (handleAddAthletesToTeam) handleAddAthletesToTeam()}}>Add Athletes</Button>
                </Box> : <></>
              }
            </>
          )
        }
        <Grid item>
          {importAthletesButton}
        </Grid>
        {theGrid}
      </Grid>
      <YesNoDialog
        title={deleteAthleteTitle}
        question={deleteAthleteQuestion}
        isOpen={isOpen}
        onClose={handleDialogClose}
        buttonActions={[
          { name: "No", color: "primary", callback: handleDialogClose },
          { name: "Yes", color: "primary", callback: handleDelete },
        ]}
      />
    </Container>)
}

export default AthleteGrid
