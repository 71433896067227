import React, { ReactElement } from 'react'
import { IconButton } from '@material-ui/core'
import {
  CheckBoxOutlineBlank as NotChecked,
  CheckBoxOutlined as Checked,
  IndeterminateCheckBoxOutlined as SomeChecked,
} from '@material-ui/icons'
import { useStyles } from "lib/theme"
import { CheckboxOptions } from "../../store/program/programReducer"

interface CheckboxMultiProps { checkboxId: string, checked: CheckboxOptions, onClick: Function, disabled: boolean}

const CheckboxMulti: React.FC<CheckboxMultiProps> = ({ checkboxId, checked, onClick, disabled }): ReactElement => {
  const classes = useStyles()

  let ariaLabel = ""
  let TheIcon = NotChecked

  switch (checked) {
    case 0:
      TheIcon = NotChecked
      ariaLabel = "Unchecked"
      break
    case 1:
      TheIcon = SomeChecked
      ariaLabel = "Some Checked"
      break
    case 2:
      TheIcon = Checked
      ariaLabel = "Checked"
      break
  }

  return <IconButton
    key={checkboxId}
    aria-label={ariaLabel}
    onClick={(e) => {onClick(checkboxId)}}
    disabled={disabled}
    className={classes.clickable}
  ><TheIcon /></IconButton>
}

export default CheckboxMulti
