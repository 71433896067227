import React, { ReactElement, useMemo, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Container, Grid, Hidden, IconButton,
  Paper, TextField, Typography,
} from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Remove as RemoveIcon } from '@material-ui/icons'
import { useStyles } from "lib/theme"
import themis_common from "store/themis_common_pb"
import { Link as RouterLink } from "react-router-dom"
import YesNoDialog from "components/util/YesNoModal"
import { validateLocationPermission, validateTeamPermission } from 'lib/permissions'
import { ProgramPermissions } from 'store/user/userReducer'

interface personnelGridProps {
  programId: number,
  locationId: number,
  permissionCodes: themis_common.PermissionCodes.AsObject
  personnel: themis_common.Personnel.AsObject[] | undefined,
  superUser: boolean,
  teamId?: number,
  locationPersonnels?: themis_common.Personnel.AsObject[],
  programPermissions?: ProgramPermissions,
  handleDeletePersonnel?: (personnelId: number) => void,
  handleAddPersonnelDropdown?: (personnelId: number) => void
  currentTeam?: themis_common.Team.AsObject
  currentLocation?: themis_common.Location.AsObject,
}

const PersonnelGrid: React.FC<personnelGridProps> = ({
  personnel, programId, locationId, teamId, locationPersonnels, superUser, programPermissions,
  handleDeletePersonnel, handleAddPersonnelDropdown, permissionCodes, currentTeam, currentLocation
}): ReactElement => {
  const classes = useStyles()

  const [personnelToDelete, setPersonnelToDelete] = useState<themis_common.Personnel.AsObject | null>(null)
  const [rerenderAutocomplete, setRerenderAutoComplete] = useState(false)

  const availablePersonneles = useMemo(() => {
    if (!locationPersonnels || !personnel) return
    return locationPersonnels.filter((lPersonnel: themis_common.Personnel.AsObject) => !personnel.find((personnel: themis_common.Personnel.AsObject) => personnel.id === lPersonnel.id))
  }, [personnel, locationPersonnels])

  const addPersonnelIcon = useMemo(() => {
    if (programPermissions) {
      if (
        (!teamId && validateLocationPermission(permissionCodes.personnelAdd, permissionCodes, superUser, programPermissions, programId, locationId)) ||
        (teamId && validateTeamPermission(permissionCodes.personnelAdd, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
      ) {
        if (teamId && teamId > 0) {
          return (<IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={`/Location/${locationId}/Team/${teamId}/AddPersonnel`}>
            <AddIcon fontSize="small" />
          </IconButton>)
        }
        return (
          <IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={`/Location/${locationId}/AddPersonnel`}>
            <AddIcon fontSize="small" />
          </IconButton>
        )
      } else {
        return []
      }
    }
  }, [classes.clickable, locationId, programPermissions, programId, superUser, teamId, permissionCodes])

  const gridHeader = useMemo(() => {
    return (
      <Grid container spacing={3} className={classes.personGridRow}>
        <Hidden only={['xs']}>
          <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
            <Paper className={classes.paperGridItem}>&nbsp;</Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={4} md={4} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>Name</Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>Birth Date</Paper>
        </Grid>
        <Hidden only={['xs', 'md', 'lg', 'xl']}>
          <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
            <Paper className={classes.paperGridItem}>&nbsp;</Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={4} md={3} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>City</Paper>
        </Grid>
      </Grid>
    )
  }, [classes.personGridRow, classes.personGridHeader, classes.paperGridItem])

  const sortedPersonneles = useMemo(() => {
    if (!personnel) return []
    const sortPersonneles = [...personnel]
    sortPersonneles.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    return sortPersonneles
  }, [personnel])

  const [isOpen, setIsOpen] = useState(false)
  const handleDialogOpen = () => {
    setIsOpen(true)
  }
  const handleDialogClose = () => {
    setIsOpen(false)
  }

  function handleDelete() {
    if (!personnelToDelete?.id || !handleDeletePersonnel) return
    handleDeletePersonnel(personnelToDelete.id)
    handleDialogClose()
  }

  const userCanViewPersonnel = useMemo(() => {
    if (!teamId && programPermissions) return validateLocationPermission(permissionCodes.personnelView, permissionCodes, superUser, programPermissions, programId, locationId)
    if (teamId && programPermissions) return validateTeamPermission(permissionCodes.personnelView, permissionCodes, superUser, programPermissions, programId, locationId, teamId)
  }, [superUser, programPermissions, programId, locationId, teamId, permissionCodes])

  const removeName = useMemo(() => currentTeam?.name ? currentTeam.name : currentLocation?.name, [currentLocation, currentTeam])
  const deletePersonnelTitle = useMemo(() => currentLocation ? `Remove ${personnelToDelete?.name} entirely from ${removeName}?` : `Delete ${personnelToDelete?.name}?`, [currentLocation, removeName, personnelToDelete?.name])
  const deletePersonnelQuestion = useMemo(() => currentLocation ? `This cannot be undone. Are you sure?`: `Are you sure you want to remove ${personnelToDelete?.name} from ${removeName}?`, [currentLocation, removeName, personnelToDelete?.name])

  const theGrid = useMemo(() => {
    if (!sortedPersonneles) return (<></>)
    return userCanViewPersonnel ? (
      <Container component={Paper}>
        {gridHeader}
        {sortedPersonneles.map((personnel) => {
          const personnelLink = teamId ? `/Location/${locationId}/Team/${teamId}/Personnel/${personnel.id}` : `/Location/${locationId}/Personnel/${personnel.id}`
          const editPersonnelLink = teamId ? `/Location/${locationId}/Team/${teamId}/EditPersonnel/${personnel?.id}` : `/Location/${locationId}/EditPersonnel/${personnel?.id}`
          return (
            <Grid container spacing={2} className={classes.personGridRow} key={personnel.id}>
              <Grid item xs={12} sm={4} md={2} className={classes.personGridItem}>
                <Paper style={{ boxShadow: "none", textAlign: "center" }}>
                  {
                    (!teamId && programPermissions && validateLocationPermission(permissionCodes.personnelEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
                    (teamId && programPermissions && validateTeamPermission(permissionCodes.personnelEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
                      ?
                      (<IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={editPersonnelLink}>
                        <EditIcon className={classes.personGridButton} />
                      </IconButton>)
                      : []
                  }
                  {
                    (!teamId && programPermissions && validateLocationPermission(permissionCodes.personnelDelete, permissionCodes, superUser, programPermissions, programId, locationId)) ||
                    (teamId && programPermissions && validateTeamPermission(permissionCodes.personnelDelete, permissionCodes, superUser, programPermissions, programId, locationId, teamId))                        ?
                      (<IconButton className={classes.clickable} aria-label="delete" onClick={_ => { setPersonnelToDelete(personnel); handleDialogOpen() }}>
                        {currentLocation ?
                          <DeleteIcon className={classes.personGridButton} /> : <RemoveIcon className={classes.personGridButton} />
                        }
                      </IconButton>)
                      : []
                  }
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.personGridItem}>
                { personnel.person?.usasfProfilePicture ?
                  (<Grid container style={{ justifyContent: "center" }}>
                    <Grid item md={8}>
                      <Paper className={classes.paperGridItem}>
                        <RouterLink to={personnelLink} className={classes.cleanLink}>
                          {personnel.name}{personnel.person?.nickName ? ' (' + personnel.person.nickName + ')' : ''}
                        </RouterLink>
                      </Paper>
                    </Grid>
                    <Hidden smDown>
                      <Grid item md={4}>
                        {personnel.person?.usasfProfilePicture ?
                          (<RouterLink to={personnelLink} className={classes.cleanLink}>
                            <img src={personnel.person.usasfProfilePicture} style={{ width: "50px", border: "1px solid red" }} alt={`${personnel.person.name} USASF Profile`} />
                          </RouterLink>) : <></>}
                      </Grid>
                    </Hidden>
                  </Grid>
                  ) :
                  <Paper className={classes.paperGridItem}>
                    <RouterLink to={personnelLink} className={classes.cleanLink}>
                      {personnel.name}{personnel.person?.nickName ? ' (' + personnel.person.nickName + ')' : ''}
                    </RouterLink>
                  </Paper>
                }
              </Grid>
              <Grid item xs={12} sm={4} md={3} className={classes.personGridItem}>
                <Paper className={classes.paperGridItem}>
                  {personnel.person?.birthDate ? format(parseISO(personnel.person.birthDate), 'MMM dd, yyyy') : ""}
                </Paper>
              </Grid>
              <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
                  <Paper className={classes.paperGridItem}></Paper>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={4} md={3} className={classes.personGridItem}>
                <Paper className={classes.paperGridItem}>
                  {personnel.person?.city}
                </Paper>
              </Grid>
            </Grid>
          )
        })}
      </Container>
    ) : <></>
  }, [gridHeader, classes, locationId, sortedPersonneles, teamId, programPermissions, programId, superUser, userCanViewPersonnel, permissionCodes])

  return (
    <Container>
      <Grid container>
        <Typography style={{ marginRight: "25px" }} variant="h2">
          Personnel
        </Typography>
        {(!availablePersonneles || availablePersonneles.length === 0) ?
          <></>
          : (
            <Autocomplete
              key={rerenderAutocomplete ? '1' : '0'}
              id="Add_New_Team"
              size="small"
              options={availablePersonneles}
              getOptionLabel={(personnel: themis_common.Personnel.AsObject) => (personnel.name || 'No Name Found') + ' ' + (personnel.person?.nickName ? ' (' + personnel.person?.nickName + ')' : '')}
              style={{ width: 300, marginBottom: "15px" }}
              renderInput={(params) => <TextField {...params} label="Add Personnel" variant="outlined" />}
              onChange={(e, v) => {
                if (handleAddPersonnelDropdown) {
                  v && handleAddPersonnelDropdown(v?.id)
                  setRerenderAutoComplete(!rerenderAutocomplete)
                }
              }}
              disabled={!(programPermissions && validateTeamPermission(permissionCodes.personnelAdd, permissionCodes, superUser, programPermissions, programId, locationId, teamId || 0))}
            />)
        }
        {addPersonnelIcon}
        {theGrid}
      </Grid>
      <YesNoDialog
        title={deletePersonnelTitle}
        question={deletePersonnelQuestion}
        isOpen={isOpen}
        onClose={handleDialogClose}
        buttonActions={[
          { name: "No", color: "primary", callback: handleDialogClose },
          { name: "Yes", color: "primary", callback: handleDelete },
        ]}
      />
    </Container>)
}

export default PersonnelGrid
