import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import {
  CheckBoxOutlineBlank as NotChecked,
  CheckBoxOutlined as Checked,
  IndeterminateCheckBoxOutlined as SomeChecked,
  ChevronRight as Closed,
  ExpandMore as Open,
} from '@material-ui/icons'
import { useStyles } from "lib/theme"
import themis_common from "store/themis_common_pb"
import { updatePermissions } from "store/user/userActions"
import { ObjectUserPermissions } from "store/program/programReducer"
import { useDispatch, useSelector } from "react-redux"
import { Typography, IconButton, Grid, Collapse } from "@material-ui/core"
import { capitalizeFirstLetter } from "lib/validators"
import {
  validateBrandPermission, validateEventPermission,
  validateLocationPermission,
  validateProducerPermission,
  validateProgramPermission,
  validateTeamPermission
} from 'lib/permissions'
import { IAppState } from 'store/store'
import { selectPermissionCodes, selectSuperUser, selectUserProducerPermissions, selectUserProgramPermissions } from 'store/user/userSelectors'
import HelpIcon from 'components/util/HelpIcon'


interface userGridProps {
  objectId: number,
  type: string,
  superObjectId?: number,
  superDuperObjectId?: number
  permissions: themis_common.Permission.AsObject[],
  currentUser: ObjectUserPermissions,
  refreshPermissions: Function
}

interface permissionGroup {
  title: string,
  status: number,
  permissions: { code: string, title: string, userHas: boolean }[]
}

const UserGridDetails: FunctionComponent<userGridProps> = ({
  objectId,
  type,
  permissions ,
  superObjectId,
  superDuperObjectId ,
  currentUser,
  refreshPermissions
}) => {

  const classes = useStyles()
  const dispatch = useDispatch()

  const [showDetail, setShowDetail] = useState(-1)
  const [showThis, setShowThis] = useState<boolean[]>([false, false, false])
  const [renderThis, setRenderThis] = useState<number>(0)

  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const producerPermissions = useSelector((state: IAppState) => selectUserProducerPermissions(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const doSetShowThis = useCallback((i: number, value: boolean) => {
    const showIt = showThis
    if (!value) setShowDetail(-1)
    else {
      showIt[showDetail] = false
      setShowDetail(i)
    }
    showIt[i] = value
    setShowThis(showIt)
    setRenderThis(renderThis+1)
  }, [renderThis, showDetail, showThis])

  const canEditUser = useMemo((): boolean => {
    if (
      (type === "program" && validateProgramPermission(permissionCodes.programUserEdit, permissionCodes, superUser, programPermissions, objectId)) ||
      (type === "location" && validateLocationPermission(permissionCodes.locationUserEdit, permissionCodes, superUser, programPermissions, superObjectId || 0, objectId)) ||
      (type === "team" && validateTeamPermission(permissionCodes.teamUserEdit, permissionCodes, superUser, programPermissions,superDuperObjectId || 0, superObjectId || 0, objectId)) ||
      (type === "producer" && validateProducerPermission(permissionCodes.producerUserEdit, permissionCodes, superUser, producerPermissions, objectId)) ||
      (type === "brand" && validateBrandPermission(permissionCodes.brandUserEdit, permissionCodes, superUser, producerPermissions, superObjectId || 0, objectId)) ||
      (type === "event" && validateEventPermission(permissionCodes.eventUserEdit, permissionCodes, superUser, producerPermissions,superDuperObjectId || 0, superObjectId || 0, objectId))
    ) {
      return true
    } return false
  }, [programPermissions, superUser, objectId, superObjectId, superDuperObjectId, type, permissionCodes, producerPermissions])

  const addViews = useCallback((permList: themis_common.Permission.AsObject[]): themis_common.Permission.AsObject[] => {
    // Create list of all nouns
    const nounList = permList.reduce<string[]>((nounList, perm) => {
      const noun = perm.code.substr(0,1)
      const verb = perm.code.substr(1,1)
      if (!nounList.includes(perm.code.substr(0,1)) && verb !== "V" && verb !== "S" && verb !== "X") nounList.push(noun)
      return nounList
    }, [])
    const neededList: string[] = nounList.reduce((currentList: string[], noun) => {
      const isView = permList.reduce((value: boolean, current) => {
        if (current.code === `${noun}V`) return true
        return value
      }, false)
      if (!isView) currentList.push(`${noun}V`)
      return currentList
    }, [])

    neededList.forEach(code => {
      const viewPerm = permissions.find(perm => perm.code === code)
      if (viewPerm) permList.push(viewPerm)
    })

    return permList
  }, [permissions])

  const addPermission = useCallback(async (permissionToAdd: themis_common.Permission.AsObject) => {
    const verb = permissionToAdd.code.substr(1)
    let currentPermissions = [...currentUser.permissionsList]
    if (verb !== "S" && verb !== "X") { // If we are adding a permission then remove S or X verbs
      currentPermissions = currentPermissions.filter(perm => perm.code.substr(1) !== 'S' && perm.code.substr(1) !== 'X')
    }
    currentPermissions.push(permissionToAdd)
    currentPermissions = addViews(currentPermissions)
    await updatePermissions(dispatch, type, objectId, currentUser.user.id, currentPermissions, superObjectId, superDuperObjectId)
    await refreshPermissions()
  }, [currentUser.permissionsList, currentUser.user.id, dispatch, objectId, refreshPermissions, superDuperObjectId, superObjectId, type, addViews])

  const removePermission = useCallback(async (permissionToRemove: themis_common.Permission.AsObject) => {
    const currentPermissions = [...currentUser.permissionsList]
    let updatedPermissions = currentPermissions.filter((perm) => perm.code !== permissionToRemove.code)
    updatedPermissions = addViews(updatedPermissions)
    await updatePermissions(dispatch, type, objectId, currentUser.user.id, updatedPermissions, superObjectId, superDuperObjectId)
    await refreshPermissions()
  }, [currentUser.permissionsList, currentUser.user.id, dispatch, objectId, refreshPermissions, superDuperObjectId, superObjectId, type, addViews])

  const removeGroupPermissions = useCallback( async (codeList: string[]) => {
    const updatedPermissions = currentUser.permissionsList.filter((perm) => !codeList.includes(perm.code))
    await updatePermissions(dispatch, type, objectId, currentUser.user.id, updatedPermissions, superObjectId, superDuperObjectId)
    await refreshPermissions()
  }, [currentUser.permissionsList, currentUser.user.id, dispatch, objectId, refreshPermissions, superDuperObjectId, superObjectId, type])

  const addGroupPermissions = useCallback( async (codeList: string[]) => {
    let updatedPermissions: themis_common.Permission.AsObject[] = []
    if (codeList[0].substr(1) !== "S" && codeList[0] !== "XX") {
      updatedPermissions = [...currentUser.permissionsList]
      updatedPermissions = updatedPermissions.filter(perm => perm.code !== "XX" && perm.code.substr(1) !== "S")
    }
    codeList.forEach(code => {
      const perm = permissions.find(perm => perm.code === code)
      if (perm) updatedPermissions.push(perm)
    })
    await updatePermissions(dispatch, type, objectId, currentUser.user.id, updatedPermissions, superObjectId, superDuperObjectId)
    await refreshPermissions()
  }, [dispatch, type, objectId, currentUser.user.id, currentUser.permissionsList, superObjectId, superDuperObjectId, refreshPermissions, permissions])

  const permissionGroups = useMemo<permissionGroup[]>(() => {
    if (!currentUser?.user.id || !currentUser?.permissionsList) return []
    const objectTitle = capitalizeFirstLetter(type)
    const groups: permissionGroup[] = [{ title: "All Permissions", status: 0, permissions: [] }, { title: "No Permissions", status: 0, permissions: [] }, { title: objectTitle, status: 0, permissions: [] }]
    permissions.forEach((permission => {
      const userHas = currentUser.permissionsList.reduce((theValue: boolean, userPermission) => {
        return theValue || (userPermission.code === permission.code)
      }, false)
      const status = userHas ? 1 : 0

      if (permission.id === 1) {
        groups[0].status = status
        groups[0].permissions.push({ code: permission.code, title: permission.name, userHas: userHas })
      }
      if (permission.id === 2) {
        groups[1].status = status
        groups[1].permissions.push({ code: permission.code, title: permission.name, userHas: userHas })
      }
      if (permission.id > 2 && permission.id < 10) {
        if (groups[2].permissions.length === 0) groups[2].status = status
        else if ((groups[2].status === 1 && !userHas) || (groups[2].status === 0 && userHas)) groups[2].status = -1
        groups[2].permissions.push({ code: permission.code, title: permission.name, userHas: userHas })
      }
      if (permission.id >= 10) {
        const titleArray = permission.name.split(" ")
        titleArray.shift()
        const title = titleArray.join(" ")
        let found = false
        groups.forEach((group, i) => {
          if (group.title === title) {
            found = true
            if ((groups[i].status === 1 && !userHas) || (groups[i].status === 0 && userHas)) groups[i].status = -1
            groups[i].permissions.push({ code: permission.code, title: permission.name, userHas: userHas })
          }
        })
        if (!found) {
          groups.push({ title: title, status: status, permissions: [{ code: permission.code, title: permission.name, userHas: userHas }] })
        }
      }
    }))
    if (groups[1].permissions.length === 0) delete groups[1]
    return groups
  }, [currentUser.permissionsList, currentUser?.user.id, permissions, type])
  // doSetShowList not included here on purpose, adding causes infinite loop

  const selectGroupIcon = useCallback((group: permissionGroup) => {
    const isAllPermissions = currentUser.permissionsList.reduce((value: boolean, perm) => {
      if (perm.code.substr(1) === "S") return true
      return value
    }, false)
    if (group.status === 1) return <IconButton className={classes.clickable} color="secondary" aria-label="all checked" onClick={() => { removeGroupPermissions(group.permissions.map((perm) => perm.code)) }} disabled={!canEditUser}><Checked /></IconButton>
    if (group.status === 0) {
      if (isAllPermissions && group.permissions[0].code !== "XX") return <IconButton className={classes.clickable} aria-label="none checked" onClick={() => { addGroupPermissions(group.permissions.map((perm) => perm.code)) }} disabled={!canEditUser}><Checked /></IconButton>
      return <IconButton className={classes.clickable} aria-label="none checked" onClick={() => { addGroupPermissions(group.permissions.map((perm) => perm.code)) }} disabled={!canEditUser}><NotChecked /></IconButton>
    }
    return <IconButton className={classes.clickable} aria-label="some checked" onClick={() => { removeGroupPermissions(group.permissions.map((perm) => perm.code)) }} disabled={!canEditUser}><SomeChecked /></IconButton>
  }, [addGroupPermissions, classes.clickable, currentUser.permissionsList, removeGroupPermissions, canEditUser])


  const outGroups = permissionGroups.map((group, i) => {
    const icon = selectGroupIcon(group)

    const helpIconText = () => {
      if (group.title === "All Permissions") return `Grant the user all permissions in this table.`
      else if (group.title === "No Permissions") return `Block the user for this ${type}.`
      else if (group.title === "Program" || group.title === "Location" || group.title === "Team" || group.title === "Producer" || group.title === "Brand" || group.title === "Event") return `Grant the user permissions related to this ${type}.`
      else if (group.title === "Locations" || group.title === "Teams" || group.title === "Athletes" || group.title === "Coaches" || group.title === "Personnel" || group.title === "Brands" || group.title === "Events" || group.title === "Judges" || group.title === "Finances") return `Grant the user permissions related to the ${group.title.toLowerCase()} under this ${type}.`
      else if (group.title === "Program Users" || group.title === "Producer Users") return `Grant the user the ability to modify permissions for users in this ${type}.`
      else if (group.title === "Location Users" || group.title === "Team Users" || group.title === "Brand Users" || group.title === "Event Users") return `Grant the user the ability to modify the permissions of other users.`
      else return ``
    }

    return <Grid container item xs={4} key={`permissionGroup-${i}`}>
      <div className={classes.permissionGroupBox}>
        <Grid container item xs={12}>
          <Grid item xs={8} className={classes.permissionGroupTitle}>
            <Grid item xs={12}>
              <Typography>{group.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <HelpIcon
                markdownText={helpIconText()}
                boxSize="small"
                iconSize="small"
                tooltipText="Click for info"
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            {icon}
            { i < 2 ? [] : showThis[i]
              ? <IconButton className={classes.clickable} aria-label="open" onClick={() => { doSetShowThis(i, false) }}><Open /></IconButton>
              : <IconButton className={classes.clickable} aria-label="open" onClick={() => { doSetShowThis(i, true) }}><Closed /></IconButton>
            }
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Collapse in={ i === showDetail } >
            <Grid item container xs={12} className={classes.verbDropdown}>
              {group.permissions.map((permission) => {
                const verb = permission.title.split(" ")[0]
                const thePermission = permissions.find(perm => perm.code === permission.code)
                if (!thePermission) return <></>
                return <Grid item container xs={12} key={`permission-list-${permission.code}`}>
                  <Grid item xs={2} />
                  <Grid item xs={5} className={classes.permissionGroupTitle}>
                    <Typography>{verb}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {
                      permission.userHas
                        ? <IconButton key={`detail-for-${permission.code}`} color="secondary" className={classes.clickable}
                          aria-label="all checked"
                          disabled={!canEditUser}
                          onClick={() => {
                            removePermission(thePermission)
                          }}><Checked/></IconButton>
                        : <IconButton key={`detail-for-${permission.code}`} className={classes.clickable}
                          disabled={!canEditUser}
                          aria-label="none checked" onClick={() => {
                            addPermission(thePermission)
                          }}><NotChecked/></IconButton>
                    }
                  </Grid>
                </Grid>
              })}
            </Grid>
          </Collapse>
        </Grid>
      </div>
    </Grid>
  })
  return <>{permissionCodes?.denyAccess.length ? outGroups : <></> }</>
}

export default UserGridDetails
