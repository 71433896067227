
import React, { ReactElement, useState } from 'react'
import { Box, IconButton, Popover, Tooltip } from '@material-ui/core'
import { HelpOutlineOutlined, InfoOutlined } from "@material-ui/icons"
import ReactMarkdown from 'react-markdown'
// import gfm from 'remark-gfm' // GitHub flavored markdown support
// import rehypeRaw from 'rehype-raw' // Allows HTML styling in markdown
import { useStyles } from 'lib/theme'

interface HelpIconProps {
  markdownText: string
  iconType?: "help" | "info"
  tooltipText?: string
  boxSize?: "medium" | "small"
  iconSize?: "small" | "inherit" | "default" | "large"
  iconColor?: "inherit" | "disabled" | "primary" | "secondary" | "action" | "error"
}

const HelpIcon: React.FC<HelpIconProps> = ({ markdownText, iconType, tooltipText, boxSize, iconSize, iconColor }): ReactElement => {
  iconType = iconType ? iconType : "help"
  tooltipText = tooltipText ? tooltipText : "Click For Help"
  boxSize = boxSize ? boxSize : "small"
  iconSize = iconSize ? iconSize : "small"
  // iconColor = iconColor ? iconColor : "secondary.light"
  iconColor = iconColor ? iconColor : "inherit"
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'help-info-popover' : undefined

  return (
    <>
      <Tooltip title={tooltipText} aria-label="info-help" placement="bottom">
        <IconButton size={boxSize} className={classes.clickable} aria-label="help-icon" onClick={handleClick} tabIndex={-1}>
          {iconType === "info" ? <InfoOutlined fontSize={iconSize} color={iconColor} /> : <HelpOutlineOutlined fontSize={iconSize} color={iconColor} />}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box aria-label="help-popup-message" paddingX={2} paddingY={1} >
          {/* <ReactMarkdown remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]} children={markdownText} className={classes.customMarkdownStyles}/> */}
          <ReactMarkdown children={markdownText} className={classes.customMarkdownStyles} />
        </Box>
      </Popover>
    </>
  )
}

export default HelpIcon
