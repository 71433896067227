import React, { useEffect, ReactElement } from 'react'
import { useStyles } from "lib/theme"
import { Grid, Tooltip, IconButton } from '@material-ui/core'
import themis_common from 'store/themis_common_pb'
import { AccountTree as AccountTreeIcon, ViewList as ViewListIcon } from '@material-ui/icons'
import { Autocomplete } from "@material-ui/lab"
import { TextField } from '@material-ui/core'
import { Categories } from 'store/user/userActions'

interface EventHeaderProps {
  categories: Categories | undefined
  setScoringAuthorities: React.Dispatch<React.SetStateAction<themis_common.ScoringAuthority.AsObject[]>>
  scoringAuthorities: themis_common.ScoringAuthority.AsObject[]
  programAllowedProducers: themis_common.ProgramAllowedProducer.AsObject[]
  setSelectedScoringAuthorityId: React.Dispatch<React.SetStateAction<number | undefined>>
  setSelectedProducerId: React.Dispatch<React.SetStateAction<number | undefined>>
  showEventGrid: boolean
  setShowEventGrid: React.Dispatch<React.SetStateAction<boolean>>
}

const EventHeader: React.FC<EventHeaderProps> = ({
  categories, setScoringAuthorities, scoringAuthorities, programAllowedProducers,
  setSelectedScoringAuthorityId, setSelectedProducerId, showEventGrid, setShowEventGrid
}): ReactElement => {
  const classes = useStyles()

  // Getting scoring authorities
  useEffect(() => {
    if (categories) setScoringAuthorities(categories.scoringAuthorities)
  }, [categories, setScoringAuthorities])

  useEffect(() => {
    const eventViewStorage = localStorage.getItem('eventGridOrListView')
    eventViewStorage === 'eventGrid' ? setShowEventGrid(true) : setShowEventGrid(false)
  }, [setShowEventGrid])


  const handleViewChange = (showEventGrid: boolean) => {
    localStorage.setItem('eventGridOrListView', showEventGrid? 'eventGrid' : 'eventList')
    setShowEventGrid(showEventGrid)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={1}>
          <IconButton className={classes.clickable} onClick={() => handleViewChange(!showEventGrid)}>
            {showEventGrid ?
              <Tooltip title="Events List View">
                <ViewListIcon></ViewListIcon>
              </Tooltip>
              :
              <Tooltip title="Events Grid View">
                <AccountTreeIcon></AccountTreeIcon>
              </Tooltip>
            }
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Autocomplete
            options={scoringAuthorities}
            renderInput={
              (params) => <TextField style={{ marginBottom: 8 }} {...params} label="Filter Events By Scoring Authority" variant="outlined"/>
            }
            getOptionLabel={(option) => {
              return option.name
            }}
            onChange={(e, v) => {
              setSelectedScoringAuthorityId(v?.id)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5} style={{ marginLeft: 10 }}>
          <Autocomplete
            options={programAllowedProducers}
            renderInput={
              (params) => <TextField style={{ marginBottom: 8 }} {...params} label="Filter Events By Producer" variant="outlined"/>
            }
            getOptionLabel={(option) => {
              return option.producer?.name || "No Name Given"
            }}
            onChange={(e, v) => {
              setSelectedProducerId(v?.producerId)
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default EventHeader
