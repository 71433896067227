import React, { ReactElement, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { useStyles } from "lib/theme"
import { Grid, IconButton, Typography } from '@material-ui/core'
import { addScoringAuthority, addTeam, removeScoringAuthority, setPrimaryScoringAuthority, updateTeam } from "store/program/teamActions"
import { IAppState } from "store/store"
import { selectCurrentProgram, selectCurrentTeam } from "store/program/programSelectors"
import { selectCurrentCategories } from "store/user/userSelectors"
import { selectCurrentLocation } from "store/program/programSelectors"
import themis_common from "store/themis_common_pb"
import { emptyTeam } from "store/program/programReducer"
import { AsFields, FormFields } from "lib/constants"
import { Delete as DeleteIcon, CheckBoxOutlined as CheckedBoxIcon, CheckBoxOutlineBlankOutlined as BoxIcon } from "@material-ui/icons"
import { refreshUser } from 'store/user/userActions'
import AddEditTeam from './AddEditTeam'

const AddEditTeamContainer: React.FC = (): ReactElement => {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()

  const { teamId: inputTeamId } = useParams<{ teamId?: string | undefined }>()


  const program = useSelector((state: IAppState) => selectCurrentProgram(state))
  const location = useSelector((state: IAppState) => selectCurrentLocation(state))
  const team = useSelector((state: IAppState) => selectCurrentTeam(state))
  const categories = useSelector((state: IAppState) => selectCurrentCategories(state))

  const teamId = Number(inputTeamId)

  const [checkTeam, setCheckTeam] = useState(false)
  const [noDivisionError, setNoDivisionError] = useState(false)
  const [updatedTeam, setUpdatedTeam] = useState<AsFields>({ ...emptyTeam, allStarType: categories?.allStarTypes[0], tier: categories?.tiers[0], division: categories?.divisions[0] })
  const [division, setDivision] = useState<themis_common.Division.AsObject | null>(null)
  const [isErrorOpen, setIsErrorOpen] = useState(false)


  const addNewScoringAuthority = (division: themis_common.Division.AsObject) => {
    if (!program || !location) return
    addScoringAuthority(dispatch, teamId, program, location, division)
  }

  const formFields: FormFields = useMemo(() => {
    return {
      name: { label: "Team Name", required: true, phone: false, email: false },
      description: { label: "Description", required: false, phone: false, email: false },
    }
  }, [])

  const divisionList = useMemo(() => {
    const saHeader = <Grid container item xs={12} key={-1}>
      <Grid item xs={6}>
        <Typography>Division</Typography>
      </Grid>
      <Grid item xs={3}>
        Primary
      </Grid>
      <Grid item xs={3}>
        Remove
      </Grid>
    </Grid>

    if (!team?.scoringAuthoritiesList?.length) {
      return saHeader
    }
    else {
      const saGrid = team.scoringAuthoritiesList.map((sa, i) => {
        return <Grid container item xs={12} key={i}>
          <Grid item xs={6}>
            <Typography className={classes.listItem100}>{sa.season?.name} / {sa.division?.divisionDescriptor}</Typography>
          </Grid>
          <Grid item xs={3}>
            <IconButton className={classes.clickable} aria-label="delete" onClick={_e => {
              if (!sa.primary && location?.id && program?.id) {
                setPrimaryScoringAuthority(dispatch, teamId, location.id, program.id, sa.id)
              }
            }}>
              {sa.primary ? <CheckedBoxIcon fontSize="small" /> : <BoxIcon fontSize="small" />}
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <IconButton className={classes.clickable} aria-label="delete" onClick={_e => {
              if (!sa.primary && location?.id && program?.id) {
                removeScoringAuthority(dispatch, teamId, location.id, program.id, sa.id)
              }
            }}>
              {sa.primary ? [] : <DeleteIcon fontSize="small" />}
            </IconButton>
          </Grid>
        </Grid>
      })
      saGrid.unshift(saHeader)
      return saGrid
    }
  }, [classes, dispatch, location?.id, program?.id, team?.scoringAuthoritiesList, teamId])

  const formReady: boolean = useMemo(() => {
    return Object.keys(formFields).reduce((currentValue: boolean, key: string) => currentValue && (!formFields[key].required || updatedTeam[key] > ''), true)
  }, [updatedTeam, formFields])

  const submitForm = async (e: { preventDefault: () => void }) => {
    if (!program || !location) return
    setCheckTeam(true)
    e.preventDefault()
    if (!formReady) return
    if (!teamId) { // Add
      if (!location?.id) {
        console.error("No location set!")
        return
      }
      if (!division?.id) {
        setNoDivisionError(true)
        console.error("No division set!")
        return
      }
      try {
        const teamId = await addTeam(dispatch, program, location, division, updatedTeam)
        await refreshUser(dispatch)
        const scoringAuthoritiesResponse = await addScoringAuthority(dispatch, teamId, program, location, division)
        await setPrimaryScoringAuthority(dispatch, teamId, location.id, program.id, scoringAuthoritiesResponse.teamScoringAuthority.id)

        history.replace(`/Location/${location.id}/Team/${teamId}`)
      } catch (e) {
        setIsErrorOpen(true)
        console.error(e)
      }
    } else { // Edit

      const teamRequest = new themis_common.Team()
      teamRequest.setId(teamId)
      teamRequest.setProgramId(program.id)
      teamRequest.setLocationId(location.id)
      teamRequest.setName(updatedTeam.name)
      teamRequest.setDescription(updatedTeam.description)
      const allStar = new themis_common.AllStarType()
      allStar.setId(updatedTeam?.allStarType?.id || 0)
      teamRequest.setAllStarType(allStar)
      const tier = new themis_common.Tier()
      tier.setId(updatedTeam?.tier?.id || 0)
      teamRequest.setTier(tier)

      const doEditTeam = updateTeam(teamRequest)
      try {
        await doEditTeam(dispatch)
        await refreshUser(dispatch)
        history.replace(`/Location/${location.id}/Team/${teamId}`)
      } catch (e) {
        setIsErrorOpen(true)
        console.error(e)
      }

    }
  }


  return (
    <AddEditTeam
      team={team}
      division={division}
      updatedTeam={updatedTeam}
      formFields={formFields}
      checkTeam={checkTeam}
      setNoDivisionError={setNoDivisionError}
      setUpdatedTeam={setUpdatedTeam}
      setCheckTeam={setCheckTeam}
      divisionList={divisionList}
      teamId={teamId}
      submitForm={submitForm}
      setDivision={setDivision}
      categories={categories}
      addNewScoringAuthority={addNewScoringAuthority}
      noDivisionError={noDivisionError}
      setIsErrorOpen={setIsErrorOpen}
      isErrorOpen={isErrorOpen} />
  )
}

export default AddEditTeamContainer
