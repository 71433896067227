export const eula =
`
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt; color: black">CHEER
TECHNOLOGIES, LLC </font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">
Themis SOFTWARE TERMS OF SERVICE AND LICENSE AGREEMENT</font></font></p>
<p style="margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">THIS
LEGAL AGREEMENT BETWEEN YOU AND CHEER TECHNOLOGIES GOVERNS YOUR USE
OF THE CHEER TECHNOLOGIES, LLC THEMIS PRODUCT, SOFTWARE,
SERVICES, AND WEBSITES (COLLECTIVELY REFERRED TO AS THE “SERVICE”).
IT IS IMPORTANT THAT YOU READ AND UNDERSTAND THE FOLLOWING TERMS. BY
CLICKING “AGREE,” YOU ARE AGREEING THAT THESE TERMS WILL APPLY IF
YOU CHOOSE TO ACCESS OR USE THE SERVICE.</span></font></font></font></p>
<p style="margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">Cheer
Technologies, LLC is the provider of the Service, which permits you
to utilize certain Internet services, including storing your personal
content (such as athlete, guardian, coach, program and producer
information - addresses, phone numbers, legal names, email addresses,
birth certificates, driver’s license and other personally
identifiable information) and making it accessible to Event Producers
that Cheer Technologies, LLC partners with at the time of
registration for an event, and certain location based services, only
under the terms and conditions set forth in this Agreement. </span></font></font></font>
</p>
<p style="margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>I.
REQUIREMENTS FOR USE OF THE SERVICE.</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>A.
Age.&nbsp;</b></span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">The
Service is only available to individuals aged 13 years or older (or
equivalent minimum age in the relevant jurisdiction), unless you are
under 13 years old and your user account was provided to you as a
result of a request by your parent or guardian. We do not knowingly
collect, use or disclose personal information from children under 13,
or equivalent minimum age in the relevant jurisdiction, without
verifiable parental consent. &nbsp;Parents and guardians should also
remind any minors that conversing with strangers on the Internet can
be dangerous and take appropriate precautions to protect children,
including monitoring their use of the Service.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">To
use the Service, you cannot be a person barred from receiving the
Service under the laws of the United States or other applicable
jurisdictions, including the country in which you reside or from
where you use the Service. By accepting this Agreement, you represent
that you understand and agree to the foregoing.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>B.
Accounts.&nbsp;</b></span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">Use
of the Service may require compatible devices, Internet access, and
certain software (fees may apply); may require periodic updates; and
may be affected by the performance of these factors. Cheer
Technologies, LLC reserves the right to limit the number of Accounts
that may be created from a device and the number of devices
associated with an Account. The latest version of compatible web
browsers (“Google Chrome” and “Firefox”) may be required for
certain transactions or features. You agree that meeting these
requirements is your responsibility.&nbsp;</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; letter-spacing: -0.3pt; line-height: 100%; background: #ffffff">
<br>

</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>C.
Limitations on Use.</b></span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">&nbsp;
You agree to use the Service only for purposes permitted by this
Agreement, and only to the extent permitted by any applicable law,
regulation, or generally accepted practice in the applicable
jurisdiction. Exceeding any applicable or reasonable limitation of
bandwidth, or storage capacity (for example, image, video or
documents) is prohibited and may prevent you from adding new images,
video or documents. If your use of the Service or other behavior
intentionally or unintentionally threatens Cheer Technologies, LLC’s
ability to provide the Service or other systems, Cheer Technologies,
LLC shall be entitled to take all reasonable steps to protect the
Service and Cheer Technologies, LLC’s systems, which may include
suspension of your access to the Service. Repeated violations of the
limitations may result in termination of your Account.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; letter-spacing: -0.3pt; line-height: 100%; background: #ffffff">
<br>

</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>D.
Availability of the Service.&nbsp;</b></span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">The
Service, or any feature or part thereof, may not be available in all
languages or in all countries and Cheer Technologies, LLC makes no
representation that the Service, or any feature or part thereof, is
appropriate or available for use in any particular location. To the
extent you choose to access and use the Service, you do so at your
own initiative and are responsible for compliance with any applicable
laws.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; letter-spacing: -0.3pt; line-height: 100%; background: #ffffff">
<br>

</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>E.
Changing the Service.&nbsp;&nbsp;</b></span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">Cheer
Technologies, LLC reserves the right at any time to modify this
Agreement and to impose new or additional terms or conditions on your
use of the Service, provided that Cheer Technologies, LLC will give
you 30 days’ advance notice of any material adverse change to the
Service or applicable terms of service, unless it would not be
reasonable to do so due to circumstances arising from legal,
regulatory, or governmental action; to address user security, user
privacy, or technical integrity concerns; to avoid service
disruptions to other users; or due to a natural disaster,
catastrophic event, war, or other similar occurrence outside of Cheer
Technologies, LLC’s reasonable control. Cheer Technologies, LLC
shall not be liable to you for any modifications to the Service or
terms of service made in accordance with this Section IE.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; letter-spacing: -0.3pt; line-height: 100%; background: #ffffff">
<br>

</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>II.
GENERAL. </b></font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>A.</b>
The Cheer Technologies, LLC software and any third party software,
content, documentation, fonts and any data accompanying this License
whether on disk, in read only memory, on any other media or in any
other form (collectively the "Cheer Technologies, LLC Software")
are licensed, not sold, to you by Cheer Technologies, LLC ("Cheer
Technologies”) for use only under the terms of this License. Cheer
Technologies and/or Cheer Technologies licensors retain ownership of
the Cheer Technologies, LLC Software itself and reserve all rights
not expressly granted to you. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>B.</b>
Cheer Technologies, LLC, at its discretion, may make available future
upgrades or updates to the Cheer Technologies, LLC Software for your
compatible web browser (“Google Chrome” and “Firefox”). The
Cheer Technologies, LLC Software upgrades and updates, if any, may
not necessarily include all existing software features or new
features that Cheer Technologies, LLC releases for newer or other
derivative features of the Themis Software. The terms of this
License will govern any software upgrades or updates provided by
Cheer Technologies, LLC that replace and/or supplement the original
Cheer Technologies, LLC Software product, unless such upgrade or
update is accompanied by a separate license in which case the terms
of that license will govern such upgrade or update. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>C.</b>
Title and intellectual property rights in and to any content
displayed by or accessed through the Cheer Technologies, LLC Software
belongs to the respective content owner. Such content may be
protected by copyright or other intellectual property laws and
treaties, and may be subject to terms of use of the third party
providing such content. Except as otherwise provided in this License,
this License does not grant you any rights to use such content nor
does it guarantee that such content will continue to be available to
you. </font></font>
</p>
<p style="margin-bottom: 0in; letter-spacing: -0.3pt; line-height: 100%; background: #ffffff">
<br>

</p>
<p style="margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>III.
FEATURES AND SERVICES.</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>A.
Use of Location-based Services</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">Cheer
Technologies, LLC and its partners and licensors may provide certain
features or services that rely upon device-based location information
using GPS (or similar technology, where available) and crowdsourced
Wi-Fi access points and cell tower locations. To provide such
features or services, where available, Cheer Technologies, LLC and
its partners and licensors must collect, use, transmit, process and
maintain your location data, including but not limited to the
geographic location of your device and information related to your
Themis account (“Account”) and any devices registered
thereunder, including but not limited to your email, device ID and
name, and device type.&nbsp;</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">You
may withdraw consent to Cheer Technologies, LLC and its partners’
and licensors’ collection, use, transmission, processing and
maintenance of location and Account data at any time by disabling
geolocation sharing in your compatible web browsers (“Google
Chrome” and “Firefox”). When using third party services that
use or provide location data as part of the Service, you are subject
to and should review such third party’s terms and privacy policy on
use of location data by such third party services. Any location data
provided by the Service is not intended to be relied upon in
situations where precise location information is needed or where
erroneous, inaccurate, time-delayed or incomplete location data may
lead to death, personal injury, property or environmental damage.
Cheer Technologies, LLC shall use reasonable skill and due care in
providing the Service, but neither Cheer Technologies, LLC nor any of
its service and/or content providers guarantees the availability,
accuracy, completeness, reliability, or timeliness of location data
or any other data displayed by the Service.&nbsp;</span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><u>LOCATION-BASED
SERVICES ARE NOT INTENDED OR SUITABLE FOR USE AS AN EMERGENCY LOCATOR
SYSTEM.</u></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>B.
Your Data</b></span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>1.&nbsp;&nbsp;Photos
and Video.&nbsp;</b></span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">When
you upload photos/videos,&nbsp;your photos, videos, metadata and any
edits that you make in the Photos App&nbsp;on your iOS device, macOS
computer, Android Device or Windows PC will be automatically uploaded
and stored in Themis. The photo and video resolution may vary
depending on the requirements of the service.</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>2.
Documents and Forms. </b></span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">When
you upload documents and forms the format may be automatically
converted to another format for use within Themis.</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0in; letter-spacing: -0.3pt; line-height: 100%; background: #ffffff">
<br>

</p>
<p style="margin-left: 1in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>3.
Diagnostic and Usage Data.</b> You agree that Cheer Technologies, LLC
and its subsidiaries and agents may collect, maintain, process and
use diagnostic, technical, usage and related information, including
but not limited to information about your web browsers, computer,
system and application software, and peripherals, that is gathered
periodically to facilitate the provision of software updates, product
support and other services to you (if any) related to the Cheer
Technologies, LLC Software, and to verify compliance with the terms
of this License. Cheer Technologies, LLC may use this information, to
provide and improve Cheer Technologies, LLC’s products and
services. To enable Cheer Technologies, LLC’s partners and third
party developers to improve their software, hardware and services
designed for use with Cheer Technologies, LLC products, Cheer
Technologies, LLC may also provide any such partner or third party
developer with a subset of diagnostic information that is relevant to
that partner’s or developer’s software, hardware and/or services.</font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; letter-spacing: -0.3pt; line-height: 100%; background: #ffffff">
<br>

</p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>C.
Third Party Apps.&nbsp;</b></span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">If
you sign in to certain third party Apps with your Cheer Technologies,
LLC credentials, you agree to allow that App to store data in your
personal Cheer Technologies, LLC account and for Cheer Technologies,
LLC to collect, store and process such data on behalf of the relevant
third-party App Developer in association with your use of the Service
and such Apps. </span></font></font></font>
</p>
<p style="margin-bottom: 0in; letter-spacing: -0.3pt; line-height: 100%; background: #ffffff">
<br>

</p>
<p style="margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>IV.
PAYMENT PROCESSING.</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>A.
Payment</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">By
registering for an event you are entering a contract with the Event
Producer (“the event provider”).</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">By
paying for a registration transaction your credit card and/or bank
account will be processed on behalf of the event provider by Cheer
Technologies, LLC.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">Cheer
Technologies, LLC may reserve a portion of your payment as the
Application Fee charged to the event provider, this Application Fee
will not affect your account due balance.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">You
can change your payment preferences in the billing preferences app
bar.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">YOU
ARE RESPONSIBLE FOR THE TIMELY PAYMENT OF ALL FEES AND FOR PROVIDING
CHEER TECHNOLOGIES, LLC WITH VALID CREDIT CARD OR PAYMENT ACCOUNT
DETAILS FOR PAYMENT OF ALL FEES. If Cheer Technologies, LLC is unable
to successfully charge your credit card or payment account for fees
due, Cheer Technologies, LLC reserves the right to revoke or restrict
access to your stored Content, delete your stored Content, or
terminate your Account. If you want to designate a different credit
card or payment account or if there is a change in your credit card
or payment account status, you must change your information online in
the Billing Preferences section of Themis; this may temporarily
disrupt your access to the Services while Cheer Technologies, LLC
verifies your new payment information. We may contact you via email
regarding your account, for reasons including, without limitation,
reaching or exceeding your storage limit.&nbsp;</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">If
you are in Brazil, notwithstanding anything herein to the contrary:</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">For
any charges made by Cheer Technologies, LLC to you, Cheer
Technologies, LLC may use&nbsp;an affiliated company&nbsp;to perform
activities of collection and remittances to charge any amounts owed
by you in connection with your account.&nbsp; In addition,&nbsp;your
total price will include the price of the upgrade plus any applicable
credit card fees.&nbsp;You are responsible for any taxes applicable
to you except for any applicable withholding taxes which shall be
collected by&nbsp;Cheer Technologies, LLC’s affiliated company.&nbsp;You
must&nbsp;provide&nbsp;all account information required by Cheer
Technologies, LLC to enable such transactions. You acknowledge and
agree that if you do not provide all required account information,
Cheer Technologies, LLC shall have the right to terminate your
account</span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><i>.&nbsp;</i></span></font></font></font></p>
<p style="margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>V.
YOUR USE OF THE SERVICE.</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>A.
Your Account</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">As
a registered user of the Service, you must establish an Account.
Don’t reveal your Account information to anyone else. You are
solely responsible for maintaining the confidentiality and security
of your Account and for all activities that occur on or through your
Account, and you agree to immediately notify Cheer Technologies, LLC
of any security breach of your Account. You further acknowledge and
agree that the Service is designed and intended for personal use on
an individual basis and you should not share your Account and/or
password details with another individual. Provided we have exercised
reasonable skill and due care, Cheer Technologies, LLC shall not be
responsible for any losses arising out of the unauthorized use of
your Account resulting from you not following these rules.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">In
order to use the Service, you must enter your email address and
password to authenticate your Account. You agree to provide accurate
and complete information when you register with, and as you use, the
Service (“Service Registration Data”), and you agree to update
your Service Registration Data to keep it accurate and complete.
Failure to provide accurate, current and complete Service
Registration Data may result in the suspension and/or termination of
your Account. You agree that Cheer Technologies, LLC may store and
use the Service Registration Data you provide for use in maintaining
and billing fees to your Account.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>B.
Use of Other Cheer Technologies, LLC Products and Services</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">Particular
components or features of the Service provided by Cheer Technologies,
LLC and/or its licensors, including but not limited to the ability to
purchase event photographs, event videos, or merchandise (additional
fees apply), require separate software or other license agreements or
terms of use. You must read, accept, and agree to be bound by any
such separate agreement as a condition of using these particular
components or features of the Service.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>C.
No Conveyance</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">Nothing
in this Agreement shall be construed to convey to you any interest,
title, or license in a domain name, user id, or similar resource used
by you in connection with the Service.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>D.
No Right of Survivorship</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">Unless
otherwise required by law, You agree that your Account is
non-transferable and that any rights to your user account or Content
within your Account terminate upon your death. Upon receipt of a copy
of a death certificate your Account may be terminated and all Content
within your Account deleted. Contact iCloud Support at
support@cheerreplay.com&nbsp;for further assistance.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>E.
No Resale of Service</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">You
agree that you will not reproduce, copy, duplicate, sell, resell,
rent or trade the Service (or any part thereof) for any purpose.</span></font></font></font></p>
<p style="margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>VI.
CONTENT AND YOUR CONDUCT.</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>A.
Content</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font color="#1d1d1f"><span style="letter-spacing: -0.3pt">“<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">Content”
means any information that may be generated or encountered through
use of the Service, such as data files, device characteristics,
written text, software, music, graphics, photographs, images, sounds,
videos, messages and any other like materials. You understand that
all Content, whether publicly posted or privately transmitted on the
Service is the sole responsibility of the person from whom such
Content originated. This means that you, and not Cheer Technologies,
LLC, are solely responsible for any Content you upload, download,
post, email, transmit, store or otherwise make available through your
use of the Service. You understand that by using the Service you may
encounter Content that you may find offensive, indecent, or
objectionable, and that you may expose others to Content that they
may find objectionable. Cheer Technologies, LLC does not control the
Content posted via the Service, nor does it guarantee the accuracy,
integrity or quality of such Content. You understand and agree that
your use of the Service and any Content is solely at your own risk.</font></font></span></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>B.
Your Conduct</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">You
agree that you will NOT use the Service to:</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">a.
upload, download, post, email, transmit, store or otherwise make
available any Content that is unlawful, harassing, threatening,
harmful, tortious, defamatory, libelous, abusive, violent, obscene,
vulgar, invasive of another’s privacy, hateful, racially or
ethnically offensive, or otherwise objectionable;</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">b.
stalk, harass, threaten or harm another;</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">c.
if you are an adult, request personal or other information from a
minor (any person under the age of 18 or such other age as local law
defines as a minor) who is not personally known to you, including but
not limited to any of the following: full name or last name, home
address, zip/postal code, telephone number, picture, or the names of
the minor’s school, church, athletic team or friends;</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">d.
pretend to be anyone, or any entity, you are not — you may not
impersonate or misrepresent yourself as another person (including
celebrities), entity, another Themis user, an Cheer
Technologies, LLC employee, or a civic or government leader, or
otherwise misrepresent your affiliation with a person or entity
(Cheer Technologies, LLC reserves the right to reject or block any
user account or email address which could be deemed to be an
impersonation or misrepresentation of your identity, or a
misappropriation of another person’s name or identity);</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">e.
engage in any copyright infringement or other intellectual property
infringement (including uploading any content to which you do not
have the right to upload), or disclose any trade secret or
confidential information in violation of a confidentiality,
employment, or nondisclosure agreement;</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">f.
post, send, transmit or otherwise make available any unsolicited or
unauthorized email messages, advertising, promotional materials, junk
mail, spam, or chain letters, including, without limitation, bulk
commercial advertising and informational announcements;</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">g.
forge any TCP-IP packet header or any part of the header information
in an email or a news group posting, or otherwise putting information
in a header designed to mislead recipients as to the origin of any
Content transmitted through the Service (“spoofing”);</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">h.
upload, post, email, transmit, store or otherwise make available any
material that contains viruses or any other computer code, files or
programs designed to harm, interfere or limit the normal operation of
the Service (or any part thereof), or any other computer software or
hardware;</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">i.
interfere with or disrupt the Service (including accessing the
Service through any automated means, like scripts or web crawlers),
or any servers or networks connected to the Service, or any policies,
requirements or regulations of networks connected to the Service
(including any unauthorized access to, use or monitoring of data or
traffic thereon);</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">j.
plan or engage in any illegal activity; and/or</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">k.
gather and store personal information on any other users of the
Service to be used in connection with any of the foregoing prohibited
activities.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>C.
Removal of Content</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">You
acknowledge that Cheer Technologies, LLC is not responsible or liable
in any way for any Content provided by others and has no duty to
screen such Content. However, Cheer Technologies, LLC reserves the
right at all times to determine whether Content is appropriate and in
compliance with this Agreement, and may screen, move, refuse, modify
and/or remove Content at any time, without prior notice and in its
sole discretion, if such Content is found to be in violation of this
Agreement or is otherwise objectionable.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>D.
Back up Your Content</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">You
are responsible for backing up, to your own computer or other device,
any important documents, images or other Content that you store or
access via the Service. Cheer Technologies, LLC shall use reasonable
skill and due care in providing the Service, but Cheer Technologies,
LLC does not guarantee or warrant that any Content you may store or
access through the Service will not be subject to inadvertent damage,
corruption or loss.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>E.
Access to Your Account and Content</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">Cheer
Technologies, LLC reserves the right to take steps Cheer
Technologies, LLC believes are reasonably necessary or appropriate to
enforce and/or verify compliance with any part of this Agreement. You
acknowledge and agree that Cheer Technologies, LLC may, without
liability to you, access, use, preserve and/or disclose your Account
information and Content to law enforcement authorities, government
officials, and/or a third party, as Cheer Technologies, LLC believes
is reasonably necessary or appropriate, if legally required to do so
or if Cheer Technologies, LLC has a good faith belief that such
access, use, disclosure, or preservation is reasonably necessary to:
(a) comply with legal process or request; (b) enforce this Agreement,
including investigation of any potential violation thereof; (c)
detect, prevent or otherwise address security, fraud or technical
issues; or (d) protect the rights, property or safety of Cheer
Technologies, LLC, its users, a third party, or the public as
required or permitted by law.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>F.
Copyright Notice - DMCA</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#1d1d1f"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><span style="letter-spacing: -0.3pt">If
you believe that any Content in which you claim copyright has been
infringed by anyone using the Service, please contact
</span></font></font></font><a href="mailto:support@cheerreplay.com"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><span style="letter-spacing: -0.3pt">support@cheerreplay.com</span></font></font></a><font color="#1d1d1f"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><span style="letter-spacing: -0.3pt">.
Cheer Technologies, LLC may, in its sole discretion, suspend and/or
terminate Accounts of users that are found to be repeat infringers.</span></font></font></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>G.
Violations of this Agreement</b></span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0.17in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">If
while using the Service, you encounter Content you find
inappropriate, or otherwise believe to be a violation of this
Agreement, you may report it by sending an email to
support@cheerreplay.com.</span></font></font></font></p>
<p style="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>H.
Content Submitted or Made Available by You on the Service</b></span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>1.
License from You.&nbsp;</b></span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">Except
for material we may license to you, Cheer Technologies, LLC does not
claim ownership of the materials and/or Content you submit or make
available on the Service. However, by submitting or posting such
Content on areas of the Service that are accessible by the public or
other users with whom you consent to share such Content, you grant
Cheer Technologies, LLC a worldwide, royalty-free, non-exclusive
license to use, distribute, reproduce, modify, adapt, publish,
translate, publicly perform and publicly display such Content on the
Service solely for the purpose for which such Content was submitted
or made available, without any compensation or obligation to you. You
agree that any Content submitted or posted by you shall be your sole
responsibility, shall not infringe or violate the rights of any other
party or violate any laws, contribute to or encourage infringing or
otherwise unlawful conduct, or otherwise be obscene, objectionable,
or in poor taste. By submitting or posting such Content on areas of
the Service that are accessible by the public or other users, you are
representing that you are the owner of such material and/or have all
necessary rights, licenses, and authorization to distribute it.</span></font></font></font></p>
<p style="margin-left: 1in; margin-bottom: 0in; line-height: 100%; background: #ffffff">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><font color="#1d1d1f"><span style="letter-spacing: -0.3pt"><b>2.
Changes to Content.&nbsp;</b></span></font><font color="#1d1d1f"><span style="letter-spacing: -0.3pt">You
understand that in order to provide the Service and make your Content
available thereon, Cheer Technologies, LLC may transmit your Content
across various public networks, in various media, and modify or
change your Content to comply with technical requirements of
connecting networks or devices or computers. You agree that the
license herein permits Cheer Technologies, LLC to take any such
actions.</span></font></font></font></p>
<p style="margin-bottom: 0.17in; letter-spacing: -0.3pt; line-height: 100%; background: #ffffff">
<br>
<br>

</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>VII.
PERMITTED LICENSE USES AND RESTRICTIONS. </b></font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>A.
License.</b> Subject to the terms and conditions of this License, you
are granted a limited, non-transferable, non-exclusive license to
install and use the Cheer Technologies, LLC Software on any
compatible web browser (“Google Chrome” and “Firefox”) that
you own or control. You may not distribute or make the Cheer
Technologies, LLC Software available over a network where it could be
used by multiple devices at the same time. You may not rent, lease,
lend, sell, redistribute or sublicense the Cheer Technologies, LLC
Software. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>B.
System Requirements.</b> Cheer Technologies, LLC Software is
supported only on compatible web browsers (“Google Chrome” and
“Firefox”) that meets specified system requirements as indicated
by Cheer Technologies, LLC. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>C.
Volume or Maintenance License.</b> If you obtained the Cheer
Technologies, LLC Software under a volume or maintenance license
program with Cheer Technologies, LLC, the terms of your volume or
maintenance license will determine the number of copies of the Cheer
Technologies, LLC Software you are permitted to use and run on Cheer
Technologies, LLC compatible web browsers (“Google Chrome” and
“Firefox”) you own or control. Except as agreed to in writing by
Cheer Technologies, LLC, all other terms and conditions of this
License shall apply to your use of the Cheer Technologies, LLC
Software obtained under a volume or maintenance license. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>D.
No Reverse Engineering.</b> You may not and you agree not to, or to
enable others to, copy (except as expressly permitted by this License
or by the Usage Rules if they are applicable to you), decompile,
reverse engineer, disassemble, attempt to derive the source code of,
decrypt, modify, or create derivative works of the Cheer
Technologies, LLC Software or any services provided by the Cheer
Technologies, LLC Software, or any part thereof (except as and only
to the extent any foregoing restriction is prohibited by applicable
law or to the extent as may be permitted by the licensing terms
governing use of open-sourced components included with the Cheer
Technologies, LLC Software). Any attempt to do so is a violation of
the rights of Cheer Technologies, LLC and its licensors of the Cheer
Technologies, LLC Software. If you breach this restriction, you may
be subject to prosecution and damages. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>E.
Compliance with Laws.</b> You agree to use the Cheer Technologies,
LLC Software and the Services (as defined in Section III above) in
compliance with all applicable laws, including local laws of the
country or region in which you reside or in which you download or use
the Cheer Technologies, LLC Software and Services. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>F.
Third Party Software.</b> Cheer Technologies, LLC may provide access
to certain third party software or services as a convenience. To the
extent that the Cheer Technologies, LLC Software contains or provides
access to any third party software or services, Cheer Technologies,
LLC has no express or implied obligation to provide any technical or
other support for such software or services. Please contact the
appropriate software vendor, manufacturer or service provider
directly for technical support and customer service related to its
software, service and/or products. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>G.
Digital Materials.</b> Except as may be provided herein, you may not
use, extract or distribute, commercially or otherwise, on a
standalone basis, any photographs, images, graphics, artwork, audio,
video or similar assets (“Digital Materials”) contained within,
or provided as a part of, the Cheer Technologies, LLC Software, or
otherwise use the Digital Materials outside the context of its
intended use as part of the Cheer Technologies, LLC Software. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>H.
Themis Features and Support.</b> The Cheer Technologies, LLC
Software may not support some video and photo formats. Use of some
features of the Cheer Technologies, LLC Software will depend on the
features of your camera. You understand and agree that sharing your
photos via a third party service requires compliance with the terms
and conditions of that service. Synchronizing photos with the Cheer
Technologies, LLC Software and your computer or any Cheer
Technologies, LLC or third party services may result in loss of data.
Receiving a new version of a photo shared with the Cheer
Technologies, LLC Software on your compatible web browsers (“Google
Chrome” and “Firefox”) may cause a previously stored version of
the photo to be overwritten. Synchronizing or sharing photos with the
Cheer Technologies, LLC Software may cause additional data about your
photos, including photo location data, to be transmitted with the
photos. Location data may not be available for all areas and is
provided solely as a convenience to you. Neither Cheer Technologies,
LLC nor any of its content providers guarantees the availability,
accuracy, completeness, reliability, or timeliness of location data
displayed by any Services. </font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>VIII.
TERMINATION.</b> This License is effective until terminated. Your
rights under this License will terminate automatically or otherwise
cease to be effective without notice from Cheer Technologies, LLC if
you fail to comply with any term(s) of this License. Upon the
termination of this License, you shall cease all use of the Cheer
Technologies, LLC Software and destroy all copies, full or partial,
of the Cheer Technologies, LLC Software. Sections III, VIII, IX, X,
XI, XIII and XIV of this License shall survive any such termination. </font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>IX.
DISCLAIMER OF WARRANTIES. </b></font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">A.
YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT, TO THE EXTENT PERMITTED BY
APPLICABLE LAW, USE OF THE CHEER TECHNOLOGIES, LLC SOFTWARE AND ANY
SERVICES PERFORMED BY OR ACCESSED THROUGH THE CHEER TECHNOLOGIES, LLC
SOFTWARE IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO
SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">B.
TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE CHEER
TECHNOLOGIES, LLC SOFTWARE AND SERVICES ARE PROVIDED "AS IS"
AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY
KIND, AND CHEER TECHNOLOGIES, LLC AND CHEER TECHNOLOGIES, LLC'S
LICENSORS (COLLECTIVELY REFERRED TO AS "CHEER TECHNOLOGIES, LLC"
FOR THE PURPOSES OF SECTIONS IX AND X) HEREBY DISCLAIM ALL WARRANTIES
AND CONDITIONS WITH RESPECT TO THE CHEER TECHNOLOGIES, LLC SOFTWARE
AND SERVICES, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT
NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF
MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR
PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND NONINFRINGEMENT OF
THIRD PARTY RIGHTS. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">C.
CHEER TECHNOLOGIES, LLC DOES NOT WARRANT AGAINST INTERFERENCE WITH
YOUR ENJOYMENT OF THE CHEER TECHNOLOGIES, LLC SOFTWARE AND SERVICES,
THAT THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED OR PROVIDED
BY, THE CHEER TECHNOLOGIES, LLC SOFTWARE WILL MEET YOUR REQUIREMENTS,
THAT THE OPERATION OF THE CHEER TECHNOLOGIES, LLC SOFTWARE OR
SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ANY SERVICES WILL
CONTINUE TO BE MADE AVAILABLE, THAT THE CHEER TECHNOLOGIES, LLC
SOFTWARE OR SERVICES WILL BE COMPATIBLE OR WORK WITH ANY THIRD PARTY
SOFTWARE, APPLICATIONS OR THIRD PARTY SERVICES, OR THAT DEFECTS IN
THE CHEER TECHNOLOGIES, LLC SOFTWARE OR SERVICES WILL BE CORRECTED.
INSTALLATION OF THIS SOFTWARE MAY AFFECT THE USABILITY OF THIRD PARTY
SOFTWARE, APPLICATIONS OR THIRD PARTY SERVICES. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">D.
YOU FURTHER ACKNOWLEDGE THAT THE CHEER TECHNOLOGIES, LLC SOFTWARE AND
SERVICES ARE NOT INTENDED OR SUITABLE FOR USE IN SITUATIONS OR
ENVIRONMENTS WHERE THE FAILURE OR TIME DELAYS OF, OR ERRORS OR
INACCURACIES IN THE CONTENT, DATA OR INFORMATION PROVIDED BY, THE
CHEER TECHNOLOGIES, LLC SOFTWARE OR SERVICES COULD LEAD TO DEATH,
PERSONAL INJURY, OR SEVERE PHYSICAL DAMAGE. </font></font>
</p>
<p style="margin-left: 0.5in; margin-bottom: 0.11in; line-height: 108%">
<font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">E.
NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY CHEER TECHNOLOGIES,
LLC OR AN CHEER TECHNOLOGIES, LLC AUTHORIZED REPRESENTATIVE SHALL
CREATE A WARRANTY. SHOULD THE CHEER TECHNOLOGIES, LLC SOFTWARE OR
SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY
SERVICING, REPAIR OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE
EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE
STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND
LIMITATIONS MAY NOT APPLY TO YOU. </font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>X.
LIMITATION OF LIABILITY. </b></font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">TO
THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT SHALL CHEER
TECHNOLOGIES, LLC BE LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL,
SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING,
WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA OR
INFORMATION, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR
LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE
CHEER TECHNOLOGIES, LLC SOFTWARE AND SERVICES OR ANY THIRD PARTY
SOFTWARE OR APPLICATIONS IN CONJUNCTION WITH THE CHEER TECHNOLOGIES,
LLC SOFTWARE, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY
(CONTRACT, TORT OR OTHERWISE) AND EVEN IF CHEER TECHNOLOGIES, LLC HAS
BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS
DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF
INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY
TO YOU. In no event shall Cheer Technologies, LLC's total liability
to you for all damages (other than as may be required by applicable
law in cases involving personal injury) exceed the amount of fifty
dollars ($50.00). The foregoing limitations will apply even if the
above stated remedy fails of its essential purpose. </font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><br>
<br>

</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>XI.
EXPORT CONTROL. </b></font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">You
may not use or otherwise export or re-export the Cheer Technologies,
LLC Software except as authorized by United States law and the laws
of the jurisdiction in which the Cheer Technologies, LLC Software was
obtained. In particular, but without limitation, the Cheer
Technologies, LLC Software may not be exported or re-exported (a)
into any U.S. embargoed countries or (b) to anyone on the U.S.
Treasury Department's list of Specially Designated Nationals or the
U.S. Department of Commerce Denied Person’s List or Entity List. By
using the Cheer Technologies, LLC Software, you represent and warrant
that you are not located in any such country or on any such list. You
also agree that you will not use the Cheer Technologies, LLC Software
for any purposes prohibited by United States law, including, without
limitation, the development, design, manufacture or production of
missiles, nuclear, chemical or biological weapons. </font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>XII.
GOVERNMENT END USERS. </b></font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">The
Cheer Technologies, LLC Software and related documentation are
"Commercial Items", as that term is defined at 48 C.F.R.
§2.101, consisting of "Commercial Computer Software" and
"Commercial Computer Software Documentation", as such terms
are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as
applicable. Consistent with 48 C.F.R. §12.212 or 48 C.F.R.
§227.7202-1 through 227.7202-4, as applicable, the Commercial
Computer Software and Commercial Computer Software Documentation are
being licensed to U.S. Government end users (a) only as Commercial
Items and (b) with only those rights as are granted to all other end
users pursuant to the terms and conditions herein. Unpublished-rights
reserved under the copyright laws of the United States. </font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>XIII.
CONTROLLING LAW, VENUE AND SEVERABILITY.</b> </font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">This
License will be governed by and construed in accordance with the laws
of the State of California, excluding its conflict of law principles.
This License shall not be governed by the United Nations Convention
on Contracts for the International Sale of Goods, the application of
which is expressly excluded. If you are a consumer based in the
United Kingdom, this License will be governed by the laws of the
jurisdiction of your residence. If for any reason a court of
competent jurisdiction finds any provision, or portion thereof, to be
unenforceable, the remainder of this License shall continue in full
force and effect. All claims arising out of or relating to these
Terms will be brought exclusively in the federal or state courts of
Stanislaus County, California, USA, and you and Cheer Technologies,
LLC each consents to personal jurisdiction in those courts.</font></font></p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>XIV.
COMPLETE AGREEMENT; GOVERNING LANGUAGE.</b> </font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">This
License constitutes the entire agreement between you and Cheer
Technologies, LLC relating to the use of the Cheer Technologies, LLC
Software, and supersedes all prior or contemporaneous understandings
regarding such subject matter. No amendment to or modification of
this License will be binding unless in writing and signed by Cheer
Technologies, LLC. Any translation of this License is done for local
requirements and in the event of a dispute between the English and
any non-English versions, the English version of this License shall
govern, to the extent not prohibited by local law in your
jurisdiction. </font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt"><b>XV.
THIRD PARTY ACKNOWLEDGEMENTS. </b></font></font>
</p>
<p style="margin-bottom: 0.11in; line-height: 108%"><font face="Calibri, sans-serif"><font size="2" style="font-size: 11pt">Portions
of the Cheer Technologies, LLC Software utilize or include third
party software and other copyrighted material. Acknowledgements,
licensing terms and disclaimers for such material are contained in
the electronic documentation for the Cheer Technologies, LLC
Software, and your use of such material is governed by their
respective terms. </font></font>
</p>`
