import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  GridSize,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { getCurrentEvent } from "store/producer/eventActions";
import { useStyles } from "lib/theme";
import { EventScheduleReportTableHeaderCell } from "lib/constants";
import {
  calculateDivisionHeight,
  calculateOverallTimeRange,
  convertTo12HourFormat,
  filterPanelsByShifts,
  findMaxEventTeamsAndDivision,
  formatDateString,
  calculateMaxTeamTrCountForRow,
  nestedCalculateOverallTimeRange,
  revampedPanelWithEmptyTeamsAndDivisionsObject,
  revampedPanelsSplitEventDivisionIntoObject,
} from "lib/functions";

import themis_common from "store/themis_common_pb";
import Loader from "components/util/Loader";
import { selectCurrentUser } from "store/user/userSelectors";
import { IAppState } from "store/store";
import { useSelector } from "react-redux";
export interface IError {
  code: number;
  message: string;
}
const MESSAGES = {
  notFound: "Record Not Found!",
};
const EMPTY_STRING = "";
const EventScheduleReport: React.FC = () => {
  const { eventId } = useParams<{
    brandId?: string | undefined;
    producerId: string | undefined;
    eventId: string | undefined;
  }>();
  const classes = useStyles();
  const teamRef = useRef<any>({});
  const teamRowRef = useRef<any>({});
  const username = useSelector((state: IAppState) => selectCurrentUser(state));
  const [event, setEvent] = useState<themis_common.Event.AsObject>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>(EMPTY_STRING);
  const [rowHeights, setRowHeight] = useState<any>({});
  const [maxTeamTrCountForRow, setMaxTeamTrCountForRow] = useState<any>({});
  const currentEventId = eventId as string;
  const handelErrorMessage = (
    isUndefinedEvent: boolean,
    notFoundFloorList: boolean
  ) => {
    if (isUndefinedEvent) {
      setError(MESSAGES.notFound);
    } else if (notFoundFloorList) {
      setError(MESSAGES.notFound);
    }
  };

  const fetchEvent = async () => {
    if (currentEventId) {
      try {
        const res: any = await getCurrentEvent(+currentEventId);
        const updatedEvent: themis_common.Event.AsObject = res["event"];
        setEvent(updatedEvent);
        const isUndefinedEvent = updatedEvent === undefined;
        const notFoundFloorList = !updatedEvent?.eventFloorsList?.length;
        handelErrorMessage(isUndefinedEvent, notFoundFloorList);
      } catch (error: unknown) {
        const errorMessage = error as IError;
        setError(errorMessage?.message || MESSAGES.notFound);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchEvent();
    const idNotFound = !currentEventId.length;
    setError(idNotFound ? MESSAGES.notFound : EMPTY_STRING);
  }, [currentEventId]);

  useEffect(() => {
    const fetchDivisionHeight = () => {
      setLoading(true);
      if (
        teamRef.current !== undefined ||
        event !== undefined ||
        teamRowRef !== undefined
      ) {
        const divisionHeight = calculateDivisionHeight(teamRef, event);
        const TeamTrCountForRow = calculateMaxTeamTrCountForRow(
          teamRowRef,
          event
        );
        setRowHeight(divisionHeight);
        setMaxTeamTrCountForRow(TeamTrCountForRow);
      }
      setLoading(false);
    };
    fetchDivisionHeight();
  }, [teamRef, teamRowRef, event]);

  const renderHeaderSection = (
    floors: themis_common.EventFloor.AsObject,
    days: themis_common.EventFloorDate.AsObject
  ) => {
    const overallTimeRange = days?.judgingPanelsList?.map((panel) =>
      calculateOverallTimeRange(panel.shiftsList)
    );
    const timesRange = overallTimeRange.filter(
      (time, index) =>
        time !== null && overallTimeRange?.indexOf(time) === index
    );
    const time = nestedCalculateOverallTimeRange(timesRange);
    const logo = event
      ? event?.logo || event?.brand?.logo || event?.brand?.producer?.logo
      : "/themislogo.png";
    return (
      <Grid container className={classes.eventScheduleReportHeaderSection}>
        <Grid item xs={4}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo}
              className={classes.previewEventScheduleReportHeaderLogo}
            />
            <Typography style={{ marginLeft: "20px" }}>
              {event?.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography style={{ display: "flex", justifyContent: "center" }}>
            {formatDateString(days?.startDayTime)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography style={{ display: "flex", justifyContent: "center" }}>
            {floors?.name} - {EMPTY_STRING}
            {time?.map((time, index) => {
              return (
                <React.Fragment key={`time-${index}`}>
                  {time.start} - {time.end}
                </React.Fragment>
              );
            })}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const panelDivisionTeams = (
    panelIndex: number,
    division: themis_common.EventDivision.AsObject,
    shiftIndex: number
  ) => {
    const values: any = Object.values(maxTeamTrCountForRow)[shiftIndex];
    const noOfTeamAdd = values?.length ? values[panelIndex] : [];

    // Create a new empty object with the same structure as other objects in eventTeamsList
    const emptyTeamObject = {
      programName: "",
      name: "",
      warmUpTime: "",
      performanceTime: "",
      // Add other properties if needed
    };

    // Add the empty object to eventTeamsList
    const updatedEventTeamsList = [
      ...((division?.eventTeamsList as any) || []),
      ...Array.from({ length: noOfTeamAdd }, () => emptyTeamObject),
    ];
    return (
      <TableContainer component={Paper}>
        <Table aria-label="teams table">
          <TableBody>
            {/* Render team information */}
            {updatedEventTeamsList?.map((team: any, teamIndex: any) => {
              const teamsWarmUpTime = team?.warmUpTime;
              const warmUpTime = teamsWarmUpTime
                ? convertTo12HourFormat(teamsWarmUpTime)
                : "";
              const performanceTime = team.performanceTime
                ? convertTo12HourFormat(team?.performanceTime)
                : "";
              const teamName = `${
                team?.programName ? team.programName : EMPTY_STRING
              } ${team?.programName ? " - " : "---"}${
                team?.name ? team.name : "---"
              }`;
              const renderNotExistingContent = "---";
              return (
                <TableRow key={teamIndex}>
                  <TableCell
                    style={{
                      fontSize: "12.5px",
                      width: "62%",
                      height: "11vh",
                      wordWrap: "break-word",
                    }}
                  >
                    {teamName}
                  </TableCell>
                  <TableCell style={{ fontSize: "12.5px" }}>
                    {warmUpTime}{" "}
                    {!warmUpTime.length && renderNotExistingContent}
                  </TableCell>
                  <TableCell style={{ fontSize: "12.5px" }}>
                    {performanceTime}{" "}
                    {!performanceTime.length && renderNotExistingContent}
                  </TableCell>
                  {/* Add additional table cells for other team information */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  {
    /* Panel & Division & Teams Header  */
  }
  const renderPanelHeader = (
    splitPanelsByEventDivision: themis_common.EventFloorDateJudgingPanel.AsObject[]
  ) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="teams table">
          <TableHead style={{ border: "3px solid" }}>
            <TableRow>
              {EventScheduleReportTableHeaderCell.map((cell, index) => (
                <TableCell
                  key={`cell_${index}`}
                  style={{
                    fontWeight: 900,
                    width: cell.id === 1 ? "62%" : "",
                    fontSize: "12.5px",
                    lineHeight: "18px",
                  }}
                >
                  {cell.name}
                </TableCell>
              ))}
              {/* Add additional table header cells if needed */}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    );
  };

  const renderPanelDivisions = (
    judgingPanelsList: themis_common.EventFloorDateJudgingPanel.AsObject[]
  ) => {
    const filteredPanels = filterPanelsByShifts(judgingPanelsList);
    const maxTeamsInfo = findMaxEventTeamsAndDivision(filteredPanels);

    const panelsWithEmptyDivisions =
      revampedPanelWithEmptyTeamsAndDivisionsObject(
        filteredPanels,
        maxTeamsInfo?.maxDivInShift,
        maxTeamsInfo?.maxTeamsLengthPerStartTime
      );

    const splitPanelsByEventDivision =
      revampedPanelsSplitEventDivisionIntoObject(panelsWithEmptyDivisions);
    let gridItem: GridSize;
    switch (splitPanelsByEventDivision?.length) {
      case 1:
        gridItem = 12;
        break;
      case 2:
        gridItem = 6;
        break;
      default:
        gridItem = 4;
        break;
    }
    return (
      <Grid
        style={{
          flexGrow: 1,
          marginTop: "15px",
          marginBottom: "10px",
        }}
        container
        spacing={2}
      >
        {splitPanelsByEventDivision?.map((panel, panelIndex) => (
          <React.Fragment key={panelIndex}>
            <Grid key={panelIndex} item xs={gridItem}>
              {renderPanelHeader(splitPanelsByEventDivision)}
              {panel
                ? panel?.shiftsList?.map((shift, shiftIndex) => {
                    const rowIndex =
                      shiftIndex % (panelsWithEmptyDivisions?.length ?? 1);
                    const modifyPanelIndex: number = panelIndex % 3;
                    return (
                      <React.Fragment key={`panel_shift${shiftIndex}`}>
                        <div
                          ref={(ref) => {
                            const dynamicKey = `${shiftIndex}`;
                            teamRef.current[dynamicKey] = ref;
                            teamRowRef.current[dynamicKey] = ref;
                          }}
                          className="content-div"
                          style={{
                            border: "1px solid",
                          }}
                          key={`panel_shift${shift.id}`} // Adding a unique key to the div
                        >
                          <div
                            style={{
                              height:
                                rowHeights[`row${shiftIndex || rowIndex}`] ??
                                rowHeights[`row${shiftIndex || rowIndex}`] +
                                  1 +
                                  "px" ??
                                "auto",
                            }}
                          >
                            {shift?.eventDivisionsList
                              ?.filter(
                                (item) => item?.eventTeamsList?.length > 0
                              )
                              ?.map((division, divisionIndex) => {
                                return (
                                  <React.Fragment
                                    key={`divisions_${divisionIndex}`}
                                  >
                                    <Typography
                                      style={{
                                        background: "grey",
                                        color: "white",
                                        padding: "10px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {`${division?.division?.tier?.name} - ${
                                        division?.name
                                          ? division?.name
                                          : division?.division?.name
                                      } (${
                                        (division?.eventTeamsList ?? []).filter(
                                          (obj) => Object.keys(obj).length !== 0
                                        ).length
                                      })`}
                                    </Typography>
                                    {/* Display teams for the current division in a table */}
                                    {!division?.eventTeamsList?.length ? (
                                      <Typography
                                        style={{
                                          marginBottom: "10px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        No Teams Found!
                                      </Typography>
                                    ) : (
                                      panelDivisionTeams(
                                        modifyPanelIndex,
                                        division,
                                        shiftIndex
                                      )
                                    )}
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })
                : null}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Grid
        container
        justify="center"
        style={{ marginTop: error ? "350px" : 0 }}
      >
        <Typography variant="h1" style={{ textTransform: "capitalize" }}>
          {error}
        </Typography>
      </Grid>
      {loading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "300px",
            alignItems: "center",
          }}
        >
          <Loader height="2%" width="2%" />
          <Typography style={{ marginLeft: "20px", marginTop: "2px" }}>
            Loading Schedule Report...
          </Typography>
        </Box>
      ) : (
        event?.eventFloorsList.map((floor) => {
          const { daysList } = floor;
          return daysList.map((days) => {
            const isDivisionPanelOrShiftExisting =
              days?.judgingPanelsList?.some((panel) =>
                panel?.shiftsList?.some(
                  (shift) => shift?.eventDivisionsList?.length
                )
              );
            return (
              <div
                key={days.id}
                className={
                  username?.length ? classes.eventScheduleReport : undefined
                }
                style={{
                  padding: !username?.length ? "10px" : undefined,
                  marginBottom: !username?.length ? "70px" : undefined,
                }}
              >
                {isDivisionPanelOrShiftExisting ? (
                  <React.Fragment>
                    {renderHeaderSection(floor, days)}
                    {renderPanelDivisions(days.judgingPanelsList)}
                  </React.Fragment>
                ) : null}
              </div>
            );
          });
        })
      )}
    </React.Fragment>
  );
};

export default EventScheduleReport;
