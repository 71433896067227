import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Transition, useStyles } from "lib/theme";
import React, { useMemo, useState } from "react";
import themis_api from "../../store/themis_api_pb";
import themis_common from 'store/themis_common_pb'
interface CompareTeamsProps {
  teamData: themis_common.EventDivisionShiftTeam.AsObject[];
  scoresByJudgeIdAndDivisionInfo: themis_api.ScoresByJudgeIdAndDivision.AsObject[];
}

const CompareTeams: React.FC<CompareTeamsProps> = ({
  teamData,
  scoresByJudgeIdAndDivisionInfo,
}) => {
  const classes = useStyles();
  const [compareTeamDialog, setCompareTeamDialog] = useState<boolean>(false);

  const compareTeams = useMemo(() => {
    return (
      <Button
        variant="contained"
        className={classes.divisionSubmit}
        style={{ textAlign: "center", marginBottom: "20px", width: "100%" }}
        onClick={() => setCompareTeamDialog(true)}
      >
        Compare Teams Score
      </Button>
    );
  }, [teamData]);

  const handleCloseTeamDialog = () => {
    setCompareTeamDialog(false);
  };

  // Function to convert camelCase to title case
  const formatTitleCase = (key: string): string => {
    const value = key
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before capital letters
      .replace(/\b\w/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word

    return value;
  };

  // Function to remove duplicated rubric area names
  const removeDuplicatedRubricAreaName = () => {
    const uniqueRubricAreas = scoresByJudgeIdAndDivisionInfo
      .flatMap(({ scoresAndNameList }) =>
        scoresAndNameList.map(({ rubricAreaName }) => rubricAreaName)
      )
      .filter(
        (obj, index, arr) =>
          index === arr.findIndex((o) => obj === o && obj === o)
      )
      .sort();
    return uniqueRubricAreas;
  };

  // Get unique rubric area names
  const uniqueScoreAndName = removeDuplicatedRubricAreaName();

  // Function to get matched score and names for each team
  const getMatchedScoresAndNames = (
    scoresAndNameList: themis_api.RubricAreaNScores.AsObject[]
  ) => {
    const emptyObject = { rubricAreaName: "", score: "0" };
    return uniqueScoreAndName.map((name) => {
      const findScoreAndName = scoresAndNameList.find(
        (match) => match.rubricAreaName === name
      );
      return findScoreAndName ? findScoreAndName : emptyObject;
    });
  };

  // Function to calculate the total score for each team
  const getTeamScoreSum = (
    scoresAndNameList: themis_api.RubricAreaNScores.AsObject[]
  ) => {
    return scoresAndNameList.reduce((acc, { score }) => acc + +score, 0);
  };

  // Function to get revamped score and names for each team
  const revampedData = scoresByJudgeIdAndDivisionInfo.map(
    ({ teamName, teamId, scoresAndNameList }) => {
      const matchedScoresAndNames = getMatchedScoresAndNames(scoresAndNameList);
      return {
        teamId,
        teamName,
        scoresAndNameList: matchedScoresAndNames,
      };
    }
  );

  const teamComparisonDialog = (
    <Dialog
      open={compareTeamDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseTeamDialog}
      aria-labelledby="compare-teams-dialog-title"
      aria-describedby="compare-teams-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="compare-teams-dialog-title">
        Compare Teams Score
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="teams-score-table">
            <TableHead>
              <TableRow>
                <TableCell>Team Name</TableCell>
                {uniqueScoreAndName.map((name: string, index) => {
                  return (
                    <TableCell key={index}>
                      {formatTitleCase(name ? name?.toLowerCase() : "")}
                    </TableCell>
                  );
                })}
                <TableCell>Total Point</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {revampedData.map(
                ({ teamName, scoresAndNameList, teamId }, index) => {
                  const teamInfo = teamData[index];
                  const programName = teamInfo?.eventTeam?.programName;
                  const divisionTeamName = `${programName ? programName : ""} ${
                    teamName ? "-" : ""
                  } ${teamName ? teamName : ""}`;
                  const teamScore = scoresByJudgeIdAndDivisionInfo.map((arr) =>
                    getTeamScoreSum(arr.scoresAndNameList)
                  );
                  const divisionTeamScore =
                    Math.round(teamScore[index] * 1000) / 1000;
                  return (
                    <TableRow key={teamId}>
                      <TableCell>{divisionTeamName}</TableCell>
                      {scoresAndNameList?.map((rubricAreaInfo, index) => (
                        <TableCell key={index} align="right">
                          {rubricAreaInfo?.score ? rubricAreaInfo?.score : ""}
                        </TableCell>
                      ))}
                      <TableCell align="right">{divisionTeamScore}</TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseTeamDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <React.Fragment>
      {compareTeams}
      {teamComparisonDialog}
    </React.Fragment>
  );
};

export default CompareTeams;
