import { IAppState } from "store/store"
import themis_common from "store/themis_common_pb"
import { RegistrationPaymentData } from "./programReducer"

export const selectCurrentLocations = (state: IAppState): themis_common.Location.AsObject[] =>
  (state.programState?.currentProgram.locationsList) ? state.programState.currentProgram.locationsList : []


export const selectLocation = (state: IAppState, locationId: number): themis_common.Location.AsObject | undefined => {
  let foundLocation
  if (state.programState?.currentProgram.locationsList.length) {
    foundLocation = state.programState.currentProgram.locationsList.find((location: themis_common.Location.AsObject) => location.id === locationId)
  }
  return foundLocation
}

export const selectCurrentProgram = (state: IAppState): themis_common.Program.AsObject | undefined =>
  state.programState?.currentProgram

export const selectCurrentLocation  = (state: IAppState): themis_common.Location.AsObject | undefined =>
  state.programState?.currentLocation

export const selectCurrentLocationTeams = (state: IAppState): themis_common.Team.AsObject[] =>
  state.programState?.currentLocation?.teamsList || []

export const selectCurrentTeam = (state: IAppState): themis_common.Team.AsObject | undefined =>
  state.programState.currentTeam

export const selectLocationAthletes = (state: IAppState): themis_common.Athlete.AsObject[] =>
  state.programState?.currentLocation?.athletesList || []

export const selectLocationCoaches = (state: IAppState): themis_common.Coach.AsObject[] =>
  state.programState?.currentLocation?.coachesList || []

export const selectLocationPersonnel = (state: IAppState): themis_common.Personnel.AsObject[] =>
  state.programState?.currentLocation?.personnelList || []

export const selectLocationGuardians = (state: IAppState): themis_common.Guardian.AsObject[] =>
  state.programState?.currentLocation?.guardiansList || []

export const selectAthlete = (state: IAppState, athleteId: number): themis_common.Athlete.AsObject | undefined =>
  state.programState.currentLocation?.athletesList.find((athlete: themis_common.Athlete.AsObject) => athlete.id === athleteId)

export const selectCoach = (state: IAppState, coachId: number): themis_common.Coach.AsObject | undefined =>
  state.programState.currentLocation?.coachesList.find((coach: themis_common.Coach.AsObject) => coach.id === coachId)

export const selectPersonnel = (state: IAppState, personnelId: number): themis_common.Personnel.AsObject | undefined =>
  state.programState.currentLocation?.personnelList.find((personnel: themis_common.Personnel.AsObject) => personnel.id === personnelId)

export const selectRegistrationPaymentData = (state: IAppState): RegistrationPaymentData =>
  state.programState.registrationPaymentData

export const selectEventRegistrationLogo = (state: IAppState): string =>
  state.programState.eventRegistrationLogo
