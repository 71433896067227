import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useStyles } from "lib/theme"
import { Button, Container, Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from "react-router-dom"
import { addObjectUser, getUserById, editUser } from "store/user/userActions"
import { AsFields, FormFields } from "lib/constants"
import YesNoDialog from 'components/util/YesNoModal'
import { buildErrors, capitalizeFirstLetter, fieldsOkay } from 'lib/validators'
import FormTextField from "components/util/FormTextField"
type addUserParams = { objectType: string, objectId: string, superObjectId: string, superDuperObjectId: string, userId: string }
const AddEditUser: React.FC = (): ReactElement => {

  const { objectType, objectId: inObjectId, superObjectId: inSuperObjectId, superDuperObjectId: inSuperDuperObjectId, userId: inUserId } = useParams<addUserParams>()

  const classes = useStyles()
  const history = useHistory()
  const objectId = Number(inObjectId)
  const superObjectId = Number(inSuperObjectId) || undefined
  const superDuperObjectId = Number(inSuperDuperObjectId) || undefined
  const userId = Number(inUserId) || 0

  const create = !userId ? 'Add' : 'Edit'
  const creating = !userId ? 'adding' : 'editing'

  const [user, setUser] = useState<AsFields>({ name: "", email: "" })
  const [checkUser, setCheckUser] = useState(false)
  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})

  const formFields: FormFields = useMemo(() => {
    return {
      name: { label: "Name", required: true, phone: false, email: false },
      email: { label: "Email", required: true, phone: false, email: true }
    }
  }, [])

  useEffect(() => {
    if (userId) {
      const getUser = async () => {
        const user = await getUserById(userId)
        setUser({ name: user.name, email: user.email })
      }
      getUser()
    }
    else setUser({ name: "", email: "" })
  }, [userId])

  const formReady = useMemo(() => fieldsOkay(user, formFields), [formFields, user])

  useEffect(() => {
    if (checkUser) {
      setErrorText(buildErrors(user, formFields, setUser))
      setCheckUser(false)
    }
  }, [checkUser, formFields, user])

  const submitForm = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    setCheckUser(true)
    if (!formReady) return

    try {
      if (userId) {
        await editUser(userId, user.name, user.email)
      } else {
        await addObjectUser(objectType, objectId, user.name, user.email, superObjectId, superDuperObjectId)
      }
      if (objectType.toLowerCase() === 'team') history.replace(`/Location/${superObjectId}/${capitalizeFirstLetter(objectType)}/${objectId}#users`)
      else if (objectType.toLowerCase() === 'event') history.replace(`/Brand/${superObjectId}/${capitalizeFirstLetter(objectType)}/${objectId}#users`)
      else history.replace(`/${capitalizeFirstLetter(objectType)}/${objectId}#users`)
    } catch (e) {
      setIsErrorOpen(true)
      console.error(e)
    }
  }

  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const verb = useMemo(() => {
    if (create === 'Add') return `${create} ${capitalizeFirstLetter(objectType)}`
    return create
  }, [create, objectType])

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{verb} User</Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid container item xs={8}>
          <Grid item xs={12} sm={6} md={6} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={user}
              setUpdateObject={setUser}
              name="name"
              autoFocus
              sliceSize={100}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={user}
              setUpdateObject={setUser}
              name="email"
              sliceSize={200}
            />
          </Grid>
          <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitForm}>
            {create} User
          </Button>
        </Grid>
        <YesNoDialog
          title={`Error Adding User`}
          question={`There was an error ${creating} your user. Please contact us if this continues to be a problem.`}
          isOpen={isErrorOpen}
          disabled={!formReady}
          onClose={() => setIsErrorOpen(false)}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
          ]}
        />
      </Grid>
    </Container>
  )
}

export default AddEditUser
