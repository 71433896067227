import { sheetData } from "lib/constants";
import RubricScoringForm from "./RubricScoring";


const SampleRubricScoringComponent: React.FC = () => {

  const sheetData: any = [
    {
      "data": {
        "title": "Building Level 4 Senior Rubric Area Example",
        "description": "https://www.unitedscoringpartners.com/wp-content/uploads/2023/10/23-24-Rubric-ASE-V9-10-15-23.pdf",
        "type": "coed",
        "items": [
          {
            "templateName": "template-1",
            "objectName": "stuntDifficulty",
            "judgeType": "stuntJudgeType",
            "data": {
              "rows": {
                "0": [
                  {
                    "title": "Stunt Difficulty",
                    "type": "label",
                    "visible": true
                  },
                  {
                    "key": "stuntDifficulty",
                    "title": "Stunt Difficulty",
                    "type": "dropdown",
                    "required": true,
                    "judgeType": "Stunt",
                    "visible": true,
                    "enums": [
                      {
                        "value": 2.5,
                        "label": "2.5 (Skills performed do not meet 3.0 requirement)"
                      },
                      {
                        "value": 3,
                        "label": "3.0 (4 different level appropriate skills performed by Most of the team)"
                      },
                      {
                        "value": 3.5,
                        "label": "3.5 (2 different level appropriate skills performed by Most of the team at the same time rippled or synchronized without recycling athletes)"
                      },
                      {
                        "value": 4,
                        "label": "4.0 (3 different level appropriate skills performed by Most of the team at the same time rippled or synchronized without recycling athletes)"
                      }
                    ]
                  },
                  {
                    "key": "isComment",
                    "title": "Comment",
                    "type": "switch",
                    "visible": true,
                    "value": true
                  },
                  {
                    "key": "comment",
                    "title": "Comment",
                    "type": "textboxarea",
                    "required": true,
                    "visible": true
                  }
                ]
              }
            }
          },
          {
            "templateName": "template-2",
            "objectName": "stuntDegreeOfDifficulty",
            "judgeType": "stuntJudgeType",
            "stuntMaxParticipationDriverThresholdValue": 0.8,
            "scoreMatrix": [
              {
                "simple": {
                  "Level Appropriate": {
                    "MAJORITY": 0,
                    "MOST": 0,
                    "MAX": 0
                  },
                  "Advanced": {
                    "MAJORITY": 0,
                    "MOST": 0.1,
                    "MAX": 0.1
                  },
                  "Elite": {
                    "MAJORITY": 0,
                    "MOST": 0.2,
                    "MAX": 0.2
                  }
                },
                "coed": {
                  "Coed Level Appropriate": 0,
                  "Coed Advanced": 0.1,
                  "Coed Elite": 0.2
                }
              }
            ],
            "data": {
              "rows": {
                "0": [
                  {
                    "title": "Stunt Degree of Difficulty (Skills)",
                    "type": "staticLabel",
                    "visible": true
                  },
                  {
                    "title": "Skill",
                    "type": "button",
                    "visible": true
                  },
                  {
                    "title": "Coed Skill",
                    "type": "button",
                    "visible": true
                  },
                  {
                    "key": "totalAthletes",
                    "title": "Total # of Athletes",
                    "type": "textbox",
                    "visible": true,
                    "required": true
                  },
                  {
                    "key": "totalMales",
                    "title": "Total # of Males",
                    "type": "textbox",
                    "visible": true,
                    "required": true,
                    "showHelpText": true,
                    "helpText": "# OF MALES ON TEAM: 1 Or More \n # OF STUNTS: 1"
                  }
                ],
                "1": [
                  {
                    "title": "Skill",
                    "type": "label",
                    "visible": true
                  },
                  {
                    "key": "skillTypes",
                    "title": "",
                    "type": "radiobutton",
                    "visible": true,
                    "required": true,
                    "enums": [
                      {
                        "title": "Level Appropriate",
                        "visible": true
                      },
                      {
                        "title": "Advanced",
                        "visible": true
                      },
                      {
                        "title": "Elite",
                        "visible": true
                      }
                    ]
                  },
                  {
                    "title": "Number of Groups Performing Skill",
                    "key": "numberOfGroupsPerformingSkill",
                    "type": "dropdownRange",
                    "rangeType": "noOfAthelete",
                    "required": true,
                    "visible": true,
                    "enums": [
                      {
                        "min": "5",
                        "max": "11",
                        "const": "1",
                        "title": "1 (MAJORITY)"
                      },
                      {
                        "min": "5",
                        "max": "11",
                        "const": "1",
                        "title": "1 (MOST)"
                      },
                      {
                        "min": "5",
                        "max": "11",
                        "const": "2",
                        "title": "2 (MAX)"
                      },
                      {
                        "min": "12",
                        "max": "15",
                        "const": "1",
                        "title": "1 (MAJORITY)"
                      },
                      {
                        "min": "12",
                        "max": "15",
                        "const": "1",
                        "title": "1 (MOST)"
                      },
                      {
                        "min": "12",
                        "max": "15",
                        "const": "3",
                        "title": "3 (MAX)"
                      },
                      {
                        "min": "16",
                        "max": "19",
                        "const": "2",
                        "title": "2 (MAJORITY)"
                      },
                      {
                        "min": "16",
                        "max": "19",
                        "const": "3",
                        "title": "3 (MOST)"
                      },
                      {
                        "min": "16",
                        "max": "19",
                        "const": "4",
                        "title": "4 (MAX)"
                      },
                      {
                        "min": "20",
                        "max": "23",
                        "const": "3",
                        "title": "3 (MAJORITY)"
                      },
                      {
                        "min": "20",
                        "max": "23",
                        "const": "4",
                        "title": "4 (MOST)"
                      },
                      {
                        "min": "20",
                        "max": "23",
                        "const": "5",
                        "title": "5 (MAX)"
                      },
                      {
                        "min": "24",
                        "max": "30",
                        "const": "4",
                        "title": "4 (MAJORITY)"
                      },
                      {
                        "min": "24",
                        "max": "30",
                        "const": "5",
                        "title": "5 (MOST)"
                      },
                      {
                        "min": "24",
                        "max": "30",
                        "const": "6",
                        "title": "6 (MAX)"
                      }
                    ]
                  },
                  {
                    "title": "Delete",
                    "type": "deleteButton",
                    "visible": true
                  }
                ],
                "2": [
                  {
                    "key": "calculationSummary",
                    "title": "Calculation Summary:",
                    "type": "calculationSummaryLabel",
                    "visible": true
                  },
                  {
                    "key": "scoreValue",
                    "title": "Score Value:",
                    "type": "scoreValueLabel",
                    "visible": true
                  },
                  {
                    "key": "isComment",
                    "title": "Comment",
                    "type": "switch",
                    "visible": true,
                    "value": true
                  },
                  {
                    "key": "comment",
                    "title": "Comment",
                    "type": "textboxarea",
                    "required": true,
                    "visible": true
                  }
                ],
                "3": [
                  {
                    "title": "Stunt Max Participation",
                    "type": "endLabel",
                    "visible": true
                  },
                  {
                    "key": "stuntMaxParticipationDriverCalculationSummary",
                    "title": "Calculation Summary",
                    "type": "dropdown",
                    "required": true,
                    "visible": true,
                    "enums": [
                      {
                        "value": 0.3,
                        "label": "0.3 (Level Appropriate Skill by MAX OR Advanced Skill by MOST)"
                      },
                      {
                        "value": 0.5,
                        "label": "0.5 (Advanced Skill by MAX OR Elite Skill by MOST)"
                      },
                      {
                        "value": 0.7,
                        "label": "0.7 (Elite Skill by MAX)"
                      }
                    ]
                  },
                  {
                    "key": "stuntMaxParticipationDriverScoreValue",
                    "title": "Score Value:",
                    "type": "endInnerLabel",
                    "visible": true
                  }
                ]
              }
            }
          },
          {
            "templateName": "template-1",
            "objectName": "pyramidDifficulty",
            "judgeType": "pyramidJudgeType",
            "data": {
              "rows": {
                "0": [
                  {
                    "title": "Pyramid Difficulty",
                    "type": "label",
                    "visible": true
                  },
                  {
                    "key": "pyramidDifficulty",
                    "title": "Pyramid Difficulty",
                    "type": "dropdownRange",
                    "required": true,
                    "visible": true,
                    "showHelpText": true,
                    "helpText": "2.0-2.5 | Below - Skills performed do not meet Low range requirement \n 2.5-3.0 | LOW - 2 different level appropriate skills and 2 structures \n 3.0-3.5 | MID - 3 different level appropriate skills and 2 structures performed by MOST of the team \n 3.5 - 4.0 | HIGH - 4 different level appropriate skills and 2 structures performed by MOST of the team",
                    "enums": [
                      {
                        "value": "2.0-2.5",
                        "label": "2.0-2.5 | BELOW"
                      },
                      {
                        "value": "2.5-3.0",
                        "label": "2.5-3.0 | LOW"
                      },
                      {
                        "value": "3.0-3.5",
                        "label": "3.0-3.5 | MID"
                      },
                      {
                        "value": "3.5-4.0",
                        "label": "3.5-4.0 | HIGH"
                      }
                    ]
                  },
                  {
                    "key": "pyramidDifficultyValue",
                    "title": "Pyramid Difficulty Value",
                    "type": "textboxRange",
                    "rangeToValidateWith": "pyramidDifficulty",
                    "required": true,
                    "visible": true
                  },
                  {
                    "key": "isComment",
                    "title": "Comment",
                    "type": "switch",
                    "visible": true,
                    "value": true
                  },
                  {
                    "key": "comment",
                    "title": "Comment",
                    "type": "textboxarea",
                    "required": true,
                    "visible": true
                  }
                ]
              }
            }
          },
          {
            "templateName": "template-1",
            "objectName": "tossDifficulty",
            "judgeType": "tossJudgeType",
            "data": {
              "rows": {
                "0": [
                  {
                    "title": "Toss Difficulty",
                    "type": "label",
                    "visible": true
                  },
                  {
                    "key": "tossDifficulty",
                    "title": "Toss Difficulty (1.0, 1.5, 2.0)",
                    "type": "dropdown",
                    "required": true,
                    "visible": true,
                    "enums": [
                      {
                        "value": 1,
                        "label": "1.0 (Less than a MAJORITY of team performs a toss)"
                      },
                      {
                        "value": 1.5,
                        "label": "1.5 (MAJORITY of the team performs a level appropriate toss)"
                      },
                      {
                        "value": 2,
                        "label": "2.0 (MAJORITY of the team performs a level appropriate toss rippled or synchronized in the same section)"
                      }
                    ]
                  },
                  {
                    "key": "isComment",
                    "title": "Comment",
                    "type": "switch",
                    "visible": true,
                    "value": true
                  },
                  {
                    "key": "comment",
                    "title": "Comment",
                    "type": "textboxarea",
                    "required": true,
                    "visible": true
                  }
                ]
              }
            }
          }
        ]
      }
    },
    {
      "data": {
        "title": "Execution Level 4 Senior Rubric Area Example",
        "description": "https://www.unitedscoringpartners.com/wp-content/uploads/2023/10/23-24-Rubric-ASE-V9-10-15-23.pdf",
        "type": "simple",
        "items": [
          {
            "templateName": "template-3",
            "objectName": "stuntPyramidStandingRunningTumbling",
            "judgeType": "stuntPyramidStandingRunningTumblingJudgeType",
            "data": {
              "rows": {
                "0": [
                  {
                    "title": "Execution - Stunt/Pyramid & Standing/Running Tumbling",
                    "type": "staticLabel",
                    "visible": true
                  },
                  {
                    "title": "Add Reduction",
                    "type": "button",
                    "visible": true
                  },
                  {
                    "key": "stuntPyramidStandingRunningTumbling",
                    "title": "Stunt/Pyramid & Standing/Running Tumbling ",
                    "type": "dropdown",
                    "required": true,
                    "visible": true,
                    "enums": [
                      {
                        "value": 0.1,
                        "label": "0.1 (Minor technique issues by the team, not just 1 athlete in Tumbling or 1 athlete in Stunts/Pyramid)"
                      },
                      {
                        "value": 0.2,
                        "label": "0.2 (Multiple technique issues by the team)"
                      },
                      {
                        "value": 0.3,
                        "label": "0.3 (Widespread technique issues by the team)"
                      }
                    ]
                  },
                  {
                    "key": "isComment",
                    "title": "Comment",
                    "type": "switch",
                    "visible": true,
                    "value": true
                  },
                  {
                    "key": "comment",
                    "title": "Comment",
                    "type": "textboxarea",
                    "required": true,
                    "visible": true
                  },
                  {
                    "title": "Delete",
                    "type": "deleteButton",
                    "visible": true
                  }
                ],
                "1": [
                  {
                    "title": "Selected Value:",
                    "type": "label",
                    "visible": true
                  },
                  {
                    "title": "Score Summary:",
                    "type": "endLabel",
                    "visible": true
                  },
                  {
                    "key": "maximumValue",
                    "value": 4,
                    "allowNegative": false
                  }
                ]
              }
            }
          },
          {
            "templateName": "template-3",
            "objectName": "tossJumps",
            "judgeType": "tossJumpsJudgeType",
            "data": {
              "rows": {
                "0": [
                  {
                    "title": "Execution - Toss & Jumps",
                    "type": "staticLabel",
                    "visible": true
                  },
                  {
                    "title": "Add Reduction",
                    "type": "button",
                    "visible": true
                  },
                  {
                    "key": "tossJumps",
                    "title": "Toss & Jumps",
                    "type": "dropdown",
                    "required": true,
                    "visible": true,
                    "enums": [
                      {
                        "value": 0.1,
                        "label": "0.1 ( Minor technique issues by the team, not just 1 athlete in Jumps or 1 athlete in Tosses)"
                      },
                      {
                        "value": 0.2,
                        "label": "0.2 (Multiple technique issues by the team)"
                      },
                      {
                        "value": 0.3,
                        "label": "0.3 (Widespread technique issues by the team)"
                      }
                    ]
                  },
                  {
                    "key": "isComment",
                    "title": "Comment",
                    "type": "switch",
                    "visible": true,
                    "value": true
                  },
                  {
                    "key": "comment",
                    "title": "Comment",
                    "type": "textboxarea",
                    "required": true,
                    "visible": true
                  },
                  {
                    "title": "Delete",
                    "type": "deleteButton",
                    "visible": true
                  }
                ],
                "1": [
                  {
                    "title": "Selected Value:",
                    "type": "label",
                    "visible": true
                  },
                  {
                    "title": "Score Summary:",
                    "type": "endLabel",
                    "visible": true
                  },
                  {
                    "key": "maximumValue",
                    "value": 2,
                    "allowNegative": true
                  }
                ]
              }
            }
          }
        ]
      }
    }
  ]

  const responseData = () => { };
  const initialJsonValues = {};

  return (<RubricScoringForm responseData={responseData} initialJsonValues={initialJsonValues} sheetData={sheetData} judgeType={["stuntJudgeType", "pyramidJudgeType", "tossJudgeType", "stuntPyramidStandingRunningTumblingJudgeType", "tossJumpsJudgeType"]} />);
};

export default SampleRubricScoringComponent;
