/* eslint max-lines: "off" */
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import Balance from "./Balance"
import themis_api from "store/themis_api_pb"
import { getLocationBalance, getProgramBalance, getTeamBalance } from "store/program/balanceActions"
import themis_common from "store/themis_common_pb"
import { IAppState } from "store/store"
import { selectCurrentProgram } from "store/program/programSelectors"
import { selectPermissionCodes, selectSuperUser, selectUserProgramPermissions } from 'store/user/userSelectors'
import { EventTeamInfo, RegistrationPaymentData, emptyEventTeam } from 'store/program/programReducer'
import { addSelectedRegistrationsForPayment } from 'store/program/programActions'
import { removeEventTeamAthlete } from 'store/program/eventTeamActions'

interface LocationState {
  pathname: string;
}

export interface BalanceType {
  balance: number
  currency: string
}

export interface BalanceEntry {
  id: number
  name: string
  balances: BalanceType[]
  checked: boolean
  athlete: themis_common.Athlete.AsObject | undefined
  eventTeam: themis_common.EventTeam.AsObject | undefined
}

interface BalanceTeam {
  id: number
  name: string
  balances: BalanceType[]
  entries: BalanceEntry[]
}

export interface BalanceLocation {
  id: number
  name: string
  balances: BalanceType[]
  teams: BalanceTeam[]
}

interface BalanceEvent {
  id: number
  name: string
  balances: BalanceType[]
  locations: BalanceLocation[]
}

interface BalanceBrand {
  id: number
  name: string
  balances: BalanceType[]
  events: BalanceEvent[]
}

export interface BalanceProducer {
  id: number
  name: string
  balances: BalanceType[]
  brands: BalanceBrand[]
}

type BalanceProducers = BalanceProducer[]

export interface BalanceTotals {
  producers: BalanceProducers
  balances: BalanceType[]
}

export interface PaymentDetails {
  balances: BalanceType[]
  locations: BalanceLocation[]
}

const BalanceContainer = (): ReactElement => {
  const location = useLocation<LocationState>()
  const dispatch = useDispatch()
  const history = useHistory()

  const emptyBalanceResponse: themis_api.GetAccountBalanceResponse.AsObject = {
    brandId: 0,
    crPayoutTotal: 0,
    eventId: 0,
    locationId: 0,
    producerId: 0,
    programId: 0,
    scope: 0,
    teamId: 0,
    total: 0,
    transactionsList: []
  }

  const athlete = new themis_common.Athlete()

  const program = useSelector((state: IAppState) => selectCurrentProgram(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const [paid, setPaid] = useState<themis_common.PaidScopeMap[keyof themis_common.PaidScopeMap]>(1)
  const [balanceData, setBalanceData] = useState<themis_api.GetAccountBalanceResponse.AsObject>(emptyBalanceResponse)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [selectedToPay, setSelectedToPay] = useState<string>("")
  const [selectedRegistrationsForPayment, setSelectedRegistrationsForPayment] = useState<number[]>([])
  const [selectedEventTeams, setSelectedEventTeams] = useState<EventTeamInfo[]>([])
  const [sortedPayData, setSortedPayData] = useState<PaymentDetails>({ balances: [], locations: [] })

  const [athleteToDeleteObj, setAthleteToDeleteObj] = useState<themis_common.Athlete.AsObject>(athlete.toObject())
  const [athleteToDeleteEventTeamObj, setAthleteToDeleteEventTeamObj] = useState<themis_common.EventTeam.AsObject>(emptyEventTeam)
  const [entryToDeleteIndex, setEntryToDeleteIndex] = useState<number>(0)
  const [isRemoveEventTeamAthleteOpen, setIsRemoveEventTeamAthleteOpen] = useState<boolean>(false)

  const payRegistration = async (amountSelectedToPay: number, currency: string) => {
    if (program && program.id > 0 && amountSelectedToPay >= 0 && selectedRegistrationsForPayment.length > 0) {
      const registrationPaymentData: RegistrationPaymentData = {
        programId: program.id,
        displayBalanceAmount: amountSelectedToPay,
        selectedRegistrationsForPayment: selectedRegistrationsForPayment,
        eventTeamInfo: [],
        currency: currency,
        sourcePath: history.location.pathname + history.location.hash
      }
      await addSelectedRegistrationsForPayment(dispatch, registrationPaymentData)
      history.push(`/Program/${program.id}/RegistrationPayment`)
    }
  }

  const programId = program?.id ? program.id : 0

  const getBalance = async (): Promise<void> => {
    if (!program || !location) return

    const splitLocation = location.pathname.split("/")
    splitLocation.shift()
    let balance
    if (splitLocation[0].toLowerCase() === "program") {
      balance = await getProgramBalance(Number(splitLocation[1]), paid)
    } else if (splitLocation[0].toLowerCase() === "location") {
      if (!splitLocation[3]) {
        balance = await getLocationBalance(program.id, Number(splitLocation[1]), paid)
      } else if (splitLocation[2].toLowerCase() === 'team') {
        balance = await getTeamBalance(program.id, Number(splitLocation[1]), Number(splitLocation[3]), paid)
      }
    }
    if (balance === undefined) return
    setBalanceData(balance)
    return
  }

  useEffect(() => {
    getBalance()
  },[location, paid, program, refresh])

  const balanceTotals = useMemo<BalanceTotals>(() => {
    const producerList: BalanceProducers = []
    // let counter = 0

    const transactionsList = balanceData.transactionsList.map(transaction => {
      const amtPaid =  transaction.paymentsList.reduce<number>((total, txPayment) => total + txPayment.amount, 0)
      transaction.amount -= amtPaid
      return transaction
    })

    for (const entry of transactionsList) {
      const newEntry: BalanceEntry = { id: entry.id, name: entry.description, balances: [{ currency: entry.currency, balance: entry.amount }], checked: false, athlete: entry.athlete, eventTeam: entry.eventTeam }
      const newTeam: BalanceTeam = { id: entry.teamId, entries: [newEntry], name: entry.eventTeam?.name || "No Team Name", balances: [{ currency: entry.currency, balance: entry.amount }] }
      const newLocation: BalanceLocation = { id: entry.locationId, teams: [newTeam], name: entry.location?.name || "No Location Name", balances: [{ currency: entry.currency, balance: entry.amount }] }
      const newEvent: BalanceEvent = { id: entry.eventId, locations: [newLocation], name: entry.event?.name || "No Event Name", balances: [{ currency: entry.currency, balance: entry.amount }] }
      const newBrand: BalanceBrand = { id: entry.brandId, name: entry.brand?.name || "No Brand Name", events: [newEvent], balances: [{ currency: entry.currency, balance: entry.amount }] }
      const newProducer: BalanceProducer = { id: entry.producerId, name: entry.producer?.name || "No Producer Name", brands: [newBrand], balances: [{ currency: entry.currency, balance: entry.amount }] }

      const foundProducer = producerList.find((producer) => producer.id === entry.producerId )
      if (!foundProducer) {
        producerList.push(newProducer)
      } else {
        const foundProducerBalance = foundProducer.balances.find((balance) => balance.currency === entry.currency)
        if (foundProducerBalance) foundProducerBalance.balance += entry.amount
        else foundProducer.balances.push({ currency: entry.currency, balance: entry.amount })
        const foundBrand = foundProducer.brands.find((brand) => brand.id === entry.brandId )
        if (!foundBrand) {
          foundProducer.brands.push(newBrand)
        } else {
          const foundBrandBalance = foundBrand.balances.find((balance) => balance.currency === entry.currency)
          if (foundBrandBalance) foundBrandBalance.balance += entry.amount
          else foundBrand.balances.push({ currency: entry.currency, balance: entry.amount })
          const foundEvent = foundBrand.events.find((event) => event.id === entry.eventId )
          if (!foundEvent) {
            foundBrand.events.push(newEvent)
          } else {
            const foundEventBalance = foundEvent.balances.find((balance) => balance.currency === entry.currency)
            if (foundEventBalance) foundEventBalance.balance += entry.amount
            else foundEvent.balances.push({ currency: entry.currency, balance: entry.amount })
            const foundLocation = foundEvent.locations.find((location) => location.id === entry.locationId)
            if (!foundLocation) {
              foundEvent.locations.push(newLocation)
            } else {
              const foundLocationBalance = foundLocation.balances.find((balance) => balance.currency === entry.currency)
              if (foundLocationBalance) foundLocationBalance.balance += entry.amount
              else foundLocation.balances.push({ currency: entry.currency, balance: entry.amount })
              const foundTeam = foundLocation.teams.find((team) => team.id === entry.teamId)
              if (!foundTeam) {
                foundLocation.teams.push(newTeam)
              } else {
                const foundTeamBalance = foundTeam.balances.find((balance) => balance.currency === entry.currency)
                if (foundTeamBalance) {
                  foundTeamBalance.balance += entry.amount
                } else {
                  foundTeam.balances.push({ currency: entry.currency, balance: entry.amount })
                }
                foundTeam.entries.push(newEntry)
              }
            }
          }
        }
      }
    }

    const finalBalances: BalanceType[] = []
    producerList.forEach((producer) => {
      producer.balances.forEach((producerBalance) => {
        const finalBalance = finalBalances.find((balance) => balance.currency === producerBalance.currency)
        if (finalBalance) finalBalance.balance += producerBalance.balance
        else finalBalances.push({ currency: producerBalance.currency, balance: producerBalance.balance })
      })
    })

    const totals: BalanceTotals = {
      producers: producerList,
      balances: finalBalances
    }

    return totals
  }, [balanceData])

  useEffect(() => {
    const paymentDetails: PaymentDetails = { balances: [], locations: [] }
    const splitSelected = selectedToPay.split('/')
    const producerId = Number(splitSelected[0])
    const brandId = Number(splitSelected[1] || "0")
    const eventId = Number(splitSelected[2] || "0")

    const foundProducerData = balanceTotals.producers.find((producer) => producer.id === producerId)
    if (!foundProducerData) {
      setSortedPayData(paymentDetails)
      return
    }
    const producerData: BalanceProducer = JSON.parse(JSON.stringify(foundProducerData)) // Deep copy the data here as we don't want to change the original
    paymentDetails.balances = producerData.balances
    producerData.brands.forEach((dataBrand) => {
      if (brandId === 0 || dataBrand.id === brandId) {
        dataBrand.events.forEach((dataEvent) => {
          if (eventId === 0 || dataEvent.id === eventId) {
            dataEvent.locations.forEach((dataLocation) => {
              const foundLocation = paymentDetails.locations.find((loc) => loc.id === dataLocation.id)
              if (foundLocation) {
                dataLocation.balances.forEach((dataLocationBalance) => {
                  const foundBalance = foundLocation.balances.find((foundBalance) => foundBalance.currency === dataLocationBalance.currency)
                  if (foundBalance) {
                    foundBalance.balance += dataLocationBalance.balance
                  } else {
                    foundLocation.balances.push(dataLocationBalance)
                  }
                })
                dataLocation.teams.forEach((dataTeam) => {
                  const foundTeam = foundLocation.teams.find((team) => team.id === dataTeam.id)
                  if (foundTeam) {
                    dataTeam.balances.forEach((dataTeamBalance) => {
                      const foundTeamBalance = foundTeam.balances.find((foundBalance) => foundBalance.currency === dataTeamBalance.currency)
                      if (foundTeamBalance) {
                        foundTeamBalance.balance += dataTeamBalance.balance
                      } else {
                        foundTeam.balances.push(dataTeamBalance)
                      }
                    })
                    if (dataTeam.entries.length === 0) {
                      foundTeam.entries = dataTeam.entries
                    } else {
                      foundTeam.entries = dataTeam.entries.concat(foundTeam.entries)
                    }
                  } else {
                    foundLocation.teams.push(dataTeam)
                  }
                })
              } else {
                paymentDetails.locations.push(dataLocation)
              }
            })
          }
        })
      }
    })
    setSortedPayData(paymentDetails)
  }, [balanceTotals.producers, selectedToPay])


  const refreshData = () => {
    setRefresh((prev) => !prev)
  }

  const deleteEventTeamAthlete = () => {
    const doDeleteEventTeamAthlete = async() => {
      await removeEventTeamAthlete(dispatch, athleteToDeleteEventTeamObj, athleteToDeleteObj)
      getBalance()
      setIsRemoveEventTeamAthleteOpen(false)
    }
    doDeleteEventTeamAthlete()
  }

  return <Balance
    balanceTotals={balanceTotals}
    paid={paid}
    setPaid={setPaid}
    refreshData={refreshData}
    sortedPayData={sortedPayData}
    setSortedPayData={setSortedPayData}
    selectedToPay={selectedToPay}
    setSelectedToPay={setSelectedToPay}
    currentProgramId={programId}
    superUser={superUser}
    programPermissions={programPermissions}
    permissionCodes={permissionCodes}
    setSelectedRegistrationsForPayment={setSelectedRegistrationsForPayment}
    setSelectedEventTeams={setSelectedEventTeams}
    payRegistration={payRegistration}
    deleteEventTeamAthlete={deleteEventTeamAthlete}
    setAthleteToDeleteEventTeamObj={setAthleteToDeleteEventTeamObj}
    setAthleteToDeleteObj={setAthleteToDeleteObj}
    setEntryToDeleteIndex={setEntryToDeleteIndex}
    setIsRemoveEventTeamAthleteOpen={setIsRemoveEventTeamAthleteOpen}
    isRemoveEventTeamAthleteOpen={isRemoveEventTeamAthleteOpen}
    entryToDeleteIndex={entryToDeleteIndex}
  />
}

export default BalanceContainer
