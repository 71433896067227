import React, { useState, useMemo, ReactElement, FormEvent } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { FormFields } from "lib/constants"
import { fieldsOkay } from "lib/validators"
import { addLocation, doSetLocation, updateLocation } from "store/program/locationActions"
import { IAppState } from "store/store"
import { selectCurrentLocations, selectCurrentProgram, selectLocation } from "store/program/programSelectors"
import { emptyLocation } from "store/program/programReducer"
import themis_common from "store/themis_common_pb"
import { refreshUser } from "store/user/userActions"
import AddEditLocation from './AddEditLocation'

const AddEditLocationContainer: React.FC = (): ReactElement => {

  const history = useHistory()
  const dispatch = useDispatch()

  const { locationId: inLocationId } = useParams<{ locationId?: string | undefined }>()
  const locationId = Number(inLocationId)

  const program = useSelector((state: IAppState) => selectCurrentProgram(state))
  const location = useSelector((state: IAppState) => selectLocation(state, locationId || 0))
  const locations = useSelector((state: IAppState) => selectCurrentLocations(state))

  const [checkLocation, setCheckLocation] = useState(false)
  const [updatedLocation, setUpdatedLocation] = useState(emptyLocation)
  const [isErrorOpen, setIsErrorOpen] = useState(false)


  const formFields: FormFields = useMemo(() => {
    return {
      name: { label: "Location Name", required: true, phone: false, email: false },
      city: { label: "City", required: true, phone: false, email: false },
      state: { label: "State or Province", required: true, phone: false, email: false },
      country: { label: "Country", required: true, phone: false, email: false },
      address: { label: "Address", required: true, phone: false, email: false },
      address2: { label: "Address 2", required: false, phone: false, email: false },
      postalCode: { label: "Postal Code", required: true, phone: false, email: false },
      phone: { label: "Contact Phone", required: true, phone: true, email: false },
      cellPhone: { label: "Cell Phone", required: false, phone: true, email: false },
      secondaryPhone: { label: "Secondary Phone", required: false, phone: true, email: false },
      email: { label: "Email", required: true, phone: false, email: true }
    }
  }, [])

  const formReady: boolean = useMemo(() => fieldsOkay(updatedLocation, formFields), [updatedLocation, formFields])


  const submitForm = async (event: FormEvent) => {
    setCheckLocation(true)
    event.preventDefault()
    if (formReady) {
      if (!locationId) { // Add
        if (!program || !program.id) {
          return
        }
        const location = new themis_common.Location()
        location.setName(updatedLocation.name)
        location.setCountry(updatedLocation.country)
        location.setCity(updatedLocation.city)
        location.setState(updatedLocation.state)
        location.setAddress(updatedLocation.address)
        location.setAddress2(updatedLocation.address2)
        location.setPostalCode(updatedLocation.postalCode)
        location.setPhone(updatedLocation.phone)
        location.setSecondaryPhone(updatedLocation.secondaryPhone)
        location.setCellPhone(updatedLocation.cellPhone)
        location.setEmail(updatedLocation.email)
        location.setProgramId(program.id)
        const doAddLocation = addLocation(location)
        try {
          const location: themis_common.Location.AsObject = await doAddLocation(dispatch)
          await refreshUser(dispatch)
          history.replace(`/Location/${location.id}`)
        } catch (e) {
          setIsErrorOpen(true)
          console.error(e)
        }
      } else { // Edit
        if (!program || !program.id) return
        const location = new themis_common.Location()
        location.setId(locationId)
        location.setName(updatedLocation.name)
        location.setCountry(updatedLocation.country)
        location.setCity(updatedLocation.city)
        location.setState(updatedLocation.state)
        location.setAddress(updatedLocation.address)
        location.setAddress2(updatedLocation.address2)
        location.setPostalCode(updatedLocation.postalCode)
        location.setPhone(updatedLocation.phone)
        location.setSecondaryPhone(updatedLocation.secondaryPhone)
        location.setCellPhone(updatedLocation.cellPhone)
        location.setEmail(updatedLocation.email)
        location.setProgramId(program.id)
        const doEditLocation = updateLocation(location)
        try {
          await doEditLocation(dispatch)
          await refreshUser(dispatch)
          await doSetLocation(dispatch, program.id, locationId)
          history.replace(`/Location/${locationId}`)
        } catch (e) {
          setIsErrorOpen(true)
          console.error(e)
        }

      }

    }
  }



  return (
    <AddEditLocation
      location={location}
      updatedLocation={updatedLocation}
      program={program}
      locationId={locationId}
      setUpdatedLocation={setUpdatedLocation}
      locations={locations}
      checkLocation={checkLocation}
      formFields={formFields}
      setCheckLocation={setCheckLocation}
      isErrorOpen={isErrorOpen}
      setIsErrorOpen={setIsErrorOpen}
      submitForm={submitForm}
    />
  )
}

export default AddEditLocationContainer
