import { client } from "store/themisClient"
import themis_common from "store/themis_common_pb"
import { buildCoach } from "./programBuilders"
import { createAction } from "redux-actions"
import { AnyDispatchType, SET_COACHES } from "lib/constants"

const setCoaches = createAction<themis_common.Coach.AsObject[]>(SET_COACHES)

async function getCoach(dispatch: AnyDispatchType, coachId: number, programId: number, locationId: number, validateTeamId: number): Promise<themis_common.Coach.AsObject> {
  const coachRequest = new themis_common.Coach()

  coachRequest.setProgramId(programId)
  coachRequest.setLocationId(locationId)
  coachRequest.setId(coachId)
  if (validateTeamId && validateTeamId > 0) coachRequest.setValidateTeamId(validateTeamId)

  const res = await client.getCoach(coachRequest, {})
  const coachResponse = res.toObject()
  return coachResponse.coach

}

async function getCoachTeams(dispatch: AnyDispatchType, coachId: number, programId: number, locationId: number, validateTeamId: number): Promise<themis_common.Team.AsObject[]> {
  const coachRequest = new themis_common.Coach()

  coachRequest.setProgramId(programId)
  coachRequest.setLocationId(locationId)
  coachRequest.setId(coachId)
  if (validateTeamId && validateTeamId > 0) coachRequest.setValidateTeamId(validateTeamId)

  const res = await client.getCoachTeams(coachRequest, {})
  const teamResponse = res.toObject()
  return teamResponse.teamsList

}

async function addCoach(dispatch: AnyDispatchType, coachPerson: themis_common.Person.AsObject, types: themis_common.AllStarType.AsObject[], programId: number, locationId: number,): Promise<themis_common.Coach.AsObject> {

  const coachRequest = buildCoach(undefined, programId, locationId, coachPerson, types)

  const res = await client.addCoach(coachRequest, {})
  const coachResponse = res.toObject()
  const coaches = coachResponse.coachesList
  const coach = coachResponse.coach
  dispatch(setCoaches(coaches))
  return coach
}

async function updateCoach(dispatch: AnyDispatchType, coachId: number, coachPerson: themis_common.Person.AsObject, types: themis_common.AllStarType.AsObject[], programId: number, locationId: number, validateTeamId: number): Promise<void> {

  const coachRequest = buildCoach(coachId, programId, locationId, coachPerson, types, validateTeamId)
  const res = await client.updateCoach(coachRequest, {})
  const coachResponse = res.toObject()
  const coaches = coachResponse.coachesList

  dispatch(setCoaches(coaches))
}

export {
  getCoach,
  getCoachTeams,
  addCoach,
  updateCoach,
}

