import { TextField } from "@material-ui/core";
import { useStyles } from "lib/theme";

interface TextboxProps {
    element: any;
    handleTextFieldChange: Function;
    values: any;
    setTotalAthletes?: any;
    errorText?: any;
    index?: number;
}

const TextboxComponent: React.FC<TextboxProps> = ({
    element,
    handleTextFieldChange,
    values,
    setTotalAthletes,
    errorText,
    index
}) => {
    const classes = useStyles();

    const handleChange = (event: any) => {
        let value = event.target.value;
        if (index !== undefined) {
            handleTextFieldChange(event, index);
        } 
        else {
            if (setTotalAthletes) {
                setTotalAthletes(parseInt(value));    
            }
            handleTextFieldChange(event);
        }      
    }

    const multiple = !!((index || index === 0) && (errorText[index] ? true : false));
    const single = errorText ? true : false;

    return (
        <TextField
            variant="outlined"
            fullWidth
            required={element.required}
            value={values ? values || '' : undefined}
            id={element.key}
            name={element.key}
            label={element.title}
            onChange={(event) => {
                handleChange(event)
            }}
            className={classes.textBoxStyle}
            error={(index !== undefined) ? multiple : single}
            helperText={(index !== undefined) ? errorText[index] : errorText}
        />
    );
};

export default TextboxComponent;