import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import { getUserPaymentSources } from "store/user/userStripeActions"
import themis_common from "store/themis_common_pb"
import BankAccountDetails from "./BankAccountDetails"
import CreditCardDetails from "./CreditCardDetails"

interface PaymentSourcesListProps {
  useCase: "manage-payment-source" | "select-payment-source"
  parentSelectedSourceId?: string
  setParentStripeSourceType?: React.Dispatch<React.SetStateAction<"bank-account" | "credit-card" | undefined>>
  setParentStripeSourceId?: React.Dispatch<React.SetStateAction<string>>
  setParentSourceDetails?: React.Dispatch<themis_common.StripeBankAccount.AsObject | themis_common.StripeCreditCard.AsObject | undefined>
}

const PaymentSourcesList: React.FC<PaymentSourcesListProps> = ({ useCase, parentSelectedSourceId, setParentStripeSourceType, setParentStripeSourceId, setParentSourceDetails }): React.ReactElement => {
  const [userBankAccounts, setUserBankAccounts] = useState<themis_common.StripeBankAccount.AsObject[]>([])
  const [userCreditCards, setUserCreditCards] = useState<themis_common.StripeCreditCard.AsObject[]>([])
  const [sourceListIdSelected, setSourceListIdSelected] = useState(0)
  const [stripeSourceType, setStripeSourceType] = useState<"bank-account" | "credit-card">()
  const [stripeSourceId, setStripeSourceId] = useState("")
  const [customerId, setCustomerId] = useState("")

  const getPaymentSources = async () => {
    const response = await getUserPaymentSources()
    setCustomerId(response.customerId)
    setUserBankAccounts(response.bankAccountsList)
    setUserCreditCards(response.creditCardsList)
  }

  useEffect(() => {
    getPaymentSources()
  }, [])

  const selectSource = useCallback((sourceListId: number, checked: boolean, stripeSourceType: "bank-account" | "credit-card", stripeSourceId: string, sourceDetails: themis_common.StripeBankAccount.AsObject | themis_common.StripeCreditCard.AsObject) => {
    if (checked) {
      setSourceListIdSelected(sourceListId)
      setStripeSourceType(stripeSourceType)
      setStripeSourceId(stripeSourceId)
      if (setParentStripeSourceType) setParentStripeSourceType(stripeSourceType)
      if (setParentStripeSourceId) setParentStripeSourceId(stripeSourceId)
      if (setParentSourceDetails) setParentSourceDetails(sourceDetails)
    }
  }, [setParentStripeSourceType, setParentStripeSourceId, setParentSourceDetails])

  const paymentSourceList = useMemo(() => {
    let sourceListId = 0
    const bankAccountList = userBankAccounts.map((account, i) => {
      sourceListId++
      if (account.id === parentSelectedSourceId) {
        setSourceListIdSelected(sourceListId)
      }
      return (<BankAccountDetails key={`bank-account-details-${sourceListId}`} useCase={useCase} bankAccount={account} refreshPaymentSourceList={getPaymentSources} sourceListId={sourceListId} sourceListIdSelected={sourceListIdSelected} selectSource={selectSource} />)
    })
    const creditCardList = userCreditCards.map((creditCard, i) => {
      sourceListId++
      if (creditCard.id === parentSelectedSourceId) {
        setSourceListIdSelected(sourceListId)
      }
      return (<CreditCardDetails key={`credit-card-details-${sourceListId}`} useCase={useCase} creditCard={creditCard} sourceListId={sourceListId} sourceListIdSelected={sourceListIdSelected} selectSource={selectSource} />)
    })

    return [...bankAccountList, ...creditCardList]
  }, [useCase, userBankAccounts, userCreditCards, sourceListIdSelected, selectSource, parentSelectedSourceId])

  return <>
    {paymentSourceList.length > 0 ? paymentSourceList : (customerId.length > 0 ? <Grid container justify="center"><Typography variant="h3">No Payment Methods</Typography></Grid> : <Grid container justify="center"><Typography variant="h3">Getting Payment Methods <CircularProgress color="secondary" size={20} /></Typography></Grid>)}
    {useCase === "manage-payment-source" ? (
      <Grid container justify="center">
        <Box mt={2} mb={3}>
          <Button variant="contained" color="default" aria-label="add-payment-method-button" component={RouterLink} to={`/AddPaymentMethod`}>Add a Payment Method</Button>
        </Box>
      </Grid>
    ) : <></>}
  </>
}

export default PaymentSourcesList
