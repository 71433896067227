import React, { ChangeEvent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { countries } from "lib/constants"
import { useStyles } from "lib/theme"
import { Link, Link as RouterLink } from "react-router-dom"
import {
  AppBar, Container, FormControlLabel, FormGroup, Grid,
  IconButton, MenuItem, Switch, Tab, Tabs, Typography, useTheme
} from '@material-ui/core'
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, } from '@material-ui/icons'
import { formatEventDates } from "store/producer/eventActions"
import { format, parse } from "date-fns"
import { TabPanel } from "lib/pieces"
import themis_common from "store/themis_common_pb"
import YesNoDialog from "components/util/YesNoModal"
import UserGrid from "components/user/user_grid/UserGrid"
import { ProducerPermissions } from 'store/user/userReducer'
import { PermissionLists } from 'store/user/userActions'
import { validateBrandPermission, validateEventPermission } from 'lib/permissions'
import JudgeGrid from '../judge/JudgeGrid'

const countryItems: any[] = []
for (const country of countries) {
  countryItems.push(<MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>)
}

interface BrandProps {
  superUser: boolean
  producerPermissions: ProducerPermissions
  permissionLists: PermissionLists
  permissionCodes: themis_common.PermissionCodes.AsObject
  brandId: number
  producer: themis_common.Producer.AsObject
  brand: themis_common.Brand.AsObject
  events: themis_common.Event.AsObject[]
  currentTab: number
  handleChangeTab: (event: ChangeEvent<{}>, value: number) => void
  handleDeleteBrand: Function
  handleDeleteEvent: Function
  isBrandOpen: boolean
  isEventOpen: boolean
  setIsBrandOpen?: (value: boolean | ((prevVar: boolean) => boolean)) => void
  setIsEventOpen?: (value: boolean | ((prevVar: boolean) => boolean)) => void
  setDeleteEvent?: (event: themis_common.Event.AsObject) => void
  eventToDelete?: themis_common.Event.AsObject | null
  producerJudges: themis_common.Judge.AsObject[]
  handleAddJudgeToBrand: (judgeId: number) => void
  handleDeleteJudgeFromBrand: (judgeId: number) => void
}

const Brand: React.FC<BrandProps> = ({
  superUser, producerPermissions, permissionLists, brandId, producer, brand, events, currentTab,
  handleChangeTab, handleDeleteBrand, handleDeleteEvent,
  isBrandOpen, isEventOpen, setIsBrandOpen, setIsEventOpen, setDeleteEvent, eventToDelete, permissionCodes,
  producerJudges, handleAddJudgeToBrand, handleDeleteJudgeFromBrand
}): ReactElement => {
  const classes = useStyles()
  const theme = useTheme()

  const [showAllEvents, setShowAllEvents] = useState(false)
  const [eventsTabEnabled, setEventsTabEnabled] = useState(false)

  // If brand has "EV", enable events tab
  useEffect(() => {
    if (validateBrandPermission(permissionCodes.eventView, permissionCodes, superUser, producerPermissions, producer.id, brandId)) {
      if (!eventsTabEnabled) setEventsTabEnabled(true)
    }
  }, [superUser, producerPermissions, producer.id, brandId, eventsTabEnabled, permissionCodes])

  const sortDateList = (a: themis_common.EventDate.AsObject, b: themis_common.EventDate.AsObject) => {
    if (!a || !a.startClockTime) return -1
    if (!b || !b.startClockTime) return 1

    if (a.startClockTime.unixTime > b.startClockTime.unixTime) return -1
    else return 1
  }

  const eventList: ReactNode[] = useMemo(() => {
    const outEvent: ReactNode[] = []
    events.sort((a, b) => {
      if (!a || !a.eventDatesList?.length) return -1
      if (!b || !b.eventDatesList?.length) return 1
      a.eventDatesList.sort(sortDateList)
      b.eventDatesList.sort(sortDateList)

      if (!a.eventDatesList || !a.eventDatesList?.[0].startClockTime) return -1
      if (!b.eventDatesList || !b.eventDatesList?.[0].startClockTime) return 1

      if (a.eventDatesList[0].startClockTime.unixTime < b.eventDatesList[0].startClockTime.unixTime) return -1
      return 1
    })
    events.forEach((event: themis_common.Event.AsObject) => {
      if (validateEventPermission(permissionCodes.eventView, permissionCodes, superUser, producerPermissions, producer.id, brandId, event.id)) {
        if (!eventsTabEnabled) setEventsTabEnabled(true)

        let dates = "no dates"
        let lastDate = ""
        let lastPrint = ""
        const today = format(new Date(), 'yyyy-MM-dd')
        const datesList = formatEventDates(event.eventDatesList)
        if (datesList && datesList.length) {
          const firstDay = datesList[0].start
          const firstPrint = format(parse(firstDay, "yyyy-MM-dd|HH:mm", new Date()), "iii MMM d, yyyy")

          dates = firstPrint

          lastDate = firstDay.substr(0, 10)
          lastPrint = firstPrint

          if (datesList.length > 1) {
            lastDate = datesList[datesList.length - 1].start.substr(0, 10)
            lastPrint = format(parse(datesList[datesList.length - 1].end, "yyyy-MM-dd|HH:mm", new Date()), "iii MMM d")
            dates = `${firstPrint} to ${lastPrint}`
          }
        }

        if ((showAllEvents || lastDate === "" || today <= lastDate) && validateEventPermission(permissionCodes.eventView, permissionCodes, superUser, producerPermissions, producer.id, brandId, event.id)) {
          outEvent.push(<div key={event.id}>
            <Link className={classes.listItem}
              to={`/Brand/${brandId}/Event/${event.id}`}>{event.name} {dates} in {event.city}, {event.state}</Link>
            { validateEventPermission(permissionCodes.eventEdit, permissionCodes, superUser, producerPermissions, producer.id, brandId, event.id) ?
              (<IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={`/Brand/${brandId}/EditEvent/${event.id}`}>
                <EditIcon fontSize="small"/>
              </IconButton>)
              :
              (<IconButton style={{ visibility: "hidden" }}>
                <EditIcon fontSize="small" />
              </IconButton>)
            }
            { validateEventPermission(permissionCodes.eventDelete, permissionCodes, superUser, producerPermissions, producer.id, brandId, event.id) ?
              (<IconButton className={classes.clickable} aria-label="delete" onClick={_e => { if (setDeleteEvent) setDeleteEvent(event) }}>
                <DeleteIcon fontSize="small"/>
              </IconButton>)
              :
              (<IconButton style={{ visibility: "hidden" }}>
                <DeleteIcon fontSize="small" />
              </IconButton>)
            }
          </div>)
        }
      }
    })
    return outEvent

  }, [events, superUser, producerPermissions, producer.id, brandId, eventsTabEnabled, showAllEvents, classes.listItem, classes.clickable, setDeleteEvent, permissionCodes])

  const headerContents = () => {
    if (brand && brand.id && brand.name) {
      return `Manage Brand - ${brand.name}`
    } else {
      return "Select Brand to Continue"
    }
  }

  const editBrandIcon = useMemo(() => {
    if (validateBrandPermission(permissionCodes.brandEdit, permissionCodes, superUser, producerPermissions, producer.id, brandId)) {
      return (
        <IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={`/EditBrand/${brand?.id}`}>
          <EditIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes.clickable, brand?.id, brandId, producerPermissions, producer, superUser, permissionCodes])

  const deleteBrandIcon = useMemo(() => {
    if (validateBrandPermission(permissionCodes.brandDelete, permissionCodes, superUser, producerPermissions, producer.id, brandId)) {
      return (
        <IconButton className={classes.clickable} aria-label="delete" onClick={() => { if (setIsBrandOpen) setIsBrandOpen(true) }}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes.clickable, brandId, producerPermissions, producer, setIsBrandOpen, superUser, permissionCodes])

  const addEventIcon = useMemo(() => {
    if (validateBrandPermission(permissionCodes.eventAdd, permissionCodes, superUser, producerPermissions, producer.id, brandId)) {
      return (
        <IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={`/brand/${brandId}/AddEvent`}>
          <AddIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [classes.clickable, brandId, producerPermissions, producer, superUser, permissionCodes])

  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const userCanViewBrand = useMemo((): boolean => {
    return validateBrandPermission(permissionCodes.brandView, permissionCodes, superUser, producerPermissions, producer.id, brandId)
  }, [superUser, producerPermissions, brandId, producer.id, permissionCodes])

  return userCanViewBrand ? (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid container item xs={12} alignItems= "flex-start">
          <Grid container item xs={12} alignItems="flex-start">
            <Typography variant="h1" className={classes.headerTypo}>{headerContents()}</Typography>
            {editBrandIcon}
            {deleteBrandIcon}
            <AppBar position="static" style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }}>
              <Tabs value={currentTab} onChange={handleChangeTab} variant="scrollable">
                <Tab label="Events" id="tab-0" disabled={!eventsTabEnabled} />
                <Tab label="Judges" id="tab-1" disabled={!validateBrandPermission(permissionCodes.judgeView, permissionCodes, superUser, producerPermissions, producer.id, brandId)} />
                <Tab label="Users" id="tab-2" disabled={!validateBrandPermission(permissionCodes.brandUserView, permissionCodes, superUser, producerPermissions, producer.id, brandId)} />
              </Tabs>
            </AppBar>
            <TabPanel value={currentTab} index={0}>
              <Typography variant="h2">
                Events
                {addEventIcon}
              </Typography>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={showAllEvents} onChange={e => {setShowAllEvents(e.target.checked)}} name="ShowAllEvents" />}
                  label="Show All Events"
                />
              </FormGroup>
              {eventList}
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              {brand.judgesList !== undefined &&
                <JudgeGrid
                  judges={brand.judgesList}
                  producerId={producer.id}
                  brandId={brand.id}
                  superUser={superUser}
                  producerPermissions={producerPermissions}
                  currentBrand={brand}
                  handleDeleteJudge={handleDeleteJudgeFromBrand}
                  handleAddJudgeDropdown={handleAddJudgeToBrand}
                  producerJudges={producerJudges}
                  permissionCodes={permissionCodes}
                />
              }
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              {!permissionLists ? [] :
                <UserGrid type="brand" superObjectId={producer?.id} objectId={brandId} permissionLists={permissionLists}/>
              }
            </TabPanel>
          </Grid>
        </Grid>
        <YesNoDialog
          title={`Delete ${brand?.name}?`}
          question={`Deleting a brand will delete all events as well and this can not be undone. Are you sure you want to delete ${brand?.name}?`}
          isOpen={isBrandOpen}
          onClose={() => {if (setIsBrandOpen) setIsBrandOpen(false)}}
          buttonActions={[
            { name: "No", color: "primary", callback: () => {if (setIsBrandOpen) setIsBrandOpen(false)} },
            { name: "Yes", color: "primary", callback: handleDeleteBrand },
          ]}
        />
        <YesNoDialog
          title={`Delete Event ${eventToDelete?.name}?`}
          question={`Deleting an event can not be undone. Are you sure you want to delete ${eventToDelete?.name}?`}
          isOpen={isEventOpen}
          onClose={() => {if (setIsEventOpen) setIsEventOpen(false)}}
          buttonActions={[
            { name: "No", color: "primary", callback: () => {if (setIsEventOpen) setIsEventOpen(false)} },
            { name: "Yes", color: "primary", callback: handleDeleteEvent },
          ]}
        />
        <YesNoDialog
          title={"Error"}
          question={"There was an error, please contact us if this continues to be a problem."}
          isOpen={isErrorOpen}
          onClose={() => setIsErrorOpen(false)}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
          ]}
        />
      </Grid>
    </Container>
  ) : <></>
}

export default Brand
