import { createAction } from "redux-actions"
import { AnyDispatchType, SET_BRAND, SET_BRANDS } from "lib/constants"
import { client } from "store/themisClient"
import { refreshUser } from "store/user/userActions"
import { doSetEvent, getEvents } from "./eventActions"
import themis_api from "store/themis_api_pb"
import themis_common from "store/themis_common_pb"
import { buildBrandRequest } from "./producerBuilders"
import { ObjectUserPermissions } from "store/program/programReducer"
import { emptyBrand } from "./producerReducer"


const setBrands = createAction<themis_common.Brand.AsObject[]>(SET_BRANDS)
const setBrand = createAction<themis_common.Brand.AsObject>(SET_BRAND)

export const deleteBrand = async (dispatch: AnyDispatchType, brandId: number, producerId: number): Promise<void> => {
  const deleteBrandRequest = new themis_common.Brand()
  deleteBrandRequest.setId(brandId)
  deleteBrandRequest.setProducerId(producerId)
  const response: themis_api.BrandResponse.AsObject = (await client.deleteBrand(deleteBrandRequest, {})).toObject()
  dispatch(setBrands(response['brandsList']))
  await refreshUser(dispatch)
}

export const getBrands = async (dispatch: AnyDispatchType, producerId: number): Promise<void> => {
  if (!producerId) return
  const response = await getProducerBrands(producerId)
  dispatch(setBrands(response['brandsList']))
}

export const getProducerBrands = async (producerId: number): Promise<themis_api.BrandResponse.AsObject> => {
  if (!producerId) return { brandsList: [] }
  const brandRequest = new themis_common.Producer()
  brandRequest.setId(producerId)
  return (await client.getBrands(brandRequest, {})).toObject()
}

export const addBrand = async (dispatch: AnyDispatchType, brandObject: themis_common.Brand.AsObject): Promise<number> => {
  const brandRequest = buildBrandRequest(brandObject)
  const response: themis_api.BrandResponse.AsObject = (await client.addBrand(brandRequest, {})).toObject()
  if (response.brand && response.brand.id > 0) {
    dispatch(setBrands(response['brandsList']))
    await refreshUser(dispatch)
    return response.brand.id
  }
  throw new Error("Error adding brand")
}

export const addBrandLogo = async(dispatch: AnyDispatchType, brandId: number, producerId: number, image: string, fileName: string): Promise<themis_api.BrandResponse.AsObject> => {
  const addLogoRequest = new themis_common.AddLogoRequest()
  addLogoRequest.setId(brandId)
  addLogoRequest.setImage(image)
  addLogoRequest.setFileName(fileName)
  const response: themis_api.BrandResponse.AsObject = (await client.addBrandLogo(addLogoRequest, {})).toObject()
  await doSetBrand(dispatch, brandId, producerId)
  return response
}

export const updateBrand = async (dispatch: AnyDispatchType, brandObject: themis_common.Brand.AsObject): Promise<void> => {
  const brandRequest = buildBrandRequest(brandObject)
  const response: themis_api.BrandResponse.AsObject = (await client.updateBrand(brandRequest, {})).toObject()
  dispatch(setBrands(response['brandsList']))
  await refreshUser(dispatch)
}

export const doSetBrand = async (dispatch: AnyDispatchType, brandId: number, producerId: number): Promise<void> => {
  let brandResponse: themis_common.Brand.AsObject
  if (brandId > 0) {
    const brand = new themis_common.Brand()
    brand.setId(brandId)
    brand.setProducerId(producerId)
    const res = await client.getBrand(brand, {})
    brandResponse = res.toObject().brand
  } else {
    brandResponse = emptyBrand
  }
  dispatch(setBrand(brandResponse))
  await getEvents(dispatch, brandId, producerId)
}

export const doClearBrand = async (dispatch: AnyDispatchType): Promise<void> => {
  dispatch(setBrand(emptyBrand))
  doSetEvent(dispatch, -1)
}

export async function getBrandUsers(brandId: number, producerId: number): Promise<ObjectUserPermissions[]> {
  const brand = new themis_common.Brand()
  brand.setId(brandId)
  brand.setProducerId(producerId)
  const response = (await client.getBrandUsers(brand, {})).toObject()
  return response["usersPermissionsList"]
}

export async function addJudgeToBrand(dispatch: AnyDispatchType, producerId: number, brandId: number, judgeId: number): Promise<void> {
  const brand = new themis_common.Brand()
  brand.setId(brandId)
  brand.setProducerId(producerId)

  const producer = new themis_common.Producer()
  producer.setId(producerId)
  brand.setProducer(producer)

  const judge = new themis_common.Judge()
  judge.setId(judgeId)

  const request = new themis_api.JudgeBrandRequest()
  request.setBrand(brand)
  request.setJudge(judge)

  const res = await client.addJudgeToBrand(request, {})
  const brandResponse = res.toObject()
  dispatch(setBrand(brandResponse.brand))
}

export async function removeJudgeFromBrand(dispatch: AnyDispatchType, producerId: number, brandId: number, judgeId: number): Promise<void> {
  const brand = new themis_common.Brand()
  brand.setId(brandId)

  const producer = new themis_common.Producer()
  producer.setId(producerId)
  brand.setProducer(producer)

  const judge = new themis_common.Judge()
  judge.setId(judgeId)

  const request = new themis_api.JudgeBrandRequest()
  request.setBrand(brand)
  request.setJudge(judge)
  const res = await client.removeJudgeFromBrand(request, {})
  const brandResponse = res.toObject()
  dispatch(setBrand(brandResponse.brand))
}
