import React, { useEffect, useState, useMemo, ReactElement, FormEvent } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { countries, usStates } from "lib/constants"
import { useStyles } from "lib/theme"
import { buildErrors, fieldsOkay, validURL } from "lib/validators"
import { addProducer, updateProducer, addProducerLogo } from "store/producer/producerActions"
import { useHistory, useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentProducer } from "store/producer/producerSelectors"
import { emptyProducer } from "store/producer/producerReducer"
import themis_common from "store/themis_common_pb"
import { selectSuperUser } from "store/user/userSelectors"
import { refreshUser, getUserGeolocation } from 'store/user/userActions'
import { logoUploadDefault } from "components/util/LogoUploadContext"
import AddEditProducer from "./AddEditProducer"
import { locationApiCall } from 'lib/functions'
import { useCallback } from 'react'
const AddEditProducerContainer: React.FC = (): ReactElement => {


    const producer = useSelector((state: IAppState) => selectCurrentProducer(state))
    const isSuperUser: boolean = useSelector((state: IAppState) => selectSuperUser(state))
    const { producerId: inputProducerId } = useParams<{ producerId?: string | undefined }>()
    const producerId = Number(inputProducerId)
    const [logoState, setLogoState] = useState<typeof logoUploadDefault>({ ...logoUploadDefault, id: producerId })
    const [updatedProducer, setUpdatedProducer] = useState<themis_common.Producer.AsObject>(emptyProducer)
    const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})
    const [checkProducer, setCheckProducer] = useState(false)
    const [isErrorOpen, setIsErrorOpen] = useState(false)
    const [locationResponse, setLocationResponse] = useState<any>()
    const [userGeoLocation, setUserGeoLocation] = useState<GeolocationPosition | undefined>()
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const formFields = useMemo(() => {
        return {
            name: { label: "Event Producer Company Name", required: true, phone: false, email: false },
            city: { label: "City", required: true, phone: false, email: false },
            state: { label: "State or Province", required: true, phone: false, email: false },
            country: { label: "Country", required: true, phone: false, email: false },
            address: { label: "Address", required: true, phone: false, email: false },
            address2: { label: "Address 2", required: false, phone: false, email: false },
            postalCode: { label: "Postal Code", required: true, phone: false, email: false },
            logo: { label: "Logo", required: false, phone: false, email: false },
            website: { label: "Website URL", required: false, phone: false, email: false },
            phone: { label: "Contact Phone", required: true, phone: true, email: false },
            email: { label: "Email for Producer Account", required: true, phone: false, email: true }
        }
    }, [])

    const handleGeoLocation = useCallback((position: GeolocationPosition) => {
        const callApi = async () => {
            setUserGeoLocation(position)
            const location = await locationApiCall(position.coords.longitude, position.coords.latitude)
            setLocationResponse(location)
        }
        callApi()
    }, [setUserGeoLocation])
    // Handle Geo Location, calls https://www.bigdatacloud.com/geocoding-apis/free-reverse-geocode-to-city-api when the user has geo location enabled.
    useEffect(() => {
        if (navigator.geolocation) getUserGeolocation(handleGeoLocation)
    }, [navigator.geolocation, handleGeoLocation])


    const defaultCountry = useMemo(() => {
        if (!updatedProducer?.country) return countries[0]
        return countries.find((country) => country.code === updatedProducer.country) || countries[0]
    }, [updatedProducer?.country])

    const defaultState = useMemo(() => usStates.find((state) => state.abbreviation === updatedProducer?.state) || usStates[0], [updatedProducer?.state])



    useEffect(() => {
        if (producerId && producer?.id) {
            // Editing producer
            setUpdatedProducer(producer)
        } else {
            // Adding producer
            setUpdatedProducer({ ...emptyProducer, country: "US", state: "AL" })
        }
    }, [producerId, producer])



    // Display logo
    useEffect(() => {
        if (updatedProducer.logo > '' && validURL(updatedProducer.logo) && updatedProducer.logo.slice(-5).indexOf(".") >= 0) {
            setLogoState(currentLogoState => { return { ...currentLogoState, oldLogo: updatedProducer.logo } })
        }
    }, [updatedProducer.logo, classes.logoCheck])

    // Check fields when user tries to submit
    useEffect(() => {
        if (checkProducer) {
            setErrorText(buildErrors(updatedProducer, formFields, setUpdatedProducer))
            setCheckProducer(false)
        }
    }, [checkProducer, updatedProducer, formFields])

    const formReady: boolean = useMemo(() => fieldsOkay(updatedProducer, formFields), [updatedProducer, formFields])
    const submitForm: (event: FormEvent) => Promise<void> = async (event) => {
        setCheckProducer(true)
        if (!producerId && !isSuperUser) { // Non super users may not create event producers
            history.push("/")
        }
        event.preventDefault()
        if (formReady) {
            if (producerId) {
                // Editing Producer
                try {
                    await updateProducer(dispatch, {
                        ...updatedProducer,
                        id: producerId,
                        accountEmail: ""
                    })
                    if (logoState.fileSelected) {
                        await addProducerLogo(dispatch, logoState.id, logoState.base64File, logoState.fileName)
                        setLogoState(logoUploadDefault)
                    }
                    await refreshUser(dispatch)
                    history.replace(`/Producer/${producerId}`)
                } catch (e) {
                    setIsErrorOpen(true)
                    console.error(e)
                }
            } else {
                // Creating Producer
                try {
                    const producerId = await addProducer(dispatch, {
                        ...updatedProducer,
                        id: 0,
                        accountEmail: updatedProducer.email
                    })
                    if (logoState.fileSelected) {
                        await addProducerLogo(dispatch, producerId, logoState.base64File, logoState.fileName)
                        setLogoState(logoUploadDefault)
                    }
                    await refreshUser(dispatch)
                    history.replace(`/`)
                } catch (e) {
                    setIsErrorOpen(true)
                    console.error(e)
                }
            }
        }
    }


    return (
        <AddEditProducer
            producer={producer}
            isSuperUser={isSuperUser}
            producerId={producerId}
            logoState={logoState}
            setLogoState={setLogoState}
            updatedProducer={updatedProducer}
            setUpdatedProducer={setUpdatedProducer}
            errorText={errorText}
            setErrorText={setErrorText}
            checkProducer={checkProducer}
            setCheckProducer={setCheckProducer}
            isErrorOpen={isErrorOpen}
            setIsErrorOpen={setIsErrorOpen}
            defaultCountry={defaultCountry}
            defaultState={defaultState}
            locationResponse={locationResponse}
            formFields={formFields}
            submitForm={submitForm}
        />
    )
}

export default AddEditProducerContainer