import React, { ChangeEvent, ReactElement, ReactNode, useState, useMemo, useEffect } from 'react'
import { countries } from "lib/constants"
import { useStyles } from "lib/theme"

import { Link, Link as RouterLink } from "react-router-dom"
import { formatRelative, parseISO } from "date-fns"
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useTheme
} from '@material-ui/core'
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import { PermissionLists } from "store/user/userActions"
import { ProgramPermissions } from 'store/user/userReducer'
import { TabPanel } from "lib/pieces"
import themis_common from "store/themis_common_pb"

import YesNoDialog from 'components/util/YesNoModal'
import UserGrid from "components/user/user_grid/UserGrid"
import { validateProgramPermission, validateLocationPermission } from 'lib/permissions'
import BalanceContainer from "./balance/BalanceContainer"
import ViewProducersButton from 'components/util/ViewProducersButton'
import EventGridContainer from './event_grid/EventGridContainer'

const countryItems: JSX.Element[] = []
for (const country of countries) {
  countryItems.push(<MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>)
}

interface ProgramProps {
  superUser: boolean
  permissionCodes: themis_common.PermissionCodes.AsObject
  programPermissions: ProgramPermissions
  permissionLists: PermissionLists
  programId: number
  program: themis_common.Program.AsObject
  locations: themis_common.Location.AsObject[]
  currentTab: number
  handleChangeTab: (event: ChangeEvent<{}>, value: number)=>void
  handleDeleteProgram: Function
  handleDeleteLocation: Function
  isLocationOpen: boolean
  isProgramOpen: boolean
  isErrorOpen: boolean
  setIsLocationOpen?: (value: boolean | ((prevVar: boolean) => boolean)) => void
  setIsProgramOpen?: (value: boolean | ((prevVar: boolean) => boolean)) => void
  setIsErrorOpen?: (value: boolean | ((prevVar: boolean) => boolean)) => void
}

const Program: React.FC<ProgramProps> = ({
  superUser, programPermissions, permissionCodes,
  permissionLists, programId, program, locations,
  currentTab, handleChangeTab, handleDeleteProgram, handleDeleteLocation,
  isLocationOpen, isProgramOpen, isErrorOpen, setIsLocationOpen, setIsProgramOpen, setIsErrorOpen,
}): ReactElement => {
  const classes = useStyles()
  const theme = useTheme()

  const [locationToDelete, setLocationToDelete] = useState<themis_common.Location.AsObject | null>(null)
  const [locationTabEnabled, setLocationTabEnabled] = useState(false)

  // If program has "LV", enable locations tab
  useEffect(() => {
    if (validateProgramPermission(permissionCodes.locationView, permissionCodes, superUser, programPermissions, programId)) {
      if (!locationTabEnabled) setLocationTabEnabled(true)
    }
  }, [superUser, locationTabEnabled, programId, programPermissions, permissionCodes])

  const setDeleteLocation = (location: themis_common.Location.AsObject) => {
    setLocationToDelete(location)
    if (setIsLocationOpen) setIsLocationOpen(true)
  }
  const locationList: ReactNode[] = []
  locations.forEach((location: themis_common.Location.AsObject) => {
    if (validateLocationPermission(permissionCodes.locationView, permissionCodes, superUser, programPermissions, programId, location.id)) {
      if (!locationTabEnabled) setLocationTabEnabled(true)
      const scrapeDate: string = location && location.lastScrape && location.lastScrape > '' ? 'Last Scraped: ' + (formatRelative(parseISO(location.lastScrape), new Date())) : ''
      locationList.push(<Grid container key={location.id}>
        <Grid item xs={8}>
          <Link className={classes.listItem100}
            to={`/Location/${location.id}`}>{location.name} - {location.city}, {location.state}</Link>
          <Typography className={classes.smallFont}>{scrapeDate}</Typography>
        </Grid>
        <Grid item xs={4}>
          { validateLocationPermission(permissionCodes.locationEdit, permissionCodes, superUser, programPermissions, programId, location.id) ?
            (<IconButton className={classes.clickable} aria-label="edit" component={RouterLink}
              to={`/EditLocation/${location.id}`}>
              <EditIcon fontSize="small"/>
            </IconButton>)
            :
            (<IconButton style={{ visibility: "hidden" }}>
              <EditIcon fontSize="small" />
            </IconButton>)
          }
          { validateLocationPermission(permissionCodes.locationDelete, permissionCodes, superUser, programPermissions, programId, location.id) ?
            (<IconButton className={classes.clickable} aria-label="delete" onClick={_ => {
              if (setIsLocationOpen) setIsLocationOpen(true)
              setDeleteLocation(location)
            }}>
              <DeleteIcon fontSize="small"/>
            </IconButton>)
            :
            (<IconButton style={{ visibility: "hidden" }}>
              <DeleteIcon fontSize="small" />
            </IconButton>)
          }
        </Grid>
      </Grid>)
    }
  })

  const editProgramIcon = useMemo(() => {
    if (validateProgramPermission(permissionCodes.programEdit, permissionCodes, superUser, programPermissions, programId)) {
      return (
        <IconButton className={classes.clickable} aria-label="edit" component={RouterLink}
          to={`/EditProgram/${programId}`}>
          <EditIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [classes, programId, superUser, programPermissions, permissionCodes])

  const deleteProgramIcon = useMemo(() => {
    if (validateProgramPermission(permissionCodes.programDelete, permissionCodes, superUser, programPermissions, programId)) {
      return (
        <IconButton className={classes.clickable} aria-label="delete" onClick={_ => {
          if (setIsProgramOpen) setIsProgramOpen(true)
        }}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes, superUser, setIsProgramOpen, programPermissions, programId, permissionCodes])

  const addLocationIcon = useMemo(() => {
    if (validateProgramPermission(permissionCodes.locationAdd, permissionCodes, superUser, programPermissions, programId)) {
      return (
        <IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={`/Program/${programId}/AddLocation`}>
          <AddIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes, programId, superUser, programPermissions, permissionCodes])

  const headerContents = () => {
    if (program && program.id && program.name) {
      return `Manage Program - ${program.name}`
    } else {
      return "Select Program to Continue"
    }
  }

  const userCanViewProgram = useMemo((): boolean => {
    return validateProgramPermission(permissionCodes.programView, permissionCodes, superUser, programPermissions, programId)
  }, [superUser, programPermissions, programId, permissionCodes])

  return userCanViewProgram ? (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid container item xs={12} alignItems="flex-start">
          <Typography variant="h1" className={classes.headerTypo}>{headerContents()}</Typography>
          <Grid item xs={2}>
            {editProgramIcon}{deleteProgramIcon}
          </Grid>
          <Grid item xs={3}>
            {currentTab === 0 ? <ViewProducersButton></ViewProducersButton> : <></>}
          </Grid>
          <AppBar position="static" style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }}>
            <Tabs value={currentTab} onChange={handleChangeTab} variant="scrollable">
              <Tab label="Events" id="tab-0" disabled={!locationTabEnabled} />
              <Tab label="Locations" id="tab-1" disabled={!locationTabEnabled} />
              <Tab label="Users" id="tab-2" disabled={!validateProgramPermission(permissionCodes.programUserView, permissionCodes, superUser, programPermissions, programId)}/>
              <Tab label="Event Balance" id="tab-3" />
              <Tab label="Actions" id="tab-4" disabled={!validateProgramPermission(permissionCodes.programSuper, permissionCodes, superUser, programPermissions, programId)}/>
            </Tabs>
          </AppBar>
          <TabPanel value={currentTab} index={0}>
            {!program ? [] :
              <EventGridContainer
                eventGridType={"program"}
                programPermissions={programPermissions}
                programId={programId}
                program={program}
                superUser={superUser}
                permissionCodes={permissionCodes} />
            }
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <Typography variant="h2">
              Locations
              {addLocationIcon}
            </Typography>
            {locationTabEnabled ? locationList : []}
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            {!permissionLists || !validateProgramPermission(permissionCodes.programUserView, permissionCodes, superUser, programPermissions, programId) ? [] :
              <UserGrid permissionLists={permissionLists} objectId={programId} type="program"/>
            }
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <BalanceContainer />
          </TabPanel>
          {/* TODO: sub this out as ActionGrid and Action components */}
          <TabPanel value={currentTab} index={4}>
            {!validateProgramPermission(permissionCodes.programSuper, permissionCodes, superUser, programPermissions, programId) ? [] : (
              <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={12} md={6} >
                  <Card className={classes.divider}>
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        Update Program
                      </Typography>
                      <Typography variant="body2" component="p">
                        Import data from your USASF account into this program
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button color="primary" variant="outlined" component={RouterLink} to={`/usasfUpdate/${programId}`}>Update Data</Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} >
                  <Card className={classes.divider}>
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        Import New Program
                      </Typography>
                      <Typography variant="body2" component="p">
                        Import data from your USASF account and create a new program
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button color="primary" variant="outlined" component={RouterLink} to={`/usasfUpdate`}>Import Data</Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            )
            }
          </TabPanel>
        </Grid>
        <YesNoDialog
          title={`Delete ${program?.name}?`}
          question={`Deleting a program will delete all locations as well and this can not be undone. Are you sure you want to delete ${program?.name}?`}
          isOpen={isProgramOpen}
          onClose={() => {if (setIsProgramOpen) setIsProgramOpen(false)}}
          buttonActions={[
            { name: "No", color: "primary", callback: () => {if (setIsProgramOpen) setIsProgramOpen(false)} },
            { name: "Yes", color: "primary", callback: handleDeleteProgram },
          ]}
        />
        <YesNoDialog
          title={`Delete ${locationToDelete?.name}?`}
          question={`Deleting a location can not be undone. Are you sure you want to delete ${locationToDelete?.name}?`}
          isOpen={isLocationOpen}
          onClose={() => {if (setIsLocationOpen) setIsLocationOpen(false)}}
          buttonActions={[
            { name: "No", color: "primary", callback: () => {if (setIsLocationOpen) setIsLocationOpen(false)} },
            { name: "Yes", color: "primary", callback: handleDeleteLocation },
          ]}
        />
        <YesNoDialog
          title={"Error"}
          question={"There was an error, please contact us if this continues to be a problem."}
          isOpen={isErrorOpen}
          onClose={() => {if (setIsErrorOpen) setIsErrorOpen(false)}}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => {if (setIsErrorOpen) setIsErrorOpen(false)} },
          ]}
        />
      </Grid>
    </Container>
  ) : <></>
}

export default Program
