import React, { ReactElement, useEffect, useState } from 'react'
import { Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core"
import { useSelector } from "react-redux"
import { IAppState } from "../../store/store"
import { selectSuperUser } from "../../store/user/userSelectors"
import { RadioButtonUnchecked as UncheckedIcon, CheckCircleOutline as CheckedIcon } from '@material-ui/icons'
import { autoCreateGuardiansforOver18, findAthletesWithoutGuardians } from "../../store/program/athleteActions"
import themis_common from "store/themis_common_pb"

const Fix18: React.FC = (): ReactElement => {

  const [createSelfGuard, setCreateSelfGuard] = useState(false)
  const [getNoGuardList, setGetNoGuardList] = useState(false)
  const [athleteList, setAthleteList] = useState<themis_common.Athlete.AsObject[]>([])

  const superUser: boolean = useSelector((state: IAppState) => selectSuperUser(state))

  useEffect(() => {
    // Fire this when the page loads
    // Create the Self Guardians
    const doCreateGuardians = async () => {
      if (await autoCreateGuardiansforOver18()) {
        setCreateSelfGuard(true)
      }
    }
    doCreateGuardians()
  }, [])

  useEffect(() => {
    // Fire this when createSelfGuard is done
    if (createSelfGuard) {
      // Get the athletes that are missing guardians.
      const doGetList = async () => {
        const athleteList = await findAthletesWithoutGuardians()
        setAthleteList(athleteList)
        setGetNoGuardList(true)
      }
      doGetList()

    }
  }, [createSelfGuard])



  useEffect(() => {
    // Fire this when the page loads
    // Create the Self Guardians
  }, [])

  return !superUser ? <></> :
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <List>
            <ListItem>
              <ListItemIcon>
                { createSelfGuard ? <CheckedIcon /> : <UncheckedIcon /> }
              </ListItemIcon>
              <ListItemText primary="Create Over 18 Self Guardian and send out email for each event signing set" />
            </ListItem>
            <ListItem disabled={!createSelfGuard}>
              <ListItemIcon>
                { getNoGuardList ? <CheckedIcon /> : <UncheckedIcon /> }
              </ListItemIcon>
              <ListItemText primary="Get List of Missing Guardians" />
            </ListItem>
          </List>
        </Grid>
        <Grid item container xs={12} hidden={!getNoGuardList}>
          <Grid item xs={12}><Typography variant="h5">Athletes Without Guardians</Typography></Grid>
          { athleteList.map((athlete) => <Grid item xs={12}>id #{athlete.id} - {athlete.name} at {athlete.athleteLocationsList[0]?.name}</Grid>)}
        </Grid>
      </Grid>
    </Container>
}

export default Fix18
