import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { skillTypes } from "lib/constants";

interface RadioProps {
    element: any;
    index: number;
    type: string;
    handleRadioChange: Function;
    selectedValue: any;
}

const RadioComponent: React.FC<RadioProps> = ({
    element,
    index,
    type,
    handleRadioChange,
    selectedValue
}) => {
    return (
        <FormControl>
            <RadioGroup row aria-label={element.key}
                name={element.key}
                value={selectedValue || ''}
                onChange={(event) => { handleRadioChange(index, type, event) }}
            >
                {element.enums && element.enums.map((option: any, idx: any) => {
                    if (option.visible) {
                        return (
                            <FormControlLabel
                                key={`${index}`}
                                value={type.toLowerCase() === skillTypes.skill ? option.title : `Coed ${option.title}`}
                                control={<Radio />}
                                label={type.toLowerCase() === skillTypes.skill ? option.title : `Coed ${option.title}`}
                            />
                        );
                    }
                    return null;
                })}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioComponent;
