import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { Grid, TextField, Typography } from '@material-ui/core'
import { Remove as Spacer } from '@material-ui/icons'
import { Autocomplete } from "@material-ui/lab"
import { autoCompleteStyles, useStyles } from "lib/theme"
import { JudgeEventPermissions, ProducerPermissions, ProgramPermissions } from "store/user/userReducer"
import { BothSides } from "store/user/userActions"
import { emptyRoute } from "lib/constants"

interface menuItem { label: string; value: string; route: string; isLogo: boolean; key: string; isSub?: boolean; isDivider?: boolean }
interface headerAutoCompleteProps { programs: ProgramPermissions, producers: ProducerPermissions, judgeEvents: JudgeEventPermissions, userState: string, isSuperUser: boolean, bothSides: BothSides }

const HeaderAutoComplete: React.FC<headerAutoCompleteProps> = ({ programs, producers, judgeEvents, userState, isSuperUser, bothSides }): ReactElement => {
  const classes = useStyles()
  const autoCompleteClasses = autoCompleteStyles()
  const history = useHistory()
  const location = useLocation()

  const [currentRoute, setCurrentRoute] = useState<menuItem>(emptyRoute)

  const programProducerItems = useMemo<menuItem[]>(() => {
    // This generates the list of items that appear in the autocomplete in the header
    const items: menuItem[] = []
    if (userState === 'program') for (const i in programs) {
      const program = programs[i]
      const label = program.logo ? program.logo : program.name
      if (program.name.trim() > '') {
        items.push({
          label: label,
          value: program.name,
          route: `/Program/${program.id}`,
          key: i,
          isLogo: !!program.logo
        })
      } else {
        items.push({
          label: label,
          value: program.name,
          route: '',
          key: i,
          isLogo: false
        })

      }
      if (program.locations) for (const j in program.locations) {
        const location = program.locations[j]
        const subLabel = location.name
        items.push({
          label: subLabel,
          value: location.name,
          route: `/Location/${location.id}`,
          key: `${i}-${j}`,
          isLogo: false,
          isSub: true
        })
      }
      items.push({ label: "divider", value: "divider", route: "", key: `${i}-div`, isLogo: false, isDivider: true })
    }
    else if (userState === 'producer') for (const i in producers) {
      const producer = producers[i]
      const label = producer.logo ? producer.logo : producer.name
      items.push({
        label: label,
        value: producer.name,
        route: `/Producer/${producer.id}`,
        key: i,
        isLogo: !!producer.logo
      })//<MenuItem key={`prog|${producer.id}`} component={RouterLink} to={`/Producer/${producer.id}`}>{display}</MenuItem>)
      if (producer.brands) for (const j in producer.brands) {
        const brand = producer.brands[j]
        const subLabel = brand.logo ? brand.logo : brand.name
        items.push({
          label: subLabel,
          value: brand.name,
          route: `/Brand/${brand.id}`,
          key: `${i}-${j}`,
          isLogo: !!brand.logo,
          isSub: true
        })
      }
      items.push({ label: "divider", value: "divider", route: "", key: `${i}-div`, isLogo: false, isDivider: true })
    }
    else if (userState === 'judge') for (const i in judgeEvents) {
      const event = judgeEvents[i]
      const label = event.logo ? event.logo : event.name
      items.push({
        label: label,
        value: event.name + ' - ' + event.eventStartDate,
        route: `/JudgeEvent/${event.id}`,
        key: i,
        isLogo: !!event.logo
      })
    }
    if (isSuperUser) items.push({
      label: "Add Event Producer",
      value: "Add Event Producer",
      route: "/AddProducer",
      key: "AddProducer",
      isLogo: false,
      isDivider: false
    })
    const programLabel = bothSides.program ? "Add Another Program" : "Add My Program"
    items.push({
      label: programLabel,
      value: programLabel,
      route: "/AddProgram",
      key: "AddProgram",
      isLogo: false,
      isDivider: false
    })
    return items

  }, [programs, producers, judgeEvents, isSuperUser, userState, bothSides.program])

  useEffect(() => {
    const toParse = location.pathname.split('/')
    if (toParse[1].substring(0, 4).toLowerCase() === 'edit') toParse[1] = toParse[1].substr(4)
    const route = '/' + toParse.slice(1, 3).join('/')
    const theCurrentRoute = programProducerItems.find((item) => item.route === route)
    if (theCurrentRoute) setCurrentRoute(theCurrentRoute)
    else setCurrentRoute(emptyRoute)
  }, [programProducerItems, location]) //[programProducerItems, emptyRoute, location.pathname])

  return (
    <Grid item container xs={12}>
      <form style={{ float: 'left', overflowY: 'auto' }} autoComplete={"none"}>
        <Autocomplete classes={autoCompleteClasses} options={programProducerItems} disableClearable
          getOptionDisabled={(option) => option.value === "divider" || option.value.trim() === ''}
          getOptionLabel={(option) => option.value}
          getOptionSelected={(option, value) => option.route.toLowerCase().trim() === value.route.toLowerCase().trim()}
          value={currentRoute}
          renderInput={(params) => (
            <TextField {...params} label="Navigate" variant="outlined" className={classes.navigationMenu}
              inputProps={{ ...params.inputProps, autoComplete: 'off' }} // disable autocomplete and autofill
            />
          )}
          onChange={(e, v: menuItem | null) => {
            if (v) history.push(v.route)
          }}
          renderOption={(option, _state) => {
            if (option.isDivider) return <hr key={option.key} style={{ width: '100%' }} />
            const addSpace = option.isSub ? <Spacer fontSize="small" /> : <></>
            if (option.isLogo) {
              return <div key={option.key}><Typography>{addSpace}<img alt={`logo for ${option.value}`}
                src={option.label} className={classes.menuImage} /> {option.value}</Typography></div>
            } else {
              return <div key={option.key}><Typography>{addSpace}{option.label}</Typography></div>
            }
          }}
          id="setProgram"
          className={classes.programSelect}
        />
      </form>
    </Grid>
  )
}

export default HeaderAutoComplete
