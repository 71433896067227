import { createAction } from "redux-actions"
import { client } from "store/themisClient"
import { emptyTeam, ObjectUserPermissions } from "./programReducer"
import themis_api from "store/themis_api_pb"
import themis_common from "store/themis_common_pb"

import { AsFields, SET_TEAM, SET_TEAM_SA, SET_TEAMS, AnyDispatchType } from "lib/constants"
import { doSetLocation } from "./locationActions"

const setTeams =createAction<themis_common.Team.AsObject[]>(SET_TEAMS)
const setTeam = createAction<themis_common.Team.AsObject>(SET_TEAM)
const setTeamScoringAuthorities = createAction<themis_common.TeamScoringAuthority.AsObject>(SET_TEAM_SA)

async function addAthleteToTeam(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, athleteId: number, update = true): Promise<void> {

  const team = new themis_common.Team()
  team.setId(teamId)

  const program = new themis_common.Program()
  program.setId(programId)
  team.setProgram(program)

  const location = new themis_common.Location()
  location.setId(locationId)
  team.setLocation(location)

  const athlete = new themis_common.Athlete()
  athlete.setId(athleteId)

  const request = new themis_api.AthleteTeamRequest()
  request.setTeam(team)
  request.setAthlete(athlete)
  const res = await client.addAthleteToTeam(request, {})
  const teamResponse = res.toObject()
  if (update) dispatch(setTeam(teamResponse.team))
}

async function addAthletesToTeam(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, athleteIds: number[], update = true): Promise<void> {
  const team = new themis_common.Team()
  team.setId(teamId)

  const program = new themis_common.Program()
  program.setId(programId)
  team.setProgram(program)

  const location = new themis_common.Location()
  location.setId(locationId)
  team.setLocation(location)

  const athletes: themis_common.Athlete[] = []
  athleteIds.forEach((athleteId) => {
    const athlete = new themis_common.Athlete()
    athlete.setId(athleteId)
    athletes.push(athlete)
  })

  const request = new themis_api.AthletesTeamRequest()
  request.setTeam(team)
  request.setAthletesList(athletes)

  const res = await client.addAthletesToTeam(request, {})
  const teamResponse = res.toObject()
  if (update) dispatch(setTeam(teamResponse.team))
}

async function removeAthleteFromTeam(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, athleteId: number, update = true): Promise<void> {
  const team = new themis_common.Team()
  team.setId(teamId)

  const program = new themis_common.Program()
  program.setId(programId)
  team.setProgram(program)

  const location = new themis_common.Location()
  location.setId(locationId)
  team.setLocation(location)

  const athlete = new themis_common.Athlete()
  athlete.setId(athleteId)

  const request = new themis_api.AthleteTeamRequest()
  request.setTeam(team)
  request.setAthlete(athlete)
  const res = await client.removeAthleteFromTeam(request, {})
  const teamResponse = res.toObject()
  if (update) dispatch(setTeam(teamResponse.team))
}

async function addCoachToTeam(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, coachId: number): Promise<void> {

  const team = new themis_common.Team()
  team.setId(teamId)

  const program = new themis_common.Program()
  program.setId(programId)
  team.setProgram(program)

  const location = new themis_common.Location()
  location.setId(locationId)
  team.setLocation(location)

  const coach = new themis_common.Coach()
  coach.setId(coachId)

  const request = new themis_api.CoachTeamRequest()
  request.setTeam(team)
  request.setCoach(coach)
  const res = await client.addCoachToTeam(request, {})
  const teamResponse = res.toObject()
  dispatch(setTeam(teamResponse.team))
}

async function removeCoachFromTeam(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, coachId: number): Promise<void> {

  const team = new themis_common.Team()
  team.setId(teamId)

  const program = new themis_common.Program()
  program.setId(programId)
  team.setProgram(program)

  const location = new themis_common.Location()
  location.setId(locationId)
  team.setLocation(location)

  const coach = new themis_common.Coach()
  coach.setId(coachId)

  const request = new themis_api.CoachTeamRequest()
  request.setTeam(team)
  request.setCoach(coach)
  const res = await client.removeCoachFromTeam(request, {})
  const teamResponse = res.toObject()
  dispatch(setTeam(teamResponse.team))
}

async function addPersonnelToTeam(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, personnelId: number): Promise<void> {

  const team = new themis_common.Team()
  team.setId(teamId)

  const program = new themis_common.Program()
  program.setId(programId)
  team.setProgram(program)

  const location = new themis_common.Location()
  location.setId(locationId)
  team.setLocation(location)

  const personnel = new themis_common.Personnel()
  personnel.setId(personnelId)

  const request = new themis_api.PersonnelTeamRequest()
  request.setTeam(team)
  request.setPersonnel(personnel)
  const res = await client.addPersonnelToTeam(request, {})
  const teamResponse = res.toObject()
  dispatch(setTeam(teamResponse.team))
}

async function removePersonnelFromTeam(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, personnelId: number): Promise<void> {

  const team = new themis_common.Team()
  team.setId(teamId)

  const program = new themis_common.Program()
  program.setId(programId)
  team.setProgram(program)

  const location = new themis_common.Location()
  location.setId(locationId)
  team.setLocation(location)

  const personnel = new themis_common.Personnel()
  personnel.setId(personnelId)

  const request = new themis_api.PersonnelTeamRequest()
  request.setTeam(team)
  request.setPersonnel(personnel)
  const res = await client.removePersonnelFromTeam(request, {})
  const teamResponse = res.toObject()
  dispatch(setTeam(teamResponse.team))
}

async function doSetTeam(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number): Promise<void> {
  let team: themis_common.Team.AsObject
  if (teamId > 0) {
    const teamRequest: any = new themis_common.Team()
    teamRequest.setId(teamId)
    teamRequest.setProgramId(programId)
    teamRequest.setLocationId(locationId)
    const res = await client.getTeam(teamRequest, {})
    const teamResponse = res.toObject()
    team = teamResponse.team
  } else {
    team = emptyTeam
  }
  dispatch(setTeam(team))
}

async function deleteTeam(dispatch: AnyDispatchType, teamId: number, locationId: number, programId: number): Promise<void> {
  const deleteTeamRequest: any = new themis_common.Team()
  deleteTeamRequest.setId(teamId)
  deleteTeamRequest.setLocationId(locationId)
  deleteTeamRequest.setProgramId(programId)
  dispatch(setTeam(emptyTeam))
  const response = (await client.deleteTeam(deleteTeamRequest, {})).toObject()
  const teams = response["teamsList"]
  dispatch(setTeams(teams))
}

async function addTeam(dispatch: AnyDispatchType, program: themis_common.Program.AsObject, location: themis_common.Location.AsObject, division: themis_common.Division.AsObject, updatedTeam: AsFields): Promise<number> {
  const teamRequest = new themis_common.Team()
  teamRequest.setProgramId(program.id)
  teamRequest.setLocationId(location.id)
  teamRequest.setName(updatedTeam.name)
  teamRequest.setDescription(updatedTeam.description)
  const allStar = new themis_common.AllStarType()
  allStar.setId(updatedTeam?.allStarType?.id || 0)
  teamRequest.setAllStarType(allStar)
  const tier = new themis_common.Tier()
  tier.setId(updatedTeam?.tier?.id || 0)
  teamRequest.setTier(tier)
  // const rDivision = new themis_common.Division()
  // rDivision.setId(division?.id || 0)
  // teamRequest.setDivision(rDivision) // TODO: Fix this

  const response = (await client.addTeam(teamRequest, {})).toObject()
  dispatch(setTeams(response['teamsList']))
  return response.team.id
}

async function addScoringAuthority(dispatch: AnyDispatchType, teamId: number, program: themis_common.Program.AsObject, location: themis_common.Location.AsObject, division: themis_common.Division.AsObject):Promise<any> {
  if (!division?.season?.id) {
    console.error("No division/season set!")
    return
  }
  // Create a team division/scoring authority
  const rDivision = new themis_common.Division()
  rDivision.setId(division?.id || 0)
  const teamSARequest = new themis_common.TeamScoringAuthority()
  const rTeam = new themis_common.Team()
  const rLocation = new themis_common.Location()
  const rProgram = new themis_common.Program()
  const rSeason = new themis_common.Season()
  rProgram.setId(program.id)
  rLocation.setId(location.id)
  rLocation.setProgram(rProgram)
  rTeam.setId(teamId)
  rTeam.setLocation(rLocation)
  rSeason.setId(division.season.id)
  teamSARequest.setTeam(rTeam)
  teamSARequest.setSeason(rSeason)
  teamSARequest.setDivision(rDivision)
  const result = (await client.addTeamScoringAuthority(teamSARequest, {})).toObject()
  await dispatch(setTeamScoringAuthorities(result.teamScoringAuthoritiesList))
  return result
}

async function setPrimaryScoringAuthority(dispatch: AnyDispatchType, teamId: number, locationId: number, programId: number, teamScoringAuthorityId: number): Promise<void> {
  const rTeamScoringAuthority = new themis_common.TeamScoringAuthority()
  rTeamScoringAuthority.setId(teamScoringAuthorityId)
  const rTeam = new themis_common.Team()
  rTeam.setId(teamId)
  const rLocation = new themis_common.Location()
  rLocation.setId(locationId)
  const rProgram = new themis_common.Program()
  rProgram.setId(programId)
  rLocation.setProgram(rProgram)
  rTeam.setLocation(rLocation)
  rTeamScoringAuthority.setTeam(rTeam)
  const result = (await client.setPrimaryTeamScoringAuthority(rTeamScoringAuthority, {})).toObject()
  await dispatch(setTeamScoringAuthorities(result.teamScoringAuthoritiesList))
  await doSetLocation(dispatch, programId, locationId)
}

async function removeScoringAuthority(dispatch: AnyDispatchType, teamId: number, locationId: number, programId: number, teamScoringAuthorityId: number): Promise<void> {
  const rTeamScoringAuthority = new themis_common.TeamScoringAuthority()
  rTeamScoringAuthority.setId(teamScoringAuthorityId)
  const rTeam = new themis_common.Team()
  rTeam.setId(teamId)
  const rLocation = new themis_common.Location()
  rLocation.setId(locationId)
  const rProgram = new themis_common.Program()
  rProgram.setId(programId)
  rLocation.setProgram(rProgram)
  rTeam.setLocation(rLocation)
  rTeamScoringAuthority.setTeam(rTeam)

  const result = (await client.deleteTeamScoringAuthority(rTeamScoringAuthority, {})).toObject()
  await dispatch(setTeamScoringAuthorities(result.teamScoringAuthoritiesList))
}

async function clearTeam(dispatch: AnyDispatchType): Promise<void> {
  dispatch(setTeam(emptyTeam))
}

function updateTeam(team: themis_common.Team): any {
  return async (dispatch: any): Promise<void> => {
    const response = (await client.updateTeam(team, {})).toObject()
    await dispatch(setTeams(response['teamsList']))
  }
}

async function getTeamEventTeams(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number): Promise<themis_common.EventTeam.AsObject[]> {
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setTeamId(teamId)
  eventTeam.setProgramId(programId)
  eventTeam.setLocationId(locationId)

  const res = await client.getEventTeamsByTeamId(eventTeam, {})
  const teamResponse = res.toObject()
  return teamResponse.teamsList
}

async function removeTeamFromEvent(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, eventId: number): Promise<void> {
  if (!programId || !locationId || !teamId || !eventId) return
}

async function addTeamToEvent(dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, eventId: number): Promise<void> {
  if (!programId || !locationId || !teamId || !eventId) return
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setProgramId(programId)
  eventTeam.setLocationId(locationId)
  eventTeam.setTeamId(teamId)

  const event = new themis_common.Event()
  event.setId(eventId)
  eventTeam.setEvent(event)

  await client.addEventTeam(eventTeam, {})
}

async function getTeamUsers(teamId: number, locationId: number, programId: number): Promise<ObjectUserPermissions[]> {
  const team = new themis_common.Team()
  team.setId(teamId)
  team.setLocationId(locationId)
  team.setProgramId(programId)
  const response = (await client.getTeamUsers(team, {})).toObject()
  return response["usersPermissionsList"]
}


export {
  addAthleteToTeam,
  addAthletesToTeam,
  removeAthleteFromTeam,
  addCoachToTeam,
  removeCoachFromTeam,
  addPersonnelToTeam,
  removePersonnelFromTeam,
  doSetTeam,
  deleteTeam,
  addTeam,
  clearTeam,
  updateTeam,
  getTeamEventTeams,
  removeTeamFromEvent,
  addTeamToEvent,
  addScoringAuthority,
  setPrimaryScoringAuthority,
  removeScoringAuthority,
  getTeamUsers
}
