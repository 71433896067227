import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import themis_common from 'store/themis_common_pb'
import { TextField, MenuItem, Grid, InputAdornment } from '@material-ui/core'
import { IDivisionApprovalStatus, IDivisionOpenStatus } from '../JudgeEvent'

interface ScoreValue {
  value: string
  comment: string
}

interface RubricAreaItemProps  {
  rubricAreaItem: themis_common.RubricAreaItem.AsObject
  handleInputChange: (id:number, value: string) => void
  handleCommentChange: (id:number, value: string) => void
  formValues: Record<number,ScoreValue>
  initialScore: themis_common.EventDivisionShiftTeamRubricAreaScore.AsObject
  currentTeamId: number
  divisionApprovalStatus:IDivisionApprovalStatus[];
  divisionOpenStatus:IDivisionOpenStatus[];
  currentDivisionIndex:number;
}

const RubricAreaItem: React.FC<RubricAreaItemProps> = (props: RubricAreaItemProps): ReactElement => {
  const {currentDivisionIndex,divisionApprovalStatus,divisionOpenStatus} = props;
  const { id, label } = props.rubricAreaItem
  const { handleInputChange, handleCommentChange, formValues, initialScore, currentTeamId } = props
  
  const [currentScore, setCurrentScore] = useState<ScoreValue>({ value: initialScore.score + "", comment: initialScore.comment })
  const isCurrentDivisionApproved = divisionApprovalStatus[currentDivisionIndex].isApprove;
  const isCurrentDivisionOpen = divisionOpenStatus[currentDivisionIndex].isOpen;

  const commentChange = (id: number, comment: string) => {
    setCurrentScore(current => { return { ...current, comment: comment }})
    handleCommentChange(id, comment )
  }

  const valueChange = (id: number, value: string) => {
    if (value !== "" && (Number(value) < 0 )) return
    setCurrentScore(current => { return { ...current, value: value }})
    handleInputChange(id, value )
  }

  useEffect(() => {
    if (initialScore?.comment !== currentScore.comment) commentChange(id, initialScore?.comment)
    if (initialScore?.score + "" !== currentScore.value) valueChange(id, initialScore?.score + "")
  }, [currentTeamId])

  const inputFactory = useCallback(() => {
    const additionalInputProps: Record<string, string | boolean> = {}
    let options = null

    let type = 'number'
    return (
      // <TextField
      //   label="With normal TextField"
      //   id="outlined-start-adornment"
      //   InputProps={{
      //     startAdornment: <InputAdornment position="start">kg</InputAdornment>,
      //   }}
      // />
      <TextField
        type={ type }
        value={currentScore.value}
        variant="outlined"
        fullWidth
        id={`inp_${id.toString()}`}
        margin="normal"
        select={!!options}
        disabled={isCurrentDivisionApproved || isCurrentDivisionOpen}
        label={ <span style={{ fontSize: 14, marginLeft: -10 }}>{label}</span>}
        InputProps={{
          startAdornment: <InputAdornment position="start" style={{ fontSize: 12, backgroundColor: "grey", color: "white" }}>
            <div style={{ backgroundColor: '#D3D4D0', marginLeft: '-14px', marginTop: '9px', padding: "0px 5px", width: "76px", color: 'black' }}>
              Score :
            </div>
          </InputAdornment>,
          autoComplete: 'off',
          ...additionalInputProps,
        }}
        onFocus={event => { event.target.select() }}
        onChange={(e) => { valueChange(id, e.target.value) }}
      >
        {options}
      </TextField>
    )
  }, [currentScore.value, id, label, valueChange])

  return (
    <Grid key={`field-${id}`} container spacing={1} >
      <Grid item xs={4}>
        {inputFactory()}
      </Grid>
      <Grid item xs={8}>
        <TextField
          variant="outlined"
          fullWidth
          value={currentScore.comment}
          multiline
          id={`comment_${id.toString()}`}
          disabled={isCurrentDivisionApproved || isCurrentDivisionOpen}
          margin="normal"
          label='Comments'
          inputProps={{
            autoComplete: 'off',
            tabIndex: -1
          }}
          onChange={(e) => { commentChange(id, e.target.value) }}
        />
      </Grid>
    </Grid>
  )
}

export default RubricAreaItem
