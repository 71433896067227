import React, { ReactElement, useCallback, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Select, TextField, MenuItem, InputLabel, FormControl } from '@material-ui/core'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { useStyles } from "lib/theme"
import { AsFields, bugIssueTypes } from 'lib/constants'
import { getUserGeolocation } from "store/user/userActions"
import YesNoDialog from "components/util/YesNoModal"

interface BugReportProps {
  bugReport: AsFields
  setBugReport: React.Dispatch<React.SetStateAction<AsFields>>
  errorText: { [key: string]: string }
  setErrorText: React.Dispatch<React.SetStateAction<{[key: string]: string}>>
  checkBugReport: boolean
  setCheckBugReport: React.Dispatch<React.SetStateAction<boolean>>
  setFormReady: React.Dispatch<React.SetStateAction<boolean>>
  reportIssue: string
  showBugReport: boolean
  setShowBugReport: React.Dispatch<React.SetStateAction<boolean>>
  setUserGeoLocation: React.Dispatch<React.SetStateAction<GeolocationPosition | undefined>>
  setReportIssue: React.Dispatch<React.SetStateAction<string>>
  resetForm: () => void
  submitForm: any
  isSubmissionSuccessOpen: boolean
  setIsSubmissionSuccessOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const BugReport: React.FC<BugReportProps> = ({
  bugReport, setBugReport, errorText, setErrorText, checkBugReport, setCheckBugReport,
  setFormReady, reportIssue, showBugReport, setShowBugReport, setUserGeoLocation, setReportIssue,
  resetForm, submitForm, isSubmissionSuccessOpen, setIsSubmissionSuccessOpen
}): ReactElement => {
  const classes = useStyles()

  const requiredFields = {
    type: true,
    description: true,
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setBugReport({ ...bugReport, [name]: value })
    if (!value) {
      setErrorText({ ...errorText, [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} cannot be empty` })
    } else {
      setErrorText({ ...errorText, [name]: "" })
    }
  }

  useEffect(() => {
    if (checkBugReport) {
      const errors:{[key: string]: string} = {}
      Object.keys(requiredFields).forEach((key) => {
        if (bugReport[key] === "" || bugReport[key] === null) {errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} cannot be empty`}
      })
      setErrorText(errors)
      setCheckBugReport(false)
    }
    setFormReady(reportIssue !== "" && bugReport.description.length >= 1)
    // Don't include errorText in dependencies
  }, [checkBugReport, bugReport, requiredFields, reportIssue, setCheckBugReport, setFormReady])

  const handleGeoLocation = useCallback((position: GeolocationPosition) => {
    setUserGeoLocation(position)
  }, [setUserGeoLocation])

  useEffect(() => {
    if (showBugReport) getUserGeolocation(handleGeoLocation)
  }, [showBugReport, handleGeoLocation])


  return (
    <>
      <IconButton className={classes.bugReport} onClick={() => setShowBugReport(true)}>
        <ControlPointIcon fontSize="large"></ControlPointIcon>
      </IconButton>
      <Dialog open={showBugReport} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
        <DialogContent>
          <DialogContentText>
            Need help?
          </DialogContentText>
          <FormControl required variant="outlined" fullWidth>
            <InputLabel id="issue-type-label">Select Issue Type</InputLabel>
            <Select
              variant="outlined"
              id="type"
              name="type"
              value={reportIssue}
              label="Select Issue Type"
              required
              error={!!errorText.type}
              fullWidth
              onChange={e => {
                setReportIssue(e.target.value as string)
                handleInputChange(e)
              }}
            >
              {bugIssueTypes.map((issueType) => (
                <MenuItem key={issueType.name} value={issueType.name}>{issueType.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            margin="dense"
            id="description"
            name="description"
            label="Describe the issue"
            fullWidth
            size="medium"
            multiline
            rows="8"
            rowsMax="8"
            inputProps={{ maxLength: 1000 }}
            required
            error={!!errorText.description}
            helperText={errorText.description || ""}
            onChange={(e: any) => {handleInputChange(e)}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button onClick={submitForm} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <YesNoDialog
        title={"Thank You"}
        question={"We'll fix the issue soon."}
        isOpen={isSubmissionSuccessOpen}
        onClose={() => setIsSubmissionSuccessOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setIsSubmissionSuccessOpen(false) },
        ]}
      />
    </>
  )

}

export default BugReport
