import React, { ReactElement, useEffect, useState } from 'react'
import { InputAdornment, TextField, IconButton } from '@material-ui/core'
import { AsFields, regExpOneLetter, regexpOneSymbolOrNumber } from 'lib/constants'
import { Alert, AlertTitle } from '@material-ui/lab'
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { PassResetReg } from "../user/PasswordReset"

interface PasswordFieldsProps {
  errorText: { [key: string]: string }
  handleInputChange: (e: any) => void
  setErrorText:React.Dispatch<React.SetStateAction<{[key: string]: string}>>
  password: string
  passStrength: string
  setObject: React.Dispatch<React.SetStateAction<AsFields>> | React.Dispatch<React.SetStateAction<PassResetReg>>
  fields: { password: boolean; password2: boolean }
  password2: string
  setPasswordMatch: React.Dispatch<React.SetStateAction<boolean>>
  setPasswordStrongEnough: React.Dispatch<React.SetStateAction<boolean>>
  setAlertSeverity: React.Dispatch<React.SetStateAction<"error" | "info" | "success" | "warning" | undefined>>
  setPasswordStrengthText: React.Dispatch<React.SetStateAction<string>>
  passwordStrongEnough: boolean
  passwordStrengthText: string
  passwordMatch: boolean
  registration?: any
  alertSeverity?: "error" | "info" | "success" | "warning" | undefined
}

const PasswordFields: React.FC<PasswordFieldsProps> = ({
  errorText, handleInputChange, fields, registration, setErrorText, password, passStrength, setObject,
  password2, setAlertSeverity, setPasswordStrengthText, setPasswordStrongEnough, setPasswordMatch,
  passwordStrongEnough, passwordStrengthText, passwordMatch, alertSeverity
}): ReactElement => {

  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  useEffect(() => {
    if (password === password2 || password2 === "") {
      setPasswordMatch(true)
    } else {
      setPasswordMatch(false)
    }

    if (passwordMatch && password.length >=1 && password2.length >=1) {
      setErrorText({ ...errorText, password: "", password2: "" })
    } else if (!passwordMatch && password.length >=1) {
      setErrorText({ ...errorText, password: "Passwords do not match", password2: "Passwords do not match" })
    }
    // Password strength check
    if (password.length >=1) {
      if (passStrength === "Too weak") {
        setPasswordStrongEnough(false)
        setAlertSeverity("error")
      } else if (passStrength === "Weak") {
        setPasswordStrongEnough(true)
        setAlertSeverity("warning")
        setPasswordStrengthText(passStrength.toLowerCase())
      } else {
        setPasswordStrongEnough(true)
        setAlertSeverity("success")
        setPasswordStrengthText(passStrength.toLowerCase())
      }
    }
    // errorText should not be included (causes infinite rerender)
  }, [passStrength, setErrorText, password, password2, passwordMatch, setAlertSeverity, setPasswordMatch, setPasswordStrengthText, setPasswordStrongEnough])


  const handleClickShowPassword = (e: any) => {
    const { name } = e.currentTarget
    if (name === "password") setShowPassword(!showPassword)
    if (name === "password2") setShowPassword2(!showPassword2)
  }


  return (
    <>
      { password.length >= 1 && passStrength === "Too weak" ?
        (<Alert severity={alertSeverity}>
          <AlertTitle style={{ fontSize: "16px" }}>Password must include:</AlertTitle>
          { password.length < 6 ?
            (<li style={{ fontSize: "14px" }}>A minimum of six characters</li>)
            : <></>
          }
          { !regExpOneLetter.test(password) ?
            (<li style={{ fontSize: "14px" }}>At least one letter</li>)
            : <></>
          }
          { !regexpOneSymbolOrNumber.test(password) ?
            (<li style={{ fontSize: "14px" }}>At least one number or symbol</li>)
            : <></>
          }
        </Alert>) : <></>
      }
      { passwordStrongEnough ?
        (<Alert severity={alertSeverity}>
          Your password is {passwordStrengthText}
        </Alert>) : <></>
      }
      <TextField
        variant="outlined"
        margin="normal"
        required
        error={!!errorText.password}
        helperText={errorText.password || ""}
        fullWidth
        name="password"
        label="Password"
        type={showPassword ? "text" : "password"}
        id="password"
        autoComplete="current-password"
        onChange={(e) => {// @ts-ignore
          handleInputChange(e, fields, registration, setObject, errorText, setErrorText)
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                name="password"
                id="password"
                onClick={(e) => {
                  handleClickShowPassword(e)
                }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        error={!!errorText.password2}
        helperText={errorText.password2 || ""}
        fullWidth
        name="password2"
        label="Password Again"
        type={showPassword2 ? "text" : "password"}
        id="password2"
        autoComplete="current-password"
        onChange={(e) => {// @ts-ignore
          handleInputChange(e, fields, registration, setObject, errorText, setErrorText)
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                name="password2"
                id="password2"
                onClick={(e) => {
                  handleClickShowPassword(e)
                }}
              >
                {showPassword2 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </>
  )
}

export default PasswordFields
