import { Button, Dialog, Grid, TextField, Tooltip, Typography, IconButton, DialogTitle, DialogContent, DialogActions, FormGroup, Checkbox, FormControlLabel, Switch, Popper, Paper, FormControl, RadioGroup, Radio } from "@material-ui/core";
import { FormValues, skillTypes, templateTypes } from "lib/constants";
import { useStyles } from "lib/theme";
import { useEffect, useState } from "react";
import { Add as AddIcon, Edit as EditIcon, Remove as RemoveIcon, InfoOutlined } from '@material-ui/icons';
import LabelComponent from "../rubricComponents/Label";
import { Autocomplete } from "@material-ui/lab";
import { camelCase } from "lib/functions";

interface Form2Props {
    index: number,
    responseData: any;
    templateType: string;
    objectName: string;
    formData: any;
    rubricId: number;
}
const Form2: React.FC<Form2Props> = ({ index, responseData, templateType, objectName, formData, rubricId }) => {
    const classes = useStyles();

    const dataValues = {
        "0": [
            {
                "title": "Difficulty (Skills)",
                "type": "staticLabel",
                "visible": true
            },
            {
                "title": "Skill",
                "type": "button",
                "visible": true,
                "guid": "f0a18f59-a598-4c19-b57d-acb4b059b682",
                "subType": false
            },
            {
                "title": "Coed Skill",
                "type": "button",
                "visible": true,
                "guid": "9d17b423-8f0a-4dbb-9c41-affaf11ea50e",
                "subType": true
            },
            {
                "key": "totalAthletes",
                "title": "Total # of Athletes",
                "type": "textbox",
                "visible": true,
                "required": true,
                "guid": "c72414b1-99c9-4461-bd4e-4c6d9a7c8037"
            },
            {
                "key": "totalMales",
                "title": "Total # of Males",
                "type": "textbox",
                "visible": true,
                "required": true,
                "showHelpText": true,
                "helpText": "# OF MALES ON TEAM: 1 Or More \\n # OF STUNTS: 1",
                "guid": "c354a89d-df94-4c82-b5e3-27343db1759e"
            }
        ],
        "1": [
            {
                "title": "Skill",
                "type": "label",
                "visible": true
            },
            {
                "key": "skillTypes",
                "title": "skillTypes",
                "type": "radiobutton",
                "visible": true,
                "required": true,
                "enums": [
                    {
                        "title": "Level Appropriate",
                        "visible": true,
                        "majority": 0,
                        "most": 0,
                        "max": 0,
                        "coed": 0
                    },
                    {
                        "title": "Advanced",
                        "visible": true,
                        "majority": 0,
                        "most": 0.1,
                        "max": 0.2,
                        "coed": 0.1
                    },
                    {
                        "title": "Elite",
                        "visible": true,
                        "majority": 0,
                        "most": 0.2,
                        "max": 0.2,
                        "coed": 0.2
                    }
                ]
            },
            {
                "title": "Number of Groups Skill",
                "key": "numberOfGroupsSkill",
                "type": "dropdownRange",
                "rangeType": "noOfAthelete",
                "required": true,
                "visible": true,
                "enums": [
                    {
                        "min": "5",
                        "max": "11",
                        "const": "1",
                        "title": "1 (MAJORITY)"
                    },
                    {
                        "min": "12",
                        "max": "19",
                        "const": "2",
                        "title": "2 (MOST)"
                    },
                ]
            },
            {
                "title": "Delete",
                "type": "deleteButton",
                "visible": true
            }
        ],
        "2": [
            {
                "key": "calculationSummary",
                "title": "Calculation Summary:",
                "type": "calculationSummaryLabel",
                "visible": true
            },
            {
                "key": "scoreValue",
                "title": "Score Value:",
                "type": "scoreValueLabel",
                "visible": true
            },
            {
                "key": "isComment",
                "title": "Comment",
                "type": "switch",
                "visible": true,
                "value": true
            },
            {
                "key": "comment",
                "title": "Comment",
                "type": "textboxarea",
                "required": true,
                "visible": true
            }
        ],
        "3": [
            {
                "title": "Max Participation",
                "type": "endLabel",
                "visible": true
            },
            {
                "key": "maxParticipationDriverCalculationSummary",
                "title": "Calculation Summary",
                "type": "dropdown",
                "required": true,
                "visible": true,
                "enums": [
                    {
                        "value": 0.3,
                        "label": "0.3 (Level Appropriate Skill by MAX OR Advanced Skill by MOST)"
                    },
                    {
                        "value": 0.5,
                        "label": "0.5 (Elite Skill by MAX OR Advanced Skill by MOST)"
                    }
                ]
            },
            {
                "key": "maxParticipationDriverScoreValue",
                "title": "Score Value:",
                "type": "endInnerLabel",
                "visible": true
            }
        ]
    }

    // Dynamically initialize the response state with an empty object for each item in dataValues
    const initialResponseState = Object.keys(dataValues).reduce((acc: any, key: any) => {
        acc = dataValues
        return acc;
    }, {});

    const [response, setResponse] = useState<any>({
        data: {
            rows: initialResponseState
        }
    });

    useEffect(() => {
        // Set data for Edit form
        if (formData) {
            if (objectName) {
                const obj = formData.find((obj: any) => {
                    return obj.data.items[0].objectName === objectName
                })
                if(obj) {
                    setData(obj.data.items[0].data.rows);
                }
            }
        }
    }, [formData]);

    const [data, setData] = useState<any>(dataValues);
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [editedElement, setEditedElement] = useState<any | null>(null);
    const [dialogDropdownOptions, setDialogDropdownOptions] = useState([{ value: "", label: "" }]);
    const [dialogDropdownRangeOptions, setDialogDropdownRangeOptions] = useState([{ min: "", max: "", const: "", title: "" }]);
    const [dialogRadioOptions, setDialogRadioOptions] = useState([{ title: "", visible: true, majority: "", most: "", max: "", coed: "" }]);
    const [isComment, setIsComment] = useState<boolean>(true);
    const [idx, setIdx] = useState<number>(0);
    const [helpTextObj, setHelpTextObj] = useState<{ showHelpText: boolean, helpText: string }>({ showHelpText: true, helpText: "" });
    const [errorText, setErrorText] = useState<string[]>([]);
    const [errorConstText, setErrorConstText] = useState<string[]>([]);
    const [titleError, setTitleError] = useState<string>('');

    useEffect(() => {
        responseData(response, index);
    }, [response]);

    // Set data for Edit form   
    useEffect(() => {
        if (formData) {
            if (objectName) {
                const obj = formData.find((obj: any) => {
                    return obj.data.items[0].objectName === objectName
                })
                setData(obj.data.items[0].data.rows);

                setResponse({
                    data: {
                        rows: obj.data.items[0].data.rows
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        //Etract enums for the dropdown
        let enums;
        if (rubricId > 0) {
            const template2Item = formData.find((item: any) => item.data.items[0].templateName === 'template-2');
            if (template2Item) {
                enums = template2Item.data.items[0].data.rows[3].find((field: any) => field.type === 'dropdown')?.enums;
            }
        } else {
            enums = response?.data?.rows[3]?.find((field: any) => field.type === 'dropdown')?.enums;
        }

        // Formatting enums into required format
        if (enums && enums.length > 0) {
            const dropdownOptions = enums.map((enumItem: any) => ({
                value: enumItem.value,
                label: enumItem.label
            }));

            // Setting dropdown options into state
            setDialogDropdownOptions(dropdownOptions);
        }

        //Etract enums for the dropdownRange
        let rangeEnums;
        if (rubricId > 0) {
            const template2Item = formData.find((item: any) => item.data.items[0].templateName === 'template-2');
            if (template2Item) {
                rangeEnums = template2Item.data.items[0].data.rows[1].find((field: any) => field.type === 'dropdownRange')?.enums;
            }
        } else {
            rangeEnums = response?.data?.rows[1]?.find((field: any) => field.type === 'dropdownRange')?.enums;
        }
        // Formatting enums into required format
        if (rangeEnums && rangeEnums.length > 0) {
            const dropdownRangeOptions = rangeEnums.map((enumItem: any) => ({
                min: enumItem.min,
                max: enumItem.max,
                const: enumItem.const,
                title: enumItem.title
            }));

            // Setting dropdown options into state
            setDialogDropdownRangeOptions(dropdownRangeOptions);
        }

        //Etract enums for the radiobutton
        let radioOption;
        if (rubricId > 0) {
            const template2Item = formData.find((item: any) => item.data.items[0].templateName === 'template-2');
            if (template2Item) {
                radioOption = template2Item.data.items[0].data.rows[1].find((field: any) => field.type === 'radiobutton')?.enums;
            }
        } else {
            radioOption = response?.data?.rows[1]?.find((field: any) => field.type === 'radiobutton')?.enums;
        }

        // Formatting enums into required format
        if (radioOption && radioOption.length > 0) {
            const radioButtonOptions = radioOption.map((enumItem: any) => ({
                title: enumItem.title,
                visible: enumItem.visible,
                majority: enumItem.majority,
                most: enumItem.most,
                max: enumItem.max,
                coed: enumItem.coed,
            }));

            // Setting dropdown options into state
            setDialogRadioOptions(radioButtonOptions);
        }
    }, []);

    const handleAddOptionDialog = () => {
        setDialogDropdownOptions([...dialogDropdownOptions, { value: "", label: "" }]);
    };

    const handleAddOptionRangeDialog = () => {
        setDialogDropdownRangeOptions([...dialogDropdownRangeOptions, { min: "", max: "", const: "", title: "" }]);
    }

    const handleAddRadioOptionDialog = () => {
        setDialogRadioOptions([...dialogRadioOptions, { title: "", visible: true, majority: "", most: "", max: "", coed: "" }]);
    }

    const handleRemoveOptionDialog = (index: number) => {
        const updatedOptions = [...dialogDropdownOptions];
        updatedOptions.splice(index, 1);
        setDialogDropdownOptions(updatedOptions);

    }

    const handleRemoveOptionRangeDialog = (index: number) => {
        const updatedRangeOptions = [...dialogDropdownRangeOptions];
        updatedRangeOptions.splice(index, 1);
        setDialogDropdownRangeOptions(updatedRangeOptions);

    }

    const handleRemoveRadioOptionDialog = (index: number) => {
        const updatedOptions = [...dialogRadioOptions];
        updatedOptions.splice(index, 1);
        setDialogRadioOptions(updatedOptions);
    }

    const handleOptionChangeDialog = (index: number, key: string, value: any) => {
        const updatedOptions: any = [...dialogDropdownOptions];
        updatedOptions[index][key] = value;

        // Check for duplicates based on label or value
        const newOptionValue = updatedOptions[index][key];
        const isDuplicate = updatedOptions.some((option: any, i: number) => i !== index && option.value === newOptionValue);

        // Update the error text accordingly
        const err = [...errorText];
        err[index] = isDuplicate ? `${key} should be unique` : '';
        setErrorText(err);

        // Update the state with the modified options array if no duplicates found
        if (!isDuplicate) {
            setDialogDropdownOptions(updatedOptions);
        }

        setEditedElement((prevEditedElement: any) => {
            if (prevEditedElement && prevEditedElement.type === FormValues.DROPDOWN || prevEditedElement.type === FormValues.DROPDOWN_RANGE) {
                return {
                    ...prevEditedElement,
                    enums: updatedOptions,
                };
            }
            return prevEditedElement;
        });
    };

    const handleRangeOptionChangeDialog = (index: number, key: string, value: any) => {
        const updatedOptions: any = [...dialogDropdownRangeOptions];
        updatedOptions[index][key] = value;

        // Check for duplicates based on label or value
        const newOptionValue = updatedOptions[index][key];
        let isDuplicate, isConstDuplicate;
        if (key === 'title') {
            isDuplicate = updatedOptions.some((option: any, i: number) => i !== index && option.title === newOptionValue);
        }

        // Update the error text accordingly
        const err = [...errorText];
        err[index] = isDuplicate ? `${key} should be unique` : '';
        setErrorText(err);

        if (key === 'const') {
            isConstDuplicate = updatedOptions.some((option: any, i: number) => i !== index && option.const === newOptionValue);
            // Update the error text accordingly
            const err = [...errorConstText];
            err[index] = isConstDuplicate ? `${key} should be unique` : '';
            setErrorConstText(err);
        }

        // Update the state with the modified options array if no duplicates found
        if (!isDuplicate) {
            setDialogDropdownOptions(updatedOptions);
        }

        setEditedElement((prevEditedElement: any) => {
            if (prevEditedElement && prevEditedElement.type === FormValues.DROPDOWN || prevEditedElement.type === FormValues.DROPDOWN_RANGE) {
                return {
                    ...prevEditedElement,
                    enums: updatedOptions,
                };
            }
            return prevEditedElement;
        });
    };

    const handleOptionRadioChangeDialog = (index: number, key: string, value: any) => {
        const updatedOptions: any = [...dialogRadioOptions];
        updatedOptions[index][key] = value;

        // Check for duplicates based on label or value
        const newOptionValue = updatedOptions[index][key];
        let isDuplicate, isEmpty;
        if (key === "title") {
            isDuplicate = updatedOptions.some((option: any, i: number) => i !== index && option.title === newOptionValue);

            // Check if the value is empty
            isEmpty = newOptionValue === "";

            // Update the error text accordingly
            const err = [...errorText];
            err[index] = isDuplicate ? `${key} should be unique` : (isEmpty ? `${key} cannot be empty` : "");
            setErrorText(err);
        }

        // Update the state with the modified options array if no duplicates found and value is not empty
        if (!isDuplicate && !isEmpty) {
            setDialogRadioOptions(updatedOptions);
        }

        setEditedElement((prevEditedElement: any) => {
            if (prevEditedElement) {
                return {
                    ...prevEditedElement,
                    enums: updatedOptions,
                };
            }
            return prevEditedElement;
        });
    };

    const addDropdownOptionsDialog = (
        <>
            {dialogDropdownOptions.map((option, index) => (
                <Grid container key={index} style={{ marginTop: '5px' }} className={classes.grouper}>
                    <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                        <TextField
                            label="Value"
                            name="value"
                            id="value"
                            variant="outlined"
                            fullWidth
                            value={option.value}
                            onChange={(event) => handleOptionChangeDialog(index, event.target.name, event.target.value)}
                            error={(errorText[index] ? true : false)}
                            helperText={(index !== undefined) ? errorText[index] : errorText}
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} className={classes.rubricScoring}>
                        <TextField
                            label="Label"
                            name="label"
                            id="label"
                            variant="outlined"
                            fullWidth
                            value={option.label}
                            onChange={(event) => handleOptionChangeDialog(index, event.target.name, event.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={1} className={classes.rubricScoring}>
                        {index > 0 && (
                            <IconButton onClick={() => handleRemoveOptionDialog(index)}>
                                <RemoveIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    );

    const addDropdownRangeOptionsDialog = (
        <>
            {dialogDropdownRangeOptions.map((option, index) => (
                <Grid container key={index} style={{ marginTop: '5px' }} className={classes.grouper}>
                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring}>
                        <TextField
                            label="Min"
                            name="min"
                            id="min"
                            variant="outlined"
                            fullWidth
                            value={option.min}
                            onChange={(event) => handleRangeOptionChangeDialog(index, event.target.name, event.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring}>
                        <TextField
                            label="Max"
                            name="max"
                            id="max"
                            variant="outlined"
                            fullWidth
                            value={option.max}
                            onChange={(event) => handleRangeOptionChangeDialog(index, event.target.name, event.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring}>
                        <TextField
                            label="Const"
                            name="const"
                            id="const"
                            variant="outlined"
                            fullWidth
                            value={option.const}
                            onChange={(event) => handleRangeOptionChangeDialog(index, event.target.name, event.target.value)}
                            error={(errorConstText[index] ? true : false)}
                            helperText={(index !== undefined) ? errorConstText[index] : errorText}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={11} className={classes.rubricScoring}>
                        <TextField
                            label="Title"
                            name="title"
                            id="title"
                            variant="outlined"
                            fullWidth
                            value={option.title}
                            onChange={(event) => handleRangeOptionChangeDialog(index, event.target.name, event.target.value)}
                            style={{ marginTop: '5px' }}
                            error={(errorText[index] ? true : false)}
                            helperText={(index !== undefined) ? errorText[index] : errorText}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={1} className={classes.rubricScoring} alignItems="center">
                        {index > 0 && (
                            <IconButton onClick={() => handleRemoveOptionRangeDialog(index)}>
                                <RemoveIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    );

    const addRadioOptionsDialog = (
        <>
            {dialogRadioOptions.map((option, index) => (
                <Grid container key={index} style={{ marginTop: '5px' }} className={classes.grouper}>
                    <Grid item xs={12} sm={8} md={8} className={classes.rubricScoring}>
                        <TextField
                            label="Title"
                            name="title"
                            id="title"
                            variant="outlined"
                            fullWidth
                            value={option.title}
                            onChange={(event) => handleOptionRadioChangeDialog(index, event.target.name, event.target.value)}
                            error={(errorText[index] ? true : false)}
                            helperText={(index !== undefined) ? errorText[index] : errorText}
                        />
                    </Grid>

                    <FormGroup>
                        <FormControlLabel
                            label="Visible"
                            name="visible"
                            id="visible"
                            control={<Checkbox
                                defaultChecked={option.visible}
                                onChange={(event) => handleOptionRadioChangeDialog(index, event.target.name, event.target.checked)}
                            />}
                        />
                    </FormGroup>

                    <Grid item xs={12} sm={6} md={1} className={classes.rubricScoring}>
                        {index > 0 && (
                            <IconButton onClick={() => handleRemoveRadioOptionDialog(index)}>
                                <RemoveIcon />
                            </IconButton>
                        )}
                    </Grid>

                    <>
                        <Grid container>
                            <Typography>Simple</Typography>
                            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '5px' }} className={classes.rubricScoring}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="majority"
                                    name="majority"
                                    label="Majority"
                                    value={option.majority}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        const isValidInput = /^-?\d*\.?\d*$/.test(value); // Regular expression to match floating-point numbers
                                        if (isValidInput || value === '') {
                                            handleOptionRadioChangeDialog(index, event.target.name, event.target.value);
                                        }
                                    }}
                                    className={classes.textBoxStyle}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '5px' }} className={classes.rubricScoring}>
                                <TextField
                                    label="Most"
                                    name="most"
                                    id="most"
                                    variant="outlined"
                                    fullWidth
                                    value={option.most}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        const isValidInput = /^-?\d*\.?\d*$/.test(value); // Regular expression to match floating-point numbers
                                        if (isValidInput || value === '') {
                                            handleOptionRadioChangeDialog(index, event.target.name, event.target.value);
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '5px' }} className={classes.rubricScoring}>
                                <TextField
                                    label="Max"
                                    name="max"
                                    id="max"
                                    variant="outlined"
                                    fullWidth
                                    value={option.max}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        const isValidInput = /^-?\d*\.?\d*$/.test(value); // Regular expression to match floating-point numbers
                                        if (isValidInput || value === '') {
                                            handleOptionRadioChangeDialog(index, event.target.name, event.target.value);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {templateType === 'coed' && <Grid container>
                            <Typography>Coed</Typography>
                            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '5px' }} className={classes.rubricScoring}>
                                <TextField
                                    label="Coed"
                                    name="coed"
                                    id="coed"
                                    variant="outlined"
                                    fullWidth
                                    value={option.coed}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        const isValidInput = /^-?\d*\.?\d*$/.test(value); // Regular expression to match floating-point numbers
                                        if (isValidInput || value === '') {
                                            handleOptionRadioChangeDialog(index, event.target.name, event.target.value);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>}

                    </>
                </Grid>
            ))}
        </>
    );

    const handleOpenDialog = (element: any, idx: number) => {
        setIdx(idx)
        setEditedElement(element);
        setEditDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setEditDialogOpen(false);
        setEditedElement(null);
        setErrorText([]);
        setErrorConstText([]);
        setTitleError('');
    };

    const handleSaveEditedElement = () => {
        if (!titleError && errorText.every(text => !text) && errorConstText.every(text => !text)) {
            let updatedEditedElement = editedElement;

            setResponse((prevResponse: any) => {
                const updatedRow = [...(prevResponse?.data?.rows[idx] || [])];
                const existingIndex = updatedRow.findIndex((item: any) => {
                    if (updatedEditedElement.type === FormValues.TEXTBOX) {
                        return item.guid === updatedEditedElement.guid
                    } else if (updatedEditedElement.type === FormValues.BUTTON) {
                        return item.guid === updatedEditedElement.guid
                    } else {
                        return item.type === updatedEditedElement.type
                    }
                });

                if (existingIndex !== -1) {
                    // If element with the same key exists, update it
                    updatedRow[existingIndex] = { ...updatedEditedElement };
                } else {
                    // If element with the same key doesn't exist, add the new element
                    updatedRow.push({ ...updatedEditedElement });
                }

                const updatedRows = { ...prevResponse?.data?.rows };
                updatedRows[idx] = updatedRow;
                return {
                    data: {
                        rows: updatedRows
                    }
                };
            });

            setData((prevData: any) => {
                const updatedData = [...prevData[idx]];
                const index = updatedData.findIndex((item) => {
                    if (updatedEditedElement.type === FormValues.TEXTBOX) {
                        return item.guid === updatedEditedElement.guid
                    } else if (updatedEditedElement.type === FormValues.BUTTON) {
                        return item.guid === updatedEditedElement.guid
                    } else {
                        return item.type === updatedEditedElement.type
                    }
                });

                if (index !== -1) {
                    updatedData[index] = { ...updatedEditedElement };
                }
                return { ...prevData, [idx]: updatedData };
            });

            handleCloseDialog();
        }
    };

    const handleSetEditedElement = (updatedElement: any) => {
        // Automatically update the key field based on the title field
        const camelCaseKey = camelCase(updatedElement.title);
        setEditedElement({ ...updatedElement, key: camelCaseKey });
    };

    const renderDialogContent = () => {
        if (!editedElement) return null;
        switch (editedElement.type) {
            case FormValues.STATIC_LABEL:
            case FormValues.LABEL:
            case FormValues.CALCULATIONSUMMARY_LABEL:
            case FormValues.SCOREVALUE_LABEL:
            case FormValues.END_LABEL:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <FormGroup>
                            <FormControlLabel
                                label="Visible"
                                name="visible"
                                control={<Checkbox defaultChecked={editedElement.visible}
                                    onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                />}
                            />
                        </FormGroup>
                    </Grid>
                );

            case FormValues.END_INNER_LABEL:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <FormGroup>
                            <FormControlLabel
                                label="Visible"
                                name="visible"
                                control={<Checkbox defaultChecked={editedElement.visible}
                                    onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                />}
                            />
                        </FormGroup>
                    </Grid>
                );

            case FormValues.TEXTBOX:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Required"
                                    name="required"
                                    control={<Checkbox defaultChecked={editedElement.required}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>

                            {editedElement.guid === 'c354a89d-df94-4c82-b5e3-27343db1759e' && <Grid container>
                                <FormGroup>
                                    <FormControlLabel
                                        label="ShowHelpText"
                                        name="showHelpText"
                                        id="showHelpText"
                                        control={<Checkbox defaultChecked={editedElement.showHelpText}
                                            onChange={(event) => {
                                                setHelpTextObj(prevState => ({
                                                    ...prevState,
                                                    showHelpText: event.target.checked
                                                }));
                                                setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })
                                            }}
                                        />}
                                    />
                                </FormGroup>

                                <Grid item xs={12} sm={12} md={12} className={classes.rubricScoring}>
                                    <TextField
                                        label="HelpText"
                                        name="helpText"
                                        id="helpText"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows="4"
                                        rowsMax="4"
                                        value={editedElement.helpText}
                                        onChange={(event) => {
                                            setHelpTextObj(prevState => ({
                                                ...prevState,
                                                helpText: event.target.value
                                            }));
                                            setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                        }}
                                    />
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>
                );

            case FormValues.RADIO_BUTTON:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    handleSetEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Required"
                                    name="required"
                                    control={<Checkbox defaultChecked={editedElement.required}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <Typography variant="h2">
                                Options
                                <IconButton onClick={handleAddRadioOptionDialog}>
                                    <AddIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                        {addRadioOptionsDialog}
                    </Grid>
                );

            case FormValues.DROPDOWN:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Required"
                                    name="required"
                                    control={<Checkbox defaultChecked={editedElement.required}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <Typography variant="h2">
                                Options
                                <IconButton onClick={handleAddOptionDialog}>
                                    <AddIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                        {addDropdownOptionsDialog}

                    </Grid>
                );

            case FormValues.DROPDOWN_RANGE:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Required"
                                    name="required"
                                    control={<Checkbox defaultChecked={editedElement.required}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <Typography variant="h2">
                                Options
                                <IconButton onClick={handleAddOptionRangeDialog}>
                                    <AddIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Typography>
                            <b>NOTE:</b> Title should contains this format only. <br />
                            EX: value(MAJORITY), value(MOST), value(MAX).
                        </Typography>
                        {addDropdownRangeOptionsDialog}

                    </Grid>
                );

            case FormValues.SWITCH:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Default Value"
                                    name="value"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                );

            case FormValues.TEXTBOXAREA:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    handleSetEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Required"
                                    name="required"
                                    control={<Checkbox defaultChecked={editedElement.required}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                );

            case FormValues.BUTTON:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }

                                    if (editedElement.guid === 'f0a18f59-a598-4c19-b57d-acb4b059b682') {
                                        setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                    } else {
                                        setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                    }
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <FormGroup>
                            <FormControlLabel
                                label="Visible"
                                name="visible"
                                control={<Checkbox defaultChecked={editedElement.visible}
                                    onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                />}
                            />
                        </FormGroup>
                    </Grid>
                );

            case FormValues.DELETE_BUTTON:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <FormGroup>
                            <FormControlLabel
                                label="Visible"
                                name="visible"
                                control={<Checkbox defaultChecked={editedElement.visible}
                                    onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                />}
                            />
                        </FormGroup>
                    </Grid>
                );

            default:
                return null;
        }
    };

    const dialog = (
        <Dialog open={editDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Edit Element</DialogTitle>
            <DialogContent>
                {renderDialogContent()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSaveEditedElement} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );

    const renderFormElements = () => {
        return Object.values(data).map((elements: any, idx: number) => {
            return elements.map((value: any, index: number) => {
                switch (value.type) {
                    case FormValues.STATIC_LABEL:
                        return (
                            <Grid container item xs={12} sm={6} md={2} alignItems="center" key={`row_${index}`}>
                                {/* Label Component */}
                                <Grid item>
                                    <LabelComponent title={value.title} />
                                </Grid>
                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                        );

                    case FormValues.RADIO_BUTTON:
                        return (
                            <Grid container xs={12} sm={6} md={4} key={`row_${index}`} alignItems="center">
                                {/* Radio button Component */}
                                <Grid item style={{ marginLeft: '7%' }}>
                                    <FormControl>
                                        <RadioGroup row aria-label={value.key} name={value.key} >
                                            {value.enums && value.enums.map((option: any, idx: any) => {
                                                if (option.visible) {
                                                    return (
                                                        <FormControlLabel
                                                            key={`${index}`}
                                                            value={option.title}
                                                            control={<Radio />}
                                                            label={option.title}
                                                        />
                                                    );
                                                }
                                                return null;
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {/* Edit Icon */}
                                <Grid item >
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="medium">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.DROPDOWN_RANGE:
                        return (
                            <Grid container xs={12} sm={6} md={5} key={`row_${index}`} alignItems="center">
                                {/* DropdownRange Component */}
                                <Grid item xs={12} sm={6} md={7} style={{ marginLeft: '21%' }}>
                                    <Autocomplete
                                        options={dialogDropdownRangeOptions}
                                        // fullWidth
                                        getOptionLabel={(obj: any) => obj.title}
                                        // disableClearable
                                        id={value.key}
                                        renderInput={(params: any) => (
                                            <TextField
                                                style={{ marginTop: 20, marginBottom: 10 }}
                                                {...params}
                                                autoComplete="none"
                                                label={value.title}
                                                name={value.key}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>

                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.DROPDOWN:
                        return (
                            <Grid container>
                                <Grid container key={`row_${index}`} alignItems="center" xs={12} sm={6} md={5}>
                                    {/* Dropdown Component */}
                                    <Grid item xs={12} sm={6} md={8}>
                                        <Autocomplete
                                            options={dialogDropdownOptions}
                                            fullWidth
                                            getOptionLabel={(obj: any) => obj.label}
                                            disableClearable
                                            id={value.key}
                                            renderInput={(params: any) => (
                                                <TextField
                                                    style={{ marginTop: 20, marginBottom: 10 }}
                                                    {...params}
                                                    autoComplete="none"
                                                    label={value.title}
                                                    name={value.key}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {/* Edit Icon */}
                                    <Grid item>
                                        <Tooltip title={`Edit_${data[idx][index].title}`}>
                                            <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.SWITCH:
                        return (
                            <Grid container alignItems="center" xs={12} sm={4} md={2} className={classes.rubricScoring} key={`row_${index}`} >
                                {/* Switch Component */}
                                <Grid item>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Switch
                                                name={value.title}
                                                defaultChecked={true}
                                                onChange={(event) => {
                                                    setIsComment(event.target.checked)
                                                }}
                                            />}
                                            label={value.title} />
                                    </FormGroup>
                                </Grid>

                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.TEXTBOX:
                        return (
                            (templateType.toLowerCase() !== 'simple') ?
                                (
                                    <Grid container xs={12} sm={6} md={3} className={classes.rubricScoring} key={`row_${index}`} >
                                        {/* Textbox Component */}
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id={value.key}
                                                name={value.key}
                                                label={value.title}
                                                inputProps={{
                                                    autoComplete: 'off',
                                                    tabIndex: -1,
                                                }}
                                            />
                                        </Grid>

                                        {value.guid === 'c354a89d-df94-4c82-b5e3-27343db1759e' && helpTextObj.showHelpText ? (
                                            (<Tooltip title={<span className={classes.helperText}>{helpTextObj.helpText ? helpTextObj.helpText : value.helpText}</span>} >
                                                <InfoOutlined
                                                    color="action"
                                                    className={classes.infoIcon}
                                                />
                                            </Tooltip>)
                                        ) : null}

                                        {/* Edit Icon */}
                                        <Grid item>
                                            <Tooltip title={`Edit_${data[idx][index].title}`}>
                                                <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>

                                    </Grid>
                                ) :
                                (
                                    ((value.guid) === 'c72414b1-99c9-4461-bd4e-4c6d9a7c8037') &&
                                    <Grid container xs={12} sm={6} md={6} className={classes.rubricScoring} key={`row_${index}`} >
                                        {/* Textbox Component */}
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id={value.key}
                                                name={value.key}
                                                label={value.title}
                                                inputProps={{
                                                    autoComplete: 'off',
                                                    tabIndex: -1,
                                                }}
                                            />
                                        </Grid>

                                        {value.guid === 'c354a89d-df94-4c82-b5e3-27343db1759e' && helpTextObj.showHelpText ? (
                                            (<Tooltip title={<span className={classes.helperText}>{helpTextObj.helpText ? helpTextObj.helpText : value.helpText}</span>} >
                                                <InfoOutlined
                                                    color="action"
                                                    className={classes.infoIcon}
                                                />
                                            </Tooltip>)
                                        ) : null}

                                        {/* Edit Icon */}
                                        <Grid item>
                                            <Tooltip title={`Edit_${data[idx][index].title}`}>
                                                <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>

                                    </Grid>
                                )
                        );

                    case FormValues.TEXTBOXAREA:
                        return (isComment) && (
                            <Grid container xs={12} sm={6} md={5} className={classes.rubricScoring} key={`row_${index}`} >
                                {/* Textbox area Component */}
                                <Grid item xs={12} sm={6} md={7}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows="2"
                                        rowsMax="2"
                                        id={value.key}
                                        name={value.key}
                                        label={value.title}
                                        inputProps={{
                                            autoComplete: 'off',
                                            tabIndex: -1,
                                        }}
                                    />
                                </Grid>

                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.BUTTON:
                        return (
                            (templateType.toLowerCase() !== 'simple') ?
                                (
                                    <Grid container xs={12} sm={6} md={2} className={classes.rubricScoring} key={`row_${index}`}>
                                        {/* Button Component */}
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                className={classes.divisionSubmit}
                                            >
                                                {value.title}
                                            </Button>
                                        </Grid>

                                        {/* Edit Icon */}
                                        <Grid item>
                                            <Tooltip title={`Edit_${data[idx][index].title}`}>
                                                <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                )
                                :
                                (
                                    ((value.title).toLowerCase() === skillTypes.skill) &&
                                    <Grid container xs={12} sm={6} md={4} className={classes.rubricScoring} key={`row_${index}`}>
                                        {/* Button Component */}
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                className={classes.divisionSubmit}
                                            >
                                                {value.title}
                                            </Button>
                                        </Grid>

                                        {/* Edit Icon */}
                                        <Grid item>
                                            <Tooltip title={`Edit_${data[idx][index].title}`}>
                                                <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                )
                        );

                    case FormValues.DELETE_BUTTON:
                        return (
                            <Grid container xs={12} sm={2} md={2} className={classes.rubricScoring} key={`row_${index}`} style={{ marginTop: '-10px' }}>
                                {/* Delete Button Component */}
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        className={classes.divisionSubmit}
                                    >
                                        {value.title}
                                    </Button>
                                </Grid>

                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid >
                        );

                    case FormValues.LABEL:
                        return (
                            <Grid container item xs={12} sm={6} md={1} alignItems="center" key={`row_${index}`}>
                                {/* Label Component */}
                                <Grid item>
                                    <LabelComponent title={value.title} />
                                </Grid>
                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                        );

                    case FormValues.END_LABEL:
                        return (
                            <Grid container item xs={12} sm={6} md={2} alignItems="center" key={`row_${index}`}>
                                {/* Label Component */}
                                <Grid item>
                                    <LabelComponent title={value.title} />
                                </Grid>
                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                        );

                    case FormValues.CALCULATIONSUMMARY_LABEL:
                    case FormValues.SCOREVALUE_LABEL:
                    case FormValues.END_INNER_LABEL:
                        return (
                            <Grid container item xs={12} sm={6} md={2} alignItems="center" key={`row_${index}`}>
                                {/* Label Component */}
                                <Grid item>
                                    <LabelComponent title={value.title} />
                                </Grid>
                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)} size="small">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    default:
                        return null;
                }
            })
        });
    };

    return (
        <Grid container alignItems="center" className={classes.grouperForm} style={{ marginTop: '10px' }}>
            {renderFormElements()}
            {dialog}
        </Grid>
    );
}

export default Form2;