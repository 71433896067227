import React, { ReactElement, useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import {
  Button,
  Grid
} from '@material-ui/core'
import { BalanceTotals, PaymentDetails } from "./BalanceContainer"
import themis_common from "store/themis_common_pb"
import { Refresh as RefreshIcon, } from '@material-ui/icons'
import { addCommas } from "lib/functions"
import { useStyles } from "lib/theme"
import BalanceEvents from "./BalanceEvents"
import BalanceDetails from "./BalanceDetails"
import { ProgramPermissions } from 'store/user/userReducer'
import { EventTeamInfo } from "../../../store/program/programReducer"

interface BalanceProps {
  balanceTotals: BalanceTotals
  refreshData: Function
  sortedPayData: PaymentDetails
  setSortedPayData: React.Dispatch<React.SetStateAction<PaymentDetails>>
  paid: themis_common.PaidScopeMap[keyof themis_common.PaidScopeMap]
  setPaid: Function
  selectedToPay: string
  setSelectedToPay: Function
  currentProgramId: number
  superUser: boolean
  permissionCodes: themis_common.PermissionCodes.AsObject
  programPermissions: ProgramPermissions
  setSelectedRegistrationsForPayment: React.Dispatch<React.SetStateAction<number[]>>
  setSelectedEventTeams: React.Dispatch<React.SetStateAction<EventTeamInfo[]>>
  payRegistration: (amountSelectedToPay: number, currency: string) => Promise<void>
  deleteEventTeamAthlete: () => void
  setAthleteToDeleteEventTeamObj: React.Dispatch<React.SetStateAction<themis_common.EventTeam.AsObject>>
  setAthleteToDeleteObj: React.Dispatch<React.SetStateAction<themis_common.Athlete.AsObject>>
  setEntryToDeleteIndex: React.Dispatch<React.SetStateAction<number>>
  setIsRemoveEventTeamAthleteOpen: React.Dispatch<React.SetStateAction<boolean>>
  isRemoveEventTeamAthleteOpen: boolean
  entryToDeleteIndex: number
}

const paidOptions = [{ value: 0, name: "Paid" }, { value: 1, name: "Unpaid" }, { value: 3, name: "Pending" }, { value: 2, name: "All" }]
const Balance: React.FC<BalanceProps> = ({ balanceTotals, refreshData, sortedPayData, setSortedPayData, paid, setPaid, selectedToPay, setSelectedToPay, currentProgramId, superUser, programPermissions, permissionCodes, setSelectedRegistrationsForPayment, setSelectedEventTeams, payRegistration,
  deleteEventTeamAthlete, setAthleteToDeleteEventTeamObj, setAthleteToDeleteObj, setEntryToDeleteIndex, setIsRemoveEventTeamAthleteOpen, isRemoveEventTeamAthleteOpen, entryToDeleteIndex }): ReactElement => {
  const classes = useStyles()

  const paidSelected = useMemo(() => {
    return paidOptions.find((option) => {
      return option.value === paid
    })
  }, [paid])

  const balances = useMemo(() => {
    return balanceTotals.balances.map((balance, i) => {
      const amount = addCommas((balance.balance / 100).toFixed(2))
      return (<Grid key={`balances-${i}`} item xs={4}>
        <Typography variant="h3">{amount} {balance.currency}</Typography>
      </Grid>)
    })
  }, [balanceTotals.balances])

  return (
    <Grid container spacing={1}>
      <Grid container>
        <Grid item xs={4}>&nbsp;</Grid>
        <Grid item container xs={4} alignContent="center" alignItems="center" >
          {paidSelected?.value === 0 ? <></> :
            <Grid item container xs={12}>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h3">Total Outstanding: </Typography>
                </Grid>
                {balanceTotals.balances.length === 1 ? balances : <></>}
              </Grid>
              {balanceTotals.balances.length > 1 ? balances : <></>}
            </Grid>
          }
        </Grid>
        <Grid item xs={2}>
          {/*<Autocomplete*/}
          {/*  value={paidSelected}*/}
          {/*  renderInput={*/}
          {/*    (params) => <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} label="Paid Status" variant="outlined"/>*/}
          {/*  }*/}
          {/*  options={paidOptions}*/}
          {/*  getOptionLabel={(option) => {*/}
          {/*    return option.name*/}
          {/*  }}*/}
          {/*  onChange={(e, v) => {*/}
          {/*    v && setPaid(v.value)*/}
          {/*  }}*/}
          {/*/>*/}
        </Grid>
        <Grid item container xs={2} alignContent="center" alignItems="center" justify="center">
          <Button className={ `${classes.clickable}` } aria-label="refresh data" startIcon={<RefreshIcon/>} variant="outlined" onClick={() => { refreshData() }}>
            Refresh Data
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <BalanceEvents balanceTotals={balanceTotals} selectedToPay={selectedToPay} setSelectedToPay={setSelectedToPay} />
        <BalanceDetails
          balanceTotals={balanceTotals}
          sortedPayData={sortedPayData}
          setSortedPayData={setSortedPayData}
          selectedToPay={selectedToPay}
          paid={paid}
          currentProgramId={currentProgramId}
          superUser={superUser}
          programPermissions={programPermissions}
          permissionCodes={permissionCodes}
          setSelectedRegistrationsForPayment={setSelectedRegistrationsForPayment}
          setSelectedEventTeams={setSelectedEventTeams}
          payRegistration={payRegistration}
          deleteEventTeamAthlete={deleteEventTeamAthlete}
          setAthleteToDeleteEventTeamObj={setAthleteToDeleteEventTeamObj}
          setAthleteToDeleteObj={setAthleteToDeleteObj}
          setEntryToDeleteIndex={setEntryToDeleteIndex}
          setIsRemoveEventTeamAthleteOpen={setIsRemoveEventTeamAthleteOpen}
          isRemoveEventTeamAthleteOpen={isRemoveEventTeamAthleteOpen}
          entryToDeleteIndex={entryToDeleteIndex}
        />
      </Grid>
    </Grid>
  )
}

export default Balance
