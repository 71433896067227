import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import { Container, Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentLocation, selectCurrentProgram } from "store/program/programSelectors"
import { emptyPerson } from "store/program/programReducer"
import AddEditPerson from "components/program/person/AddEditPerson"
import themis_common from "store/themis_common_pb"
import { addPersonnelToTeam } from 'store/program/teamActions'
import { addPersonnel, getPersonnel, updatePersonnel } from "store/program/personnelActions"
import { personnelPersonFields } from "lib/constants"
import YesNoDialog from 'components/util/YesNoModal'
import { capitalizeFirstLetter, fieldsOkay } from 'lib/validators'
import SubmitButton from 'components/util/SubmitButton'
import { refreshUser } from "store/user/userActions"

const AddEditPersonnel: React.FC = (): ReactElement => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { personnelId: inputPersonnelId, teamId: inputTeamId, locationId: inLocationId } = useParams<{personnelId?: string | undefined, teamId?: string | undefined, locationId?: string | undefined}>()
  const personnelId = Number(inputPersonnelId)
  const teamId = Number(inputTeamId)
  const locationId = Number(inLocationId)

  const program: themis_common.Program.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProgram(state))
  const location: themis_common.Location.AsObject | undefined = useSelector((state: IAppState) => selectCurrentLocation(state))

  const programId = useMemo(() => program?.id,[program])

  useEffect(() => {
    load()
    async function load() {
      if (!programId || !locationId || !personnelId) return
      setPersonnel(await getPersonnel(personnelId, programId, locationId, teamId))
    }
  }, [programId, locationId, personnelId, dispatch])

  const [person, setPerson] = useState(emptyPerson)
  const [personnel, setPersonnel] = useState<themis_common.Personnel.AsObject | undefined>(undefined)

  const [checkPersonnel, setCheckPersonnel] = useState(false)

  const locationCountry: string | undefined = useMemo(() => personnelId ? undefined : location?.country, [location?.country, personnelId])
  const locationState: string | undefined = useMemo(() => personnelId ? undefined : location?.state, [location?.state, personnelId])

  useEffect(() => {
    if (!personnelId || !personnel?.person?.id) return
    setPerson(personnel.person)
  }, [personnelId, personnel, setPerson])

  const create = !personnelId ? 'Create' : 'Edit'
  const creating = !personnelId ? 'creating' : 'editing'
  const creatingBool = !personnelId

  const formReady = useMemo(() => fieldsOkay(person, personnelPersonFields), [person])

  const submitForm = async (event: { preventDefault: () => void }) => {
    setCheckPersonnel(true)
    event.preventDefault()
    if (!programId || !locationId) return

    const dest = teamId ? `/Location/${locationId}/Team/${teamId}` : `/Location/${locationId}`
    if (!formReady) return
    if (personnelId) {
      // Editing Personnel
      try {
        await updatePersonnel(dispatch, personnelId, person, programId, locationId, teamId)
        history.replace(dest + `/Personnel/${personnelId}`)
      } catch (e) {
        setIsErrorOpen(true)
        console.error(e)
      }
    } else {
      // Creating Personnel
      try {
        const personnel = await addPersonnel(dispatch, person, programId, locationId)
        await refreshUser(dispatch)
        if (teamId > 0) {
          await addPersonnelToTeam(dispatch, programId, locationId, teamId, personnel.id)
          history.replace(dest + `#personnel`)
        } else {
          history.replace(dest + `/Personnel/${personnel.id}`)
        }
      } catch (e) {
        setIsErrorOpen(true)
        console.error(e)
      }
    }
  }

  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const headerContents = () => {
    if (personnel?.name) {
      return `${create} Personnel - ${personnel.name}`
    } else {
      return `${create} Personnel`
    }
  }

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{headerContents()}</Typography>
        </Grid>

        <Grid item xs={1}/>
        <Grid item xs={10}>
          <div className={classes.paper}>
            <AddEditPerson person={person}
              formId={0}
              updatePerson={setPerson}
              formReady={formReady}
              formFields={personnelPersonFields}
              hiddenFields={{}}
              locationCountry={locationCountry}
              locationState={locationState}
              readonly={false}
              checkPerson={checkPersonnel}
              setCheckPerson={setCheckPersonnel}
              creating={creatingBool}
              editBirthDate={false}
            />
            <Grid container item xs={12}>
              <SubmitButton title={`${person.id ? 'Edit' : 'Create'} Personnel`} ariaLabel="submit-personnel-form" submitForm={submitForm} />
            </Grid>
          </div>
        </Grid>
        <YesNoDialog
          title={`Error ${capitalizeFirstLetter(creating)} Personnel`}
          question={`There was an error ${creating} your personnel. Please contact us if this continues to be a problem.`}
          isOpen={isErrorOpen}
          disabled={!formReady}
          onClose={() => setIsErrorOpen(false)}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
          ]}
        />
      </Grid>
    </Container>
  )
}

export default AddEditPersonnel
