import React, { ChangeEvent, ReactElement, useState, useMemo, useEffect } from 'react'
import { countries } from "lib/constants"
import { useStyles } from "lib/theme"
import { Link as RouterLink } from "react-router-dom"
import { AppBar, Container, Grid, IconButton, MenuItem, Tab, Tabs, Typography, useTheme } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon, } from '@material-ui/icons'
import { PermissionLists } from "store/user/userActions"
import { TabPanel } from "lib/pieces"
import themis_common from "store/themis_common_pb"

import LocationTeams from "components/program/team/LocationTeams"
import CoachGrid from "components/program/coach/CoachGrid"
import PersonnelGrid from 'components/program/personnel/PersonnelGrid'

import YesNoDialog from "components/util/YesNoModal"

import AthleteGrid from "components/program/athlete/AthleteGrid"
import UserGrid from "components/user/user_grid/UserGrid"
import { ProgramPermissions } from 'store/user/userReducer'
import { validateLocationPermission } from 'lib/permissions'
import BalanceContainer from "../balance/BalanceContainer"
import ViewProducersButton from 'components/util/ViewProducersButton'
import EventGridContainer from '../event_grid/EventGridContainer'

const countryItems: any[] = []
for (const country of countries) {
  countryItems.push(<MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>)
}

interface LocationProps {
  superUser: boolean
  permissionCodes: themis_common.PermissionCodes.AsObject
  programPermissions: ProgramPermissions
  permissionLists: PermissionLists
  program: themis_common.Program.AsObject
  locationId: number
  currentLocation: themis_common.Location.AsObject
  currentTab: number
  handleChangeTab: (event: ChangeEvent<{}>, value: number)=>void
  handleDeleteLocation: Function
  isLocationOpen: boolean
  handleDeleteAthleteFromLocation: (athleteId: number) => void
  handleDeleteCoachFromLocation: (coachId: number) => void
  handleDeletePersonnelFromLocation: (personnelId: number) => void
  setIsLocationOpen?: (value: boolean | ((prevVar: boolean) => boolean)) => void
}

const Location: React.FC<LocationProps> = ({
  superUser, programPermissions, permissionCodes,
  permissionLists, program, locationId, currentLocation,
  currentTab, handleChangeTab, handleDeleteLocation,
  isLocationOpen, setIsLocationOpen, handleDeleteAthleteFromLocation,
  handleDeleteCoachFromLocation, handleDeletePersonnelFromLocation
}): ReactElement => {
  const classes = useStyles()
  const theme = useTheme()

  const [teamTabEnabled, setTeamTabsEnabled] = useState(false)

  // If location has "TV", enable teams tab
  useEffect(() => {
    if (validateLocationPermission(permissionCodes.teamView, permissionCodes, superUser, programPermissions, program.id, locationId)) {
      if (!teamTabEnabled) setTeamTabsEnabled(true)
    }
  }, [superUser, locationId, program.id, programPermissions, teamTabEnabled, permissionCodes])

  const headerContents = () => {
    if (currentLocation && currentLocation.id && currentLocation.name) {
      return `Manage Location - ${currentLocation.name}`
    } else {
      return "Select Location to Continue"
    }
  }

  const editLocationIcon = useMemo(() => {
    if (validateLocationPermission(permissionCodes.locationEdit, permissionCodes, superUser, programPermissions, program.id, locationId)) {
      return (
        <IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={`/EditLocation/${currentLocation?.id}`}>
          <EditIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [classes, currentLocation?.id, locationId, programPermissions, program, superUser, permissionCodes])

  const deleteLocationIcon = useMemo(() => {
    if (validateLocationPermission(permissionCodes.locationDelete, permissionCodes, superUser, programPermissions, program.id, locationId)) {
      return (
        <IconButton className={classes.clickable} aria-label="delete" onClick={() => { if (setIsLocationOpen) setIsLocationOpen(true) }}>
          <DeleteIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [classes, locationId, programPermissions, program, superUser, setIsLocationOpen, permissionCodes])

  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const userCanViewLocation = useMemo((): boolean => {
    return validateLocationPermission(permissionCodes.locationView, permissionCodes, superUser, programPermissions, program.id, locationId)
  }, [superUser, programPermissions, locationId, program.id, permissionCodes])

  if (!program || !currentLocation) return (<></>)
  return userCanViewLocation ? (
    <Container style={{ maxWidth: "none" }}>
      <YesNoDialog
        title={`Delete ${currentLocation.name}?`}
        question={`Deleting a location cannot be undone. Are you sure you want to delete ${currentLocation.name}?`}
        isOpen={isLocationOpen}
        onClose={() => {if (setIsLocationOpen) setIsLocationOpen(false)}}
        buttonActions={[
          { name: "No", color: "primary", callback: () => {if (setIsLocationOpen) setIsLocationOpen(false)} },
          { name: "Yes", color: "primary", callback: handleDeleteLocation },
        ]}
      />
      <YesNoDialog
        title={"Error"}
        question={"There was an error. Please contact us if this continues to be a problem."}
        isOpen={isErrorOpen}
        onClose={() => setIsErrorOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
        ]}
      />

      <Grid container>
        <Grid container item xs={12} alignItems="flex-start">
          <Typography variant="h1" className={classes.headerTypo}>{headerContents()}</Typography>
          <Grid item xs={2}>
            {editLocationIcon}{deleteLocationIcon}
          </Grid>
          <Grid item xs={3}>
            {currentTab === 4 ? <ViewProducersButton></ViewProducersButton> : <></>}
          </Grid>
          <AppBar position="static" style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }}>
            <Tabs value={currentTab} onChange={handleChangeTab} variant="scrollable">
              <Tab label="Teams" id="tab-0" disabled={!teamTabEnabled} />
              <Tab label="Athletes" id="tab-1" disabled={!validateLocationPermission(permissionCodes.athleteView, permissionCodes, superUser, programPermissions, program.id, locationId)} />
              <Tab label="Coaches" id="tab-2" disabled={!validateLocationPermission(permissionCodes.coachView, permissionCodes, superUser, programPermissions, program.id, locationId)} />
              <Tab label="Personnel" id="tab-3" disabled={!validateLocationPermission(permissionCodes.personnelView, permissionCodes, superUser, programPermissions, program.id, locationId)} />
              <Tab label="Events" id="tab-4" disabled={!teamTabEnabled} />
              <Tab label="Users" id="tab-5" disabled={!validateLocationPermission(permissionCodes.locationUserView, permissionCodes, superUser, programPermissions, program.id, locationId)} />
              <Tab label="Event Balance" id="tab-6" />
            </Tabs>
          </AppBar>
          <TabPanel value={currentTab} index={0}>
            <LocationTeams programId={program.id} locationId={locationId} setTeamTabEnabled={setTeamTabsEnabled}/>
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            {currentLocation?.athletesList !== undefined &&
            <AthleteGrid
              athletes={currentLocation?.athletesList}
              programId={program.id}
              locationId={currentLocation.id}
              superUser={superUser}
              programPermissions={programPermissions}
              currentLocation={currentLocation}
              handleDeleteAthlete={handleDeleteAthleteFromLocation}
              permissionCodes={permissionCodes}
            />
            }
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            {currentLocation?.coachesList !== undefined &&
              <CoachGrid
                coaches={currentLocation?.coachesList}
                programId={program.id}
                locationId={currentLocation.id}
                superUser={superUser}
                programPermissions={programPermissions}
                currentLocation={currentLocation}
                handleDeleteCoach={handleDeleteCoachFromLocation}
                permissionCodes={permissionCodes}
              />
            }
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            {currentLocation?.personnelList !== undefined &&
              <PersonnelGrid
                personnel={currentLocation?.personnelList}
                programId={program.id}
                locationId={currentLocation.id}
                superUser={superUser}
                programPermissions={programPermissions}
                currentLocation={currentLocation}
                handleDeletePersonnel={handleDeletePersonnelFromLocation}
                permissionCodes={permissionCodes}
              />
            }
          </TabPanel>
          <TabPanel value={currentTab} index={4}>
            <EventGridContainer
              eventGridType="location"
              programPermissions={programPermissions}
              programId={program.id}
              locationId={locationId}
              program={program}
              location={currentLocation}
              superUser={superUser}
              permissionCodes={permissionCodes} />
          </TabPanel>
          <TabPanel value={currentTab} index={5}>
            {!permissionLists ? [] :
              <UserGrid type="location" superObjectId={program.id} objectId={locationId} permissionLists={permissionLists}/>
            }
          </TabPanel>
          <TabPanel value={currentTab} index={6}>
            <BalanceContainer />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  ) : <></>
}

export default Location
