import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import {
  Card, CardContent, Checkbox, Container, Grid, IconButton,
  List, ListItem, ListItemIcon, ListItemText, Typography
} from '@material-ui/core'
import { Link as RouterLink, useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentLocation, selectCurrentProgram } from "store/program/programSelectors"
import themis_common from "store/themis_common_pb"
import { getPersonnel, getPersonnelTeams } from "store/program/personnelActions"
import { genderOptions } from "lib/constants"
import { parseISO, format } from "date-fns"
import { addPersonnelToTeam, removePersonnelFromTeam } from "store/program/teamActions"
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import YesNoDialog from "components/util/YesNoModal"
import { removePersonnelFromLocation } from "store/program/locationActions"
import { validateLocationPermission, validateTeamPermission } from 'lib/permissions'
import { selectPermissionCodes, selectSuperUser, selectUserProgramPermissions } from 'store/user/userSelectors'

const Personnel: React.FC = (): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { personnelId: inputPersonnelId, teamId: inputTeamId, locationId: inLocationId } = useParams<{ personnelId?: string | undefined, teamId?: string | undefined, locationId?: string | undefined }>()
  const personnelId = Number(inputPersonnelId)
  const teamId = Number(inputTeamId)
  const locationId = Number(inLocationId)

  const program: themis_common.Program.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProgram(state))
  const location: themis_common.Location.AsObject | undefined = useSelector((state: IAppState) => selectCurrentLocation(state))
  const programId = useMemo(() => program?.id || 0, [program])

  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const [personnel, setPersonnel] = useState<themis_common.Personnel.AsObject | undefined>(undefined)
  const [teams, setTeams] = useState<number[]>([])
  const [reload, updateReload] = useState<number>(0)
  const [deletePersonnelIsOpen, setDeletePersonnelIsOpen] = useState<number>(0)

  async function updateTeams() {
    const teams = await getPersonnelTeams(personnelId, programId, locationId, teamId)
    setTeams(teams.map((team: any) => team.id))
  }

  const doReload = () => {
    updateReload(reload + 1)
  }

  useEffect(() => {
    load()
    async function load() {
      if (!programId || !locationId || !personnelId) return
      setPersonnel(await getPersonnel(personnelId, programId, locationId, teamId))
      await updateTeams()
    }
  }, [programId, locationId, personnelId, reload, dispatch])

  async function handleToggle(teamId: number, onTeamNow: boolean) {
    if (onTeamNow) {
      await removePersonnelFromTeam(dispatch, programId, locationId, teamId, personnelId)
    } else {
      await addPersonnelToTeam(dispatch, programId, locationId, teamId, personnelId)
    }
    await updateTeams()
  }

  const teamList = useMemo(() => {
    if (!location?.teamsList || !teams) return []
    const unsortedTeamsList = [...location?.teamsList]
    const sortedTeamsList = unsortedTeamsList.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      return 0
    })
    return sortedTeamsList.map((team) => {
      const labelId = `checkbox-list-label-${team.id}`

      return (
        <ListItem key={team.id} role={undefined} dense button onClick={(e) => { handleToggle(team.id, teams.includes(team.id)) }}
          disabled={
            !((!teamId && validateLocationPermission(permissionCodes.personnelEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
            (teamId && validateTeamPermission(permissionCodes.personnelEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId)))
          }>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={teams.includes(team.id)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={team.name} />
        </ListItem>
      )
    })
  }, [location?.teamsList, teams])

  const handleRemovePersonnel = async (): Promise<void> => {
    if (!deletePersonnelIsOpen) return
    await removePersonnelFromLocation(dispatch, locationId, programId, personnelId)
    history.replace(`/Location/${locationId}`)
  }

  const editPersonnelLink = teamId ? `/Location/${locationId}/Team/${teamId}/EditPersonnel/${personnelId}` : `/Location/${locationId}/EditPersonnel/${personnelId}`

  const editPersonnelIcon = useMemo(() => {
    if (
      (!teamId && validateLocationPermission(permissionCodes.personnelEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
      (teamId && validateTeamPermission(permissionCodes.personnelEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
    ) {
      return (
        <IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={editPersonnelLink}>
          <EditIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes.clickable, editPersonnelLink, teamId, locationId, programId, programPermissions, superUser, permissionCodes])

  const deletePersonnelIcon = useMemo(() => {
    if (
      (!teamId && validateLocationPermission(permissionCodes.personnelDelete, permissionCodes, superUser, programPermissions, programId, locationId)) ||
      (teamId && validateTeamPermission(permissionCodes.personnelDelete, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
    ) {
      return (
        <IconButton className={classes.clickable} aria-label="delete" onClick={() => setDeletePersonnelIsOpen(personnelId)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes.clickable, personnelId, teamId, locationId, programId, programPermissions, superUser, permissionCodes])

  const headerContents = () => {
    if (personnel?.name) {
      return `Manage Personnel - ${personnel.name}`
    } else {
      return `Select Personnel to Continue`
    }
  }

  return ( personnel?.person?.id && permissionCodes?.denyAccess.length ?
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>
            {headerContents()}
            {editPersonnelIcon}
            {deletePersonnelIcon}
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item container justify="flex-start" xs={5}>
          <Card key={`names-${personnel.id}`} className={classes.cardSpacing}>
            <Grid container>
              <Grid item md={7}>
                <CardContent>
                  <Typography>First Name: {personnel.person.legalFirstName}</Typography>
                  <Typography>Middle Name: {personnel.person.legalMiddleName}</Typography>
                  <Typography>Last Name: {personnel.person.legalLastName}</Typography>
                  <Typography>Suffix: {personnel.person.suffix}</Typography>
                  <Typography>Nick Name: {personnel.person.nickName}</Typography>
                  <Typography>Title: {personnel.person.title}</Typography>
                </CardContent>
              </Grid>
              <Grid item md={5}>
                {personnel.person.usasfProfilePicture ?
                  <img src={personnel.person.usasfProfilePicture} style={{ width: "120px", border: "1px solid red", marginTop: "20px" }} alt={`${personnel.person.name} USASF Profile`} />
                  : <></>
                }
              </Grid>
            </Grid>
          </Card>
          <Card key={`address-${personnel.id}`} className={classes.cardSpacing}>
            <CardContent>
              <Typography>Address: {personnel.person.address1}</Typography>
              <Typography>Address 2: {personnel.person.address2}</Typography>
              <Typography>City: {personnel.person.city}</Typography>
              <Typography>State: {personnel.person.state}</Typography>
              <Typography>Postal Code: {personnel.person.postalCode}</Typography>
              <Typography>Country: {personnel.person.country}</Typography>
            </CardContent>
          </Card>
          <Card key={`contact-${personnel.id}`} className={classes.cardSpacing}>
            <CardContent>
              <Typography>Email: <a className={classes.cleanLink} target="_blank" rel="noreferrer" href={`mailto:${personnel.person.email}`}>{personnel.person.email}</a> </Typography>
              <Typography>Phone: <a className={classes.cleanLink} target="_blank" rel="noreferrer" href={`tel:${personnel.person.cellPhone}`}>{personnel.person.cellPhone}</a></Typography>
              <Typography>Home Phone: {personnel.person.homePhone}</Typography>
              <Typography>Gender: {(genderOptions.find((gender) => gender.value === personnel?.person?.gender))?.name}</Typography>
              <Typography>Birth Date: {personnel.person?.birthDate ? format(parseISO(personnel.person.birthDate), "MM/dd/yyyy") : ""}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5} container justify="flex-start">
          <Grid item xs={12} container justify="flex-start">
            <div className={classes.paper}>
              <Typography variant="h2">Teams</Typography>
              <List>
                {teamList}
              </List>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <YesNoDialog
        title={`Remove ${personnel.name} entirely from ${location?.name}?`}
        question={"This cannot be undone. Are you sure?"}
        isOpen={!!deletePersonnelIsOpen}
        onClose={() => { setDeletePersonnelIsOpen(0) }}
        buttonActions={[
          { name: "No", color: "primary", callback: () => { setDeletePersonnelIsOpen(0) } },
          { name: "Yes", color: "primary", callback: handleRemovePersonnel },
        ]}
      />
    </Container> : <></> )

}

export default Personnel
