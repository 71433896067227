import { client } from "store/themisClient"
import { Dispatch } from "react"
import { buildPersonnel } from "./programBuilders"
import { Action, createAction } from "redux-actions"
import { SET_PERSONNEL } from "lib/constants"
import themis_api from "store/themis_api_pb"
import themis_common from "store/themis_common_pb"

const setPersonnels = createAction<themis_common.Personnel.AsObject[]>(SET_PERSONNEL)

async function getPersonnel(personnelId: number, programId: number, locationId: number, validateTeamId: number): Promise<themis_common.Personnel.AsObject | undefined> {
  const personnelRequest = new themis_common.Personnel()

  personnelRequest.setProgramId(programId)
  personnelRequest.setLocationId(locationId)
  personnelRequest.setId(personnelId)
  if (validateTeamId && validateTeamId > 0) personnelRequest.setValidateTeamId(validateTeamId)

  const personnelResponse: themis_api.PersonnelResponse.AsObject = (await client.getPersonnel(personnelRequest, {})).toObject()
  return personnelResponse.personnel
}

async function getPersonnelTeams(personnelId: number, programId: number, locationId: number, validateTeamId: number): Promise<themis_common.Team.AsObject[]> {
  const personnelRequest = new themis_common.Personnel()

  personnelRequest.setProgramId(programId)
  personnelRequest.setLocationId(locationId)
  personnelRequest.setId(personnelId)
  if (validateTeamId && validateTeamId > 0) personnelRequest.setValidateTeamId(validateTeamId)

  const teamResponse: themis_api.TeamResponse.AsObject = (await client.getPersonnelTeams(personnelRequest, {})).toObject()
  return teamResponse.teamsList
}

async function addPersonnel(dispatch: Dispatch<Action<themis_common.Personnel.AsObject[]>>, personnelPerson: themis_common.Person.AsObject, programId: number, locationId: number,): Promise<themis_common.Personnel.AsObject> {

  const personnelRequest = buildPersonnel(undefined, programId, locationId, personnelPerson)

  const personnelResponse: themis_api.PersonnelResponse.AsObject = (await client.addPersonnel(personnelRequest, {})).toObject()
  const personnels = personnelResponse.personnelsList
  dispatch(setPersonnels(personnels))
  if (personnelResponse.personnel) return personnelResponse.personnel
  throw new Error("Error creating personnel")
}

async function updatePersonnel(dispatch: Dispatch<Action<themis_common.Personnel.AsObject[]>>, personnelId: number, personnelPerson: themis_common.Person.AsObject, programId: number, locationId: number, validateTeamId: number): Promise<void> {

  const personnelRequest = buildPersonnel(personnelId, programId, locationId, personnelPerson, validateTeamId)
  const res = await client.updatePersonnel(personnelRequest, {})
  const personnelResponse = res.toObject()
  const personneles = personnelResponse.personnelsList

  dispatch(setPersonnels(personneles))
}

export {
  getPersonnel,
  getPersonnelTeams,
  addPersonnel,
  updatePersonnel,
}

