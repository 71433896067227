import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import { Card, CardContent, Container, Grid, IconButton, List, Typography } from '@material-ui/core'
import { Link as RouterLink, useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { IAppState } from "store/store"
import themis_common from "store/themis_common_pb"
import { genderOptions } from "lib/constants"
import { parseISO, format } from "date-fns"
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import YesNoDialog from "components/util/YesNoModal"
import { selectPermissionCodes, selectSuperUser, selectUserProducerPermissions } from 'store/user/userSelectors'
import { selectCurrentProducer } from 'store/producer/producerSelectors'
import { getJudge } from 'store/producer/judgeActions'
import { validateBrandPermission, validateProducerPermission } from 'lib/permissions'
import { removeJudge } from 'store/producer/producerActions'


const Judge: React.FC = (): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { judgeId: inputJudgeId, brandId: inputBrandId } = useParams<{judgeId?: string | undefined, brandId?: string | undefined}>()
  const judgeId = Number(inputJudgeId)
  const brandId = Number(inputBrandId)

  const producer: themis_common.Producer.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProducer(state))
  const producerId = useMemo(() => producer?.id || 0, [producer])

  const [judge, setJudge] = useState<themis_common.Judge.AsObject | undefined>(undefined)
  const [deleteJudgeIsOpen, setDeleteJudgeIsOpen] = useState<number>(0)

  const producerPermissions = useSelector((state: IAppState) => selectUserProducerPermissions(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  useEffect(() => {
    load()
    async function load() {
      if (!producerId || !judgeId) return
      setJudge(await getJudge(judgeId, producerId, brandId))
    }
  }, [producerId, brandId, judgeId])

  const handleRemoveJudge = async (): Promise<void> => {
    if (!deleteJudgeIsOpen) return
    if (producer) {
      await removeJudge(dispatch, producerId, judgeId)
      history.replace(`/producer/${producer.id}`)
    }
  }

  const editJudgeLink = brandId ? `/Brand/${brandId}/EditJudge/${judgeId}` : `/Producer/${producerId}/EditJudge/${judgeId}`


  const editJudgeIcon = useMemo(() => {
    if (
      (validateBrandPermission(permissionCodes.judgeEdit, permissionCodes, superUser, producerPermissions, producerId, brandId)) ||
      (validateProducerPermission(permissionCodes.judgeEdit, permissionCodes, superUser, producerPermissions, producerId))
    ) {
      return (
        <IconButton
          className={classes.clickable}
          aria-label="edit"
          component={RouterLink}
          to={editJudgeLink}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes.clickable, brandId, permissionCodes, producerId, producerPermissions, superUser, editJudgeLink])

  const deleteJudgeIcon = useMemo(() => {
    if (
      validateProducerPermission(permissionCodes.judgeDelete, permissionCodes, superUser, producerPermissions, producerId)
    ) {
      return (
        <IconButton
          className={classes.clickable}
          aria-label="delete"
          onClick={() => setDeleteJudgeIsOpen(judgeId)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes.clickable, judgeId, permissionCodes, producerId, producerPermissions, superUser])

  if (!judge?.person?.id) return <></>

  const headerContents = () => {
    if (judge?.name) {
      return `Manage Judge - ${judge.name}`
    } else {
      return `Select judge to Continue`
    }
  }

  return ( permissionCodes?.denyAccess.length ?
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>
            {headerContents()}
            {editJudgeIcon}
            {deleteJudgeIcon}
          </Typography>

        </Grid>
        <Grid item xs={1} />
        <Grid item container justify="flex-start" xs={5}>
          <Card key={`names-${judge.id}`} className={classes.cardSpacing}>
            <Grid container>
              <Grid item md={7}>
                <CardContent>
                  <Typography>First Name: {judge.person.legalFirstName}</Typography>
                  <Typography>Middle Name: {judge.person.legalMiddleName}</Typography>
                  <Typography>Last Name: {judge.person.legalLastName}</Typography>
                  <Typography>Suffix: {judge.person.suffix}</Typography>
                  <Typography>Nick Name: {judge.person.nickName}</Typography>
                  <Typography>Title: {judge.person.title}</Typography>
                </CardContent>
              </Grid>
              <Grid item md={5}>
                {judge.person.usasfProfilePicture ?
                  <img src={judge.person.usasfProfilePicture} style={{ width: "120px", border: "1px solid red", marginTop: "20px" }} alt={`${judge.person.name} USASF Profile`} />
                  : <></>
                }
              </Grid>
            </Grid>
          </Card>
          <Card key={`address-${judge.id}`} className={classes.cardSpacing}>
            <CardContent>
              <Typography>Address: {judge.person.address1}</Typography>
              <Typography>Address 2: {judge.person.address2}</Typography>
              <Typography>City: {judge.person.city}</Typography>
              <Typography>State: {judge.person.state}</Typography>
              <Typography>Postal Code: {judge.person.postalCode}</Typography>
              <Typography>Country: {judge.person.country}</Typography>
            </CardContent>
          </Card>
          <Card key={`contact-${judge.id}`} className={classes.cardSpacing}>
            <CardContent>
              <Typography>Email: <a className={classes.cleanLink} target="_blank" rel="noreferrer" href={`mailto:${judge.person.email}`}>{judge.person.email}</a> </Typography>
              <Typography>Phone: <a className={classes.cleanLink} target="_blank" rel="noreferrer" href={`tel:${judge.person.cellPhone}`}>{judge.person.cellPhone}</a></Typography>
              <Typography>Home Phone: {judge.person.homePhone}</Typography>
              <Typography>Gender: {(genderOptions.find((gender) => gender.value === judge?.person?.gender))?.name}</Typography>
              <Typography>Birth Date: {(judge.person?.birthDate && judge.person?.birthDate !== "1601-01-01") ? format(parseISO(judge.person.birthDate), "MM/dd/yyyy") : ""}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5} container justify="flex-start">
          <Grid item xs={12} container justify="flex-start">
            <div className={classes.paper}>
              <Typography variant="h2">Events</Typography>
              <List>
                {/* Events that a judge has been a part of or are scheduled for will go here */}
              </List>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <YesNoDialog
        title={`Remove ${judge.name} entirely from ${producer?.name}?`}
        question={"This cannot be undone. Are you sure?"}
        isOpen={!!deleteJudgeIsOpen}
        onClose={() => { setDeleteJudgeIsOpen(0) }}
        buttonActions={[
          { name: "No", color: "primary", callback: () => { setDeleteJudgeIsOpen(0) } },
          { name: "Yes", color: "primary", callback: handleRemoveJudge },
        ]}
      />
    </Container> : <></>
  )
}


export default Judge
