import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import themis_api from 'store/themis_api_pb'
import themis_common from "store/themis_common_pb"
import Reports from "./Reports"
import { add, format, sub } from "date-fns"
import {
  getEventsReport,
  getDetailEventReport,
  getDetailEventReportByPrograms,
  getDetailProducerProgramCreditReport,
  getConnectedProgramsReport
} from "../../../store/producer/reportActions"

export interface ReportData {
  startDate: string
  endDate: string
  data: reportDataType
  eventDetailReportData: eventRegistrationReportType,
  eventDetailByProgramReportData: eventRegistrationReportByProgramsType,
  producerProgramCreditReportData: producerProgramCreditReportType,
  connectedProgramReportData: connectedProgramReportType
}

export type reportDataType = themis_api.BasicReportResponse.AsObject | null
export type eventRegistrationReportType = themis_common.EventRegistrationReport.AsObject | null
export type eventRegistrationReportByProgramsType = themis_common.EventRegistrationByProgramReport.AsObject | null
export type producerProgramCreditReportType = themis_common.ProducerProgramCreditReport.AsObject | null
export type connectedProgramReportType = themis_common.Program.AsObject[]

interface reportsContainerProps { producerId: number, brandId?: number, eventId?: number, reportId: number }

const ReportsContainer: React.FC<reportsContainerProps> = ({ producerId, brandId, reportId, eventId }): ReactElement => {

  const [currentReport, setCurrentReport] = useState(reportId)
  const [startDate, setStartDate] = useState<string>(format(sub(new Date(), { months: 2 }), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState<string>(format(add(new Date(), { months: 2 }), 'yyyy-MM-dd'))
  const [reportData, setReportData] = useState<reportDataType>(null)
  const [eventRegistrationReportData, setEventRegistrationReportData] = useState<eventRegistrationReportType>(null)
  const [eventRegistrationReportDataByProgram, setEventRegistrationReportDataByProgram] = useState<eventRegistrationReportByProgramsType>(null)
  const [producerProgramCreditReportData, setProducerProgramCreditReportData] = useState<producerProgramCreditReportType>(null)
  const [connectedProgramReportData, setConnectedProgramReportData] = useState<connectedProgramReportType>([])

  useEffect(() => {
    switch (currentReport) {
      case 1:
        const getReportData = async () => {
          const reportData = await getEventsReport(producerId, startDate, endDate, brandId)
          setReportData(reportData)
        }
        getReportData()
        break
      case 2:
        const getDetailEventReportData = async () => {
          const reportData = await getDetailEventReport(eventId || 0)
          setEventRegistrationReportData(reportData)
        }
        getDetailEventReportData()
        break
      case 3:
        const getDetailEventReportDataByProgram = async () => {
          const reportData = await getDetailEventReportByPrograms(eventId || 0)
          setEventRegistrationReportDataByProgram(reportData)
        }
        getDetailEventReportDataByProgram()
        break
      case 4:
        const getDetailProducerProgramCreditReportA = async () => {
          const reportData = await getDetailProducerProgramCreditReport(producerId || 0)
          setProducerProgramCreditReportData(reportData)
        }
        getDetailProducerProgramCreditReportA()
        break
      case 5:
        const getConnectedProgramsReportA = async () => {
          if (producerId) {
            const reportData = await getConnectedProgramsReport(producerId)
            setConnectedProgramReportData(reportData.programsList)
          } else {
            setConnectedProgramReportData([])
          }
        }
        getConnectedProgramsReportA()
        break
      default:
        setReportData(null)
    }
  }, [currentReport, startDate, endDate, producerId, brandId, eventId])

  const reportDataObject = useMemo<ReportData>(() => {
    return { startDate: startDate, endDate: endDate, data: reportData, eventDetailReportData: eventRegistrationReportData, eventDetailByProgramReportData: eventRegistrationReportDataByProgram, producerProgramCreditReportData: producerProgramCreditReportData, connectedProgramReportData: connectedProgramReportData }
  }, [reportData, startDate, endDate, eventRegistrationReportData, eventRegistrationReportDataByProgram, producerProgramCreditReportData, connectedProgramReportData])


  return (<Reports producerId={producerId} brandId={brandId} currentReport={currentReport} setCurrentReport={setCurrentReport} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} reportData={reportDataObject} />)
}

export default ReportsContainer
