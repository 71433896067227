import React, { useEffect, useState, useMemo, ReactElement, FormEvent } from 'react'
import { Countries, countries, UsStates, usStates, FormFields } from "lib/constants"
import { buildErrors, handleInputChange } from "lib/validators"
import { useStyles } from "lib/theme"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Container, Grid, TextField, Typography } from '@material-ui/core'
import themis_common from "store/themis_common_pb"
import YesNoDialog from 'components/util/YesNoModal'
import { capitalizeFirstLetter } from "lib/validators"
import FormTextField from 'components/util/FormTextField'
import SubmitButton from 'components/util/SubmitButton'

interface AddEditLocationProps {
  location: themis_common.Location.AsObject | undefined
  updatedLocation: themis_common.Location.AsObject
  program: themis_common.Program.AsObject | undefined
  locationId: number
  setUpdatedLocation: React.Dispatch<React.SetStateAction<themis_common.Location.AsObject>>
  locations: themis_common.Location.AsObject[]
  checkLocation: boolean
  formFields: FormFields
  setCheckLocation: React.Dispatch<React.SetStateAction<boolean>>
  isErrorOpen: boolean
  setIsErrorOpen: React.Dispatch<React.SetStateAction<boolean>>
  submitForm: (event: FormEvent) => Promise<void>
}

const AddEditLocation: React.FC<AddEditLocationProps> = ({
  location, updatedLocation, program, locationId, setUpdatedLocation, locations, checkLocation,
  formFields, setCheckLocation, isErrorOpen, setIsErrorOpen, submitForm
}): ReactElement => {
  const classes = useStyles()


  const create = !location?.id ? 'Create' : 'Edit'
  const creating = !location?.id ? 'creating' : 'editing'

  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})

  const defaultCountry = useMemo(() => {
    if (!updatedLocation?.country) return countries[0]
    return countries.find((country) => country.code === updatedLocation.country) || countries[0]
  }, [updatedLocation?.country])

  const defaultState = useMemo(() => usStates.find((state) => state.abbreviation === updatedLocation?.state) || usStates[0], [updatedLocation?.state])


  const phoneFields = useMemo(() => {
    return {
      phone: true
    }
  }, [])

  useEffect(() => {
    if (!program?.id) return
    if (locationId && location?.id) {
      // Editing an existing location
      setUpdatedLocation(location)
    } else { // Add new location
      // If no locations on current program, autofill
      if (Object.keys(locations)?.length === 0) {
        setUpdatedLocation({
          id: 0,
          name: "",
          country: program?.country || "",
          state: program?.state || "",
          city: program?.city || "",
          address: program?.address || "",
          address2: program?.address2 || "",
          postalCode: program?.postalCode || "",
          phone: program?.phone || "",
          email: program?.email || "",
          cellPhone: program?.cellPhone || "",
          secondaryPhone: program?.secondaryPhone || "",
          lastScrape: "",
          usasfLocationId: "",
          teamsList: [],
          athletesList: [],
          coachesList: [],
          personnelList: [],
          guardiansList: [],
          programId: program.id,
        })
      } else {
        setUpdatedLocation({
          id: 0,
          name: "",
          country: program?.country || "",
          state: program?.state || "",
          city: "",
          address: "",
          address2: "",
          postalCode: "",
          phone: "",
          email: "",
          cellPhone: "",
          secondaryPhone: "",
          lastScrape: "",
          usasfLocationId: "",
          teamsList: [],
          athletesList: [],
          coachesList: [],
          personnelList: [],
          guardiansList: [],
          programId: program.id,
        })
      }
    }
  }, [program, locations, location, locationId])



  // Check fields when user tries to submit
  useEffect(() => {
    if (checkLocation) {
      setErrorText(buildErrors(updatedLocation, formFields, setUpdatedLocation))
      setCheckLocation(false)
    }
  }, [checkLocation, phoneFields, formFields, updatedLocation])

  const headerContents = () => {
    if (location?.name) {
      return `${create} Location - ${location.name}`
    } else {
      return `${create} Location`
    }
  }

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{headerContents()}</Typography>
        </Grid>
      </Grid>

      {/* Location row */}
      <Grid container>
        <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedLocation}
            setUpdateObject={setUpdatedLocation}
            name="name"
            autoFocus
            sliceSize={100}
          />
        </Grid>
        {!updatedLocation?.country && location?.country ? <></> :
          <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
            <Autocomplete
              key={'1'}
              options={countries}
              fullWidth={true}
              value={defaultCountry}
              getOptionLabel={(country: Countries) => country.name}
              disableClearable
              renderInput={(params) =>
                <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                  <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} autoComplete="none" label={formFields.country.label} required={formFields.country.required} variant="outlined" />
                </form>
              }
              onChange={(e, v) => {
                v && handleInputChange({ target: { name: "country", value: v.pseudoCode || v.code } }, formFields, updatedLocation, setUpdatedLocation, errorText, setErrorText)
              }}
            />
          </Grid>
        }
        {updatedLocation.country === 'US' || !updatedLocation?.country ?
          <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
            <Autocomplete
              key={'2'}
              options={usStates}
              fullWidth={true}
              value={defaultState}
              disableClearable
              getOptionLabel={(state: UsStates) => state.name}
              renderInput={(params) => <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} label={formFields.state.label} required={formFields.state.required} variant="outlined" />}
              onChange={(e, v) => {
                v && handleInputChange({ target: { name: "state", value: v.abbreviation } }, formFields, updatedLocation, setUpdatedLocation, errorText, setErrorText)
              }}
            />
          </Grid>
          :
          <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedLocation}
              setUpdateObject={setUpdatedLocation}
              name="state"
              sliceSize={30}
            />
          </Grid>
        }
        <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedLocation}
            setUpdateObject={setUpdatedLocation}
            name="city"
            sliceSize={40}
          />
        </Grid>
      </Grid>
      {/* Address row */}
      <Grid container>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedLocation}
            setUpdateObject={setUpdatedLocation}
            name="address"
            sliceSize={200}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedLocation}
            setUpdateObject={setUpdatedLocation}
            name="address2"
            sliceSize={200}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedLocation}
            setUpdateObject={setUpdatedLocation}
            name="postalCode"
            sliceSize={20}
          />
        </Grid>
      </Grid>
      {/* new row */}
      <Grid container>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedLocation}
            setUpdateObject={setUpdatedLocation}
            name="phone"
            sliceSize={20}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedLocation}
            setUpdateObject={setUpdatedLocation}
            name="secondaryPhone"
            sliceSize={20}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedLocation}
            setUpdateObject={setUpdatedLocation}
            name="cellPhone"
            sliceSize={20}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
        <FormTextField
          formFields={formFields}
          errorText={errorText}
          setErrorText={setErrorText}
          updatedObject={updatedLocation}
          setUpdateObject={setUpdatedLocation}
          name="email"
          sliceSize={100}
        />
      </Grid>
      <Grid container item xs={12}>
        <SubmitButton title={`${create} Location`} ariaLabel="submit-location-form" submitForm={submitForm} />
      </Grid>
      <YesNoDialog
        title={`Error ${capitalizeFirstLetter(creating)} Location`}
        question={`There was an error ${creating} your location. Please contact us if this continues to be a problem.`}
        isOpen={isErrorOpen}
        onClose={() => setIsErrorOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
        ]}
      />
    </Container>
  )
}

export default AddEditLocation
