import React, { ReactElement, useEffect, useMemo, useState, ChangeEvent } from 'react'
import { useDispatch } from "react-redux"
import { useStyles } from "lib/theme"
import {
  Card, CardContent, Checkbox, Container, Grid, IconButton,
  List, ListItem, ListItemIcon, ListItemText, Typography
} from '@material-ui/core'
import { Link as RouterLink, useHistory } from "react-router-dom"
import themis_common from "store/themis_common_pb"
import { genderOptions } from "lib/constants"
import { parseISO, format } from "date-fns"
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import YesNoDialog from "components/util/YesNoModal"
import { validateLocationPermission, validateTeamPermission } from 'lib/permissions'
import { ProgramPermissions } from 'store/user/userReducer'


interface CoachProps {
  coachId: number
  teamId: number
  locationId: number
  program: themis_common.Program.AsObject | undefined
  location: themis_common.Location.AsObject | undefined
  coach: themis_common.Coach.AsObject | undefined
  setCoach: (value: themis_common.Coach.AsObject | undefined | ((prevVal: themis_common.Coach.AsObject | undefined) => themis_common.Coach.AsObject | undefined)) => void
  teams: number[]
  setTeams: (value: number[] | ((prevVar: number[]) => number[])) => void
  reload: number
  updateReload: (value: number | ((prevVar: number) => number)) => void
  deleteCoachIsOpen: number
  setDeleteCoachIsOpen: (value: number | ((prevVar: number) => number)) => void
  programId: number
  programPermissions: ProgramPermissions
  permissionCodes: themis_common.PermissionCodes.AsObject
  superUser: boolean
  handleToggle: (teamId: number, onTeamNow: boolean) => Promise<void>
  handleRemoveCoach: () => Promise<void>
}

const Coach: React.FC<CoachProps> = ({
  coachId, teamId, locationId, program, location, coach, teams, reload, deleteCoachIsOpen, permissionCodes,
  programId, superUser, programPermissions, updateReload, setDeleteCoachIsOpen, handleToggle, handleRemoveCoach
}):
  ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()



  const doReload = () => {
    updateReload(reload + 1)
  }



  const teamList = useMemo(() => {
    if (!location?.teamsList || !teams) return []
    const unsortedTeamsList = [...location?.teamsList]
    const sortedTeamsList = unsortedTeamsList.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      return 0
    })

    return sortedTeamsList.map((team) => {
      const labelId = `checkbox-list-label-${team.id}`

      return (
        <ListItem key={team.id} role={undefined} dense button onClick={(e) => { handleToggle(team.id, teams.includes(team.id)) }}
          disabled={
            !((!teamId && validateLocationPermission(permissionCodes.coachEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
              (teamId && validateTeamPermission(permissionCodes.coachEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId)))
          }>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={teams.includes(team.id)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={team.name} />
        </ListItem>
      )
    })
  }, [location?.teamsList, teams, locationId, programId, programPermissions, superUser, teamId, permissionCodes])


  const editCoachLink = teamId ? `/Location/${locationId}/Team/${teamId}/EditCoach/${coachId}` : `/Location/${locationId}/EditCoach/${coachId}`

  const editCoachIcon = useMemo(() => {

    if (
      (!teamId && validateLocationPermission(permissionCodes?.coachEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
      (teamId && validateTeamPermission(permissionCodes?.coachEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
    ) {
      return (
        <IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={editCoachLink}>
          <EditIcon fontSize="small" />
        </IconButton>
      )
    }
    return []
  }, [classes.clickable, editCoachLink, programId, locationId, teamId, programPermissions, superUser, permissionCodes])

  const deleteCoachIcon = useMemo(() => {
    if (
      (!teamId && validateLocationPermission(permissionCodes.coachDelete, permissionCodes, superUser, programPermissions, programId, locationId)) ||
      (teamId && validateTeamPermission(permissionCodes.coachDelete, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
    ) {
      return (
        <IconButton className={classes.clickable} aria-label="delete" onClick={() => setDeleteCoachIsOpen(coachId)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )
    }
    return []
  }, [classes.clickable, coachId, locationId, programId, programPermissions, superUser, teamId, permissionCodes])

  if (!coach?.person?.id) return <></>

  const headerContents = () => {
    if (coach?.name) {
      return `Manage Coach - ${coach.name}`
    } else {
      return `Select Coach to Continue`
    }
  }

  return (permissionCodes?.denyAccess.length ?
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>
            {headerContents()}
            {editCoachIcon}
            {deleteCoachIcon}
          </Typography>

        </Grid>
        <Grid item xs={1} />
        <Grid item container justify="flex-start" xs={5}>
          <Card key={`names-${coach.id}`} className={classes.cardSpacing}>
            <Grid container>
              <Grid item md={7}>
                <CardContent>
                  <Typography>First Name: {coach.person.legalFirstName}</Typography>
                  <Typography>Middle Name: {coach.person.legalMiddleName}</Typography>
                  <Typography>Last Name: {coach.person.legalLastName}</Typography>
                  <Typography>Suffix: {coach.person.suffix}</Typography>
                  <Typography>Nick Name: {coach.person.nickName}</Typography>
                  <Typography>Title: {coach.person.title}</Typography>
                </CardContent>
              </Grid>
              <Grid item md={5}>
                {coach.person.usasfProfilePicture ?
                  <img src={coach.person.usasfProfilePicture} style={{ width: "120px", border: "1px solid red", marginTop: "20px" }} alt={`${coach.person.name} USASF Profile`} />
                  : <></>
                }
              </Grid>
            </Grid>
          </Card>
          <Card key={`address-${coach.id}`} className={classes.cardSpacing}>
            <CardContent>
              <Typography>Address: {coach.person.address1}</Typography>
              <Typography>Address 2: {coach.person.address2}</Typography>
              <Typography>City: {coach.person.city}</Typography>
              <Typography>State: {coach.person.state}</Typography>
              <Typography>Postal Code: {coach.person.postalCode}</Typography>
              <Typography>Country: {coach.person.country}</Typography>
            </CardContent>
          </Card>
          <Card key={`contact-${coach.id}`} className={classes.cardSpacing}>
            <CardContent>
              <Typography>Email: <a className={classes.cleanLink} target="_blank" rel="noreferrer" href={`mailto:${coach.person.email}`}>{coach.person.email}</a> </Typography>
              <Typography>Phone: <a className={classes.cleanLink} target="_blank" rel="noreferrer" href={`tel:${coach.person.cellPhone}`}>{coach.person.cellPhone}</a></Typography>
              <Typography>Home Phone: {coach.person.homePhone}</Typography>
              <Typography>Gender: {(genderOptions.find((gender) => gender.value === coach?.person?.gender))?.name}</Typography>
              <Typography>Birth Date: {coach.person?.birthDate ? format(parseISO(coach.person.birthDate), "MM/dd/yyyy") : ""}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5} container justify="flex-start">
          <Grid item xs={12} container justify="flex-start">
            <div className={classes.paper}>
              <Typography variant="h2">Teams</Typography>
              <List>
                {teamList}
              </List>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <YesNoDialog
        title={`Remove ${coach.name} entirely from ${location?.name}?`}
        question={"This cannot be undone. Are you sure?"}
        isOpen={!!deleteCoachIsOpen}
        onClose={() => { setDeleteCoachIsOpen(0) }}
        buttonActions={[
          { name: "No", color: "primary", callback: () => { setDeleteCoachIsOpen(0) } },
          { name: "Yes", color: "primary", callback: handleRemoveCoach },
        ]}
      />
    </Container> : <></>)

}

export default Coach
