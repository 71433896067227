import React, { useState, useEffect } from 'react';
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

interface DropdownProps {
    element: any;
    handleDropdownChange: Function;
    selectedData: any;
    index?: number;
}

const DropdownComponent: React.FC<DropdownProps> = ({
    element,
    handleDropdownChange,
    selectedData,
    index = 0
}) => {
    const [value, setValue] = useState<any>(null);

    useEffect(() => {
        // Update the value when selectedData changes
        if (selectedData !== null && selectedData !== undefined) {
            const test = selectedData || '';
            const selectedOption = test ? element.enums.find((option: any) => option.value === test) : null;
            setValue(selectedOption);
        }
    }, [selectedData, element.enums]);

    return (
        <Autocomplete
            options={element.enums}
            value={value}
            fullWidth
            getOptionLabel={(obj: any) => obj.label}
            disableClearable
            id={element.key}
            onChange={(event, newValue) => {
                setValue(newValue);
                handleDropdownChange && handleDropdownChange(newValue, element, index);
            }}
            renderInput={(params) => (
                <TextField
                    style={{ marginTop: 16, marginBottom: 8 }}
                    {...params}
                    autoComplete="none"
                    label={element.title}
                    name={element.key}
                    required={element.required}
                    variant="outlined"
                />
            )}
        />
    );
};

export default DropdownComponent;