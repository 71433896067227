import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from "react-router-dom"
import {
  checkGuardianAthleteValid,
  getDocumentSignatureDetails, getDocumentSigntures, saveElectSignatureAgree, saveSignature,
  setGuardianAthleteValid, signTheDocument
} from "../../store/user/userActions"
import themis_common from 'store/themis_common_pb'
import themis_api from 'store/themis_api_pb'
import SignDocument from "./SignDocument"
import YesNoDialog from "../util/YesNoModal"
import IconDialog from "../util/IconModal"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core"
import { CheckCircleOutline as IconCheck } from '@material-ui/icons'


const SignDocumentContainer: React.FC = (): ReactElement => {
  const { signCode: theSignCode } = useParams<{ signCode?: string }>()

  const [documentInfo, setDocumentInfo] = useState<themis_common.DocumentSignatureGuardian.AsObject>()
  const [signatures, setSignatures] = useState<themis_common.DocumentSignature.AsObject[]>([])
  const [isLegalOpen, setIsLegalOpen] = useState(false)
  const [showSign, setShowSign] = useState(false)
  const [sigDialogIsOpen, setSigDialogIsOpen] = useState(false)
  const [signature, setSignature] = useState("")
  const [agreeElectSigSaved, setAgreeElectSigSaved] = useState(false)
  const [refreshDocuments, setRefreshDocuments] = useState(false)
  const [currentDocument, setCurrentDocument] = useState(documentInfo?.documentSignature?.event?.eventDocumentsList[0])
  const [unsignedCount, setUnsignedCount] = useState(0)
  const [clearedModal, setClearedModal] = useState(false)
  const [showERSD, setShowERSD] = useState(false)

  //TODO: Load this from a file...
  const ersd = <div>This is where the ESRD goes<br/><br/>TEST<br/><br/>TEST<br/><br/>TEST<br/><br/>TEST<br/><br/>TEST<br/><br/>TEST<br/><br/>TEST<br/><br/>TEST<br/><br/>TEST<br/><br/>TEST</div>

  useMemo(() => {
    const getDocuments = async () => {
      if (theSignCode) {
        const docInfo = await getDocumentSignatureDetails(theSignCode)
        if (!docInfo?.documentSignature?.event?.eventDocumentsList || !docInfo?.documentSignature?.athlete?.id) return
        const sigs: themis_api.SignatureQueryResponse.AsObject = await getDocumentSigntures(docInfo.documentSignature.event.eventDocumentsList.map(doc => doc.id), docInfo.documentSignature.athlete.id, docInfo.guardian?.id)
        setDocumentInfo(docInfo)
        setSignatures(sigs.documentSignaturesList)
        setCurrentDocument(docInfo.documentSignature?.event?.eventDocumentsList.find(doc => doc.id === currentDocument?.id) || docInfo.documentSignature?.event?.eventDocumentsList[0])
        setUnsignedCount(sigs.documentSignaturesList.reduce((prev: number, current) => !current.status ? prev + 1 : prev, 0))

        setAgreeElectSigSaved(docInfo.agreeElectroncRecordsSignatures)
        if (docInfo.documentSignature?.athlete?.id) {
          const isValid = await checkGuardianAthleteValid(docInfo.guardian?.id, docInfo.documentSignature.athlete.id)
          if (!isValid) {
            setIsLegalOpen(true)
          } else {
            setShowSign(true)
          }
        }
      }
    }
    getDocuments()
  }, [theSignCode, refreshDocuments])

  useMemo(() => {
    if (!documentInfo) return
    if (!documentInfo.guardian?.signature || documentInfo.guardian?.signature.length < 3 ) {
      setSigDialogIsOpen(true)
    } else if (documentInfo.guardian?.signature) {
      setSignature(documentInfo.guardian.signature)
    }

  }, [documentInfo])

  const setNextToCurrent = useCallback(() => {
    setCurrentDocument(documentInfo?.documentSignature?.event?.eventDocumentsList.find(doc => !signatures.find(sig => sig.status && sig.eventDocumentId === doc.id)) || documentInfo?.documentSignature?.event?.eventDocumentsList[0])
  }, [documentInfo?.documentSignature?.event?.eventDocumentsList, signatures])

  const currentSignature = useMemo(() => signatures.find(sig => sig.eventDocumentId === currentDocument?.id), [currentDocument, signatures])

  const approvalLegalGuardian = useCallback(() => {
    const setLegal = async () => {
      if (!documentInfo?.documentSignature?.athlete?.id) return
      if (await setGuardianAthleteValid(documentInfo.guardian?.id, documentInfo.documentSignature.athlete.id, theSignCode || "")) {
        setShowSign(true)
      }
    }
    setIsLegalOpen(false)
    setLegal()
  }, [documentInfo?.documentSignature?.athlete?.id, documentInfo?.guardian?.id, theSignCode])

  const saveSig = useCallback(async (signature: string) => {
    if (theSignCode) await saveSignature(theSignCode, signature)
  }, [theSignCode])

  const saveElectSig = useCallback( async (answer: boolean) => {
    if (theSignCode) {
      setAgreeElectSigSaved(await saveElectSignatureAgree(theSignCode, answer))
    }
  }, [theSignCode])

  const replaceText = useCallback((html: string): string => {
    let out = html
    const theEvent = documentInfo?.documentSignature?.event
    const brand = documentInfo?.documentSignature?.event?.brand
    const producer = documentInfo?.documentSignature?.event?.brand?.producer
    const venueAddress = `${theEvent?.address} ${theEvent?.address2}, ${theEvent?.city} ${theEvent?.state} ${theEvent?.postalCode}`
    const venueName = theEvent?.venueName

    const sortedDates = theEvent?.eventDatesList.sort((a, b) => {
      const date1 = a.startDay.split('|')[0]
      const date2 = b.startDay.split('|')[0]
      if (date1 > date2) return 1
      else return -1
    })

    let eventDates = ""
    if (!sortedDates?.length) {
      eventDates = "No Dates For Event"
    } else if (sortedDates.length === 1) {
      eventDates = `${sortedDates[0]?.startClockTime?.formattedDateTime?.monthLong} ${sortedDates[0]?.startClockTime?.formattedDateTime?.dayNumber}, ${sortedDates[0]?.startClockTime?.formattedDateTime?.year} `
    } else {
      const lastDate = sortedDates[sortedDates.length-1]
      if (lastDate?.startClockTime?.formattedDateTime?.monthLong === sortedDates[0]?.startClockTime?.formattedDateTime?.monthLong) {
        eventDates = `${lastDate?.startClockTime?.formattedDateTime?.monthLong} ${sortedDates[0]?.startClockTime?.formattedDateTime?.dayNumber}-${lastDate?.startClockTime?.formattedDateTime?.dayNumber}, ${lastDate?.startClockTime?.formattedDateTime?.year}`
      } else {
        eventDates = `${sortedDates[0]?.startClockTime?.formattedDateTime?.monthLong} ${sortedDates[0]?.startClockTime?.formattedDateTime?.dayNumber} - ${lastDate?.startClockTime?.formattedDateTime?.monthLong} ${lastDate?.startClockTime?.formattedDateTime?.dayNumber}, ${lastDate?.startClockTime?.formattedDateTime?.year}`
      }
    }
    out = out.replaceAll("{{ .Athlete }}", "Jane Doe")
    out = out.replaceAll("{{ .Venue }}", venueName || "Venue")
    out = out.replaceAll("{{ .VenueAddress }}", venueAddress)
    out = out.replaceAll("{{ .Event }}", theEvent?.name || "Event")
    out = out.replaceAll("{{ .Producer }}", producer?.name || "Producer")
    out = out.replaceAll("{{ .Brand }}", brand?.name || 'Brand')
    out = out.replaceAll("{{ .Dates }}", eventDates)
    out = out.replaceAll("{{ .Guardian }}", "Joseph Doe")

    return out
  }, [documentInfo?.documentSignature?.event?.brand, documentInfo?.documentSignature?.event?.brand?.producer])

  const signDocument = useCallback((documentId: number) => {
    const doSign = async () => {
      const theSignature = signatures.find((sig) => sig.id === currentSignature?.id)
      if (!theSignCode || !theSignature) return
      await signTheDocument(theSignature, theSignCode, signature)
      setRefreshDocuments((current) => !current)
    }
    const theDoc: themis_common.EventDocument.AsObject | undefined = documentInfo?.documentSignature?.event?.eventDocumentsList.find(document => document.id === documentId)
    doSign()
    if (!theDoc) return

  }, [currentSignature, documentInfo?.documentSignature?.event?.eventDocumentsList, signature, signatures, theSignCode])

  const saveTheSignature = useCallback((_) => {
    const doSaveTheSig = async () => {
      if (documentInfo?.guardian) documentInfo.guardian.signature = signature
      await saveSig(signature)
      setRefreshDocuments(current => !current)
      setSigDialogIsOpen(false)

    }
    doSaveTheSig()
  }, [documentInfo?.guardian, saveSig, signature])


  return <>{ documentInfo && showSign ?
    <SignDocument
      signInfo={documentInfo}
      signatures={signatures}
      saveElectSig={saveElectSig}
      agreeElectSigSaved={agreeElectSigSaved}
      replaceText={replaceText}
      signDocument={signDocument}
      refreshDocuments={refreshDocuments}
      currentDocument={currentDocument}
      setCurrentDocument={setCurrentDocument}
      currentSignature={currentSignature}
      setNextToCurrent={setNextToCurrent}
      unsignedCount={unsignedCount}
      setShowERSD={setShowERSD}
    /> : [] }
  <YesNoDialog question={`Are you the parent or legal guardian for ${documentInfo?.documentSignature?.athlete?.name} or are you ${documentInfo?.documentSignature?.athlete?.name} and over 18 years old?`} title="Validate Legal Right To Sign" isOpen={isLegalOpen} onClose={() => setIsLegalOpen(false)} buttonActions={[{ name: "Yes", color: "primary", callback: approvalLegalGuardian }, { name: "No", color: "secondary", callback: () => { setIsLegalOpen(false) } }]} />
  <YesNoDialog question={<div style={{ width: "100%", height: "300px", overflowY: "auto" }}>{ersd}</div>} title="Electronic Record and Signature Disclosre" isOpen={showERSD} onClose={() => setShowERSD(false)} buttonActions={[{ name: "Okay", color: "primary", callback: () => { setShowERSD(false) } }]} />
  <IconDialog question={`You're done signing.`} icon={<IconCheck style={{ paddingBottom: 0, color: "#99CC56" }} fontSize="large" />} title="Thank You" isOpen={!unsignedCount && !clearedModal && (documentInfo?.documentSignature?.event?.eventDocumentsList?.length ?? 0) > 0 } onClose={() => setClearedModal(true)} buttonActions={[{ name: "Okay", color: "secondary", callback: () => { setClearedModal(true)} } ]} />
  <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    fullWidth
    maxWidth='sm'
    open={sigDialogIsOpen}
    aria-labelledby='max-width-dialog-title'
  >
    <DialogTitle id="max-width-dialog-title">Set Signature</DialogTitle>
    <DialogContent>
      <DialogContentText>Please Set Your Signature</DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="signature"
        label="Signature"
        type="text"
        fullWidth
        required
        onChange={(e) => { setSignature(e.target.value) } }
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => { setSigDialogIsOpen(false) }} color="primary">
        Cancel
      </Button>
      <Button onClick={saveTheSignature} color="primary" disabled={signature.trim().length < 3}>
        Save Signature
      </Button>
    </DialogActions>
  </Dialog>
  </>
}

export default SignDocumentContainer
