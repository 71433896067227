import React, { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import {
  Container, Grid, TextField, Typography,
  ListItem, ListItemIcon, ListItemText, Checkbox, Button
} from '@material-ui/core'
import { useHistory, useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentLocation, selectCurrentProgram, selectLocationGuardians } from "store/program/programSelectors"
import { emptyPerson } from "store/program/programReducer"
import AddEditPerson from "components/program/person/AddEditPerson"
import { Autocomplete } from "@material-ui/lab"
import { selectCurrentCategories } from "store/user/userSelectors"
import { Categories, refreshUser } from "store/user/userActions"
import themis_common from "store/themis_common_pb"
import { addAthleteToTeam } from "store/program/teamActions"
import { addAthlete, getAthlete, updateAthlete } from "store/program/athleteActions"
import { FormFields } from "lib/constants"
import YesNoDialog from 'components/util/YesNoModal'
import { capitalizeFirstLetter, fieldsOkay } from 'lib/validators'
import { format, addYears } from "date-fns"


const AddEditAthlete: React.FC = (): ReactElement => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { athleteId: inputAthleteId, teamId: inputTeamId, locationId: inLocationId } = useParams<{athleteId?: string | undefined, teamId?: string | undefined, locationId?: string | undefined}>()
  const athleteId = Number(inputAthleteId)
  const teamId = Number(inputTeamId)
  const locationId = Number(inLocationId)

  const program: themis_common.Program.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProgram(state))
  const location: themis_common.Location.AsObject | undefined = useSelector((state: IAppState) => selectCurrentLocation(state))
  const categories: Categories | undefined = useSelector((state: IAppState) => selectCurrentCategories(state))
  const guardians: themis_common.Guardian.AsObject[] = useSelector((state: IAppState) => selectLocationGuardians(state))

  const programId = useMemo(() => program?.id,[program])

  useEffect(() => {
    load()
    async function load() {
      if (!programId || !locationId || !athleteId) return
      setAthlete(await getAthlete(dispatch, athleteId, programId, locationId, teamId))
    }
  }, [programId, locationId, athleteId, dispatch, teamId])

  const [person, setPerson] = useState(emptyPerson)
  const [guardian, setGuardian] = useState(emptyPerson)
  const [guardianId, setGuardianId] = useState<number | undefined>(undefined)
  const [guardianReadOnly, setGuardianReadOnly] = useState<boolean>(false)
  const [athlete, setAthlete] = useState<themis_common.Athlete.AsObject | undefined>(undefined)
  const [tiers, setTiers] = useState<themis_common.Tier.AsObject[]>(athlete?.athleteTiersList || [])
  const [types, setTypes] = useState<themis_common.AllStarType.AsObject[]>(athlete?.athleteTypesList || [])
  const [checkAthlete, setCheckAthlete] = useState(false)
  const [checkGuardian, setCheckGuardian] = useState(false)
  const [athleteInfoTransferChecked, setAthleteInfoTransferChecked] = useState(false)
  const [allowMinAthlete, setAllowMinAthlete] = useState(false)
  const [allowMinAthleteTest, setAllowMinAthleteTest] = useState(false)
  const [allowNoGuardian, setAllowNoGuardian] = useState(false)
  const [allowNoGuardianTest, setAllowNoGuardianTest] = useState(false)
  const [checkGuardianEmail, setCheckGuardianEmail] = useState(false)

  const athletePersonFields: FormFields = useMemo(() => { return {
    legalFirstName: { label: "First Name", required: true, phone: false, email: false },
    legalMiddleName: { label: "Middle Name", required: false, phone: false, email: false },
    legalLastName: { label: "Last Name", required: true, phone: false, email: false },
    suffix: { label: "Suffix (Jr, Sr, etc.)", required: false, phone: false, email: false },
    nickName: { label: "Nick Name", required: false, phone: false, email: false },
    title: { label: "Title", required: false, phone: false, email: false },
    cellPhone: { label: "Cell Phone", required: !allowMinAthlete, phone: true, email: false },
    homePhone: { label: "Home Phone", required: false, phone: true, email: false },
    email: { label: "Email", required: !allowMinAthlete, phone: false, email: true },
    birthDate: { label: "Birth Date", required: true, phone: false, email: false },
    gender: { label: "Gender", required: false, phone: false, email: false },
    address1: { label: "Address", required: !allowMinAthlete, phone: false, email: false },
    address2: { label: "Address 2", required: false, phone: false, email: false },
    city: { label: "City", required: !allowMinAthlete, phone: false, email: false },
    state: { label: "State or Province", required: !allowMinAthlete, phone: false, email: false },
    postalCode: { label: "Postal Code", required: !allowMinAthlete, phone: false, email: false },
    country: { label: "Country", required: !allowMinAthlete, phone: false, email: false },
  } }, [allowMinAthlete])

  const guardianPersonFields: FormFields = {
    legalFirstName: { label: "First Name", required: true, phone: false, email: false },
    legalMiddleName: { label: "Middle Name", required: false, phone: false, email: false },
    legalLastName: { label: "Last Name", required: true, phone: false, email: false },
    suffix: { label: "Suffix (Jr, Sr, etc.)", required: false, phone: false, email: false },
    nickName: { label: "Nick Name", required: false, phone: false, email: false },
    title: { label: "Title", required: false, phone: false, email: false },
    cellPhone: { label: "Cell Phone", required: !allowMinAthlete, phone: true, email: false },
    homePhone: { label: "Home Phone", required: false, phone: true, email: false },
    email: { label: "Email", required: !allowMinAthlete, phone: false, email: true },
    birthDate: { label: "Birth Date", required: false, phone: false, email: false },
    gender: { label: "Gender", required: false, phone: false, email: false },
    address1: { label: "Address", required: !allowMinAthlete, phone: false, email: false },
    address2: { label: "Address 2", required: false, phone: false, email: false },
    city: { label: "City", required: !allowMinAthlete, phone: false, email: false },
    state: { label: "State or Province", required: !allowMinAthlete, phone: false, email: false },
    postalCode: { label: "Postal Code", required: !allowMinAthlete, phone: false, email: false },
    country: { label: "Country", required: !allowMinAthlete, phone: false, email: false },
  }

  const guardianRequired: boolean = useMemo(() => {
    if (allowNoGuardian) return false
    const birthDate = person.birthDate.split("T")[0]
    const eightTeenYearsAgo = format(addYears(new Date(), -18), "yyyy-MM-dd")
    if (birthDate.length > 0 && eightTeenYearsAgo > birthDate) return false
    return !athleteId || athlete?.athleteGuardiansList.length === 0
  }, [athleteId, athlete?.athleteGuardiansList, person.birthDate, allowNoGuardian])

  const locationCountry: string | undefined = useMemo(() => athleteId ? undefined : location?.country, [location?.country, athleteId])
  const locationState: string | undefined = useMemo(() => athleteId ? undefined : location?.state, [location?.state, athleteId])

  useEffect(() => {
    if (!athlete) return
    setTiers(athlete.athleteTiersList)
    setTypes(athlete.athleteTypesList)
  }, [athlete?.athleteTiersList, athlete?.athleteTypesList, athlete])

  useEffect(() => {
    if (!athleteId || !athlete?.person?.id) return
    setPerson(athlete.person)
    setGuardian(athlete.athleteGuardiansList[0]?.person || emptyPerson)
  }, [athleteId, athlete, setPerson])

  useEffect(() => {
    if (guardian && athleteInfoTransferChecked) setGuardian({ ...guardian, legalLastName: person.legalLastName, address1: person.address1, address2: person.address2, city: person.city, country: person.country, state: person.state, postalCode: person.postalCode, cellPhone: person.cellPhone, homePhone: person.homePhone, email: person.email })
    return
  }, [athleteInfoTransferChecked]) // Don't include guardian and person in the dependencies

  const create = !athleteId ? 'Create' : 'Edit'
  const creating = !athleteId ? 'creating' : 'editing'
  const creatingBool = !athleteId

  const guardianFormReady = useMemo(() => fieldsOkay(guardian, guardianPersonFields), [guardian])
  const personFormReady = useMemo(() => fieldsOkay(person, athletePersonFields), [person, athletePersonFields])

  const submitForm = async (event: FormEvent | null): Promise<void> => {
    setCheckAthlete(true)
    setCheckGuardian(true)
    event?.preventDefault()
    if (!programId || !locationId) return

    const dest = teamId ? `/Location/${locationId}/Team/${teamId}` : `/Location/${locationId}`
    if (!personFormReady || (guardianRequired && !guardianFormReady)) {
      if (!personFormReady){
        setAllowMinAthleteTest(true)
      }
      return
    }

    if (athleteId) {
      // Editing Athlete
      try {
        await updateAthlete(dispatch, athleteId, person, guardian, tiers, types, programId, locationId, teamId)
        history.replace(dest + `/Athlete/${athleteId}`)
      } catch (e) {
        setIsErrorOpen(true)
        console.error(e)
      }
    } else {
      // Creating Athlete
      try {
        const athlete = await addAthlete(dispatch, person, guardian, tiers, types, programId, locationId, guardianId || 0)
        await refreshUser(dispatch)
        if (teamId > 0) {
          await addAthleteToTeam(dispatch, programId, locationId, teamId, athlete.id)
          history.replace(dest + `#athletes`)
        } else {
          history.replace(dest + `/Athlete/${athlete.id}`)
        }
      } catch (e) {
        setIsErrorOpen(true)
        console.error(e)
      }
    }
  }

  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const setGuardianFromList = (guardian: themis_common.Guardian.AsObject | undefined): void => {
    if (!guardian || !guardian.person) {
      setGuardianId(0)
      setGuardianReadOnly(false)
    } else {
      setGuardian(guardian.person)
      setGuardianId(guardian.id)
      setGuardianReadOnly(true)
    }
  }

  const headerContents = () => {
    if (athlete?.name) {
      return `${create} Athlete - ${athlete.name}`
    } else {
      return `${create} Athlete`
    }
  }

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{headerContents()}</Typography>
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={10}>
          <div className={classes.paper}>
            <ListItem>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={allowMinAthlete}
                  tabIndex={0}
                  disableRipple
                  onChange={(e: any) => { if (e.target.checked) {
                    setAllowMinAthleteTest(true)
                  } else {
                    setAllowMinAthlete(false)
                  }
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"Allow minimum athlete information"}/>
            </ListItem>

            <AddEditPerson person={person}
              formId={0}
              updatePerson={setPerson}
              formReady={personFormReady && (!guardianRequired || guardianFormReady)}
              formFields={athletePersonFields}
              hiddenFields={{ title: true }}
              locationCountry={locationCountry}
              locationState={locationState}
              readonly={false}
              checkPerson={checkAthlete}
              setCheckPerson={setCheckAthlete}
              creating={creatingBool}
              editBirthDate={false}
              editingAthlete={true}
            />
            <Grid container item xs={12} style={{ marginTop: "8px" }}>
              { !categories?.allStarTypes || !types ? <></> :
                <Grid item sm={12} md={6} className={classes.gridForm}>
                  <Autocomplete
                    multiple
                    options={categories.allStarTypes}
                    getOptionLabel={(option) => option.name}
                    value={types}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={(e, v) => {
                      v && setTypes(v)
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <form noValidate onSubmit={(e) => {e.preventDefault()}}>
                        <TextField
                          {...params}
                          variant="outlined"
                          autoComplete="none"
                          label="Athlete Types"
                        />
                      </form>
                    )}
                  />
                </Grid>
              }
              { !categories?.tiers || !tiers ? <></> :
                <Grid item sm={12} md={6} className={classes.gridForm}>
                  <Autocomplete
                    multiple
                    options={categories.tiers}
                    getOptionLabel={(option) => option.name}
                    value={tiers}
                    onChange={(e, v) => {
                      v && setTiers(v)
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <form noValidate onSubmit={(e) => {e.preventDefault()}}>
                        <TextField
                          {...params}
                          variant="outlined"
                          autoComplete="none"
                          label="Tiers"
                        />
                      </form>
                    )}
                  />
                </Grid>
              }
            </Grid>
            { athleteId ? [] :
              <Grid container item xs={12} style={{ marginTop: "8px" }}>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={allowNoGuardian}
                      tabIndex={0}
                      disableRipple
                      onChange={(e: any) => { if (e.target.checked) {
                        setAllowNoGuardianTest(true)
                      } else {
                        setAllowNoGuardian(false)
                      }
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Allow no guardian to be submitted."}/>
                </ListItem>
              </Grid>
            }

            {!guardianRequired ? <></> : // Force
              <>
                <hr style={{ width: "100%", marginTop: 24 }} />
                <Typography variant="h2">Guardian (Required)</Typography>
                <div className={classes.paper}>
                  <Typography variant="h2">Select Existing Guardian</Typography>
                  <Autocomplete
                    id="pick-guardian"
                    options={guardians || []}
                    defaultValue={null}
                    getOptionLabel={(option) => `${option.name} - ${option.person?.address1}`}
                    onChange={(e, v) => {
                      v && setGuardianFromList(v)
                    }}
                    onInputChange={(e, value, reason) => {
                      if (reason === "clear") {
                        setGuardianFromList(undefined)
                      }
                    }}
                    style={{ width: 450 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="none"
                        label="Select Guardian"
                      />
                    )}
                  />
                </div>

                <Typography variant="h2">or</Typography>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={athleteInfoTransferChecked}
                      tabIndex={0}
                      disableRipple
                      onChange={(e: any) => setAthleteInfoTransferChecked(e.target.checked)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Same info as athlete"}/>
                </ListItem>

                <AddEditPerson
                  person={guardian}
                  formId={1}
                  updatePerson={setGuardian}
                  formReady={guardianFormReady}
                  formFields={guardianPersonFields}
                  hiddenFields={{ title: true, birthDate: true, gender: true, nickName: true }}
                  locationCountry={locationCountry}
                  locationState={locationState}
                  readonly={guardianReadOnly}
                  checkPerson={checkGuardian}
                  creating={creatingBool}
                  setCheckPerson={setCheckGuardian}
                />
              </>
            }
            <Grid container item xs={12}>
              <Button fullWidth className={classes.submit} onClick={ (e) => {
                console.log('guardian email', guardian.email, !allowNoGuardian, guardian.email.trim().length === 0)
                if ( !allowNoGuardian && guardian.email.trim().length === 0 ) {
                  setCheckGuardianEmail(true)
                } else {
                  submitForm(e)
                }
              }}>{`${person.id ? 'Edit' : 'Create'} Athlete`}</Button>
            </Grid>
          </div>
        </Grid>
        <YesNoDialog
          title={`Error ${capitalizeFirstLetter(creating)} Athlete`}
          question={`There was an error ${creating} your athlete. Please contact us if this continues to be a problem.`}
          isOpen={isErrorOpen}
          disabled={!personFormReady || (guardianRequired && !guardianFormReady)}
          onClose={() => setIsErrorOpen(false)}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
          ]}
        />
        <YesNoDialog
          title={`Adding an athlete without a guardian will prevent this athlete from registering for some events until a guardian is added.`}
          question={`Would you still like to add this athlete with no guardian?`}
          isOpen={allowNoGuardianTest}
          onClose={() => setAllowNoGuardianTest(false)}
          buttonActions={[
            { name: "Yes", color: "primary", callback: () => { setAllowNoGuardianTest(false); setAllowNoGuardian(true) } },
            { name: "No", color: "primary", callback: () => { setAllowNoGuardianTest(false); setAllowNoGuardian(false) } },
          ]}
        />
        <YesNoDialog
          title={`Adding an athlete with minimal information (First Name, Last Name, Birth Date) may prevent this athlete from registering for some events until more information is added.`}
          question={`Would you still like to add this athlete with minimal information?`}
          isOpen={allowMinAthleteTest}
          onClose={() => setAllowMinAthleteTest(false)}
          buttonActions={[
            { name: "Yes", color: "primary", callback: () => { setAllowMinAthleteTest(false); setAllowMinAthlete(true) } },
            { name: "No", color: "primary", callback: () => { setAllowMinAthleteTest(false); setAllowMinAthlete(false) } },
          ]}
        />
        <YesNoDialog
          title={`Adding a guardian without an email address may prevent this athlete from registering for some events until an email is added.`}
          question={`Would you still like to add this athlete without a guardian email?`}
          isOpen={checkGuardianEmail}
          onClose={() => setCheckGuardianEmail(false)}
          buttonActions={[
            { name: "Yes", color: "primary", callback: () => { setCheckGuardianEmail(false);
              setAllowNoGuardian(true)
              submitForm(null) } },
            { name: "No", color: "primary", callback: () => { setCheckGuardianEmail(false) } },
          ]}
        />
      </Grid>
    </Container>
  )
}

export default AddEditAthlete
