import { createContext, useContext } from 'react'

export const csvUploadDefault = {
  id: 0,
  fileSelected: false,
  base64File: "",
  fileName: "",
  maxFileSizeExceeded: false
}

export type CsvUploadContent = {
  csvState: typeof csvUploadDefault
  setCsvState: (l: typeof csvUploadDefault) => void
}

export const csvUploadContext = createContext<CsvUploadContent>({
  csvState: csvUploadDefault,
  setCsvState: () => {
    return useCsvUploadState
  }
})

export const useCsvUploadState = (): CsvUploadContent => useContext(csvUploadContext)
