import { client } from "store/themisClient"
import themis_api from "store/themis_api_pb"
import themis_common from "store/themis_common_pb"

/* SCOPE
    TRANSACTION_ITEMS_SPECIFIC = 0;
    PRODUCER_PROGRAM_ITEMS = 1;
    PRODUCER_TEAM_ITEMS = 2;
    PRODUCER_LOCATION_ITEMS = 3;
    EVENT_PROGRAM_ITEMS = 4;
    EVENT_TEAM_ITEMS = 5;
    EVENT_LOCATION_ITEMS = 6;
    PROGRAM_ITEMS = 7;
    LOCATION_ITEMS = 8;
    TEAM_ITEMS = 9;
    BRAND_PROGRAM_ITEMS = 10;
    BRAND_TEAM_ITEMS = 11;
    BRAND_LOCATION_ITEMS = 12;
    PRODUCER_ITEMS = 13;
    BRAND_ITEMS = 14;
    EVENT_ITEMS = 15;
 */

/* PAID_SCOPE
    PAID = 0;
    UNPAID = 1;
    ANY = 2;
 */

export async function getRegistrationTransactionsByIds(selectedRegistrationsForPayment: number[]): Promise<themis_common.RegistrationTransaction.AsObject[]> {
  const request = new themis_api.GetRegistrationTransactionsByIdsRequest()
  request.setRegistrationTransactionIdsList(selectedRegistrationsForPayment)

  const res: themis_api.GetRegistrationTransactionsByIdsResponse.AsObject = (await client.getRegistrationTransactionsByIds(request, {})).toObject()
  return res.registrationTransactionsList
}

export async function getRegistrationTransactionsByEventTeam(eventTeamId: number): Promise<themis_common.RegistrationTransaction.AsObject[]> {
  const request = new themis_common.EventTeam()
  request.setId(eventTeamId)
  const res: themis_api.GetRegistrationTransactionsByIdsResponse.AsObject = (await client.getRegistrationTransactionsByEventTeam(request, {})).toObject()
  return res.registrationTransactionsList
}

export async function addPayment(addPaymentRequest: themis_api.AddPaymentRequest.AsObject): Promise<themis_api.AddPaymentResponse.AsObject> {
  const request = new themis_api.AddPaymentRequest()
  request.setScope(addPaymentRequest.scope)
  request.setAmount(addPaymentRequest.amount)
  request.setProducerId(addPaymentRequest.producerId)
  request.setProgramId(addPaymentRequest.programId)
  request.setRegistrationTransactionsList(addPaymentRequest.registrationTransactionsList)
  request.setCurrency(addPaymentRequest.currency)
  request.setPaymentType(addPaymentRequest.paymentType)
  request.setPaymentSource(addPaymentRequest.paymentSource)
  request.setEventTeamIdsList(addPaymentRequest.eventTeamIdsList)
  // Need to allow the request to be for an EventTeam and a deposit...

  request.setStoredPaymentType(addPaymentRequest.storedPaymentType)

  const res: themis_api.AddPaymentResponse.AsObject = (await client.addPayment(request, {})).toObject()
  return res
}

export async function chargePaymentSource(chargePaymentRequest: themis_api.AddPaymentRequest.AsObject): Promise<boolean> {
  const request = new themis_api.AddPaymentRequest()
  request.setScope(chargePaymentRequest.scope)
  request.setAmount(chargePaymentRequest.amount)
  request.setProducerId(chargePaymentRequest.producerId)
  request.setProgramId(chargePaymentRequest.programId)
  request.setRegistrationTransactionsList(chargePaymentRequest.registrationTransactionsList)
  request.setCurrency(chargePaymentRequest.currency)
  request.setPaymentType(chargePaymentRequest.paymentType)
  request.setPaymentSource(chargePaymentRequest.paymentSource)
  request.setStoredPaymentType(chargePaymentRequest.storedPaymentType)
  request.setPaymentId(chargePaymentRequest.paymentId)

  const res: themis_api.ChargePaymentSourceResponse.AsObject = (await client.chargePaymentSource(request, {})).toObject()
  return res.succeeded
}
