// Common Imports
import React, { ReactElement } from 'react';
import { templateTypes } from 'lib/constants';

// Templates Imports
import TemplateOne from './templates/Template-1';
import TemplateTwo from './templates/Template-2';
import TemplateThree from './templates/Template-3';
import TemplateFour from './templates/Template-4';

interface RubricScoringFormProps {
  sheetData: any;
  responseData: Function;
  initialJsonValues: any;
  judgeType: string[];
}

const RubricScoringForm: React.FC<RubricScoringFormProps> = ({
  sheetData,
  responseData,
  initialJsonValues,
  judgeType
}): ReactElement => {
  const renderRubricItems = (rubricArea: any) => {
    return (
      <>
        {Object.entries(rubricArea?.data?.items).map(([key, value]: any) => {
          return Object.entries(value).map(([key1, value1]: any) => {
            switch (value1) {
              case templateTypes.TEMPLATE_ONE:
                const templateOneMode = initialJsonValues ? Object.keys(initialJsonValues).length > 0 ? 'Edit' : 'Add' : '';
                return (
                  <TemplateOne
                    mode={templateOneMode}
                    templateData={value}
                    responseData={responseData}
                    judgeType={judgeType}
                    initialValues={initialJsonValues}
                  />
                );

              case templateTypes.TEMPLATE_TWO:
                const templateTwoMode = initialJsonValues ? Object.keys(initialJsonValues).length > 0 ? 'Edit' : 'Add' : '';
                return (
                  <TemplateTwo
                    templateData={value}
                    templateType={rubricArea?.data?.type}
                    responseData={responseData}
                    judgeType={judgeType}
                    mode={templateTwoMode}
                    initialValues={initialJsonValues}
                  />
                );

              case templateTypes.TEMPLATE_THREE:
                const templateThreeMode = initialJsonValues ? Object.keys(initialJsonValues).length > 0 ? 'Edit' : 'Add' : '';
                return (
                  <TemplateThree
                    mode={templateThreeMode}
                    templateData={value}
                    responseData={responseData}
                    judgeType={judgeType}
                    initialValues={initialJsonValues}
                  />
                );

              case templateTypes.TEMPLATE_FOUR:
                const templateFourMode = initialJsonValues ? Object.keys(initialJsonValues).length > 0 ? 'Edit' : 'Add' : '';
                return (
                  <TemplateFour
                    mode={templateFourMode}
                    templateData={value}
                    responseData={responseData}
                    judgeType={judgeType}
                    initialValues={initialJsonValues}
                  />
                );

              default:
                return null;
            }
          });
        })
        }
      </>
    );
  }

  const renderDifficultyForm = () => {
    return sheetData.map((rubricArea: any) => {
      const filteredItems = rubricArea?.data?.items.filter((item: any) => judgeType?.includes(item.judgeType));

      if (judgeType) {
        if (filteredItems.length > 0) {
          return (
            <>
              {renderRubricItems(rubricArea)}
            </>
          );
        }
      } else {
        return (
          <>
            {renderRubricItems(rubricArea)}
          </>
        );
      }
    });
  }

  return (
    <>
      {renderDifficultyForm()}
    </>
  );
}
export default RubricScoringForm;