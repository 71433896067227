import React, { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import { Countries, countries, genderOptions, UsStates, usStates, Gender, FormFields } from "lib/constants"
import { useStyles } from "lib/theme"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, TextField } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import themis_common from "store/themis_common_pb"
import { formatISO, parseISO } from 'date-fns'
import { HiddenFields } from "store/program/programReducer"
import FormTextField from 'components/util/FormTextField'
import { handleInputChange, buildErrors } from "lib/validators"
import SubmitButton from 'components/util/SubmitButton'
import { getCountryDateFormat } from 'lib/functions'

interface addEditPersonProps {
  person: themis_common.Person.AsObject,
  formId: number,
  formReady: boolean,
  hiddenFields: HiddenFields,
  formFields: FormFields,
  updatePerson: Function,
  submitForm?: (event: FormEvent) => Promise<void>,
  locationState: string | undefined,
  locationCountry: string | undefined
  readonly: boolean
  checkPerson: boolean
  setCheckPerson: Function
  creating: boolean
  editBirthDate?: boolean
  editingAthlete?: boolean
}

const AddEditPerson: React.FC<addEditPersonProps> = ({ person, updatePerson, submitForm, hiddenFields, formFields, formId, locationCountry, locationState, readonly, checkPerson, setCheckPerson, editBirthDate, creating, editingAthlete}): ReactElement => {
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})
  const [locationCountryAutofilled, setLocationCountryAutofilled] = useState(false)
  const [locationStateAutofilled, setLocationStateAutofilled] = useState(false)
  const [countryDateFormat, setCountryDateFormat] = useState<string>("MM/dd/yyyy")

  const bdReadOnly = useMemo(() => {
    // TODO: If super user, return false
    if (editBirthDate || person.isBirthdayEdited === false) return false
    return !!person.id
  }, [editBirthDate, person.id, person.isBirthdayEdited])

  // TODO: Test this with hidden country field (maybe judges)
  useEffect(() => {
    if (creating && locationCountry && !locationCountryAutofilled) {
      person.country = locationCountry
      setLocationCountryAutofilled(true)
    }
  }, [locationCountry])

  useEffect(() => {
    if (creating && locationState && !locationStateAutofilled) {
      person.state = locationState
      setLocationStateAutofilled(true)
    }
  }, [locationState])

  useEffect(() => {
    setCountryDateFormat(getCountryDateFormat(new Date()))
  }, [])

  const convertCountryCodeToObject = (inputCountryCode: string): Countries => {
    const unitedStates = countries[0]
    return countries.find((country) => country.code === inputCountryCode) || unitedStates
  }

  const convertStateCodeToObject = (inputStateCode: string): UsStates => {
    const alabama = usStates[0]
    return usStates.find((state) => state.abbreviation === inputStateCode) || alabama
  }

  const handleDateChange = (date: Date | null): void => {
    setSelectedDate(date)
    try {
      if (date) {
        handleInputChange({ target: { name: "birthDate", value: formatISO(date) } }, formFields, person, updatePerson, errorText, setErrorText)
      }
    } catch (e) {
      // Do Nothing
    }
  }

  // Check fields when user tries to submit
  useEffect(() => {
    if (checkPerson) {
      setErrorText(buildErrors(person, formFields, updatePerson))
      setCheckPerson(false)
    }
  }, [formFields, person, setErrorText, checkPerson, setCheckPerson, updatePerson])

  useEffect(() => {
    const newDate = person.birthDate ? parseISO(person.birthDate) : null
    setSelectedDate(newDate)
  }, [person.birthDate])

  const submitArea = useMemo(() => {

    if (!submitForm) return []

    return <Grid container item xs={12}>
      <SubmitButton title={`${person.id ? 'Edit' : 'Create'} Athlete`} ariaLabel="submit-person-form" submitForm={submitForm} />
    </Grid>

  }, [person.id, submitForm])

  return (
    <>
      <Grid container>
        <Grid container>
          <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
            <form noValidate onSubmit={(e) => { e.preventDefault() }}>
              <FormTextField
                formFields={formFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={person}
                setUpdateObject={updatePerson}
                name="legalFirstName"
                autoFocus={formId === 0}
                sliceSize={100} inputProps={{ readOnly: readonly }}
                formId={formId}
              />
            </form>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
            <form noValidate onSubmit={(e) => { e.preventDefault() }}>
              <FormTextField
                formFields={formFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={person}
                setUpdateObject={updatePerson}
                name="legalMiddleName"
                sliceSize={100} inputProps={{ readOnly: readonly }}
              />
            </form>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
            <form noValidate onSubmit={(e) => { e.preventDefault() }}>
              <FormTextField
                formFields={formFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={person}
                setUpdateObject={updatePerson}
                name="legalLastName"
                sliceSize={100} inputProps={{ readOnly: readonly }}
                formId={formId}
              />
            </form>
          </Grid>
          {hiddenFields.suffix ? <></> :
            <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
              <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                <FormTextField
                  formFields={formFields}
                  errorText={errorText}
                  setErrorText={setErrorText}
                  updatedObject={person}
                  setUpdateObject={updatePerson}
                  name="suffix"
                  sliceSize={100} inputProps={{ readOnly: readonly }}
                />
              </form>
            </Grid>
          }
          {hiddenFields.nickName ? <></> :
            <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
              <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                <FormTextField
                  formFields={formFields}
                  errorText={errorText}
                  setErrorText={setErrorText}
                  updatedObject={person}
                  setUpdateObject={updatePerson}
                  name="nickName"
                  sliceSize={100} inputProps={{ readOnly: readonly }}
                  formId={formId}
                />
              </form>
            </Grid>
          }
          {hiddenFields.title ? <></> :
            <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
              <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                <FormTextField
                  formFields={formFields}
                  errorText={errorText}
                  setErrorText={setErrorText}
                  updatedObject={person}
                  setUpdateObject={updatePerson}
                  name="title"
                  sliceSize={100} inputProps={{ readOnly: readonly }}
                  formId={formId}
                />
              </form>
            </Grid>
          }
        </Grid>
        {hiddenFields.address ? <></> :
          <>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4} className={classes.gridForm}>
                <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                  <FormTextField
                    formFields={formFields}
                    errorText={errorText}
                    setErrorText={setErrorText}
                    updatedObject={person}
                    setUpdateObject={updatePerson}
                    name="address1"
                    sliceSize={200} inputProps={{ readOnly: readonly }}
                    formId={formId}
                  />
                </form>
              </Grid>
              <Grid item xs={12} md={4} className={classes.gridForm}>
                <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                  <FormTextField
                    formFields={formFields}
                    errorText={errorText}
                    setErrorText={setErrorText}
                    updatedObject={person}
                    setUpdateObject={updatePerson}
                    name="address2"
                    sliceSize={200} inputProps={{ readOnly: readonly }}
                  />
                </form>
              </Grid>
              <Grid item xs={12} md={4} className={classes.gridForm}>
                <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                  <FormTextField
                    formFields={formFields}
                    errorText={errorText}
                    setErrorText={setErrorText}
                    updatedObject={person}
                    setUpdateObject={updatePerson}
                    name="city"
                    sliceSize={200} inputProps={{ readOnly: readonly }}
                    formId={formId}
                  />
                </form>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={4} className={classes.gridForm}>
                {!readonly ? (!person.country && countries) ||
                  <Autocomplete
                    key={'1'}
                    options={countries}
                    fullWidth={true}
                    value={convertCountryCodeToObject(person.country)}
                    getOptionLabel={(country: Countries) => country.name}
                    disableClearable
                    renderInput={(params) =>
                      <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                        <TextField
                          style={{ marginTop: 16, marginBottom: 8 }} {...params}
                          autoComplete="none"
                          label={formFields.country.label}
                          required={formFields.country.required}
                          error={!!errorText.country}
                          helperText={errorText.country || ""}
                          variant="outlined" />
                      </form>
                    }
                    onChange={(e, v) => {
                      v && handleInputChange({ target: { name: "country", value: v.pseudoCode || v.code } }, formFields, person, updatePerson, errorText, setErrorText)
                    }}
                  /> :
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required={formFields.country.required}
                    error={!!errorText.country}
                    helperText={errorText.country || ""}
                    id={`country-${formId}`}
                    value={person.country}
                    label={formFields.country.label}
                    name="country"
                    autoComplete="off"
                    inputProps={{ readOnly: readonly }}
                  />
                }
              </Grid>
              <Grid item xs={12} md={4} className={classes.gridForm}>
                {(person.country === 'US' || (!person.country && person.country === "US")) && !readonly ?
                  <Autocomplete
                    key={'2'}
                    fullWidth={true}
                    options={usStates}
                    value={convertStateCodeToObject(person.state)}
                    disableClearable
                    getOptionLabel={(state: UsStates) => state.name}
                    renderInput={(params) =>
                      <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                        <TextField
                          style={{ marginTop: 16, marginBottom: 8 }} {...params}
                          autoComplete="none"
                          label={formFields.state.label}
                          required={formFields.state.required}
                          error={!!errorText.state}
                          helperText={errorText.state || ""}
                          variant="outlined" />
                      </form>}
                    onChange={(e, v) => {
                      v && handleInputChange({ target: { name: "state", value: v.abbreviation } }, formFields, person, updatePerson, errorText, setErrorText)
                    }}
                  />
                  :
                  <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                    <FormTextField
                      formFields={formFields}
                      errorText={errorText}
                      setErrorText={setErrorText}
                      updatedObject={person}
                      setUpdateObject={updatePerson}
                      name="state"
                      sliceSize={30} inputProps={{ readOnly: readonly }}
                      formId={formId}
                    />
                  </form>
                }
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid item xs={12} className={classes.gridForm}>
                  <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                    <FormTextField
                      formFields={formFields}
                      errorText={errorText}
                      setErrorText={setErrorText}
                      updatedObject={person}
                      setUpdateObject={updatePerson}
                      name="postalCode"
                      sliceSize={20} inputProps={{ readOnly: readonly }}
                      formId={formId}
                    />
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
        <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
          <form noValidate onSubmit={(e) => { e.preventDefault() }}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={person}
              setUpdateObject={updatePerson}
              name="cellPhone"
              sliceSize={20} inputProps={{ readOnly: readonly }}
              formId={formId}
            />
          </form>
        </Grid>
        {hiddenFields.homePhone ? <></> :
          <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
            <form noValidate onSubmit={(e) => { e.preventDefault() }}>
              <FormTextField
                formFields={formFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={person}
                setUpdateObject={updatePerson}
                name="homePhone"
                sliceSize={20} inputProps={{ readOnly: readonly }}
              />
            </form>
          </Grid>
        }
        {hiddenFields.gender ? <></> :
          <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
            <Autocomplete
              key={'1'}
              id={`genderAuto-${formId}`}
              options={editingAthlete ? genderOptions.filter(option => option.allowForAthlete) : genderOptions}
              value={genderOptions.find((g) => g.value === person.gender)}
              getOptionLabel={(gender: Gender) => gender.name}
              disableClearable
              renderInput={(params) =>
                <form noValidate onSubmit={(e) => {
                  e.preventDefault()
                }}>
                  <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} autoComplete="none" label={formFields.gender.label}
                    required={formFields.gender.required} variant="outlined" />
                </form>}
              onChange={(e, v: Gender) => {
                v && handleInputChange({ target: { name: "gender", value: v.value } }, formFields, person, updatePerson, errorText, setErrorText)
              }}
            />
          </Grid>
        }
        <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
          <form noValidate onSubmit={(e) => { e.preventDefault() }}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={person}
              setUpdateObject={updatePerson}
              name="email"
              sliceSize={100} inputProps={{ readOnly: readonly }}
              formId={formId}
            />
          </form>
        </Grid>
        {hiddenFields.birthDate ? <></> :
          <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
            <div className="customDatePickerWidth">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={countryDateFormat}
                margin="normal"
                autoOk={true}
                id={`birthDate-${formId}`}
                label={formFields.birthDate.label}
                readOnly={bdReadOnly}
                value={selectedDate}
                name="birthDate"
                required={formFields.birthDate.required}
                error={!!errorText.birthDate}
                helperText={errorText.birthDate || ""}
                inputVariant="outlined"
                inputProps={{ readOnly: bdReadOnly }}
                onChange={(e) => { handleDateChange(e) }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>
          </Grid>
        }
      </Grid>

      {submitArea}
    </>
  )
}

export default AddEditPerson
