import themis_common from 'store/themis_common_pb'

function buildPerson(personObject: themis_common.Person.AsObject): themis_common.Person {
  const person = new themis_common.Person()
  person.setId(personObject.id)

  if (personObject.name > '') person.setName(personObject.name)
  else if (personObject.preferredFirstName > '') person.setName(`${personObject.preferredFirstName} ${personObject.legalLastName}`)
  else person.setName(`${personObject.legalFirstName} ${personObject.legalLastName}`)

  person.setPreferredFirstName(personObject.preferredFirstName)
  person.setLegalFirstName(personObject.legalFirstName)
  person.setLegalMiddleName(personObject.legalMiddleName)
  person.setLegalLastName(personObject.legalLastName)
  person.setNickName(personObject.nickName)
  person.setTitle(personObject.title)
  person.setSuffix(personObject.suffix)

  person.setAddress1(personObject.address1)
  person.setAddress2(personObject.address2)
  person.setCity(personObject.city)
  person.setState(personObject.state)
  person.setCountry(personObject.country)
  person.setPostalCode(personObject.postalCode)

  person.setEmail(personObject.email)
  person.setCellPhone(personObject.cellPhone)
  person.setHomePhone(personObject.homePhone)

  person.setBirthDate(personObject.birthDate)
  person.setHasBirthCertificate(personObject.hasBirthCertificate)
  person.setGender(personObject.gender)

  person.setUsasfProfilePicture(personObject.usasfProfilePicture)

  person.setIsUsasfGuardian(personObject.isUsasfGuardian)
  person.setUsasfPersonId(personObject.usasfPersonId)

  person.setIsInternal(personObject.isInternal)
  person.setLastScrape(personObject.lastScrape)

  person.setUser(buildUser(personObject.user || { id: 0, name: "", email: "", firstName: "", lastName: "", birthDate: "", lastlogin: "", programowner: false, superuser: false, ipAddress: "", agreedToTerms: true }))
  return person
}

function buildUser(userObject: themis_common.User.AsObject): themis_common.User {
  const user = new themis_common.User()
  user.setId(userObject.id)

  return user
}

function buildProgram(programObject: themis_common.Program.AsObject): themis_common.Program {
  const program = new themis_common.Program()
  program.setId(programObject.id)
  program.setName(programObject.name)
  program.setCountry(programObject.country)
  program.setState(programObject.state)
  program.setCity(programObject.city)
  program.setWebsite(programObject.website)
  program.setPhone(programObject.phone)
  program.setLogo(programObject.logo)
  program.setLegalName(programObject.legalName)
  program.setDba(programObject.dba)
  program.setProgramClassification(programObject.programClassification)
  program.setAddress(programObject.address)
  program.setAddress2(programObject.address2)
  program.setPostalCode(programObject.postalCode)
  program.setDescription(programObject.description)
  return program
}

function buildAthlete(athleteId: number | undefined, guardianId: number | undefined, programId: number, locationId: number, athletePerson: themis_common.Person.AsObject, guardianPerson: themis_common.Person.AsObject, tiers: themis_common.Tier.AsObject[], types: themis_common.AllStarType.AsObject[], validateTeamId?: number): themis_common.Athlete {
  const athleteRequest = new themis_common.Athlete()

  if (athleteId) {
    athleteRequest.setId(athleteId)
  }
  athleteRequest.setProgramId(programId)
  athleteRequest.setLocationId(locationId)
  athleteRequest.setPerson(buildPerson(athletePerson))

  athleteRequest.setName(`${athletePerson.legalFirstName} ${athletePerson.legalLastName}`)

  if (guardianPerson.legalFirstName.length > 0 || guardianPerson.legalLastName.length > 0) {
    const guardian = new themis_common.Guardian()
    if (guardianId) {
      guardian.setId(guardianId)
    }
    guardian.setName(`${guardianPerson.legalFirstName} ${guardianPerson.legalLastName}`)
    guardian.setPerson(buildPerson(guardianPerson))
    athleteRequest.addAthleteGuardians(guardian)
  }

  const location = new themis_common.Location()
  location.setId(locationId)
  athleteRequest.addAthleteLocations(location)

  if (validateTeamId && validateTeamId > 0) athleteRequest.setValidateTeamId(validateTeamId)

  tiers.forEach((tierObject) => {
    const tier = new themis_common.Tier()
    tier.setId(tierObject.id)
    athleteRequest.addAthleteTiers(tier)
  })

  types.forEach((typeObject) => {
    const type = new themis_common.AllStarType()
    type.setId(typeObject.id)
    athleteRequest.addAthleteTypes(type)
  })

  return athleteRequest
}

function buildGuardian(guardianId: number | undefined, programId: number, locationId: number, guardianPerson: themis_common.Person.AsObject): themis_common.Guardian {
  const guardianRequest = new themis_common.Guardian()

  if (guardianId) {
    guardianRequest.setId(guardianId)
  }
  guardianRequest.setPerson(buildPerson(guardianPerson))
  guardianRequest.setName(`${guardianPerson.legalFirstName} ${guardianPerson.legalLastName}`)

  return guardianRequest
}

function buildCoach(coachId: number | undefined, programId: number, locationId: number, coachPerson: themis_common.Person.AsObject, types: themis_common.AllStarType.AsObject[], validateTeamId?: number): themis_common.Coach {
  const coachRequest = new themis_common.Coach()

  if (coachId) {
    coachRequest.setId(coachId)
  }
  coachRequest.setProgramId(programId)
  coachRequest.setLocationId(locationId)
  coachRequest.setPerson(buildPerson(coachPerson))

  coachRequest.setName(`${coachPerson.legalFirstName} ${coachPerson.legalLastName}`)

  const location = new themis_common.Location()
  location.setId(locationId)
  coachRequest.addCoachLocations(location)

  if (validateTeamId && validateTeamId > 0) coachRequest.setValidateTeamId(validateTeamId)

  types.forEach((typeObject) => {
    const type = new themis_common.AllStarType()
    type.setId(typeObject.id)
    coachRequest.addCoachTypes(type)
  })

  return coachRequest
}

function buildPersonnel(personnelId: number | undefined, programId: number, locationId: number, personnelPerson: themis_common.Person.AsObject, validateTeamId?: number): themis_common.Personnel {
  const personnelRequest = new themis_common.Personnel()

  if (personnelId) {
    personnelRequest.setId(personnelId)
  }
  personnelRequest.setProgramId(programId)
  personnelRequest.setLocationId(locationId)
  personnelRequest.setPerson(buildPerson(personnelPerson))

  personnelRequest.setName(`${personnelPerson.legalFirstName} ${personnelPerson.legalLastName}`)

  const location = new themis_common.Location()
  location.setId(locationId)
  personnelRequest.addPersonnelLocations(location)

  if (validateTeamId && validateTeamId > 0) personnelRequest.setValidateTeamId(validateTeamId)

  return personnelRequest
}

export {
  buildPerson,
  buildUser,
  buildProgram,
  buildAthlete,
  buildGuardian,
  buildCoach,
  buildPersonnel,
}
