import React, { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import { Container, Grid, TextField, Typography } from '@material-ui/core'
import { useHistory, useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentLocation, selectCurrentProgram } from "store/program/programSelectors"
import { emptyPerson } from "store/program/programReducer"
import AddEditPerson from "components/program/person/AddEditPerson"
import { Autocomplete } from "@material-ui/lab"
import { selectCurrentCategories } from "store/user/userSelectors"
import { Categories, refreshUser } from "store/user/userActions"
import themis_common from "store/themis_common_pb"
import { addCoachToTeam } from 'store/program/teamActions'
import { addCoach, getCoach, updateCoach } from "store/program/coachActions"
import { coachPersonFields } from "lib/constants"
import YesNoDialog from 'components/util/YesNoModal'
import { capitalizeFirstLetter, fieldsOkay } from 'lib/validators'
import SubmitButton from 'components/util/SubmitButton'

const AddEditCoach: React.FC = (): ReactElement => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { coachId: inputCoachId, teamId: inputTeamId, locationId: inLocationId } = useParams<{coachId?: string | undefined, teamId?: string | undefined, locationId?: string | undefined}>()
  const coachId = Number(inputCoachId)
  const teamId = Number(inputTeamId)
  const locationId = Number(inLocationId)

  const program: themis_common.Program.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProgram(state))
  const location: themis_common.Location.AsObject | undefined = useSelector((state: IAppState) => selectCurrentLocation(state))
  const categories: Categories | undefined = useSelector((state: IAppState) => selectCurrentCategories(state))

  const programId = useMemo(() => program?.id,[program])

  useEffect(() => {
    load()
    async function load() {
      if (!programId || !locationId || !coachId) return
      setCoach(await getCoach(dispatch, coachId, programId, locationId, teamId))
    }
  }, [programId, locationId, coachId, dispatch])

  const [person, setPerson] = useState(emptyPerson)
  const [coach, setCoach] = useState<themis_common.Coach.AsObject | undefined>(undefined)
  const [types, setTypes] = useState<themis_common.AllStarType.AsObject[]>(coach?.coachTypesList || [])

  const [checkCoach, setCheckCoach] = useState(false)

  const locationCountry: string | undefined = useMemo(() => coachId ? undefined : location?.country, [location?.country, coachId])
  const locationState: string | undefined = useMemo(() => coachId ? undefined : location?.state, [location?.state, coachId])

  useEffect(() => {
    if (!coach) return
    setTypes(coach.coachTypesList)
  }, [coach?.coachTypesList, coach])

  useEffect(() => {
    if (!coachId || !coach?.person?.id) return
    setPerson(coach.person)
  }, [coachId, coach, setPerson])

  const create = !coachId ? 'Create' : 'Edit'

  const creating = !coachId ? 'creating' : 'editing'
  const creatingBool = !coachId

  const formReady = useMemo(() => fieldsOkay(person, coachPersonFields), [person])

  const submitForm = async (event: FormEvent) => {
    setCheckCoach(true)
    event.preventDefault()
    if (!programId || !locationId) return

    const dest = teamId ? `/Location/${locationId}/Team/${teamId}` : `/Location/${locationId}`
    if (!formReady) return
    if (coachId) {
      // Editing Coach
      try {
        await updateCoach(dispatch, coachId, person, types, programId, locationId, teamId)
        history.replace(dest + `/Coach/${coachId}`)
      } catch (e) {
        setIsErrorOpen(true)
        console.error(e)
      }
    } else {
      // Creating Coach
      try {
        const coach = await addCoach(dispatch, person, types, programId, locationId)
        await refreshUser(dispatch)
        if (teamId > 0) {
          await addCoachToTeam(dispatch, programId, locationId, teamId, coach.id)
          history.replace(dest + `#coaches`)
        } else {
          history.replace(dest + `/Coach/${coach.id}`)
        }
      } catch (e) {
        setIsErrorOpen(true)
        console.error(e)
      }
    }
  }

  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const headerContents = () => {
    if (coach?.name) {
      return `${create} Coach - ${coach.name}`
    } else {
      return `${create} Coach`
    }
  }

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{headerContents()}</Typography>
        </Grid>

        <Grid item xs={1}/>
        <Grid item xs={10}>
          <div className={classes.paper}>
            <AddEditPerson person={person}
              formId={0}
              updatePerson={setPerson}
              formReady={formReady}
              formFields={coachPersonFields}
              hiddenFields={{}}
              locationCountry={locationCountry}
              locationState={locationState}
              readonly={false}
              checkPerson={checkCoach}
              setCheckPerson={setCheckCoach}
              editBirthDate={false}
              creating={creatingBool}
            />
            <Grid container item xs={12} style={{ marginTop: "8px" }}>
              { !categories?.allStarTypes || !types ? <></> :
                <Grid item sm={12} md={6} className={classes.gridForm}>
                  <Autocomplete
                    multiple
                    options={categories.allStarTypes}
                    getOptionLabel={(option) => option.name}
                    value={types}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={(e, v) => {
                      v && setTypes(v)
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <form noValidate onSubmit={(e) => {e.preventDefault()}}>
                        <TextField
                          {...params}
                          variant="outlined"
                          autoComplete="none"
                          label="Coach Types"
                        />
                      </form>
                    )}
                  />
                </Grid>
              }
            </Grid>
            <Grid container item xs={12}>
              <SubmitButton title={`${person.id ? 'Edit' : 'Create'} Coach`} ariaLabel="submit-coach-form" submitForm={submitForm} />
            </Grid>
          </div>
        </Grid>
        <YesNoDialog
          title={`Error ${capitalizeFirstLetter(creating)} Coach`}
          question={`There was an error ${creating} your coach. Please contact us if this continues to be a problem.`}
          isOpen={isErrorOpen}
          disabled={!formReady}
          onClose={() => setIsErrorOpen(false)}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
          ]}
        />
      </Grid>
    </Container>
  )
}

export default AddEditCoach
