import React, { FunctionComponent, useCallback, useEffect, useState } from 'react' //useMemo
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core'
// import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Save as SaveIcon,
} from '@material-ui/icons'
import { useStyles } from "lib/theme"
import themis_common from "store/themis_common_pb"
import { AnyDispatchType, currencySymbols, regexpNumber } from "lib/constants"
import { addEventDivisionPricing, getRubricsByProducerId, updateEventDivision, updateEventDivisionPricing } from "store/producer/eventActions"
import HelpIcon from 'components/util/HelpIcon'

interface eventPricingDetailsProps {
  eventDivision: themis_common.EventDivision.AsObject
  brandId: number
  producerId: number
  userCanViewEvent: boolean
  userCanEditEvent: boolean
  refreshEvent: Function
  currency: string
  eventId: number
  dispatch: AnyDispatchType
  defaultAthletePrice: string
  // rubrics: themis_common.Rubric.AsObject[] | undefined
}

const emptyRubric: themis_common.Rubric.AsObject = {
  id: 0,
  name: "",
  // formula: "",
  rubricAreasList: [],
  rubricDeductionsList: [],
  archive: 0
}

const EventDetailsPricing: FunctionComponent<eventPricingDetailsProps> = ({ eventDivision, brandId, producerId, userCanEditEvent, userCanViewEvent, refreshEvent, currency, eventId, dispatch, defaultAthletePrice }) => {
  const classes = useStyles()

  const [athletePrice, setAthletePrice] = useState<string>("")
  const [teamPrice, setTeamPrice] = useState<string>("")
  const [currencySymbol, setCurrencySymbol] = useState<string>("$")
  const [dirty, setDirty] = useState<boolean>(false)
  const [inheritAthletePrice, setInheritAthletePrice] = useState<string>("")

  useEffect(() => {
    const symbol = currencySymbols.find(cs => cs.cc === currency)
    if (symbol) setCurrencySymbol(symbol.symbol)
  }, [currency])

  // If cleared, show the default price as placeholder
  useEffect(() => {
    if (
      (athletePrice === "" || athletePrice === "0") &&
      (teamPrice === "" || teamPrice === "0")
    ) {
      setInheritAthletePrice(defaultAthletePrice)
    } else {
      setInheritAthletePrice("")
    }
  }, [athletePrice, teamPrice, defaultAthletePrice])

  useEffect(() => {
    if (!eventDivision.eventDivisionPricing) {
      setAthletePrice("")
      setTeamPrice("")
    } else {
      if (eventDivision.eventDivisionPricing?.athletePriceString.length > 0 && eventDivision.eventDivisionPricing?.athletePriceString !== "0") {
        setAthletePrice(eventDivision.eventDivisionPricing.athletePriceString)
        setTeamPrice("")
      }
      if (eventDivision.eventDivisionPricing?.teamPriceString.length > 0 && eventDivision.eventDivisionPricing?.teamPriceString !== "0") {
        setTeamPrice(eventDivision.eventDivisionPricing.teamPriceString)
        setAthletePrice("")
      }
    }
  }, [eventDivision.eventDivisionPricing, eventDivision.eventDivisionPricing?.athletePriceString, eventDivision.eventDivisionPricing?.teamPriceString])

  // const [eventDivisionRubric, setEventDivisionRubric] = useState<themis_common.Rubric.AsObject>(emptyRubric)

  // Sends duplicate request. Currently handled on backend
  const submitForm = useCallback(() => {
    if (!userCanEditEvent) return
    const doSubmitForm = async () => {
      await addEventDivisionPricing(eventDivision.id, eventId, brandId, producerId, athletePrice, teamPrice)
      await updateEventDivisionPricing(eventDivision.id, athletePrice, teamPrice, dispatch)
      await updateEventDivision(eventDivision.id, eventId, brandId, producerId, eventDivision.nonCrossover)
    }
    setDirty(false)
    doSubmitForm()
  }, [athletePrice, brandId, eventId, producerId, teamPrice, dispatch, eventDivision.id, eventDivision.nonCrossover, userCanEditEvent])

  // Sends duplicate request. Currently handled on backend
  const clearForm = () => {
    if (!userCanEditEvent) return
    const doClearForm = async () => {
      setAthletePrice("")
      setTeamPrice("")
      setDirty(false)
      // setEventDivisionRubric(emptyRubric)
      await addEventDivisionPricing(eventDivision.id, eventId, brandId, producerId, "", "")
      await updateEventDivisionPricing(eventDivision.id, "", "", dispatch)
      await updateEventDivision(eventDivision.id, eventId, brandId, producerId, eventDivision.nonCrossover)
    }
    doClearForm()
  }

  // Do not remove this useMemo despite the es-lint unusued error. This sets the eventDivisionRubric on component init.
  // TODO: find out why a useEffect doesn't do it here.
  // const defaultRubric = useMemo(() => {
  //   if (rubrics) {
  //     const foundRubric = rubrics.find(rubric => rubric.id === eventDivision.rubricId)
  //     if (foundRubric) setEventDivisionRubric(foundRubric)
  //     return rubrics.find(rubric => rubric.id === eventDivision.rubricId)
  //   }
  // }, [rubrics, eventDivision.rubricId])

  return !userCanViewEvent ? <></> : (
    <Grid container item xs={12} spacing={2} style={{ padding: "16px", alignContent: "flex-start" }}  >
      <Grid item xs={4} md={3}>
        <Box position="relative">
          <TextField
            variant="outlined"
            label={"Athlete Registration"}
            fullWidth
            id={`AthletePrice-${eventDivision.id}`}
            disabled={!userCanEditEvent}
            value={athletePrice}
            placeholder={inheritAthletePrice}
            name={"AthletePrice"}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
            }}
            onChange={(e) => {
              if (regexpNumber.test(e.target.value)) {
                setDirty(true)
                setAthletePrice(e.target.value)
                setTeamPrice("")
              } else return false
            }}
          />
          <Box position="absolute" top={15} right={0} zIndex="modal">
            <HelpIcon
              markdownText={`### Division Athlete Price\n*Specific pricing for this division*\n\nIf set, this price is used instead of the default athlete price.  \nCrossover price is still used for crossover athletes`}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4} md={3}>
        <Box position="relative">
          <TextField
            variant="outlined"
            label={"Team Registration"}
            fullWidth
            id={`TeamPrice-${eventDivision.id}`}
            value={teamPrice}
            name={"teamPrice"}
            disabled={!userCanEditEvent}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
            }}
            onChange={(e) => {
              if (regexpNumber.test(e.target.value)) {
                setDirty(true)
                setTeamPrice(e.target.value)
                setAthletePrice("")
              } else return false
            }}
          />
          <Box position="absolute" top={15} right={0} zIndex="modal">
            <HelpIcon
              markdownText={`### Division Team Price\n*Specific pricing for this division*\n\nIf set, teams pay a flat price per team`}
            />
          </Box>
        </Box>
      </Grid>
      {/* <Grid item container xs={4} alignItems="center">
        <Autocomplete
          value={eventDivisionRubric}
          id="rubrics"
          options={rubrics || []}
          getOptionLabel={(rubric: themis_common.Rubric.AsObject) => (rubric.name || '')}
          style={{ width: 225 }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Rubric" />
          )}
          onChange={(_, v, reason) => {
            setDirty(true)
            if (reason === 'clear') setEventDivisionRubric(emptyRubric)
            if (v) setEventDivisionRubric(v)
          }}
        />
      </Grid> */}
      { !userCanEditEvent ? <></> :
        <Grid item>
          <SaveIcon className={classes.saveIcon} color={dirty ? "primary" : "disabled"}/>
          <Button
            type="submit"
            variant="contained"
            size="small"
            className={classes.divisionSubmit}
            onClick={submitForm}
            style={{ marginRight: "8px" }}
          >Save</Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            className={classes.divisionSubmit}
            onClick={clearForm}
          >Clear</Button>
        </Grid>
      }
    </Grid>)

}

export default EventDetailsPricing
