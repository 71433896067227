import React, { useCallback, useMemo, useState } from "react"
import { Box, Button, Grid, Paper, Typography, TextField, Checkbox } from "@material-ui/core"
import { DoneOutline as DoneOutlineIcon, RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import { verifyACHSource } from "store/user/userStripeActions"
import themis_api from "store/themis_api_pb"
import themis_common from "store/themis_common_pb"
import { useStyles } from "lib/theme"
import YesNoDialog from "components/util/YesNoModal"
import { StripeError } from "@stripe/stripe-js"

interface BankAccountDetailsProps {
  useCase: "manage-payment-source" | "select-payment-source"
  bankAccount: themis_common.StripeBankAccount.AsObject
  refreshPaymentSourceList?: () => Promise<void>
  sourceListId?: number
  sourceListIdSelected?: number
  selectSource?: (sourceListId: number, checked: boolean, stripeSourceType: "bank-account" | "credit-card", stripeSourceId: string, sourceDetails: themis_common.StripeBankAccount.AsObject | themis_common.StripeCreditCard.AsObject) => void
}

const BankAccountDetails: React.FC<BankAccountDetailsProps> = ({ useCase, bankAccount, refreshPaymentSourceList, sourceListId, sourceListIdSelected, selectSource }): React.ReactElement => {
  const classes = useStyles()

  const [isErrorOpen, setIsErrorOpen] = useState(false)
  const defaultErrorMessage = `There was an error verifying your bank account. Please contact us if this continues to be a problem.`
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage)

  const [verifyBankAccountAmounts, setVerifyBankAccountAmounts] = useState<themis_api.VerifyACHSourceRequest.AsObject>({
    id: bankAccount.id,
    amount1: 0,
    amount2: 0,
  })

  const isCurrentlySelected: boolean = useMemo(() => {
    return sourceListId === sourceListIdSelected
  }, [sourceListId, sourceListIdSelected])

  const handleSourceSelect = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (selectSource) selectSource(sourceListId ?? 0, checked, "bank-account", bankAccount.id, bankAccount)
  }, [selectSource, sourceListId, bankAccount])

  const formReady = useMemo(() => {
    if (verifyBankAccountAmounts.id.length > 0 && verifyBankAccountAmounts.amount1 > 0 && verifyBankAccountAmounts.amount2 > 0) return true
    return false
  }, [verifyBankAccountAmounts])

  const verifyBankAccount = useCallback(() => {
    (async () => {
      if (formReady) {
        try {
          await verifyACHSource(verifyBankAccountAmounts)
          if (refreshPaymentSourceList) refreshPaymentSourceList()
        } catch (error) {
          console.error("Error verifying Bank Account")
          const errorResponseObject: StripeError | undefined = JSON.parse(error["message"])
          if (errorResponseObject?.message && errorResponseObject.message.length > 0) {
            console.error(errorResponseObject.message)
            setErrorMessage(errorResponseObject.message)
          } else {
            setErrorMessage(defaultErrorMessage)
          }
          setIsErrorOpen(true)
        }
      }
    })()
  }, [formReady, verifyBankAccountAmounts, refreshPaymentSourceList, defaultErrorMessage])

  const verifyBankAccountSection = useMemo(() => {
    if (useCase === "manage-payment-source") {
      if (bankAccount.status === "verified") {
        return (<Box textAlign="center" width="100%">
          <Box style={{ color: "green" }}>Verified</Box>
          <DoneOutlineIcon fontSize="large" style={{ color: "green" }}></DoneOutlineIcon>
        </Box>)
      } else {
        return (<Box textAlign="center" width="100%">
          <form className={classes.form} noValidate>
            <Grid container justify="center" alignItems="center" spacing={1}>
              <Grid item xs={12} md={3} lg={10}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  type="number"
                  required
                  // error={!!errorText.firstName}
                  // helperText={errorText.firstName ?? ""}
                  fullWidth
                  id={`amount1-${sourceListId}`}
                  label="Verification Amount 1"
                  name="amount1"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setVerifyBankAccountAmounts((orig) => { return { ...orig, amount1: Number(e.target.value) } })
                  }}
                  inputProps={{ autoComplete: "off" }}
                // onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                //   e.target.value = e.target.value.slice(0, 100)
                // }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={10}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  type="number"
                  required
                  // error={!!errorText.lastName}
                  // helperText={errorText.lastName ?? ""}
                  fullWidth
                  id={`amount2-${sourceListId}`}
                  label="Verification Amount 2"
                  name="amount2"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setVerifyBankAccountAmounts((orig) => { return { ...orig, amount2: Number(e.target.value) } })
                  }}
                  inputProps={{ autoComplete: "off" }}
                // onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                //   e.target.value = e.target.value.slice(0, 100)
                // }}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={10}>
                <Button variant="outlined" color="primary" onClick={verifyBankAccount}>Verify Bank Account</Button>
              </Grid>
              <YesNoDialog
                title={`Error Verifying Bank Account`}
                question={errorMessage}
                isOpen={isErrorOpen}
                disabled={!formReady}
                onClose={() => setIsErrorOpen(false)}
                buttonActions={[
                  { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
                ]}
              />
            </Grid>
          </form>
        </Box>)
      }
    } else if (useCase === "select-payment-source") {
      if (bankAccount.status === "verified") {
        return (
          <Checkbox
            icon={<RadioButtonUnchecked />}
            checkedIcon={<RadioButtonChecked />}
            checked={isCurrentlySelected}
            onChange={handleSourceSelect}
            inputProps={{ 'aria-label': 'checkbox' }}
          />
        )
      } else {
        return (<Box textAlign="center" width="100%">
          Bank Account Not Verified
        </Box>)
      }
    }
  }, [useCase, bankAccount.status, verifyBankAccount, classes.form, sourceListId, isCurrentlySelected, handleSourceSelect, errorMessage, formReady, isErrorOpen])

  return <>
    <Paper elevation={isCurrentlySelected ? 8 : 2} key={`bank-account-details`}>
      <Box m={1} p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">Bank Account</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={12} lg={9} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Bank Name:</Box>
                <Box>{bankAccount.bankName}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Account Holder Name:</Box>
                <Box>{bankAccount.accountHolderName}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Account Holder Type:</Box>
                <Box>{bankAccount.accountHolderType}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Country:</Box>
                <Box>{bankAccount.country}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Currency:</Box>
                <Box>{bankAccount.currency}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Status:</Box>
                <Box>{bankAccount.status}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Last 4:</Box>
                <Box>{bankAccount.last4}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Routing Number:</Box>
                <Box>{bankAccount.routingNumber}</Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={3} justify="center" alignItems="center">
            {verifyBankAccountSection}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  </>
}

export default BankAccountDetails
