import React, { useEffect, useMemo, useState, ReactElement } from 'react'
import { useHistory } from "react-router-dom"
import { Button, Grid, Paper } from '@material-ui/core'
import themis_common from 'store/themis_common_pb'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { Alert } from "@material-ui/lab"
import { Typography, Accordion, AccordionSummary, AccordionDetails, useTheme } from '@material-ui/core'
import { ProgramPermissions } from "store/user/userReducer"
import { format, parseISO } from 'date-fns'
import { validateProgramPermission, validateTeamPermission } from "lib/permissions"

interface EventListProps {
  permissionCodes: themis_common.PermissionCodes.AsObject
  programPermissions: ProgramPermissions
  superUser: boolean
  programId: number
  teams: ListTeam[]
  eventTeams: themis_common.EventTeam.AsObject[]
  approvedEvents: themis_common.Event.AsObject[]
  locationId?: number
  teamId?: number
  location?: themis_common.Location.AsObject
}

interface ListTeam {
  teamId: number
  teamName: string
  locationId: number
  locationName: string
  team: themis_common.Team.AsObject
}

const EventList: React.FC<EventListProps> = ({
  programPermissions, programId, locationId, teamId, location,
  superUser, permissionCodes, teams, approvedEvents
}): ReactElement => {

  const history = useHistory()
  const theme = useTheme()

  const [locations, setLocations] = useState<themis_common.Location.AsObject[]>([])
  const [locationsGenerated, setLocationsGenerated] = useState<boolean>(false)

  const userCanRegisterTeam = useMemo(() => {
    return (locationId && teamId && validateTeamPermission(permissionCodes.teamRegister, permissionCodes, superUser, programPermissions, programId, locationId, teamId)) ||
      validateProgramPermission(permissionCodes.teamRegister, permissionCodes, superUser, programPermissions, programId)
  }, [locationId, programId, programPermissions, superUser, teamId, permissionCodes])


  const sortedApprovedEvents = useMemo(() => {
    if (!approvedEvents) return []

    const firstApprovedEvents = approvedEvents.map(event => { return { ...event, eventDatesList: event.eventDatesList.sort((a, b) => { if (a.startDay > b.startDay) return 1; return -1}) }})
    const sortApprovedEvents = [...firstApprovedEvents]
    sortApprovedEvents
      .sort((a, b) => {
        if (a.eventDatesList[0].startDay < b.eventDatesList[0].startDay) {
          return -1
        }
        if (a.eventDatesList[0].startDay > b.eventDatesList[0].startDay) {
          return 1
        }
        return 0
      })
    return sortApprovedEvents
  }, [approvedEvents])

  // Generate a list of locations based on teams array
  useEffect(() => {
    const allLocations: themis_common.Location.AsObject[] = []
    teams.forEach((team: any) => {
      let teamLocation: themis_common.Location.AsObject | undefined = allLocations.find(location => location.id === team.locationId)
      if (!teamLocation) {
        teamLocation = {
          id: team.locationId,
          name: team.locationName,
          country: "",
          state: "",
          city: "",
          address: "",
          address2: "",
          postalCode:  "",
          phone: "",
          email: "",
          cellPhone: "",
          secondaryPhone: "",
          lastScrape: "",
          usasfLocationId: "",
          teamsList: [],
          athletesList: [],
          coachesList: [],
          personnelList: [],
          programId: 0,
          guardiansList: []
        }
        allLocations.push(teamLocation)
      }
      teamLocation?.teamsList.push(team)
    })
    setLocationsGenerated(true)
    setLocations(allLocations)
  }, [teams, location])

  const eventsList = useMemo(() => {
    return (
      sortedApprovedEvents.map((columnEvent) =>  {
        return (
          <Grid container>
            <Grid item xs={4} component={Paper} style={{ border: "1px solid gray" }}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {format(parseISO(columnEvent.eventDatesList[0].startDay.substr(0, 10)), 'MMM dd, yyyy')} - {columnEvent.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {columnEvent.brand?.name} - {columnEvent.brand?.producer?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              { locations.map((location) => {
                if (locationsGenerated) {
                  return (
                    <Accordion style={{ border: "1px solid gray", backgroundColor: "transparent", margin: "0 auto" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{location.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          {location.teamsList.map((team: any) => {
                            const registrationInfo = columnEvent.teamsList.find((eventTeam) => eventTeam.teamId === team.teamId)
                            if (registrationInfo) {
                              return (
                                <Grid item xs={12} md={4} sm={6}>
                                  {/* TODO: Find a way to show red in our primray theme color? i.e. color="primary" currently shows a white button */}
                                  <Button size="small" variant="contained" fullWidth style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }} onClick={() => {
                                    if (userCanRegisterTeam) history.push(`/Registration/${location.id}/team/${team.teamId}/${columnEvent.id}/${registrationInfo.id}`)
                                  }}>
                                    Edit {team.teamName}
                                  </Button>
                                </Grid>
                              )
                            } else {
                              return (
                                <Grid item xs={12} md={4} sm={6}>
                                  <Button size="small" variant="contained" fullWidth style={{ backgroundColor: "green", color: "white" }} onClick={() => {
                                    if (userCanRegisterTeam) history.push(`/Register/${location.id}/team/${team.teamId}/${columnEvent.id}`)
                                  }}>
                                    Register {team.teamName}
                                  </Button>
                                </Grid>
                              )
                            }
                          })
                          }
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )
                }
                return []
              })
              }
            </Grid>
          </Grid>
        )
      })
    )
  }, [sortedApprovedEvents, locations, locationsGenerated, userCanRegisterTeam, history, theme])

  return (
    <Grid container style={{ border: "1px solid gray", width: "100%" }}>
      <Grid item xs={12}>
        {sortedApprovedEvents.length > 0 ? eventsList : <Alert severity="warning">No events match your filter.</Alert>}
      </Grid>
    </Grid>
  )
}

export default EventList
