import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import { useStyles } from "lib/theme"

import { useHistory, useParams } from "react-router-dom"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import themis_api from "store/themis_api_pb"
import { client } from "store/themisClient"
import { refreshUser } from "store/user/userActions"
import YesNoDialog from "components/util/YesNoModal"

const ScrapeUSASF: React.FC = (): ReactElement => {
  const classes = useStyles()
  const history = useHistory()

  const { programId: inProgramId } = useParams<{ programId: string }>()
  const programId = inProgramId ? Number(inProgramId) : 0

  const [dialogOpen, setDialogOpen] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [scrapeError, setScrapeError] = useState("")
  const [scrapeErrorType, setScrapeErrorType] = useState("")
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [programStatus, setProgramStatus] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    setDialogOpen(true)
  }, [])

  const handleScrapeProgram = async () => {
    setProgramStatus(1)

    const scrapeRequest = new themis_api.ScrapeRequest()
    scrapeRequest.setEmail(email)
    scrapeRequest.setPassword(password)
    scrapeRequest.setProgramId(programId)
    try {
      await client.scrapeProgram(scrapeRequest, {})
      setProgramStatus(2)
      await refreshUser(dispatch)
      setProgramStatus(3)
    } catch (err) {
      setProgramStatus(0)
      setScrapeError("There was an issue importing USASF data")
      setScrapeErrorType("Program")
      setErrorDialogOpen(true)
    }
  }

  const doDone = async () => {
    history.push(`/`)
  }

  return (
    <>
      <CssBaseline/>
      <Grid container>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h1">Import from USASF</Typography>
          </Grid>
          <Grid item xs={12}>
            <Container maxWidth="xs">

              <div className={classes.paper}>
                <form className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="USASF Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={e => setEmail(e.target.value)}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="USASF Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={e => setPassword(e.target.value)}
                  />
                </form>
              </div>
              <Box mt={3}>
                <Card className={classes.divider}>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      Import Program
                    </Typography>
                    <Box mt={2}>
                      <Typography variant="body2" component="p">
                        Import data from your USASF account
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <Typography>{programId > 0 ? "This will update and modify your program. Some data may be overwritten!" : "" }</Typography>
                    </Box>
                    <Box mt={2}>
                      <Typography color="error">{scrapeError.length > 0 ? scrapeError : "" }</Typography>
                    </Box>
                    <Grid item xs={12}>
                      {programStatus === 1 && <span><CircularProgress size={16} /> Importing... This may take up to 45 minutes. Feel free to close this page.</span>}
                      {programStatus === 2 && <span><CircularProgress variant="determinate" value={80} size={16} /> Imported...</span>}
                      {programStatus === 3 && <span><CircularProgress variant="determinate" value={100} size={16} /> Successfully Imported Data </span>}
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button color="primary" variant="outlined" disabled={!email || !password || programStatus > 0} onClick={(_e) => {
                      setScrapeError("")
                      setScrapeErrorType("")
                      setErrorDialogOpen(false)
                      handleScrapeProgram()
                    }}>Import Data</Button>
                  </CardActions>
                </Card>
              </Box>

            </Container>
          </Grid>
        </Grid>
      </Grid>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Button color="primary" variant="outlined" disabled={programStatus !== 3} onClick={(_e) => {doDone()}}>Done</Button>
        </div>
      </Container>
      <YesNoDialog
        title={"Error Importing Data"}
        question={`There was an issue retrieving the ${scrapeErrorType} data from your USASF account.  Please check your email and password and try again.`}
        isOpen={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setErrorDialogOpen(false) },
        ]}
      />
      <YesNoDialog
        title={`Warning: Updating Changes Data`}
        question={`The data from your USASF account will be use to update matched information on your account or if there is no
        matched data to create new information.
        This may include programs, locations, coaches, athletes, teams, and other types of information.
        Are you sure you want to proceed?`}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        buttonActions={[
          { name: "Yes", color: "primary", callback: () => setDialogOpen(false) },
          { name: "No", color: "primary", callback: () => history.push('/') },
        ]}
      />

    </>)
}

export default ScrapeUSASF
