import React, { useEffect, useState, useMemo, ReactElement, FormEvent } from 'react'
import { Countries, countries, FormFields, UsStates, usStates } from "lib/constants"
import { useStyles } from "lib/theme"
import { capitalizeFirstLetter, handleInputChange } from "lib/validators"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Container, Grid, TextField, Typography } from '@material-ui/core'
import themis_common from "store/themis_common_pb"
import YesNoDialog from 'components/util/YesNoModal'
import FormTextField from 'components/util/FormTextField'
import SubmitButton from "components/util/SubmitButton"
import { AddLogo } from "components/util/AddLogo"
import { logoUploadContext, logoUploadDefault } from "components/util/LogoUploadContext"



interface AddEditProducerProps {
  producer: themis_common.Producer.AsObject | undefined
  isSuperUser: boolean
  producerId: number
  logoState: { id: number; addLogoType: string; oldLogo: string; fileSelected: boolean; base64File: string; fileName: string; maxFileSizeExceeded: boolean }
  setLogoState: React.Dispatch<React.SetStateAction<{ id: number; addLogoType: string; oldLogo: string; fileSelected: boolean; base64File: string; fileName: string; maxFileSizeExceeded: boolean }>>
  updatedProducer: themis_common.Producer.AsObject
  setUpdatedProducer: React.Dispatch<React.SetStateAction<themis_common.Producer.AsObject>>
  errorText: { [key: string]: string; }
  setErrorText: React.Dispatch<React.SetStateAction<{
    [key: string]: string;
  }>>
  checkProducer: boolean
  setCheckProducer: React.Dispatch<React.SetStateAction<boolean>>
  isErrorOpen: boolean
  setIsErrorOpen: React.Dispatch<React.SetStateAction<boolean>>
  defaultCountry: Countries
  defaultState: UsStates
  locationResponse: any
  formFields: FormFields
  submitForm: (event: FormEvent) => Promise<void>
}

const AddEditProducer: React.FC<AddEditProducerProps> = ({ producer, isSuperUser, producerId, logoState, setLogoState,
  updatedProducer, setUpdatedProducer, errorText, setErrorText,
  checkProducer, setCheckProducer, isErrorOpen, setIsErrorOpen, defaultCountry, locationResponse, formFields, submitForm }):
  ReactElement => {
  const classes = useStyles()
  const create = !producerId ? 'Create' : 'Edit'
  const creating = !producerId ? 'creating' : 'editing'
  const headerContents = () => {
    if (producer?.name) {
      return `${create} Producer - ${producer.name}`
    } else {
      return `${create} Producer`
    }
  }

  const findState = (stateCode?: string) => {
    return usStates.find((state) => state.abbreviation === stateCode?.slice(3, 5))
  }

  const findCountry = (countryCode?: string) => {
    return countries.find((country) => country.code === countryCode)
  }
  const flashDefault = useMemo(() => {
    if (!updatedProducer.id) {
      if (updatedProducer.postalCode === "") { updatedProducer.postalCode = locationResponse?.postcode ?? "" }
      if (updatedProducer.city === "") { updatedProducer.city = locationResponse?.city ?? "" }
      if (updatedProducer.state === "" || "AL") { updatedProducer.state = findState(locationResponse?.principalSubdivisionCode)?.abbreviation ?? "AL" }
      if (updatedProducer.country === "") { updatedProducer.country = "" + findCountry(locationResponse?.countryCode) }
    }
    return (<></>)
  }, [locationResponse])

  const defaultState = useMemo(() => {
    if (updatedProducer.state) {
      return usStates.find((state) => state.abbreviation === updatedProducer?.state) || usStates[0]
    }
    else {
      return findState(locationResponse?.principalSubdivisionCode) ?? usStates[0]
    }
  }, [updatedProducer.state])




  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{headerContents()}</Typography>
        </Grid>
      </Grid>
      {!isNaN(producerId) || isSuperUser ?
        <Grid container>
          {/* Name row */}
          <Grid container>
            <Grid item xs={12} sm={6} md={6} className={classes.gridForm}>
              <FormTextField
                formFields={formFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={updatedProducer}
                setUpdateObject={setUpdatedProducer}
                name="name"
                autoFocus
                sliceSize={100}
              />
            </Grid>
          </Grid>
          {/* Address row */}
          {/* City, country, state row */}
          <Grid container>
            {/* Address row */}
            <Grid container>
              <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
                <FormTextField
                  formFields={formFields}
                  errorText={errorText}
                  setErrorText={setErrorText}
                  updatedObject={updatedProducer}
                  setUpdateObject={setUpdatedProducer}
                  name="address"
                  sliceSize={200} />
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
                <FormTextField
                  formFields={formFields}
                  errorText={errorText}
                  setErrorText={setErrorText}
                  updatedObject={updatedProducer}
                  setUpdateObject={setUpdatedProducer}
                  name="address2"
                  sliceSize={200} />
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
                <FormTextField
                  formFields={formFields}
                  errorText={errorText}
                  setErrorText={setErrorText}
                  updatedObject={updatedProducer}
                  setUpdateObject={setUpdatedProducer}
                  name="city"

                  sliceSize={40} />
              </Grid>

            </Grid>
            {/* City, country, state row */}
            <Grid container>
              <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
                <FormTextField
                  formFields={formFields}
                  errorText={errorText}
                  setErrorText={setErrorText}
                  updatedObject={updatedProducer}
                  setUpdateObject={setUpdatedProducer}
                  name="postalCode"

                  sliceSize={20} />
              </Grid>
              {!updatedProducer?.country && producer?.country ? <></> :
                <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
                  <Autocomplete
                    key={'1'}
                    options={countries}
                    fullWidth={true}
                    value={defaultCountry}
                    getOptionLabel={(country: Countries) => country.name}
                    disableClearable
                    renderInput={(params) =>
                      <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                        <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} autoComplete="none" label={formFields.country.label} required={formFields.country.required} variant="outlined" />
                      </form>
                    }
                    onChange={(e, v) => {
                      v && handleInputChange({ target: { name: "country", value: v.pseudoCode || v.code } }, formFields, updatedProducer, setUpdatedProducer, errorText, setErrorText)
                    }}
                  />
                </Grid>
              }

              {updatedProducer.country === 'US' || !updatedProducer?.country ?
                <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
                  <Autocomplete
                    key={'2'}
                    options={usStates}
                    fullWidth={true}
                    value={defaultState}
                    disableClearable
                    getOptionLabel={(state: UsStates) => state.name}
                    renderInput={(params) => <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} label={formFields.state.label} required={formFields.state.required} variant="outlined" />}
                    onChange={(e, v) => {
                      v && handleInputChange({ target: { name: "state", value: v.abbreviation } }, formFields, updatedProducer, setUpdatedProducer, errorText, setErrorText)
                    }}
                  />
                </Grid>
                :
                <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
                  <FormTextField
                    formFields={formFields}
                    errorText={errorText}
                    setErrorText={setErrorText}
                    updatedObject={updatedProducer}
                    setUpdateObject={setUpdatedProducer}
                    name="state"

                    sliceSize={30} />
                </Grid>
              }
            </Grid>
            {/* URLs row */}
            <Grid container>
              <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
                <logoUploadContext.Provider value={{ logoState, setLogoState }}>
                  <AddLogo />
                </logoUploadContext.Provider>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
              <FormTextField
                formFields={formFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={updatedProducer}
                setUpdateObject={setUpdatedProducer}
                name="website"
                sliceSize={200} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
              <FormTextField
                formFields={formFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={updatedProducer}
                setUpdateObject={setUpdatedProducer}
                name="phone"
                sliceSize={20} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
              <FormTextField
                formFields={formFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={updatedProducer}
                setUpdateObject={setUpdatedProducer}
                name="email"
                sliceSize={100} />
            </Grid>
          </Grid>
          <SubmitButton title={`${create.toUpperCase()} Producer`} ariaLabel="submit-producer-form" submitForm={submitForm} />
        </Grid> : <></>}

      <YesNoDialog title={`Error ${capitalizeFirstLetter(creating)} Producer`} question={`There was an error ${creating} your producer. Please contact us if this continues to be a problem.`}
        isOpen={isErrorOpen} onClose={() => setIsErrorOpen(false)} buttonActions={[{ name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) }]} />
    </Container>
  )
}

export default AddEditProducer
