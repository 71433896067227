import { client } from "store/themisClient"
import themis_api from "store/themis_api_pb"
import themis_common from "store/themis_common_pb"



export const getEventsReport = async (producerId: number, startDate: string, endDate: string, brandId?: number ): Promise<themis_api.BasicReportResponse.AsObject> => {
  const reportRequest = new themis_api.ReportRequest()
  reportRequest.setProducerId(producerId)
  if (brandId) reportRequest.setBrandId(brandId)
  reportRequest.setStartDate(startDate)
  reportRequest.setEndDate(endDate)
  const response: themis_api.BasicReportResponse.AsObject = (await client.basicEventsReport(reportRequest, {})).toObject()
  return response
}

export const getDetailEventReport = async (eventId: number): Promise<themis_common.EventRegistrationReport.AsObject> => {
  const reportRequest = new themis_api.ReportRequest()
  reportRequest.setEventId(eventId)
  const response: themis_common.EventRegistrationReport.AsObject = (await client.detailEventReport(reportRequest, {})).toObject()
  return response
}

export const getDetailEventReportByPrograms = async (eventId: number): Promise<themis_common.EventRegistrationByProgramReport.AsObject> => {
  const reportRequest = new themis_api.ReportRequest()
  reportRequest.setEventId(eventId)
  const response: themis_common.EventRegistrationByProgramReport.AsObject = (await client.detailEventReportByProgram(reportRequest, {})).toObject()
  return response
}

export const getDetailProducerProgramCreditReport = async (producerId: number): Promise<themis_common.ProducerProgramCreditReport.AsObject> => {
  const reportRequest = new themis_api.ReportRequest()
  reportRequest.setProducerId(producerId)
  const response: themis_common.ProducerProgramCreditReport.AsObject = (await client.detailProducerProgramCreditReport(reportRequest, {})).toObject()
  return response
}

export const getConnectedProgramsReport = async (producerId: number): Promise<themis_api.ProgramResponse.AsObject> => {
  const producer = new themis_common.Producer()
  producer.setId(producerId)
  const response: themis_api.ProgramResponse.AsObject = (await client.getConnectedProgramsReport(producer, {})).toObject()
  return response
}