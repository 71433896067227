import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from "react-redux"
import {
  Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon,
  RadioButtonChecked as SelectedIcon,
  RadioButtonUnchecked as NotSelectedIcon,
} from '@material-ui/icons'
import { useStyles } from "lib/theme"
import { IAppState } from "store/store"
import themis_common from "store/themis_common_pb"
import { deleteObjectUser, PermissionLists } from "store/user/userActions"
import { selectPermissionCodes, selectSuperUser, selectUserProducerPermissions, selectUserProgramPermissions } from "store/user/userSelectors"
import { ObjectUserPermissions } from "store/program/programReducer"
import { Link as RouterLink } from "react-router-dom"
import YesNoDialog from "components/util/YesNoModal"
import { getProgramUsers } from "store/program/programActions"
import { getLocationUsers } from "store/program/locationActions"
import { getTeamUsers } from "store/program/teamActions"
import { getProducerUsers } from "store/producer/producerActions"
import { getEventUsers } from "store/producer/eventActions"
import { getBrandUsers } from "store/producer/brandActions"
import { Typography, Paper, IconButton, Container, Grid, Box } from "@material-ui/core"
import UserGridDetails from "./UserGridDetails"
import {
  validateProgramPermission,
  validateLocationPermission,
  validateTeamPermission,
  validateEventPermission, validateBrandPermission, validateProducerPermission
} from 'lib/permissions'
import HelpIcon from 'components/util/HelpIcon'


interface userGridProps {
  objectId: number,
  type: string,
  permissionLists: PermissionLists,
  superObjectId?: number,
  superDuperObjectId?: number
}

const UserGrid: FunctionComponent<userGridProps> = ({ objectId, type, permissionLists: incomingPermissionLists , superObjectId, superDuperObjectId }) => {
  const classes = useStyles()

  const [permissions, setPermissions] = useState<themis_common.Permission.AsObject[]>([])
  const [userPermissions, setUserPermissions] = useState<ObjectUserPermissions[]>([])
  const [toDelete, setToDelete] = useState<ObjectUserPermissions | null>(null)
  const [selectedUser, setSelectedUser] = useState<ObjectUserPermissions>()

  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const producerPermissions = useSelector((state: IAppState) => selectUserProducerPermissions(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const userStateStorage = localStorage.getItem('programOrProducer') || 'program'
  const programOrProducer = userStateStorage === 'program'

  const refreshPermissions = useCallback(async () => {
    let up: ObjectUserPermissions[] = []
    if (type === "program") up = await getProgramUsers(objectId)
    else if (type === "location" && superObjectId) up = await getLocationUsers(objectId, superObjectId)
    else if (type === "team" && superObjectId && superDuperObjectId) up = await getTeamUsers(objectId, superObjectId, superDuperObjectId)
    else if (type === "producer") up = await getProducerUsers(objectId)
    else if (type === "brand" && superObjectId) up = await getBrandUsers(objectId, superObjectId)
    else if (type === "event" && superObjectId && superDuperObjectId) up = await getEventUsers(objectId, superObjectId, superDuperObjectId)
    setUserPermissions(up)
    const user = up.find(user => user.user.id === selectedUser?.user.id)
    setSelectedUser(user)
  }, [type, objectId, superObjectId, superDuperObjectId, selectedUser?.user.id])

  // This sets the list of possible permissions and refreshes the users and their permissions
  useEffect(() => {
    refreshPermissions()
    if (type === "program") setPermissions(incomingPermissionLists.program)
    else if (type === "location") setPermissions(incomingPermissionLists.location)
    else if (type === "team") setPermissions(incomingPermissionLists.team)
    else if (type === "producer") setPermissions(incomingPermissionLists.producer)
    else if (type === "brand") setPermissions(incomingPermissionLists.brand)
    else if (type === "event") setPermissions(incomingPermissionLists.event)
  }, [objectId, superObjectId, superDuperObjectId, incomingPermissionLists, type, refreshPermissions])

  const doDelete = useCallback(async(userId: number) => {
    setToDelete(null)
    await deleteObjectUser(type, objectId, userId, superObjectId, superDuperObjectId)
    await refreshPermissions()
  }, [type, objectId, superObjectId, superDuperObjectId, refreshPermissions])

  const sortedUsers: ObjectUserPermissions[] = useMemo(() => {
    if (!userPermissions) return []
    const sortUsers = [...userPermissions]
    sortUsers.sort((a, b) => a.user.name < b.user.name ? -1 : 1)
    return sortUsers
  }, [userPermissions])

  const addUserURL = useMemo(() => {
    if (superDuperObjectId) return `/AddUser/${type}/${objectId}/${superObjectId}/${superDuperObjectId}`
    if (superObjectId) return `/AddUser/${type}/${objectId}/${superObjectId}`
    return `/AddUser/${type}/${objectId}`
  }, [objectId, superDuperObjectId, superObjectId, type])
  const editUserURL = useCallback((userId: number) => {
    if (superDuperObjectId) return `/EditUser/${type}/${objectId}/${superObjectId}/${superDuperObjectId}/${userId}`
    if (superObjectId) return `/EditUser/${type}/${objectId}/${superObjectId}/${userId}`
    return `/EditUser/${type}/${objectId}/${userId}`
  }, [objectId, superDuperObjectId, superObjectId, type])

  const addUserIcon = useMemo(() => {
    if (
      (type === "program" && validateProgramPermission(permissionCodes.programUserAdd, permissionCodes, superUser, programPermissions, objectId)) ||
      (type === "location" && validateLocationPermission(permissionCodes.locationUserAdd, permissionCodes, superUser, programPermissions, superObjectId || 0 , objectId)) ||
      (type === "team" && validateTeamPermission(permissionCodes.teamUserAdd, permissionCodes, superUser, programPermissions, superDuperObjectId || 0, superObjectId || 0 , objectId)) ||
      (type === "producer" && validateProducerPermission(permissionCodes.producerUserAdd, permissionCodes, superUser, producerPermissions, objectId)) ||
      (type === "brand" && validateBrandPermission(permissionCodes.brandUserAdd, permissionCodes, superUser, producerPermissions, superObjectId || 0 , objectId)) ||
      (type === "event" && validateEventPermission(permissionCodes.eventUserAdd, permissionCodes, superUser, producerPermissions, superDuperObjectId || 0, superObjectId || 0 , objectId))
    ) {
      return (
        <IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={addUserURL}>
          <AddIcon fontSize="small"/>
        </IconButton>
      )
    }
    return []
  }, [addUserURL, classes.clickable, superUser, superDuperObjectId, superObjectId, objectId, programPermissions, type, permissionCodes])

  const privilegesHelpText = `
  ### Privileges
  Each area ${programOrProducer ? '(Program, Location, and Team)' : '(Producer, Brand, and Event)'} has its own user permissions table.
  You may grant specific ${type} privileges to your users here.

  Permissions set at the ${programOrProducer ? 'program' : 'producer'} table will be inherited by ${programOrProducer ? 'the locations and teams of the program' : 'the brands and events of the producer'} (if the permissions are empty in those areas).
  `

  const userHelpText = `
  ### User
  Add a permission user to your ${type} **(Permission users don't always exist on each area)**.
  
  If the user already exists, they will appear as soon as you add them. If the user does not exist, they will appear after they click their invite email link and register.
  `

  // Include some help messages for adding a permission user and that permission users don’t always exist at each level

  const UsersGrid = useMemo(() => {
    if (!sortedUsers) return (<></>)
    return (
      <Container component={Paper}>
        <Grid container className={classes.userGrid}>
          <Grid container className={classes.userHeader}>
            <Grid item className={classes.userPadding} xs={4}>
              <Grid container>
                <Typography>User</Typography>
                <Box position="relative" bottom={5} left={5} zIndex="modal">
                  <HelpIcon
                    markdownText={userHelpText}
                    boxSize="small"
                    iconSize="small"
                    tooltipText="Click for info"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item className={classes.userGridRight} xs={8}>
              <Grid container>
                <Typography>Privileges</Typography>
                <Box position="relative" bottom={5} left={5} zIndex="modal">
                  <HelpIcon
                    markdownText={privilegesHelpText}
                    boxSize="small"
                    iconSize="small"
                    tooltipText="Click for info"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={4}>
            {sortedUsers.map((user) => {
              return (
                <Grid item container xs={12} className={classes.userGridLeft} key={`user--${user.user.id}`}>
                  <Grid item xs={8}>
                    <Typography>
                      {user.user.name}
                      <Grid item xs={12}>
                        {
                          (type === "program" && validateProgramPermission(permissionCodes.programUserEdit, permissionCodes, superUser, programPermissions, objectId)) ||
                          (type === "location" && validateLocationPermission(permissionCodes.locationUserEdit, permissionCodes, superUser, programPermissions, superObjectId || 0 , objectId)) ||
                          (type === "team" && validateTeamPermission(permissionCodes.teamUserEdit, permissionCodes, superUser, programPermissions, superDuperObjectId || 0, superObjectId || 0 , objectId)) ||
                          (type === "producer" && validateProducerPermission(permissionCodes.producerUserEdit, permissionCodes, superUser, producerPermissions, objectId)) ||
                          (type === "brand" && validateBrandPermission(permissionCodes.brandUserEdit, permissionCodes, superUser, producerPermissions, superObjectId || 0 , objectId)) ||
                          (type === "event" && validateEventPermission(permissionCodes.eventUserEdit, permissionCodes, superUser, producerPermissions, superDuperObjectId || 0, superObjectId || 0 , objectId))
                            ?
                            (
                              <IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={editUserURL(user.user.id)}>
                                <EditIcon fontSize="small"/>
                              </IconButton>
                            ) : <></>
                        }
                        {
                          (type === "program" && validateProgramPermission(permissionCodes.programUserDelete, permissionCodes, superUser, programPermissions, objectId)) ||
                          (type === "location" && validateLocationPermission(permissionCodes.locationUserDelete, permissionCodes, superUser, programPermissions, superObjectId || 0 , objectId)) ||
                          (type === "team" && validateTeamPermission(permissionCodes.teamUserDelete, permissionCodes, superUser, programPermissions, superDuperObjectId || 0, superObjectId || 0 , objectId)) ||
                          (type === "producer" && validateProducerPermission(permissionCodes.producerUserDelete, permissionCodes, superUser, producerPermissions, objectId)) ||
                          (type === "brand" && validateBrandPermission(permissionCodes.brandUserDelete, permissionCodes, superUser, producerPermissions, superObjectId || 0 , objectId)) ||
                          (type === "event" && validateEventPermission(permissionCodes.eventUserDelete, permissionCodes, superUser, producerPermissions, superDuperObjectId || 0, superObjectId || 0 , objectId))
                            ?
                            (
                              <IconButton className={classes.clickable} aria-label="delete" onClick={_e => {setToDelete(user)}}>
                                <DeleteIcon fontSize="small"/>
                              </IconButton>
                            ) : <></>
                        }
                      </Grid>
                      <a className={classes.cleanLink} href={`mailto:${user.user.email}`}>{user.user.email}</a>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    { user.user.id === selectedUser?.user.id ?
                      <IconButton className={classes.clickable} aria-label="selected">
                        <SelectedIcon />
                      </IconButton>
                      :
                      <IconButton className={classes.clickable} aria-label="select" onClick={() => {setSelectedUser(user)}}>
                        <NotSelectedIcon />
                      </IconButton>
                    }
                  </Grid>
                </Grid>
              )
            }
            )}
          </Grid>
          <Grid className={classes.userGridRight} item container xs={8}>
            {!selectedUser ? [] :
              <UserGridDetails permissions={permissions} currentUser={selectedUser}
                refreshPermissions={refreshPermissions} type={type} objectId={objectId}
                superDuperObjectId={superDuperObjectId} superObjectId={superObjectId}/>
            }
          </Grid>
        </Grid>
      </Container>
    )
  }, [sortedUsers, classes.userGrid, classes.userHeader, classes.userPadding, classes.userGridRight, classes.userGridLeft, classes.clickable, classes.cleanLink, selectedUser, permissions, objectId, refreshPermissions, type, superDuperObjectId, superObjectId, editUserURL, programPermissions, superUser, permissionCodes])

  return ( permissionCodes?.denyAccess.length ?
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Typography style={{ marginRight: "25px" }} variant="h2">
            Users
            {addUserIcon}
          </Typography>
        </Grid>
        {UsersGrid}
      </Grid>
      <YesNoDialog
        title={`Delete User`}
        question={`Are you sure you want to delete ${toDelete?.user.name}?`}
        isOpen={!!toDelete?.user.name}
        onClose={() => setToDelete(null)}
        buttonActions={[
          { name: "Yes", color: "secondary", callback: () => doDelete(toDelete?.user.id || 0) },
          { name: "No", color: "primary", callback: () => setToDelete(null) },
        ]}
      />
    </Container> : <></> )
}

export default UserGrid
