import React, { ReactElement, useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { BalanceTotals } from "./BalanceContainer"
import { addCommas } from "../../../lib/functions"
import { useStyles } from "../../../lib/theme"

interface BalanceEventsProps {
  balanceTotals: BalanceTotals
  selectedToPay: string
  setSelectedToPay: Function
}

const BalanceEvents: React.FC<BalanceEventsProps> = ({ balanceTotals, selectedToPay, setSelectedToPay }): ReactElement => {
  const classes = useStyles()

  const handleChangeSelectedToPay = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value
    setSelectedToPay(newValue)
  }

  const eventGrid = useMemo(() => (
    <FormControl component="fieldset"><RadioGroup aria-label="Select Producer, Brand, or Event to pay for"
      name="selectedToPay" value={selectedToPay}
      onChange={handleChangeSelectedToPay}>{
        balanceTotals.producers.map((producer, i) => {
          return (<Grid key={`producer-${i}`} container item xs={12}>
            <FormControlLabel value={`${producer.id}`} control={<Radio/>} label={
              <Typography variant="h3">{producer.name} {
                producer.balances.map((balance, k) => {
                  const amount = addCommas((balance.balance / 100).toFixed(2))
                  return <span key={`producerBalance-${i}-${k}`}> - {amount} {balance.currency}</span>
                })
              } </Typography>}
            />
            {producer.brands.map((brand, j) => {
              return (<Grid key={`brand-${j}`} item container xs={12}>
                <Grid item xs={1}>&nbsp;</Grid>
                <Grid container item xs={11}>
                  <FormControlLabel value={`${producer.id}/${brand.id}`} control={<Radio/>} label={
                    <Typography variant="h3">{brand.name} {
                      brand.balances.map((balance, l) => {
                        const amount = addCommas((balance.balance / 100).toFixed(2))
                        return <span key={`brandBalance-${i}-${j}-${l}`}> - {amount} {balance.currency} </span>
                      })
                    }</Typography>
                  }/>
                  {brand.events.map((event, k) => {
                    return (<Grid key={`event-${k}`} item container xs={12}>
                      <Grid item xs={1}>&nbsp;</Grid>
                      <Grid container item xs={11}>
                        <FormControlLabel value={`${producer.id}/${brand.id}/${event.id}`} control={<Radio/>} label={
                          <Typography variant="h3">{event.name} {
                            event.balances.map((balance) => {
                              const amount = addCommas((balance.balance / 100).toFixed(2))
                              return <span key={`eventBalance-${i}`}> - {amount} {balance.currency} </span>
                            })
                          }</Typography>
                        }/>
                      </Grid>
                    </Grid>)
                  })}
                </Grid>
              </Grid>)
            })}
          </Grid>)
        })
      }</RadioGroup></FormControl>
  ), [balanceTotals.producers, selectedToPay])

  return <Grid item container xs={6} alignContent="flex-start">
    <Grid item xs={12} className={classes.paymentGridHeader}>
      <Typography variant="h2">Events</Typography>
      <Typography variant="h4">Click below to see details</Typography>
    </Grid>
    {balanceTotals.producers.length > 0 ? eventGrid : <></>}
  </Grid>
}

export default BalanceEvents
