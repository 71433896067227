/* eslint max-lines: "off" */
import React, { FunctionComponent, ReactElement, useState } from 'react'
import {
  Container, IconButton, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField
} from '@material-ui/core'
import { useStyles } from 'lib/theme'

import themis_common, { Personnel } from 'store/themis_common_pb'
import { Remove as RemoveIcon } from "@material-ui/icons"
import Autocomplete from "@material-ui/lab/Autocomplete"

type eventRegistrationPersonnelProps = {
  sortedPersonnel: Personnel.AsObject[]
  sortedValidPersonnel: Personnel.AsObject[]
  addPersonnel: Function
  removePersonnel: Function
  eventTeamId: number
  dateComparison: boolean
}

const EventRegistrationPersonnel: FunctionComponent<eventRegistrationPersonnelProps> = ({
  sortedPersonnel,
  sortedValidPersonnel,
  addPersonnel,
  removePersonnel,
  eventTeamId,
  dateComparison
}): ReactElement => {
  const classes = useStyles()

  const [rerenderAutocomplete, setRerenderAutoComplete] = useState(false)

  return (
    <Container maxWidth="lg">
      {(!sortedValidPersonnel || sortedValidPersonnel.length === 0 || !dateComparison) ?
        <></>
        : (
          <Autocomplete
            key={rerenderAutocomplete ? '1' : '0'}
            id="Add_New_Personnel"
            size="small"
            options={sortedValidPersonnel}
            getOptionLabel={(personnel: themis_common.Personnel.AsObject) => (personnel.name || 'No Name Found') + ' ' + (personnel.person?.nickName ? ' (' + personnel.person?.nickName + ')' : '')}
            style={{ width: 300, marginBottom: "15px" }}
            renderInput={(params) => <TextField {...params} label="Add Personnel" variant="outlined" />}
            onChange={(e, v) => {
              v && addPersonnel(v)
              setRerenderAutoComplete(!rerenderAutocomplete)
            }}
          />)
      }
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {dateComparison && <TableCell style={{ width: "150px" }}>Remove from Event Team</TableCell>}
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPersonnel.map((personnel) => {
              return (
                <TableRow key={personnel.id}>
                  {dateComparison && < TableCell style={{ width: "150px" }}>
                    <IconButton className={classes.clickable} aria-label="remove" onClick={_ => {
                      removePersonnel(personnel)
                    }}>
                      <RemoveIcon fontSize="small" />
                    </IconButton>
                  </TableCell>}
                  <TableCell>
                    {personnel.name}{personnel.person?.nickName ? ' (' + personnel.person.nickName + ')' : ''}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container >
  )
}

export default EventRegistrationPersonnel
