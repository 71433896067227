import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useStyles } from "lib/theme"
import themis_common from 'store/themis_common_pb'
import themis_api from 'store/themis_api_pb'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Button, Typography, Accordion, Grid } from '@material-ui/core'
import {
  getEventEmailCounts,
  getRegistrationActivityLogByEvent,
  sendEventDocumentEmails
} from "store/producer/eventActions"

interface producerRegistrationActivityProps { eventId: number, brandId: number, producerId: number, eventName: string}

const ProducerRegistrationActivity: React.FC<producerRegistrationActivityProps> = ({ eventId, brandId, producerId, eventName }): ReactElement => {
  const classes = useStyles()

  const [reportData, setReportData] = useState<themis_common.RegistrationActivityLogItem.AsObject[]>([])
  const [expanded, setExpanded] = useState<string | false>(false)
  const [counts, setCounts] = useState<themis_api.CountEventEmailsResponse.AsObject>()
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    setReportData([])
    const getReportData = async () => {
      const reportData = await getRegistrationActivityLogByEvent(eventId)
      setReportData(reportData)
    }
    const getEmailCounts = async () => {
      const counts = await getEventEmailCounts(eventId, brandId, producerId)
      setCounts(counts)
    }
    getReportData()
    getEmailCounts()
  }, [brandId, eventId, producerId])

  const doSendEmails = useCallback(() => {
    const sendEmails = async () => {
      await sendEventDocumentEmails(eventId, brandId, producerId)
      const counts = await getEventEmailCounts(eventId, brandId, producerId)
      setCounts(counts)
    }
    sendEmails()
  }, [brandId, eventId, producerId])

  const activityList = useMemo(() => {
    return (<div>
      {reportData.map((item) => (
        <Accordion key={item.id} expanded={expanded === item.id} onChange={handleChange(item.id)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid container className={classes.root}>
              <Grid item xs={2}>
                <Typography variant="caption" style={{ "color": "gray", "width": "200px" }}>{`${item.updatedAt?.formattedDateTime?.year}-${item.updatedAt?.formattedDateTime?.monthLeadingNumber}-${item.updatedAt?.formattedDateTime?.dayLeadingNumber} ${item.updatedAt?.formattedDateTime?.hour24leadingNumber}:${item.updatedAt?.formattedDateTime?.minutesLeadingNumber}`}&nbsp;</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="subtitle2">{item.description}</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <div dangerouslySetInnerHTML={{ __html: item.html.replace(/(<? *script)/gi, 'illegalscript') }}>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <Typography>Displaying last {reportData.length} items - limit (200)</Typography>
    </div>)
  },[reportData, expanded])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h2">
          Recent {eventName} Registration Activity
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {counts === undefined ? [] :
          <Button
            disabled={!counts?.canSend}
            onClick={() => {
              doSendEmails()
            }}
            fullWidth
            variant="outlined"
            color="primary">
            Send Emails to Guardians Who Have Not Yet Signed Documents ({`${counts?.canSend}/${counts?.total}`})
          </Button>
        }
      </Grid>
      <Grid item sm={12}>
        {activityList}
      </Grid>
    </Grid>)
}

export default ProducerRegistrationActivity
