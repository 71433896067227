import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { fieldsOkay } from "lib/validators"
import { refreshUser, getUserGeolocation } from "store/user/userActions"
import { useHistory, useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { addProgram, addProgramLogo, doSetProgram, updateProgram } from "store/program/programActions"
import { selectCurrentProgram } from "store/program/programSelectors"
import { emptyProgram } from "store/program/programReducer"
import { ADD_LOGO_PROGRAM } from "lib/constants"
import { logoUploadDefault } from "components/util/LogoUploadContext"
import AddEditProgram from './AddEditProgram'
import { connectProgramToProducerLink } from "store/producer/producerActions"
import { useEffect } from 'react'
import { locationApiCall } from "lib/functions"


const AddEditProgramContainer: React.FC = (): ReactElement => {
  const history = useHistory()
  const dispatch = useDispatch()

  const program = useSelector((state: IAppState) => selectCurrentProgram(state))
  const { programId } = useParams<{ programId?: string | undefined }>()

  const [checkProgram, setCheckProgram] = useState(false)
  const [updatedProgram, setUpdatedProgram] = useState(emptyProgram)
  const [logoState, setLogoState] = useState<typeof logoUploadDefault>({ ...logoUploadDefault, id: Number(programId), addLogoType: ADD_LOGO_PROGRAM })
  const [isErrorOpen, setIsErrorOpen] = useState(false)
  const [storageProducerLink, setStorageProducerLink] = useState<string | null>(null)
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false)
  const [userGeoLocation, setUserGeoLocation] = useState<GeolocationPosition | undefined>()
  const [locationResponse, setLocationResponse] = useState<any>()


  const handleGeoLocation = useCallback((position: GeolocationPosition) => {
    const callApi = async () => {
      setUserGeoLocation(position)
      const location = await locationApiCall(position.coords.longitude, position.coords.latitude)
      setLocationResponse(location)
    }
    callApi()
  }, [setUserGeoLocation])
  // Handle Geo Location, calls https://www.bigdatacloud.com/geocoding-apis/free-reverse-geocode-to-city-api when the user has geo location enabled. 
  useEffect(() => {
    if (navigator.geolocation) getUserGeolocation(handleGeoLocation)
  }, [navigator.geolocation, handleGeoLocation])

  const formFields = useMemo(() => {
    return {
      name: { label: "Program Name", required: true, phone: false, email: false },
      legalName: { label: "Legal Name", required: false, phone: false, email: false },
      dba: { label: "DBA Name", required: false, phone: false, email: false },
      city: { label: "City", required: true, phone: false, email: false },
      state: { label: "State or Province", required: true, phone: false, email: false },
      country: { label: "Country", required: true, phone: false, email: false },
      address: { label: "Address", required: true, phone: false, email: false },
      address2: { label: "Address 2", required: false, phone: false, email: false },
      postalCode: { label: "Postal Code", required: true, phone: false, email: false },
      logo: { label: "Logo", required: false, phone: false, email: false },
      website: { label: "Website URL", required: false, phone: false, email: false },
      phone: { label: "Contact Phone", required: true, phone: true, email: false },
    }
  }, [])

  const formReady = useMemo(() => fieldsOkay(updatedProgram, formFields), [updatedProgram, formFields])

  const submitForm = async (event: { preventDefault: () => void }) => {
    setCheckProgram(true)
    event.preventDefault()
    if (formReady) {
      // If programId from url param: update program
      // Else: add program
      if (programId) {
        // Update Program
        const doUpdateProgram = updateProgram({
          ...updatedProgram
        })
        try {
          await doUpdateProgram()
          if (logoState.fileSelected) {
            await addProgramLogo(dispatch, logoState.id, logoState.base64File, logoState.fileName)
            setLogoState(logoUploadDefault)
          }
          await doSetProgram(dispatch, Number(programId))
          await refreshUser(dispatch)
          history.replace(`/Program/${programId}`)
        } catch (e) {
          setIsErrorOpen(true)
        }
      } else {
        // Add Program
        const doAddProgram = addProgram({
          ...updatedProgram,
        })
        try {
          const newProgramId = await doAddProgram(dispatch)
          await localStorage.setItem('programOrProducer', 'program')
          if (logoState.fileSelected) {
            await addProgramLogo(dispatch, newProgramId, logoState.base64File, logoState.fileName)
            setLogoState(logoUploadDefault)
          }
          await refreshUser(dispatch)
          await doSetProgram(dispatch, newProgramId)
          // If registered from producer connect link, connect program to producer
          if (storageProducerLink) {
            connectProgramToProducerLink(newProgramId)
            localStorage.removeItem('producerConnectLink')
            setIsNotificationOpen(true)
          }
          history.push(`/Program/${newProgramId}/AddLocation`)
        } catch (e) {
          setIsErrorOpen(true)
          console.error(e)
        }
      }
    }
  }

  return (
    <AddEditProgram
      updatedProgram={updatedProgram}
      program={program}
      programId={programId}
      setUpdatedProgram={setUpdatedProgram}
      checkProgram={checkProgram}
      setCheckProgram={setCheckProgram}
      formFields={formFields}
      isErrorOpen={isErrorOpen}
      setIsErrorOpen={setIsErrorOpen}
      logoState={logoState}
      setLogoState={setLogoState}
      submitForm={submitForm}
      isNotificationOpen={isNotificationOpen}
      setIsNotificationOpen={setIsNotificationOpen}
      locationResponse={locationResponse}
    />
  )
}

export default AddEditProgramContainer
