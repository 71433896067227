/* eslint max-lines: "off" */
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import themis_common from 'store/themis_common_pb'
import { useHistory, useLocation, useParams } from "react-router-dom"
import EventRegistration from "./EventRegistration"
import { emptyEventDivision } from "store/producer/producerReducer"
import { IAppState } from "store/store"
import { selectCurrentLocation, selectCurrentTeam, selectCurrentProgram } from "store/program/programSelectors"
import { doSetEventRegistrationLogo, getEvent } from "store/producer/eventActions"
import {
  checkForCrossovers,
  findEventRegistrationCodeForEvent,
  getCalculatedDisplayPricing,
  getEventTeam,
  getEventTeamOutstanding,
  getEventTeamSignStatus,
  getEventTeamTransactions, resendEmail, updateEventTeamDivision
} from "store/program/eventTeamActions"

import { isValidEmail, teamDivisionError, validateAthleteOnDivision, validateTeamOnDivision } from "lib/validators"
import {
  addEventTeamAthlete,
  addEventTeamCoach, addEventTeamPersonnel,
  doRegisterEventTeam,
  doUnregisterEventTeam, removeEventTeamAthlete,
  removeEventTeamCoach, removeEventTeamPersonnel
} from "store/program/eventTeamActions"
import { emptyEventTeam, EventTeamPayments, RegistrationPaymentData } from "store/program/programReducer"
import { addSelectedRegistrationsForPayment } from "../../../store/program/programActions"
import { getRegistrationTransactionsByEventTeam } from "../../../store/program/paymentActions"

// TODO: Fix all the hook dependencies
const emptyEventTeamDivision = {
  id: 0,
  division: undefined,
  divisionSplit: undefined,
  event: undefined,
  eventTeamsList: [],
  paid: 0,
  remainingAmount: 0,
  remainingDeposit: 0,
  divisionsList: [],
}
interface DiscountCodeWithTeamPrice extends themis_common.EventRegistrationCode.AsObject {
  teamPrice: number
}

export type regErrorObject = {
  name: string,
  hash: string,
}

const EventRegistrationContainer = (): ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { locationId: inLocationId, teamId: inTeamId, eventId: inEventId, eventTeamId: inEventTeamId } = useParams<{ locationId: string, teamId: string, eventId: string, eventTeamId: string }>()

  const hashValues = useMemo(() => ['details', 'athletes', 'coaches', 'personnel'], [])

  const locationId = Number(inLocationId)
  const teamId = Number(inTeamId)
  const eventId = Number(inEventId)
  const eventTeamId = Number(inEventTeamId)

  const currentProgram = useSelector((state: IAppState) => selectCurrentProgram(state))
  const currentLocation = useSelector((state: IAppState) => selectCurrentLocation(state))
  const currentTeam = useSelector((state: IAppState) => selectCurrentTeam(state))

  const [event, setEvent] = useState<themis_common.Event.AsObject>()
  const [producerId, setProducerId] = useState<number>(0)
  const [brandId, setBrandId] = useState<number>(0)
  const [currentTab, setCurrentTab] = useState(0)
  const [currentEventTeam, setCurrentEventTeam] = useState<themis_common.EventTeam.AsObject>(emptyEventTeam)
  const [currentEventTeamSignStatus, setCurrentEventTeamSignStatus] = useState<themis_common.AthleteSignStatus.AsObject[]>([])
  const [eventTeamTransactions, setEventTeamTransactions] = useState<EventTeamPayments>({ credit: 0, deposit: 0, payment: 0, availableCredit: 0, total: 0, transactions: [] })
  const [currentTeamAthletes, setCurrentTeamAthletes] = useState<themis_common.Athlete.AsObject[]>([])
  const [currentTeamCoaches, setCurrentTeamCoaches] = useState<themis_common.Coach.AsObject[]>([])
  const [currentTeamPersonnel, setCurrentTeamPersonnel] = useState<themis_common.Personnel.AsObject[]>([])
  const [validAthletes, setValidAthletes] = useState<themis_common.Athlete.AsObject[]>([])
  const [validCoaches, setValidCoaches] = useState<themis_common.Coach.AsObject[]>([])
  const [validPersonnel, setValidPersonnel] = useState<themis_common.Personnel.AsObject[]>([])
  const [registrationPrice, setRegistrationPrice] = useState<string>("0.00")
  const [registrationDivisionPriceId, setRegistrationDivisionPriceId] = useState<number>(0)
  const [registrationDiscountCode, setRegistrationDiscountCode] = useState<string>("")
  const [foundRegistrationDiscountCode, setFoundRegistrationDiscountCode] = useState<themis_common.EventRegistrationCode.AsObject>(new themis_common.EventRegistrationCode().toObject())
  const [selectedEventDivision, setSelectedEventDivision] = useState<themis_common.EventDivision.AsObject>(emptyEventDivision)
  const [readyToRegister, setReadyToRegister] = useState<boolean>(false)
  const [eventTeamBalance, setEventTeamBalance] = useState<themis_common.EventTeamBalance.AsObject>()
  const [calculatedAthletePrices, setCalculatedAthletePrices] = useState<themis_common.CalculatedAthletePricing.AsObject[]>([])

  const [registrationIssues, setRegistrationIssues] = useState<regErrorObject[]>([])
  const [currentCrossovers, setCurrentCrossovers] = useState<themis_common.Athlete.AsObject[]>([])

  const [changeDivisionError, setChangeDivisionError] = useState<boolean>(false)
  const [changeDivisionDialogOpen, setChangeDivisionDialogOpen] = useState<boolean>(false)
  const [newEventTeamDivision, setNewEventTeamDivision] = useState<themis_common.EventDivision.AsObject>(emptyEventDivision)
  const [newEventTeamRegCode, setNewEventTeamRegCode] = useState<string>("")
  const [changeDivisionFoundCode, setChangeDivisionFoundCode] = useState<themis_common.EventRegistrationCode.AsObject>(new themis_common.EventRegistrationCode().toObject())
  const [foundChangeDivisionRegistrationDiscountCode, setFoundChangeDivisionRegistrationDiscountCode] = useState<themis_common.EventRegistrationCode.AsObject>(new themis_common.EventRegistrationCode().toObject())
  const [isRegisterButtonDisabled, setIsRegisterButtonDisabled] = useState<boolean>(false)
  const [isUnregisterButtonDisabled, setIsUnregisterButtonDisabled] = useState<boolean>(false)

  const programId = useMemo(() => {
    return currentLocation?.program?.id || 0
  }, [currentLocation?.program?.id])

  const dateComparsion = useMemo(() => {
    if (event) {
      const index = event?.eventDatesList?.length - 1 ?? 0
      if (event && event.eventDatesList[index].endClockTime) {
        const comparison = event?.eventDatesList[index]?.endClockTime?.unixTime ?? 0
        if (comparison < Date.now()) {
          return false
        }
        return true
      }
    }
    return false
  }, [event]) // used to disable buttons and other jsx on lower components.

  useEffect(() => {
    // Gotta have these to move on
    if (!event || selectedEventDivision.id < 1 || !programId || !locationId || !teamId || currentTeamAthletes.length === 0) return

    //
    if (event?.registrationCodesList.length) {
      const validEventRegCodes: themis_common.EventRegistrationCode.AsObject[] = []
      event.registrationCodesList.forEach((code) => {
        // If this code is current
        if (code.beginsOnClockTime?.unixTime && code.expiresOnClockTime?.unixTime) {
          const codeBeginsUnixTime = Math.floor(code.beginsOnClockTime.unixTime / 1000) // in seconds
          const codeExpiresUnixTime = Math.floor(code.expiresOnClockTime.unixTime / 1000)
          const unixTimeNow = Math.floor(Date.now() / 1000) // in seconds

          // Gather all codes that are marked autofill, not expired, status true, and match selected division
          if (code.pb_default && code.status && codeBeginsUnixTime < unixTimeNow && codeExpiresUnixTime > unixTimeNow) {
            // Check if discount code is valid for the division
            if (code.discountAppliesTo === "All") {
              validEventRegCodes.push(code)
            } else {
              code.eventDivisionsList.forEach((ed) => {
                if (ed.division?.id === selectedEventDivision.division?.id) {
                  validEventRegCodes.push(code)
                }
              })
            }
          }
        }
      })

      // Generate array of valid registration codes with appended team prices
      if (validEventRegCodes.length > 0) {
        // Get team price for all valid discount codes
        const getCalculatedPricesPromises = validEventRegCodes.map((code): Promise<DiscountCodeWithTeamPrice> => {
          const getPricingBE = async (): Promise<DiscountCodeWithTeamPrice> => {
            const eventTeamDisplayPricing = await getCalculatedDisplayPricing(dispatch, programId, locationId, teamId, eventId, event, selectedEventDivision, currentTeamAthletes, code.id)
            const codeWithTeamPrice: DiscountCodeWithTeamPrice = { ...code, teamPrice: eventTeamDisplayPricing.teamPrice }
            return codeWithTeamPrice
          }
          return getPricingBE()
        })
        // Wait for all the backend calls to finish
        Promise.all(getCalculatedPricesPromises).then((codesWithTeamPrice) => {
          // Find code that gives lowest team price and set it
          const maxDiscountCode = codesWithTeamPrice.reduce((min, code) => min.teamPrice < code.teamPrice ? min : code)
          if ((currentEventTeam?.id ?? 0) === 0) { setRegistrationDiscountCode(maxDiscountCode.code) }
          else if (((currentEventTeam?.eventRegistrationCode?.id ?? 0) === 0)) { // Else if no valid event reg codes, clear discount code
            setRegistrationDiscountCode("")
          }
        })
      } else { // Else if no valid event reg codes, clear discount code
        setRegistrationDiscountCode("")
      }
    }
  }, [event?.registrationCodesList, selectedEventDivision, currentTeam, event, dispatch, programId, locationId, teamId, eventId, currentTeamAthletes])

  useEffect(() => {
    if (event?.logo) {
      doSetEventRegistrationLogo(dispatch, event.logo)
    } else if (event?.brand?.logo) {
      doSetEventRegistrationLogo(dispatch, event.brand.logo)
    }
  }, [event, dispatch])

  const handleResendEmail = useCallback((athleteId) => {
    resendEmail(athleteId, eventId, programId, locationId, teamId)
  }, [eventId, locationId, programId, teamId])

  useEffect(() => {
    const doGetEventTeam = async () => {
      if (currentProgram?.id && currentLocation?.id && currentTeam?.id) {
        const eventTeam = await getEventTeam(eventTeamId, currentProgram.id, currentLocation.id, currentTeam.id)
        setCurrentEventTeam(eventTeam)
        const eventTeamSignStatus = await getEventTeamSignStatus(eventTeamId, currentProgram.id, currentLocation.id, currentTeam.id)
        setCurrentEventTeamSignStatus(eventTeamSignStatus.athleteStatusList)
        const eventTeamTx = await getEventTeamTransactions(eventTeamId, currentProgram.id, currentLocation.id, currentTeam.id, producerId)
        const eventTeamPayments: EventTeamPayments = eventTeamTx.transactionsList.reduce((current, eventTeam) => {
          eventTeam.paymentsList.forEach(payment => {
            if (payment.paidOrPending) {
              switch (payment.cdp) {
                case "P":
                  current.payment += payment.amount
                  break
                case "D":
                  current.deposit += payment.amount
                  break
                case "C":
                  current.credit += payment.amount
                  break
              }
            }
          })
          return current
        }, { payment: 0, credit: 0, deposit: eventTeamTx.depositAmount, availableCredit: eventTeamTx.creditAmount, transactions: eventTeamTx.transactionsList, total: eventTeamTx.transactionsList.reduce((current, eventTeamRx) => current + eventTeamRx.amount, 0) })
        setEventTeamTransactions(eventTeamPayments)
      }
    }
    if (eventTeamId > 0) {
      doGetEventTeam()
    }
  }, [currentLocation?.id, currentProgram?.id, currentTeam?.id, eventTeamId])

  const handleChangeTab = (event: React.ChangeEvent<any> | null, newValue: number) => {
    if (hashValues[newValue]) history.push('#' + hashValues[newValue])
    setCurrentTab(newValue)
  }

  const checkGuardians = (guardians: themis_common.Guardian.AsObject[]): boolean => {
    if (guardians.length === 0) return false
    return guardians.reduce((current: boolean, guardian) => isValidEmail(guardian.person?.email || "") || current, false)
  }

  useEffect(() => {
    const hash = location.hash.toLowerCase().substr(1)
    if (hash === '') {
      history.replace('#' + hashValues[0])
    }
    const tab = hashValues.indexOf(location.hash.toLowerCase().substr(1))
    if (tab >= 0) setCurrentTab(tab)
  }, [location, hashValues, history])

  const teamErrors = useMemo<teamDivisionError[]>(() => {
    let errors: teamDivisionError[] = []
    if (selectedEventDivision?.division?.divisionRestriction) {
      errors = validateTeamOnDivision(currentTeamAthletes, selectedEventDivision?.division?.divisionRestriction)
    }
    currentTeamAthletes.forEach(athlete => {
      if (event?.brand?.guardianRequired && !checkGuardians(athlete.athleteGuardiansList)) {
        const newError: teamDivisionError = {
          athleteId: athlete.id, issue: "Athlete has no guardians with valid email addresses."
        }
        errors.push(newError)
      }
    })
    return errors
  }, [selectedEventDivision?.division?.divisionRestriction, currentTeamAthletes])

  useEffect(() => {
    if (!eventTeamId && event?.eventDivisionList[0].id) {
      const eventDivision = event.eventDivisionList.find((ed) => currentTeam?.scoringAuthoritiesList.find((sa) => {
        return sa?.division?.id === ed.division?.id
      })) || event?.eventDivisionList[0]
      setSelectedEventDivision(eventDivision)
    } else {
      setSelectedEventDivision(currentEventTeam.eventDivision || emptyEventDivision)
    }
  }, [currentTeam?.scoringAuthoritiesList, event?.eventDivisionList, eventTeamId, currentEventTeam])

  useEffect(() => {
    const getOutstandingBE = async () => {
      if (eventTeamId && programId) {
        const eventTeamBalance = await getEventTeamOutstanding(dispatch, programId, locationId, teamId, eventTeamId)
        setEventTeamBalance(eventTeamBalance)
      }
    }
    getOutstandingBE()
  }, [dispatch, programId, locationId, teamId, eventTeamId])

  useEffect(() => {
    const getPricingBE = async () => {
      if (!event || selectedEventDivision.id < 1 || !programId || !locationId || !teamId || currentTeamAthletes.length === 0) return
      const eventTeamDisplayPricing = await getCalculatedDisplayPricing(dispatch, programId, locationId, teamId, eventId, event, selectedEventDivision, currentTeamAthletes, foundRegistrationDiscountCode.id)
      setRegistrationPrice((eventTeamDisplayPricing.teamPrice / 100).toFixed(2))
      setCalculatedAthletePrices(eventTeamDisplayPricing.athletePricesList)
    }
    getPricingBE()
  }, [dispatch, eventTeamId, programId, locationId, teamId, eventId, event, selectedEventDivision, currentTeamAthletes, foundRegistrationDiscountCode.id])

  // Should probably just pull this from DB instead of sending it with request. Done?
  // This may not be needed anymore
  useEffect(() => {
    // Calculate base price
    if (selectedEventDivision.eventDivisionPricing && (Number(selectedEventDivision.eventDivisionPricing.athletePriceString) > 0 || Number(selectedEventDivision.eventDivisionPricing.teamPriceString) > 0)) {
      // If division pricing
      setRegistrationDivisionPriceId(selectedEventDivision.eventDivisionPricing?.id || 0)
    }
  }, [selectedEventDivision])

  useEffect(() => {
    if (eventId > 0) {
      const getEventData = async () => {
        const incomingEvent = await getEvent(eventId)
        setBrandId(incomingEvent?.brand?.id || 0)
        setProducerId(incomingEvent?.brand?.producer?.id || 0)
        setEvent(incomingEvent)
      }
      getEventData()
    }
  }, [eventId])

  // If editing event team, set registration code
  useEffect(() => {
    if (currentEventTeam.id > 0 && currentEventTeam.eventRegistrationCode && currentEventTeam.eventRegistrationCode?.id > 0) {
      setRegistrationDiscountCode(currentEventTeam.eventRegistrationCode?.code)
    }
  }, [currentEventTeam.id, currentEventTeam.eventRegistrationCode, currentEventTeam.eventRegistrationCode?.id])

  // Find a discount code for event
  useEffect(() => {
    // Could find regCode in event?.registrationCodesList instead of hitting BE
    if (registrationDiscountCode.length > 0) {
      const findRegCode = async () => {
        const foundRegCode = await findEventRegistrationCodeForEvent(registrationDiscountCode, eventId)
        // Check if same pricing type as discount
        let pricingType = "athlete"
        if (selectedEventDivision.eventDivisionPricing && selectedEventDivision.eventDivisionPricing.teamPriceInt > 0) {
          pricingType = "team"
        }
        if (foundRegCode.discountType === 0) { // Percent Type
          setFoundRegistrationDiscountCode(foundRegCode)
        } else if (foundRegCode.discountType === 1 && foundRegCode.discountPerType === pricingType) { // Dollar type and If discount type matches pricing type
          setFoundRegistrationDiscountCode(foundRegCode)
        }
      }
      findRegCode()
    } else {
      setFoundRegistrationDiscountCode(new themis_common.EventRegistrationCode().toObject())
    }
  }, [registrationDiscountCode, eventId, selectedEventDivision.eventDivisionPricing])

  useEffect(() => {
    const locationAthletes = currentLocation?.athletesList || []
    if (!eventTeamId) {
      const newValidAthleteList = locationAthletes.filter((athlete) => {
        if (selectedEventDivision.division?.divisionRestriction && validateAthleteOnDivision(athlete, selectedEventDivision.division?.divisionRestriction)) return false
        return !currentTeam?.athletesList.find((teamAthlete) => athlete.id === teamAthlete.id)
      })
      setValidAthletes(newValidAthleteList)
    } else {
      const newValidAthleteList = locationAthletes.filter((athlete) => {
        if (selectedEventDivision.division?.divisionRestriction && validateAthleteOnDivision(athlete, selectedEventDivision.division?.divisionRestriction)) return false
        return !currentEventTeam?.athletesList.find((teamAthlete) => athlete.id === teamAthlete.id)
      })
      setValidAthletes(newValidAthleteList)
    }

    const locationCoaches = currentLocation?.coachesList || []
    if (!eventTeamId) {
      const newValidCoachList = locationCoaches.filter((coach) => {
        return !currentTeam?.coachesList.find((teamCoach) => coach.id === teamCoach.id)
      })
      setValidCoaches(newValidCoachList)
    } else {
      const newValidCoachList = locationCoaches.filter((coach) => {
        return !currentEventTeam?.coachesList.find((teamCoach) => coach.id === teamCoach.id)
      })
      setValidCoaches(newValidCoachList)
    }

    const locationPersonnel = currentLocation?.personnelList || []
    if (!eventTeamId) {
      const newValidPersonnelList = locationPersonnel.filter((personnel) => {
        return !currentTeam?.personnelList.find((teamPersonnel) => personnel.id === teamPersonnel.id)
      })
      setValidPersonnel(newValidPersonnelList)
    } else {
      const newValidPersonnelList = locationPersonnel.filter((personnel) => {
        return !currentEventTeam?.personnelList.find((teamPersonnel) => personnel.id === teamPersonnel.id)
      })
      setValidPersonnel(newValidPersonnelList)
    }
  }, [currentLocation?.athletesList, currentLocation?.coachesList, currentLocation?.personnelList, currentTeam?.athletesList, currentTeam?.coachesList, currentTeam?.personnelList, selectedEventDivision.division?.divisionRestriction])

  useEffect(() => {
    if (eventTeamId) {
      if (currentEventTeam?.athletesList.length > 0) {
        setCurrentTeamAthletes(currentEventTeam?.athletesList)
      }
    } else {
      const athletes = currentTeam?.athletesList || []
      if (athletes.length > 0) {
        setCurrentTeamAthletes(athletes)
      }
    }
  }, [currentTeam?.athletesList, eventTeamId, currentEventTeam])

  useEffect(() => {
    if (eventTeamId && currentEventTeam?.coachesList.length > 0) {
      setCurrentTeamCoaches(currentEventTeam?.coachesList)
      setCurrentTeamCoaches(currentEventTeam?.coachesList)
    } else {
      const coaches = currentTeam?.coachesList || []
      if (coaches.length > 0) {
        setCurrentTeamCoaches(coaches)
      }
    }
  }, [currentTeam?.coachesList, eventTeamId, currentEventTeam])

  useEffect(() => {
    if (eventTeamId) {
      if (currentEventTeam?.personnelList.length > 0) {
        setCurrentTeamPersonnel(currentEventTeam?.personnelList)
      }
    } else {
      const personnel = currentTeam?.personnelList || []
      if (personnel.length > 0) {
        setCurrentTeamPersonnel(personnel)
      }
    }
  }, [currentTeam?.personnelList, eventTeamId, currentEventTeam])

  const registerTeam = useCallback(() => {
    registerEventTeam()
    async function registerEventTeam() {
      setIsRegisterButtonDisabled(true)
      if (!programId || !locationId || !teamId || !event) return
      const returnValues = await doRegisterEventTeam(dispatch, programId, locationId, teamId, eventId, brandId, producerId, currentProgram?.name || "", currentLocation?.name || "", currentTeam?.name || "", selectedEventDivision, currentTeamAthletes, currentTeamCoaches, currentTeamPersonnel, registrationDivisionPriceId, event, foundRegistrationDiscountCode)
      if (returnValues.id > 0) {
        history.push(`/Registration/${locationId}/Team/${teamId}/${eventId}/${returnValues.id}/#details`)
      }
      setIsRegisterButtonDisabled(false)
    }
  }, [history, brandId, currentLocation?.name, currentProgram?.name, currentTeam?.name, currentTeamAthletes, currentTeamCoaches, currentTeamPersonnel, dispatch, eventId, locationId, producerId, programId, registrationDivisionPriceId, selectedEventDivision, teamId, event, foundRegistrationDiscountCode])

  const unregisterTeam = useCallback(() => {
    const unregisterEventTeam = async () => {
      setIsUnregisterButtonDisabled(true)
      if (!eventTeamId) return
      await doUnregisterEventTeam(dispatch, programId, locationId, teamId, eventTeamId, eventId, producerId, brandId)
      history.push(`/Location/${locationId}#events`)
      setIsUnregisterButtonDisabled(true)
    }
    unregisterEventTeam()
  }, [dispatch, eventTeamId, history, locationId, programId, teamId, eventId, producerId, brandId])


  useEffect(() => {
    setReadyToRegister(true)
    setRegistrationIssues([])
    if (teamErrors.length > 0) {
      setReadyToRegister(false)
      setRegistrationIssues((currentValue) => [...currentValue, { name: "Fix team issues", hash: "athletes" }])
    }
    if (!programId) {
      setReadyToRegister(false)
      setRegistrationIssues((currentValue) => [...currentValue, { name: "ProgramId not set", hash: "details" }])
    }
    if (!locationId) {
      setReadyToRegister(false)
      setRegistrationIssues((currentValue) => [...currentValue, { name: "LocationId not set", hash: "details" }] )
    }
    if (!teamId) {
      setReadyToRegister(false)
      setRegistrationIssues((currentValue) => [...currentValue, { name: "TeamId not set", hash: "details" }])
    }
    if (!eventId) {
      setReadyToRegister(false)
      setRegistrationIssues((currentValue) => [...currentValue, { name: "EventId not set", hash: "details" }])
    }
    if (!brandId) {
      setReadyToRegister(false)
      setRegistrationIssues((currentValue) => [...currentValue, { name: "BrandId not set", hash: "details" }])
    }
    if (!producerId) {
      setReadyToRegister(false)
      setRegistrationIssues((currentValue) => [...currentValue, { name: "ProducerId not set", hash: "details" }])
    }
    if (!selectedEventDivision.id) {
      setReadyToRegister(false)
      setRegistrationIssues((currentValue) => [...currentValue, { name: "Event division not set", hash: "details" }])
    }
    if (currentTeamCoaches.length < 1) {
      setReadyToRegister(false)
      setRegistrationIssues((currentValue) => [...currentValue, { name: "Teams must have at least one coach assigned", hash: "coaches" }])
    }

  }, [brandId, eventId, locationId, producerId, programId, selectedEventDivision, teamErrors.length, teamId, currentTeamCoaches])

  const addAthlete = useCallback((athlete: themis_common.Athlete.AsObject) => {
    if (eventTeamId > 0) {
      const doAddEventTeamAthlete = async (currentEventTeam: themis_common.EventTeam.AsObject, athlete: themis_common.Athlete.AsObject) => {
        const eventTeam = await addEventTeamAthlete(dispatch, currentEventTeam, athlete)
        setCurrentEventTeam(eventTeam)

        const eventTeamBalance = await getEventTeamOutstanding(dispatch, programId, locationId, teamId, eventTeamId)
        setEventTeamBalance(eventTeamBalance)
      }
      doAddEventTeamAthlete(currentEventTeam, athlete)
    } else {
      const newValidAthletes = validAthletes.filter(a => a.id !== athlete.id)
      setValidAthletes(newValidAthletes)
      setCurrentTeamAthletes([...currentTeamAthletes, athlete])
    }
  }, [currentTeamAthletes, validAthletes])

  const removeAthlete = useCallback((athlete: themis_common.Athlete.AsObject) => {
    if (eventTeamId > 0) {
      const doRemoveEventTeamAthlete = async (currentEventTeam: themis_common.EventTeam.AsObject, athlete: themis_common.Athlete.AsObject) => {
        const eventTeam = await removeEventTeamAthlete(dispatch, currentEventTeam, athlete)
        setCurrentEventTeam(eventTeam)

        const eventTeamBalance = await getEventTeamOutstanding(dispatch, programId, locationId, teamId, eventTeamId)
        setEventTeamBalance(eventTeamBalance)
      }
      doRemoveEventTeamAthlete(currentEventTeam, athlete)
    } else {
      const newAthleteList = currentTeamAthletes.filter(a => a.id !== athlete.id)
      setCurrentTeamAthletes(newAthleteList)
      setValidAthletes([athlete, ...validAthletes])
    }
  }, [currentTeamAthletes, validAthletes])

  const addCoach = useCallback((coach: themis_common.Coach.AsObject) => {
    if (eventTeamId > 0) {
      const doAddEventTeamCoach = async (currentEventTeam: themis_common.EventTeam.AsObject, coach: themis_common.Coach.AsObject) => {
        const eventTeam = await addEventTeamCoach(dispatch, currentEventTeam, coach)
        setCurrentEventTeam(eventTeam)
      }
      doAddEventTeamCoach(currentEventTeam, coach)
    } else {
      const newValidCoaches = validCoaches.filter(a => a.id !== coach.id)
      setValidCoaches(newValidCoaches)
      setCurrentTeamCoaches([...currentTeamCoaches, coach])
    }
  }, [currentEventTeam, currentTeamCoaches, eventTeamId, validCoaches])

  const removeCoach = useCallback((coach: themis_common.Coach.AsObject) => {
    if (eventTeamId > 0) {
      const doRemoveEventTeamCoach = async (currentEventTeam: themis_common.EventTeam.AsObject, coach: themis_common.Coach.AsObject) => {
        const eventTeam = await removeEventTeamCoach(dispatch, currentEventTeam, coach)
        setCurrentEventTeam(eventTeam)
      }
      doRemoveEventTeamCoach(currentEventTeam, coach)
    } else {
      const newCoachList = currentTeamCoaches.filter(a => a.id !== coach.id)
      setCurrentTeamCoaches(newCoachList)
      setValidCoaches([coach, ...validCoaches])
    }
  }, [currentEventTeam, currentTeamCoaches, eventTeamId, validCoaches])

  const addPersonnel = useCallback((personnel: themis_common.Personnel.AsObject) => {
    if (eventTeamId > 0) {
      const doAddEventTeamPersonnel = async (currentEventTeam: themis_common.EventTeam.AsObject, personnel: themis_common.Personnel.AsObject) => {
        const eventTeam = await addEventTeamPersonnel(dispatch, currentEventTeam, personnel)
        setCurrentEventTeam(eventTeam)
      }
      doAddEventTeamPersonnel(currentEventTeam, personnel)
    } else {
      const newValidPersonnel = validPersonnel.filter(a => a.id !== personnel.id)
      setValidPersonnel(newValidPersonnel)
      setCurrentTeamPersonnel([...currentTeamPersonnel, personnel])
    }
  }, [currentEventTeam, currentTeamPersonnel, eventTeamId, validPersonnel])

  const payBalance = useCallback((amountSelectedToPay: number, eventTeamId: number, deposit: boolean): void => {
    if (currentProgram?.id) {
      const doPayBalance = async () => {
        let registrationPaymentData: RegistrationPaymentData
        const sourcePath = history.location.pathname + history.location.hash
        if (deposit) {
          registrationPaymentData = {
            sourcePath: sourcePath,
            programId: currentProgram.id,
            displayBalanceAmount: amountSelectedToPay,
            selectedRegistrationsForPayment: [],
            eventTeamInfo: [{
              eventTeamId: eventTeamId,
              teamName: currentTeam?.name || "Unknown Team Name",
              eventName: event?.name || "Unknown Event Name"
            }],
            currency: event?.currency,
            deposit: deposit
          }
        } else {
          const eventTransactions = await getRegistrationTransactionsByEventTeam(eventTeamId)
          const selectedRegistrations = eventTransactions.map(tx => tx.id)
          registrationPaymentData = {
            sourcePath: sourcePath,
            programId: currentProgram.id,
            displayBalanceAmount: amountSelectedToPay,
            selectedRegistrationsForPayment: selectedRegistrations,
            eventTeamInfo: [{
              eventTeamId: eventTeamId,
              teamName: currentTeam?.name || "Unknown Team Name",
              eventName: event?.name || "Unknown Event Name"
            }],
            currency: event?.currency,
            deposit: deposit
          }
        }
        await addSelectedRegistrationsForPayment(dispatch, registrationPaymentData)
        history.push(`/Program/${currentProgram.id}/RegistrationPayment`)
      }
      doPayBalance()
    }
  }, [currentProgram?.id, currentTeam?.name, event?.currency, event?.name, history, dispatch])

  const removePersonnel = useCallback((personnel: themis_common.Personnel.AsObject) => {
    if (eventTeamId > 0) {
      const doRemoveEventTeamPersonnel = async (currentEventTeam: themis_common.EventTeam.AsObject, personnel: themis_common.Personnel.AsObject) => {
        const eventTeam = await removeEventTeamPersonnel(dispatch, currentEventTeam, personnel)
        setCurrentEventTeam(eventTeam)
      }
      doRemoveEventTeamPersonnel(currentEventTeam, personnel)
    } else {
      const newPersonnelList = currentTeamPersonnel.filter(a => a.id !== personnel.id)
      setCurrentTeamPersonnel(newPersonnelList)
      setValidPersonnel([personnel, ...validPersonnel])
    }
  }, [currentEventTeam, currentTeamPersonnel, eventTeamId, validPersonnel])

  useEffect(() => {
    const getCrossovers = async () => {
      const currentXovers = await checkForCrossovers(currentTeamAthletes, eventTeamId, eventId, programId, locationId, teamId)
      setCurrentCrossovers(currentXovers)
    }
    getCrossovers()
  }, [currentTeamAthletes, eventTeamId, eventId, programId, locationId, teamId])

  const changeDivisionTeamErrors = useMemo<teamDivisionError[]>(() => {
    let errors: teamDivisionError[] = []
    if (newEventTeamDivision?.division?.divisionRestriction) {
      errors = validateTeamOnDivision(currentTeamAthletes, newEventTeamDivision?.division?.divisionRestriction)
    }
    currentTeamAthletes.forEach(athlete => {
      if (event?.brand?.guardianRequired && !checkGuardians(athlete.athleteGuardiansList)) {
        const newError: teamDivisionError = {
          athleteId: athlete.id, issue: "Athlete has no guardians with valid email addresses."
        }
        errors.push(newError)
      }
    })
    return errors
  }, [currentTeamAthletes, newEventTeamDivision?.division?.divisionRestriction])

  // Find a discount code for event
  useEffect(() => {
    // Could find regCode in event?.registrationCodesList instead of hitting BE
    if (newEventTeamRegCode.length > 0) {
      const findRegCode = async () => {
        const foundRegCode = await findEventRegistrationCodeForEvent(newEventTeamRegCode, eventId)
        // Check if same pricing type as discount
        let pricingType = "athlete"
        if (newEventTeamDivision.eventDivisionPricing && newEventTeamDivision.eventDivisionPricing.teamPriceInt > 0) {
          pricingType = "team"
        }
        if (foundRegCode.discountType === 0) { // Percent Type
          setChangeDivisionFoundCode(foundRegCode)
        } else if (foundRegCode.discountType === 1 && foundRegCode.discountPerType === pricingType) { // Dollar type and If discount type matches pricing type
          setChangeDivisionFoundCode(foundRegCode)
        }
      }
      findRegCode()
    } else {
      setChangeDivisionFoundCode(new themis_common.EventRegistrationCode().toObject())
    }
  }, [newEventTeamRegCode, newEventTeamDivision, eventId])

  // Find valid reg codes for change division modal
  useEffect(() => {
    if (!event || newEventTeamDivision.id < 1 || !programId || !locationId || !teamId || currentTeamAthletes.length === 0) return
    if (event?.registrationCodesList.length) {
      const validEventRegCodes: themis_common.EventRegistrationCode.AsObject[] = []
      event.registrationCodesList.forEach((code) => {
        if (code.beginsOnClockTime?.unixTime && code.expiresOnClockTime?.unixTime) {
          const codeBeginsUnixTime = Math.floor(code.beginsOnClockTime.unixTime / 1000) // in seconds
          const codeExpiresUnixTime = Math.floor(code.expiresOnClockTime.unixTime / 1000)
          const unixTimeNow = Math.floor(Date.now() / 1000) // in seconds

          // Gather all codes that are marked autofill, not expired, status true, and match selected division
          if (code.pb_default && code.status && codeBeginsUnixTime < unixTimeNow && codeExpiresUnixTime > unixTimeNow) {
            // Check if discount code is valid for the division
            if (code.discountAppliesTo === "All") {
              validEventRegCodes.push(code)
            } else {
              code.eventDivisionsList.forEach((ed) => {
                if (ed.division?.id === newEventTeamDivision.division?.id) {
                  validEventRegCodes.push(code)
                }
              })
            }
          }
        }
      })

      // Generate array of valid registration codes with appended team prices
      if (validEventRegCodes.length > 0) {
        // Get team price for all valid discount codes
        const getCalculatedPricesPromises = validEventRegCodes.map((code): Promise<DiscountCodeWithTeamPrice> => {
          const getPricingBE = async (): Promise<DiscountCodeWithTeamPrice> => {
            const eventTeamDisplayPricing = await getCalculatedDisplayPricing(dispatch, programId, locationId, teamId, eventId, event, newEventTeamDivision, currentTeamAthletes, code.id)
            const codeWithTeamPrice: DiscountCodeWithTeamPrice = { ...code, teamPrice: eventTeamDisplayPricing.teamPrice }
            return codeWithTeamPrice
          }
          return getPricingBE()
        })
        // Wait for all the backend calls to finish
        Promise.all(getCalculatedPricesPromises).then((codesWithTeamPrice) => {
          // Find code that gives lowest team price and set it
          const maxDiscountCode = codesWithTeamPrice.reduce((min, code) => min.teamPrice < code.teamPrice ? min : code)
          setNewEventTeamRegCode(maxDiscountCode.code)
        })
      } else { // Else if no valid event reg codes, clear discount code
        setNewEventTeamRegCode("")
      }
    }
  }, [currentEventTeam, currentTeamAthletes, dispatch, event, eventId, locationId, newEventTeamDivision, programId, teamId])


  useEffect(() => {
    if (changeDivisionTeamErrors.length >= 1) {
      setChangeDivisionError(true)
    } else {
      setChangeDivisionError(false)
    }
  }, [changeDivisionTeamErrors.length])


  const submitChangeDivision = useCallback(() => {
    if (changeDivisionTeamErrors.length >= 1) {
      return
    } else {
      const changeEventTeamDivision = async () => {
        const isProducerUpdate = false
        await updateEventTeamDivision(eventTeamId, eventId, programId, locationId, teamId, newEventTeamDivision, changeDivisionFoundCode, currentEventTeam.athletesList, isProducerUpdate, currentEventTeam.name, currentEventTeam.programName)
        setChangeDivisionError(false)
        setChangeDivisionDialogOpen(false)
        window.location.reload()
      }
      changeEventTeamDivision()
    }


  }, [changeDivisionFoundCode, changeDivisionTeamErrors.length, currentEventTeam, eventId, eventTeamId, locationId, newEventTeamDivision, programId, teamId])

  return (!currentTeam?.program?.id || !event) ? <>No Team or Event</> : (
    <EventRegistration
      locationId={locationId}
      teamId={teamId}
      event={event}
      registrationPrice={registrationPrice}
      registrationPriceId={registrationDivisionPriceId}
      registrationDiscountCode={registrationDiscountCode}
      setRegistrationDiscountCode={setRegistrationDiscountCode}
      foundRegistrationDiscountCode={foundRegistrationDiscountCode}
      validAthletes={validAthletes}
      validCoaches={validCoaches}
      validPersonnel={validPersonnel}
      teamAthletes={currentTeamAthletes}
      teamCoaches={currentTeamCoaches}
      teamPersonnel={currentTeamPersonnel}
      selectedEventDivision={selectedEventDivision}
      setSelectedEventDivision={setSelectedEventDivision}
      registerTeam={registerTeam}
      unregisterTeam={unregisterTeam}
      addAthlete={addAthlete}
      removeAthlete={removeAthlete}
      addCoach={addCoach}
      removeCoach={removeCoach}
      addPersonnel={addPersonnel}
      removePersonnel={removePersonnel}
      currentTab={currentTab}
      handleChangeTab={handleChangeTab}
      readyToRegister={readyToRegister}
      registrationIssues={registrationIssues}
      teamErrors={teamErrors}
      eventTeamId={eventTeamId}
      currentCrossovers={currentCrossovers}
      eventTeam={currentEventTeam}
      eventTeamSignStatus={currentEventTeamSignStatus}
      eventTeamTransactions={eventTeamTransactions}
      eventTeamBalance={eventTeamBalance}
      payBalance={payBalance}
      handleResendEmail={handleResendEmail}
      calculatedAthletePrices={calculatedAthletePrices}
      checkGuardians={checkGuardians}

      submitChangeDivision={submitChangeDivision}
      newEventTeamDivision={newEventTeamDivision}
      setNewEventTeamDivision={setNewEventTeamDivision}
      changeDivisionDialogOpen={changeDivisionDialogOpen}
      setChangeDivisionDialogOpen={setChangeDivisionDialogOpen}
      changeDivisionError={changeDivisionError}
      setChangeDivisionError={setChangeDivisionError}
      changeDivisionTeamErrors={changeDivisionTeamErrors}
      newEventTeamRegCode={newEventTeamRegCode}
      setNewEventTeamRegCode={setNewEventTeamRegCode}
      changeDivisionFoundCode={changeDivisionFoundCode}

      dateComparison={dateComparsion}
      isRegisterButtonDisabled={isRegisterButtonDisabled}
      isUnregisterButtonDisabled={isUnregisterButtonDisabled}
    />
  )
}

export default EventRegistrationContainer
