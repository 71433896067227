import { client } from "store/themisClient"
import { Dispatch } from "react"
import themis_api from "store/themis_api_pb"
import themis_common from "store/themis_common_pb"
import { buildAthlete } from "./programBuilders"
import { Action, createAction } from "redux-actions"
import { AnyDispatchType, SET_ATHLETES, SET_GUARDIANS } from "lib/constants"
import { differenceInCalendarYears, format, parse, parseISO } from "date-fns"
import { doSetTeam } from "./teamActions"
import { isValidEmail } from "../../lib/validators"

const setGuardians = createAction(SET_GUARDIANS)
const setAthletes = createAction<themis_common.Athlete.AsObject[]>(SET_ATHLETES)

export const getAthlete = async (dispatch: AnyDispatchType, athleteId: number, programId: number, locationId: number, validateTeamId: number): Promise<themis_common.Athlete.AsObject | undefined> => {
  // try {
  const athleteRequest = new themis_common.Athlete()

  athleteRequest.setProgramId(programId)
  athleteRequest.setLocationId(locationId)
  athleteRequest.setId(athleteId)
  if (validateTeamId && validateTeamId > 0) athleteRequest.setValidateTeamId(validateTeamId)

  const athleteResponse = (await client.getAthlete(athleteRequest, {})).toObject()
  if (athleteResponse.athlete) return athleteResponse.athlete
}

export const getAthleteTeams = async (dispatch: AnyDispatchType, athleteId: number, programId: number, locationId: number, validateTeamId: number): Promise<themis_common.Team.AsObject[]> => {
  const athleteRequest = new themis_common.Athlete()

  athleteRequest.setProgramId(programId)
  athleteRequest.setLocationId(locationId)
  athleteRequest.setId(athleteId)
  if (validateTeamId && validateTeamId > 0) athleteRequest.setValidateTeamId(validateTeamId)

  const res = await client.getAthleteTeams(athleteRequest, {})
  const teamResponse = res.toObject()
  return teamResponse.teamsList

}

export const addAthlete = async (
  dispatch: Dispatch<Action<themis_common.Athlete.AsObject[]>>,
  athletePerson: themis_common.Person.AsObject,
  guardianPerson: themis_common.Person.AsObject,
  tiers: themis_common.Tier.AsObject[],
  types: themis_common.AllStarType.AsObject[],
  programId: number,
  locationId: number,
  guardianId: number
): Promise<themis_common.Athlete.AsObject> => {
  const athleteRequest = buildAthlete(undefined, guardianId, programId, locationId, athletePerson, guardianPerson, tiers, types)
  const athleteResponse: themis_api.AthleteResponse.AsObject = (await client.addAthlete(athleteRequest, {})).toObject()
  dispatch(setAthletes(athleteResponse.athletesList))
  const guardians = athleteResponse.guardiansList
  dispatch(setGuardians(guardians))

  if (athleteResponse.athlete) return athleteResponse.athlete
  throw new Error("Error adding athlete")
}

export const updateAthlete = async (
  dispatch: Dispatch<Action<themis_common.Athlete.AsObject[]>>,
  athleteId: number,
  athletePerson: themis_common.Person.AsObject,
  guardianPerson: themis_common.Person.AsObject,
  tiers: themis_common.Tier.AsObject[],
  types: themis_common.AllStarType.AsObject[],
  programId: number,
  locationId: number,
  validateTeamId: number
): Promise<void> => {

  const athleteRequest = buildAthlete(athleteId, undefined, programId, locationId, athletePerson, guardianPerson, tiers, types, validateTeamId)
  const athleteResponse: themis_api.AthleteResponse.AsObject = (await client.updateAthlete(athleteRequest, {})).toObject()
  const athletes = athleteResponse.athletesList
  dispatch(setAthletes(athletes))
}

export const autoCreateGuardiansforOver18 = async (): Promise<boolean> => {
  try {
    const emptyRequest = new themis_api.EmptyMessage()
    const res: themis_api.StatusResponse.AsObject = (await client.autoCreateGuardianOver18(emptyRequest, {})).toObject()
    if (!res.status) return false
  } catch (e) {
    return false
  }
  return true
}

export const findAthletesWithoutGuardians = async (): Promise<themis_common.Athlete.AsObject[]> => {
  const emptyRequest = new themis_api.EmptyMessage()
  const res: themis_api.AthleteResponse.AsObject = (await client.findAthletesWithoutGuardians(emptyRequest, {})).toObject()
  return res.athletesList
}

export const submitAthletes = async (dispatch: AnyDispatchType, athletesProto: any[], programId: number, locationId: number, teamId: number, dateFormat: number, hasMinimal: boolean) => {
  const addAthleteListRequest = new themis_api.AddAthleteListRequest()
  const newAthletes: any = []

  athletesProto.forEach((athlete: themis_common.Athlete.AsObject, i: number) => {
    if (!athlete?.person?.birthDate) return

    let athleteBirthDate: Date

    switch (dateFormat) {
      case 1:
        athleteBirthDate = parse(athlete.person.birthDate, "MM/dd/yyyy", new Date())
        break
      case 2:
        athleteBirthDate = parse(athlete.person.birthDate, "dd/MM/yyyy", new Date())
        break
      case 3:
        athleteBirthDate = parseISO(athlete.person.birthDate)
        break
      default:
        athleteBirthDate = parse(athlete.person.birthDate, "MM/dd/yyyy", new Date())
    }
    if (isNaN(athleteBirthDate.getTime())) {
      return
    }
    const age = differenceInCalendarYears(new Date(), athleteBirthDate)

    if (!(athlete.person.gender === 0) && !(athlete.person.gender === 1)) {
      return
    }

    if (!hasMinimal && age < 18) {
      if (athlete.athleteGuardiansList.length === 0 && !hasMinimal) {
        return
      } else if (!athlete.athleteGuardiansList.reduce((current: boolean, guardian: any) => current || (guardian?.name?.trim().length > 0 && isValidEmail(guardian?.person?.email?.length)), false) && !hasMinimal) {
        return
      }
    }

    const newAthlete = new themis_common.Athlete()
    const newPerson = new themis_common.Person()
    const newGuardian = new themis_common.Guardian()
    const newGuardianPerson = new themis_common.Person()
    const newGuardianList:themis_common.Guardian[] = []
    newPerson.setName(athlete.person.name)
    newPerson.setEmail(athlete.person.email)
    newPerson.setBirthDate(format(athleteBirthDate, "yyyy-MM-dd"))
    newPerson.setNickName(athlete.person.nickName)
    newPerson.setPreferredFirstName(athlete.person.preferredFirstName)
    newPerson.setLegalFirstName(athlete.person.legalFirstName)
    newPerson.setLegalMiddleName(athlete.person.legalMiddleName)
    newPerson.setLegalLastName(athlete.person.legalLastName)
    newPerson.setSuffix(athlete.person.suffix)
    newPerson.setGender(athlete.person.gender)
    newPerson.setCellPhone(athlete.person.cellPhone)
    newPerson.setHomePhone(athlete.person.homePhone)
    newPerson.setAddress1(athlete.person.address1)
    newPerson.setAddress2(athlete.person.address2)
    newPerson.setCity(athlete.person.city)
    newPerson.setCountry(athlete.person.country)
    newPerson.setState(athlete.person.state)
    newPerson.setPostalCode(athlete.person.postalCode)
    newPerson.setHasBirthCertificate(athlete.person.hasBirthCertificate)
    newGuardianPerson.setLegalFirstName(athlete.athleteGuardiansList?.[0].person?.legalFirstName ?? "")
    newGuardianPerson.setLegalLastName(athlete.athleteGuardiansList?.[0].person?.legalLastName ?? "")
    newGuardianPerson.setLegalMiddleName(athlete.athleteGuardiansList?.[0].person?.legalMiddleName ?? "")
    newGuardianPerson.setAddress1(athlete.athleteGuardiansList?.[0].person?.address1 ?? "")
    newGuardianPerson.setAddress2(athlete.athleteGuardiansList?.[0].person?.address2 ?? "")
    newGuardianPerson.setCity(athlete.athleteGuardiansList?.[0].person?.city ?? "")
    newGuardianPerson.setState(athlete.athleteGuardiansList?.[0].person?.state ?? "")
    newGuardianPerson.setCountry(athlete.athleteGuardiansList?.[0].person?.country ?? "")
    newGuardianPerson.setPostalCode(athlete.athleteGuardiansList?.[0].person?.postalCode ?? "")
    newGuardianPerson.setCellPhone(athlete.athleteGuardiansList?.[0].person?.cellPhone ?? "")
    newGuardianPerson.setEmail(athlete.athleteGuardiansList?.[0].person?.email ?? "")
    newGuardian.setName(athlete.athleteGuardiansList?.[0].person?.legalFirstName ?? "" + " " + athlete.athleteGuardiansList?.[0].person?.legalLastName ?? "")
    newGuardian.setPerson(newGuardianPerson)
    newGuardianList.push(newGuardian)
    newAthlete.setPerson(newPerson)
    newAthlete.setId(athlete.id)
    newAthlete.setName(athlete.person.name)
    newAthlete.setAthleteGuardiansList(newGuardianList)
    newAthletes.push(newAthlete)
  })

  addAthleteListRequest.setAthletesList(newAthletes)

  const importTeam = new themis_common.Team()
  const importLocation = new themis_common.Location()
  importTeam.setId(teamId)
  importTeam.setLocationId(locationId)
  importTeam.setProgramId(programId)
  importLocation.setId(locationId)
  importTeam.setLocation(importLocation)
  addAthleteListRequest.setTeam(importTeam)
  await client.addAthleteList(addAthleteListRequest, {})
  if (programId) doSetTeam(dispatch, programId, locationId, teamId)
}