// Common Imports
import React, { ReactElement, useState, useEffect } from 'react';
import { Grid, Paper, Card, Typography } from '@material-ui/core';
import { useStyles } from 'lib/theme';
import { FormValues } from 'lib/constants';

// Components Imports
import SwitchComponent from '../rubricComponents/Switch';
import TextboxAreaComponent from '../rubricComponents/Textboxarea';

interface RadioValue {
  rowIndex: number;
  colIndex: number;
  value: string;
}

interface TemplateFourProps {
  mode: string;
  templateData: any;
  responseData: any;
  initialValues?: any;
  judgeType?: string[];
}

const TemplateFour: React.FC<TemplateFourProps> = ({
  mode,
  templateData,
  responseData,
  initialValues,
  judgeType
}): ReactElement => {
  const classes = useStyles();
  const [objectName, setObjectName] = useState<string>(templateData.objectName);
  const [initialData, setInitialData] = useState<any>({});
  const [errorText, setErrorText] = useState<string>('');
  const [isComment, setIsComment] = useState<boolean>(mode == 'Edit' ? initialValues?.[objectName]?.['isComment'] : true);
  const [selectedTextboxValue, setSelectedTextboxValue] = useState<string>("");
  const [radioValues, setRadioValues] = useState<RadioValue[]>([]);
  const [response, setResponse] = useState<any>({
    [objectName]: {
      isComment: true
    }
  });

  useEffect(() => {
    responseData(response);
  }, [response]);

  useEffect(() => {
    if (mode == 'Edit') {
      setInitialData(initialValues?.[objectName]);
      setIsComment(initialValues?.[objectName]?.['isComment'])
      setSelectedTextboxValue(initialValues?.[objectName]?.['comment'])

      const initialRatings = initialValues?.[objectName]?.ratings;
      if (initialRatings && initialRatings.radioValues) {
        setRadioValues(initialRatings.radioValues);
      }

      setResponse({
        [objectName]: initialValues?.[objectName]
      })
    }
  }, [initialValues, objectName, mode]);

  // handles switch change event
  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    const updatedFormValues: any = { ...response };
    updatedFormValues[objectName][name] = checked;
    setResponse(updatedFormValues);
  };

  // handles textfield change event
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setSelectedTextboxValue(value);

    const updatedFormValues: any = { ...response };
    updatedFormValues[objectName][name] = value;
    setResponse(updatedFormValues);
  };

  // handles Radio change event
  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    colIndex: number
  ) => {
    const { value } = event.target;
    let updatedOptions = [...radioValues];

    // Check if the combination of rowIndex exists in prevRadioValues
    const indexToUpdate = radioValues.findIndex(item => item.rowIndex === rowIndex);
    if (indexToUpdate !== -1) {
      // If the combination exists, update its value
      updatedOptions[indexToUpdate] = { rowIndex, colIndex, value };
    } else {
      // If the combination doesn't exist, add a new item to the array
      updatedOptions.push({ rowIndex, colIndex, value });
    }
    setRadioValues(updatedOptions);

    const categoryDescriptionValues = [];
    for (const option of updatedOptions) {
      // Split the value string by "_&_" and extract the last part
      const parts = option.value.split("_&_");
      const lastPart = parts[parts.length - 1];
      // Push the extracted value to the categoryDescriptionValues array
      categoryDescriptionValues.push(lastPart);
    }

    const categoryDescriptionCount: any = {};
    for (const value of categoryDescriptionValues) {
      // Check if the value already exists in the categoryDescriptionCount object
      if (categoryDescriptionCount[value]) {
        categoryDescriptionCount[value]++;  // If it exists, increment its count
      } else {
        categoryDescriptionCount[value] = 1;  // If it doesn't exist, initialize its count to 1
      }
    }

    // Check if all values in categoryDescriptionCount are the same
    const allEqual = Object.values(categoryDescriptionCount).every(count => count === Object.values(categoryDescriptionCount)[0]);

    // If all values are equal and there is more than one value, display error message
    if (allEqual && Object.keys(categoryDescriptionCount).length > 1) {
      setErrorText("Insufficient data for rating. Please check your selection!");
    } else {
      setErrorText("");
    }

    let highestCountKey: string = "";
    let highestCountValue: number = 0;
    for (const [key, value] of Object.entries(categoryDescriptionCount)) {
      // Check if the current value is higher than the previously stored highest count
      if (typeof value === 'number') {
        if (value > highestCountValue) {
          // If it is, update the highest count key and value
          highestCountKey = key;
          highestCountValue = value;
        }
      }
    }

    const ratings = {
      score: highestCountValue,
      scoreDescription: highestCountKey,
      radioValues: updatedOptions,
      categoryDescriptionCount: categoryDescriptionCount
    };

    //Update the response state
    const updatedFormValues: any = { ...response };

    // Ensure response[objectName] is initialized
    updatedFormValues[objectName] = updatedFormValues[objectName] || {};
    updatedFormValues[objectName].ratings = ratings;
    setResponse(updatedFormValues);
  };

  const renderForm = () => {
    return Object.values(templateData?.data?.rows).map((row: any, index: number) => {
      return (
        <Grid container key={`row_${index}`}>
          {row.map((value: any, key: number) => {
            if (value.visible) {
              switch (value.type) {
                case FormValues.MATRIX:
                  return (
                    <Grid item xs={12} sm={6} md={12} key={`row_${index}_col_${key}`} style={{ marginTop: 10 }}>
                      <Card className={classes.cardSpacing} style={{ margin: 2 }}>
                        <table className={classes.templateMatrix}>
                          <thead>
                            <tr>
                              <th className={classes.templateMatrixTh}></th>
                              {value.matrixColumns.map((col: any, colIndex: number) => (
                                <th key={colIndex} className={classes.templateMatrixTh}>{col.label}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {value.matrixRows.map((row: any, rowIndex: number) => (
                              <tr key={`${rowIndex}`}>
                                <td className={classes.templateMatrixTd}>{row.label}</td>
                                {value.matrixColumns.map((col: any, colIndex: number) => {
                                  const radioValue = `${row.label}_&_${col.label}_&_${col.categoryDescription}`;
                                  const isChecked = radioValues.some(
                                    item => item.rowIndex === rowIndex && item.colIndex === colIndex && item.value === radioValue
                                  );
                                  return (
                                    <td className={classes.templateMatrixTd}>
                                      <input
                                        type="radio"
                                        id={`${rowIndex}__${colIndex}`}
                                        name={`row_${rowIndex}`}
                                        value={radioValue}
                                        className={classes.templateMatrixRadio}
                                        onChange={(event) => handleRadioChange(event, rowIndex, colIndex)}
                                        checked={isChecked}
                                      />
                                    </td>
                                  )
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <Grid item xs={12} style={{ margin: 5 }}>
                          <Paper>
                            <Typography variant="body1" color="error">
                              {errorText}
                            </Typography>
                          </Paper>
                        </Grid>
                      </Card>
                    </Grid>
                  );

                case FormValues.SWITCH:
                  return (
                    <Grid item xs={12} sm={4} md={2} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                      <SwitchComponent
                        element={value}
                        handleSwitchChange={handleSwitchChange}
                        setIsComment={setIsComment}
                        isComment={isComment}
                      />
                    </Grid>
                  );

                case FormValues.TEXTBOXAREA:
                  return (isComment) && (
                    <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                      <TextboxAreaComponent
                        element={value}
                        handleTextFieldChange={handleTextFieldChange}
                        selectedTextboxValue={selectedTextboxValue}
                      />
                    </Grid>
                  );

                default:
                  return null;
              }
            }
            return null;
          })}
        </Grid>
      );
    });
  };

  const renderFormElements = () => {
    const shouldDisplayElement = !templateData.judgeType || judgeType?.includes(templateData.judgeType);
    if (shouldDisplayElement) {
      return (
        <Grid container alignItems="center" className={classes.grouperForm} style={{ padding: '20px' }}>
          {renderForm()}
        </Grid>
      );
    }
  }

  return (
    <>
      {renderFormElements()}
    </>
  );
};

export default TemplateFour;
