import React, { ReactElement, useEffect, useState } from 'react'
import { Container, CssBaseline, FormControlLabel, Grid, Typography, Checkbox, Button, LinearProgress } from '@material-ui/core'
import { useStyles } from "lib/theme"
import { AsFields } from 'lib/constants'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserProducerPermissions, selectUserProgramPermissions } from 'store/user/userSelectors'
import { IAppState } from 'store/store'
import { refreshUser } from 'store/user/userActions'

interface onboard {
  importUSASF: boolean
  addNewProgram: boolean
  accountInvite: boolean
}

const Onboard: React.FC = (): ReactElement => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const producerPermissions = useSelector((state: IAppState) => selectUserProducerPermissions(state))

  const onboardDefault: onboard = {
    importUSASF: false,
    addNewProgram: false,
    accountInvite: false,
  }

  const [formReady, setFormReady] = useState<boolean>(false)
  const [onboard, setOnboard] = useState<AsFields>(onboardDefault)
  const [accountInviteMsg, setAccountInviteMsg] = useState<boolean>(false)
  const [oneMustBeSelectedError, setOneMustBeSelectedError] = useState<boolean>(false)

  useEffect(() => {
    if (Object.keys(programPermissions).length || Object.keys(producerPermissions).length) {
      history.push("/")
    }
  }, [programPermissions, producerPermissions, history])

  useEffect(() => {
    if (formReady) setOneMustBeSelectedError(false)
  }, [formReady])

  useEffect(() => {
    if ( accountInviteMsg ) {
      const milliseconds = 10 * 1000
      const interval = setInterval(() => {
        refreshUser(dispatch)
      }, milliseconds)
      return () => clearInterval(interval)
    }
  })

  const handleInputChange = async(e: any) => {
    const { name, checked } = e.target
    // If one option is checked, the other two will be set to false
    if (checked) {
      const updatedOnboard: onboard = Object.keys(onboardDefault).reduce((result, k) => {
        return { ...result, [k]: name === k }
      }, { ...onboardDefault })
      setOnboard(updatedOnboard)
      setFormReady(true)
    }
  }

  const submitForm: any = async (event: any) => {
    event.preventDefault()
    if (!formReady) setOneMustBeSelectedError(true)
    if (formReady) {
      if (onboard.importUSASF) {
        history.push('/USASFUpdate')
      } else if (onboard.addNewProgram) {
        history.push('/AddProgram')
      } else if (onboard.accountInvite) {
        setAccountInviteMsg(true)
      } else {
        history.push('/')
      }
    }
  }

  return !accountInviteMsg ? (
    <Container>
      <CssBaseline/>
      <Grid container>
        <Grid item xs={1}/>
        <Grid item xs={10}>
          <Typography variant="h1">Welcome</Typography>
          <Typography>
            Thank you for registering to Cheer Themis. If you experience any issues with the platform, please submit a short report which can be accessed by clicking the plus button (always visible on the lower right corner). In order to help you get started, which of the following best describes you?
          </Typography>
        </Grid>
      </Grid>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <form className={classes.form}
            noValidate
            onSubmit={submitForm}
          >
            <FormControlLabel
              className={classes.fullWidth}
              control={
                <Checkbox
                  checked={onboard.importUSASF}
                  onChange={handleInputChange}
                  name="importUSASF"
                  color="primary"
                />
              }
              label="I would like to import my data from USASF"
            />
            <FormControlLabel
              className={classes.fullWidth}
              control={
                <Checkbox
                  checked={onboard.addNewProgram}
                  onChange={handleInputChange}
                  name="addNewProgram"
                  color="primary"
                />
              }
              label="I want to create a new program"
            />
            <FormControlLabel
              className={classes.fullWidth}
              control={
                <Checkbox
                  checked={onboard.accountInvite}
                  onChange={handleInputChange}
                  name="accountInvite"
                  color="primary"
                />
              }
              label="Someone asked me to create an account"
            />
            <Typography variant={"subtitle1"} style={{ color: "#ff1744", fontWeight: "bold", fontSize: ".75rem", margin: 5 }} hidden={!oneMustBeSelectedError}>You must select one option</Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Continue
            </Button>
          </form>
        </div>
      </Container>
    </Container>
  )
    :
    <Container>
      <CssBaseline/>
      <Typography>
        Waiting for an authorized person to add you as a user. You will automatically be redirected as soon as you are added.
      </Typography>
      <LinearProgress color="primary" style={{ marginTop: 20 }}/>
    </Container>
}

export default Onboard
