import { Action, createAction } from 'redux-actions'
import { emptyProgram, ObjectUserPermissions, RegistrationPaymentData } from "./programReducer"
import { client } from "store/themisClient"
import themis_common from 'store/themis_common_pb'
import themis_api from 'store/themis_api_pb'
import { Dispatch } from "react"
import {
  AnyDispatchType,
  RESET_PROGRAM,
  SET_SELECTED_REGISTRATIONS_FOR_PAYMENT
} from "lib/constants"
import { refreshUser } from "store/user/userActions"

import { SET_PROGRAM } from "lib/constants"
import { doSetLocation } from "./locationActions"
import { doSetTeam } from "./teamActions"
import { buildProgram } from "./programBuilders"


const setProgram = createAction<themis_common.Program.AsObject>(SET_PROGRAM)
const resetProgram = createAction<void>(RESET_PROGRAM)
const setRegistrationPaymentRegs = createAction<RegistrationPaymentData>(SET_SELECTED_REGISTRATIONS_FOR_PAYMENT)

async function resetProgramState(dispatch: AnyDispatchType): Promise<void> {
  dispatch(resetProgram())
}

async function doSetProgram(dispatch: Dispatch<Action<themis_common.Program.AsObject>>, programId: number): Promise<void> {
  let program: themis_common.Program.AsObject
  if (programId > 0) {
    const getProgramRequest = new themis_common.Program()
    getProgramRequest.setId(programId)
    let programResponse: themis_api.ProgramResponse.AsObject = { programsList: [], program: undefined }
    try {
      const res = await client.getProgram(getProgramRequest, {})
      programResponse = res.toObject()
    } catch (e) {
      // console.log(e)
    }

    if (programResponse?.program?.id && programResponse.program.id > 0) {
      program = programResponse?.program || emptyProgram
    } else {
      program = { ...emptyProgram, id: programId }
    }
  } else {
    program = emptyProgram
  }

  dispatch(setProgram(program))
}

function addProgram(programDetails: themis_common.Program.AsObject) {
  return async (dispatch: AnyDispatchType): Promise<number> => {
    const addProgramRequest = buildProgram(programDetails)
    const res = await client.addProgram(addProgramRequest, {})
    const response = res.toObject()
    if (response.program.id > 0) {
      await refreshUser(dispatch)
      return response.program.id
    } else {
      throw new Error("Error creating program")
    }
  }
}

export const addProgramLogo = async(dispatch: AnyDispatchType, programId: number, image: string, fileName: string): Promise<themis_api.ProgramResponse.AsObject> => {
  const addLogoRequest = new themis_common.AddLogoRequest()
  addLogoRequest.setId(programId)
  addLogoRequest.setImage(image)
  addLogoRequest.setFileName(fileName)
  const response: themis_api.ProgramResponse.AsObject = (await client.addProgramLogo(addLogoRequest, {})).toObject()
  await doSetProgram(dispatch, programId)
  return response
}

async function clearProgram(dispatch: AnyDispatchType): Promise<void> {
  await doSetProgram(dispatch, -1)
  await doSetLocation(dispatch, -1, -1)
  await doSetTeam(dispatch, -1, -1, -1)
}

function updateProgram(programDetails: themis_common.Program.AsObject): () => Promise<void> {
  return async (): Promise<void> => {
    try {

      const updateProgramRequest = buildProgram(programDetails)
      await client.updateProgram(updateProgramRequest, {})
    } catch (e) {
      console.error(e)
    }
  }
}

async function deleteProgram(dispatch: Dispatch<void>, programId: number): Promise<void> {
  const deleteProgramRequest = new themis_common.Program()
  deleteProgramRequest.setId(programId)
  await client.deleteProgram(deleteProgramRequest, {})
  await refreshUser(dispatch)
}

async function getProgramUsers(programId: number): Promise<ObjectUserPermissions[]> { const program = new themis_common.Program()
  program.setId(programId)
  const response = (await client.getProgramUsers(program, {})).toObject()
  return response["usersPermissionsList"]
}

export const addSelectedRegistrationsForPayment = async (dispatch: AnyDispatchType, registrationPaymentData: RegistrationPaymentData): Promise<void> => {
  dispatch(setRegistrationPaymentRegs(registrationPaymentData))
}

export async function listProducerLink(programIds: number[]): Promise<themis_common.ProgramAllowedProducers.AsObject[]> {
  const request = new themis_api.ConnectProducerLinkRequest()
  request.setProgramIdsList(programIds)
  const response: themis_common.ProgramAllowedProducers.AsObject[] = (await client.listProducerLink(request, {})).toObject()
  return response
}

export {
  doSetProgram,
  addProgram,
  updateProgram,
  deleteProgram,
  resetProgramState,
  clearProgram,
  getProgramUsers,
}
