import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { addJudgeToBrand, deleteBrand, removeJudgeFromBrand } from "store/producer/brandActions"
import { IAppState } from "store/store"
import { selectCurrentBrand, selectCurrentEvents, selectCurrentProducer, selectProducerJudges } from "store/producer/producerSelectors"
import { selectCurrentPermissionLists, selectPermissionCodes, selectSuperUser, selectUserProducerPermissions } from "store/user/userSelectors"
import { refreshUser } from "store/user/userActions"
import { doSetBrand } from "store/producer/brandActions"
import { deleteEvent } from "store/producer/eventActions"
import themis_common from "store/themis_common_pb"
import Brand from './Brand'
import { useCallback } from 'react'

const BrandContainer: React.FC = (): ReactElement => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [currentTab, setCurrentTab] = useState(0)
  const [isBrandOpen, setIsBrandOpen] = useState(false)
  const [isEventOpen, setIsEventOpen] = useState(false)
  const [eventToDelete, setEventToDelete] = useState<themis_common.Event.AsObject | null>(null)

  const producer = useSelector((state: IAppState) => selectCurrentProducer(state))
  const brand = useSelector((state: IAppState) => selectCurrentBrand(state))
  const events = useSelector((state: IAppState) => selectCurrentEvents(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const producerPermissions = useSelector((state: IAppState) => selectUserProducerPermissions(state))
  const permissionLists = useSelector((state: IAppState) => selectCurrentPermissionLists(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))
  const producerJudges = useSelector((state: IAppState) => selectProducerJudges(state))

  const { brandId: inBrandId } = useParams<{brandId?: string | undefined}>()
  const brandId = Number(inBrandId)
  const producerId = useMemo(() => producer?.id || 0, [producer])

  const hashValues = useMemo(() => ['events', 'judges', 'users'], [])

  useEffect(() => {
    const hash = location.hash.toLowerCase().substr(1)
    if (hash === '') {
      history.replace('#' + hashValues[0])
    }
    const tab = hashValues.indexOf(location.hash.toLowerCase().substr(1))
    if (tab >= 0) setCurrentTab(tab)
  }, [hashValues, history, location])

  const handleChangeTab = (event: React.ChangeEvent<any>|null, newValue: number) => {
    if (hashValues[newValue]) history.push('#' + hashValues[newValue])
    setCurrentTab(newValue)
  }

  const handleDeleteBrand = async () => {
    await deleteBrand(dispatch, brand?.id || 0, producer?.id || 0)
    setIsBrandOpen(false)
    await refreshUser(dispatch)
    await doSetBrand(dispatch, 0, 0)
    history.push(`/Producer/${producer?.id}`)
  }

  const handleDeleteEvent = async () => {
    if (!eventToDelete?.id || !brand?.id || !producer?.id) return
    await deleteEvent(dispatch, eventToDelete.id, brand.id || 0, producer.id || 0)
    setIsEventOpen(false)
  }

  const setDeleteEvent = (event: themis_common.Event.AsObject) => {
    setEventToDelete(event)
    setIsEventOpen(true)
  }

  const handleAddJudgeToBrand = useCallback((judgeId: number) => {
    if (!judgeId) return
    addJudgeToBrand(dispatch, producerId, brandId, judgeId)
  }, [dispatch, producerId, brandId])

  const handleDeleteJudgeFromBrand = useCallback((judgeId: number) => {
    removeJudgeFromBrand(dispatch, producerId, brandId, judgeId)
  }, [dispatch, producerId, brandId])

  if (permissionLists && producer && brand && permissionCodes?.denyAccess.length) {
    return (
      <Brand
        superUser={superUser}
        producerPermissions={producerPermissions}
        permissionLists={permissionLists}
        permissionCodes={permissionCodes}
        brandId={brandId}
        producer={producer}
        brand={brand}
        events={events}
        currentTab={currentTab}
        handleChangeTab={handleChangeTab}
        handleDeleteBrand={handleDeleteBrand}
        handleDeleteEvent={handleDeleteEvent}
        isBrandOpen={isBrandOpen}
        isEventOpen={isEventOpen}
        setIsBrandOpen={setIsBrandOpen}
        setIsEventOpen={setIsEventOpen}
        setDeleteEvent={setDeleteEvent}
        eventToDelete={eventToDelete}
        handleAddJudgeToBrand={handleAddJudgeToBrand}
        handleDeleteJudgeFromBrand={handleDeleteJudgeFromBrand}
        producerJudges={producerJudges}
      />
    )
  }
  return <></>

}

export default BrandContainer
