
import React, { ReactElement } from 'react'
import { Button, InputLabel } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useLogoUploadState } from "components/util/LogoUploadContext"

const AddLogo: React.FC = (): ReactElement => {
  const { logoState, setLogoState } = useLogoUploadState()

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result?.toString())
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleFileRead = async (event: any) => {
    try {
      const file = event.target.files[0]
      const base64: any = await convertBase64(file)
      setLogoState({ ...logoState, fileName: await file.name, base64File: base64 })
      if (file.size > 10485760) {
        setLogoState({ ...logoState, fileName: await file.name, base64File: base64, fileSelected: false, maxFileSizeExceeded: true })
      } else {
        setLogoState({ ...logoState, fileName: await file.name, base64File: base64, fileSelected: true, maxFileSizeExceeded: false })
      }

    }
    catch (err) {
      console.log(err)
    }
  }



  return (
    <div>
      <div>
        <InputLabel>Logo</InputLabel>
        <img alt="Logo" src={logoState.base64File} style={{ maxHeight: "100px", maxWidth: "200px", alignSelf: "center" }} hidden={!logoState.fileSelected || logoState.maxFileSizeExceeded}/>
        <img alt="Logo" src={logoState.oldLogo} style={{ maxHeight: "100px", maxWidth: "200px", alignSelf: "center" }} hidden={logoState.fileSelected || logoState.oldLogo.length === 0}/>
        <b hidden={logoState.oldLogo.length > 0 || logoState.fileSelected}>Please select a logo</b>
        {logoState.maxFileSizeExceeded ? <Alert severity="error" style={{ marginBottom: "10px" }} >Maximum File Size (10MB) exceeded, please try a smaller file!</Alert> : <></>}
      </div>

      <Button
        variant="contained"
        component="label"
        color="primary">
        Select Logo
        <input
          type="file"
          accept="image/jpg, image/gif, image/png"
          onChange={handleFileRead}
          hidden
        />
      </Button>
    </div>
  )

}

export { AddLogo }

