import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Box,Container, Grid,Paper, Typography,FormControl, RadioGroup, Radio, FormControlLabel } from "@material-ui/core"
import AddCreditCard from "./AddCreditCard"
import AddBankAccount from "./AddBankAccount"

const AddPaymentSource: React.FC = (): React.ReactElement => {
  const history = useHistory()

  const [paymentMethodType, setPaymentMethodType] = useState<"credit-card" | "bank-account">("bank-account")

  const handleChangePaymentMethodType = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setPaymentMethodType(value as "credit-card" | "bank-account")
  }

  const handleFinishAddCreditCard = (success: boolean, errorMessage: string) => {
    history.push("/ManagePaymentMethods")
    return
  }

  // TODO: Permissions
  return <>
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="left" alignItems="center">
            <Typography variant="h1" style={{ marginRight: "10px", marginBottom: "20px" }}>Add Payment Method</Typography>
          </Box>
        </Grid>
      </Grid>
      <Container maxWidth="lg">
        <Paper elevation={2} key={`payment-method-type-selector`}>
          <Box m={1} p={2}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h2">Payment Method Type</Typography>
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">Payment Method Type</FormLabel> */}
                  <RadioGroup aria-label="payment-method-type" name="payment-method-type" value={paymentMethodType} onChange={handleChangePaymentMethodType}>
                    <FormControlLabel value="bank-account" control={<Radio />} label="Bank Account (ACH)" />
                    <FormControlLabel value="credit-card" control={<Radio />} label="Credit Card" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        {paymentMethodType === "bank-account" ? (
          // <Box mt={2} textAlign="center" key={`add-ach-form`}>
          //   <Grid container justify="center" alignItems="center">
          //     <Grid item xs={12} lg={8}>
          //       <Typography variant="h2">Add a Bank Account (ACH)</Typography>
          //       <AddBankAccount />
          //     </Grid>
          //   </Grid>
          // </Box>
          <AddBankAccount />
        ) : <></>}

        {paymentMethodType === "credit-card" ? (
          // <Box mt={2} textAlign="center" key={`add-credit-card-form`}>
          //   <Grid container justify="center" alignItems="center">
          //     <Grid item xs={12} lg={8}>
          //       <Typography variant="h2">Add a Credit Card</Typography>
          //       <AddCreditCard />
          //     </Grid>
          //   </Grid>
          // </Box>
          <AddCreditCard handleFinishAddCreditCard={handleFinishAddCreditCard}/>
        ) : <></>}

      </Container>
    </Container>
  </>
}

export default AddPaymentSource
