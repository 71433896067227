import React, { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import { Container, Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { emptyPerson } from "store/program/programReducer"
import AddEditPerson from "components/program/person/AddEditPerson"
import { refreshUser } from "store/user/userActions"
import themis_common from "store/themis_common_pb"
import { addJudge, getJudge, updateJudge } from "store/producer/judgeActions"
import { judgePersonFields } from "lib/constants"
import YesNoDialog from 'components/util/YesNoModal'
import { capitalizeFirstLetter, fieldsOkay } from 'lib/validators'
import SubmitButton from 'components/util/SubmitButton'
import { selectCurrentProducer } from 'store/producer/producerSelectors'
import { addJudgeToBrand } from 'store/producer/brandActions'

const AddEditJudge: React.FC = (): ReactElement => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { judgeId: inputJudgeId, brandId: inputBrandId } = useParams<{judgeId?: string | undefined, brandId?: string | undefined}>()
  const judgeId = Number(inputJudgeId)
  const brandId = Number(inputBrandId)

  const producer: themis_common.Producer.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProducer(state))

  const producerId = useMemo(() => producer?.id,[producer])

  useEffect(() => {
    load()
    async function load() {
      if (!producerId || !judgeId) return
      setJudge(await getJudge(judgeId, producerId, brandId))
    }
  }, [producerId, brandId, judgeId])

  const [person, setPerson] = useState(emptyPerson)
  const [judge, setJudge] = useState<themis_common.Judge.AsObject | undefined>(undefined)
  const [checkJudge, setCheckJudge] = useState(false)
  const [isErrorOpen, setIsErrorOpen] = useState(false)

  useEffect(() => {
    if (!judgeId || !judge?.person?.id) return
    setPerson(judge.person)
  }, [judgeId, judge])

  const create = !judgeId ? 'Create' : 'Edit'

  const creating = !judgeId ? 'creating' : 'editing'
  const creatingBool = !judgeId

  const formReady = useMemo(() => fieldsOkay(person, judgePersonFields), [person])

  const submitForm = async (event: FormEvent) => {
    setCheckJudge(true)
    event.preventDefault()

    if (!producerId) return

    const dest = brandId ? `/Brand/${brandId}` : `/Producer/${producerId}`
    if (!formReady) return
    if (judgeId) {
      // Editing Judge
      try {
        await updateJudge(dispatch, judgeId, person, producerId, brandId)
        // TODO: sending to "/" for now, need to make Judge component (TS-138 jira card)
        history.replace(dest + `/Judge/${judgeId}`)
      } catch (e) {
        setIsErrorOpen(true)
        console.error(e)
      }
    } else {
      // Creating Judge
      try {
        const judge = await addJudge(dispatch, person, producerId)
        await refreshUser(dispatch)
        // If adding from brand, send to brand judges tab
        if (brandId > 0) {
          await addJudgeToBrand(dispatch, producerId, brandId, judge.id)
          history.replace(dest + `#judges`)
        } else { // Adding from producer, send to judge page (producer route)
          history.replace(dest + `/Judge/${judge.id}`)
        }
      } catch (e) {
        setIsErrorOpen(true)
        console.error(e)
      }
    }
  }

  const headerContents = () => {
    if (judge?.name) {
      return `${create} Judge - ${judge.name}`
    } else {
      return `${create} Judge`
    }
  }

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{headerContents()}</Typography>
        </Grid>

        <Grid item xs={1}/>
        <Grid item xs={10}>
          <div className={classes.paper}>
            <AddEditPerson person={person}
              formId={0}
              updatePerson={setPerson}
              formReady={formReady}
              formFields={judgePersonFields}
              hiddenFields={{}}
              locationCountry={undefined}
              locationState={undefined}
              readonly={false}
              checkPerson={checkJudge}
              setCheckPerson={setCheckJudge}
              editBirthDate={true}
              creating={creatingBool}
            />
            <Grid container item xs={12}>
              <SubmitButton title={`${person.id ? 'Edit' : 'Create'} Judge`} ariaLabel="submit-judge-form" submitForm={submitForm} />
            </Grid>
          </div>
        </Grid>
        <YesNoDialog
          title={`Error ${capitalizeFirstLetter(creating)} Judge`}
          question={`There was an error ${creating} your judge. Please contact us if this continues to be a problem.`}
          isOpen={isErrorOpen}
          disabled={!formReady}
          onClose={() => setIsErrorOpen(false)}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
          ]}
        />

      </Grid>
    </Container>
  )
}

export default AddEditJudge
