import React, { ReactElement, useMemo } from "react"
import { Container, Grid, Typography, Card, CardContent, Button, useTheme } from "@material-ui/core"
import { validateProducerPermission } from "lib/permissions"
import { useStyles } from "lib/theme"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { selectCurrentProducer } from "store/producer/producerSelectors"
import { IAppState } from "store/store"
import { selectPermissionCodes, selectSuperUser, selectUserProducerPermissions } from "store/user/userSelectors"
import { createStripeProducerAccount, getProducerStripeOnBoardingUrl } from "store/producer/producerActions"

const CreateStripeProducerAccount: React.FC = (): ReactElement => {
  const { producerId: inputProducerId } = useParams<{ producerId?: string | undefined }>()
  const producerId = Number(inputProducerId)

  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const producer = useSelector((state: IAppState) => selectCurrentProducer(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const producerPermissions = useSelector((state: IAppState) => selectUserProducerPermissions(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const userCanViewProducer = useMemo((): boolean => {
    return validateProducerPermission(permissionCodes.producerView, permissionCodes, superUser, producerPermissions, producerId)
  }, [superUser, producerPermissions, producerId, permissionCodes])

  const getOnboardingLinkOrCreateAccount = async () => {
    // If Producer already has a stripe account id, just get onboarding url, else create account
    if (producer && producer?.stripeAccount.length > 0) {
      const getOnboardingUrlResponse = await getProducerStripeOnBoardingUrl(producerId)
      window.location.href = getOnboardingUrlResponse.url
    } else {
      const createStripeProducerAccountResponse = await createStripeProducerAccount(producerId)
      window.location.href = createStripeProducerAccountResponse.url
    }
  }

  return userCanViewProducer && permissionCodes?.denyAccess.length ? (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid container item xs={12} alignItems="flex-start">
          <Typography variant="h1" className={classes.headerTypo}>Create Merchant Account - {producer?.name}</Typography>
        </Grid>
        <Container component="main" maxWidth="md">
          <div className={classes.paper}>
            <Card key={`${producer?.id}-${producer?.name}`} className={classes.cardSpacing}>
              <Grid container>
                <Grid item md={7}>
                  <CardContent>
                    <Typography>Producer Email: {producer?.email}</Typography>
                    <Typography>Producer Country: {producer?.country}</Typography>
                  </CardContent>
                </Grid>
              </Grid>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      onClick={() => {history.push(`/EditProducer/${producerId}`)}}
                    >
                      Edit Producer
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }}
                      onClick={() => {getOnboardingLinkOrCreateAccount()}}
                    >
                      Create Merchant Account
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Container>
      </Grid>
    </Container>
  ) : <></>
}

export default CreateStripeProducerAccount
