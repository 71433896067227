import red from '@material-ui/core/colors/red'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
export const light = createMuiTheme({
  palette: {
    type: "light",
    common: {
      black: '#333',
      white: '#FFF',
    },
    primary: {
      main: '#b30808',
    },
    secondary: {
      main: '#000',
      light: 'rgba(0,0,0,.7)',
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: '#b30808',
      light: '#ffc400'
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Open Sans',
    h1: {
      fontFamily: 'Open Sans',
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: 'Open Sans',
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '1.2rem',
      },
      fontWeight: 'bold',
      lineHeight: '2.25rem',
    },
    h3: {
      fontFamily: 'Open Sans',
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '0.7rem',
      },
      fontWeight: 'bold',
      lineHeight: '1.75rem',
      display: 'inline-block'
    },
    h4: {
      fontFamily: 'Open Sans',
      fontSize: '.75rem',
      lineHeight: '1rem',
      '@media (min-width:600px)': {
        fontSize: '0.6rem',
      },
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    caption: {
      fontFamily: 'Open Sans',
      fontSize: '.75rem',
      '@media (min-width:600px)': {
        fontSize: '0.6rem',
      },
      lineHeight: '1rem',
      fontWeight: 'bold',
    },
  },
},
// createMuiTheme options
{ index: 2 }
)

export const dark = createMuiTheme({
  palette: {
    type: "dark",
    common: {
      black: '#333',
      white: '#fff',
    },
    primary: {
      main: '#cccccc',
    },
    secondary: {
      main: '#ddd',
      light: 'rgba(220,220,220,.7)',
    },
    warning: {
      main: '#b30808',
      light: '#ffc400'
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#111',
    },
    text: {
      primary: '#ddd'
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Open Sans',
    h1: {
      fontFamily: 'Open Sans',
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: 'Open Sans',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: '2.25rem',
    },
    h3: {
      fontFamily: 'Open Sans',
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: '1.75rem',
      display: 'inline-block'
    },
    h4: {
      fontFamily: 'Open Sans',
      fontSize: '.75rem',
      lineHeight: '1rem',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    caption: {
      fontFamily: 'Open Sans',
      fontSize: '.75rem',
      lineHeight: '1rem',
      fontWeight: 'bold',
    },
  },
},
// createMuiTheme options
{ index: 2 }
)

