import { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import HelperTextComponent from "./HelperText";

interface DropdownRangeProps {
    element: any;
    handleDropdownChange: Function;
    options: any;
    selectedDropdownValue: any
    setSelectedDropdownValue?: Function;
    type?: string,
    index?: number,
}

const DropdownRangeComponent: React.FC<DropdownRangeProps> = ({
    element,
    handleDropdownChange,
    options,
    selectedDropdownValue,
    setSelectedDropdownValue,
    type,
    index = 0
}) => {
    const [byDefaultValue, setByDefaultValue] = useState<any>(element.enums.length === 1 ? element.enums[0] : null);
    
    useEffect(() => {
        if (setSelectedDropdownValue) {
            setSelectedDropdownValue((element.enums.length === 1) ? element.enums[0] : null)
        }
    }, []);

    const getValue = () => {
        if (selectedDropdownValue) {
            return selectedDropdownValue || '';
        }
        return byDefaultValue || null;
    };

    return (
        <Grid container alignItems="center">
            <Grid item xs={11}>
                <Autocomplete
                    options={options}
                    value={getValue()}
                    fullWidth
                    getOptionLabel={(obj: any) => obj.label ? obj.label : obj.title}
                    disableClearable
                    id={element.key}
                    onChange={(event, value) => {
                        setByDefaultValue(value),
                            handleDropdownChange(value, element, index, type),
                            setSelectedDropdownValue && setSelectedDropdownValue(value)
                    }}

                    renderInput={(params) => (
                        <TextField
                            style={{ marginTop: 16, marginBottom: 8 }}
                            {...params}
                            autoComplete="none"
                            label={element.title}
                            name={element.key}
                            required={element.required}
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={1}>
                <HelperTextComponent element={element}/>
            </Grid>
        </Grid>
    );
};

export default DropdownRangeComponent;
