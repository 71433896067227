import React, { ReactElement, useEffect } from 'react'
import { Container, Typography } from "@material-ui/core"
import { JudgeEventPermissions, ProducerPermissions, ProgramPermissions } from "store/user/userReducer"
import { useSelector } from "react-redux"
import { IAppState } from "store/store"
import {
  selectBothSides,
  selectCurrentLoginState,
  selectTryLogin,
  selectUserJudgeEvents,
  selectUserProducers,
  selectUserPrograms
} from "store/user/userSelectors"
import { useHistory } from "react-router-dom"

const Splash: React.FC = (): ReactElement => {
  const userType: string = localStorage.getItem('programOrProducer') || 'program'
  const programs: ProgramPermissions = useSelector((state: IAppState) => selectUserPrograms(state))
  const producers: ProducerPermissions = useSelector((state: IAppState) => selectUserProducers(state))
  const judgeEvents: JudgeEventPermissions = useSelector((state:IAppState) => selectUserJudgeEvents(state))
  const bothSides = useSelector((state: IAppState) => selectBothSides(state))
  const loggedIn = useSelector((state: IAppState) => selectCurrentLoginState(state))
  const tryLogin = useSelector((state: IAppState) => selectTryLogin(state))
  const history = useHistory()

  useEffect(() => {
    console.log(userType, bothSides.judge, Object.keys(judgeEvents)[0])
    console.log("bothsides", bothSides)
    console.log("usertype", userType)
    const storageProducerLink = localStorage.getItem('producerConnectLink')
    if (!loggedIn || tryLogin) {
      // If not loggedIn or loggingIn, return
      return
    } else if (storageProducerLink && Object.entries(programs).length > 1 && bothSides.program) {
      // If logging in from connect link and have more than one program, take them to event producers page
      history.push(storageProducerLink)
      localStorage.removeItem('producerConnectLink')
    }
    if (!bothSides.program && !bothSides.producer && !bothSides.judge) {
      history.push("/onboard")
      return
    }
    if (userType === 'program' && bothSides.program) {
      // Find the first one in the list and use that
      const allLocationIds: string[] = []
      for (const [key, value] of Object.entries(programs)) {
        const ids = Object.keys(value.locations)
        allLocationIds.push(...ids)
      }
      if (Object.keys(programs).length) {
        // Go to latest program or location else go to first valid item
        if (localStorage.latestProgramId && Object.keys(programs).includes(localStorage.latestProgramId)) {
          const latestProgramId = localStorage.getItem('latestProgramId')
          history.replace(`/Program/${latestProgramId}`)
        } else if (localStorage.latestLocationId && allLocationIds.includes(localStorage.latestLocationId)) {
          const latestLocationId = localStorage.getItem('latestLocationId')
          history.replace(`/Location/${latestLocationId}`)
        } else {
          Object.keys(programs).forEach(programId => {
            if (programs[programId].name > "") history.replace(`/Program/${programId}`)
          })
          // if we are still here then we have no direct programs, look for a location
          Object.keys(programs).forEach(programId => {
            if (Object.keys(programs[programId].locations).length > 0) {
              const locationId = Object.keys(programs[programId].locations)[0]
              history.replace(`/Location/${locationId}`)
            }
          })
        }
      } else {
        history.replace(`/AddProgram`)
      }
    } else if (userType === 'producer' && bothSides.producer) {
      const allBrandIds: string[] = []
      for (const [key, value] of Object.entries(producers)) {
        const ids = Object.keys(value.brands)
        allBrandIds.push(...ids)
      }

      if (Object.keys(producers).length) {
        const connectionLink = localStorage.getItem("connectionLink")
        if (localStorage.latestProducerId && Object.keys(producers).includes(localStorage.latestProducerId)) {
          const latestProducerId = localStorage.getItem('latestProducerId')
          history.replace(`/Producer/${latestProducerId}`)
        } else if (localStorage.latestBrandId && allBrandIds.includes(localStorage.latestBrandId)) {
          const latestBrandId = localStorage.getItem('latestBrandId')
          history.replace(`/Brand/${latestBrandId}`)
        } else {
          let producerId
          let brandId
          Object.keys(producers).forEach(currentProducerId => {
            if (producers[currentProducerId].perms.length) {
              producerId = currentProducerId
              history.replace(`/Producer/${producerId}`)
            }
          })
          if (!producerId) {
            Object.keys(producers).forEach(currentProducerId => {
              if (Object.keys(producers[currentProducerId].brands).length) {
                Object.keys(producers[currentProducerId].brands).forEach(currentBrandId => {
                  if (producers[currentProducerId].brands[currentBrandId].perms.length) {
                    brandId = currentBrandId
                    history.replace(`/Brand/${brandId}`)
                  }
                })
              }
            })
          } else {
            history.replace(`/Producer/${producerId}`)
          }
        }
      }  else { // Set at producer but we have no producers
        localStorage.setItem('programOrProducer', 'program')
        if (Object.keys(programs).length) {
          if (localStorage.latestProgramId) {
            const latestProgramId = localStorage.getItem('latestProgramId')
            history.replace(`/Program/${latestProgramId}`)
          } else if (localStorage.latestLocationId) {
            const latestLocationId = localStorage.getItem('latestLocationId')
            history.replace(`/Location/${latestLocationId}`)
          } else {
            Object.keys(programs).forEach(programId => {
              if (programs[programId].name > "") history.replace(`/Program/${programId}`)
            })
            // if we are still here then we have no direct programs, look for a location
            Object.keys(programs).forEach(programId => {
              if (Object.keys(programs[programId].locations).length > 0) {
                const locationId = Object.keys(programs[programId].locations)[0]
                history.replace(`/Location/${locationId}`)
              }
            })
          }
        } else {
          history.replace(`/AddProgram`)
        }
      }
    } else if (userType === 'judge' && bothSides.judge && Object.keys(judgeEvents)[0]) {
      console.log('JUDGE')
      history.replace(`/JudgeEvent/${Object.keys(judgeEvents)[0]}`)
    } else {
      history.push("/onboard")
    }
  }, [loggedIn, tryLogin, programs, producers, userType, history, bothSides.program, bothSides.producer])

  return (
    <Container>
      <Typography variant="h1">Redirecting...</Typography>
    </Container>
  )
}

export default Splash
