import { FormControlLabel, Switch } from "@material-ui/core";

interface SwitchProps {
    element: any;
    handleSwitchChange: Function;
    isComment: boolean;
    setIsComment?: Function;
    type?: string;
    index?: number;
}

const SwitchComponent: React.FC<SwitchProps> = ({
    element,
    handleSwitchChange,
    isComment, 
    setIsComment,
    type,
    index = 0
}) => {
    return (
        <FormControlLabel
            control={
                <Switch 
                    name={element.key}
                    defaultChecked={isComment ? isComment || false : undefined}
                    checked={isComment ? isComment || false : undefined}
                    onChange={(event: any) => {
                        handleSwitchChange(event, index, type);
                        setIsComment && setIsComment(event.target.checked);
                    }} 
                />
            }
            label={element.title}
        />
    );
};

export default SwitchComponent;