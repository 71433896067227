/* eslint max-lines: "off" */
import React, { ChangeEvent, FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import {
  AppBar,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
  useTheme,
  Box,
  Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core'
import { useStyles, Transition } from 'lib/theme'

import themis_common, { Athlete, Coach, Event, EventDivision, Personnel } from 'store/themis_common_pb'
import { makeMoney, sortAthletes, sortCoaches, sortPersonnel } from "../../../lib/functions"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { teamDivisionError } from "../../../lib/validators"
import { TabPanel } from "../../../lib/pieces"
import { Alert } from "@material-ui/lab"
import EventRegistrationAthlete from "./EventRegistrationAthletes"
import EventRegistrationCoaches from "./EventRegistrationCoaches"
import EventRegistrationPersonnel from "./EventRegistrationPersonnel"
import EventRegistrationDetail from "./EventRegistrationDetails"
import { EventTeamPayments } from "../../../store/program/programReducer"
import ThemisButton from 'components/util/ThemisButton'
import { useHistory } from "react-router-dom"
import { regErrorObject } from "./EventRegistrationContainer"

type eventRegistrationProps = {
  locationId: number
  teamId: number
  currentTab: number,
  event: Event.AsObject
  registrationPrice: string,
  registrationPriceId: number,
  registrationDiscountCode: string,
  setRegistrationDiscountCode: React.Dispatch<React.SetStateAction<string>>,
  foundRegistrationDiscountCode: themis_common.EventRegistrationCode.AsObject,
  validAthletes: Athlete.AsObject[]
  validCoaches: Coach.AsObject[]
  validPersonnel: Personnel.AsObject[]
  teamAthletes: Athlete.AsObject[]
  teamCoaches: Coach.AsObject[]
  teamPersonnel: Personnel.AsObject[]
  currentCrossovers: Athlete.AsObject[]
  selectedEventDivision: EventDivision.AsObject
  teamErrors: teamDivisionError[],
  registrationIssues: regErrorObject[],
  readyToRegister: boolean,
  registerTeam: Function
  unregisterTeam: Function,
  addAthlete: Function
  removeAthlete: Function,
  addCoach: Function,
  removeCoach: Function,
  addPersonnel: Function,
  removePersonnel: Function,
  setSelectedEventDivision: Function,
  handleChangeTab: (event: ChangeEvent<{}>, value: number) => void,
  eventTeamId: number,
  eventTeam: themis_common.EventTeam.AsObject,
  eventTeamTransactions: EventTeamPayments,
  eventTeamBalance: themis_common.EventTeamBalance.AsObject | undefined,
  payBalance: (amountSelectedToPay: number, eventTeamId: number, deposit: boolean) => void,
  eventTeamSignStatus: themis_common.AthleteSignStatus.AsObject[],
  handleResendEmail: Function
  calculatedAthletePrices: themis_common.CalculatedAthletePricing.AsObject[]
  checkGuardians: Function

  submitChangeDivision: () => void
  newEventTeamDivision: themis_common.EventDivision.AsObject
  setNewEventTeamDivision: React.Dispatch<React.SetStateAction<themis_common.EventDivision.AsObject>>
  changeDivisionDialogOpen: boolean
  setChangeDivisionDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  changeDivisionError: boolean
  setChangeDivisionError: React.Dispatch<React.SetStateAction<boolean>>
  changeDivisionTeamErrors: teamDivisionError[]
  newEventTeamRegCode: string
  setNewEventTeamRegCode: React.Dispatch<React.SetStateAction<string>>
  changeDivisionFoundCode: themis_common.EventRegistrationCode.AsObject
  dateComparison: boolean
  isRegisterButtonDisabled: boolean
  isUnregisterButtonDisabled: boolean
}

const EventRegistration: FunctionComponent<eventRegistrationProps> = ({
  locationId,
  teamId,
  currentTab,
  event,
  registrationPrice,
  registrationPriceId,
  registrationDiscountCode,
  setRegistrationDiscountCode,
  foundRegistrationDiscountCode,
  validAthletes,
  validCoaches,
  validPersonnel,
  teamAthletes,
  teamPersonnel,
  teamCoaches,
  currentCrossovers,
  selectedEventDivision,
  teamErrors,
  registrationIssues,
  readyToRegister,
  setSelectedEventDivision,
  registerTeam,
  unregisterTeam,
  addAthlete,
  removeAthlete,
  addCoach,
  removeCoach,
  addPersonnel,
  removePersonnel,
  handleChangeTab,
  eventTeamId,
  eventTeam,
  eventTeamTransactions,
  eventTeamBalance,
  payBalance,
  eventTeamSignStatus,
  handleResendEmail,
  calculatedAthletePrices,
  checkGuardians,
  submitChangeDivision,
  newEventTeamDivision,
  setNewEventTeamDivision,
  changeDivisionDialogOpen,
  setChangeDivisionDialogOpen,
  changeDivisionError,
  setChangeDivisionError,
  changeDivisionTeamErrors,
  newEventTeamRegCode,
  setNewEventTeamRegCode,
  changeDivisionFoundCode,
  dateComparison,
  isRegisterButtonDisabled,
  isUnregisterButtonDisabled
}): ReactElement => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const sortedAthletes = useMemo<Athlete.AsObject[]>(() => teamAthletes ? sortAthletes(teamAthletes) : [], [teamAthletes])
  const sortedValidAthletes = useMemo<Athlete.AsObject[]>(() => validAthletes ? sortAthletes(validAthletes) : [], [validAthletes])
  const sortedCoaches = useMemo<Coach.AsObject[]>(() => teamCoaches ? sortCoaches(teamCoaches) : [], [teamCoaches])
  const sortedValidCoaches = useMemo<Coach.AsObject[]>(() => validCoaches ? sortCoaches(validCoaches) : [], [validCoaches])
  const sortedPersonnel = useMemo<Personnel.AsObject[]>(() => teamPersonnel ? sortPersonnel(teamPersonnel) : [], [teamPersonnel])
  const sortedValidPersonnel = useMemo<Personnel.AsObject[]>(() => validPersonnel ? sortPersonnel(validPersonnel) : [], [validPersonnel])
  const hashValues = useMemo(() => ['details', 'athletes', 'coaches', 'personnel'], [])

  const [eventBegins, setEventBegins] = useState<string>()
  const [eventEnds, setEventEnds] = useState<string>()


  const formatClockTimeForDiscountCode = (endClockTime?: themis_common.ClockTime.AsObject) => {
    const e = endClockTime?.formattedDateTime
    if (e) {
      return `${e.monthLong} ${e.dayNumber} ${e.year}`
    }
    return ""
  }

  useEffect(() => {
    if (eventTeamId) {
      setNewEventTeamDivision(selectedEventDivision)
      setNewEventTeamRegCode(registrationDiscountCode)
    }
  }, [registrationDiscountCode, selectedEventDivision, eventTeamId, setNewEventTeamDivision, setNewEventTeamRegCode])

  const eventRegistrationCodeHelperText = useMemo(() => {
    if (foundRegistrationDiscountCode.id <= 0) return ""
    if (foundRegistrationDiscountCode.discountAppliesTo === "DivisionList" && !foundRegistrationDiscountCode.eventDivisionsList.find(eventDivision => {
      return eventDivision.id === selectedEventDivision.id
    })) return ""
    const discountValue = foundRegistrationDiscountCode.depositType === 'athlete' ? teamAthletes.length * foundRegistrationDiscountCode.depositAmount : foundRegistrationDiscountCode.depositAmount
    const discountInfo = foundRegistrationDiscountCode.depositAmount > 0 ? `Deposit of ${makeMoney(discountValue, event.currency)} required by` : "Valid until"
    if (foundRegistrationDiscountCode.discountType === 0) {
      // Percent Type
      return `${foundRegistrationDiscountCode.amountString}% discount - ${discountInfo} ${formatClockTimeForDiscountCode(foundRegistrationDiscountCode.expiresOnClockTime)}`
    }
    if (foundRegistrationDiscountCode.discountType === 1) {
      // Dollar Type
      // TODO: ${currencySymbol}
      return `USD $${foundRegistrationDiscountCode.amountString} discount per ${foundRegistrationDiscountCode.discountPerType} - ${discountInfo} ${formatClockTimeForDiscountCode(foundRegistrationDiscountCode.expiresOnClockTime)}`
    }
    return ""
  }, [foundRegistrationDiscountCode, teamAthletes, selectedEventDivision])

  const changeDivisionEventRegistrationCodeHelperText = useMemo(() => {
    if (changeDivisionFoundCode.id <= 0) return ""
    if (changeDivisionFoundCode.discountAppliesTo === "DivisionList" && !changeDivisionFoundCode.eventDivisionsList.find(eventDivision => {
      return eventDivision.id === newEventTeamDivision.id
    })) return ""
    const discountValue = changeDivisionFoundCode.depositType === 'athlete' ? teamAthletes.length * changeDivisionFoundCode.depositAmount : changeDivisionFoundCode.depositAmount
    const discountInfo = changeDivisionFoundCode.depositAmount > 0 ? `Deposit of ${makeMoney(discountValue, event.currency)} required by` : "Valid until"
    if (changeDivisionFoundCode.discountType === 0) {
      // Percent Type
      return `${changeDivisionFoundCode.amountString}% discount - ${discountInfo} ${formatClockTimeForDiscountCode(changeDivisionFoundCode.expiresOnClockTime)}`
    }
    if (changeDivisionFoundCode.discountType === 1) {
      // Dollar Type
      // TODO: ${currencySymbol}
      return `USD $${changeDivisionFoundCode.amountString} discount per ${changeDivisionFoundCode.discountPerType} - ${discountInfo} ${formatClockTimeForDiscountCode(changeDivisionFoundCode.expiresOnClockTime)}`
    }
    return ""
  }, [changeDivisionFoundCode, teamAthletes, newEventTeamDivision, event.currency])

  useEffect(() => {
    if (!event.eventDatesList || event.eventDatesList.length === 0) return
    // sortedDates was actually a pointer to event.eventDatesList
    const sortedDates = [...event.eventDatesList].sort((a, b) => {
      if (a.startDay > b.startDay) return 1
      if (a.startDay < b.startDay) return 0
      return 0
    })
    const formattedTimeStart = event.eventDatesList[0].startClockTime?.formattedDateTime
    const eventBeginsDate = `${formattedTimeStart?.monthLong} ${formattedTimeStart?.dayNumber}, ${formattedTimeStart?.year}`
    setEventBegins(eventBeginsDate)

    if (sortedDates.length > 1) {
      const lastDay = sortedDates[-1]
      if (lastDay) {
        const formattedTimeEnd = lastDay.startClockTime?.formattedDateTime
        const eventEndsDate = `${formattedTimeEnd?.monthLong} ${formattedTimeEnd?.dayNumber}, ${formattedTimeEnd?.year}`
        setEventEnds(eventEndsDate)
      }
      return
    }
  }, [event.eventDatesList, event.eventDatesList.length]) // Do we need the .length?


  const sortedEventDivisions = useMemo(() => {
    return event.eventDivisionList.sort((a, b) => {
      if (!a.division?.name || !b.division?.name) return 0
      if (a.division.name > b.division.name) return 1
      if (a.division.name < b.division.name) return -1
      return 0
    })
  }, [event.eventDivisionList])

  const handleChangeDivisionDialogClose = () => {
    setChangeDivisionDialogOpen(false)
    setChangeDivisionError(false)
  }



  const handleChangeEventTeamDivision = () => {
    setChangeDivisionDialogOpen(true)
    if (eventTeam?.eventDivision) setSelectedEventDivision(eventTeam.eventDivision)
    if (eventTeam?.eventRegistrationCode) setRegistrationDiscountCode(eventTeam.eventRegistrationCode.code)
  }

  const changeDivisionDialog = (
    <Dialog
      open={changeDivisionDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title">Change Event Team Division</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth={true}
              id="Pick Division"
              size="small"
              options={sortedEventDivisions}
              getOptionSelected={(option, value) => option.id === value.id}
              value={newEventTeamDivision}
              getOptionLabel={(eventDivision: themis_common.EventDivision.AsObject) => eventDivision.division?.tier?.name + " - " + eventDivision.division?.name}
              renderInput={(params) => <TextField {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'off', style: { padding: "12px" } }} // disable autocomplete and autofill
                label="Select Division"
                variant="outlined"
                margin="normal"
                fullWidth
              />}
              onChange={(e, v) => {
                v && setNewEventTeamDivision(v)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required={false}
              label={"Discount Code"}
              helperText={changeDivisionEventRegistrationCodeHelperText}
              fullWidth
              id={`${"eventRegistrationCode"}-${1}`}
              value={newEventTeamRegCode}
              name={"eventRegistrationCode"}
              onChange={(e) => {
                setNewEventTeamRegCode(e.target.value)
              }}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                // Limit to 10, uppercase, replace space with _
                e.target.value = e.target.value.slice(0, 10).toUpperCase().split(' ').join('_')
              }}

            />
          </Grid>
        </Grid>
      </DialogContent>
      {changeDivisionError ? <Box pl={4}><Typography className={classes.warning} display="inline" variant="body2">New division not compatible with current team athletes.</Typography></Box> : <></>}
      <DialogActions>
        <Button onClick={() => handleChangeDivisionDialogClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => submitChangeDivision()} color="primary">
          Change
        </Button>
      </DialogActions>
    </Dialog>
  )

  return ( //(!currentLocation || !currentTeam) ? <></> : (
    <Container maxWidth="lg" style={{ maxWidth: "none" }}>
      <Grid container>
        <Typography variant="h1" className={classes.fullWidth}>Event Registration
          - {event.name} ({eventBegins?.split("|")[0].trim()}{eventEnds ? <>- {eventEnds?.split("|")[0].trim()} </> : <></>})</Typography>
        <AppBar position="static" style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }}>
          <Toolbar>
            <Grid container spacing={1} justify="center" alignItems="flex-start" >
              <Grid item xs={12} md={6}>
                <Tabs value={currentTab} onChange={handleChangeTab} variant="scrollable" style={{ marginTop: "4px" }} TabIndicatorProps={{ style: { backgroundColor: theme.palette.common.white } }}>
                  <Tab label="Details" id="tab-1" />
                  <Tab label="Athletes" id="tab-2" />
                  <Tab label="Coaches" id="tab-3" />
                  <Tab label="Personnel" id="tab-4" />
                </Tabs>
              </Grid>
              <Grid item xs={6} md={2}>
                {
                  <Autocomplete
                    className={classes.formColorOverPrimary}
                    fullWidth={true}
                    disabled={!event.eventDivisionList || event.eventDivisionList.length === 0 || eventTeamId > 0 || !dateComparison}
                    id="Pick Division"
                    size="small"
                    options={sortedEventDivisions}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={selectedEventDivision}
                    getOptionLabel={(eventDivision: themis_common.EventDivision.AsObject) => eventDivision.division?.tier?.name + " - " + eventDivision.division?.name}
                    // style={{ width: 300, marginBottom: "4px", marginLeft: "128px", paddingTop: "8px" }}
                    renderInput={(params) => <TextField {...params}
                      inputProps={{ ...params.inputProps, autoComplete: 'off', style: { padding: "12px" } }} // disable autocomplete and autofill
                      label="Select Division"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />}
                    onChange={(e, v) => {
                      v && setSelectedEventDivision(v)
                    }}
                  />}
              </Grid>
              <Grid item xs={6} md={2}>
                {
                  <TextField
                    className={classes.formColorOverPrimary}
                    disabled={eventTeamId > 0 || !dateComparison}
                    variant="outlined"
                    margin="normal"
                    required={false}
                    label={"Discount Code"}
                    // error={!!errorText[name]}
                    helperText={eventRegistrationCodeHelperText}
                    fullWidth
                    id={`${"eventRegistrationCode"}-${1}`}
                    value={registrationDiscountCode}
                    name={"eventRegistrationCode"}
                    onChange={(e) => { setRegistrationDiscountCode(e.target.value) }}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      // Limit to 10, uppercase, replace space with _
                      e.target.value = e.target.value.slice(0, 10).toUpperCase().split(' ').join('_')
                    }}
                  />}
              </Grid>
              <Grid item xs={4} md={2} style={dateComparison ? { marginBottom: "12px", marginTop: "18px" } : { marginBottom: "12px" }}>
                {
                  eventTeamId && dateComparison ?
                    <Button variant="outlined" color="inherit" size="small" style={{ padding: "10px", marginRight: "4px", minWidth: "150px", marginBottom: "2px" }} onClick={e => {
                      handleChangeEventTeamDivision()
                    }}>
                      Change Division
                    </Button>
                    :
                    <></>
                }
                {(registrationPrice === 'NaN' || Number(registrationPrice) === 0) && !eventTeamId ? <span>No Registration Pricing!</span> :
                  !dateComparison ?
                    <Button disabled variant="outlined" color="inherit" style={{ marginLeft: "16px", marginTop: "16px", marginBottom: "8px", padding: "14px" }}
                    >
                      Expired Event
                    </Button> :
                    eventTeamId ?
                      <ThemisButton
                        isDisabled={isUnregisterButtonDisabled}
                        title="Un-Register"
                        submitAction={e => unregisterTeam()}
                        style={{ padding: "10px", minWidth: "150px", marginBottom: "2px" }}
                        color="inherit"
                        variant="outlined"
                        size="small"
                        spinnerColor="inherit"
                        spinnerSize={30}
                      />
                      :
                      <ThemisButton
                        isDisabled={isRegisterButtonDisabled || !readyToRegister}
                        title={`Register for ${makeMoney(Number(registrationPrice) * 100, event.currency)}`}
                        submitAction={e => registerTeam()}
                        additionalDisabler={readyToRegister}
                        style={{ marginLeft: "16px", padding: "14px" }}
                        color="inherit"
                        variant="outlined"
                        spinnerColor="inherit"
                        spinnerSize={30}
                      />
                }
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {registrationIssues.length && dateComparison ?
          <Container>
            <Grid container item xs={12}>
              <Typography variant="h2">Issues to Resolve <small>(Click on error to fix.)</small></Typography>
            </Grid>
            <Grid container item xs={12}>
              {
                registrationIssues.map((issue, i) => <Alert style={{ cursor: "pointer" }} onClick={() => {history.push(`#${issue.hash}`); console.log(issue)}} key={`alert-${i}`} severity="error">{issue.name}</Alert>)
              }
            </Grid>
          </Container>
          : <></>}
        <TabPanel value={currentTab} index={0}>
          <EventRegistrationDetail event={event} eventTeam={eventTeam} eventTeamBalance={eventTeamBalance} payBalance={payBalance} eventTeamTransactions={eventTeamTransactions} calculatedAthletePrices={calculatedAthletePrices} dateComparison={dateComparison} />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <EventRegistrationAthlete sortedAthletes={sortedAthletes} sortedValidAthletes={sortedValidAthletes} currentCrossovers={currentCrossovers} addAthlete={addAthlete} removeAthlete={removeAthlete} selectedEventDivision={selectedEventDivision} teamErrors={teamErrors} eventTeamId={eventTeamId} locationId={locationId} checkGuardians={checkGuardians} eventTeamSignStatus={eventTeamSignStatus} handleResendEmail={handleResendEmail} dateComparison={dateComparison} guardianRequired={event?.brand?.guardianRequired} />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <EventRegistrationCoaches sortedCoaches={sortedCoaches} sortedValidCoaches={sortedValidCoaches} addCoach={addCoach} removeCoach={removeCoach} eventTeamId={eventTeamId} dateComparison={dateComparison} />
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <EventRegistrationPersonnel sortedPersonnel={sortedPersonnel} sortedValidPersonnel={sortedValidPersonnel} addPersonnel={addPersonnel} removePersonnel={removePersonnel} eventTeamId={eventTeamId} dateComparison={dateComparison} />
        </TabPanel>
      </Grid>
      {changeDivisionDialog}
    </Container>

  )
}

export default EventRegistration
