import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentTeam, selectCurrentProgram, selectCurrentLocation, selectLocationAthletes, selectLocationCoaches, selectLocationPersonnel } from "store/program/programSelectors"
import { selectCurrentPermissionLists, selectPermissionCodes, selectSuperUser, selectUserProgramPermissions } from "store/user/userSelectors"
import { addAthleteToTeam, addCoachToTeam, addPersonnelToTeam, deleteTeam, removeAthleteFromTeam, removeCoachFromTeam, removePersonnelFromTeam } from "store/program/teamActions"
import Team from './Team'
import { addAthletesToTeam } from 'store/program/teamActions'
import themis_common from "store/themis_common_pb"

const TeamContainer: React.FC = (): ReactElement => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const program = useSelector((state: IAppState) => selectCurrentProgram(state))
  const currentLocation = useSelector((state: IAppState) => selectCurrentLocation(state))
  const team = useSelector((state: IAppState) => selectCurrentTeam(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const permissionLists = useSelector((state: IAppState) => selectCurrentPermissionLists(state))
  const locationAthletes = useSelector((state: IAppState) => selectLocationAthletes(state))
  const locationCoaches = useSelector((state: IAppState) => selectLocationCoaches(state))
  const locationPersonnels = useSelector((state: IAppState) => selectLocationPersonnel(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const { teamId: inputTeamId } = useParams<{teamId: string}>()
  const teamId = Number(inputTeamId)
  const locationId = Number(currentLocation?.id)
  const programId = useMemo(() => program?.id || 0, [program])

  const [currentTab, setCurrentTab] = useState(0)
  const [athletesToAdd, setAthletesToAdd] = useState<themis_common.Athlete.AsObject[]>([])

  const hashValues = useMemo(() => ['athletes', 'coaches', 'personnel', 'events', 'users'], [])

  useEffect(() => {
    const hash = location.hash.toLowerCase().substr(1)
    if (hash === '') {
      history.replace('#' + hashValues[0])
    }
    const tab = hashValues.indexOf(location.hash.toLowerCase().substr(1))
    if (tab >= 0) setCurrentTab(tab)
  }, [location, hashValues, history])

  const handleChangeTab = (event: React.ChangeEvent<any>|null, newValue: number) => {
    if (hashValues[newValue]) history.push('#' + hashValues[newValue])
    setCurrentTab(newValue)
  }

  const handleDeleteTeam = async () => {
    await deleteTeam(dispatch, Number(teamId), locationId, Number(program?.id))
    history.push(`/Location/${locationId}/#teams`)
  }

  const handleDeleteAthleteFromTeam = useCallback((athleteId: number) => {
    removeAthleteFromTeam(dispatch, programId, locationId, teamId, athleteId)
  }, [dispatch, locationId, programId, teamId])

  const handleDeleteCoachFromTeam = useCallback((athleteId: number) => {
    removeCoachFromTeam(dispatch, programId, locationId, teamId, athleteId)
  }, [dispatch, locationId, programId, teamId])

  const handleDeletePersonnelFromTeam = useCallback((personnelId: number) => {
    removePersonnelFromTeam(dispatch, programId, locationId, teamId, personnelId)
  }, [dispatch, programId, locationId, teamId])

  const handleAddAthleteToTeam = useCallback((athleteId: number) => {
    if (!teamId) return
    addAthleteToTeam(dispatch, programId, locationId, teamId, athleteId)
  }, [dispatch, programId, locationId, teamId])

  const handleAddCoachToTeam = useCallback((coachId: number) => {
    if (!teamId) return
    addCoachToTeam(dispatch, programId, locationId, teamId, coachId)
  }, [dispatch, programId, locationId, teamId])

  const handleAddPersonnelToTeam = useCallback((personnelId: number) => {
    addPersonnelToTeam(dispatch, programId, locationId, teamId, personnelId)
  }, [dispatch, programId, locationId, teamId])

  const handleAddAthletesToTeam = useCallback(async() => {
    const athleteIds: number[] = []
    athletesToAdd.forEach(athlete => athleteIds.push(athlete.id))
    await addAthletesToTeam(dispatch, programId, locationId, teamId || 0, athleteIds)
    setAthletesToAdd([])
  }, [athletesToAdd, dispatch, locationId, programId, teamId])


  return ( permissionLists && teamId && program && currentLocation && team && permissionCodes?.denyAccess.length ?
    <Team
      superUser={superUser}
      programPermissions={programPermissions}
      permissionLists={permissionLists}
      locationId={locationId}
      teamId={teamId}
      program={program}
      currentLocation={currentLocation}
      team={team}
      currentTab={currentTab}
      handleChangeTab={handleChangeTab}
      handleDeleteTeam={handleDeleteTeam}
      handleDeleteAthleteFromTeam={handleDeleteAthleteFromTeam}
      handleDeleteCoachFromTeam={handleDeleteCoachFromTeam}
      handleDeletePersonnelFromTeam={handleDeletePersonnelFromTeam}
      handleAddAthleteToTeam={handleAddAthleteToTeam}
      handleAddCoachToTeam={handleAddCoachToTeam}
      handleAddPersonnelToTeam={handleAddPersonnelToTeam}
      locationAthletes={locationAthletes}
      locationCoaches={locationCoaches}
      locationPersonnels={locationPersonnels}
      permissionCodes={permissionCodes}
      handleAddAthletesToTeam={handleAddAthletesToTeam}
      athletesToAdd={athletesToAdd}
      setAthletesToAdd={setAthletesToAdd}
    /> : <></>
  )
}

export default TeamContainer
