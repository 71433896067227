import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import themis_common, { TeamScoringAuthority } from "store/themis_common_pb"
import { Categories } from "store/user/userActions"
import { parseISO } from 'date-fns'


interface findDivisionProps {
  setDivision: any,
  categories: Categories,
  classes: any,
  addNewScoringAuthority: Function | null
  currentList: TeamScoringAuthority.AsObject[] | undefined
}

const FindDivision: FunctionComponent<findDivisionProps> = ({ setDivision, categories, classes, addNewScoringAuthority, currentList }) => {

  const competitionTypes = useMemo(() => ["Competitive", "Exhibition"], [])
  const [scoringAuthorities, setScoringAuthorities] = useState<themis_common.ScoringAuthority.AsObject[]>([])
  const [competition, setCompetition] = useState<string | null>(null)
  const [competitions, setCompetitions] = useState<string[] | null>(null)
  const [scoringAuthority, setScoringAuthority] = useState<themis_common.ScoringAuthority.AsObject | null>(null)
  const [allStar, setAllStar] = useState<themis_common.AllStarType.AsObject | null>(null)
  const [allStars, setAllStars] = useState<themis_common.AllStarType.AsObject[] | null>(null)
  const [tier, setTier] = useState<themis_common.Tier.AsObject | null>(null)
  const [tiers, setTiers] = useState<themis_common.Tier.AsObject[] | null>(null)
  const [level, setLevel] = useState<themis_common.DivisionLevel.AsObject | null>(null)
  const [levels, setLevels] = useState<themis_common.DivisionLevel.AsObject[] | null>(null)
  const [divisions, setDivisions] = useState<themis_common.Division.AsObject[] | null>(null)
  const [rerenderSASelector, setRerenderSASelector] = useState<number>(0)


  const selectScoringAuthority = useCallback((v: themis_common.ScoringAuthority.AsObject | null) => {
    setScoringAuthority(v)
  }, [setScoringAuthority])

  const selectCompetitionType = useCallback((v: string | null) => {
    setCompetition(v)
  },[setCompetition])

  const selectAllStarType = useCallback((v: themis_common.AllStarType.AsObject | null) => {
    setAllStar(v)
  }, [setAllStar])

  const selectTierType = useCallback((v: themis_common.Tier.AsObject | null) => {
    setTier(v)
  }, [setTier])

  const selectLevelType = useCallback((v: themis_common.DivisionLevel.AsObject | null) => {
    setLevel(v)
  }, [setLevel])

  useEffect(() => {
    if (currentList?.length) setScoringAuthorities(categories.scoringAuthorities.filter((sa) => !currentList.find((li) => li.season?.scoringAuthority?.id === sa.id)))
    else setScoringAuthorities(categories.scoringAuthorities)
  }, [categories.scoringAuthorities, currentList])

  useEffect(() => {
    const cTypes = competitionTypes.filter((type) => !!categories.divisions.find((division) => division.competitionTypeDescriptor === type
      && division.season?.scoringAuthority?.id === scoringAuthority?.id))
    if (scoringAuthority === null || !cTypes.length) {
      selectCompetitionType(null)
      setCompetitions(null)
    } else if (cTypes) {
      if (competition) {
        if (cTypes.includes(competition)) {
          selectCompetitionType(competition)
        } else {
          selectCompetitionType(null)
        }
      }
      setCompetitions(cTypes)
    }

  }, [categories.divisions, competition, competitionTypes, scoringAuthority, selectCompetitionType])

  useEffect(() => {
    const aTypes = categories.allStarTypes.filter((type) => !!categories.divisions.find((division) => division.allStarType?.id === type.id
      && division.season?.scoringAuthority?.id === scoringAuthority?.id
      && division.competitionTypeDescriptor === competition))
    if (competition === null || !aTypes) {
      selectAllStarType(null)
      setAllStars(null)
    } else if (aTypes) {
      if (allStar) {
        if (aTypes.includes(allStar)) {
          selectAllStarType(allStar)
        } else {
          setAllStar(null)
        }
      }
      setAllStars(aTypes)
    }

  }, [allStar, categories.allStarTypes, categories.divisions, competition, scoringAuthority?.id, selectAllStarType])

  useEffect(() => {
    const tTypes = categories.tiers.filter((type) => !!categories.divisions.find((division) => division.tier?.id === type.id
      && division.season?.scoringAuthority?.id === scoringAuthority?.id
      && division.competitionTypeDescriptor === competition
      && division.allStarType?.id === allStar?.id))
    if (allStar === null || !tTypes) {
      selectTierType(null)
      setTiers(null)
    } else if (tTypes) {
      if (tier) {
        if (tTypes.includes(tier)) {
          selectTierType(tier)
        } else {
          selectTierType(null)
        }
      }
      setTiers(tTypes)
    }
  }, [allStar, categories.divisions, categories.tiers, competition, scoringAuthority?.id, selectTierType, tier])

  useEffect(() => {
    const lTypes = categories.levels.filter((type) => !!categories.divisions.find((division) => division.level?.id === type.id
      && division.season?.scoringAuthority?.id === scoringAuthority?.id
      && division.competitionTypeDescriptor === competition
      && division.allStarType?.id === allStar?.id
      && division.tier?.id === tier?.id))
    if (tier === null || !lTypes) {
      selectLevelType(null)
      setLevels(null)
    } else if (lTypes) {
      if (level) {
        if (lTypes.includes(level)) {
          selectLevelType(level)
        } else {
          selectLevelType(null)
        }
      }
      setLevels(lTypes)
    }

  }, [allStar?.id, categories.divisions, categories.levels, competition, level, scoringAuthority?.id, selectLevelType, tier])

  useEffect(() => {
    const today = new Date()

    const dTypes = categories.divisions.filter((division) => {
      if (division.season) {
        const seasonLastDay = parseISO(division.season.endDate)

        return division.season?.scoringAuthority?.id === scoringAuthority?.id
        && division.competitionTypeDescriptor === competition
        && division.allStarType?.id === allStar?.id
        && division.tier?.id === tier?.id
        && division.level?.id === level?.id
        && seasonLastDay >= today
      } else {
        return []
      }
    })

    if (dTypes) {
      const sortedTypes = dTypes.sort((a, b) => {
        if (a.season?.name && b.season?.name) {
          if (a.season?.name < b.season?.name) return 1
          else if (a.season?.name > b.season?.name) return -1
          else {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
          }
        }
        return 0
      } )
      setDivisions(sortedTypes)
    } else {
      setDivisions(null)
    }

  }, [allStar?.id, categories.divisions, competition, level?.id, scoringAuthority?.id, tier?.id])

  const competitionSelector = useMemo(() =>
    <>
      {!competitions?.length ? <></> :
        <Grid item xs={12} sm={4} className={classes.gridForm}>
          <Autocomplete
            options={competitions}
            getOptionLabel={(option) => option}
            value={competition}
            getOptionSelected={(option, value) => option === value}
            onChange={(e, v) => {
              selectCompetitionType(v)
            }}
            renderInput={(params) => (
              <form noValidate onSubmit={(e) => {
                e.preventDefault()
              }}>
                <TextField
                  {...params}
                  variant="outlined"
                  autoComplete="none"
                  label="Competition Type"
                />
              </form>
            )}
          />
        </Grid>
      }
    </>, [competitions, competition, classes.gridForm, selectCompetitionType])

  const allStarsSelector = useMemo(() =>
    <>
      {!allStars?.length ? <></> :
        <Grid item xs={12} sm={4} className={classes.gridForm}>
          <Autocomplete
            options={allStars}
            getOptionLabel={(option) => option.name}
            value={allStar}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(e, v) => {
              selectAllStarType(v)
            }}
            renderInput={(params) => (
              <form noValidate onSubmit={(e) => {
                e.preventDefault()
              }}>
                <TextField
                  {...params}
                  variant="outlined"
                  autoComplete="none"
                  label="Athlete Types"
                />
              </form>
            )}
          />
        </Grid>
      }
    </>, [allStar, allStars, classes.gridForm, selectAllStarType]
  )

  const tiersSelector = useMemo(() =>
    <>
      {!tiers?.length ? <></> :
        <Grid item xs={12} sm={4} className={classes.gridForm}>
          <Autocomplete
            options={tiers}
            getOptionLabel={(option) => option.name}
            value={tier}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(e, v) => {
              selectTierType(v)
            }}
            renderInput={(params) => (
              <form noValidate onSubmit={(e) => {
                e.preventDefault()
              }}>
                <TextField
                  {...params}
                  variant="outlined"
                  autoComplete="none"
                  label="Tier"
                />
              </form>
            )}
          />
        </Grid>
      }
    </>, [classes.gridForm, selectTierType, tier, tiers]
  )

  const levelsSelector = useMemo(() =>
    <>
      {!levels?.length ? <></> :
        <Grid item xs={12} sm={4} className={classes.gridForm}>
          <Autocomplete
            options={levels}
            getOptionLabel={(option) => option.name}
            value={level}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(e, v) => {
              selectLevelType(v)
            }}
            renderInput={(params) => (
              <form noValidate onSubmit={(e) => {
                e.preventDefault()
              }}>
                <TextField
                  {...params}
                  variant="outlined"
                  autoComplete="none"
                  label="Level"
                />
              </form>
            )}
          />
        </Grid>
      }

    </>, [classes.gridForm, level, levels, selectLevelType]
  )

  const divisionsSelector = useMemo(() =>
    <>
      {!divisions?.length ? <></> :
        <Grid item xs={12} sm={4} className={classes.gridForm}>
          <Autocomplete
            options={divisions}
            getOptionLabel={(option) => `${option.name} - ${option.season?.name}`}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(e, v) => {
              if (addNewScoringAuthority) {
                // Add the division to the team
                addNewScoringAuthority(v)
                // Reset the FindDivision
                selectScoringAuthority(null)
                setRerenderSASelector(rerenderSASelector+1)
              } else {
                setDivision(v)
              }
            }}
            renderInput={(params) => (
              <form noValidate onSubmit={(e) => {
                e.preventDefault()
              }}>
                <TextField
                  {...params}
                  variant="outlined"
                  autoComplete="none"
                  label="Division"
                />
              </form>
            )}
          />
        </Grid>
      }
    </>, [addNewScoringAuthority, classes.gridForm, divisions, rerenderSASelector, selectScoringAuthority, setDivision]
  )

  return (
    <>
      <Grid item xs={12} sm={4} className={classes.gridForm}>
        <Autocomplete
          options={scoringAuthorities}
          key={rerenderSASelector}
          getOptionLabel={(option: themis_common.ScoringAuthority.AsObject) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(e, v) => {
            selectScoringAuthority(v)
          }}
          renderInput={(params) => (
            <form noValidate onSubmit={(e) => {
              e.preventDefault()
            }}>
              <TextField
                {...params}
                variant="outlined"
                autoComplete="none"
                label="Rules Organization"
              />
            </form>
          )}
        />
      </Grid>
      {competitionSelector}
      {allStarsSelector}
      {tiersSelector}
      {levelsSelector}
      {divisionsSelector}
    </>
  )
}

export default FindDivision
