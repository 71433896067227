import React, { ReactElement } from 'react'
import { Grid } from '@material-ui/core'

export interface RubricAreaProps {
  name: string
  score?: number
  children: React.ReactNode
}
const RubricArea: React.FC<RubricAreaProps> = ({ name, children, score=0 }: RubricAreaProps): ReactElement => {
  const hThreestyle = {
    marginBottom: '0px',
    textAlign: "center" as const,
  }

  return (
    <>
      <h3 style={ hThreestyle }>{name}</h3>
      <Grid container item xs={12} style={{ fontSize: 12 }}>{children}</Grid>
      <h4>{name} SCORE: {score}</h4>
    </>
  )
}

export default RubricArea
