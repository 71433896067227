import React, { useState, ReactElement, useEffect, useMemo } from "react"
import { CreateTokenBankAccountData, StripeError } from "@stripe/stripe-js"
import { Box, Button, CircularProgress, InputLabel, Grid, MenuItem, Select, FormControl } from '@material-ui/core'
import themis_common from "store/themis_common_pb"
import { useStyles } from "lib/theme"
import { AsFields, FormFields } from "lib/constants"
import FormTextField from "components/util/FormTextField"
import { buildErrors, fieldsOkay } from "lib/validators"
import HelpIcon from "components/util/HelpIcon"
import YesNoDialog from "components/util/YesNoModal"

interface BankAccountFormProps {
  handleAddBankAccount: (bankAccountData: CreateTokenBankAccountData) => Promise<[themis_common.StripeBankAccount.AsObject, StripeError | undefined]>
}

const BankAccountForm: React.FC<BankAccountFormProps> = ({ handleAddBankAccount }): ReactElement => {
  const classes = useStyles()

  const [bankAccount, setBankAccount] = useState<AsFields>({
    accountHolderType: "company",
    accountHolderName: "",
    routingNumber: "",
    accountNumber: "",
    country: "US",
    currency: "USD",
  })
  const [checkBankAccount, setCheckBankAccount] = useState(false)
  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})
  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const [processing, setProcessing] = useState(false)

  const defaultErrorMessage = `There was an error creating your bank account. Please contact us if this continues to be a problem.`
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage)

  const bankAccountFields: FormFields = useMemo(() => ({
    accountHolderType: { label: "Account Holder Type", required: true, phone: false, email: false }, // This field is required when attaching the bank account to a Customer object.
    accountHolderName: { label: "Account Holder Name", required: true, phone: false, email: false }, // This field is required when attaching the bank account to a Customer object.
    routingNumber: { label: "Routing Number", required: true, phone: false, email: false }, // Should be the ACH routing number, not the wire routing number
    accountNumber: { label: "Account Number", required: true, phone: false, email: false }, // Must be a checking account
    country: { label: "Country", required: true, phone: false, email: false },
    currency: { label: "Currency", required: true, phone: false, email: false },
  }), [])

  const formReady = useMemo(() => fieldsOkay(bankAccount, bankAccountFields), [bankAccountFields, bankAccount])

  useEffect(() => {
    if (checkBankAccount) {
      setErrorText(buildErrors(bankAccount, bankAccountFields, setBankAccount))
      setCheckBankAccount(false)
    }
  }, [checkBankAccount, bankAccountFields, bankAccount])

  const submitForm = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    setCheckBankAccount(true)
    if (!formReady) return

    setProcessing(true)
    try {
      const [newBankAccount, stripeError] = await handleAddBankAccount({
        account_holder_type: bankAccount.accountHolderType,
        account_holder_name: bankAccount.accountHolderName,
        routing_number: bankAccount.routingNumber,
        account_number: bankAccount.accountNumber,
        country: bankAccount.country,
        currency: bankAccount.currency,
      })
      if (newBankAccount.id.length === 0) {
        if (stripeError?.message && stripeError.message.length > 0) {
          setErrorMessage(stripeError?.message)
        } else {
          setErrorMessage(defaultErrorMessage)
        }
        setIsErrorOpen(true)
      }
    } catch (e) {
      setIsErrorOpen(true)
    }
    setProcessing(false)
  }

  return (
    <Box textAlign="center" width="100%">
      <form className={classes.form} noValidate>
        <Grid container justify="center" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControl
              variant="outlined"
              fullWidth
              required={bankAccountFields["accountHolderType"].required}
              className={classes.formControl}
            >
              <InputLabel htmlFor="accountHolderType">{bankAccountFields["accountHolderType"].label}</InputLabel>
              <Select
                required={bankAccountFields["accountHolderType"].required}
                value={bankAccount.accountHolderType}
                onChange={(e) => setBankAccount((prev) => { return { ...prev, accountHolderType: e.target.value }})}
                label={bankAccountFields["accountHolderType"].label}
                inputProps={{
                  name: 'accountHolderType',
                  id: 'accountHolderType',
                }}
              >
                <MenuItem value={"company"}>Company</MenuItem>
                <MenuItem value={"individual"}>Individual</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              name="accountHolderName"
              formFields={bankAccountFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={bankAccount}
              setUpdateObject={setBankAccount}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box position="relative">
              <FormTextField
                name="routingNumber"
                formFields={bankAccountFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={bankAccount}
                setUpdateObject={setBankAccount}
              />
              <Box position="absolute" top={15} right={0} zIndex="modal">
                <HelpIcon iconType="info" tooltipText="Info" markdownText={`Should be the ACH routing number, not the wire routing number`} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box position="relative">
              <FormTextField
                name="accountNumber"
                formFields={bankAccountFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={bankAccount}
                setUpdateObject={setBankAccount}
              />
              <Box position="absolute" top={15} right={0} zIndex="modal">
                <HelpIcon iconType="info" tooltipText="Info" markdownText={`Must be a checking account`} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              name="country"
              formFields={bankAccountFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={bankAccount}
              setUpdateObject={setBankAccount}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              name="currency"
              formFields={bankAccountFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={bankAccount}
              setUpdateObject={setBankAccount}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitForm}>
              {processing ? <CircularProgress color="secondary" size={30}/> : "Add Bank Account"}
            </Button>
          </Grid>
          <YesNoDialog
            title={`Error Adding Bank Account`}
            question={errorMessage}
            isOpen={isErrorOpen}
            disabled={!formReady}
            onClose={() => setIsErrorOpen(false)}
            buttonActions={[
              { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
            ]}
          />
        </Grid>
      </form>
    </Box>
  )
}

export default BankAccountForm
