import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import { Card, CardContent, Container, Grid, IconButton, Typography } from '@material-ui/core'
import { Link as RouterLink } from "react-router-dom"
import { useParams } from "react-router-dom"
import { IAppState } from "store/store"
import {
  selectCurrentProgram,
} from "store/program/programSelectors"
import themis_common from "store/themis_common_pb"
import { getGuardian } from "store/program/guardianActions"
import { Edit as EditIcon } from "@material-ui/icons"
import { validateLocationPermission, validateTeamPermission } from 'lib/permissions'
import { selectPermissionCodes, selectSuperUser, selectUserProgramPermissions } from 'store/user/userSelectors'

const Guardian: React.FC = (): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { athleteId: inputAthleteId, teamId: inputTeamId, locationId: inputLocationId, guardianId: inputGuardianId } = useParams<{athleteId?: string | undefined, teamId?: string | undefined, guardianId?: string | undefined, locationId?: string | undefined}>()
  const athleteId = inputAthleteId ? Number(inputAthleteId) : 0
  const teamId = inputTeamId ? Number(inputTeamId) : 0
  const guardianId = inputGuardianId ? Number(inputGuardianId) : 0
  const locationId = inputLocationId ? Number(inputLocationId) : 0

  const program: themis_common.Program.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProgram(state))
  const programId = useMemo(() => program?.id || 0,[program])

  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))


  const [guardian, setGuardian] = useState<themis_common.Guardian.AsObject | undefined>(undefined)

  useEffect(() => {
    async function doGet(): Promise<undefined> {
      if (!guardianId || !athleteId || !programId || !locationId) return
      const guardian = await getGuardian(dispatch, guardianId, athleteId, programId, locationId, teamId)
      setGuardian(guardian)
    }
    doGet()
  }, [dispatch, locationId, programId, athleteId, guardianId, teamId])

  const headerContents = () => {
    if (guardian?.name) {
      return `Manage Guardian - ${guardian.name}`
    } else {
      return "Select Guardian to Continue"
    }
  }

  const editGuardianLink = teamId ? `/Location/${locationId}/Team/${teamId}/Athlete/${athleteId}/EditGuardian/${guardianId}` : `/Location/${locationId}/Athlete/${athleteId}/EditGuardian/${guardianId}`

  const editGuardianIcon = useMemo(() => {
    if (
      (!teamId && validateLocationPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
      (teamId && validateTeamPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
    ) {
      return (
        <IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={editGuardianLink}>
          <EditIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [classes.clickable, editGuardianLink, locationId, programId, teamId, programPermissions, superUser, permissionCodes])

  return ( guardian?.person?.id && permissionCodes?.denyAccess.length ? <Container style={{ maxWidth: "none" }}>
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" className={classes.fullWidth}>
          {headerContents()} - <a className={classes.cleanLink} href={`tel:${guardian.person.cellPhone}`}>{guardian.person.cellPhone}</a>
          {editGuardianIcon}
        </Typography>
      </Grid>
      <Grid item xs={3}/>
      <Grid item container justify="flex-start" xs={6}>
        <Card key={`names-${guardian.id}`} className={classes.cardSpacing}>
          <CardContent>
            <Typography>First Name: {guardian.person.legalFirstName}</Typography>
            <Typography>Middle Name: {guardian.person.legalMiddleName}</Typography>
            <Typography>Last Name: {guardian.person.legalLastName}</Typography>
            <Typography>Suffix: {guardian.person.suffix}</Typography>
          </CardContent>
        </Card>
        <Card key={`address-${guardian.id}`} className={classes.cardSpacing}>
          <CardContent>
            <Typography>Address: {guardian.person.address1}</Typography>
            <Typography>Address2: {guardian.person.address2}</Typography>
            <Typography>City: {guardian.person.city}</Typography>
            <Typography>State: {guardian.person.state}</Typography>
            <Typography>Postal Code: {guardian.person.postalCode}</Typography>
            <Typography>Country: {guardian.person.country}</Typography>
          </CardContent>
        </Card>
        <Card key={`contact-${guardian.id}`} className={classes.cardSpacing}>
          <CardContent>
            <Typography>Email: <a className={classes.cleanLink} target="_blank" rel="noreferrer" href={`mailto:${guardian.person.email}`}>{guardian.person.email}</a> </Typography>
            <Typography>Phone: <a className={classes.cleanLink} target="_blank" rel="noreferrer" href={`tel:${guardian.person.cellPhone}`}>{guardian.person.cellPhone}</a></Typography>
            <Typography>Home Phone: {guardian.person.homePhone}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Container> : <></> )
}

export default Guardian
