import React, { FormEvent, ReactElement } from 'react'
import { Button } from '@material-ui/core'
import { useStyles } from "lib/theme"

interface SubmitButtonProps { ariaLabel: string, submitForm?: (event: FormEvent) => Promise<void>, title: string, disabled?: boolean}

const SubmitButton: React.FC<SubmitButtonProps> = ({ ariaLabel, submitForm, title , disabled }): ReactElement => {
  const classes = useStyles()
  return <Button
    type="submit"
    fullWidth
    disabled={disabled}
    aria-label={ariaLabel}
    variant="contained"
    className={classes.submit}
    onClick={submitForm}
  >{title}</Button>
}

export default SubmitButton
