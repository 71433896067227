/* eslint max-lines: "off" */
import React, { useEffect, useState, useMemo, ReactElement, FormEvent } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Countries, countries, currencies, regexpNumber, UsStates, usStates } from "lib/constants"
import { buildErrors, capitalizeFirstLetter, fieldsOkay, handleInputChange, validURL } from "lib/validators"
import { useStyles } from 'lib/theme'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Box, Container, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { addEvent, getSeasons, updateEvent, addEventLogo } from "store/producer/eventActions"
import { IAppState } from "store/store"
import { selectCurrentBrand, selectCurrentEvent, selectCurrentEventDates, selectCurrentProducer } from "store/producer/producerSelectors"
import { emptyEvent } from "store/producer/producerReducer"
import YesNoDialog from 'components/util/YesNoModal'
import FormTextField from 'components/util/FormTextField'
import { refreshUser } from 'store/user/userActions'
import SubmitButton from 'components/util/SubmitButton'
import { KeyboardDatePicker } from "@material-ui/pickers"
import { format } from "date-fns"
import themis_common from 'store/themis_common_pb'
import { doSetBrand } from "store/producer/brandActions"
import { getCountryDateFormat } from 'lib/functions'
import { AddLogo } from "components/util/AddLogo"
import { logoUploadContext, logoUploadDefault } from "components/util/LogoUploadContext"
import HelpIcon from 'components/util/HelpIcon'

const AddEditEvent = (): ReactElement => {
  const classes = useStyles()
  const history = useHistory()

  const producer = useSelector((state: IAppState) => selectCurrentProducer(state))
  const brand = useSelector((state: IAppState) => selectCurrentBrand(state))
  const event = useSelector((state: IAppState) => selectCurrentEvent(state))
  const eventDates = useSelector((state: IAppState) => selectCurrentEventDates(state))

  const create = !event?.id ? 'Create' : 'Edit'
  const creating = !event?.id ? 'creating' : 'editing'

  const dispatch = useDispatch()

  const { brandId: brandIdT } = useParams<{ brandId?: string | undefined }>()
  const { eventId: eventIdT } = useParams<{ eventId?: string | undefined }>()
  const brandId = Number(brandIdT)
  const eventId = Number(eventIdT)

  const [logoState, setLogoState] = useState<typeof logoUploadDefault>({ ...logoUploadDefault, id: eventId })

  const [updatedEvent, setUpdatedEvent] = useState(emptyEvent)
  const [checkEvent, setCheckEvent] = useState(false)
  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})
  const [isErrorOpen, setIsErrorOpen] = useState(false)
  const [firstDate, setFirstDate] = useState<Date>(new Date())
  const [seasons, setSeasons] = useState<themis_common.Season.AsObject[]>([])
  const [eventSeasons, setEventSeasons] = useState<themis_common.Season.AsObject[]>(event?.seasonsList || [])
  const [seasonFieldShowError, setSeasonFieldShowError] = useState(false)
  const [countryDateFormat, setCountryDateFormat] = useState<string>("MM/dd/yyyy")
  const [warmupTime, setWarmupTime] = useState<number>(0)
  const [warmupTimeShowError, setWarmupTimeShowError] = useState<boolean>(false)
  const [rotationTime, setRotationTime] = useState<number>(0)
  const [rotationTimeShowError, setRotationTimeShowError] = useState<boolean>(false)

  const defaultCountry = useMemo(() => {
    if (!updatedEvent?.country) return countries[0]
    return countries.find((country) => country.code === updatedEvent.country) || countries[0]
  }, [updatedEvent?.country])
  const defaultState = useMemo(() => usStates.find((state) => state.abbreviation === updatedEvent?.state) || usStates[0], [updatedEvent?.state])
  const defaultCurrency = useMemo(() => currencies.find((currency) => currency.code === updatedEvent?.currency) || currencies[0], [updatedEvent?.currency])

  useEffect(() => {
    const doGetSeasons = async () => {
      const eventDate = eventDates?.length ? eventDates[0].startDay.split("|")[0] : format(firstDate, 'yyyy-MM-dd')
      const seasons = await getSeasons(eventDate)
      const exclusiveFilterSeasons: themis_common.Season.AsObject[] = []
      seasons.forEach(season => {
        if (season.isExclusive) {
          if (season.exclusiveProducerId === producer?.id ?? 0) {
            exclusiveFilterSeasons.push(season)
          }
        } else {
          exclusiveFilterSeasons.push(season)
        }
      })
      setSeasons(exclusiveFilterSeasons)
    }
    doGetSeasons()
  }, [firstDate, eventDates])

  useEffect(() => {
    if (eventId) {
      setWarmupTime(updatedEvent.warmupTime / 60)
      setRotationTime(updatedEvent.rotationTime / 60)
    }
  }, [updatedEvent])

  const formFields = useMemo(() => {
    return {
      name: { label: "Event Name", required: true, phone: false, email: false },
      city: { label: "City", required: true, phone: false, email: false },
      state: { label: "State or Province", required: true, phone: false, email: false },
      country: { label: "Country", required: true, phone: false, email: false },
      address: { label: "Address", required: true, phone: false, email: false },
      address2: { label: "Address 2", required: false, phone: false, email: false },
      postalCode: { label: "Postal Code", required: true, phone: false, email: false },
      venueName: { label: "Venue Name", required: true, phone: false, email: false },
      currency: { label: "Currency", required: false, phone: false, email: false },
      logo: { label: "Logo", required: false, phone: false, email: false },
      website: { label: "Website URL", required: false, phone: false, email: false },
      phone: { label: "Contact Phone", required: true, phone: true, email: false },
      email: { label: "Email", required: true, phone: false, email: true },
    }
  }, [])

  // Display logo
  useEffect(() => {
    if (updatedEvent.logo > '' && validURL(updatedEvent.logo) && updatedEvent.logo.slice(-5).indexOf(".") >= 0) {
      setLogoState({ ...logoState, oldLogo:updatedEvent.logo })
    }
  }, [updatedEvent.logo, classes.logoCheck])

  // Edit existing event
  useEffect(() => {
    if (event?.id) {
      setUpdatedEvent(event)
      setEventSeasons(event.seasonsList)
    }
  }, [event])

  // New event, auto filling from brand and producer
  useEffect(() => {
    if (brand?.id !== 0 && event?.id === 0) {
      setUpdatedEvent({ ...emptyEvent, country: brand?.country || countries[0].code, state: brand?.state || usStates[0].abbreviation, logo: brand?.logo || "", phone: brand?.phone || "", website: brand?.website || "", currency: event?.currency || brand?.currency || "" })
    } return
  }, [producer, brand, event])

  // Check fields when user tries to submit
  useEffect(() => {
    if (checkEvent) {      
      setErrorText(buildErrors(updatedEvent, formFields, setUpdatedEvent))      
      setCheckEvent(false)
    }
  }, [checkEvent, formFields, updatedEvent])

  useEffect(() => {
    setCountryDateFormat(getCountryDateFormat(new Date()))
  }, [])

  const handleDateChange = (date: Date | null) => {
    // Checking if date is valid before setting it
    if (date && (date instanceof Date && !isNaN(date.valueOf()))) {
      setFirstDate(date)
    } return
  }

  const formReady = useMemo(() => fieldsOkay(updatedEvent, formFields) && eventSeasons.length > 0 && warmupTime > 0 && rotationTime > 0, [updatedEvent, formFields, eventSeasons, warmupTime, rotationTime])

  const logoCheck = useMemo(() => {
    if (updatedEvent.logo > '' && validURL(updatedEvent.logo) && updatedEvent.logo.slice(-5).indexOf(".") >= 0) {
      return (<img alt="Logo" src={updatedEvent.logo} className={classes.logoCheck} />)
    }
    return (<></>)
  }, [updatedEvent.logo, classes.logoCheck])

  const submitForm = async (event: FormEvent) => {
    setCheckEvent(true)
    setSeasonFieldShowError(true)
    setWarmupTimeShowError(true)
    setRotationTimeShowError(true)
    event.preventDefault()
    if (!producer || !brand) return
    if (formReady) {
      if (!eventId) { // Add
        const brandId = brand?.id ? brand.id : 0
        if (!brandId) {
          return
        }
        try {
          const newEventId = await addEvent(dispatch, {
            ...updatedEvent,
            producerId: producer.id,
            brandId: brandId,
            seasonsList: eventSeasons,
            firstDay: format(firstDate, "yyyy-MM-dd"),
            warmupTime: warmupTime * 60,
            rotationTime:rotationTime * 60,
          })
          await refreshUser(dispatch)
          if (logoState.fileSelected) {
            await addEventLogo(dispatch, newEventId, logoState.base64File, logoState.fileName)
            setLogoState(logoUploadDefault)
          }
          await doSetBrand(dispatch, brandId, producer?.id) // This fetches an updated set of events for the brand
          history.replace(`/Brand/${brandId}/Event/${newEventId}`)
        } catch (e) {
          setIsErrorOpen(true)
          console.error(e)
        }
      } else { // Edit
        if (!brandId) return
        try {
          await updateEvent(dispatch, {
            ...updatedEvent,
            producerId: producer.id,
            brandId: brandId,
            seasonsList: eventSeasons,
            id: eventId,
            warmupTime: warmupTime * 60,
            rotationTime: rotationTime * 60,
          })
          if (logoState.fileSelected) {
            await addEventLogo(dispatch, logoState.id, logoState.base64File, logoState.fileName)
            setLogoState(logoUploadDefault)
          }
          await refreshUser(dispatch)
          history.replace(`/Brand/${brandId}/Event/${eventId}`)

        } catch (e) {
          setIsErrorOpen(true)
          console.error(e)
        }
      }
    }
  }

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{event?.name ? `${create} Event - ${event.name}` : `${create} Event`}</Typography>
        </Grid>
      </Grid>

      {/* Name row */}
      <Grid container>
        <Grid item xs={12} sm={6} md={6} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedEvent}
            setUpdateObject={setUpdatedEvent}
            name="name"
            autoFocus
            sliceSize={100}
          />
        </Grid>
      </Grid>
      {/* Location row */}
      <Grid container>
        <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
          {!updatedEvent?.country && event?.country ? <></> :
            <Autocomplete
              key={'1'}
              options={countries}
              fullWidth={true}
              value={defaultCountry}
              getOptionLabel={(country: Countries) => country.name}
              disableClearable
              renderInput={(params) =>
                <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                  <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} autoComplete="none" label={formFields.country.label} required={formFields.country.required} variant="outlined" />
                </form>
              }
              onChange={(e, v) => {
                v && handleInputChange({ target: { name: "country", value: v.pseudoCode || v.code } }, formFields, updatedEvent, setUpdatedEvent, errorText, setErrorText)
              }}
            />
          }
        </Grid>
        {updatedEvent.country === 'US' || !updatedEvent?.country ?
          <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
            <Autocomplete
              key={'2'}
              options={usStates}
              fullWidth={true}
              value={defaultState}
              disableClearable
              getOptionLabel={(state: UsStates) => state.name}
              renderInput={(params) => <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} label={formFields.state.label} required={formFields.state.required} variant="outlined" />}
              onChange={(e, v) => {
                v && handleInputChange({ target: { name: "state", value: v.abbreviation } }, formFields, updatedEvent, setUpdatedEvent, errorText, setErrorText)
              }}
            />
          </Grid>
          :
          <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedEvent}
              setUpdateObject={setUpdatedEvent}
              name="state"
              sliceSize={30}
            />
          </Grid>
        }
        <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedEvent}
            setUpdateObject={setUpdatedEvent}
            name="city"
            sliceSize={40}
          />
        </Grid>
      </Grid>
      {/* address row */}
      <Grid container>
        <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedEvent}
            setUpdateObject={setUpdatedEvent}
            name="address"
            sliceSize={200}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedEvent}
            setUpdateObject={setUpdatedEvent}
            name="address2"
            sliceSize={200}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedEvent}
            setUpdateObject={setUpdatedEvent}
            name="postalCode"
            sliceSize={20}
          />
        </Grid>
      </Grid>
      {/* venue currency row */}
      <Grid container>
        <Grid item xs={12} sm={6} md={8} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedEvent}
            setUpdateObject={setUpdatedEvent}
            name="venueName"
            sliceSize={200}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <Autocomplete
            key={'3'}
            options={currencies}
            fullWidth={true}
            value={defaultCurrency}
            disableClearable
            getOptionLabel={(currency) => currency.currency}
            renderInput={(params) => <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} label={formFields.currency.label} required={formFields.currency.required} variant="outlined" />}
            onChange={(e, v) => {
              v && handleInputChange({ target: { name: "currency", value: v.code } }, formFields, updatedEvent, setUpdatedEvent, errorText, setErrorText)
            }}
          />
        </Grid>
      </Grid>
      {/* url row */}
      <Grid container>
        <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
          <logoUploadContext.Provider value={{ logoState, setLogoState }}>
            <AddLogo></AddLogo>
          </logoUploadContext.Provider>
        </Grid>
      </Grid>
      {/* Contact row */}
      <Grid container>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedEvent}
            setUpdateObject={setUpdatedEvent}
            name="website"
            sliceSize={100}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedEvent}
            setUpdateObject={setUpdatedEvent}
            name="phone"
            sliceSize={20}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <FormTextField
            formFields={formFields}
            errorText={errorText}
            setErrorText={setErrorText}
            updatedObject={updatedEvent}
            setUpdateObject={setUpdatedEvent}
            name="email"
            sliceSize={100}
          />
        </Grid>
      </Grid>
      <Grid container>
        { !!event?.id ? <></> :
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format={countryDateFormat}
              margin="normal"
              autoOk={true}
              id="firstDate"
              label="First day of event"
              value={firstDate}
              required={true}
              inputVariant="outlined"
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'first date',
              }}
              error={!firstDate}
              helperText={!!firstDate ? "" : "A date is required"}
            />
          </Grid>
        }
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <TextField
            variant="outlined"
            id="warmupTime"
            margin="normal"
            label="Warmup Time"
            required
            value={warmupTime}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">Minutes</InputAdornment>,
            }}
            onChange={(e) => {
              if (regexpNumber.test(e.target.value)) {
                setWarmupTime(Number(e.target.value))
                setWarmupTimeShowError(true)
              }
            }}
            error={warmupTimeShowError && warmupTime < 1 ? true : false}
            helperText={warmupTimeShowError && warmupTime < 1 ? "Warmup time cannot be zero minutes" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
          <Box position="relative">
            <TextField
              variant="outlined"
              id="rotationTime"
              margin="normal"
              label="Rotation Time"
              required
              value={rotationTime}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">Minutes</InputAdornment>,
              }}
              onChange={(e) => {
                if (regexpNumber.test(e.target.value)) {
                  setRotationTime(Number(e.target.value))
                  setRotationTimeShowError(true)
                }
              }}
              error={rotationTimeShowError && rotationTime < 1 ? true : false}
              helperText={rotationTimeShowError && rotationTime < 1 ? "Rotation time cannot be zero minutes" : ""}
            />
            <Box position="absolute" top={12} left={285} zIndex="modal">
              <HelpIcon markdownText={`### Rotation Time \n Sets the default rotation time for the event. You can set a custom rotation time per event floor date on the event schedule page.`} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} className={classes.gridForm}>
          {/* {!seasons.length || !currentSeason ? <></> : */}
          {!seasons.length ? <></> : // Is this breaking the Autocomplete?
            <Autocomplete
              multiple
              filterSelectedOptions
              options={seasons}
              disableClearable
              style={{ marginTop: 16 }}
              getOptionLabel={(option: themis_common.Season.AsObject) => option.name || "Unknown"}
              getOptionSelected={(option, value) => option.id === value.id}
              value={eventSeasons}
              renderInput={(params) => (
                <TextField {...params} label="Season" variant="outlined"
                  className={classes.navigationMenu}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off'
                  }} // disable autocomplete and autofill
                  required={true}
                  error={seasonFieldShowError && eventSeasons.length < 1 ? true : false}
                  helperText={seasonFieldShowError && eventSeasons.length < 1 ? "Season cannot be empty" : ""}
                />
              )}
              onChange={(e, v: themis_common.Season.AsObject[] | null) => {
                if (v) {
                  setEventSeasons(v)
                  setSeasonFieldShowError(true)
                }
              }}
              id="setSeason"
              className={classes.programSelect}
            />
          }
        </Grid>
      </Grid>
      <SubmitButton title={`${create} Event`} ariaLabel="submit-event-form" submitForm={submitForm} />
      <YesNoDialog        
        title={`Error ${capitalizeFirstLetter(creating)} Event`}
        question={`There was an error ${creating} your event. Please contact us if this continues to be a problem.`}
        isOpen={isErrorOpen}
        onClose={() => setIsErrorOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
        ]}
      />
    </Container>
  )
}

export default AddEditEvent
