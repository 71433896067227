import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useStyles } from "lib/theme"
import { Button, Card, CardActions, CardContent, Grid, Switch, Typography } from '@material-ui/core'
import { ReportData } from "./ReportsContainer"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { format, parseISO } from "date-fns"
import { addCommas, getCountryDateFormat } from "../../../lib/functions"
import MUIDataTable from "mui-datatables"

interface reportsProps {
  producerId: number
  brandId?: number
  currentReport: number
  setCurrentReport: Function
  startDate: string
  setStartDate: Function
  endDate: string
  setEndDate: Function
  reportData: ReportData
}

interface report1data {
  name: string
  live: string
  paid: string
  unpaid: string
  total: string
  paidAthletes: number
  unpaidAthletes: number
  totalAthletes: number
  paidTeams: number
  partlyPaidTeams: number
  unpaidTeams: number
  totalTeams: number
}

const Reports: React.FC<reportsProps> = ({
  currentReport,
  setCurrentReport,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  reportData
}): ReactElement => {
  const classes = useStyles()
  const todayDate = format(new Date(), 'yyyy-MM-dd')

  const [countryDateFormat, setCountryDateFormat] = useState<string>("MM/dd/yyyy")
  const [isEventReportByProgram, setIsEventReportByProgram] = useState<boolean>(false)
  const [isSelectedByTeam, setIsSelectedByTeam] = useState<boolean>(false)

  const handleEventReportToggle = () => {
    const toggled = !isEventReportByProgram
    setIsEventReportByProgram(toggled)
    if (toggled) {
      setCurrentReport(3)
    } else {
      setCurrentReport(2)
    }
  }

  useEffect(() => {
    setCountryDateFormat(getCountryDateFormat(new Date()))
    setIsEventReportByProgram(false)
    if (currentReport === 3) {
      setIsEventReportByProgram(true)
    }
  }, [])

  useEffect(() => {
    const storageStartDate = localStorage.getItem('reportStartDate')
    const storageEndDate = localStorage.getItem('reportEndDate')
    if (storageStartDate !== null || storageEndDate !== null) {
      setStartDate(storageStartDate)
      setEndDate(storageEndDate)
    }
  }, [setStartDate, setEndDate])

  const handleStartDateChange = (date: Date | null) => {
    // Checking if date is valid before setting it
    if (date && (date instanceof Date && !isNaN(date.valueOf()))) {
      const start = format(date, "yyyy-MM-dd")
      setStartDate(start)
      localStorage.setItem('reportStartDate', start)
    }
    return
  }

  const handleEndDateChange = (date: Date | null) => {
    // Checking if date is valid before setting it
    if (date && (date instanceof Date && !isNaN(date.valueOf()))) {
      const end = format(date, "yyyy-MM-dd")
      setEndDate(end)
      localStorage.setItem('reportEndDate', end)

    }
    return
  }

  const newData = useMemo(() => {
    if (!reportData.data?.reportList && !reportData.eventDetailReportData?.eventTeamsList && !reportData.eventDetailByProgramReportData?.eventProgramsList && !reportData.producerProgramCreditReportData?.programCreditsList) return []
    if (currentReport === 1) {
      return reportData.data?.reportList.map<report1data>(row => {
        return {
          name: row.eventName,
          live: row.eventLive ? "Open" : "Closed",
          paid: "$" + addCommas((row.paid / 100).toFixed(2)),
          unpaid: "$" + addCommas((row.unpaid / 100).toFixed(2)),
          total: "$" + addCommas(((row.paid + row.unpaid) / 100).toFixed(2)),
          paidAthletes: row.paidAthletes,
          unpaidAthletes: row.unpaidAthletes,
          totalAthletes: row.paidAthletes + row.unpaidAthletes,
          paidTeams: row.paidTeams,
          partlyPaidTeams: row.partlyPaidTeams,
          unpaidTeams: row.unpaidTeams,
          totalTeams: row.paidTeams + row.partlyPaidTeams + row.unpaidTeams
        }
      })
    }
    if (currentReport === 2) {
      return reportData.eventDetailReportData?.eventTeamsList
    }
    if (currentReport === 3) {
      return reportData.eventDetailByProgramReportData?.eventProgramsList
    }
    if (currentReport === 4) {
      return reportData.producerProgramCreditReportData?.programCreditsList
    }
    if (currentReport === 5) {
      return reportData.connectedProgramReportData
    }
  }, [currentReport, reportData.data?.reportList, reportData.eventDetailReportData?.eventTeamsList, reportData.eventDetailByProgramReportData?.eventProgramsList, reportData.producerProgramCreditReportData?.programCreditsList])

  switch (currentReport) {
    case 1:
      const columns = [
        { label: "Name", name: "name" },
        { label: "Registration", name: "live" },
        { label: "Paid", name: "paid" },
        { label: "Unpaid", name: "unpaid" },
        { label: "Total", name: "total" },
        { label: "Paid Athletes", name: "paidAthletes" },
        { label: "Unpaid Athletes", name: "unpaidAthletes" },
        { label: "Total Athletes", name: "totalAthletes" },
        { label: "Paid Teams", name: "paidTeams" },
        { label: "Part Paid Teams", name: "partlyPaidTeams" },
        { label: "Unpaid Teams", name: "unpaidTeams" },
        { label: "Total Teams", name: "totalTeams" }]
      const options = {
        filter: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        downloadOptions: { filename: `ProducerEventsReport.csv`, separator: ',' } // Include producer name in filename and in table title
      }
      return <Grid container spacing={3} justify="flex-start">
        <Grid item xs={3}>
          <Typography variant="h2">Events Report</Typography>
        </Grid>
        <Grid item xs={3}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format={countryDateFormat}
            margin="normal"
            autoOk={true}
            id="sdate"
            label="Start date for events"
            value={parseISO(startDate)}
            required={true}
            inputVariant="outlined"
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format={countryDateFormat}
            margin="normal"
            autoOk={true}
            id="edate"
            label="End date for events"
            value={parseISO(endDate)}
            required={true}
            inputVariant="outlined"
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={3} container justify={"flex-end"}>
          <Button color="primary" size="small" variant="outlined" onClick={() => {
            setCurrentReport(0);
          }}>Back to Reports</Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          { newData ?
            <MUIDataTable
              title={"Event Report"}
              data={newData}
              columns={columns}
              options={options}
            /> :
            <></> }
        </Grid>
      </Grid>
    case 2:
      const columns2 = [
        { label: "Program", name: "programName", options: { sort: true } },
        { label: "Team", name: "teamName" },
        { label: "Tier", name: "tierName" },
        { label: "Division", name: "divisionName" },
        { label: "Contact", name: "contactName" },
        { label: "Contact Email", name: "contactEmail" },
        { label: "Location Phone", name: "locationPhone" },
        { label: "Registrant Phone", name: "registrantPhone" },
        { label: "Athletes", name: "athletes" },
        { label: "Crossovers", name: "crossovers" },
        { label: "Paid", name: "billablePaid" },
        { label: "Amount Due", name: "billableDue" },
        { label: "Fees", name: "fees" },
        { label: "Net", name: "producerNet" }]
      const options2 = {
        filter: false,
        print: false,
        rowsPerPage: 100,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        downloadOptions: { filename: `EventTeamsDetailReport.csv`, separator: ',' } // Include producer name in filename and in table title
      }
      return <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={12} lg={12}>
          <Grid container item xs={12}>
            <span>By Teams</span>
            <Switch checked={isEventReportByProgram} onClick={() => handleEventReportToggle()} />
            <span>By Programs</span>
          </Grid>
          { newData ?
            <MUIDataTable
              title={"Event Teams Detail Report"}
              data={newData}
              columns={columns2}
              options={options2}
            /> :
            <></> }
        </Grid>
      </Grid>
    case 3:
      const columns3 = [
        { label: "Program", name: "programName", options: { sort: true } },
        { label: "Unique Athletes", name: "uniqueAthletes" },
        { label: "Crossovers", name: "crossovers" },
        { label: "Paid", name: "billablePaid" },
        { label: "Amount Due", name: "billableDue" },
        { label: "Fees", name: "fees" },
        { label: "Net", name: "producerNet" }]
      const options3 = {
        filter: false,
        print: false,
        rowsPerPage: 100,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        downloadOptions: { filename: `EventProgramsDetailReport.csv`, separator: ',' } // Include producer name in filename and in table title
      }
      return <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={12} lg={12}>
          <Grid container item xs={12}>
            <span>By Teams</span>
            <Switch checked={isEventReportByProgram} onClick={() => handleEventReportToggle()} />
            <span>By Programs</span>
          </Grid>
          { newData ?
            <MUIDataTable
              title={"Event Programs Detail Report"}
              data={newData}
              columns={columns3}
              options={options3}
            /> :
            <></> }
        </Grid>
      </Grid>
    case 4:
      const columns4 = [
        { label: "Program", name: "programName", options: { sort: true } },
        { label: "Currency", name: "currency" },
        { label: "Amount", name: "amount" }]
      const options4 = {
        filter: false,
        print: false,
        rowsPerPage: 100,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        downloadOptions: { filename: `ProducerProgramCreditReport.csv`, separator: ',' } // Include producer name in filename and in table title
      }
      return <Grid container spacing={3} justify="center">
        <Grid item xs={3} container justify={"flex-end"}>
          <Button color="primary" size="small" variant="outlined" onClick={() => {
            setCurrentReport(0);
          }}>Back to Reports</Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          { newData ?
            <MUIDataTable
              title={"Programs Outstanding Credit Report (Global, all events)"}
              data={newData}
              columns={columns4}
              options={options4}
            /> :
            <></>
          }
        </Grid>
      </Grid>
    case 5:
      const columns5 = [
        { label: "Program", name: "programName", options: { sort: true } },
        { label: "Unique Athletes", name: "uniqueAthletes" },
        { label: "Crossovers", name: "crossovers" },
        { label: "Paid", name: "billablePaid" },
        { label: "Amount Due", name: "billableDue" },
        { label: "Fees", name: "fees" },
        { label: "Net", name: "producerNet" }]
      const options5 = {
        filter: true,
        print: true,
        rowsPerPage: 100,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        downloadOptions: { filename: `ConnectedProgramsList.csv`, separator: ',' }
      }
      return <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={12} lg={12}>
          <Grid container item xs={12}>
            <span>By Teams</span>
            {/*<Switch checked={isSelectedByTeam} onClick={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
            {/*  return setIsSelectedByTeam(event.target.checked)*/}
            {/*}}/>*/}
            <span>By Location</span>
          </Grid>
          {newData ?
            <MUIDataTable
              title={"Connected Programs Report"}
              data={newData}
              columns={columns5}
              options={options5}
            /> :
            <></>}
        </Grid>
      </Grid>

    default:
      return <Grid container spacing={3} justify="flex-start">
        <Grid item xs={12} md={6} lg={4}>
          <Card className={classes.divider}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Events Report
              </Typography>
              <Typography variant="body2" component="p">
                A report showing the current status of events
              </Typography>
            </CardContent>
            <CardActions>
              <Button color="primary" variant="outlined" onClick={() => {
                setCurrentReport(1)
              }}>Go</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4} >
          <Card className={classes.divider}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Programs Credit Report
              </Typography>
              <Typography variant="body2" component="p">
                A report showing the current outstanding credit available to all of your programs
              </Typography>
            </CardContent>
            <CardActions>
              <Button color="primary" variant="outlined" onClick={() => {
                setCurrentReport(4)
              }}>Go</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card className={classes.divider}>
            <CardContent>
              <Typography variant="h5" component="h2">
                Connected Programs Report
              </Typography>
              <Typography variant="body2" component="p">
                A report showing the programs that have connected to your events
              </Typography>
            </CardContent>
            <CardActions>
              <Button color="primary" variant="outlined" onClick={() => {
                setCurrentReport(5)
              }}>Go</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
  }
}

export default Reports
