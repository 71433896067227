import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import { Card, CardContent, Container, List, ListItem, ListItemText, ListItemIcon, Typography, Grid, Button } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import { makeMoney } from "lib/functions"
import { useStyles } from "lib/theme"
import { useSelector, useDispatch } from "react-redux"

import { addPayment, chargePaymentSource, getRegistrationTransactionsByEventTeam, getRegistrationTransactionsByIds } from "store/program/paymentActions"
import { selectRegistrationPaymentData } from "store/program/programSelectors"
import { IAppState } from "store/store"
import themis_api from "store/themis_api_pb"
import themis_common from "store/themis_common_pb"
import ProcessPaymentContainer from "./ProcessPaymentContainer"
import { useHistory } from "react-router-dom"
import { RegistrationPaymentData } from 'store/program/programReducer'
import { addSelectedRegistrationsForPayment } from 'store/program/programActions'

const EventRegistrationPaymentContainer: React.FC = (): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const registrationPaymentData = useSelector((state: IAppState) => selectRegistrationPaymentData(state))

  const [paymentAmount, setPaymentAmount] = useState(0)
  const [paymentCurrency, setPaymentCurrency] = useState("")
  const [depositAmount, setDepositAmount] = useState(0)
  const [creditAmount, setCreditAmount] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [registrationTransactions, setRegistrationTransactions] = useState<themis_common.RegistrationTransaction.AsObject[]>([])
  const [addPaymentData, setAddPaymentData] = useState<themis_api.AddPaymentResponse.AsObject>(new themis_api.AddPaymentResponse().toObject())
  const [showFinished, setShowFinished] = useState(false)



  // Get Registration Transactions for display
  useEffect(() => {
    setPaymentCurrency(registrationPaymentData.currency || "USD")
    setPaymentAmount(registrationPaymentData.displayBalanceAmount)
    const getRegTransactions = async () => {
      if (!registrationPaymentData.deposit) {
        const regTransactionsResponse = await getRegistrationTransactionsByIds(registrationPaymentData.selectedRegistrationsForPayment)
        const adjustedRegTx = regTransactionsResponse.map(tx => {
          const paid = tx.paymentsList.reduce<number>((total, txPayment) => {
            if (txPayment.paidOrPending) {
              return total + txPayment.amount
            }
            return total
          }, 0)
          tx.amount -= paid
          return tx
        })
        setRegistrationTransactions(adjustedRegTx)
      } else {
        // This should not be necessary, but left here as a safety for historical transactions.
        let transactionSet: themis_common.RegistrationTransaction.AsObject[] = []
        for (const etInfo of registrationPaymentData.eventTeamInfo) {
          const regTransactionsResponse = await getRegistrationTransactionsByEventTeam(etInfo.eventTeamId)
          let totalPaidAmount = 0
          let totalPayout = 0
          const regTxs = regTransactionsResponse.map(tx => {
            const paid = tx.paymentsList.reduce<number>((total, txPayment) => total + txPayment.amount, 0)
            totalPaidAmount += paid
            totalPayout += tx.crPayoutAmount
            tx.amount -= paid
            return tx
          })
          if (registrationPaymentData.deposit) {
            const newTransaction: themis_common.RegistrationTransaction.AsObject = {
              amount: registrationPaymentData.displayBalanceAmount - totalPaidAmount,
              baseAmount: registrationPaymentData.displayBalanceAmount,
              brandId: 0,
              crPayoutAmount: totalPayout,
              crossoverDiscountAmount: 0,
              currency: registrationPaymentData.currency || "USD",
              description: `Pay Deposit for ${etInfo.teamName} at ${etInfo.eventName}`,
              discountAmount: 0,
              eventId: 0,
              id: 0,
              locationId: 0,
              paid: false,
              paymentsList: [],
              producerId: regTxs[0].producerId,
              programId: regTxs[0].programId,
              teamId: 0,
              eventTeam: {
                id: etInfo.eventTeamId,
                teamId: 0,
                programId: 0,
                locationId: 0,
                name: etInfo.teamName,
                programName: "",
                locationName: "",
                athletesList: [],
                coachesList: [],
                personnelList: [],
                paid: 0,
                remainingAmount: 0,
                remainingDeposit: 0,
                totalPaid: 0,
                allPaid: false,
                deposit: false,
                signed: false,
                isProducerUpdate: false,
                registrationTransactionsList: [],
                order: 0,
                warmUpTime: "",
                performanceTime: "",
              }
            }
            if (newTransaction.amount < 0) {
              newTransaction.amount = newTransaction.baseAmount
            }
            transactionSet.push(newTransaction)
          } else {
            transactionSet = regTxs
          }
        }
        setRegistrationTransactions(transactionSet)
      }
    }
    getRegTransactions()
  }, [registrationPaymentData])


  // Add Payment
  const createPayment = useCallback(async (paymentType: themis_common.PaymentTypeMap[keyof themis_common.PaymentTypeMap], paymentSource: string): Promise<[boolean, string]> => {
    if (registrationTransactions.length > 0 ) {
      setAddPaymentData(new themis_api.AddPaymentResponse().toObject())

      let registrationTransactionsList: number[] = []
      let eventTeamIdsList: number[] = []
      let scope: themis_common.RegistrationTransactionScopeMap[keyof themis_common.RegistrationTransactionScopeMap] = 0
      if (registrationPaymentData.deposit) {
        scope = 19
        eventTeamIdsList = registrationTransactions.map(tx => {
          return tx.eventTeam?.id || 0
        })
      } else {
        registrationTransactionsList = registrationTransactions.filter(tx => {
          return !tx.paid
        }).map(tx => tx.id)
      }

      const addPaymentRequest: themis_api.AddPaymentRequest.AsObject = {
        ...(new themis_api.AddPaymentRequest().toObject()),
        amount: paymentAmount,
        currency: paymentCurrency,
        paymentType: paymentType, // 0 = credit card, 1 = ACH
        paymentSource: paymentSource, // Empty string for newCredit Card
        storedPaymentType: paymentSource.length > 0, // Using existing source
        programId: registrationPaymentData.programId,
        producerId: registrationTransactions[0].producerId,
        scope: scope, // 0 = TRANSACTION_ITEMS_SPECIFIC, 19 = DEPOSIT
        registrationTransactionsList: registrationTransactionsList, // Should probably get this from registrationTransactions array
        eventTeamIdsList: eventTeamIdsList,
      }
      const addPaymentDataResponse = await addPayment(addPaymentRequest)
      setDepositAmount(addPaymentDataResponse.depositAmount)
      setCreditAmount(addPaymentDataResponse.creditAmount)
      setPaymentAmount(addPaymentDataResponse.amount)
      setPaymentCurrency(addPaymentDataResponse.currency)
      if (addPaymentDataResponse.amount === 0) {
        setShowFinished(true)
      }
      setAddPaymentData(addPaymentDataResponse)
      return [true, addPaymentDataResponse.clientSecret]
    }
    return [false, ""]
  }, [paymentAmount, registrationPaymentData.selectedRegistrationsForPayment, registrationPaymentData.deposit, registrationPaymentData.programId, registrationTransactions, paymentCurrency])

  // Charge Payment
  const chargePayment = useCallback(async (paymentType: themis_common.PaymentTypeMap[keyof themis_common.PaymentTypeMap], paymentSource: string): Promise<boolean> => {
    if (paymentAmount > 0) {
      const chargePaymentRequest: themis_api.AddPaymentRequest.AsObject = {
        ...(new themis_api.AddPaymentRequest().toObject()),
        amount: paymentAmount,
        currency: registrationTransactions[0].currency,
        paymentType: paymentType, // 0 = credit card, 1 = ACH
        paymentSource: paymentSource,
        storedPaymentType: true,
        programId: registrationPaymentData.programId,
        producerId: registrationTransactions[0].producer?.id ?? 0,
        scope: 0, // 0 = TRANSACTION_ITEMS_SPECIFIC
        registrationTransactionsList: registrationPaymentData.selectedRegistrationsForPayment, // Should probably get this from registrationTransactions array
        paymentId: addPaymentData.paymentId,
      }
      const paymentSucceededResponse = await chargePaymentSource(chargePaymentRequest)
      if (paymentSucceededResponse) {
        return true
      }
    }
    return false
  }, [paymentAmount, registrationPaymentData, registrationTransactions, addPaymentData])

  // After payment succeeds, button runs this
  const successReturn = async () => {
    const registrationPaymentDataEmpty: RegistrationPaymentData = {
      programId: 0,
      displayBalanceAmount: 0,
      selectedRegistrationsForPayment: [],
      eventTeamInfo: [],
      sourcePath: "",
    }
    await addSelectedRegistrationsForPayment(dispatch, registrationPaymentDataEmpty)
    history.replace(registrationPaymentData.sourcePath)
  }

  const regTransactionsView = useMemo(() => {
    setSubTotal(0)
    if (registrationTransactions.length === 0) return <></>
    return (
      <Container component="main" maxWidth="lg">
        <div className={classes.paper}>
          <Card key={`event-registrations`} className={classes.cardSpacing} >
            <CardContent>
              <Typography variant="h2">Payment Registrations {paymentAmount > 0 ? `- ${ makeMoney(paymentAmount, registrationTransactions[0].currency) }` : ""}</Typography>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <List >
                    {registrationTransactions.map((transactionItem, i) => {
                      const amount = makeMoney(transactionItem.amount, transactionItem.currency)
                      if (registrationPaymentData.deposit && registrationTransactions.length <= 1) {
                        setSubTotal(paymentAmount)
                      } else {
                        setSubTotal((current) => current + transactionItem.amount)
                      }

                      return (
                        <ListItem button disabled key={`registration-${i}`}>
                          <ListItemIcon>
                            <CheckIcon />
                          </ListItemIcon>
                          <ListItemText primary={`${ transactionItem.description} - ${amount}`} />
                        </ListItem>
                      )
                    })}
                  </List>
                </Grid>
                <Grid container item xs={12} md={6} spacing={2} style={{ height: "200px" }}>
                  <Grid item xs={6}><Typography variant="h2" style={{ textAlign: "right" }}>SubTotal:</Typography></Grid>
                  <Grid item xs={6}><Typography variant="h2" style={{ textAlign: "right" }}>{makeMoney(subTotal, paymentCurrency)}</Typography></Grid>
                  { depositAmount > 0 ? <>
                    <Grid item xs={6}><Typography variant="h2" style={{ textAlign: "right" }}>Applied Deposit:</Typography></Grid>
                    <Grid item xs={6}><Typography variant="h2" style={{ textAlign: "right" }}>{makeMoney(depositAmount, paymentCurrency)}</Typography></Grid>
                  </> : <Grid item xs={12}><Typography variant="h2">&nbsp;</Typography></Grid> }
                  { creditAmount > 0 ? <>
                    <Grid item xs={6}><Typography variant="h2" style={{ textAlign: "right" }}>Applied Credit:</Typography></Grid>
                    <Grid item xs={6}><Typography variant="h2" style={{ textAlign: "right" }}>{makeMoney(creditAmount, paymentCurrency)}</Typography></Grid>
                  </> : <Grid item xs={12}><Typography variant="h2">&nbsp;</Typography></Grid> }
                  <Grid item xs={6}><Typography variant="h2" style={{ textAlign: "right" }}>Total:</Typography></Grid>
                  <Grid item xs={6}><Typography variant="h2" style={{ textAlign: "right" }}>{makeMoney(subTotal - (depositAmount + creditAmount), paymentCurrency)}</Typography></Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </Container>
    )
  }, [registrationTransactions, classes.paper, classes.cardSpacing, paymentAmount, subTotal, paymentCurrency, depositAmount])

  // TODO: Permissions
  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid container item xs={12} md={10} alignItems="flex-start">
          <Typography variant="h1" className={classes.headerTypo}>Finalize Payment</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button variant="outlined" onClick={() => history.replace(registrationPaymentData.sourcePath)}>Go Back</Button>
        </Grid>
        {/* <Grid container item xs={12} alignItems="flex-start">
          {regTransactionsView}
        </Grid> */}
        <Grid container item xs={12} alignItems="flex-start" justify="center">
          <ProcessPaymentContainer paymentAmount={paymentAmount} paymentCurrency={paymentCurrency} successReturn={successReturn} createPayment={createPayment} chargePayment={chargePayment} showFinished={showFinished} >
            {regTransactionsView}
          </ProcessPaymentContainer>
          {/* {showCreditCardForm ? (<ProcessPaymentContainer reviewItemsJSX={regTransactionsView} paymentAmount={paymentAmount} clientSecret={addPaymentData.clientSecret} successReturn={successReturn} createPayment={createPayment}/>
          ) : <Typography variant="h3">Connecting to Payment Processor <CircularProgress color="secondary" size={20} /></Typography>} */}
        </Grid>
      </Grid>
    </Container>
  )
}

export default EventRegistrationPaymentContainer
