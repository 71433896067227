import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Transition, useStyles } from "lib/theme"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  Switch,
  InputAdornment,
  ListItem, ListItemIcon, ListItemText, Checkbox
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxEmptyIcon,
  ArrowRight as ArrowRightIcon,
  ArrowDropDown as ArrowDownIcon  } from '@material-ui/icons'
import { IAppState } from "store/store"
import { selectCurrentEventRegCodes } from "store/producer/producerSelectors"
import { selectCurrentEventDivisions } from "store/producer/producerSelectors"
import { addEventRegCode, deleteEventRegCode, updateEventRegCode } from "store/producer/eventActions"
import themis_common from "store/themis_common_pb"

import { format } from 'date-fns'
import { currencySymbols, regexpNumber } from "lib/constants"
import { getCountryDateFormat } from 'lib/functions'
import HelpIcon from "../../util/HelpIcon"

interface eventRegCodeProps {
  producerId: number,
  brandId: number,
  eventId: number,
  currency: string,
  userCanViewEvent: boolean,
  userCanEditEvent: boolean,
}

const sortDivisions = (divisionA: themis_common.EventDivision.AsObject, divisionB: themis_common.EventDivision.AsObject) => {
  if (!divisionA || !divisionA.division || !divisionA.division.tier ||  !divisionA.division.tier.name) return -1
  if (!divisionB || !divisionB.division || !divisionB.division.tier ||  !divisionB.division.tier.name) return 1
  if (divisionA.division.tier.name > divisionB.division.tier.name) return 1
  if (divisionA.division.tier.name < divisionB.division.tier.name) return -1
  if (divisionA.division.name > divisionB.division.name) return 1
  if (divisionA.division.name < divisionB.division.name) return -1
  return 0
}

// TODO: Don't allow negative date range
// TODO: Change amount format and verify

const EventRegCodes: React.FC<eventRegCodeProps> = ({ producerId, brandId, eventId, currency, userCanViewEvent, userCanEditEvent }): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()


  const [addCodeDialogOpen, setAddCodeDialogOpen] = useState(false)
  const [newRegCodeName, setNewRegCodeName] = useState('')
  const [newRegCodeValid, setNewRegCodeValid] = useState('')
  const [newRegCodeExpires, setNewRegCodeExpires] = useState('')
  const [newRegCodeAmt, setNewRegCodeAmt] = useState('')
  const [newRegCodeType, setNewRegCodeType] = useState<themis_common.UnitTypeMap[keyof themis_common.UnitTypeMap]>(0)
  const [newRegCodeCode, setNewRegCodeCode] = useState('')
  const [newRegCodeError, setNewRegCodeError] = useState('')
  const [currencySymbol, setCurrencySymbol] = useState<string>("$")
  const [newDiscountPerType, setNewDiscountPerType] = useState("athlete")
  const [depositType, setDepositType] = useState("athlete")
  const [discountAppliesTo, setDiscountAppliesTo] = useState("All")
  const [depositAmount, setDepositAmount] = useState(0)
  const [isDefault, setIsDefault] = useState(false)
  const [isCrossover, setIsCrossover] = useState(false)
  const [countryDateFormat, setCountryDateFormat] = useState<string>("MM/dd/yyyy")
  const [searchDivisionText, setSearchDivisionText] = useState<string>("")
  const [filteredEventDivisions, setFilteredEventDivisions] = useState<themis_common.EventDivision.AsObject[] | undefined>([])
  const [eventDivisionIds, setEventDivisions] = useState<number[]>([])
  const [mode, setMode] = useState<"Add"|"Edit">("Add")
  const [regCodeId, setRegCodeId] = useState<number>(0)

  useEffect(() => {
    const symbol = currencySymbols.find(cs => cs.cc === currency)
    if (symbol) setCurrencySymbol(symbol.symbol)
  }, [currency])

  const eventRegCodes = useSelector((state: IAppState) => selectCurrentEventRegCodes(state))
  const currentEventDivisions = useSelector((state: IAppState) => selectCurrentEventDivisions(state))

  const [selectedValidDate, setSelectedValidDate] = useState<Date | null>(null)
  const [selectedExpireDate, setSelectedExpireDate] = useState<Date | null>(null)
  const [selectedCode, setSelectedCode] = useState(0)


  useEffect(() => {
    setCountryDateFormat(getCountryDateFormat(new Date()))
  }, [])


  useMemo(() => {
    if (searchDivisionText.length === 0) {
      setFilteredEventDivisions(currentEventDivisions)
    } else {
      setFilteredEventDivisions(currentEventDivisions?.filter(currentEventDivision => (currentEventDivision?.division?.tier?.name + " - " + currentEventDivision?.division?.name).toLowerCase().includes(searchDivisionText.toLowerCase())))
    }
  }, [searchDivisionText, currentEventDivisions])

  const handleToggle = (eventDivisionId: number) => {
    if (eventDivisionIds.includes(eventDivisionId)) {
      setEventDivisions(thisEventDivisions => {
        const newEventDivisions = [...thisEventDivisions]
        newEventDivisions.splice(newEventDivisions.indexOf(eventDivisionId), 1)
        return newEventDivisions
      })
    } else {
      setEventDivisions(thisEventDivisions => {
        const newEventDivisions = [...thisEventDivisions, eventDivisionId]
        return newEventDivisions
      })
    }
  }

  const handleCheck = (eventDivisionId: number) => {
    if (!eventDivisionIds.includes(eventDivisionId)) {
      setEventDivisions(thisEventDivisions => {
        const newEventDivisions = [...thisEventDivisions, eventDivisionId]
        return newEventDivisions
      })
    }
  }

  const handleUnCheck = (eventDivisionId: number) => {
    if (eventDivisionIds.includes(eventDivisionId)) {
      setEventDivisions(thisEventDivisions => {
        const newEventDivisions = [...thisEventDivisions]
        newEventDivisions.splice(newEventDivisions.indexOf(eventDivisionId), 1)
        return newEventDivisions
      })
    }
  }


  const divisionSelectList = useMemo(() => {
    return filteredEventDivisions?.sort(sortDivisions).map((division) => {
      const labelId = `checkbox-list-label-${division.id}`
      return (
        <ListItem key={division.id} role={undefined} dense button
          onClick={() => {
            handleToggle(division.id)
          }}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              tabIndex={-1}
              disableRipple
              checked={eventDivisionIds.includes(division.id)}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={division.division?.tier?.name + " - " + division.division?.name}/>
        </ListItem>
      )
    })
  }, [filteredEventDivisions, eventDivisionIds])

  const addRegCodeIcon = useMemo(() => {
    if (userCanEditEvent) {
      return (
        <IconButton className={classes.clickable} aria-label="add" onClick={_ => {
          setAddCodeDialogOpen(true)
          setMode("Add")
        }}>
          <AddIcon fontSize="small"/>
        </IconButton>
      )
    } else {
      return []
    }
  }, [classes.clickable, userCanEditEvent])

  const handleValidDateChange = (date: Date | null) => {
    // Checking if date is valid before setting it
    if (date && (date instanceof Date && !isNaN(date.valueOf()))) {
      setSelectedValidDate(date)
      setNewRegCodeValid(format(date, "yyyy-MM-dd"))
    }
  }

  const handleExpireDateChange = (date: Date | null) => {
    // Checking if date is valid before setting it
    if (date && (date instanceof Date && !isNaN(date.valueOf()))) {
      setSelectedExpireDate(date)
      setNewRegCodeExpires(format(date, "yyyy-MM-dd"))
    }
  }

  const formatClockTimeRangeForRegCodes = (startClockTime?: themis_common.ClockTime.AsObject, endClockTime?: themis_common.ClockTime.AsObject) => {
    const s = startClockTime?.formattedDateTime
    const e = endClockTime?.formattedDateTime
    if (s && e) {
      if (s.year === e.year) {
        if (s.monthNumber === e.monthNumber) return `${s.year} ${s.monthLong} ${s.dayNumber} - ${e.dayNumber}`
        else return `${s.year} ${s.monthLong} ${s.dayNumber} - ${e.monthLong} ${e.dayNumber}`
      }
      return `${s.year} ${s.monthLong} ${s.dayNumber} - ${e.year} ${e.monthLong} ${e.dayNumber}`
    }
    return ""
  }

  const handleEditRegCode = (code: themis_common.EventRegistrationCode.AsObject) => {
    setAddCodeDialogOpen(true)
    setMode("Edit")

    if (code.beginsOnClockTime && code.expiresOnClockTime) {
      const beginDate = new Date(`${code.beginsOnClockTime.formattedDateTime?.year}-${code.beginsOnClockTime.formattedDateTime?.monthLong}-${code.beginsOnClockTime.formattedDateTime?.dayNumber}`)
      const endDate = new Date(`${code.expiresOnClockTime.formattedDateTime?.year}-${code.expiresOnClockTime.formattedDateTime?.monthLong}-${code.expiresOnClockTime.formattedDateTime?.dayNumber}`)

      setSelectedValidDate(beginDate)
      setNewRegCodeValid(format(beginDate, "yyyy-MM-dd"))
      setSelectedExpireDate(endDate)
      setNewRegCodeExpires(format(endDate, "yyyy-MM-dd"))
    }
    setRegCodeId(code.id)
    setNewRegCodeName(code.name)
    setNewRegCodeCode(code.code)
    setNewRegCodeAmt(code.amountString)
    setNewRegCodeType(code.discountType)
    setNewDiscountPerType(code.discountPerType)
    setDepositType(code.depositType)
    setDepositAmount(parseInt(code.depositAmountString))
    setDepositAmount(code.depositAmount / 100)
    setIsDefault(code.pb_default)
    setIsCrossover(code.crossovers)
    setDiscountAppliesTo(code.discountAppliesTo)

    const codeEventDivisionIds: number[] = []
    code.eventDivisionsList.forEach(ed => codeEventDivisionIds.push(ed.id))
    setEventDivisions(codeEventDivisionIds)
  }

  const codeList = useMemo(() => {
    if (!eventRegCodes) return
    const handleDeleteRegCode = async (eventCodeId: number) => {
      await deleteEventRegCode(dispatch, eventCodeId, producerId, Number(brandId), Number(eventId))
    }
    const outList: ReactNode[] = []
    eventRegCodes.forEach((code: themis_common.EventRegistrationCode.AsObject) => {
      const codeAmountInfo = code.discountType === 0 ? `${code.amountString}% discount` : `${currencySymbol}${code.amountString} discount per ${code.discountPerType}`
      const depositPer = code.depositType === 'athlete' ? `per ${code.depositType}` : ''
      const codeDepositInfo = code.depositAmount > 0 ? ` -- deposit of ${currencySymbol}${code.depositAmountString} ${depositPer} before expiry date required to retain discount.` : ""
      outList.push(<Grid container key={code.id}>
        <Grid item xs={3}>{code.name} - "{code.code}"</Grid>
        <Grid item xs={5}>
          <>{`${ code.pb_default ? "[autofill]" : "" } ${codeAmountInfo} ${ code.crossovers ? "(Applies to Crossovers)": ""} ${codeDepositInfo} (${formatClockTimeRangeForRegCodes(code.beginsOnClockTime, code.expiresOnClockTime)})`}</>
        </Grid>
        <Grid item container xs={3} onClick={() => {
          if (selectedCode === code.id) {
            setSelectedCode(0)
          } else {
            setSelectedCode(code.id)
          }
        }
        }>
          {code.discountAppliesTo === "DivisionList" ?
            <Grid container alignItems="flex-start">Divisions: { code.eventDivisionsList.length } { selectedCode === code.id ? <ArrowDownIcon /> : <ArrowRightIcon /> }</Grid>
            : []
          }
        </Grid>
        <Grid item xs={1}>
          {userCanEditEvent ?
            (
              <>
                <IconButton className={classes.clickable} aria-label="delete" style={{ float: 'right', padding: '4px' }}
                  onClick={_e => {
                    handleDeleteRegCode(code.id)
                  }}>
                  <DeleteIcon fontSize="small"/>
                </IconButton>
                <IconButton className={classes.clickable} aria-label="edit" style={{ float: 'right', padding: '4px' }}
                  onClick={_e => {
                    handleEditRegCode(code)
                  }}>
                  <EditIcon fontSize="small"/>
                </IconButton>
              </>
            )
            : []
          }
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={4} hidden={selectedCode !== code.id}>
          { code.eventDivisionsList.sort(sortDivisions).map(division => <ListItem>{division.division?.tier?.name} - {division.division?.name}</ListItem>) }
        </Grid>
      </Grid>)
    }, [eventRegCodes,selectedCode])

    return outList
  }, [eventRegCodes, selectedCode, dispatch, producerId, brandId, eventId, currencySymbol, userCanEditEvent, classes.clickable])

  const handleCloseAddCodeDialog = () => {
    setNewRegCodeAmt("")
    setNewRegCodeName("")
    setNewRegCodeCode("")
    setNewRegCodeValid("")
    setNewRegCodeExpires("")
    setSelectedValidDate(null)
    setSelectedExpireDate(null)
    setNewDiscountPerType("athlete")
    setNewRegCodeError("")
    setAddCodeDialogOpen(false)
    setIsCrossover(false)
    setIsDefault(false)
    setDiscountAppliesTo("All")
    setEventDivisions([])
    setDepositAmount(0)
    setAddCodeDialogOpen(false)
  }

  const submitForm = async () => {
    if (!userCanEditEvent) return
    if (Number(newRegCodeAmt) <= 0) {
      setNewRegCodeError('Amount is Required to be greater than zero')
      return
    }
    if (!regexpNumber.test(newRegCodeAmt) || newRegCodeAmt.includes("-")) {
      setNewRegCodeError('Amount is Required to be a valid number')
      return
    }
    if (newRegCodeType === 0 && (Number(newRegCodeAmt) > 100 || newRegCodeAmt.includes("."))) {
      setNewRegCodeError('Percent Amount is Required to be a valid percent')
      return
    }
    if (!newRegCodeExpires) {
      setNewRegCodeError('Expiration date is required')
      return
    }
    if (!newRegCodeValid) {
      setNewRegCodeError('Valid date is required')
      return
    }
    if (newRegCodeName.trim().length === 0) {
      setNewRegCodeError('Name is required')
      return
    }
    if (newDiscountPerType.trim().length === 0) {
      setNewRegCodeError('Discount per is required')
      return
    }
    if (mode === "Add") {
      await addEventRegCode(dispatch, newRegCodeName.trim(), newRegCodeCode.trim(), newRegCodeType, newRegCodeAmt, newRegCodeValid, newRegCodeExpires, newDiscountPerType, Number(eventId), Number(brandId), producerId, depositType, depositAmount, isDefault, isCrossover, discountAppliesTo, eventDivisionIds)
    } else {
      await updateEventRegCode(dispatch, regCodeId, newRegCodeName.trim(), newRegCodeCode.trim(), newRegCodeType, newRegCodeAmt, newRegCodeValid, newRegCodeExpires, newDiscountPerType, Number(eventId), Number(brandId), producerId, depositType, depositAmount, isDefault, isCrossover, discountAppliesTo, eventDivisionIds)
    }
    setNewRegCodeAmt("")
    setNewRegCodeName("")
    setNewRegCodeCode("")
    setNewRegCodeValid("")
    setNewRegCodeExpires("")
    setSelectedValidDate(null)
    setSelectedExpireDate(null)
    setNewDiscountPerType("athlete")
    setNewRegCodeError("")
    setAddCodeDialogOpen(false)
    setIsCrossover(false)
    setIsDefault(false)
    setDiscountAppliesTo("All")
    setEventDivisions([])
    setDepositAmount(0)
  }

  const addCodeDialog = !userCanEditEvent ? <></> : (
    <Dialog
      open={addCodeDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseAddCodeDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{`${mode} Registration Code`}</DialogTitle>

      <DialogContent>
        <form className={classes.dateForm} noValidate onKeyPress={e => {
          if (e.key === 'Enter') {
            submitForm()
          }
        }}>
          <Container>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} className={classes.gridForm}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="name"
                  label="Name"
                  type="string"
                  required
                  inputProps={{ maxLength: 100 }}
                  value={newRegCodeName}
                  className={classes.fullWidthField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => {
                    setNewRegCodeName(e.target.value)
                  }}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.slice(0, 100)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} className={classes.gridForm}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="code"
                  label="Code -- Leave blank to generate, max 10 characters"
                  type="string"
                  inputProps={{ maxLength: 10 }}
                  value={newRegCodeCode}
                  className={classes.fullWidthField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => {
                    setNewRegCodeCode(e.target.value)
                  }}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // Limit to 10, uppercase, replace space with _
                    e.target.value = e.target.value.slice(0, 10).toUpperCase().split(' ').join('_')
                  }}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginLeft: "8px" }}>
              <Grid item xs={12} sm={6} md={6} className={classes.gridForm}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={countryDateFormat}
                  margin="normal"
                  autoOk={true}
                  id="validate"
                  label="Valid on"
                  value={selectedValidDate}
                  required={true}
                  inputVariant="outlined"
                  onChange={handleValidDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} className={classes.gridForm}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={countryDateFormat}
                  margin="normal"
                  autoOk={true}
                  id="expdate"
                  label="Expires on"
                  value={selectedExpireDate}
                  required={true}
                  inputVariant="outlined"
                  onChange={handleExpireDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} sm={3} md={3} className={classes.gridForm}>
                <Select
                  style={{ width: "100px", marginTop: "17px" }}
                  variant="outlined"
                  id="demo-customized-select"
                  value={newRegCodeType}
                  required
                  className={classes.numField}
                  onChange={e => {
                    setNewRegCodeType(e.target.value as themis_common.UnitTypeMap[keyof themis_common.UnitTypeMap])
                  }}
                >
                  <MenuItem value={0}>%</MenuItem>
                  <MenuItem value={1}>{currencySymbol}</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6} sm={3} md={3} className={classes.gridForm}>
                <TextField
                  variant="outlined"
                  id="amount"
                  margin="normal"
                  label="Amount"
                  type="number"
                  required
                  value={newRegCodeAmt}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => {
                    if (newRegCodeType === 1 && regexpNumber.test(e.target.value) && !e.target.value.includes("-")) {
                      setNewRegCodeAmt(e.target.value)
                    } else if (newRegCodeType === 0 && regexpNumber.test(e.target.value) && !e.target.value.includes("-") && Number(e.target.value) <= 100 && !e.target.value.includes(".")) {
                      setNewRegCodeAmt(e.target.value)
                    } else return false
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={1} md={1}>
                <Box mt={4}><Typography align="center">Per</Typography></Box>
              </Grid>
              <Grid item xs={8} sm={5} md={5} className={classes.gridForm}>
                <Select
                  style={{ width: "100%", marginTop: "17px" }}
                  variant="outlined"
                  id="demo-simple-select-required-label"
                  value={newDiscountPerType}
                  required
                  className={classes.numField}
                  onChange={e => {
                    setNewDiscountPerType(e.target.value as string)
                  }}
                >
                  <MenuItem value={"athlete"}>Athlete</MenuItem>
                  <MenuItem value={"team"}>Team</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={6} >
                <Typography variant="h3">Auto Fill on Registration</Typography>
                <HelpIcon
                  markdownText={`Code will auto fill for any registering teams.  Do this if you are asking for a deposit.`}
                  boxSize="small"
                  iconSize="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} >
                <Typography component="div">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Off</Grid>
                    <Grid item>
                      <Switch checked={isDefault} onChange={ e => setIsDefault(e.target.checked)} color="primary" />
                    </Grid>
                    <Grid item>On</Grid>
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={6} >
                <Typography variant="h3">Applies to Crossovers</Typography>
                <HelpIcon
                  markdownText={`Code will apply to both regular registrations as well as to crossover registrations.`}
                  boxSize="small"
                  iconSize="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} >
                <Typography component="div">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Off</Grid>
                    <Grid item>
                      <Switch checked={isCrossover} onChange={ e => setIsCrossover(e.target.checked)} color="primary" />
                    </Grid>
                    <Grid item>On</Grid>
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} >
                <Typography variant="h3">Deposit</Typography>
                <HelpIcon
                  markdownText={`A deposit is required by the code expiration date to retain the discount beyond that date.  Anything paid prior to the expiration date will retain it's discount.`}
                  boxSize="small"
                  iconSize="small"
                />

              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={6} className={classes.gridForm}>
                <TextField
                  variant="outlined"
                  id="depositAmount"
                  margin="normal"
                  label="Amount"
                  type="number"
                  required
                  value={depositAmount}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                  }}
                  onChange={e => {
                    if (regexpNumber.test(e.target.value) && !e.target.value.includes("-")) {
                      setDepositAmount(Number(e.target.value))
                    } else return false
                  }}
                />
              </Grid>

              <Grid item xs={4} sm={1} md={1}>
                <Box mt={4}><Typography align="center">Per</Typography></Box>
              </Grid>
              <Grid item xs={8} sm={5} md={5} className={classes.gridForm}>
                <Select
                  style={{ width: "100%", marginTop: "17px" }}
                  variant="outlined"
                  id="demo-simple-select-required-label"
                  value={depositType}
                  required
                  className={classes.numField}
                  onChange={e => {
                    setDepositType(e.target.value as string)
                  }}
                >
                  <MenuItem value={"athlete"}>Athlete</MenuItem>
                  <MenuItem value={"team"}>Team</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h3">Limit Code to Specific Teams</Typography>
              </Grid>
              <Grid item xs={4} sm={1} md={1}>
                <Box mt={4}><Typography align="center">Filter By</Typography></Box>
              </Grid>
              <Grid item xs={8} sm={5} md={5} className={classes.gridForm}>
                <Select
                  style={{ width: "100%", marginTop: "17px" }}
                  variant="outlined"
                  id="demo-simple-select-required-label"
                  value={discountAppliesTo}
                  required
                  className={classes.numField}
                  onChange={e => {
                    setEventDivisions([])
                    setDiscountAppliesTo(e.target.value as string)
                  }}
                >
                  <MenuItem value={"All"}>All Divisions</MenuItem>
                  <MenuItem value={"DivisionList"}>By Divisions</MenuItem>
                  {/* <MenuItem value={"TierList"}>By Tiers</MenuItem> */}
                  {/* <MenuItem value={"ProgramList"}>By Programs</MenuItem> */}
                </Select>
              </Grid>
              { discountAppliesTo === "DivisionList" ?
                (<Grid item xs={12} sm={6} md={6} className={classes.gridForm}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="searchDivision"
                    label="Filter Divisions"
                    type="string"
                    inputProps={{ maxLength: 10 }}
                    value={searchDivisionText}
                    className={classes.fullWidthField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      setSearchDivisionText(e.target.value)
                    }}
                  />
                </Grid>) : <></>
              }
            </Grid>
            { discountAppliesTo === "DivisionList" ?
              <Grid container style={{ overflowY: "scroll", height:'300px' }}>
                <Grid item xs={6} >
                  <ListItem key="Select All" role={undefined} dense button
                    onClick={() => {
                      filteredEventDivisions?.forEach((division) => {
                        handleCheck(division.id)
                      })
                    }}>
                    <ListItemIcon>
                      <CheckBoxIcon style={{ marginLeft: -2 }} />
                    </ListItemIcon>
                    <ListItemText id={"SelectAllDivisions"} primary="Select All Visible"/>
                  </ListItem>
                </Grid>
                <Grid item xs={6} >
                  <ListItem key="Remove All" role={undefined} dense button
                    onClick={() => {
                      filteredEventDivisions?.forEach((division) => {
                        handleUnCheck(division.id)
                      })
                    }}>
                    <ListItemIcon>
                      <CheckBoxEmptyIcon style={{ marginLeft: -2 }} />
                    </ListItemIcon>
                    <ListItemText id={"UnSelectAllDivisions"} primary="Un-Select All Visible"/>
                  </ListItem>
                </Grid>

                { divisionSelectList }
              </Grid> : <></>
            }
          </Container>
        </form>
        <Typography>{newRegCodeError}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAddCodeDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={submitForm} color="primary">
          {mode} Discount Code
        </Button>
      </DialogActions>
    </Dialog>
  )

  return !userCanViewEvent ? <></> : (
    <>
      <Grid item xs={7}>
        <Typography variant="h2">Discount Codes
          {addRegCodeIcon}
        </Typography>

      </Grid>
      <Grid container item xs={12}>
        {codeList}
      </Grid>
      {addCodeDialog}
    </>)
}

export default EventRegCodes
