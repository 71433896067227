import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { registerUser, checkEmail } from 'store/user/userActions'
import { isValidEmail } from "lib/validators"
import { AsFields } from "lib/constants"
import { useHistory } from "react-router-dom"
import Register from './Register'
import publicIp from "public-ip"
import { IAppState } from 'store/store'
import { selectCurrentUser } from 'store/user/userSelectors'


const RegisterContainer: React.FC = (): ReactElement => {
  const history = useHistory()
  const dispatch = useDispatch()

  const username = useSelector((state: IAppState) => selectCurrentUser(state))

  const emptyReg = {
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    password: "",
    password2: "",
    program: true,
    programUSASF: false,
    producer: false,
    agreedToTerms: false,
    ipAddress: ""
  }

  const [registration, setRegistration] = useState<AsFields>(emptyReg)
  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})
  const [emailAlreadyExists, setEmailAlreadyExists] = useState<boolean>(false)
  const [validUniqueEmail, setValidUniqueEmail] = useState<boolean>(false)
  const [checkRegister, setCheckRegister] = useState<boolean>(false)
  const [formReady, setFormReady] = useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    if (username) {
      history.push('/')
    }
  }, [username, history])

  useEffect(() => {
    const setEmail = async (): Promise<void> => {
      const validEmail = isValidEmail(registration.email)
      let availableEmailCheck = true
      if (validEmail) {
        availableEmailCheck = await checkEmail(registration.email)
      }

      if (registration.email.length >=1 && !validEmail) {
        setErrorText({ ...errorText, email: "Invalid email" })
        setEmailAlreadyExists(false)
      } else if (!availableEmailCheck) {
        setErrorText({ ...errorText, email: "There is already an account with that email" })
        setEmailAlreadyExists(true)
      }

      setValidUniqueEmail(validEmail && availableEmailCheck)
    }
    setEmail()
  }, [registration.email]) // errorText should not be included (causes infinite rerender)

  const getClientIp = async () => {
    const clientIp = await publicIp.v4({ fallbackUrls: [ "https://ifconfig.co/ip" ] })
    setRegistration({ ...registration, ipAddress: clientIp })
  }

  useEffect(() => {
    getClientIp()
  }, [])

  const submitForm: any = async (event: any) => {
    setCheckRegister(true)
    event.preventDefault()
    if (formReady) {
      const register = registerUser(registration)
      try {
        await register(dispatch)
        if (registration) {
          history.push('/onboard')
        } else {
          history.push('/')
        }
      } catch (e) {
        setDialogOpen(true)
        console.error(e)
      }
    }
  }

  return (
    <Register
      registration={registration}
      setRegistration={setRegistration}
      setErrorText={setErrorText}
      errorText={errorText}
      setFormReady={setFormReady}
      validUniqueEmail={validUniqueEmail}
      checkRegister={checkRegister}
      emailAlreadyExists={emailAlreadyExists}
      setCheckRegister={setCheckRegister}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      submitForm={submitForm} />
  )

}

export default RegisterContainer
