import themis_common from 'store/themis_common_pb'
import themis_api from "store/themis_api_pb"

export const buildProgramUser = (objectId: number, userId?: number, name?: string, email?: string, permissions?: themis_common.Permission.AsObject[]): themis_api.ProgramUser => {
  const programUser = new themis_api.ProgramUser()
  const program = new themis_common.Program()
  program.setId(objectId)
  programUser.setProgram(program)
  const userPermissions = new themis_common.UserPermissions()
  const user = new themis_common.User()
  if (userId) user.setId(userId)
  if (name) user.setName(name)
  if (email) user.setEmail(email)
  userPermissions.setUser(user)
  if (permissions) {
    const permissionList: themis_common.Permission[] = permissions.map((perm) => {
      const permission = new themis_common.Permission()
      permission.setCode(perm.code)
      permission.setId(perm.id)
      permission.setName(perm.name)
      return permission
    })
    userPermissions.setPermissionsList(permissionList)
  }

  programUser.setUser(userPermissions)
  return programUser
}

export const buildLocationUser = (programId: number, locationId: number, userId?: number, name?: string, email?: string, permissions?: themis_common.Permission.AsObject[]): themis_api.LocationUser => {
  const locationUser = new themis_api.LocationUser()
  const location = new themis_common.Location()
  location.setId(locationId)
  location.setProgramId(programId)
  locationUser.setLocation(location)
  const userPermissions = new themis_common.UserPermissions()
  const user = new themis_common.User()
  if (userId) user.setId(userId)
  if (name) user.setName(name)
  if (email) user.setEmail(email)
  userPermissions.setUser(user)
  if (permissions) {
    const permissionList: themis_common.Permission[] = permissions.map((perm) => {
      const permission = new themis_common.Permission()
      permission.setCode(perm.code)
      permission.setId(perm.id)
      permission.setName(perm.name)
      return permission
    })
    userPermissions.setPermissionsList(permissionList)
  }

  locationUser.setUser(userPermissions)
  return locationUser
}

export const buildTeamUser = (programId: number, locationId: number, teamId: number, userId?: number, name?: string, email?: string, permissions?: themis_common.Permission.AsObject[]): themis_api.TeamUser => {
  const teamUser = new themis_api.TeamUser()
  const team = new themis_common.Team()
  team.setId(teamId)
  team.setLocationId(locationId)
  team.setProgramId(programId)
  teamUser.setTeam(team)
  const userPermissions = new themis_common.UserPermissions()
  const user = new themis_common.User()
  if (userId) user.setId(userId)
  if (name) user.setName(name)
  if (email) user.setEmail(email)
  userPermissions.setUser(user)
  if (permissions) {
    const permissionList: themis_common.Permission[] = permissions.map((perm) => {
      const permission = new themis_common.Permission()
      permission.setCode(perm.code)
      permission.setId(perm.id)
      permission.setName(perm.name)
      return permission
    })
    userPermissions.setPermissionsList(permissionList)
  }

  teamUser.setUser(userPermissions)
  return teamUser
}

export const buildProducerUser = (objectId: number, userId?: number, name?: string, email?: string, permissions?: themis_common.Permission.AsObject[]): themis_api.ProducerUser => {
  const producerUser = new themis_api.ProducerUser()
  const producer = new themis_common.Producer()
  producer.setId(objectId)
  producerUser.setProducer(producer)
  const userPermissions = new themis_common.UserPermissions()
  const user = new themis_common.User()
  if (userId) user.setId(userId)
  if (name) user.setName(name)
  if (email) user.setEmail(email)
  userPermissions.setUser(user)
  if (permissions) {
    const permissionList: themis_common.Permission[] = permissions.map((perm) => {
      const permission = new themis_common.Permission()
      permission.setCode(perm.code)
      permission.setId(perm.id)
      permission.setName(perm.name)
      return permission
    })
    userPermissions.setPermissionsList(permissionList)
  }

  producerUser.setUser(userPermissions)
  return producerUser
}

export const buildBrandUser = (producerId: number, brandId: number, userId?: number, name?: string, email?: string, permissions?: themis_common.Permission.AsObject[]): themis_api.BrandUser => {
  const brandUser = new themis_api.BrandUser()
  const brand = new themis_common.Brand()
  brand.setId(brandId)
  brand.setProducerId(producerId)
  brandUser.setBrand(brand)
  const userPermissions = new themis_common.UserPermissions()
  const user = new themis_common.User()
  if (userId) user.setId(userId)
  if (name) user.setName(name)
  if (email) user.setEmail(email)
  userPermissions.setUser(user)
  if (permissions) {
    const permissionList: themis_common.Permission[] = permissions.map((perm) => {
      const permission = new themis_common.Permission()
      permission.setCode(perm.code)
      permission.setId(perm.id)
      permission.setName(perm.name)
      return permission
    })
    userPermissions.setPermissionsList(permissionList)
  }

  brandUser.setUser(userPermissions)
  return brandUser
}

export const buildEventUser = (producerId: number, brandId: number, eventId: number, userId?: number, name?: string, email?: string, permissions?: themis_common.Permission.AsObject[]): themis_api.EventUser => {
  const eventUser = new themis_api.EventUser()
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setBrandId(brandId)
  event.setProducerId(producerId)
  eventUser.setEvent(event)
  const userPermissions = new themis_common.UserPermissions()
  const user = new themis_common.User()
  if (userId) user.setId(userId)
  if (name) user.setName(name)
  if (email) user.setEmail(email)
  userPermissions.setUser(user)
  if (permissions) {
    const permissionList: themis_common.Permission[] = permissions.map((perm) => {
      const permission = new themis_common.Permission()
      permission.setCode(perm.code)
      permission.setId(perm.id)
      permission.setName(perm.name)
      return permission
    })
    userPermissions.setPermissionsList(permissionList)
  }

  eventUser.setUser(userPermissions)
  return eventUser
}

