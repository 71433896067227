import { client } from "store/themisClient"
import themis_common from "store/themis_common_pb"
import themis_api from "store/themis_api_pb"
import { buildGuardian } from "./programBuilders"
import { createAction } from "redux-actions"
import { AnyDispatchType, SET_GUARDIANS } from "lib/constants"

const setGuardians = createAction<themis_common.Guardian.AsObject[]>(SET_GUARDIANS)

async function getGuardian(dispatch: AnyDispatchType, guardianId: number, athleteId: number, programId: number, locationId: number, validateTeamId: number): Promise<themis_common.Guardian.AsObject | undefined> {
  const athlete = new themis_common.Athlete()
  athlete.setProgramId(programId)
  athlete.setLocationId(locationId)
  if (validateTeamId && validateTeamId > 0) athlete.setValidateTeamId(validateTeamId)
  athlete.setId(athleteId)

  const guardian = new themis_common.Guardian()
  guardian.setId(guardianId)

  const athleteGuardian = new themis_api.GuardianAthleteRequest()
  athleteGuardian.setAthlete(athlete)
  athleteGuardian.setGuardian(guardian)

  // Update guardiansList
  const res: themis_api.GuardianResponse = await client.getGuardian(athleteGuardian, {})
  const guardianResponse = res.toObject()
  dispatch(setGuardians(guardianResponse.guardiansList))

  const returnGuardian = guardianResponse.guardian
  return returnGuardian
}

async function addGuardian(dispatch: AnyDispatchType, guardianPerson: themis_common.Person.AsObject, programId: number, locationId: number, athleteId: number, validateTeamId: number): Promise<void> {
  const guardian = buildGuardian(undefined, programId, locationId, guardianPerson)
  const athlete = new themis_common.Athlete()
  athlete.setProgramId(programId)
  athlete.setLocationId(locationId)
  if (validateTeamId && validateTeamId > 0) athlete.setValidateTeamId(validateTeamId)
  athlete.setId(athleteId)

  const athleteGuardian = new themis_api.GuardianAthleteRequest()
  athleteGuardian.setAthlete(athlete)
  athleteGuardian.setGuardian(guardian)

  const res: themis_api.GuardianResponse = await client.addGuardian(athleteGuardian, {})
  const guardianResponse = res.toObject()
  const guardians = guardianResponse.guardiansList
  dispatch(setGuardians(guardians))
}

async function updateGuardian(dispatch: AnyDispatchType, guardianId: number, guardianPerson: themis_common.Person.AsObject, programId: number, locationId: number, athleteId: number, validateTeamId: number): Promise<void> {

  const guardian = buildGuardian(guardianId, programId, locationId, guardianPerson)
  const athlete = new themis_common.Athlete()
  athlete.setProgramId(programId)
  athlete.setLocationId(locationId)
  if (validateTeamId && validateTeamId > 0) athlete.setValidateTeamId(validateTeamId)
  athlete.setId(athleteId)

  const athleteGuardian = new themis_api.GuardianAthleteRequest()
  athleteGuardian.setAthlete(athlete)
  athleteGuardian.setGuardian(guardian)

  const res: themis_api.GuardianResponse = await client.updateGuardian(athleteGuardian, {})
  const guardianResponse = res.toObject()
  const guardians = guardianResponse.guardiansList
  dispatch(setGuardians(guardians))
}

async function addGuardianToAthlete(dispatch: AnyDispatchType, guardianId: number, athleteId: number, programId: number, locationId: number, validateTeamId: number): Promise<void> {
  const guardian = new themis_common.Guardian()
  guardian.setId(guardianId)
  const athlete = new themis_common.Athlete()
  athlete.setProgramId(programId)
  athlete.setLocationId(locationId)
  if (validateTeamId && validateTeamId > 0) athlete.setValidateTeamId(validateTeamId)
  athlete.setId(athleteId)

  const athleteGuardian = new themis_api.GuardianAthleteRequest()
  athleteGuardian.setAthlete(athlete)
  athleteGuardian.setGuardian(guardian)

  const res: themis_api.GuardianResponse = await client.addGuardianToAthlete(athleteGuardian, {})
  const guardianResponse = res.toObject()
  const guardians = guardianResponse.guardiansList
  dispatch(setGuardians(guardians))
}

async function removeGuardianFromAthlete(dispatch: AnyDispatchType, guardianId: number, athleteId: number, programId: number, locationId: number, validateTeamId: number): Promise<void> {
  const guardian = new themis_common.Guardian()
  guardian.setId(guardianId)
  const athlete = new themis_common.Athlete()
  athlete.setProgramId(programId)
  athlete.setLocationId(locationId)
  if (validateTeamId && validateTeamId > 0) athlete.setValidateTeamId(validateTeamId)
  athlete.setId(athleteId)

  const athleteGuardian = new themis_api.GuardianAthleteRequest()
  athleteGuardian.setAthlete(athlete)
  athleteGuardian.setGuardian(guardian)

  await client.removeGuardianFromAthlete(athleteGuardian, {})
}

export {
  getGuardian,
  addGuardian,
  updateGuardian,
  addGuardianToAthlete,
  removeGuardianFromAthlete,
}

