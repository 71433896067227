import React, { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import {
  Container,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import { useHistory, useParams } from "react-router-dom"
import { IAppState } from "store/store"
import {
  selectAthlete,
  selectCurrentLocation,
  selectCurrentProgram, selectLocationGuardians,
} from "store/program/programSelectors"
import { emptyPerson } from "store/program/programReducer"
import AddEditPerson from "components/program/person/AddEditPerson"
import themis_common from "store/themis_common_pb"
import { FormFields } from "lib/constants"
import { addGuardian, addGuardianToAthlete, getGuardian, updateGuardian } from "store/program/guardianActions"
import { Autocomplete } from "@material-ui/lab"
import YesNoDialog from "components/util/YesNoModal"
import { capitalizeFirstLetter, fieldsOkay } from "lib/validators"
import SubmitButton from 'components/util/SubmitButton'
import { refreshUser } from "store/user/userActions"

const AddEditGuardian: React.FC = (): ReactElement => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { athleteId: inputAthleteId, teamId: inputTeamId, locationId: inputLocationId, guardianId: inputGuardianId } = useParams<{guardianId?: string | undefined, athleteId?: string | undefined, teamId?: string | undefined, locationId?: string | undefined}>()
  const athleteId = inputAthleteId ? Number(inputAthleteId) : 0
  const teamId = inputTeamId ? Number(inputTeamId) : 0
  const locationId = inputLocationId ? Number(inputLocationId) : 0
  const guardianId = inputGuardianId ? Number(inputGuardianId) : 0

  const athlete: themis_common.Athlete.AsObject | undefined = useSelector((state: IAppState) => selectAthlete(state, athleteId))
  const program: themis_common.Program.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProgram(state))
  const location: themis_common.Location.AsObject | undefined = useSelector((state: IAppState) => selectCurrentLocation(state))
  const guardians: themis_common.Guardian.AsObject[] = useSelector((state: IAppState) => selectLocationGuardians(state))

  const programId = useMemo(() => program?.id || 0,[program])

  const locationCountry: string | undefined = useMemo(() => guardianId ? undefined : location?.country, [location?.country, guardianId])
  const locationState: string | undefined = useMemo(() => guardianId ? undefined : location?.state, [location?.state, guardianId])

  const guardianPersonFields: FormFields = {
    legalFirstName: { label: "First Name", required: true, phone: false, email: false },
    legalMiddleName: { label: "Middle Name", required: false, phone: false, email: false },
    legalLastName: { label: "Last Name", required: true, phone: false, email: false },
    suffix: { label: "Suffix (Jr, Sr, etc.)", required: false, phone: false, email: false },
    nickName: { label: "Nick Name", required: false, phone: false, email: false },
    title: { label: "Title", required: false, phone: false, email: false },
    cellPhone: { label: "Cell Phone", required: true, phone: true, email: false },
    homePhone: { label: "Home Phone", required: false, phone: true, email: false },
    email: { label: "Email", required: true, phone: false, email: true },
    birthDate: { label: "Birth Date", required: false, phone: false, email: false },
    gender: { label: "Gender", required: false, phone: false, email: false },
    address1: { label: "Address", required: true, phone: false, email: false },
    address2: { label: "Address 2", required: false, phone: false, email: false },
    city: { label: "City", required: true, phone: false, email: false },
    state: { label: "State or Province", required: true, phone: false, email: false },
    postalCode: { label: "Postal Code", required: true, phone: false, email: false },
    country: { label: "Country", required: true, phone: false, email: false },
  }


  useEffect(() => {
    load()
    async function load() {

      if (!programId || !locationId || !athleteId) return
      if (guardianId) {
        const guardian = await getGuardian(dispatch, guardianId, athleteId, programId, locationId, teamId)
        if (!guardian?.person) return
        setGuardian(guardian?.person)
      } else {
        setGuardian({ ...emptyPerson, country: locationCountry || "US", state: locationState || "AL" })
      }
    }
  }, [programId, locationId, athleteId, guardianId, locationCountry, locationState, dispatch])

  const setGuardianFromList = async (guardian: themis_common.Guardian.AsObject) => {
    await addGuardianToAthlete(dispatch, guardian.id, athleteId, programId, locationId, teamId)
    const athleteLink = teamId ? `/Location/${locationId}/Team/${teamId}/Athlete/${athleteId}` : `/Location/${locationId}/Athlete/${athleteId}`
    history.replace(athleteLink)
  }

  const [guardian, setGuardian] = useState<themis_common.Person.AsObject>(emptyPerson)
  const [checkGuardian, setCheckGuardian] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const create = !guardianId ? 'Add' : 'Edit'
  const creating = !guardianId ? 'adding' : 'editing'
  const creatingBool = !guardianId

  const guardianFormReady = useMemo(() => fieldsOkay(guardian, guardianPersonFields), [guardian])

  const submitForm = async (event: FormEvent) => {
    setCheckGuardian(true)
    event.preventDefault()
    if (!programId || !locationId || !athleteId || !guardianFormReady) return

    const dest = teamId ? `/Location/${locationId}/Team/${teamId}/Athlete/${athleteId}` : `/Location/${locationId}/Athlete/${athleteId}`
    if (guardianId) {
      // Editing Guardian
      try {
        await updateGuardian(dispatch, guardianId, guardian, programId, locationId, athleteId, teamId)
        history.replace(dest)
      } catch (e) {
        setDialogOpen(true)
        console.error(e)
      }
    } else {
      // Creating Guardian
      try {
        await addGuardian(dispatch, guardian, programId, locationId, athleteId, teamId)
        await refreshUser(dispatch)
        history.replace(dest)
      } catch (e) {
        setDialogOpen(true)
        console.error(e)
      }
    }
  }

  const headerContents = () => {

    if (guardianId && guardian?.name) {
      return `${create} Guardian - ${guardian.name}`
    } else if (athlete) {
      return `${create} Guardian for ${athlete.name}`
    }
    else {
      return `${create} Guardian`
    }
  }

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{headerContents()}</Typography>
        </Grid>

        <Grid item xs={1}/>
        <Grid item xs={10}>
          {guardianId ? <></> :
            <>
              <div className={classes.paper}>
                <Typography variant="h2">Select Existing Guardian</Typography>
                <Autocomplete
                  id="pick-guardian"
                  options={guardians || []}
                  getOptionLabel={(option) => `${option.name} - ${option.person?.address1}`}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(e, v) => {
                    v && setGuardianFromList(v)
                  }}
                  style={{ width: 450 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      autoComplete="none"
                      label="Select Guardian"
                    />
                  )}
                />
              </div>
            </>
          }
          <div className={classes.paper}>
            <Typography variant="h2">{create} Guardian</Typography>
            <AddEditPerson person={guardian}
              formId={1}
              updatePerson={setGuardian}
              formReady={guardianFormReady}
              formFields={guardianPersonFields}
              hiddenFields={{ title: true, birthDate: true, gender: true, nickName: true }}
              locationCountry={locationCountry}
              locationState={locationState}
              readonly={false}
              checkPerson={checkGuardian}
              creating={creatingBool}
              setCheckPerson={setCheckGuardian}
            />
            <Grid container item xs={12}>
              <SubmitButton title={`${create} Guardian`} ariaLabel="submit-guardian-form" submitForm={submitForm} />
            </Grid>
          </div>
        </Grid>
        <YesNoDialog
          title={`Error ${capitalizeFirstLetter(creating)} Guardian`}
          question={`There was an error ${creating} your guardian. Please contact us if this continues to be a problem.`}
          isOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => setDialogOpen(false) },
          ]}
        />
      </Grid>
    </Container>
  )
}

export default AddEditGuardian
