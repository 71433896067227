import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Box, Container, Grid, IconButton, Typography, Tooltip } from "@material-ui/core"
import { Add as AddIcon } from '@material-ui/icons'
import { useStyles } from "lib/theme"
import PaymentSourcesList from "./PaymentSourcesList"

const ManagePaymentSources: React.FC = (): React.ReactElement => {
  const classes = useStyles()

  return <>
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="left" alignItems="center">
            <Typography variant="h1" style={{ marginRight: "10px", marginBottom: "20px" }}>Manage Payment Methods</Typography>
            <Tooltip title="Add Payment Method" aria-label="add-payment-method" placement="right">
              <IconButton size="small" className={classes.clickable} aria-label="add-payment-method-button" component={RouterLink} to={`/AddPaymentMethod`}>
                <AddIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <Container maxWidth="lg">
        <PaymentSourcesList useCase="manage-payment-source" />
      </Container>
    </Container>
  </>
}

export default ManagePaymentSources
