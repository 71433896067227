import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import themis_api from 'store/themis_api_pb'
import themis_common from 'store/themis_common_pb'
import { getRubric, getTeamReportData } from "../../../store/user/userActions"
import { Container, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { convertToNumber } from 'lib/functions'

interface point {
  id: number
  score: string
  count?: number
  comments?: string[]
}

interface rubricArea {
  id: number
  points: point[]
}

interface score {
  judgeId: number
  rubricAreas: rubricArea[]

}

interface teamData {
  team: themis_common.EventTeam.AsObject
  judgeScores: score[]
  finalScore: score
}


const TeamReport: React.FC = (): ReactElement => {

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      team:{
        display: "flex",
      },
      [`@media print`]: {
        team:{ pageBreakAfter: "always", fontSize: 10 },
      }
    }))

  const classes = useStyles()

  const { eventDivisionShiftId: inputEventDivisionShiftId } = useParams<{ eventDivisionShiftId?: string }>()
  const eventDivisionShiftId = Number(inputEventDivisionShiftId)

  const [data, setData] = useState<themis_api.TeamReport.AsObject>()
  const [rubric, setRubric] = useState<themis_common.Rubric.AsObject>()
  const [doneData, setDoneData] = useState<teamData[]>()

  useEffect(() => {
    const getReportData = async () => {
      const data = await getTeamReportData(eventDivisionShiftId)
      setData(data)
      const rubricId = data.teamsList[0].eventTeam?.eventDivision?.rubricId || 0
      const theRubric = await getRubric(rubricId)
      setRubric(theRubric)
    }
    getReportData()
  }, [eventDivisionShiftId])

  useEffect(() => {
    if (!data) return
    const fixedData: teamData[] = data.teamsList.filter(team => !!team?.eventTeam && team?.eventDivisionShiftTeamRubricAreasList.length > 0).map(team => {
      // @ts-ignore
      const theTeam: themis_common.EventTeam.AsObject = team.eventTeam

      // Sort the scores out into a per judge set
      const scores: score[] = []
      const theFinalScore: score = {
        judgeId: 0,
        rubricAreas: []
      }
      team.eventDivisionShiftTeamRubricAreasList.forEach(area => {
        area.eventDivisionShiftTeamRubricAreaScoresList.forEach(score => {
          const judgeId = score.judge?.id || 0
          let theScore = scores.find(score => score.judgeId === judgeId)
          if (!theScore) {
            theScore = {
              judgeId: judgeId,
              rubricAreas: []
            }
            scores.push(theScore)
          }
          let theArea = theScore.rubricAreas.find(theArea => theArea.id === area.rubricArea?.id)
          if (!theArea) {
            theArea = {
              id: area?.rubricArea?.id || 0,
              points: []
            }
            theScore.rubricAreas.push(theArea)
          }
          let theFinalArea = theFinalScore.rubricAreas.find(theArea => theArea.id === area.rubricArea?.id)
          if (!theFinalArea) {
            theFinalArea = theArea
            theFinalScore.rubricAreas.push(theFinalArea)
          }
          const thePoint: point = {
            id: score.rubricAreaItemId,
            score: score.score,
            count: 1,
            comments: score.comment ? [score.comment] : []
          }
          theArea.points.push(thePoint)
          const theFinalPoint = theFinalArea.points.find(aPoint => aPoint.id === thePoint.id)
          if (theFinalPoint) {
            theFinalPoint.score += score.score
            if (theFinalPoint.count) {
              theFinalPoint.count++
            } else {
              theFinalPoint.count = 1
            }
          }
        })
      })

      const data: teamData = {
        team: theTeam,
        judgeScores: scores,
        finalScore: theFinalScore
      }
      return data
    })


    setDoneData(fixedData)
  }, [data])


  const teamReport = useMemo(() => {
    if (!doneData || !rubric) {
      return (<Typography variant="h2">Loading Data...</Typography>)
    } else {
      return (<Container>{doneData.map(team => {
        let finalTotal = 0
        return (<Grid key={team.team.teamId} container className={classes.team}>
          <Grid item xs={12}>
            <Typography style={{ width: "100%", textAlign: "center", fontSize: 20 }}>{team.team.eventDivision?.event?.name } - {team.team.eventDivision?.name ? team.team.eventDivision?.name : team.team.eventDivision?.division?.name}</Typography>
            <Typography style={{ width: "100%", textAlign: "center", fontSize: 20 }}>Team Report: {team.team.program?.name} - {team.team.name}</Typography>
          </Grid>
          {team.judgeScores.map((jScore) => {
            let grandTotal = 0
            return (
              <Grid key={jScore.judgeId} item xs={6} style={{ marginTop: 18 }}>
                <Typography style={{ width: "100%", textAlign: "center", fontSize: 16 }}>Judge: {jScore.judgeId}</Typography>
                {jScore.rubricAreas.map(rubricArea => {
                  const foundRa = rubric?.rubricAreasList.find(rubArea => rubArea.id === rubricArea.id)
                  if (foundRa) {
                    const ra: themis_common.RubricArea.AsObject = foundRa
                    return (
                      <Grid key={rubricArea.id} container item xs={12} style={{ marginTop: 8 }}>
                        <Grid item xs={12}><Typography style={{ width: "100%", fontSize: 16 }}>{ra?.name ? ra.name : "XXX"}</Typography></Grid>
                        {rubricArea.points.map(point => {
                          const type = ra.rubricAreaItemsList.find(type => type.id === point.id)
                          grandTotal += point?.count ? Math.round(convertToNumber(point.score) / point.count * 10) / 10 : 0
                          return (<Grid container key={point.id}>
                            <Grid item xs={6}>{type?.label}</Grid>
                            <Grid item xs={1}>{point?.count ? Math.round(convertToNumber(point.score) / point.count * 10) / 10 : 0}</Grid>
                            <Grid item xs={5} style={{ textAlign: "left" }}>{ point?.comments ? point.comments.join(",") : ""}</Grid>
                          </Grid>)
                        })}
                      </Grid>)
                  } else {
                    return []
                  }
                })}
                <Grid key={jScore.judgeId} container item xs={12} style={{ marginTop: 16 }}>
                  <Grid item xs={6}>Judge {jScore.judgeId} Total</Grid>
                  <Grid item xs={2}>{Math.round(grandTotal*10) /10 }</Grid>
                </Grid>
              </Grid>
            )
          })}
          <Grid key={team.team.id} item container xs={12}>
            <Grid item xs={3}></Grid>
            <Grid  container item xs={6} spacing={2} style={{ marginTop: 16 }}>
              <Typography style={{ width: "100%", textAlign: "center", fontSize: 18 }}>Official Score</Typography>
              {team.finalScore.rubricAreas.map(rubricArea => {
                const foundRa = rubric?.rubricAreasList.find(rubArea => rubArea.id === rubricArea.id)
                if (foundRa) {
                  const ra: themis_common.RubricArea.AsObject = foundRa
                  return (
                    <Grid key={rubricArea.id} container item xs={12} style={{ marginTop: 8 }}>
                      <Grid item xs={12}><Typography style={{ width: "100%", fontSize: 16 }}>{ra?.name ? ra.name : "XXX"}</Typography></Grid>
                      {rubricArea.points.map(point => {
                        const type = ra.rubricAreaItemsList.find(type => type.id === point.id)
                        finalTotal += point?.count ? Math.round(convertToNumber(point.score) / point.count * 10) / 10 : 0
                        return (<Grid container key={point.id}>
                          <Grid item xs={9}>{type?.label}</Grid>
                          <Grid item xs={3}>{point?.count ? Math.round(convertToNumber(point.score) / point.count * 10) / 10 : 0}</Grid>
                        </Grid>)
                      })}
                    </Grid>)
                } else {
                  return []
                }
              })}
              <Grid key={team.team.teamId} container item xs={12} style={{ marginTop: 16 }}>
                <Grid item xs={9}>Total</Grid>
                <Grid item xs={3}>{Math.round(finalTotal * 10) / 10}</Grid>
              </Grid>


            </Grid>
          </Grid>
        </Grid>)
      })}
      </Container>)
    }
  }, [doneData, rubric])

  return teamReport
}

export default TeamReport
