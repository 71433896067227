import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  AppBar, Button, Checkbox, Container,
  Grid, Toolbar, Typography, Hidden, Select, MenuItem, useTheme
} from "@material-ui/core"
import HeaderLogo from "../header/HeaderLogo"
import draftToHtml from 'draftjs-to-html'
import { useStyles } from "../../lib/theme"
import themis_common from 'store/themis_common_pb'
import { ArrowRight as IconArrowRight } from '@material-ui/icons'
import { format } from "date-fns"


interface SignDocumentProps {
  signInfo: themis_common.DocumentSignatureGuardian.AsObject
  signatures: themis_common.DocumentSignature.AsObject[]
  saveElectSig: Function
  agreeElectSigSaved: boolean
  replaceText: Function
  signDocument: Function
  refreshDocuments: boolean
  currentDocument: themis_common.EventDocument.AsObject | undefined
  setCurrentDocument: Function
  currentSignature: themis_common.DocumentSignature.AsObject | undefined
  setNextToCurrent: Function
  unsignedCount: number
  setShowERSD: Function
}

const SignDocument: React.FC<SignDocumentProps> = ({ signInfo, signatures, saveElectSig, agreeElectSigSaved, replaceText, signDocument, refreshDocuments, currentDocument, setCurrentDocument, currentSignature, setNextToCurrent, unsignedCount, setShowERSD }): ReactElement => {
  const classes = useStyles()
  const theme = useTheme()

  const [agreeElectSig, setAgreeElectSig] = useState(signInfo.agreeElectroncRecordsSignatures || false)
  const [currentText, setCurrentText] = useState("")

  useEffect(() => {
    signInfo.documentSignature?.event?.eventDocumentsList.sort((a, b) => {
      if (a.status > b.status) return -1
      if (a.status < b.status) return 1
      if (a.name > b.name) return 1
      return -1
    })
    setCurrentDocument(signInfo.documentSignature?.event?.eventDocumentsList[0])
  }, [signInfo.documentSignature?.event?.eventDocumentsList, refreshDocuments])

  useEffect(() => {
    if (currentDocument?.text) setCurrentText(replaceText(draftToHtml(JSON.parse(currentDocument.text))))
  }, [currentDocument?.text, replaceText])

  const selectDrop = useMemo(() => {
    return <Select
      labelId="select-documents"
      id="select-documents"
      fullWidth
      value={currentDocument?.id}
      onChange={(e) => {
        setCurrentDocument(signInfo.documentSignature?.event?.eventDocumentsList.find(document => document.id === e.target.value))
      }}
    >
      { signInfo.documentSignature?.event?.eventDocumentsList.filter((document) => document.status ).map((document) => <MenuItem key={`document-${document.id}`} value={document.id}>{document.name}</MenuItem>) }
    </Select> }, [currentDocument?.id, setCurrentDocument, signInfo.documentSignature?.event?.eventDocumentsList])


  return (
    <Container style={{ paddingBottom: 75 }} >
      <AppBar color="transparent" position="sticky" elevation={0} className={classes.headerRoot} style={{ marginBottom: 35, paddingBottom: 0 }}>
        <Grid container>
          <HeaderLogo userState="program" currentFocus={undefined} currentBrand={signInfo.documentSignature?.event?.brand} currentEventRegistrationLogo={signInfo.documentSignature?.event?.logo} />
          <Grid item sm={8} xs={12}>
            <Typography variant="h1" style={{ paddingTop: 5, textAlign: "center" }}>{signInfo.documentSignature?.event?.name}</Typography>
            <Typography variant="h2" style={{ paddingTop: 5, textAlign: "center" }}>Event Participation Forms for {signInfo.documentSignature?.athlete?.name}</Typography>
          </Grid>
        </Grid>
        <Hidden mdUp>
          <Typography variant="h2">Available Forms</Typography>
          {selectDrop}
        </Hidden>

      </AppBar>
      <Grid container spacing={4}>
        <Grid container item md={3} xs={12} alignContent="flex-start">
          <Hidden smDown>
            <Typography variant="h2">Available Forms</Typography>
            { signInfo.documentSignature?.event?.eventDocumentsList.filter((document) => document.status ).map((document) => {
              const sig = signatures.find(sig => sig.eventDocumentId === document.id)
              return <Grid item xs={12} key={`documentButton-${document.id}`}>
                <Button variant={currentDocument?.id === document.id ? "contained" : "outlined" } fullWidth
                  color="secondary"
                  style={{ marginBottom: 20, color: sig?.status ? "#AAA" : currentDocument?.id === document.id ? "#EEE" : "#333" }}
                  endIcon = {currentDocument?.id === document.id ? <IconArrowRight/> : []}
                  onClick={() => {
                    setCurrentDocument(document)
                  }}> {document.name}</Button>
              </Grid>
            })}
          </Hidden>
        </Grid>
        <Grid container item md={9} xs={12}>
          <Grid style={{ position: "relative" }} item xs={12}>
            { !agreeElectSigSaved ?
              <AppBar style={{ position: "absolute" }}>
                <Toolbar style={{ paddingTop: 10, paddingBottom: 10 }} >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: "12px", lineHeight: "13px", marginTop: "5px" }}>
                        Please read the <a style={{ textDecoration: "underline" }} onClick={() => {setShowERSD(true)}} target="_blank" >The Electronic Record and Signature Disclosre.</a>
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Checkbox checked={agreeElectSig} onClick={(_) => { setAgreeElectSig(current => !current ) }} />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography style={{ fontSize: "12px", lineHeight: "13px", marginTop: "5px" }}>I agree to use electronic records and signatures.</Typography>
                    </Grid>
                    <Grid item xs={5} container justify="center" >
                      <Button onClick={ (_) => { saveElectSig(agreeElectSig) } } disabled={!agreeElectSig} variant="outlined" style={{ borderColor: "white", color: "white" }} >Continue</Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              :
              <Grid container item xs={12}>
                <Hidden smDown>
                  <Typography variant="h2">{currentDocument?.name}</Typography>
                </Hidden>
                <div dangerouslySetInnerHTML={{ __html: currentText }} />
              </Grid>
            }
          </Grid>
          <Hidden smDown>
            { agreeElectSigSaved ?
              <Grid container>
                <Grid item xs={4} sm={2} style={{ textAlign: "right" }} >Sign: </Grid>
                <Grid item xs={8} sm={4}>
                  <div style={{ fontFamily: "handlee", borderBottom: "1px solid black", width: "100%", color: currentSignature?.status ? theme.palette.text.primary : theme.palette.text.disabled }}>
                    &nbsp;{ currentSignature?.status ? currentSignature.signature : signInfo.guardian?.signature }
                  </div>
                </Grid>
                <Grid item xs={4} sm={2} style={{ textAlign: "right" }} >
                  Date:
                </Grid>
                <Grid item xs={4} sm={2}>
                  <div style={{ fontFamily: "handlee", borderBottom: "1px solid black", width: "100%", color: currentSignature?.status ? theme.palette.text.primary : theme.palette.text.disabled }}>
                    &nbsp;{ currentSignature?.status ? `${currentSignature.signTime?.monthNumber}/${currentSignature.signTime?.dayNumber}/${currentSignature.signTime?.year}` : format(new Date(), 'MM/dd/yyyy') }
                  </div>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={8}>
                    <Button fullWidth size="small" variant="contained" color="primary" disabled={currentSignature?.status} style={{ backgroundColor: "#fcc04f", color: !currentSignature?.status ? "#333" : "#AAA", marginTop: 10 }} onClick={ () => { if (currentDocument) signDocument(currentDocument.id) }} >{ !currentSignature?.status ? "Click to Sign" : "Already Signed" }</Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button fullWidth size="small" variant="outlined" color="secondary" style={{ marginTop: 10 }} disabled={ unsignedCount === 0 } onClick={ () => { setNextToCurrent() }} >Next ({ unsignedCount } Remaining)</Button>
                  </Grid>
                </Grid>
              </Grid>
              : [] }
          </Hidden>
        </Grid>
      </Grid>
      <Hidden mdUp>
        { agreeElectSigSaved ?
          <AppBar position="fixed" style={{ bottom: 0, top: "auto" }} color="default">
            <Grid container>
              <Grid item xs={4} sm={2}>Sign</Grid>
              <Grid item xs={8} sm={4}>
                <div style={{ fontFamily: "handlee", borderBottom: "1px solid black", width: "100%", color: currentSignature?.status ? '#000' : '#DDD' }}>
                  { currentSignature?.status ? currentSignature.signature : signInfo.guardian?.signature }
                </div>
              </Grid>
              <Grid item xs={4} sm={2}>
                Date
              </Grid>
              <Grid item xs={4} sm={2}>
                <div style={{ fontFamily: "handlee", borderBottom: "1px solid black", width: "100%", color: currentSignature?.status ? '#000' : '#DDD' }}>
                  { currentSignature?.status ? `${currentSignature.signTime?.monthNumber}/${currentSignature.signTime?.dayNumber}/${currentSignature.signTime?.year}` : format(new Date(), 'MM/dd/yyyy') }
                </div>
              </Grid>
              <Grid item xs={8}>
                <Button fullWidth size="small" variant="contained" style={{ backgroundColor: "#fcc04f", color: !currentSignature?.status ? "#333" : "#AAA", marginTop: 10 }} disabled={currentSignature?.status} onClick={ () => { if (currentDocument) signDocument(currentDocument.id) }} >Sign</Button>
              </Grid>
              <Grid item xs={4}>
                <Button fullWidth size="small" variant="contained" color="secondary" style={{ marginTop: 10 }} disabled={ unsignedCount === 0 } onClick={ () => { setNextToCurrent() }} >Next ({ unsignedCount })</Button>
              </Grid>
            </Grid>
          </AppBar>
          : [] }
      </Hidden>
    </Container>
  )
}

export default SignDocument
