import React, { ReactElement, useCallback, useMemo, useState } from "react"
import { Container, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Box, Button, Grid, Paper, Stepper, Step, StepLabel, Typography } from "@material-ui/core"
import { useStripe } from "@stripe/react-stripe-js"
import { StripeCardElement } from "@stripe/stripe-js"
import { Link as RouterLink, useHistory } from "react-router-dom"
import CreditCardForm from "./CreditCardForm"
import PaymentSourcesList from "./PaymentSourcesList"
import themis_common from "store/themis_common_pb"
import BankAccountDetails from "./BankAccountDetails"
import CreditCardDetails from "./CreditCardDetails"
import { addCommas } from "lib/functions"
import YesNoDialog from "components/util/YesNoModal"

interface ProcessPaymentContainerProps {
  paymentAmount: number
  paymentCurrency: string
  successReturn: () => Promise<void> // Button after success
  createPayment: (paymentType: themis_common.PaymentTypeMap[keyof themis_common.PaymentTypeMap], paymentSource: string) => Promise<[boolean, string]>
  chargePayment: (paymentType: themis_common.PaymentTypeMap[keyof themis_common.PaymentTypeMap], paymentSource: string) => Promise<boolean>
  showFinished: boolean
}

const ProcessPaymentContainer: React.FC<ProcessPaymentContainerProps> = ({ paymentAmount, paymentCurrency, successReturn, createPayment, chargePayment, children , showFinished }): ReactElement => {
  const stripe = useStripe()
  const history = useHistory()

  const [paymentAdded, setPaymentAdded] = useState(false)
  const [processingPayment, setProcessingPayment] = useState(false)
  const [paymentSucceeded, setPaymentSucceeded] = useState(false)
  const [paymentMethodType, setPaymentMethodType] = useState<"new-credit-card" | "existing-source" | "">("")
  const [stripeSourceType, setStripeSourceType] = useState<"bank-account" | "credit-card" | undefined>()
  const [stripeSourceId, setStripeSourceId] = useState("")
  const [stripeSourceDetails, setStripeSourceDetails] = useState<themis_common.StripeBankAccount.AsObject | themis_common.StripeCreditCard.AsObject | undefined>()
  const [clientSecret, setClientSecret] = useState("")
  const [isErrorOpen, setIsErrorOpen] = useState(false)
  const defaultErrorMessage = `There was an error processing your payment. Please contact us if this continues to be a problem.`
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage)

  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  const handleChangePaymentMethodType = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setPaymentMethodType(value as "new-credit-card" | "existing-source")
    setStripeSourceType(undefined)
    setStripeSourceId("")
  }

  function getSteps() {
    return ['Select Payment Method', 'Review Items', 'Finish Payment']
  }

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        // Select Payment Method
        return selectPaymentMethodSection
      case 1:
        // Review Items
        return (<>
          <Grid item xs={12} container justify="center" alignItems="center">
            <Box p={3}>
              <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
              <Button variant="contained" color="primary" onClick={() => {
                handleNext()
                if (showFinished) {
                  handleNext()
                  handleFinish()
                }
              }}>
                {activeStep === steps.length - 1 || showFinished  ? 'Finish' : 'Continue'}
              </Button>
            </Box>
          </Grid>
          {children}
          <Grid item xs={12} container justify="center" alignItems="center">
            <Box p={3}>
              <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
              <Button variant="contained" color="primary" onClick={() => {
                handleNext()
                if (showFinished) {
                  handleNext()
                  handleFinish()
                }
              }}>
                {activeStep === steps.length - 1 || showFinished ? 'Finish' : 'Continue'}
              </Button>
            </Box>
          </Grid>
        </>)
      case 2:
        // Finish Payment
        return paymentSection
      default:
        return 'Unknown stepIndex'
    }
  }

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }, [])

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }, [])

  const handleFinish = useCallback(async () => {
    successReturn()
  }, [successReturn])

  const handleSelectPaymentSource = useCallback(async () => {
    setClientSecret("")
    // export interface PaymentTypeMap {
    //   CC: 0;
    //   ACH: 1;
    //   GOOGLE_PAY: 2;
    //   APPLE_PAY: 3;
    //   MICROSOFT_PAY: 4;
    // }
    let paymentType: themis_common.PaymentTypeMap[keyof themis_common.PaymentTypeMap] = 0
    let paymentSourceId = ""
    if (paymentMethodType === "existing-source") {
      paymentSourceId = stripeSourceId
      if (stripeSourceType === "bank-account") {
        paymentType = 1
      }
    }
    handleNext()
    const [paymentAddedSuccessfully, clientSecretResponse] = await createPayment(paymentType, paymentSourceId)
    if (paymentAddedSuccessfully) {
      setClientSecret(clientSecretResponse)
      setPaymentAdded(true)
    } else {
      setIsErrorOpen(true)
    }
  }, [createPayment, paymentMethodType, stripeSourceType, stripeSourceId, handleNext])

  const payWithExistingSource = useCallback(async (sourceType: "bank-account" | "credit-card"): Promise<void> => {
    setProcessingPayment(true)
    if (stripe) {
      // export interface PaymentTypeMap {
      //   CC: 0;
      //   ACH: 1;
      //   GOOGLE_PAY: 2;
      //   APPLE_PAY: 3;
      //   MICROSOFT_PAY: 4;
      // }z
      let paymentType: themis_common.PaymentTypeMap[keyof themis_common.PaymentTypeMap] = 0
      if (sourceType === "bank-account") paymentType = 1

      const chargePaymentResponse = await chargePayment(paymentType, stripeSourceId)
      if (chargePaymentResponse) {
        setPaymentSucceeded(true)
      }
    }
    setProcessingPayment(false)
    return
  }, [stripe, stripeSourceId, chargePayment])

  const payWithNewCreditCard = useCallback(async (stripeCardElement: StripeCardElement): Promise<string> => {
    if (stripe && clientSecret) {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: stripeCardElement
        }
      })
      if (payload.error) {
        return `Payment failed ${payload.error.message}`
      }
      setPaymentSucceeded(true)
      return ""
    }
    return "Error creating payment"
  }, [stripe, clientSecret])

  const selectPaymentMethodSection = useMemo(() => {
    return (<>
      <Grid item xs={12}>
        <Paper elevation={2} key={`payment-method-type-selector`}>
          <Box m={1} p={2}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography variant="h2">Payment Method Type</Typography>
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">Payment Method Type</FormLabel> */}
                  <RadioGroup aria-label="payment-method-type" name="payment-method-type" value={paymentMethodType} onChange={handleChangePaymentMethodType}>
                    <FormControlLabel value="existing-source" control={<Radio />} label="Existing Payment Method" />
                    <FormControlLabel value="new-credit-card" control={<Radio />} label="New Credit Card" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button variant="outlined" component={RouterLink} to={'/ManagePaymentMethods'}>Manage Payment Methods</Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} container justify="center" alignItems="center">
        <Box p={3}>
          {/* <Button disabled={activeStep === 0} onClick={handleBack} >Back</Button> */}
          <Button variant="contained" color="primary" onClick={handleSelectPaymentSource} disabled={!(paymentMethodType === "new-credit-card" || (paymentMethodType === "existing-source" && stripeSourceId.length > 0))}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
          </Button>
        </Box>
      </Grid>
      {(paymentMethodType === "existing-source") ? (<>
        <Grid item xs={12}>
          <PaymentSourcesList useCase="select-payment-source" parentSelectedSourceId={stripeSourceId} setParentStripeSourceType={setStripeSourceType} setParentStripeSourceId={setStripeSourceId} setParentSourceDetails={setStripeSourceDetails} />
        </Grid>

        {/* <Grid item xs={12} container justify="center" alignItems="center">
          <Box p={3}>
            <Button variant="contained" color="primary" onClick={handleNext} disabled={!(paymentMethodType === "existing-source" && stripeSourceId.length > 0)}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
            </Button>
          </Box>
        </Grid> */}
      </>) : <></>}
    </>)
  }, [paymentMethodType, activeStep, steps.length, stripeSourceId, handleSelectPaymentSource])

  const newCreditCardPaymentSection = useMemo(() => {
    return (<Box mt={2} textAlign="center" key={`pay-credit-card-form`}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          {(!paymentSucceeded) ? (<>
            <Typography variant="h2">Pay with Credit Card {paymentAmount > 0 ? `- $${addCommas((paymentAmount / 100).toFixed(2))} ${[paymentCurrency]}` : ""}</Typography>
            <CreditCardForm buttonText="Pay Now" successText="Payment Succeeded" handleCreateCardElement={payWithNewCreditCard} />
          </>) : (
            <Typography>Payment Complete</Typography>
          )}
        </Grid>
      </Grid>
    </Box>)
  }, [paymentSucceeded, payWithNewCreditCard, paymentAmount, paymentCurrency])

  const existingSourcePaymentSection = useMemo(() => {
    if (stripeSourceType === "bank-account") {
      return (<Box mt={2} textAlign="center" key={`pay-bank-account-source`}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} lg={8}>
            {(!paymentSucceeded) ? (<>
              <Typography variant="h2">Pay with Bank Account (ACH) {paymentAmount > 0 ? `- $${addCommas((paymentAmount / 100).toFixed(2))} ${[paymentCurrency]}` : ""}</Typography>
              <BankAccountDetails useCase="manage-payment-source" bankAccount={stripeSourceDetails as themis_common.StripeBankAccount.AsObject} />
              <Box mt={3} >
                <Button variant="contained" color="primary" fullWidth onClick={() => payWithExistingSource("bank-account")}>{processingPayment ? <CircularProgress color="secondary" size={20} /> : "Pay Now"}</Button>
              </Box>
            </>) : (
              <Typography>Payment Created. This may take up to 7 days to finish processing.</Typography>
            )}
          </Grid>
        </Grid>
      </Box>)
    }
    if (stripeSourceType === "credit-card") {
      return (<Box mt={2} textAlign="center" key={`pay-credit-card-source`}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} lg={8}>
            {(!paymentSucceeded) ? (<>
              <Typography variant="h2">Pay with Credit Card</Typography>
              <CreditCardDetails useCase="manage-payment-source" creditCard={stripeSourceDetails as themis_common.StripeCreditCard.AsObject} />
              <Box mt={3} >
                <Button variant="contained" color="primary" fullWidth onClick={() => payWithExistingSource("credit-card")}>{processingPayment ? <CircularProgress color="secondary" size={20} /> : "Pay Now"}</Button>
              </Box>
            </>) : (
              <Typography>Payment Complete</Typography>
            )}
          </Grid>
        </Grid>
      </Box>)
    }
    return <></>


    // return (<Box mt={2} textAlign="center" key={`add-credit-card-form`}>
    //   <Grid container justify="center" alignItems="center">
    //     <Grid item xs={12} lg={8}>
    //       {(!paymentSucceeded) ? (<>
    //         <Typography variant="h2">Pay with Credit Card</Typography>
    //         <CreditCardForm buttonText="Pay Now" successText="Payment Succeeded" handleCreateCardElement={payWithCreditCard} />
    //       </>) : (
    //         <Button variant="outlined" onClick={() => successReturn()}>{"Payment Succeeded"}</Button>
    //       )}
    //     </Grid>
    //   </Grid>
    // </Box>)
  }, [stripeSourceType, paymentSucceeded, payWithExistingSource, stripeSourceDetails, processingPayment, paymentAmount, paymentCurrency])

  const paymentSection = useMemo(() => {
    if (!paymentAdded) {
      return <Grid item xs={12} container justify="center" alignItems="center">
        <Box p={3}>
          <Typography variant="h3">Connecting to Payment Processor <CircularProgress color="secondary" size={20} /></Typography>
        </Box>
      </Grid>
    }
    if (paymentMethodType === "existing-source") {
      return (<>
        {existingSourcePaymentSection}
        <Grid item xs={12} container justify="center" alignItems="center">
          <Box p={3}>
            <Button disabled={activeStep === 0} onClick={handleBack} >Back</Button>
            <Button variant="contained" color="primary" onClick={handleFinish} disabled={!paymentSucceeded}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
            </Button>
          </Box>
        </Grid>
      </>)
    }
    if (paymentMethodType === "new-credit-card") {
      return (<>
        {newCreditCardPaymentSection}
        <Grid item xs={12} container justify="center" alignItems="center">
          <Box p={3}>
            <Button disabled={activeStep === 0} onClick={handleBack} >Back</Button>
            <Button variant="contained" color="primary" onClick={handleFinish} disabled={!paymentSucceeded}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
            </Button>
          </Box>
        </Grid>
      </>)
    }
  }, [paymentMethodType, activeStep, steps.length, newCreditCardPaymentSection, existingSourcePaymentSection, handleBack, handleFinish, paymentAdded, paymentSucceeded])

  function handleErrorPopupClose(exitPayment: boolean) {
    setIsErrorOpen(false)
    if (exitPayment) history.goBack()
  }

  return (
    <Container maxWidth="lg">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {getStepContent(activeStep)}
      </div>
      <YesNoDialog
        title={`Error Processing Payment`}
        question={errorMessage}
        isOpen={isErrorOpen}
        // disabled={!formReady}
        onClose={() => handleErrorPopupClose(true)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => handleErrorPopupClose(true) },
        ]}
      />
    </Container>
  )
}

export default ProcessPaymentContainer
