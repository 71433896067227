import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentLocation, selectCurrentProgram } from "store/program/programSelectors"

import { removeAthleteFromLocation, removeCoachFromLocation, removePersonnelFromLocation } from "store/program/locationActions"

import { selectCurrentPermissionLists, selectPermissionCodes, selectSuperUser, selectUserProgramPermissions } from "store/user/userSelectors"
import { deleteLocation } from "store/program/locationActions"
import { refreshUser } from "store/user/userActions"
import Location from './Location'

const LocationContainer: React.FC = (): ReactElement => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const { locationId: locationIdInput } = useParams<{locationId: string}>()
  const locationId = Number(locationIdInput)

  const program = useSelector((state: IAppState) => selectCurrentProgram(state))
  const currentLocation = useSelector((state: IAppState) => selectCurrentLocation(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const permissionLists = useSelector((state: IAppState) => selectCurrentPermissionLists(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const programId = useMemo(() => program?.id || 0, [program])

  const [isLocationOpen, setIsLocationOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const hashValues = useMemo(() => ['teams', 'athletes', 'coaches', 'personnel', 'events', 'users', 'event_balance'], [])

  useEffect(() => {
    const hash = location.hash.toLowerCase().substr(1)
    if (hash === '') {
      history.replace('#' + hashValues[0])
    }
    const tab = hashValues.indexOf(location.hash.toLowerCase().substr(1))
    if (tab >= 0) setCurrentTab(tab)
  }, [location, hashValues, history])

  const handleChangeTab = (event: React.ChangeEvent<any>|null, newValue: number) => {
    if (hashValues[newValue]) history.push('#' + hashValues[newValue])
    setCurrentTab(newValue)
  }

  const handleDeleteLocation = async () => {
    setIsLocationOpen(true)
    await deleteLocation(dispatch, locationId, Number(program?.id))
    await refreshUser(dispatch)
    history.push(`/program/${program?.id}`)
  }

  const handleDeleteAthleteFromLocation = useCallback((athleteId: number) => {
    removeAthleteFromLocation(dispatch, locationId, programId, athleteId)
  }, [dispatch, locationId, programId])

  const handleDeleteCoachFromLocation = useCallback((coachId: number) => {
    removeCoachFromLocation(dispatch, locationId, programId, coachId)
  }, [dispatch, locationId, programId])

  const handleDeletePersonnelFromLocation = useCallback((personnelId: number) => {
    removePersonnelFromLocation(dispatch, locationId, programId, personnelId)
  }, [dispatch, locationId, programId])

  return ( permissionLists && program && currentLocation && permissionCodes?.denyAccess.length ?
    <Location
      superUser={superUser}
      programPermissions={programPermissions}
      permissionLists={permissionLists}
      program={program}
      locationId={locationId}
      currentLocation={currentLocation}
      currentTab={currentTab}
      handleChangeTab={handleChangeTab}
      handleDeleteLocation={handleDeleteLocation}
      isLocationOpen={isLocationOpen}
      setIsLocationOpen={setIsLocationOpen}
      handleDeleteAthleteFromLocation={handleDeleteAthleteFromLocation}
      handleDeleteCoachFromLocation={handleDeleteCoachFromLocation}
      handleDeletePersonnelFromLocation={handleDeletePersonnelFromLocation}
      permissionCodes={permissionCodes}
    /> : <></>
  )
}

export default LocationContainer
