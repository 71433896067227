import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core'
import { useHistory, useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentLocation, selectCurrentProgram, selectCurrentTeam } from "store/program/programSelectors"
import { AddCsv } from "components/util/AddCsv"
import { csvUploadContext, csvUploadDefault } from "components/util/CsvUploadContext"
import themis_common from "store/themis_common_pb"
import { client } from "store/themisClient"
import MUIDataTable from "mui-datatables"
import { Alert } from '@material-ui/lab'
import { differenceInCalendarYears, format, parse, parseISO } from "date-fns"
import YesNoModal from "../../util/YesNoModal"
import YesNoDialog from "../../util/YesNoModal"
import { isValidEmail } from "../../../lib/validators"
import { submitAthletes } from "../../../store/program/athleteActions"

const AddAthleteList: React.FC = (): ReactElement => {
  const [csvState, setCsvState] = useState(csvUploadDefault)
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { athleteId: inputAthleteId, teamId: inputTeamId, locationId: inLocationId } = useParams<{ athleteId?: string | undefined, teamId?: string | undefined, locationId?: string | undefined }>()
  const athleteId = Number(inputAthleteId)
  const teamId = Number(inputTeamId)
  const team = useSelector((state: IAppState) => selectCurrentTeam(state))
  const locationId = Number(inLocationId)

  const program: themis_common.Program.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProgram(state))
  const location: themis_common.Location.AsObject | undefined = useSelector((state: IAppState) => selectCurrentLocation(state))

  const programId = useMemo(() => program?.id, [program])
  const [columns, setColumns] = useState<any>([])
  const [rows, setRows] = useState<any>([])

  const [athletes, setAthletes] = useState<any>([])
  const [athletesProto, setAthletesProto] = useState<any>([])
  const [responseItems, setResponseItems] = useState<any>([])
  const [destinationTargets, setDestinationTargets] = useState<any>({})
  const [hasHeader, setHasHeader] = useState<boolean>(true)
  const [hasMinimal, setHasMinimal] = useState<boolean>(false)
  const [askHasMinimal, setAskHasMinimal] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [dateFormat, setDateFormat] = useState<number>(1)
  const [athleteErrors, setAthleteErrors] = useState<any[]>([])
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [errorIndex, setErrorIndex] = useState<number[]>([])

  const athleteColumns: any = [
    { name: "legalFirstName", label: "First Name" },
    { name: "legalMiddleName", label: "Middle Name" },
    { name: "legalLastName", label: "Last Name" },
    { name: "email", label: "Email" },
    { name: "gender", label: "Gender" },
    { name: "address1", label: "Address 1" },
    { name: "address2", label: "Address 2" },
    { name: "city", label: "City" },
    { name: "country", label: "Country" },
    { name: "postalCode", label: "Postal Code" },
    { name: "cellPhone", label: "Cell Phone Number" },
    { name: "state", label: "State" },
    { name: "birthDate", label: "Birth Date" },
    { name: "guardianFirstName", label: "Guardian First Name" },
    { name: "guardianMiddleName", label: "Guardian Middle Name" },
    { name: "guardianLastName", label: "Guardian Last Name" },
    { name: "guardianAddress1", label: "Guardian Address 1" },
    { name: "guardianAddress2", label: "Guardian Address 2" },
    { name: "guardianCity", label: "Guardian City" },
    { name: "guardianState", label: "Guardian State" },
    { name: "guardianCountry", label: "Guardian Country" },
    { name: "guardianPostalCode", label: "Guardian Postal Code" },
    { name: "guardianEmail", label: "Guardian Email" },
    { name: "guardianCellPhoneNumber", label: "Guardian Cell Phone Number" },
  ]

  const transformTargets: any = useMemo(() => [
    { name: "LegalFirstName", description: "First Name" },
    { name: "LegalMiddleName", description: "Middle Name" },
    { name: "LegalLastName", description: "Last Name" },
    { name: "Email", description: "Email" },
    { name: "Gender", description: "Gender" },
    { name: "Address1", description: "Address 1" },
    { name: "Address2", description: "Address 2" },
    { name: "City", description: "City" },
    { name: "Country", description: "Country" },
    { name: "PostalCode", description: "Postal Code" },
    { name: "CellPhoneNumber", description: "Phone Number" },
    { name: "State", description: "State" },
    { name: "BirthDate", description: "Birth Date" },
    { name: "GuardianFirstName", description: "Guardian First Name" },
    { name: "GuardianMiddleName", description: "Guardian Middle Name" },
    { name: "GuardianLastName", description: "Guardian Last Name" },
    { name: "GuardianAddress1", description: "Guardian Address 1" },
    { name: "GuardianAddress2", description: "Guardian Address 2" },
    { name: "GuardianCity", description: "Guardian City" },
    { name: "GuardianState", description: "Guardian State" },
    { name: "GuardianCountry", description: "Guardian Country" },
    { name: "GuardianPostalCode", description: "Guardian Postal Code" },
    { name: "GuardianEmail", description: "Guardian Email" },
    { name: "guardianCellPhoneNumber", description: "Guardian Cell Phone Number" },
    { name: "None", description: "Not Used" }
  ], [])

  useEffect(() => {
    const getResponse = async () => {
      const csvUploadRequest = new themis_common.UploadCsvRequest()
      csvUploadRequest.setCsvFile(csvState.base64File)
      csvUploadRequest.setFileName(csvState.fileName)
      csvUploadRequest.setHasHeader(hasHeader)
      const response = (await client.importSpreadsheet(csvUploadRequest, {})).toObject()
      setColumns(response?.data?.fieldsList.sort(function (a: themis_common.GenericFieldDescriptor.AsObject, b: themis_common.GenericFieldDescriptor.AsObject) { return a.id > b.id }) ?? [])
      const responseColumns = response?.data?.fieldsList || []
      const newRows = (response?.data?.itemsList ?? []).map((row: themis_common.RowSnapshot.AsObject) => {

        const newRow: any = {}
        row.fieldsList.forEach((field) => {
          newRow[responseColumns[Number(field.parentId) - 1]?.name] = field.string
        })

        return newRow
      })
      setRows(newRows)
      setResponseItems(response?.data?.itemsList ?? [])
      const items = { ...destinationTargets }
      responseColumns.forEach((column: any) => {
        const newTarget = transformTargets.find((target: any) => target.description.toLowerCase().replace(/\s/g, '').replace(/\_/g, '').replace("parent", "guardian") === column.name.toLowerCase().replace(/\s/g, '').replace(/\_/g, '').replace("parent", "guardian"))
        items[column.id || "0"] = newTarget?.name || "None"
      })
      setDestinationTargets(items)
    }
    if (csvState.base64File.length > 0 && csvState.fileSelected) {
      getResponse()
    }

  }, [csvState, hasHeader])

  const table = useMemo(() => {
    return (
      <div>
        { programId ? <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={ async () => { await submitAthletes(dispatch, athletesProto, programId, locationId, teamId, dateFormat, hasMinimal); history.goBack() } }>Import Athletes</Button> : [] }
        <MUIDataTable
          title={"Import Review"}
          data={athletes}
          columns={athleteColumns}
          options={{
            selectableRows: "none" // <===== will turn off checkboxes in rows
          }}
        />
      </div>

    )
  }, [athletes])

  const handleChange = (value: string, name: string) => {
    const items = { ...destinationTargets }
    items[name || "0"] = value
    setDestinationTargets(items)
  }

  const columnMatcher = useMemo(() => {
    if (columns.length > 0 && rows.length > 0) {
      return (
        <div>
          <p style={{ fontWeight: "bold" }}>Please match your file&apos;s columns to the appropriate fields</p>
          <Grid container>
            {columns.map((csvColumn: any) => {
              return (
                <Grid item xs={12} sm={4} md={4} className={classes.gridForm} key={"grid-" + csvColumn.id}>
                  <InputLabel id={"demo-simple-select-label-" + csvColumn.id} key={"input-" + csvColumn.id} shrink variant="outlined">{csvColumn.name}</InputLabel>
                  <FormControl variant="outlined" fullWidth style={{ padding: "0px 10px 0px" }} key={"form-" + csvColumn.id}>
                    <Select
                      labelId="demo-simple-select-label"
                      id={"demo-simple-select-" + csvColumn.id}
                      value={destinationTargets[csvColumn.id] || "None"}
                      onChange={(e, v) => {
                        v && handleChange(String(e.target.value), csvColumn.id)
                      }}
                      key={"select-" + csvColumn.id}
                    >
                      {transformTargets.map((field: any) => {
                        return (
                          <MenuItem value={field.name} key={"column-" + csvColumn.id + "-field-" + field.name}>{field.description}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>

              )
            })}

          </Grid>
        </div>
      )
    }
  }, [rows, columns, destinationTargets])

  const modalQuestion: ReactElement = useMemo(() => <ul>{errorMessages.map((error, i) => <li key={`errors-${i}`}>{error}</li>)}</ul>, [errorMessages])

  useEffect(() => {
    if (destinationTargets.length === 0) return
    const targetFields = new Array<themis_common.GenericFieldDescriptor>()
    const itemsList = new Array<themis_common.RowSnapshot>()
    const convertSpreadsheetToAthletesRequest = new themis_common.FlatCsv()
    const table = new themis_common.TableSnapshot()
    table.setName("Spreadsheet")
    table.setId("0")
    for (const key of Object.keys(destinationTargets)) {
      const field = new themis_common.GenericFieldDescriptor()
      field.setId(key)
      field.setName(destinationTargets[key])
      field.setType(0)
      targetFields.push(field)
    }

    responseItems.forEach((inputRow: any) => {
      const row = new themis_common.RowSnapshot()
      row.setId(inputRow.id)
      inputRow.fieldsList.forEach((inputField: any) => {
        const field = new themis_common.GenericKeyValue()
        field.setString(inputField.string)
        field.setParentId(inputField.parentId)
        field.setTable(inputField.table)
        row.addFields(field)
      })
      itemsList.push(row)
    })
    table.setFieldsList(targetFields)
    table.setItemsList(itemsList)
    convertSpreadsheetToAthletesRequest.setData(table)

    const getAthletes = async function () {
      //Convert the spreadsheet data to an athlete set
      const response = (await client.convertSpreadsheetToAthletes(convertSpreadsheetToAthletesRequest, {})).toObject()
      setAthletesProto(response.athletesList)
      const athleteRows: any = []
      setAthleteErrors([])
      setErrorIndex([])
      response.athletesList.forEach((athlete: any, i: number) => {
        const newPerson: any = { ...athlete.person }
        let athleteBirthDate: Date

        if (athlete?.name?.trim().length === 0) {
          setAthleteErrors((current) => {
            athlete.errorMessage = `Missing Name (at row ${i+ 1})`
            current.push(athlete)
            setErrorIndex((current)=> { current.push(i); return current })
            return current
          })
          return
        }

        if (athlete?.person?.birthDate) {
          switch (dateFormat) {
            case 1:
              athleteBirthDate = parse(athlete.person.birthDate, "MM/dd/yyyy", new Date())
              break
            case 2:
              athleteBirthDate = parse(athlete.person.birthDate, "dd/MM/yyyy", new Date())
              break
            case 3:
              athleteBirthDate = parseISO(athlete.person.birthDate)
              break
            default:
              athleteBirthDate = parse(athlete.person.birthDate, "MM/dd/yyyy", new Date())
          }
          if (isNaN(athleteBirthDate.getTime())) {
            setAthleteErrors((current) => {
              athlete.errorMessage = `BirthDate (at row ${i+1})`
              current.push(athlete)
              return current
            })
            return
          }
          newPerson.birthDate = format(athleteBirthDate, "yyyy-MM-dd")
        } else {
          return
        }

        switch (athlete.person.gender) {
          case 0:
            newPerson.gender = "M"
            break
          case 1:
            newPerson.gender = "F"
            break
          case 2:
            setAthleteErrors((current) => {
              athlete.errorMessage = `Gender (at row ${i+1})`
              current.push(athlete)
              return current
            })
            return
          case 3:
            setAthleteErrors((current) => {
              athlete.errorMessage = `Gender (at row ${i+1})`
              current.push(athlete)
              return current
            })
            return
        }

        const age = differenceInCalendarYears(new Date(), athleteBirthDate)

        if (!hasMinimal && age < 18) {
          if (athlete.athleteGuardiansList.length === 0 && !hasMinimal) {
            setAthleteErrors((current) => {
              athlete.errorMessage = `Missing Guardian (at row ${i + 1})`
              current.push(athlete)
              return current
            })
            return
          } else if (!athlete.athleteGuardiansList.reduce((current: boolean, guardian: any) => current || (guardian?.name?.trim().length > 0 && isValidEmail(guardian?.person?.email?.length)), false) && !hasMinimal) {
            setAthleteErrors((current) => {
              athlete.errorMessage = `Missing Guardian Name or Email (at row ${i + 1})`
              current.push(athlete)
              return current
            })
            return
          }
        }

        newPerson.guardianFirstName = athlete.athleteGuardiansList?.[0].person?.legalFirstName ?? ""
        newPerson.guardianLastName = athlete.athleteGuardiansList?.[0].person?.legalLastName ?? ""
        newPerson.guardianMiddleName = athlete.athleteGuardiansList?.[0].person?.legalMiddleName ?? ""
        newPerson.guardianAddress1 = athlete.athleteGuardiansList?.[0].person?.address1 ?? ""
        newPerson.guardianAddress2 = athlete.athleteGuardiansList?.[0].person?.address2 ?? ""
        newPerson.guardianCity = athlete.athleteGuardiansList?.[0].person?.city ?? ""
        newPerson.guardianState = athlete.athleteGuardiansList?.[0].person?.state ?? ""
        newPerson.guardianCountry = athlete.athleteGuardiansList?.[0].person?.country ?? ""
        newPerson.guardianPostalCode = athlete.athleteGuardiansList?.[0].person?.postalCode ?? ""
        newPerson.guardianCellPhoneNumber = athlete.athleteGuardiansList?.[0].person?.cellPhone ?? ""
        newPerson.guardianEmail = athlete.athleteGuardiansList?.[0].person?.email ?? ""
        athleteRows.push(newPerson)
      })
      if (athleteErrors.length > 0) setHasErrors(true)
      setAthletes(athleteRows)
    }

    if (table.getItemsList().length > 0) {
      getAthletes()
    }
  }, [hasMinimal, dateFormat, destinationTargets, responseItems])

  const handleHeaderChange = () => {
    setHasHeader(!hasHeader)
  }

  const handleMinimalChange = (e: any) => {
    if (!e.target.checked) {
      setHasMinimal(false)
    } else {
      setAskHasMinimal(true)
    }
  }

  const athleteErrorHTML = useMemo(() => {
    const returnVal = athleteErrors.map((athlete) => `${athlete.name} - ${athlete.errorMessage}`)
    return returnVal.join(", ")
  }, [athleteErrors, athleteErrors.length])

  return (
    <Container style={{ maxWidth: "none" }}>
      <Typography variant="h1" component="h2" style={{ paddingBottom: 15 }}>
        Import Athletes on {program?.name ?? ""} - {team?.name ?? ""}
      </Typography>
      <Grid container justify="center" spacing={1}>
        <Grid item xs={12} md={3} lg={2} >
          <Paper>
            <csvUploadContext.Provider value={{ csvState, setCsvState }}>
              <AddCsv/>
            </csvUploadContext.Provider>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          <Alert severity="info">
            Choose a CSV file to import athletes and add them to your team.
            If your CSV has a header, the software will try its best to match the columns of your CSV to the correct fields.
            If your CSV does not have a header, uncheck the box to the left to manually assign the appropriate fields.
            We recommend that you carefully review your athletes in the preview below the import button before submitting.
          </Alert>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <FormControl>
            <InputLabel id="select-date-format-label">Date Format</InputLabel>
            <Select
              labelId="select-date-format-label"
              id="select-date-format"
              value={dateFormat}
              label="Date Format"
              onChange={(e) => setDateFormat(e.target.value as number) }
            >
              <MenuItem value={1}>MM/DD/YYYY</MenuItem>
              <MenuItem value={2}>DD/MM/YYYY</MenuItem>
              <MenuItem value={3}>YYYY-MM-DD</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            className={classes.fullWidth}
            control={
              <Checkbox
                checked={hasHeader}
                onChange={handleHeaderChange}
                name="hasHeader"
                color="primary"
              />
            }
            label="My CSV file has a header"
          />

          <FormControlLabel
            className={classes.fullWidth}
            control={
              <Checkbox
                checked={hasMinimal}
                onChange={handleMinimalChange}
                name="hasMinimal"
                color="primary"
              />
            }
            label="My CSV file has minimal information"
          />

        </Grid>
      </Grid>
      {columnMatcher}
      {table}
      <YesNoModal question={modalQuestion} title="Import Errors - Data not imported" isOpen={ errorMessages.length > 0 }  onClose={ () => { setErrorMessages([]) } } buttonActions={[ { name: "Okay", color: 'primary', callback: () => { setErrorMessages([]) } } ]} />
      <YesNoDialog
        title={`There are data errors`}
        question={`The following errors exist in your data set: ${athleteErrorHTML}.  If you press Import Athletes only the ones shown in the table will be imported.` }
        isOpen={hasErrors}
        onClose={() => setHasErrors(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => { setHasErrors(false) } },
        ]}
      />
      <YesNoDialog
        title={`Uploading a minimal file will mean that your team may not be able to register for some events without adding more information.  Absolute minimum information is first and last name, date of birth, and gender.`}
        question={`Would you still like to remove errors concerning missing data?`}
        isOpen={askHasMinimal}
        onClose={() => setAskHasMinimal(false)}
        buttonActions={[
          { name: "Yes", color: "primary", callback: () => { setAskHasMinimal(false); setHasMinimal(true) } },
          { name: "No", color: "primary", callback: () => { setAskHasMinimal(false) } },
        ]}
      />

    </Container>
  )
}

export default AddAthleteList
