
import React, { ReactElement } from 'react'
import { Button, InputLabel, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useCsvUploadState } from "components/util/CsvUploadContext"

const AddCsv: React.FC = (): ReactElement => {
  const { csvState, setCsvState } = useCsvUploadState()

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result?.toString())
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleFileRead = async (event: any) => {
    try {
      const file = event.target.files[0]
      const base64: any = await convertBase64(file)
      setCsvState({ ...csvState, fileName: await file.name, base64File: base64 })
      if (file.size > 10485760) {
        setCsvState({ ...csvState, fileName: await file.name, base64File: base64, fileSelected: false, maxFileSizeExceeded: true })
      } else {
        setCsvState({ ...csvState, fileName: await file.name, base64File: base64, fileSelected: true, maxFileSizeExceeded: false })
      }

    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <div style={{ padding: 20 }}>
      <div>
        <InputLabel>CSV File:</InputLabel>
        {/* <img alt="Logo" src={logoState.base64File} style={{ maxHeight: "100px", maxWidth: "200px", alignSelf: "center" }} hidden={!logoState.fileSelected || logoState.maxFileSizeExceeded}/>
        <img alt="Logo" src={logoState.oldLogo} style={{ maxHeight: "100px", maxWidth: "200px", alignSelf: "center" }} hidden={logoState.fileSelected || logoState.oldLogo.length === 0}/> */}
        <Typography hidden={!csvState.fileSelected} variant="caption">
          {csvState.fileName}
        </Typography>
        <Typography hidden={csvState.fileSelected} variant="caption">
          Please select a CSV file
        </Typography>
        {csvState.maxFileSizeExceeded ? <Alert severity="error" style={{ marginBottom: "10px" }} >Maximum File Size (10MB) exceeded, please try a smaller file!</Alert> : <></>}
      </div>

      <Button
        variant="contained"
        size="small"
        component="label"
        color="primary">
        Select CSV File
        <input
          type="file"
          accept=".csv"
          onChange={handleFileRead}
          hidden
        />
      </Button>
    </div>
  )

}

export { AddCsv }
