import React, { ReactElement, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from 'lib/theme';
import { FormValues, skillTypes } from 'lib/constants';
import { isValidDropdownValue } from 'lib/validators';

import LabelComponent from '../rubricComponents/Label';
import DropdownComponent from '../rubricComponents/Dropdown';
import SwitchComponent from '../rubricComponents/Switch';
import TextboxAreaComponent from '../rubricComponents/Textboxarea';
import DropdownRangeComponent from '../rubricComponents/DropdownRange';
import ButtonComponent from '../rubricComponents/Button';
import RadioComponent from '../rubricComponents/Radio';
import HelperTextComponent from '../rubricComponents/HelperText';
import TextboxComponent from '../rubricComponents/Textbox';

interface TemplateTwoProps {
    templateData: any;
    templateType: string;
    responseData: any;
    mode: string;
    judgeType?: string[];
    initialValues?: any;
}

const TemplateTwo: React.FC<TemplateTwoProps> = ({
    templateData,
    templateType,
    responseData,
    mode,
    judgeType,
    initialValues
}): ReactElement => {
    const classes = useStyles();
    const [objectName, setObjectName] = useState<string>(templateData.objectName)
    const [maxParticipationDriverThresholdValue, setmaxParticipationDriverThresholdValue] = useState<number>(templateData.maxParticipationDriverThresholdValue);
    const [totalAthletes, setTotalAthletes] = useState<number>(0);

    // State for managing skill 
    const [numberOfSkills, setNumberOfSkills] = useState<any>([]);
    const [isComment, setIsComment] = useState<boolean[]>([]);
    const [selectedSkills, setSelectedSkills] = useState<any>([]);
    const [selectedType, setSelectedType] = useState<any>({});
    const [selectedDropdownType, setSelectedDropdownType] = useState<string[]>([]);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState<any>([]);
    const [selectedTextboxValue, setSelectedTextboxValue] = useState<string[]>([]);
    const [selectedScore, setSelectedScore] = useState<any>({});

    // State for managing coed skill 
    const [numberOfCoedSkills, setNumberOfCoedSkills] = useState<any>([]);
    const [isCommentCoed, setIsCommentCoed] = useState<boolean[]>([]);
    const [selectedCoedSkills, setSelectedCoedSkills] = useState<any>([]);
    const [selectedCoedType, setSelectedCoedType] = useState<any>({});
    const [selectedCoedTextboxValue, setSelectedCoedTextboxValue] = useState<string[]>([]);
    const [selectedCoedScore, setSelectedCoedScore] = useState<any>({});

    // State for managing Score matrix
    const [scoreMatrix, setScoreMatrix] = useState<any>({});
    const [scoreCoedMatrix, setScoreCoedMatrix] = useState<any>({});

    // State for managing driverScoreSummary and maxDriverScore
    const [maxDriverScoreSummary, setMaxDriverScoreSummary] = useState<number>();
    const [maxDriverScoreValues, setMaxDriverScoreValues] = useState<{ [key: string]: number }>({});

    const [initialData, setInitialData] = useState<any>({});
    const [response, setResponse] = useState<any>({
        [objectName]: {}
    });

    useEffect(() => {
        responseData(response);
    }, [response]);

    useEffect(() => {
        // Extract score matrix 
        const scoreObj: any = { simple: {}, coed: {} };
        Object.values(templateData?.data?.rows).forEach((row: any) => {
            row.forEach((value: any) => {
                if (value.type === FormValues.RADIO_BUTTON) {
                    value.enums.forEach((enumItem: any) => {
                        const title = enumItem.title;
                        const simpleScores = {
                            MAJORITY: enumItem.majority,
                            MOST: enumItem.most,
                            MAX: enumItem.max
                        };

                        const coedTitle = `Coed ${title}`;
                        const coedScore = enumItem.coed;

                        scoreObj.simple[title] = simpleScores;
                        scoreObj.coed[coedTitle] = coedScore;
                    });
                }
            });
        });
        setScoreMatrix(scoreObj.simple);
        setScoreCoedMatrix(scoreObj.coed)
    }, []);

    // Find option values based on totalAtheltes
    const findMatchingOptionForPerformingSkill = (totalAthletes: number, options: any) => {
        const matchingOptions = [];
        for (let i = 0; i < options.length; i++) {
            const min = parseInt(options[i].min);
            const max = parseInt(options[i].max);
            if (totalAthletes >= min && totalAthletes <= max) {
                matchingOptions.push(options[i]);
            }
        }
        return matchingOptions;
    }

    const changeSkillData = () => {
        // Update setNumberOfSkills state for the render skills
        const defaultNumberOfSkills = initialValues?.[objectName]?.skill;
        if (defaultNumberOfSkills && Array.isArray(defaultNumberOfSkills)) {
            const skills = defaultNumberOfSkills.map((_, index) => ({
                type: 'Skill',
                value: index + 1,
            }));
            setNumberOfSkills(skills);;
        }

        // Extracting isComment values into a separate array
        const defaultComments = initialValues?.[objectName]?.skill?.map((item: any) => item.isComment);
        setIsComment(defaultComments);

        // Extracting skill values into a separate array
        const defaultSelectedSkill = initialValues?.[objectName]?.skill
        setSelectedSkills(defaultSelectedSkill);

        // Extracting type values into a separate array
        const defaultSelectedType = initialValues?.[objectName]?.skill?.map((item: any) => item.skillTypes);
        setSelectedType({ [objectName]: defaultSelectedType });

        // Extracting dropdown string into a separate array
        const skillArray = initialValues?.[objectName]?.skill;
        if (skillArray && Array.isArray(skillArray)) {
            const dropdownTypes = skillArray?.map(skill => {
                const match = skill.calculationSummary.match(/([a-zA-Z]+)\sAthletes/);
                return match ? match[1] : ''; // Extract the string before 'Athletes'
            });
            setSelectedDropdownType(dropdownTypes.filter(type => type !== '')); // Remove empty strings
        }

        // Extract default dropdown range values
        const defaultDropdownRangeValues = initialValues?.[objectName]?.skill?.map((item: any) => {
            const rangeValue = item.numberOfGroupsSkill;
            const allOption = templateData.data.rows[1].find((row: any) => row.type === "dropdownRange");
            const filteredOption = findMatchingOptionForPerformingSkill(initialValues?.[objectName]?.totalAthletes, allOption?.enums)
            // Find the object whose title starts with rangeValue
            // const defaultOption = filteredOption.find((option: any) => option.title.startsWith(rangeValue.toString()));
            const defaultOption = filteredOption.find((option: any) => option.const === rangeValue);

            return defaultOption;
        });
        setSelectedDropdownValue(defaultDropdownRangeValues);

        // Extracting comment values into a separate array
        const defaultTextboxValue = initialValues?.[objectName]?.skill.map((item: any) => item.comment);
        setSelectedTextboxValue(defaultTextboxValue);

        // Extracting scoreValue values into a separate array
        const defaultSelectedScore = initialValues?.[objectName]?.skill.map((item: any) => item.scoreValue);
        setSelectedScore({ [objectName]: defaultSelectedScore });
    }

    const changeCoedSkillData = () => {
        // Update setNumberOfCoedSkills state for the render skills
        const defaultNumberOfCoedSkills = initialValues?.[objectName]?.coedskill;
        if (defaultNumberOfCoedSkills && Array.isArray(defaultNumberOfCoedSkills)) {
            const skills = defaultNumberOfCoedSkills.map((_, index) => ({
                type: '"Coed Skill"',
                value: index + 1,
            }));
            setNumberOfCoedSkills(skills);;
        }

        // Extracting isComment values into a separate array
        const defaultCoedComments = initialValues?.[objectName]?.coedskill?.map((item: any) => item.isComment);
        setIsCommentCoed(defaultCoedComments);

        // Extracting coed skill values into a separate array
        const defaultSelectedCoedSkill = initialValues?.[objectName]?.coedskill
        setSelectedCoedSkills(defaultSelectedCoedSkill);

        // Extracting type values into a separate array
        const defaultSelectedCoedType = initialValues?.[objectName]?.coedskill?.map((item: any) => item.skillTypes);
        setSelectedCoedType({ [objectName]: defaultSelectedCoedType });

        // Extracting comment values into a separate array
        const defaultCoedTextboxValue = initialValues?.[objectName]?.coedskill?.map((item: any) => item.comment);
        setSelectedCoedTextboxValue(defaultCoedTextboxValue);

        // Extracting scoreValue values into a separate array
        const defaultSelectedCoedScore = initialValues?.[objectName]?.coedskill?.map((item: any) => item.scoreValue);
        setSelectedCoedScore({ [objectName]: defaultSelectedCoedScore });
    }

    useEffect(() => {
        if (mode === 'Edit') {
            setInitialData(initialValues?.[objectName]);

            // Update setTotalAthletes based on initialValues
            setTotalAthletes(initialValues?.[objectName]?.totalAthletes)

            //Update skill related states
            changeSkillData();

            //Update coed skill related states
            changeCoedSkillData();

            setMaxDriverScoreSummary(initialValues?.[objectName]?.maxParticipationDriverCalculationSummary);

            // Update maxParticipationDriverScoreValue to the state
            setMaxDriverScoreValues({ [objectName]: initialValues?.[objectName]?.maxParticipationDriverScoreValue })

            // Setting initialValues to the response
            setResponse({
                [objectName]: initialValues?.[objectName]
            })
        }
    }, [mode, initialValues, objectName]);

    // Calculate score of individual skill
    const calculateScore = (skillIndex: number, level: string, skill?: string) => {
        const updatedScores = { ...selectedScore };
        const updatedCoedScores = { ...selectedCoedScore };

        if (skill) {
            const updatedScore = [...(updatedScores[objectName] || [])];
            updatedScore[skillIndex] = scoreMatrix[level][skill.toUpperCase()];
            updatedScores[objectName] = updatedScore;
            setSelectedScore(updatedScores);
            return updatedScores;
        } else {
            const updatedCoedScore = [...(updatedCoedScores[objectName] || [])];
            updatedCoedScore[skillIndex] = scoreCoedMatrix[level];
            updatedCoedScores[objectName] = updatedCoedScore;
            setSelectedCoedScore(updatedCoedScores);
            return updatedCoedScores;
        }
    };

    // Calculate score of combined(All) skills
    const calculateMaxDriverScoreValue = (data: any, type: string) => {
        let mergedData;

        if (type.toLowerCase() === skillTypes.skill) {
            mergedData = [...data, ...(selectedCoedScore[objectName] || [])];
        } else {
            mergedData = [...data, ...(selectedScore[objectName] || [])];
        }
        const sum = mergedData.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);
        const roundedSum = parseFloat(sum.toFixed(2));

        if (roundedSum > maxParticipationDriverThresholdValue) {
            setMaxDriverScoreValues(prevState => ({
                ...prevState,
                [objectName]: maxParticipationDriverThresholdValue
            }));
            return maxParticipationDriverThresholdValue;
        } else {
            setMaxDriverScoreValues(prevState => ({
                ...prevState,
                [objectName]: roundedSum
            }));
            return roundedSum;
        }
    }

    // Click event of skill button
    const handleSkillButtonClick = (buttonType: string) => {
        if (buttonType.toLowerCase() === skillTypes.skill) {
            setNumberOfSkills((prevSkills: any[]) => {
                const lastValue = prevSkills.length > 0 ? prevSkills[prevSkills.length - 1].value : 0;
                return [
                    ...prevSkills,
                    {
                        type: buttonType,
                        value: lastValue + 1
                    }
                ]
            });
        } else {
            setNumberOfCoedSkills((prevSkills: any[]) => {
                const lastValue = prevSkills.length > 0 ? prevSkills[prevSkills.length - 1].value : 0;
                return [
                    ...prevSkills,
                    {
                        type: buttonType,
                        value: lastValue + 1
                    }
                ]
            });
        }
    };

    // handles text change event
    const handleTextFieldChange = (
        event: any
    ) => {
        const { name, value } = event.target;
        const updatedFormValues: any = { ...response };

        // Ensure response[objectName] is initialized
        updatedFormValues[objectName] = updatedFormValues[objectName] || {};
        updatedFormValues[objectName][name] = parseInt(value);
        setResponse(updatedFormValues);
    };

    // handles dropdown change event for calculation summary
    const handleCalculationDropdownChange = (
        value: any,
        element: any
    ) => {
        const updatedFormValues: any = { ...response };
        setMaxDriverScoreSummary(value.value);
        updatedFormValues[objectName][element.key] = value.value;
        setResponse(updatedFormValues);
    };

    // handles radio change event
    const handleRadioFieldChange = (skillIndex: number, type: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (type.toLowerCase() === skillTypes.skill) {
            //Get value of Radio value for calculationSummary Label
            const updatedTypes: any = [...(selectedType[objectName] || [])];
            updatedTypes[skillIndex] = value;
            const updatedSelectedType = { ...selectedType };
            updatedSelectedType[objectName] = updatedTypes;

            setSelectedType(updatedSelectedType);

            //Change calculationSummary and scoreValue label's value
            let newCalculationSummary = '';
            let scoreValue;
            let stuntScoreValue: number = 0;
            if (selectedDropdownType[skillIndex]) {
                newCalculationSummary = `${updatedTypes[skillIndex]} ${selectedDropdownType[skillIndex]} `;
                scoreValue = calculateScore(skillIndex, updatedTypes[skillIndex], selectedDropdownType[skillIndex])
                stuntScoreValue = calculateMaxDriverScoreValue(scoreValue[objectName], type)
            }

            let updatedSkills: any = selectedSkills ? [...selectedSkills] : [];
            updatedSkills[skillIndex] = {
                ...updatedSkills[skillIndex],
                [name]: value,
                calculationSummary: `${newCalculationSummary} Athletes`,
                scoreValue: scoreValue ? scoreValue[objectName][skillIndex] : 0
            };
            setSelectedSkills(updatedSkills);

            // Update form value
            setResponse((prevData: any) => ({
                ...prevData,
                [objectName]: {
                    ...prevData[objectName],
                    [skillTypes.skill]: updatedSkills,
                    maxParticipationDriverScoreValue: maxDriverScoreValues[objectName]
                }
            }));
        } else {
            //Get value of Radio value for calculationSummary Label
            const updatedCoedTypes: any = [...(selectedCoedType[objectName] || [])];
            updatedCoedTypes[skillIndex] = value;
            const updatedSelectedCoedType = { ...selectedCoedType };
            updatedSelectedCoedType[objectName] = updatedCoedTypes;
            setSelectedCoedType(updatedSelectedCoedType);

            //Change calculationSummary and scoreValue label's value
            let newCalculationSummary = '';
            let scoreValue;
            let stuntScoreValue: number = 0;
            scoreValue = calculateScore(skillIndex, updatedCoedTypes[skillIndex])
            stuntScoreValue = calculateMaxDriverScoreValue(scoreValue[objectName], type)
            newCalculationSummary = `${updatedCoedTypes[skillIndex]} `

            // Update coed skills in response
            let updatedCoedSkills: any = selectedCoedSkills ? [...selectedCoedSkills] : [];
            updatedCoedSkills[skillIndex] = {
                ...updatedCoedSkills[skillIndex],
                [name]: value,
                calculationSummary: `${newCalculationSummary} Athletes`,
                scoreValue: scoreValue ? scoreValue[objectName][skillIndex] : 0
            };
            setSelectedCoedSkills(updatedCoedSkills);

            // Update form value
            setResponse((prevData: any) => ({
                ...prevData,
                [objectName]: {
                    ...prevData[objectName],
                    [skillTypes.coedSkill]: updatedCoedSkills,
                    maxParticipationDriverScoreValue: stuntScoreValue
                }
            }));
        }
    };

    // handles dropdown change event
    const handleDropdownChange = (
        selectedDropdown: any,
        element: any,
        skillIndex: number,
        type: string,
    ) => {
        const updatedDropdownType = [...selectedDropdownType];
        const difficultyLabel = selectedDropdown.title;

        // Matches dropdown value with pattern
        if (isValidDropdownValue(difficultyLabel)) {
            const maxString = difficultyLabel.match(/\((\w+)\)/)?.[1] || '';
            updatedDropdownType[skillIndex] = maxString;
        } else {
            updatedDropdownType[skillIndex] = difficultyLabel;
        }
        setSelectedDropdownType(updatedDropdownType);

        const dropdownValue = selectedDropdownValue ? [...selectedDropdownValue] : [];
        dropdownValue[skillIndex] = selectedDropdown;
        setSelectedDropdownValue(dropdownValue);

        let newCalculationSummary = '';
        let scoreValue;
        let stuntScoreValue: number = 0;

        if (selectedType[objectName] && selectedType[objectName][skillIndex]) {
            newCalculationSummary = `${selectedType[objectName][skillIndex]} ${updatedDropdownType[skillIndex]}`;
            scoreValue = calculateScore(skillIndex, selectedType[objectName][skillIndex], updatedDropdownType[skillIndex]);
            stuntScoreValue = calculateMaxDriverScoreValue(scoreValue[objectName], type);
        } else {
            newCalculationSummary = `${updatedDropdownType[skillIndex]}`;
        }

        let updatedSkills: any = [...selectedSkills];
        updatedSkills[skillIndex] = {
            ...updatedSkills[skillIndex],
            [element.key]: selectedDropdown.const,
            calculationSummary: `${newCalculationSummary} Athletes`,
            scoreValue: scoreValue ? scoreValue[objectName][skillIndex] : 0
        };
        setSelectedSkills(updatedSkills);

        setResponse((prevData: any) => ({
            ...prevData,
            [objectName]: {
                ...prevData[objectName],
                [skillTypes.skill]: updatedSkills,
                maxParticipationDriverScoreValue: stuntScoreValue,
            },
        }));
    };

    // handles switch change event
    const handleSwitchFieldChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        skillIndex: number,
        type: string,
    ) => {
        const { name, checked } = event.target;

        if (type.toLowerCase() === skillTypes.skill) {
            const updatedIsComment = isComment ? [...isComment] : [];
            updatedIsComment[skillIndex] = event.target.checked;
            setIsComment(updatedIsComment);
        } else {
            const updatedIsComment = isCommentCoed ? [...isCommentCoed] : [];
            updatedIsComment[skillIndex] = event.target.checked;
            setIsCommentCoed(updatedIsComment);
        }
        let updatedSkills: any = type.toLowerCase() === skillTypes.skill ? (selectedSkills ? [...selectedSkills] : []) : (selectedCoedSkills ? [...selectedCoedSkills] : []);
        let updateFunction: any = type.toLowerCase() === skillTypes.skill ? setSelectedSkills : setSelectedCoedSkills;

        updatedSkills[skillIndex] = {
            ...updatedSkills[skillIndex],
            [name]: checked,
        };
        updateFunction(updatedSkills);

        // Update form value
        setResponse((prevData: any) => ({
            ...prevData,
            [objectName]: {
                ...prevData[objectName],
                [type.toLowerCase() === skillTypes.skill ? skillTypes.skill : skillTypes.coedSkill]: updatedSkills,
            },
        }));
    };

    // handles textboxarea change event
    const handleTextboxAreaFieldChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        skillIndex: number,
        type: string,
    ) => {
        const { name, value } = event.target;
        let updatedSkills: any = type.toLowerCase() === skillTypes.skill ? [...selectedSkills] : [...selectedCoedSkills];
        let updateSkillsFunction: any = type.toLowerCase() === skillTypes.skill ? setSelectedSkills : setSelectedCoedSkills;
        let updatedTextBox: any = type.toLowerCase() === skillTypes.skill ? (selectedTextboxValue ? [...selectedTextboxValue] : []) : (selectedCoedTextboxValue ? [...selectedCoedTextboxValue] : []);
        let updateTextBoxFunction: any = type.toLowerCase() === skillTypes.skill ? setSelectedTextboxValue : setSelectedCoedTextboxValue;

        updatedTextBox[skillIndex] = value;
        updateTextBoxFunction(updatedTextBox);

        updatedSkills[skillIndex] = {
            ...updatedSkills[skillIndex],
            [name]: value,
        };

        updateSkillsFunction(updatedSkills);

        // Update form value
        setResponse((prevData: any) => ({
            ...prevData,
            [objectName]: {
                ...prevData[objectName],
                [type.toLowerCase() === skillTypes.skill ? skillTypes.skill : skillTypes.coedSkill]: updatedSkills,
            },
        }));
    };

    // handle Delete skill feature
    const handleDeleteSkills = (
        skillIndex: number,
        type: string
    ) => {
        if (type.toLowerCase() === skillTypes.skill) {
            setNumberOfSkills((prevSkills: any) => {
                const updatedSkills = prevSkills.filter((item: any, index: number) => index !== skillIndex);
                return updatedSkills;
            })

            setIsComment(prevIsComment => {
                const updatedIsComment = prevIsComment?.filter((item, index) => index !== skillIndex);
                return updatedIsComment;
            });

            setSelectedDropdownValue((prevData: any) => {
                const test = prevData.filter((item: any, index: number) => index !== skillIndex);
                return test;
            });

            setSelectedTextboxValue((prevData: any) => {
                const test = prevData.filter((item: any, index: number) => index !== skillIndex);
                return test;
            });

        } else {
            setNumberOfCoedSkills((prevSkills: any) => {
                const updatedSkills = prevSkills.filter((item: any, index: number) => index !== skillIndex);
                return updatedSkills;
            });

            setIsCommentCoed(prevIsComment => {
                const updatedIsComment = prevIsComment.filter((item: any, index: number) => index !== skillIndex);
                return updatedIsComment;
            });

            setSelectedCoedTextboxValue((prevData: any) => {
                const test = prevData.filter((item: any, index: number) => index !== skillIndex);
                return test;
            });
        }

        let updatedData: any;
        let updateFunction: any;
        let updateTypeFunction: any;
        let updateDropdownTypeFunction: any;
        let updateScoreFunction: any;
        let stuntScoreValue: number;

        if (type.toLowerCase() === skillTypes.skill) {
            updateFunction = setSelectedSkills;
            updateTypeFunction = setSelectedType;
            updateDropdownTypeFunction = setSelectedDropdownType;
            updateScoreFunction = setSelectedScore;
        } else {
            updateFunction = setSelectedCoedSkills;
            updateTypeFunction = setSelectedCoedType;
            updateScoreFunction = setSelectedCoedScore;
        }

        updateFunction((prevData: any) => {
            updatedData = [...prevData];
            updatedData.splice(skillIndex, 1);
            return updatedData;
        });

        updateTypeFunction((prevData: any) => {
            let updatedType = [...(prevData[objectName] || [])];
            updatedType.splice(skillIndex, 1);
            return { [objectName]: updatedType };
        });

        if (updateDropdownTypeFunction) {
            updateDropdownTypeFunction((prevData: any) => {
                let updatedDropdownType = [...prevData];
                updatedDropdownType.splice(skillIndex, 1);
                return updatedDropdownType;
            });
        }

        updateScoreFunction((prevData: any) => {
            let updatedScore: any = [...(prevData[objectName] || [])];
            updatedScore.splice(skillIndex, 1);

            stuntScoreValue = calculateMaxDriverScoreValue(
                updatedScore,
                type
            );
            return { [objectName]: updatedScore };
        });

        setResponse((prevData: any) => ({
            ...prevData,
            [objectName]: {
                ...prevData[objectName],
                [type.toLowerCase() === skillTypes.skill ? skillTypes.skill : skillTypes.coedSkill]: updatedData,
                maxParticipationDriverScoreValue: stuntScoreValue,
            },
        }));
    }

    const renderFormButton = () => {
        return Object.values(templateData?.data?.rows).map((row: any, index: number) => {
            return (
                <Grid container key={`row_${index}`}>
                    {row.map((value: any, key: number) => {
                        if (value.visible) {
                            switch (value.type) {
                                case FormValues.STATIC_LABEL:
                                    return (
                                        <Grid item xs={12} sm={6} md={1}>
                                            <LabelComponent title={value.title} />
                                        </Grid>
                                    );

                                case FormValues.BUTTON:
                                    return ((templateType.toLowerCase()) !== 'simple') ?
                                        (<Grid item xs={12} sm={6} md={2} >
                                            <ButtonComponent
                                                element={value}
                                                handleButtonClick={handleSkillButtonClick}
                                                buttonType={value.subType ? skillTypes.coedSkill : skillTypes.skill}
                                            />
                                        </Grid>
                                        ) :
                                        (!value.subType &&
                                            <Grid item xs={12} sm={6} md={1} key={index}>
                                                <ButtonComponent
                                                    element={value}
                                                    handleButtonClick={handleSkillButtonClick}
                                                    buttonType={value.subType ? skillTypes.coedSkill : skillTypes.skill}
                                                />
                                            </Grid>
                                        );

                                case FormValues.TEXTBOX:
                                    return ((templateType.toLowerCase()) !== 'simple') ?
                                        (value.visible &&
                                            <Grid key={index} item xs={12} sm={6} md={3} className={classes.rubricScoring}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={11}>
                                                        <TextboxComponent
                                                            element={value}
                                                            handleTextFieldChange={handleTextFieldChange}
                                                            setTotalAthletes={value.key === 'totalAthletes' && setTotalAthletes}
                                                            values={initialValues?.[objectName]?.[value.key]}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1}>
                                                        <HelperTextComponent element={value} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (value.visible && value.key === 'totalAthletes' &&
                                            <Grid key={index} item xs={12} sm={6} md={3} className={classes.rubricScoring}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={11}>
                                                        <TextboxComponent
                                                            element={value}
                                                            handleTextFieldChange={handleTextFieldChange}
                                                            setTotalAthletes={setTotalAthletes}
                                                            values={initialValues?.[objectName]?.[value.key]}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1}>
                                                        <HelperTextComponent element={value} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );

                                default:
                                    return null;
                            }
                        }
                    })}
                </Grid>
            );
        });
    }

    const renderSkills = () => {
        const skills = [];
        for (let i = 0; i < numberOfSkills.length; i++) {
            const gridItems: any = [];
            Object.values(templateData?.data?.rows).forEach((row: any, index: number) => {
                const rowItems: any = [];
                row.forEach((element: any, key: number) => {
                    if (element.visible) {
                        switch (element.type) {
                            case FormValues.LABEL:
                                rowItems.push(
                                    <Grid item xs={12} sm={6} md={2} className={classes.rubricScoring} key={`skill_${i}_${index}_${key}`} style={{ textAlign: "center" }}>
                                        <LabelComponent title={element.title} data={i + 1} />
                                    </Grid>
                                );
                                break;

                            case FormValues.RADIO_BUTTON:
                                rowItems.push(
                                    <Grid item xs={12} sm={6} md={5} key={`skill_${i}_${index}_${key}`}>
                                        <RadioComponent
                                            element={element}
                                            index={i}
                                            type={numberOfSkills[i].type}
                                            handleRadioChange={handleRadioFieldChange}
                                            selectedValue={selectedType?.[objectName]?.[i]}
                                        />
                                    </Grid>
                                );
                                break;

                            case FormValues.DROPDOWN_RANGE:
                                rowItems.push(
                                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring} key={`skill_${i}_${index}_${element.key}_${element.type}_${key}`}>
                                        <DropdownRangeComponent
                                            element={element}
                                            handleDropdownChange={handleDropdownChange}
                                            options={findMatchingOptionForPerformingSkill(totalAthletes, element.enums)}
                                            selectedDropdownValue={selectedDropdownValue && selectedDropdownValue[i]}
                                            index={i}
                                            type={numberOfSkills[i].type}
                                        />
                                    </Grid>
                                );
                                break;

                            case FormValues.DELETE_BUTTON:
                                rowItems.push(
                                    <Grid item xs={12} sm={6} md={1} key={`skill_${i}_${index}_${key}`}>
                                        <ButtonComponent
                                            element={element}
                                            index={i}
                                            type={numberOfSkills[i].type}
                                            handleButtonClick={handleDeleteSkills}
                                        />
                                    </Grid>
                                );
                                break;

                            case FormValues.CALCULATIONSUMMARY_LABEL:
                                const typeValue = ((selectedType[objectName] && selectedType[objectName][i]) && selectedType[objectName][i]) || '';
                                const dropdownValue = ((selectedDropdownType[i]) && selectedDropdownType[i]) || '';
                                const calculationSummary = `${typeValue} ${dropdownValue} Athletes`;
                                rowItems.push(
                                    <>
                                        <Grid item xs={12} sm={6} md={1}></Grid>
                                        <Grid item xs={12} sm={6} md={2} key={`skill_${i}_${index}_${key}`}>
                                            <LabelComponent title={element.title} data={calculationSummary} />
                                        </Grid>
                                    </>
                                );
                                break;

                            case FormValues.SCOREVALUE_LABEL:
                                const scoreValue = ((selectedScore[objectName] && selectedScore[objectName][i]) && selectedScore[objectName][i]) || 0;
                                rowItems.push(
                                    <Grid item xs={12} sm={6} md={2} key={`skill_${i}_${index}_${key}`}>
                                        <LabelComponent title={` | ${element.title}`} data={scoreValue} />
                                    </Grid>
                                );
                                break;

                            case FormValues.SWITCH:
                                rowItems.push(
                                    <Grid item xs={12} sm={6} md={2} key={`skill_${i}_${index}_${key}`}>
                                        <SwitchComponent
                                            element={element}
                                            handleSwitchChange={handleSwitchFieldChange}
                                            type={numberOfSkills[i].type}
                                            isComment={isComment && isComment[i]}
                                            index={i}
                                        />
                                    </Grid>
                                );
                                break;

                            case FormValues.TEXTBOXAREA:
                                rowItems.push(
                                    (isComment && isComment[i]) &&
                                    (<Grid item xs={12} sm={6} md={4} className={classes.rubricScoring} key={`skill_${i}_${index}_${element.key}_${element.type}_${key}`}>
                                        <TextboxAreaComponent
                                            element={element}
                                            handleTextFieldChange={handleTextboxAreaFieldChange}
                                            selectedTextboxValue={selectedTextboxValue && selectedTextboxValue?.[i]}
                                            type={numberOfSkills[i].type}
                                            index={i}
                                        />
                                    </Grid>
                                    )
                                );
                                break;

                            default:
                                break;
                        }
                    }
                }
                );

                gridItems.push(
                    <Grid container key={`gridrow_skill_${index}_${i}`} alignItems="center">
                        {rowItems}
                    </Grid>
                );
            });

            {/* Add horizontal line */ }
            gridItems.push(<hr className={classes.horizontalLine} />);

            skills.push(
                <Grid container key={`skillsrow_skill_${i}`} alignItems="center">
                    {gridItems}
                </Grid>
            );
        }

        for (let i = 0; i < numberOfCoedSkills.length; i++) {
            const gridItems: any = [];

            Object.values(templateData?.data?.rows).forEach((row: any, index: number) => {
                const rowItems: any = [];
                row.forEach((element: any, key: number) => {
                    if (element.visible) {
                        switch (element.type) {
                            case FormValues.LABEL:
                                rowItems.push(
                                    <Grid item xs={12} sm={6} md={2} className={classes.rubricScoring} key={`coedSkill_${i}_${index}_${key}`} style={{ textAlign: 'center' }}>
                                        <LabelComponent title={`Coed ${element.title}`} data={i + 1} />
                                    </Grid>
                                );
                                break;

                            case FormValues.RADIO_BUTTON:
                                rowItems.push(
                                    <Grid item xs={12} sm={6} md={5} key={`coedSkill_${i}_${index}_${key}`}>
                                        <RadioComponent
                                            element={element}
                                            index={i}
                                            type={numberOfCoedSkills[i].type}
                                            handleRadioChange={handleRadioFieldChange}
                                            selectedValue={selectedCoedType?.[objectName]?.[i]}
                                        />
                                    </Grid>
                                );
                                break;

                            case FormValues.DELETE_BUTTON:
                                rowItems.push(
                                    <>
                                        <Grid item xs={12} sm={6} md={4} key={index}></Grid>
                                        <Grid item xs={12} sm={6} md={1} key={`coedSkill_${i}_${index}_${key}`}>
                                            <ButtonComponent
                                                element={element}
                                                index={i}
                                                type={numberOfCoedSkills[i].type}
                                                handleButtonClick={handleDeleteSkills}
                                            />
                                        </Grid>
                                    </>
                                );
                                break;

                            case FormValues.CALCULATIONSUMMARY_LABEL:
                                const typeValue = ((selectedCoedType[objectName] && selectedCoedType[objectName][i]) && selectedCoedType[objectName][i]) || '';
                                const calculationSummary = `${typeValue} Athletes`;
                                rowItems.push(
                                    <>
                                        <Grid item xs={12} sm={6} md={1}></Grid>
                                        <Grid item xs={12} sm={6} md={2} key={`coedSkill_${i}_${index}_${key}`}>
                                            <LabelComponent title={element.title} data={calculationSummary} />
                                        </Grid>
                                    </>
                                );
                                break;

                            case FormValues.SCOREVALUE_LABEL:
                                const scoreValue = ((selectedCoedScore[objectName] && selectedCoedScore[objectName][i]) && selectedCoedScore[objectName][i]) || 0;
                                rowItems.push(
                                    <Grid item xs={12} sm={6} md={2} key={`coedSkill_${i}_${index}_${key}`}>
                                        <LabelComponent title={` | ${element.title}`} data={scoreValue} />
                                    </Grid>
                                );
                                break;

                            case FormValues.SWITCH:
                                rowItems.push(
                                    <Grid item xs={12} sm={6} md={2} key={`coedSkill_${i}_${index}_${key}`}>
                                        <SwitchComponent
                                            element={element}
                                            handleSwitchChange={handleSwitchFieldChange}
                                            type={numberOfCoedSkills[i].type}
                                            isComment={isCommentCoed && isCommentCoed[i]}
                                            index={i}
                                        />
                                    </Grid>
                                );
                                break;

                            case FormValues.TEXTBOXAREA:
                                rowItems.push(
                                    (isCommentCoed && isCommentCoed[i]) &&
                                    (<Grid item xs={12} sm={6} md={4} className={classes.rubricScoring} key={`coedSkill_${i}_${index}_${key}`}>
                                        <TextboxAreaComponent
                                            element={element}
                                            handleTextFieldChange={handleTextboxAreaFieldChange}
                                            selectedTextboxValue={selectedCoedTextboxValue && selectedCoedTextboxValue[i]}
                                            type={numberOfCoedSkills[i].type}
                                            index={i}
                                        />
                                    </Grid>)
                                );
                                break;

                            default:
                                break;
                        }
                    }
                });

                gridItems.push(
                    <Grid container key={`gridrow_coedSkill_${index}_${i}`} alignItems="center">
                        {rowItems}
                    </Grid>
                );
            });
            {/* Add horizontal line */ }
            gridItems.push(<hr className={classes.horizontalLine} />);

            skills.push(
                <Grid container key={`skillsrow_coedSkill_${i}`} alignItems="center">
                    {gridItems}
                </Grid>
            );
        }

        return skills;
    };

    const renderSummarySkills = () => {
        return Object.values(templateData?.data?.rows).map((row: any, index: number) => {
            return (
                <Grid container key={`row_${index}`}>
                    {row.map((value: any, key: number) => {
                        if (value.visible) {
                            switch (value.type) {
                                case FormValues.END_LABEL:
                                    return (value.visible &&
                                        <Grid container>
                                            <Grid item xs={12} sm={6} md={3} key={index} style={{ textAlign: 'center' }}>
                                                <LabelComponent key={index} title={value.title} />
                                            </Grid>
                                        </Grid>
                                    );

                                case FormValues.DROPDOWN:
                                    return (value.visible) ?
                                        (<>
                                            <Grid item xs={12} sm={6} md={1}></Grid>
                                            <Grid item xs={12} sm={6} md={3} key={index} className={classes.rubricScoring}>
                                                <DropdownComponent
                                                    element={value}
                                                    handleDropdownChange={handleCalculationDropdownChange}
                                                    selectedData={maxDriverScoreSummary}
                                                />
                                            </Grid>
                                        </>
                                        ) : null;


                                case FormValues.END_INNER_LABEL:
                                    return (value.visible &&
                                        <Grid item xs={12} sm={6} md={3} key={index} style={{ textAlign: 'center' }}>
                                            <LabelComponent title={value.title} data={maxDriverScoreValues[objectName]} />
                                        </Grid>
                                    );

                                default:
                                    return null;
                            }
                        }
                    })}
                </Grid>
            );
        });
    }

    const renderFormElements = () => {
        const shouldDisplayElement = !templateData.judgeType || judgeType?.includes(templateData.judgeType);
        if (shouldDisplayElement) {
            return (
                <Grid container alignItems="center" className={classes.grouperForm}>
                    {renderFormButton()}
                    {renderSkills()}
                    {renderSummarySkills()}
                </Grid>
            );
        }
    }

    return (
        <>
            {renderFormElements()}
        </>
    );
};

export default TemplateTwo;
