interface LabelComponentProps {
    title: string,
    data?: any;
}
const LabelComponent: React.FC<LabelComponentProps> = ({ title, data }) => {
    return ((data) ? (
        <h4> {title} {data}</h4 >) :
        (<h4>{title}</h4>)
    );
};

export default LabelComponent;
