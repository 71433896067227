import { Action, createAction } from 'redux-actions'
import { Dispatch } from "react"
import { client } from "store/themisClient"
import { emptyLocation, ObjectUserPermissions } from "./programReducer"
import { AnyDispatchType, SET_LOCATION, SET_LOCATIONS } from "lib/constants"
import themis_common from "store/themis_common_pb"
import themis_api from "store/themis_api_pb"
import { doSetTeam } from "./teamActions"
import { refreshUser } from "store/user/userActions"

const setLocations = createAction<themis_common.Location.AsObject[]>(SET_LOCATIONS)
const setLocation = createAction<themis_common.Location.AsObject>(SET_LOCATION)

async function clearLocation(dispatch: Dispatch<Action<themis_common.Location.AsObject> | Action<themis_common.Team.AsObject>>): Promise<void> {
  dispatch(setLocation(emptyLocation))
  doSetTeam(dispatch, -1, -1, -1)
}

async function doSetLocation(dispatch: Dispatch<Action<themis_common.Location.AsObject>>, programId: number, locationId: number): Promise<void> {
  let locationResponse: themis_common.Location.AsObject
  if (locationId > 0) {
    const location = new themis_common.Location()
    location.setId(locationId)
    location.setProgramId(programId)
    const res = await client.getLocation(location, {})
    locationResponse = res.toObject().location
  } else {
    locationResponse = emptyLocation
  }
  dispatch(setLocation(locationResponse))
}

async function deleteLocation(dispatch: AnyDispatchType, locationId: number, programId: number): Promise<void> {
  const location = new themis_common.Location()
  location.setId(locationId)
  location.setProgramId(programId)

  const response = (await client.deleteLocation(location, {})).toObject()

  const locations = response['locationsList']
  dispatch(setLocations(locations))
  await refreshUser(dispatch)
}

async function getLocations(dispatch: AnyDispatchType, programId: number): Promise<void> {
  const response = await getProgramLocations(programId)
  const locations = response['locationsList']
  dispatch(setLocations(locations))
}

async function getProgramLocations(programId: number): Promise<themis_api.LocationResponse.AsObject> {
  const program = new themis_common.Program()
  program.setId(programId)
  return (await client.getLocations(program, {})).toObject()
}

async function getLocationUsers(locationId: number, programId: number): Promise<ObjectUserPermissions[]> {
  const location = new themis_common.Location()
  location.setId(locationId)
  location.setProgramId(programId)
  const response = (await client.getLocationUsers(location, {})).toObject()
  return response["usersPermissionsList"]
}


function addLocation(location: themis_common.Location): any {
  return async (dispatch: any): Promise<themis_common.Location.AsObject> => {
    const response = (await client.addLocation(location, {})).toObject()

    const locations = response['locationsList']
    await dispatch(setLocations(locations))
    return response.location
  }
}

function updateLocation(location: themis_common.Location): any {
  return async (dispatch: any): Promise<void> => {
    const response = (await client.updateLocation(location, {})).toObject()

    const locations = response['locationsList']
    await dispatch(setLocations(locations))
  }
}

async function removeAthleteFromLocation(dispatch: AnyDispatchType, locationId: number, programId: number, athleteId: number): Promise<void> {
  const athlete = new themis_common.Athlete()
  athlete.setId(athleteId)

  const location = new themis_common.Location()
  location.setId(locationId)
  location.setProgramId(programId)
  const request = new themis_api.AthleteLocationRequest()
  request.setAthlete(athlete)
  request.setLocation(location)

  const res = await client.removeAthleteFromLocation(request, {})
  const locationResponse = res.toObject()
  const resultLocation = locationResponse.location
  dispatch(setLocation(resultLocation))
}

async function removeCoachFromLocation(dispatch: AnyDispatchType, locationId: number, programId: number, coachId: number): Promise<void> {
  const coach = new themis_common.Coach()
  coach.setId(coachId)

  const location = new themis_common.Location()
  location.setId(locationId)
  location.setProgramId(programId)
  const request = new themis_api.CoachLocationRequest()
  request.setCoach(coach)
  request.setLocation(location)

  const res = await client.removeCoachFromLocation(request, {})
  const locationResponse = res.toObject()
  const resultLocation = locationResponse.location
  dispatch(setLocation(resultLocation))
}

async function removePersonnelFromLocation(dispatch: AnyDispatchType, locationId: number, programId: number, personnelId: number): Promise<void> {
  const personnel = new themis_common.Personnel()
  personnel.setId(personnelId)

  const location = new themis_common.Location()
  location.setId(locationId)
  location.setProgramId(programId)
  const request = new themis_api.PersonnelLocationRequest()
  request.setPersonnel(personnel)
  request.setLocation(location)

  const res = await client.removePersonnelFromLocation(request, {})
  const locationResponse = res.toObject()
  const resultLocation = locationResponse.location
  dispatch(setLocation(resultLocation))
}

export {
  getLocations,
  getProgramLocations,
  addLocation,
  updateLocation,
  deleteLocation,
  doSetLocation,
  clearLocation,
  removeAthleteFromLocation,
  removeCoachFromLocation,
  removePersonnelFromLocation,
  getLocationUsers,
}
