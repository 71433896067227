import React, { ReactElement } from 'react'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

const ViewProducersButton: React.FC = (): ReactElement => {
  return (
    <Link to={"/EventProducers"} style={{ textDecoration: "none" }}>
      <Button
        aria-label="view-more-producers"
        variant="contained"
        color="primary">
          View More Producers
      </Button>
    </Link>
  )
}

export default ViewProducersButton
