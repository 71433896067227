import React, { ReactElement, useMemo } from 'react'
import { Grid } from '@material-ui/core'

import { useStyles } from "lib/theme"
import { ProgramPermission, ProducerPermission, BrandPermission, JudgeEventPermission, JudgeEventPermissions } from "store/user/userReducer"
import { Link as RouterLink } from "react-router-dom"
import themis_common from "../../store/themis_common_pb"
import { titleCase } from "../../lib/functions"

interface HeaderLogoProps {
  userState: string
  currentFocus: ProgramPermission | ProducerPermission | JudgeEventPermission | undefined
  currentBrand: BrandPermission | themis_common.Brand.AsObject | undefined
  currentEventRegistrationLogo: string | undefined
  judgeEvents?:JudgeEventPermissions | undefined
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({ userState, currentFocus, currentBrand, currentEventRegistrationLogo, judgeEvents }): ReactElement => {
  const classes = useStyles()

  const logo = useMemo<string | boolean | undefined>(() => {
    if (currentEventRegistrationLogo && currentEventRegistrationLogo > "") {
      return currentEventRegistrationLogo
    } else if (currentBrand?.logo && currentBrand.logo > "") {
      return currentBrand.logo
    } else if(userState === "judge" && judgeEvents) {
     for (const event in judgeEvents) {
          const eventLogo = judgeEvents[event]?.logo; 
          return eventLogo > "" && eventLogo;
      }
    }
     else {
      return currentFocus && currentFocus?.logo && currentFocus?.logo > "" ? currentFocus.logo : "/themislogo.png"
    }
  }, [currentBrand?.logo, currentFocus, currentEventRegistrationLogo])

  const logoLink = useMemo<string>(() => {
    if (currentBrand?.logo && currentBrand.logo > "") return `/Brand/${currentBrand.id}`
    const theType = titleCase(userState)
    return currentFocus?.logo && currentFocus?.logo > "" ? `/${theType}/${currentFocus.id}` : "/"
  }, [currentBrand?.logo, currentBrand?.id, userState, currentFocus?.logo, currentFocus?.id])
  return (
    <Grid item container sm={2} md={2} justify="center">
      <div className={classes.imageHolder}>
        <RouterLink to={logoLink}>
          <img key="LogoKey" src={logo as string} className={classes.headerLogo} alt={currentFocus?.name}/>
        </RouterLink>
      </div>
    </Grid>
  )
}

export default HeaderLogo
