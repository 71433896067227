import React, { ReactElement, useMemo, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Container, Grid, Hidden, IconButton,
  Paper, TextField, Typography
} from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Remove as RemoveIcon } from '@material-ui/icons'
import { useStyles } from "lib/theme"
import themis_common from "store/themis_common_pb"
import { Link as RouterLink } from "react-router-dom"
import YesNoDialog from 'components/util/YesNoModal'
import { validateLocationPermission, validateTeamPermission } from 'lib/permissions'
import { ProgramPermissions } from 'store/user/userReducer'

interface coachGridProps {
  programId: number, locationId: number, teamId?: number, coaches?: themis_common.Coach.AsObject[] | undefined,
  superUser: boolean,
  permissionCodes: themis_common.PermissionCodes.AsObject
  locationCoaches?: themis_common.Coach.AsObject[],
  programPermissions?: ProgramPermissions,
  handleDeleteCoach?: (coachId: number) => void,
  handleAddCoachToTeam?: (coachId: number) => void
  handleAddCoachDropdown?: (coachId: number) => void
  currentTeam?: themis_common.Team.AsObject
  currentLocation?: themis_common.Location.AsObject,
}

const CoachGrid: React.FC<coachGridProps> = ({
  programId, locationId, teamId, coaches, locationCoaches, superUser, programPermissions,
  handleDeleteCoach, handleAddCoachToTeam, handleAddCoachDropdown, permissionCodes,
  currentTeam, currentLocation
}): ReactElement => {

  const classes = useStyles()

  const [coachToDelete, setCoachToDelete] = useState<themis_common.Coach.AsObject | null>(null)
  const [rerenderAutocomplete, setRerenderAutoComplete] = useState(false)

  const availableCoaches = useMemo(() => {
    if (!locationCoaches || !coaches) return
    return locationCoaches.filter((lCoach: themis_common.Coach.AsObject) => !coaches.find((coach: themis_common.Coach.AsObject) => coach.id === lCoach.id))
  }, [coaches, locationCoaches])

  const gridHeader = useMemo(() => {
    return (
      <Grid container spacing={3} className={classes.personGridRow}>
        <Hidden only={['xs']}>
          <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
            <Paper className={classes.paperGridItem}>&nbsp;</Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={4} md={4} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>Name</Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>Birth Date</Paper>
        </Grid>
        <Hidden only={['xs', 'md', 'lg', 'xl']}>
          <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
            <Paper className={classes.paperGridItem}>&nbsp;</Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={4} md={3} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>City</Paper>
        </Grid>
      </Grid>
    )
  }, [classes.paperGridItem, classes.personGridHeader, classes.personGridRow])

  const addCoachIcon = useMemo(() => {
    if (
      (!teamId && programPermissions && validateLocationPermission(permissionCodes.coachAdd, permissionCodes, superUser, programPermissions, programId, locationId)) ||
      (teamId && programPermissions &&  validateTeamPermission(permissionCodes.coachAdd, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
    ) {
      if (teamId && teamId > 0) {
        return (<IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={`/Location/${locationId}/Team/${teamId}/AddCoach`}>
          <AddIcon fontSize="small" />
        </IconButton>)
      }
      return (
        <IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={`/Location/${locationId}/AddCoach`}>
          <AddIcon fontSize="small" />
        </IconButton>
      )
    } else {
      return []
    }
  }, [classes.clickable, locationId, programPermissions, programId, superUser, teamId, permissionCodes])

  const sortedCoaches = useMemo(() => {
    if (!coaches) return []
    const sortCoaches = [...coaches]
    sortCoaches.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    return sortCoaches
  }, [coaches])

  const [isOpen, setIsOpen] = useState(false)
  const handleDialogOpen = () => {
    setIsOpen(true)
  }
  const handleDialogClose = () => {
    setIsOpen(false)
  }

  function handleDelete() {
    if (!coachToDelete?.id || !handleDeleteCoach) return
    handleDeleteCoach(coachToDelete.id)
    handleDialogClose()
  }

  const userCanViewCoaches = useMemo(() => {
    if (!teamId && programPermissions) return validateLocationPermission(permissionCodes.coachView, permissionCodes, superUser, programPermissions, programId, locationId)
    if (teamId && programPermissions) return validateTeamPermission(permissionCodes.coachView, permissionCodes, superUser, programPermissions, programId, locationId, teamId)
  }, [superUser, programPermissions, programId, locationId, teamId, permissionCodes])

  const removeName = useMemo(() => currentTeam?.name ? currentTeam.name : currentLocation?.name, [currentLocation, currentTeam])
  const deleteCoachTitle = useMemo(() => currentLocation ? `Remove ${coachToDelete?.name} entirely from ${removeName}?` : `Delete ${coachToDelete?.name}?`, [currentLocation, removeName, coachToDelete?.name])
  const deleteCoachQuestion = useMemo(() => currentLocation ? `This cannot be undone. Are you sure?`: `Are you sure you want to remove ${coachToDelete?.name} from ${removeName}?`, [currentLocation, removeName, coachToDelete?.name])


  const theGrid = useMemo(() => {
    if (!sortedCoaches) return (<></>)
    return userCanViewCoaches ? (
      <Container component={Paper}>
        {gridHeader}
        {sortedCoaches.map((coach) => {
          const coachLink = teamId ? `/Location/${locationId}/Team/${teamId}/Coach/${coach.id}` : `/Location/${locationId}/Coach/${coach.id}`
          const editCoachLink = teamId ? `/Location/${locationId}/Team/${teamId}/EditCoach/${coach?.id}` : `/Location/${locationId}/EditCoach/${coach?.id}`
          return (
            <Grid container spacing={2} className={classes.personGridRow} key={coach.id}>
              <Grid item xs={12} sm={4} md={2} className={classes.personGridItem}>
                <Paper style={{ boxShadow: "none", textAlign: "center" }}>
                  {
                    (!teamId && programPermissions && validateLocationPermission(permissionCodes.coachEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
                    (teamId && programPermissions && validateTeamPermission(permissionCodes.coachEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
                      ?
                      (<IconButton aria-label="edit" component={RouterLink} to={editCoachLink}>
                        <EditIcon className={classes.personGridButton} />
                      </IconButton>)
                      : []
                  }
                  {
                    (!teamId && programPermissions && validateLocationPermission(permissionCodes.coachDelete, permissionCodes, superUser, programPermissions, programId, locationId)) ||
                    (teamId &&  programPermissions && validateTeamPermission(permissionCodes.coachDelete, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
                      ?
                      (<IconButton  aria-label="delete" onClick={e => { setCoachToDelete(coach); handleDialogOpen() }}>
                        {currentLocation ?
                          <DeleteIcon className={classes.personGridButton}/> : <RemoveIcon className={classes.personGridButton}/>
                        }
                      </IconButton>)
                      : []
                  }
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.personGridItem}>
                { coach.person?.usasfProfilePicture ?
                  (<Grid container style={{ justifyContent: "center" }}>
                    <Grid item md={8}>
                      <Paper className={classes.paperGridItem}>
                        <RouterLink to={coachLink} className={classes.cleanLink}>
                          {coach.name}{coach.person.nickName ? ' (' + coach.person.nickName + ')' : ''}
                        </RouterLink>
                      </Paper>
                    </Grid>
                    <Hidden smDown>
                      <Grid item md={4}>
                        {coach.person?.usasfProfilePicture ?
                          (<RouterLink to={coachLink} className={classes.cleanLink}>
                            <img src={coach.person.usasfProfilePicture} style={{ width: "50px", border: "1px solid red" }} alt={`${coach.person.name} USASF Profile`} />
                          </RouterLink>) : <></>}
                      </Grid>
                    </Hidden>
                  </Grid>
                  ) :
                  <Paper className={classes.paperGridItem}>
                    <RouterLink to={coachLink} className={classes.cleanLink}>
                      {coach.name}{coach.person?.nickName ? ' (' + coach.person.nickName + ')' : ''}
                    </RouterLink>
                  </Paper>
                }
              </Grid>
              <Grid item xs={12} sm={4} md={3} className={classes.personGridItem}>
                <Paper className={classes.paperGridItem}>
                  {coach.person?.birthDate ? format(parseISO(coach.person.birthDate), 'MMM dd, yyyy') : ""}
                </Paper>
              </Grid>
              <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
                  <Paper className={classes.paperGridItem}>&nbsp;</Paper>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={4} md={3} className={classes.personGridItem}>
                <Paper className={classes.paperGridItem}>
                  {coach.person?.city}
                </Paper>
              </Grid>
            </Grid>
          )
        })}
      </Container>
    ) : <></>
  }, [gridHeader, classes, locationId, sortedCoaches, programPermissions, programId, teamId, superUser, userCanViewCoaches, permissionCodes])

  return (
    <Container>
      <Grid container>
        <Typography style={{ marginRight: "25px" }} variant="h2">
          Coaches
        </Typography>
        {(!availableCoaches || availableCoaches.length === 0) ?
          <></>
          : (
            <Autocomplete
              key={rerenderAutocomplete ? '1' : '0'}
              id="Add_New_Team"
              size="small"
              options={availableCoaches}
              getOptionLabel={(coach: themis_common.Coach.AsObject) => (coach.name || 'No Name Found') + ' ' + (coach.person?.nickName ? ' (' + coach.person?.nickName + ')' : '')}
              style={{ width: 300, marginBottom: "15px" }}
              renderInput={(params) => <TextField {...params} label="Add Coach" variant="outlined" />}
              onChange={(e, v) => {
                if (handleAddCoachDropdown) {
                  v && handleAddCoachDropdown(v?.id)
                  setRerenderAutoComplete(!rerenderAutocomplete)
                }
              }}
              disabled={!(programPermissions && validateTeamPermission(permissionCodes.coachAdd, permissionCodes, superUser, programPermissions, programId, locationId, teamId || 0))}
            />)
        }
        {addCoachIcon}
        {theGrid}
      </Grid>
      <YesNoDialog
        title={deleteCoachTitle}
        question={deleteCoachQuestion}
        isOpen={isOpen}
        onClose={handleDialogClose}
        buttonActions={[
          { name: "No", color: "primary", callback: handleDialogClose },
          { name: "Yes", color: "primary", callback: handleDelete },
        ]}
      />
    </Container>)
}

export default CoachGrid
