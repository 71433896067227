import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import {
  AppBar,
  Button,
  Container,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import { loginUser } from 'store/user/userActions'
import { IAppState } from "store/store"
import { selectCurrentUser } from "store/user/userSelectors"
import { useHistory } from "react-router-dom"
import { useStyles } from "lib/theme"
import YesNoDialog from "components/util/YesNoModal"
import { useCallback } from 'react'
import { getAllProducerLinks } from "store/producer/producerActions"
import themis_common from 'store/themis_common_pb'
import { useMemo } from 'react'
import HeaderLogo from "../header/HeaderLogo"


const Login: React.FC = (): ReactElement => {
  const classes = useStyles()

  const username = useSelector((state: IAppState) => selectCurrentUser(state))

  const history = useHistory()

  useEffect(() => {
    if (username) {
      history.push('/')
    }
  }, [username, history])

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [dialogOpen, setDialogOpen] = useState(false)

  const dispatch = useDispatch()

  const submitForm: any = async (event: any) => {
    event.preventDefault()
    const doLogin = loginUser({ email, password })
    try {
      await doLogin(dispatch)
    } catch (e) {
      setDialogOpen(true)
      console.error(e)
    }
  }

  const startRegistration: any = (history: any) => {
    return (_event: any) => {
      // move to registration page
      history.push('/register')
    }
  }

  const [allproducers, setAllProducers] = useState<themis_common.ProducerUrl.AsObject[]>([])

  const getProducers = useCallback(() => {
    const getProducers = async () => {
      const producers = await getAllProducerLinks()
      setAllProducers(producers)
    }
    getProducers()
  }, [])

  useEffect(() => {
    if (allproducers.length === 0) getProducers()
  }, [getProducers, allproducers])

  const logoProducer = useMemo(() => {
    const storageProducerLink = localStorage.getItem('producerConnectLink')?.substring(9).toLowerCase()
    return allproducers.find((producer) => producer.connectUrl === storageProducerLink)
  }, [allproducers])

  return (
    <Container>
      <AppBar color="transparent" position="sticky" elevation={0} className={classes.headerRoot}>
        <Grid container>
          <HeaderLogo userState="program" currentFocus={undefined} currentBrand={undefined} currentEventRegistrationLogo={undefined} />
          <Grid item xs={8}>
            <Typography variant="h1" style={{ fontSize: 50, paddingTop: 10, textAlign: "center" }}>Themis Event</Typography>
            <Typography variant="h2" style={{ fontSize: 50, paddingTop: 10, textAlign: "center" }}>Registration System</Typography>
          </Grid>

        </Grid>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <Grid container>
          <Grid item xs={12} style={{ paddingTop: 60 }}>
            <Typography variant="h2">Login</Typography>
          </Grid>
        </Grid>
        <div className={classes.paper}>
          <form className={classes.form} noValidate onSubmit={submitForm}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={e => setEmail(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Button
              type="button"
              fullWidth
              variant="contained"
              className={classes.register}
              onClick={startRegistration(history)}
            >
              Register
            </Button>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              onClick={() => { history.push("/PasswordReset")}}
            >
              Forgot Password?
            </Button>
          </form>
          {/* TODO: Place producer logo in future header */}
          {username && logoProducer?.producer?.logo ?
            <img key="LogoKey" src={logoProducer?.producer?.logo} style={{ maxWidth: 275 }} alt={`${logoProducer?.producer?.name} Logo`}/> : <></>
          }
        </div>
      </Container>
      <YesNoDialog
        title={"Invalid Email or Password"}
        question={`The email and password you entered is an invalid combination, please try again.`}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setDialogOpen(false) },
        ]}
      />
    </Container>
  )
}

export default Login
