import { Button, Dialog, Grid, TextField, Tooltip, Typography, IconButton, DialogTitle, DialogContent, DialogActions, FormGroup, Checkbox, FormControlLabel, Switch, Card } from "@material-ui/core";
import { FormValues } from "lib/constants";
import { useStyles } from "lib/theme";
import React, { useEffect, useState } from "react";
import { Add as AddIcon, Edit as EditIcon, Remove as RemoveIcon } from '@material-ui/icons';
import LabelComponent from "../rubricComponents/Label";
import { camelCase } from "lib/functions";

interface Form4Props {
    index: number,
    responseData: any;
    objectName: string;
    formData: any;
    rubricId: number;
}
const Form4: React.FC<Form4Props> = ({ index, responseData, objectName, formData, rubricId }) => {
    const classes = useStyles();

    const dataValues = {
        "0": [
            {
                "key": "isComment",
                "title": "Comment",
                "type": "switch",
                "visible": true,
                "value": true
            },
            {
                "key": "comment",
                "title": "Comment",
                "type": "textboxarea",
                "required": true,
                "visible": true
            },
            {
                "title": "Criteria",
                "type": "matrix",
                "visible": true,
                "matrixRows": [
                    {
                        "label": "Execution of all skills (control, pace, mistakes)"
                    },
                    {
                        "label": "Stability / control / stance (bases & spotters)"
                    }
                ],
                "matrixColumns": [
                    {
                        "label": "Below average/ Low",
                        "categoryDescription": "Outstanding"
                    },
                    {
                        "label": "Average Above average/ Some",
                        "categoryDescription": "Excellent"
                    },
                    {
                        "label": "Excellent/ Plenty",
                        "categoryDescription": "Superior"
                    }
                ]
            },
        ]
    }

    // Dynamically initialize the response state with an empty object for each item in dataValues
    const initialResponseState = Object.keys(dataValues).reduce((acc: any, key: any) => {
        acc = dataValues
        return acc;
    }, {});

    const [response, setResponse] = useState<any>({
        data: {
            rows: initialResponseState
        }
    });
    const [data, setData] = useState<any>(dataValues);
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [editedElement, setEditedElement] = useState<any | null>(null);
    const [dialogRowOptions, setDialogRowOptions] = useState([{ label: "" }]);
    const [dialogColumnOptions, setDialogColumnOptions] = useState([{ label: "", categoryDescription: "" }]);
    const [isComment, setIsComment] = useState<boolean>(true);
    const [idx, setIdx] = useState<number>(0);
    const [errorText, setErrorText] = useState<string[]>([]);
    const [errorConstText, setErrorConstText] = useState<string[]>([]);
    const [titleError, setTitleError] = useState<string>('');

    // Update dropdown, dropdownRange and textboxRange keys as objectName 
    useEffect(() => {
        // Create a new copy of response
        const updatedResponse = { ...response };

        Object.values(updatedResponse.data.rows).forEach((row: any) => {
            row.forEach((field: any) => {
                if (field.type === FormValues.MATRIX) {
                    field.key = objectName;
                }
            });
        });

        // Update the state with the new response
        responseData(updatedResponse, index);
    }, [response, objectName]);

    // Set data for Edit form
    useEffect(() => {
        if (formData) {
            if (objectName) {
                const obj = formData.find((obj: any) => {
                    return obj.data.items[0].objectName === objectName
                })
                setData(obj.data.items[0].data.rows);

                setResponse({
                    data: {
                        rows: obj.data.items[0].data.rows
                    }
                });
            }
        }
    }, []);

    // Extracting matrixRows from the response
    useEffect(() => {
        let matrixRows, matrixColumns;
        if (rubricId > 0) {
            const template4Item = formData.find((item: any) => item.data.items[0].templateName === 'template-4');
            if (template4Item) {
                matrixRows = template4Item.data.items[0].data.rows[0].find((field: any) => field.type === FormValues.MATRIX)?.matrixRows;
                matrixColumns = template4Item.data.items[0].data.rows[0].find((field: any) => field.type === FormValues.MATRIX)?.matrixColumns;
            }
        } else {
            matrixRows = response?.data?.rows[0]?.find((field: any) => field.type === FormValues.MATRIX)?.matrixRows;
            matrixColumns = response?.data?.rows[0]?.find((field: any) => field.type === FormValues.MATRIX)?.matrixColumns;
        }

        // Formatting matrixRows into required format
        if (matrixRows && matrixRows.length > 0) {
            const dropdownOptions = matrixRows.map((enumItem: any) => ({
                label: enumItem.label
            }));

            // Setting dropdown options into state
            setDialogRowOptions(dropdownOptions);
        }
        if (matrixColumns && matrixColumns.length > 0) {
            const dropdownOptions = matrixColumns.map((enumItem: any) => ({
                label: enumItem.label,
                categoryDescription: enumItem.categoryDescription
            }));

            // Setting dropdown options into state
            setDialogColumnOptions(dropdownOptions);
        }
    }, []);

    const handleAddRowsOptionDialog = () => {
        setDialogRowOptions([...dialogRowOptions, { label: "" }]);
    };

    const handleAddColumnsOptionDialog = () => {
        setDialogColumnOptions([...dialogColumnOptions, { label: "", categoryDescription: "" }]);
    };

    const handleRemoveRowsOptionDialog = (index: number) => {
        const updatedOptions = [...dialogRowOptions];
        updatedOptions.splice(index, 1);
        setDialogRowOptions(updatedOptions);

        setEditedElement((prevEditedElement: any) => {
            if (prevEditedElement && (prevEditedElement.type === FormValues.MATRIX)) {
                return {
                    ...prevEditedElement,
                    matrixRows: updatedOptions,
                };
            }
            return prevEditedElement;
        });
    }

    const handleRemoveColumnsOptionDialog = (index: number) => {
        const updatedOptions = [...dialogColumnOptions];
        updatedOptions.splice(index, 1);
        setDialogColumnOptions(updatedOptions);

        setEditedElement((prevEditedElement: any) => {
            if (prevEditedElement && (prevEditedElement.type === FormValues.MATRIX)) {
                return {
                    ...prevEditedElement,
                    matrixColumns: updatedOptions,
                };
            }
            return prevEditedElement;
        });
    }

    const handleOptionChangeRowDialog = (index: number, key: string, value: any) => {
        // Create a copy of the current options array
        const rowsCopy: any = [...dialogRowOptions];

        // Update the value of the option at the specified index
        rowsCopy[index][key] = value;

        // Check for duplicates based on label or value
        const newOptionValue = rowsCopy[index][key];
        let isDuplicate;
        if (key === 'label') {
            isDuplicate = rowsCopy.some((option: any, i: number) => i !== index && option.label === newOptionValue);
        }

        // Update the error text accordingly
        const err = [...errorText];
        err[index] = isDuplicate ? `${key} should be unique` : '';
        setErrorText(err);

        // Update the state with the modified options array if no duplicates found
        if (!isDuplicate) {
            setDialogRowOptions(rowsCopy);
        }

        // Update the edited element if it's a dropdown or dropdown range
        setEditedElement((prevEditedElement: any) => {
            if (prevEditedElement && (prevEditedElement.type === FormValues.MATRIX)) {
                return {
                    ...prevEditedElement,
                    matrixRows: rowsCopy,
                };
            }
            return prevEditedElement;
        });
    };

    const handleOptionChangeColumnDialog = (index: number, key: string, value: any) => {
        // Create a copy of the current options array
        const coulmnsCopy: any = [...dialogColumnOptions];

        // Update the value of the option at the specified index
        coulmnsCopy[index][key] = value;

        // Check for duplicates based on label or value
        const newOptionValue = coulmnsCopy[index][key];
        let isDuplicate;
        if (key === 'label') {
            isDuplicate = coulmnsCopy.some((option: any, i: number) => i !== index && option.label === newOptionValue);
        }

        // Update the error text accordingly
        const err = [...errorText];
        err[index] = isDuplicate ? `${key} should be unique` : '';
        setErrorText(err);

        // Update the state with the modified options array if no duplicates found
        if (!isDuplicate) {
            setDialogColumnOptions(coulmnsCopy);
        }

        // Update the edited element if it's a dropdown or dropdown range
        setEditedElement((prevEditedElement: any) => {
            if (prevEditedElement && (prevEditedElement.type === FormValues.MATRIX)) {
                return {
                    ...prevEditedElement,
                    matrixColumns: coulmnsCopy,
                };
            }
            return prevEditedElement;
        });
    };

    const handleOpenDialog = (element: any, idx: number) => {
        setIdx(idx)
        setEditedElement(element);
        setEditDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setEditDialogOpen(false);
        setEditedElement(null);
        setErrorText([]);
        setErrorConstText([]);
        setTitleError('');
    };

    const handleSaveEditedElement = () => {
        if (!titleError && errorText.every(text => !text) && errorConstText.every(text => !text)) {
            let updatedEditedElement = editedElement;

            setResponse((prevResponse: any) => {
                const updatedRow = [...(prevResponse?.data?.rows[idx] || [])];
                const existingIndex = updatedRow.findIndex((item: any) => item.type === updatedEditedElement.type);

                if (existingIndex !== -1) {
                    // If element with the same key exists, update it
                    updatedRow[existingIndex] = { ...updatedEditedElement };
                } else {
                    // If element with the same key doesn't exist, add the new element
                    updatedRow.push({ ...updatedEditedElement });
                }

                const updatedRows = { ...prevResponse?.data?.rows };
                updatedRows[idx] = updatedRow;
                // Remove objects with a specific key if isDropdownRange is true

                return {
                    data: {
                        rows: updatedRows
                    }
                };
            });

            setData((prevData: any) => {
                const updatedData = [...prevData[idx]];
                const index = updatedData.findIndex((item) => item.type === updatedEditedElement.type);

                if (index !== -1) {
                    updatedData[index] = { ...updatedEditedElement };
                }
                return { ...prevData, [idx]: updatedData };
            });

            handleCloseDialog();
        }
    };

    const handleSetEditedElement = (updatedElement: any) => {
        // Automatically update the key field based on the title field
        const camelCaseKey = camelCase(updatedElement.title);
        setEditedElement({ ...updatedElement, key: camelCaseKey });
    };

    const addRowsOptionsDialog = (
        <>
            {dialogRowOptions.map((option, index) => (
                <Grid container key={index} style={{ marginTop: '5px' }} className={classes.grouper}>
                    <Grid item xs={12} sm={6} md={11} className={classes.rubricScoring}>
                        <TextField
                            label="Label"
                            name="label"
                            id="label"
                            variant="outlined"
                            fullWidth
                            value={option.label}
                            onChange={(event) => handleOptionChangeRowDialog(index, event.target.name, event.target.value)}
                            error={(errorText[index] ? true : false)}
                            helperText={(index !== undefined) ? errorText[index] : errorText}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={1} className={classes.rubricScoring}>
                        {index > 0 && (
                            <IconButton onClick={() => handleRemoveRowsOptionDialog(index)}>
                                <RemoveIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    );

    const addColumnsOptionsDialog = (
        <>
            {dialogColumnOptions.map((option, index) => (
                <Grid container key={index} style={{ marginTop: '5px' }} className={classes.grouper}>
                    <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                        <TextField
                            label="Label"
                            name="label"
                            id="label"
                            variant="outlined"
                            fullWidth
                            value={option.label}
                            onChange={(event) => handleOptionChangeColumnDialog(index, event.target.name, event.target.value)}
                            error={(errorText[index] ? true : false)}
                            helperText={(index !== undefined) ? errorText[index] : errorText}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} className={classes.rubricScoring}>
                        <TextField
                            label="Category Description"
                            name="categoryDescription"
                            id="categoryDescription"
                            variant="outlined"
                            fullWidth
                            value={option.categoryDescription}
                            onChange={(event) => handleOptionChangeColumnDialog(index, event.target.name, event.target.value)}
                            error={(errorText[index] ? true : false)}
                            helperText={(index !== undefined) ? errorText[index] : errorText}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={1} className={classes.rubricScoring}>
                        {index > 0 && (
                            <IconButton onClick={() => handleRemoveColumnsOptionDialog(index)}>
                                <RemoveIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    );

    const renderDialogContent = () => {
        if (!editedElement) return null;
        switch (editedElement.type) {
            case FormValues.MATRIX:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    setEditedElement({ ...editedElement, [event.target.name]: newTitle });
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                fullWidth
                                disabled
                                value={objectName}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />

                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <Typography variant="h2">
                                Rows
                                <IconButton onClick={handleAddRowsOptionDialog}>
                                    <AddIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                        {addRowsOptionsDialog}

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <Typography variant="h2">
                                Columns
                                <IconButton onClick={handleAddColumnsOptionDialog}>
                                    <AddIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                        {addColumnsOptionsDialog}

                    </Grid>
                );

            case FormValues.SWITCH:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    handleSetEditedElement({ ...editedElement, [event.target.name]: newTitle });
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Default Value"
                                    name="value"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                );

            case FormValues.TEXTBOXAREA:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    handleSetEditedElement({ ...editedElement, [event.target.name]: newTitle });
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Required"
                                    name="required"
                                    control={<Checkbox defaultChecked={editedElement.required}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                );

            default:
                return null;
        }
    };

    const dialog = (
        <Dialog open={editDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Edit Element</DialogTitle>
            <DialogContent>
                {renderDialogContent()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSaveEditedElement} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );

    const renderFormElements = () => {
        return Object.values(data).map((elements: any, idx: number) => {
            return elements.map((value: any, index: number) => {
                switch (value.type) {
                    case FormValues.MATRIX:
                        return (
                            <Grid container item xs={12} sm={6} md={2} alignItems="center" key={`row_${index}`}>
                                {/* Label Component */}
                                <Grid item>
                                    <LabelComponent title={value.title} />
                                </Grid>
                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.SWITCH:
                        return (
                            <Grid container xs={12} sm={4} md={2} className={classes.rubricScoring} key={`row_${index}`}>
                                {/* Switch Component */}
                                <Grid item>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Switch
                                                name={value.title}
                                                defaultChecked={true}
                                                onChange={(event) => {
                                                    setIsComment(event.target.checked)
                                                }}
                                            />}
                                            label={value.title} />
                                    </FormGroup>
                                </Grid>

                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.TEXTBOXAREA:
                        return (isComment) && (
                            <Grid container xs={12} sm={6} md={4} className={classes.rubricScoring} key={`row_${index}`}>
                                {/* TextBoxArea Component */}
                                <Grid item xs={12} sm={6} md={10}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows="2"
                                        rowsMax="2"
                                        id={value.key}
                                        name={value.key}
                                        label={value.title}
                                        inputProps={{
                                            autoComplete: 'off',
                                            tabIndex: -1,
                                        }}
                                    />
                                </Grid>

                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    default:
                        return null;
                }
            })
        });
    };

    const renderTables = () => {
        const gridData: any = Object.values(data).flat().filter((item: any) => item.type === FormValues.MATRIX);
        if (gridData.length === 0) {
            return null;
        }

        return (
            <Card className={classes.cardSpacing}>
                <table className={classes.templateMatrix}>
                    <thead>
                        <tr>
                            <th className={classes.templateMatrixTh}></th>
                            {gridData.map((label: any, index: number) => (
                                <React.Fragment key={index}>
                                    {label.matrixColumns.map((col: any, colIndex: number) => (
                                        <th key={colIndex} className={classes.templateMatrixTh}>{col.label}</th>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {gridData.map((label: any, dataIndex: number) => (
                            label.matrixRows.map((row: any, rowIndex: number) => (
                                <tr key={`${dataIndex}_${rowIndex}`}>
                                    <td className={classes.templateMatrixTd}>{row.label}</td>
                                    {label.matrixColumns.map((col: any, colIndex: number) => (
                                        <td className={classes.templateMatrixTd}>
                                            <input
                                                type="radio"
                                                id={`${dataIndex}_${rowIndex}_${colIndex}`}
                                                name={`row_${dataIndex}_${rowIndex}`}
                                                value={`${row.label}_&_${col.label}_&_${col.categoryDescription}`}
                                                className={classes.templateMatrixRadio}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table>
            </Card>
        );
    };

    return (
        <>
            <Grid container alignItems="center" className={classes.grouperForm} style={{ marginTop: '10px' }}>
                {renderFormElements()}
                {dialog}
            </Grid>
            <Grid container>
                {renderTables()}
            </Grid>
        </>
    );
}

export default Form4;