import { client } from "store/themisClient"
import themis_api from "store/themis_api_pb"
import themis_common from "store/themis_common_pb"


/* SCOPE
    TRANSACTION_ITEMS_SPECIFIC = 0;
    PRODUCER_PROGRAM_ITEMS = 1;
    PRODUCER_TEAM_ITEMS = 2;
    PRODUCER_LOCATION_ITEMS = 3;
    EVENT_PROGRAM_ITEMS = 4;
    EVENT_TEAM_ITEMS = 5;
    EVENT_LOCATION_ITEMS = 6;
    PROGRAM_ITEMS = 7;
    LOCATION_ITEMS = 8;
    TEAM_ITEMS = 9;
    BRAND_PROGRAM_ITEMS = 10;
    BRAND_TEAM_ITEMS = 11;
    BRAND_LOCATION_ITEMS = 12;
    PRODUCER_ITEMS = 13;
    BRAND_ITEMS = 14;
    EVENT_ITEMS = 15;
 */

/* PAID_SCOPE
    PAID = 0;
    UNPAID = 1;
    ANY = 2;
 */

async function getProgramBalance(programId: number, paid: themis_common.PaidScopeMap[keyof themis_common.PaidScopeMap]): Promise<themis_api.GetAccountBalanceResponse.AsObject> {
  const request = new themis_api.GetAccountBalanceRequest()
  request.setScope(7)
  request.setPaidScope(paid)
  request.setProgramId(programId)

  const res = await client.getAccountBalance(request, {})
  return res.toObject()
}
async function getLocationBalance(programId: number, locationId: number, paid: themis_common.PaidScopeMap[keyof themis_common.PaidScopeMap]): Promise<themis_api.GetAccountBalanceResponse.AsObject> {
  const request = new themis_api.GetAccountBalanceRequest()
  request.setScope(8)
  request.setPaidScope(paid)
  request.setProgramId(programId)
  request.setLocationId(locationId)

  const res = await client.getAccountBalance(request, {})
  return res.toObject()
}

async function getTeamBalance(programId: number, locationId: number, teamId: number, paid: themis_common.PaidScopeMap[keyof themis_common.PaidScopeMap]): Promise<themis_api.GetAccountBalanceResponse.AsObject> {
  const request = new themis_api.GetAccountBalanceRequest()
  request.setScope(9)
  request.setPaidScope(paid)
  request.setTeamId(teamId)
  request.setProgramId(programId)
  request.setLocationId(locationId)

  const res = await client.getAccountBalance(request, {})
  return res.toObject()
}

export {
  getProgramBalance,
  getLocationBalance,
  getTeamBalance
}
