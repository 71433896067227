import React, { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { placeCurrency, countries, Countries, usStates, UsStates, currencies, FormFields } from "lib/constants"
import { buildErrors, capitalizeFirstLetter, fieldsOkay, handleInputChange } from "lib/validators"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useStyles } from "lib/theme"
import { Container, Grid, Switch, TextField, Typography } from '@material-ui/core'
import { addBrand, addBrandLogo, doClearBrand, updateBrand } from "store/producer/brandActions"
import { IAppState } from "store/store"
import { selectCurrentBrand, selectCurrentBrands, selectCurrentProducer } from "store/producer/producerSelectors"
import { emptyBrand } from "store/producer/producerReducer"
import YesNoDialog from 'components/util/YesNoModal'
import FormTextField from 'components/util/FormTextField'
import { refreshUser } from 'store/user/userActions'
import SubmitButton from 'components/util/SubmitButton'
import { doSetProducer } from "store/producer/producerActions"
import { ADD_LOGO_BRAND } from "lib/constants"
import { AddLogo } from "components/util/AddLogo"
import { logoUploadContext, logoUploadDefault } from "components/util/LogoUploadContext"

const AddEditBrand: React.FC = (): ReactElement => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const loc = location.pathname.split('/')
  if (loc[3]?.toLowerCase() === 'addbrand') {
    doClearBrand(dispatch)
  }

  const { brandId: brandIdT } = useParams<{ brandId?: string | undefined }>()
  const brandId = Number(brandIdT)

  const [logoState, setLogoState] = useState<typeof logoUploadDefault>({ ...logoUploadDefault, id: brandId, addLogoType: ADD_LOGO_BRAND })

  const brands = useSelector((state: IAppState) => selectCurrentBrands(state))
  const brand = useSelector((state: IAppState) => selectCurrentBrand(state))
  const producer = useSelector((state: IAppState) => selectCurrentProducer(state))

  const create = !brand?.id ? 'Create' : 'Edit'
  const creating = !brand?.id ? 'creating' : 'editing'

  const [updatedBrand, setUpdatedBrand] = useState(emptyBrand)

  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})
  const [checkBrand, setCheckBrand] = useState(false)

  const defaultCountry = useMemo(() => {
    if (!updatedBrand?.country) return countries[0]
    return countries.find((country) => country.code === updatedBrand.country) || countries[0]
  }, [updatedBrand?.country])

  const defaultState = useMemo(() => usStates.find((state) => state.abbreviation === updatedBrand?.state) || usStates[0], [updatedBrand?.state])

  const defaultCurrency = useMemo(() => currencies.find((currency) => currency.code === updatedBrand?.currency) || currencies[0], [updatedBrand?.currency])

  const formFields: FormFields = useMemo(() => {
    return {
      name: { label: "Brand Name", required: true, phone: false, email: false },
      city: { label: "City", required: true, phone: false, email: false },
      state: { label: "State or Province", required: true, phone: false, email: false },
      country: { label: "Country", required: true, phone: false, email: false },
      address: { label: "Address", required: true, phone: false, email: false },
      address2: { label: "Address 2", required: false, phone: false, email: false },
      postalCode: { label: "Postal Code", required: true, phone: false, email: false },
      currency: { label: "Currency", required: true, phone: false, email: false },
      logo: { label: "Logo", required: false, phone: false, email: false },
      website: { label: "Website URL", required: false, phone: false, email: false },
      phone: { label: "Contact Phone", required: true, phone: true, email: false },
      email: { label: "Email", required: true, phone: false, email: true },
      guardianRequired: { label: "Guardian Required", required: false, phone: false, email: false },
    }
  }, [])


  const phoneFields = useMemo(() => {
    return {
      phone: true
    }
  }, [])

  useEffect(() => {
    // This is an edit case
    if (brand && brand.id) setUpdatedBrand(brand)
  }, [brand])

  // Always autofill from producer
  // New Brand
  useEffect(() => {
    // This is adding a new brand, defaults from the producer
    if (!producer) return
    if (producer.id !== 0 && brand?.id === 0) {
      setUpdatedBrand({
        id: 0,
        address: producer.address || "",
        address2: producer.address2 || "",
        city: producer.city || "",
        country: producer.country || "",
        email: producer.email || "",
        logo: producer.logo || "",
        name: "",
        phone: producer?.phone || "",
        postalCode: producer?.postalCode || "",
        state: producer?.state || "",
        website: producer?.website || "",
        eventsList: [],
        judgesList: [],
        producer: undefined,
        producerId: producer.id || 0,
        currency: placeCurrency(producer.country) || "USD",
        guardianRequired: true,
      })
    }
  }, [producer, brand, brands])

  const formReady = useMemo(() => fieldsOkay(updatedBrand, formFields), [updatedBrand, formFields])

  const submitForm = async (event: FormEvent) => {
    setCheckBrand(true)
    event.preventDefault()
    if (formReady) {
      if (!brandId) { // Add
        if (!producer?.id) return
        try {
          const newBrandId = await addBrand(dispatch, {
            ...updatedBrand,
            producerId: producer.id,
          })
          if (logoState.fileSelected) {
            await addBrandLogo(dispatch, newBrandId, producer.id, logoState.base64File, logoState.fileName)
            setLogoState(logoUploadDefault)
          }
          await refreshUser(dispatch)
          await doSetProducer(dispatch, producer.id) // This fetches an updated set of brands for the producer
          history.replace(`/Brand/${newBrandId}`)
        } catch (e) {
          setIsErrorOpen(true)
          console.error(e)
        }
      } else { // Edit
        if (!producer?.id) return
        try {
          await updateBrand(dispatch, {
            ...updatedBrand,
            producerId: producer.id,
            id: brandId,
          })
          if (logoState.fileSelected) {
            await addBrandLogo(dispatch, logoState.id, producer.id, logoState.base64File, logoState.fileName)
            setLogoState(logoUploadDefault)
          }
          await refreshUser(dispatch)
          history.replace(`/Brand/${brandId}`)
        } catch (e) {
          setIsErrorOpen(true)
          console.error(e)
        }
      }
    }
  }

  const [isErrorOpen, setIsErrorOpen] = useState(false)


  // Check fields when user tries to submit
  useEffect(() => {
    if (checkBrand) {
      setErrorText(buildErrors(updatedBrand, formFields, setUpdatedBrand))
      setCheckBrand(false)
    }
  }, [checkBrand, phoneFields, formFields, updatedBrand])

  const headerContents = () => {
    if (brand?.name) {
      return `${create} Brand - ${brand.name}`
    } else {
      return `${create} Brand`
    }
  }

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{headerContents()}</Typography>
        </Grid>
      </Grid>

      <Container component="main" maxWidth="md">
        {/* name row */}
        <Grid container>
          <Grid item xs={12} sm={6} md={6} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedBrand}
              setUpdateObject={setUpdatedBrand}
              name="name"
              autoFocus
              sliceSize={100}
            />
          </Grid>
        </Grid>
        {/* location row */}
        <Grid container>
          {!updatedBrand?.country && brand?.country ? <></> :
            <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
              <Autocomplete
                key={'1'}
                options={countries}
                fullWidth={true}
                value={defaultCountry}
                getOptionLabel={(country: Countries) => country.name}
                disableClearable
                renderInput={(params) =>
                  <form noValidate onSubmit={(e) => { e.preventDefault() }}>
                    <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} autoComplete="none" label={formFields.country.label} required={formFields.country.required} variant="outlined" />
                  </form>
                }
                onChange={(e, v) => {
                  v && handleInputChange({ target: { name: "country", value: v.pseudoCode || v.code } }, formFields, updatedBrand, setUpdatedBrand, errorText, setErrorText)
                }}
              />
            </Grid>
          }

          {updatedBrand.country === 'US' || !updatedBrand?.country ?
            <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
              <Autocomplete
                key={'2'}
                options={usStates}
                fullWidth={true}
                value={defaultState}
                disableClearable
                getOptionLabel={(state: UsStates) => state.name}
                renderInput={(params) => <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} label={formFields.state.label} required={formFields.state.required} variant="outlined" />}
                onChange={(e, v) => {
                  v && handleInputChange({ target: { name: "state", value: v.abbreviation } }, formFields, updatedBrand, setUpdatedBrand, errorText, setErrorText)
                }}
              />
            </Grid>
            :
            <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
              <FormTextField
                formFields={formFields}
                errorText={errorText}
                setErrorText={setErrorText}
                updatedObject={updatedBrand}
                setUpdateObject={setUpdatedBrand}
                name="state"
                sliceSize={30}
              />
            </Grid>
          }
          <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedBrand}
              setUpdateObject={setUpdatedBrand}
              name="city"
              sliceSize={40}
            />
          </Grid>
        </Grid>
        {/* address row */}
        <Grid container>
          <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedBrand}
              setUpdateObject={setUpdatedBrand}
              name="address"
              sliceSize={200}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedBrand}
              setUpdateObject={setUpdatedBrand}
              name="address2"
              sliceSize={200}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedBrand}
              setUpdateObject={setUpdatedBrand}
              name="postalCode"
              sliceSize={20}
            />
          </Grid>
        </Grid>
        {/* currency row */}
        <Grid container>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <Autocomplete
              key={'3'}
              options={currencies}
              fullWidth={true}
              value={defaultCurrency}
              disableClearable
              getOptionLabel={(currency) => currency.currency}
              renderInput={(params) => <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} label={formFields.currency.label} required={formFields.currency.required} variant="outlined" />}
              onChange={(e, v) => {
                v && handleInputChange({ target: { name: "currency", value: v.code } }, formFields, updatedBrand, setUpdatedBrand, errorText, setErrorText)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <div style={{ marginTop: 16 }}>Require Guardian: </div>
            <div>
              <span style={{cursor: "pointer"}} onClick={(_) => { handleInputChange({ target: { name: "guardianRequired", value: false } }, formFields, updatedBrand, setUpdatedBrand, errorText, setErrorText) }}>No</span>
              <Switch checked={updatedBrand.guardianRequired} onChange={(e) => { handleInputChange({ target: { name: "guardianRequired", value: e.target.checked } }, formFields, updatedBrand, setUpdatedBrand, errorText, setErrorText) }} />
              <span style={{cursor: "pointer"}} onClick={(_) => { handleInputChange({ target: { name: "guardianRequired", value: true } }, formFields, updatedBrand, setUpdatedBrand, errorText, setErrorText) }}>Yes</span>
            </div>
          </Grid>
        </Grid>
        {/* url row */}
        <Grid container>
          <Grid item xs={12} sm={4} md={4} className={classes.gridForm}>
            <logoUploadContext.Provider value={{ logoState, setLogoState }}>
              <AddLogo/>
            </logoUploadContext.Provider>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedBrand}
              setUpdateObject={setUpdatedBrand}
              name="website"
              sliceSize={100}
            />
          </Grid>
        </Grid>
        {/* contact row */}
        <Grid container>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedBrand}
              setUpdateObject={setUpdatedBrand}
              name="phone"
              sliceSize={20}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedBrand}
              setUpdateObject={setUpdatedBrand}
              name="email"
              sliceSize={100}
            />
          </Grid>
        </Grid>
        <SubmitButton title={`${create} Brand`} ariaLabel="submit-brand-form" submitForm={submitForm} />
      </Container>
      <YesNoDialog
        title={`Error ${capitalizeFirstLetter(creating)} Brand`}
        question={`There was an error ${creating} your brand. Please contact us if this continues to be a problem.`}
        isOpen={isErrorOpen}
        onClose={() => setIsErrorOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
        ]}
      />
    </Container>
  )
}

export default AddEditBrand
