import React, { FunctionComponent, ReactElement } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

export interface DialogAction {
  name: string;
  color?: "inherit" | "primary" | "secondary" | "default" | undefined;
  callback: any;
}

interface YesNoDialogProps {
  question: string|ReactElement,
  title: string,
  isOpen: boolean,
  onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void,
  buttonActions: Array<DialogAction>,
  disabled?: boolean
}

const YesNoDialog: FunctionComponent<YesNoDialogProps> = (props: YesNoDialogProps) => {
  const {
    question,
    title,
    isOpen,
    onClose,
    buttonActions
  } = props
  const buttons = buttonActions.map((bAction: DialogAction) => {
    return <Button key={bAction.name} color={bAction?.color} onClick={bAction.callback}>
      {bAction.name}
    </Button>
  })
  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={isOpen}
      onClose={onClose}
      aria-labelledby='max-width-dialog-title'
    >
      <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{question}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons}
      </DialogActions>
    </Dialog>
  )
}

export default YesNoDialog
