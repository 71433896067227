import { Container, Grid, Typography, IconButton, Paper, Hidden } from '@material-ui/core';
import YesNoDialog from 'components/util/YesNoModal';
import { validateBrandPermission, validateProducerPermission } from 'lib/permissions';
import { Transition, useStyles } from "lib/theme"
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Remove as RemoveIcon, Archive as ArchiveIcon, CloseOutlined } from '@material-ui/icons'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import themis_common from "store/themis_common_pb"
import { ProducerPermissions } from 'store/user/userReducer'
import { Link as RouterLink } from "react-router-dom"
import { addProducerDivisionRubrics, deleteRubricAreaItems, getProducerDivisionRubrics, getRubricsByProducerId, getSeasons, deleteProducerDivisionRubrics, updateRubric } from 'store/producer/eventActions';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { IAppState } from 'store/store';
import { selectCurrentEventDates } from 'store/producer/producerSelectors';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import DivisionsList from './DivisionsList';

interface IAddDivision {
    isOpen: boolean;
}

interface scoreeSheetContainerProps {
    producerId: number,
    brandId?: number,
    judges?: themis_common.Judge.AsObject[] | undefined,
    superUser: boolean,
    permissionCodes: themis_common.PermissionCodes.AsObject
    producerPermissions?: ProducerPermissions,
    currentProducer?: themis_common.Producer.AsObject,
}

const ScoreSheetContainer: React.FC<scoreeSheetContainerProps> = ({
    judges,
    producerId,
    brandId,
    superUser,
    producerPermissions,
    permissionCodes,
    currentProducer
}): ReactElement => {
    const classes = useStyles();
    
    const eventDates = useSelector((state: IAppState) => selectCurrentEventDates(state))

    const [rubricData, setRubricData] = useState<themis_common.Rubric.AsObject[]>([]);
    const [deleteRubricAreaItem, setDeleteRubricAreaItem] = useState<any>({});
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [usedRubricOpen, setUsedRubricOpen] = useState<boolean>(false);
    const [archiveRubricAreaItem, setArchiveRubricAreaItem] = useState<any>({});
    const [isArchiveOpen, setIsArchiveOpen] = useState<boolean>(false);
    const [firstDate, setFirstDate] = useState<Date>(new Date())
    const [seasons, setSeasons] = useState<themis_common.Season.AsObject[]>([])
    const [addInputFilterValue, setAddInputFilterValue] = useState<any>([])
    const [eventDivisionUses, setEventDivisionUses] = useState<any[]>([])
    const [currentDivisions, setCurrentDivisions] = useState<any[]>([])
    const [addDivisionRubricAreaItem, setAddDivisionRubricAreaItem] = useState<any>({});

    const deleteScoreSheetBuilder = useMemo(() => currentProducer ? `Remove ${deleteRubricAreaItem?.name} entirely?` : `Delete ${deleteRubricAreaItem?.name}?`, [currentProducer, deleteRubricAreaItem])
    const deleteScoreSheetQuestion = useMemo(() => currentProducer ? `This cannot be undone. Are you sure you want to remove?` : `Are you sure you want to remove ${deleteRubricAreaItem?.name}?`, [currentProducer, deleteRubricAreaItem])

    const archiveScoreSheetBuilder = useMemo(() => currentProducer ? `Archive ${archiveRubricAreaItem?.name} entirely?` : `Archive ${archiveRubricAreaItem?.name}?`, [currentProducer, archiveRubricAreaItem])
    const archiveScoreSheetQuestion = useMemo(() => currentProducer ? `This cannot be undone. Are you sure you want to archive?` : `Are you sure you want to archive ${archiveRubricAreaItem?.name}?`, [currentProducer, archiveRubricAreaItem])

    const [isSplitDivision, setIsSplitDivision] = useState<IAddDivision>({ isOpen: false })
    const [isFormValid, setIsValid] = useState({ isOpen: false, isError: false })

    const getRubricData = async (producerId: number) => {
        const data = await getRubricsByProducerId(producerId);
        setRubricData(data);
        return data;
    }

    useEffect(() => {
        //Get rubric data list for current producer
        getRubricData(producerId);
    }, [producerId])

    useEffect(() => {
        const doGetSeasons = async () => {
            const eventDate = eventDates?.length ? eventDates[0].startDay.split("|")[0] : format(firstDate, 'yyyy-MM-dd')
            const seasonsResponse = await getSeasons(eventDate)

            setSeasons(seasonsResponse)
        }
        doGetSeasons()
    }, [firstDate, eventDates])

    const addJudgeIcon = useMemo(() => {
        if (
            (brandId && producerPermissions && validateBrandPermission(permissionCodes.judgeAdd, permissionCodes, superUser, producerPermissions, producerId, brandId) && validateProducerPermission(permissionCodes.judgeAdd, permissionCodes, superUser, producerPermissions, producerId))
            ||
            (!brandId && producerPermissions && validateProducerPermission(permissionCodes.judgeAdd, permissionCodes, superUser, producerPermissions, producerId))
        ) {
            let addScoreSheetLink = `${producerId}/ScoreSheetBuilder`;
            return (
                <IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={addScoreSheetLink}>
                    <AddIcon fontSize="small" />
                </IconButton>
            )
        } else {
            return []
        }
    }, [classes.clickable, producerId, producerPermissions, brandId, superUser, permissionCodes])

    const archiveRubricData = async (rubric: themis_common.Rubric.AsObject) => {
        if (archiveRubricAreaItem) {
            try {
                const data = await updateRubric(producerId, archiveRubricAreaItem.id, archiveRubricAreaItem.name, 1);
                setRubricData(data);
                setIsArchiveOpen(false)
            } catch (error) {
                console.log(error);
            }
        }
    }

    const gridHeader = useMemo(() => {
        return (
            <Grid container spacing={3} className={classes.personGridRow}>
                <Grid item xs={12} sm={4} md={8} className={classes.personGridHeader}>
                    <b><Paper className={classes.paperGridItem}>Rubric Name</Paper></b>
                </Grid>

                <Hidden only={['xs']}>
                    <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
                        <b><Paper className={classes.paperGridItem}>Action</Paper></b>
                    </Grid>
                </Hidden>
            </Grid>
        )
    }, [classes.paperGridItem, classes.personGridHeader, classes.personGridRow])

    const theGrid = useMemo(() => {
        return (
            <Container component={Paper}>
                {gridHeader}
                {rubricData.map((rubric: any) => (
                    <Grid container spacing={2} className={classes.personGridRow} key={rubric.id}>
                        <Grid item xs={12} sm={4} md={8} className={classes.personGridItem}>
                            <Paper className={classes.paperGridItem}>
                                {rubric.name}
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={4} md={2} className={classes.personGridItem}>
                            <Paper style={{ boxShadow: "none", textAlign: "center" }}>
                                {
                                    (!brandId && producerPermissions && validateProducerPermission(permissionCodes.judgeEdit, permissionCodes, superUser, producerPermissions, producerId)) ||
                                        (brandId && producerPermissions && validateBrandPermission(permissionCodes.judgeEdit, permissionCodes, superUser, producerPermissions, producerId, brandId))
                                        ?
                                        (<IconButton aria-label="edit" component={RouterLink}
                                            to={`${producerId}/ScoreSheetBuilder/${rubric?.id}`}
                                        >
                                            <EditIcon className={classes.personGridButton} />
                                        </IconButton>)
                                        : []
                                }
                                {
                                    !rubric.archive && (!brandId && producerPermissions && validateProducerPermission(permissionCodes.judgeDelete, permissionCodes, superUser, producerPermissions, producerId)) ||
                                        (brandId && producerPermissions && validateBrandPermission(permissionCodes.judgeDelete, permissionCodes, superUser, producerPermissions, producerId, brandId))
                                        ?
                                        (<IconButton aria-label="delete" onClick={e => { setDeleteRubricAreaItem(rubric), handleDialogOpen() }}>
                                            {currentProducer ?
                                                <DeleteIcon className={classes.personGridButton} /> : <RemoveIcon className={classes.personGridButton} />
                                            }
                                        </IconButton>)
                                        : []
                                }
                                {
                                    !rubric.archive && (!brandId && producerPermissions && validateProducerPermission(permissionCodes.judgeDelete, permissionCodes, superUser, producerPermissions, producerId)) ||
                                        (brandId && producerPermissions && validateBrandPermission(permissionCodes.judgeDelete, permissionCodes, superUser, producerPermissions, producerId, brandId))
                                        ?
                                        (<IconButton aria-label="archive" onClick={e => { setArchiveRubricAreaItem(rubric), setIsArchiveOpen(true) }}>
                                            {currentProducer ?
                                                <ArchiveIcon className={classes.personGridButton} /> : <RemoveIcon className={classes.personGridButton} />
                                            }
                                        </IconButton>)
                                        : []
                                }
                                {
                                    !rubric.archive && (!brandId && producerPermissions && validateProducerPermission(permissionCodes.judgeDelete, permissionCodes, superUser, producerPermissions, producerId)) ||
                                        (brandId && producerPermissions && validateBrandPermission(permissionCodes.judgeDelete, permissionCodes, superUser, producerPermissions, producerId, brandId))
                                        ?
                                        (<IconButton aria-label="division" onClick={e => {
                                            setIsSplitDivision(prev => ({ ...prev, isOpen: true }))
                                            setIsValid(() => ({
                                                isOpen: true,
                                                isError: false,
                                            }))
                                            setAddDivisionRubricAreaItem(rubric)
                                        }}>
                                            {
                                                <AddIcon className={classes.personGridButton} />
                                            }
                                        </IconButton>)
                                        : []
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                ))}
            </Container>
        );
    }, [rubricData, gridHeader, classes]);

    const handleDialogOpen = () => {
        setIsOpen(true)
    }
    const handleDialogClose = () => {
        setIsOpen(false)
    }

    const handleDelete = async () => {
        try {
            const data = await deleteRubricAreaItems(deleteRubricAreaItem);
            setRubricData(data);
            handleDialogClose()
        } catch (error: any) {
            const errorMessage = error.message;
            const isAlreadyInUse = errorMessage.includes("already in use");
            if (isAlreadyInUse) {
                setUsedRubricOpen(true)
            }
            handleDialogClose()
        }
    }
    
    //Get current Divisions
    const doGetEventDivisions = async () => {
        const foundEventDivisions: any = []

        seasons.forEach((item => {
            item.divisionsList.map(function (division: any) {
                division.season.id = item.id;
                division.season.name = item.name;
                return division;
            })
            foundEventDivisions.push(...item.divisionsList)
        }))

        const foundEventDivUses: any = producerId && await getProducerDivisionRubrics(producerId, addDivisionRubricAreaItem.id)
        foundEventDivUses?.producerDivisionRubricList?.map(((item: any) => {
            item.division.season.id = item.season.id;
            item.division.season.name = item.season.name;
            return item;
        }))
        if (foundEventDivUses) {
            setEventDivisionUses(foundEventDivUses?.producerDivisionRubricList)
        }

        const eventDivisionIds = foundEventDivUses?.producerDivisionRubricList?.map((division: any) => division.division.id);
        if (eventDivisionIds && eventDivisionIds.length > 0) {
            const filteredDivisions = foundEventDivisions.filter(
                (division: any) => !eventDivisionIds.includes(division.id)
            );
            if (filteredDivisions.length > 0) {
                setCurrentDivisions(filteredDivisions)
            } else {
                setCurrentDivisions([]);
            }
        } else {
            setCurrentDivisions(foundEventDivisions)
        }
    }

    useEffect(() => {
        //Get current Divisions
        doGetEventDivisions()
    }, [producerId, seasons, addDivisionRubricAreaItem])

    const addDivisions = useCallback((divisions: themis_common.Division.AsObject[]) => {
        const divisionList = divisions.map(division => ({
            producerId: producerId,
            divisionId: division.id,
            rubricId: addDivisionRubricAreaItem.id,
            seasonId: division?.season?.id || 1
        }));

        const doAddDivisions = async () => {
            if (producerId) {
                await addProducerDivisionRubrics(divisionList)

                setCurrentDivisions((prevData: any) => {
                    return prevData.filter((division: any) => !divisions.includes(division));
                });
                setEventDivisionUses((prevData: any) => [...prevData, ...divisions]);
            }
        }
        doAddDivisions()
    }, [producerId, addDivisionRubricAreaItem])

    const removeDivisions = useCallback((divisions: any) => {
        const divisionList = divisions.map((division: any) => ({
            producerId: producerId,
            id: division.id
        }));

        const doRemoveDivisions = async () => {
            if (producerId) {
                await deleteProducerDivisionRubrics(divisionList)

                const updatedUnusedDivisions = eventDivisionUses.filter(division => !divisions.some((newDivision: any) => newDivision.id === division.id));
                const division = divisions.map((division: any) => division.division);

                setEventDivisionUses(updatedUnusedDivisions);

                setCurrentDivisions((prevData: any) => [
                    ...prevData,
                    ...division
                ]);
            }
        }
        doRemoveDivisions()
    }, [producerId, eventDivisionUses])

    const splitEventDivisionDialog = useMemo(() => {
        const handelCloseButtonClick = () => {
            setIsSplitDivision((prev) => ({ ...prev, isOpen: false }))
        }
        return (
            <form className={classes.form}>
                <Dialog
                    open={isSplitDivision.isOpen}
                    onClose={handelCloseButtonClick}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth
                    maxWidth="md"
                    style={{ overflowY: "scroll", height: "750px" }}
                >
                    <Grid container justify="flex-end">
                        <IconButton
                            size="small"
                            style={{ marginTop: "10px" }}
                            onClick={handelCloseButtonClick}
                        >
                            <CloseOutlined style={{ marginRight: "3px" }} />
                        </IconButton>
                    </Grid>
                    <DialogContent>
                        <form>
                            <Grid container justify="center">
                                <Typography>Assign Rubric to Divisions</Typography>
                            </Grid>
                            <DivisionsList
                                currentDivisions={eventDivisionUses}
                                unusedDivisions={currentDivisions}
                                addDivisions={addDivisions}
                                removeDivisions={removeDivisions}
                                eventSeasons={seasons}
                                userCanViewEvent={true}
                                userCanEditEvent={true} />
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Grid container>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </form>
        )
    }, [isSplitDivision?.isOpen, isFormValid, addInputFilterValue, currentDivisions, eventDivisionUses])

    return (
        <Container>
            <Grid container>
                <Typography style={{ marginRight: "25px" }} variant="h2">
                    ScoreSheet
                </Typography>
                {addJudgeIcon}
                {theGrid}
                {splitEventDivisionDialog}
            </Grid>
            <YesNoDialog
                title={deleteScoreSheetBuilder}
                question={deleteScoreSheetQuestion}
                isOpen={isOpen}
                onClose={handleDialogClose}
                buttonActions={[
                    { name: "No", color: "primary", callback: handleDialogClose },
                    { name: "Yes", color: "primary", callback: handleDelete },
                ]}
            />

            <YesNoDialog
                title={`Archive ${deleteRubricAreaItem?.name} Rubric?`}
                question={'Can Not Delete, Rubric is already in use. You can archive this rubric.'}
                isOpen={usedRubricOpen}
                onClose={() => setUsedRubricOpen(false)}
                buttonActions={[
                    { name: "Okay", color: "primary", callback: () => setUsedRubricOpen(false) },
                ]}
            />

            <YesNoDialog
                title={archiveScoreSheetBuilder}
                question={archiveScoreSheetQuestion}
                isOpen={isArchiveOpen}
                onClose={() => setIsArchiveOpen(false)}
                buttonActions={[
                    { name: "No", color: "primary", callback: () => setIsArchiveOpen(false) },
                    { name: "Yes", color: "primary", callback: archiveRubricData },
                ]}
            />
        </Container>
    )
}

export default ScoreSheetContainer
