
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { AppBar, Button, Container, Grid, TextField, Typography } from '@material-ui/core'
import { useParams } from "react-router-dom"
import HeaderLogo from "../header/HeaderLogo"
import { useStyles } from "../../lib/theme"
import PasswordFields from "../util/PasswordFields"
import { passwordStrengthOptions } from "../../lib/constants"
import { useHistory } from "react-router"
import YesNoDialog from "../util/YesNoModal"
import { passwordStrength } from "check-password-strength"
import { resetPassword, requestReset, testPasswordResetCode } from "../../store/user/userActions"
import { isValidEmail } from "../../lib/validators"

export interface PassResetReg {
  password: string
  password2: string
  email: string
  code: string
}

const PasswordReset: React.FC = (): ReactElement => {
  const { code } = useParams<{ code?: string | undefined }>()
  const classes = useStyles()
  const history = useHistory()

  const emptyReg: PassResetReg = {
    password: "",
    password2: "",
    email: "",
    code: code || "",
  }

  const [registration, setRegistration] = useState<PassResetReg>(emptyReg)
  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [sentOpen, setSentOpen] = useState<boolean>(false)
  const [doneOpen, setDoneOpen] = useState<boolean>(false)
  const [invalidOpen, setInvalidOpen] = useState<boolean>(false)
  const [formReady, setFormReady] = useState<boolean>(false)
  const [formReadyEmail, setFormReadyEmail] = useState<boolean>(false)
  const [passwordMatch, setPasswordMatch] = useState(true)
  const [passwordStrongEnough, setPasswordStrongEnough] = useState<boolean>(false)
  const [passwordStrengthText, setPasswordStrengthText] = useState<string>("")
  const [alertSeverity, setAlertSeverity] = useState<'error'| 'info' | 'success' | 'warning' | undefined>('warning')

  useEffect( () => {
    const testCode = async () => {
      if (code) {
        const isValidCode = await testPasswordResetCode(code)
        if (!isValidCode) {
          setInvalidOpen(true)
        }
      }
    }
    testCode()
  }, [code])

  useEffect(() => {
    setFormReady(
      passwordMatch && registration.password2.length >= 3 && passwordStrongEnough
    )
  }, [passwordMatch, passwordStrongEnough, setFormReady])

  useEffect(() => {
    setFormReadyEmail(
      isValidEmail(registration.email)
    )
  }, [registration.email])

  const submitForm: any = async (event: any) => {
    event.preventDefault()
    if (formReady) {
      try {
        await resetPassword(registration)
        setDoneOpen(true)
      } catch (e) {
        setDialogOpen(true)
        console.error(e)
      }
    }
  }

  const submitRequest: any = async (event: any) => {
    event.preventDefault()
    if (formReadyEmail) {
      try {
        await requestReset(registration)
        setSentOpen(true)
      } catch (e) {
        setDialogOpen(true)
        console.error(e)
      }
    }
  }
  const handleInputChange = (e: any) => {
    const { name, value } = e.target

    setRegistration((registration) => { return {  ...registration, [name]: value } })

    if (!value) {
      setErrorText((errorText) => { return { ...errorText, [name]: `${name.charAt(0).toUpperCase() + name.slice(1).replace(/([^0-9])([0-9])/g, '$1 $2').replace(/([A-Z])/g, ' $1').trim()} cannot be empty` } })
    } else {
      setErrorText((errorText) => { return { ...errorText, [name]: "" } })
    }
  }

  const passStrength: string = useMemo(() => { return passwordStrength(registration.password, passwordStrengthOptions).value }, [registration.password])

  return (
    <Container>
      <AppBar color="transparent" position="sticky" elevation={0} className={classes.headerRoot}>
        <Grid container>
          <HeaderLogo userState="program" currentFocus={undefined} currentBrand={undefined} currentEventRegistrationLogo={undefined} />
          <Grid item xs={8}>
            <Typography variant="h1" style={{ fontSize: 50, paddingTop: 10, textAlign: "center" }}>Themis Events</Typography>
            <Typography variant="h2" style={{ fontSize: 50, paddingTop: 10, textAlign: "center" }}>Reset Password</Typography>
          </Grid>

        </Grid>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <Grid container>
          <Grid item xs={12} style={{ paddingTop: 60 }}>
            <Typography variant="h2">Reset Password</Typography>
          </Grid>
        </Grid>
        {code ?
          <div className={classes.paper}>
            <form className={classes.form} noValidate onSubmit={submitForm}>
              <PasswordFields
                errorText={errorText}
                handleInputChange={handleInputChange}
                fields={{ password: true, password2: true }}
                password={registration.password}
                registration={registration}
                setObject={setRegistration}
                setErrorText={setErrorText}
                passStrength={passStrength}
                password2={registration.password2}
                setAlertSeverity={setAlertSeverity}
                setPasswordMatch={setPasswordMatch}
                setPasswordStrongEnough={setPasswordStrongEnough}
                setPasswordStrengthText={setPasswordStrengthText}
                passwordMatch={passwordMatch}
                passwordStrongEnough={passwordStrongEnough}
                passwordStrengthText={passwordStrengthText}
                alertSeverity={alertSeverity}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                onClick={submitForm}
              >
                Update Password
              </Button>
            </form>
          </div>
          :
          <div className={classes.paper}>
            <Typography>Enter the email you wish to reset the password for below and then press the Request Reset button.  We will send you an email with a link to reset the password.</Typography>
            <form className={classes.form} noValidate onSubmit={submitRequest}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                error={!!errorText.email}
                helperText={formReadyEmail || registration.email.length < 5 ? "" : "Invalid Email"}
                fullWidth
                name="email"
                label="email"
                type="text"
                id="email"
                autoComplete="current-email"
                onChange={(e) => {// @ts-ignore
                  handleInputChange(e)
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                onClick={submitRequest}
              >
                Request Reset
              </Button>
            </form>
          </div>
        }
      </Container>
      <YesNoDialog
        title={"Registration Error"}
        question={`There was an error updating your password. Please contact us if this continues to be a problem.`}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setDialogOpen(false) },
        ]}
      />
      <YesNoDialog
        title={"Code Requested"}
        question={`An email has been sent to the address you submitted with your reset link.  The email may take a few minutes to be delivered, please be patient and check your spam folder.`}
        isOpen={sentOpen}
        onClose={() => setDialogOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setSentOpen(false) },
        ]}
      />
      <YesNoDialog
        title={"Password Reset"}
        question={`Your password has been reset.  Click Okay to log in.`}
        isOpen={doneOpen}
        onClose={() => setDialogOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => { setDoneOpen(false); history.push('/login') } },
        ]}
      />
      <YesNoDialog
        title={"Invalid Password Reset Code"}
        question={`Your code is either invalid or has expired.  Click Okay to return to the login screen.`}
        isOpen={invalidOpen}
        onClose={() => setInvalidOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => { setDoneOpen(false); history.push('/login') } },
        ]}
      />
    </Container>

  )



}

export { PasswordReset }
