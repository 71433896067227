import React, { FunctionComponent } from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

interface NotificationSnackbarProps {
  isOpen: boolean
  onClose: (event?: React.SyntheticEvent<Element, Event> | undefined, reason?: string | undefined) => void
  message: string
  severity: 'error' | 'info' | 'success' | 'warning'
  autoHideDuration: number
}

const NotificationSnackbar: FunctionComponent<NotificationSnackbarProps> = (props: NotificationSnackbarProps) => {
  const { isOpen, onClose, message, severity, autoHideDuration } = props

  return (
    <Snackbar open={isOpen} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert variant="filled" onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default NotificationSnackbar
