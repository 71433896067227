/* eslint max-lines: "off" */
import React, { FunctionComponent, ReactElement, useState } from 'react'
import {
  Container, IconButton, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField
} from '@material-ui/core'
import { useStyles } from 'lib/theme'

import themis_common, { Coach } from 'store/themis_common_pb'
import { Remove as RemoveIcon } from "@material-ui/icons"
import Autocomplete from "@material-ui/lab/Autocomplete"

type eventRegistrationCoachProps = {
  sortedCoaches: Coach.AsObject[]
  sortedValidCoaches: Coach.AsObject[]
  addCoach: Function
  removeCoach: Function
  eventTeamId: number
  dateComparison: boolean
}

const EventRegistrationCoach: FunctionComponent<eventRegistrationCoachProps> = ({
  sortedCoaches,
  sortedValidCoaches,
  addCoach,
  removeCoach,
  eventTeamId,
  dateComparison
}): ReactElement => {
  const classes = useStyles()

  const [rerenderAutocomplete, setRerenderAutoComplete] = useState(false)

  return (
    <Container maxWidth="lg">
      {(!sortedValidCoaches || sortedValidCoaches.length === 0 || !dateComparison) ?
        <></>
        : (
          <Autocomplete
            key={rerenderAutocomplete ? '1' : '0'}
            id="Add_New_Coach"
            size="small"
            options={sortedValidCoaches}
            getOptionLabel={(coach: themis_common.Coach.AsObject) => (coach.name || 'No Name Found') + ' ' + (coach.person?.nickName ? ' (' + coach.person?.nickName + ')' : '')}
            style={{ width: 300, marginBottom: "15px" }}
            renderInput={(params) => <TextField {...params} label="Add Coach" variant="outlined" />}
            onChange={(e, v) => {
              v && addCoach(v)
              setRerenderAutoComplete(!rerenderAutocomplete)
            }}
          />)
      }
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {dateComparison && <TableCell style={{ width: "150px" }}>Remove from Event Team</TableCell>}
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCoaches.map((coach) => {
              return (
                <TableRow key={coach.id}>
                  {dateComparison && <TableCell style={{ width: "150px" }}>
                    <IconButton className={classes.clickable} aria-label="remove" onClick={_ => {
                      removeCoach(coach)
                    }}>
                      <RemoveIcon fontSize="small" />
                    </IconButton>
                  </TableCell>}
                  <TableCell>
                    {coach.name}{coach.person?.nickName ? ' (' + coach.person.nickName + ')' : ''}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default EventRegistrationCoach
