import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import {
  Card, CardContent, Checkbox, Container, Grid, IconButton, Typography,
  List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText
} from '@material-ui/core'
import { Link as RouterLink, useHistory, useParams } from "react-router-dom"
import { IAppState } from "store/store"
import { selectCurrentLocation, selectCurrentProgram, } from "store/program/programSelectors"
import themis_common from "store/themis_common_pb"
import { getAthlete, getAthleteTeams } from "store/program/athleteActions"
import { genderOptions } from "lib/constants"
import { format, parseISO } from "date-fns"
import { addAthleteToTeam, removeAthleteFromTeam } from "store/program/teamActions"
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import YesNoDialog from "components/util/YesNoModal"
import { removeGuardianFromAthlete } from "store/program/guardianActions"
import { removeAthleteFromLocation } from "store/program/locationActions"
import { validateLocationPermission, validateTeamPermission } from 'lib/permissions'
import { selectPermissionCodes, selectSuperUser, selectUserProgramPermissions } from 'store/user/userSelectors'

const Athlete: React.FC = (): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    athleteId: inputAthleteId,
    teamId: inputTeamId,
    locationId: inLocationId
  } = useParams<{ athleteId?: string | undefined, teamId?: string | undefined, locationId?: string | undefined }>()
  const athleteId = Number(inputAthleteId)
  const teamId = Number(inputTeamId)
  const locationId = Number(inLocationId)

  const program: themis_common.Program.AsObject | undefined = useSelector((state: IAppState) => selectCurrentProgram(state))
  const location: themis_common.Location.AsObject | undefined = useSelector((state: IAppState) => selectCurrentLocation(state))
  const programId = useMemo(() => program?.id || 0, [program])

  const [guardians, setGuardians] = useState<themis_common.Guardian.AsObject[]>([])
  const [athlete, setAthlete] = useState<themis_common.Athlete.AsObject | undefined>(undefined)
  const [teams, setTeams] = useState<number[]>([])
  const [guardianToDelete, setGuardianToDelete] = useState<themis_common.Guardian.AsObject | undefined>(undefined)
  const [reload, updateReload] = useState<number>(0)
  const [deleteAthleteIsOpen, setDeleteAthleteIsOpen] = useState<number>(0)

  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const updateTeams = useCallback(async () => {
    const teams = await getAthleteTeams(dispatch, athleteId, programId, locationId, teamId)
    setTeams(teams.map((team) => team.id))
  }, [athleteId, dispatch, locationId, programId, teamId])

  const doReload = () => {
    updateReload(reload + 1)
  }

  useEffect(() => {
    load()
    async function load() {
      if (!programId || !locationId || !athleteId) return
      try {
        const returnedAthlete = await getAthlete(dispatch, athleteId, programId, locationId, teamId)
        if (returnedAthlete) { // Is this needed?
          setAthlete(returnedAthlete)
          await updateTeams()
        }
      } catch (error) {
        console.log(error)
        history.goBack()
      }
    }
  }, [programId, locationId, athleteId, reload, dispatch, updateTeams, history, teamId])

  useEffect(() => {
    if (!athlete?.id) return
    setGuardians(athlete.athleteGuardiansList)
  }, [athlete])

  const handleToggle = useCallback(async (teamId: number, onTeamNow: boolean) => {
    if (onTeamNow) {
      await removeAthleteFromTeam(dispatch, programId, locationId, teamId, athleteId, false)
    } else {
      await addAthleteToTeam(dispatch, programId, locationId, teamId, athleteId, false)
    }
    await updateTeams()
  }, [athleteId, dispatch, locationId, programId, updateTeams])

  // This should be subbed out as a component. Maybe, "personTeamsList"?
  const teamList = useMemo(() => {
    if (!location?.teamsList || !teams) return []
    const unsortedTeamsList = [...location?.teamsList]
    const sortedTeamsList = unsortedTeamsList.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      return 0
    })
    return sortedTeamsList.map((team) => {
      const labelId = `checkbox-list-label-${team.id}`

      return (
        <ListItem key={team.id} role={undefined} dense button
          disabled={
            !((!teamId && validateLocationPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
            (teamId && validateTeamPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId)))
          }

          onClick={(_) => {
            handleToggle(team.id, teams.includes(team.id))
          }}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={teams.includes(team.id)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={team.name}/>
        </ListItem>
      )
    })
  }, [handleToggle, location?.teamsList, teams, locationId, programId, teamId, programPermissions, superUser, permissionCodes])

  const setDeleteGuardian = (guardian: themis_common.Guardian.AsObject): void => {
    setGuardianToDelete(guardian)
  }

  const clearDeleteGuardian = (): void => {
    setGuardianToDelete(undefined)
  }

  const handleRemoveGuardian = async (): Promise<void> => {
    if (!guardianToDelete?.id) return
    await removeGuardianFromAthlete(dispatch, guardianToDelete.id, athleteId, programId, locationId, teamId)
    clearDeleteGuardian()
    doReload()
  }

  const handleRemoveAthlete = async (): Promise<void> => {
    if (!deleteAthleteIsOpen) return
    await removeAthleteFromLocation(dispatch, locationId, programId, athleteId)
    history.replace(`/Location/${locationId}`)
  }

  const guardianList = useMemo(() => {
    if (!guardians) return []
    const items = guardians.map((guardian) => {
      const name = guardian.name > '' ? guardian.name : 'No Name On File'
      const guardianLink = teamId ? `/Location/${locationId}/Team/${teamId}/Athlete/${athleteId}/Guardian/${guardian.id}` : `/Location/${locationId}/Athlete/${athleteId}/Guardian/${guardian.id}`
      const guardianEditLink = teamId ? `/Location/${locationId}/Team/${teamId}/Athlete/${athleteId}/EditGuardian/${guardian.id}` : `/Location/${locationId}/Athlete/${athleteId}/EditGuardian/${guardian.id}`
      return <ListItem key={guardian.id} component={RouterLink} to={guardianLink}>
        <ListItemText className={classes.cleanLink} style={{ width: 300 }} primary={`${name}`}/>
        <ListItemSecondaryAction>
          {(!teamId && validateLocationPermission(permissionCodes.athleteDelete, permissionCodes, superUser, programPermissions, programId, locationId)) || (teamId && validateTeamPermission(permissionCodes.athleteDelete, permissionCodes, superUser, programPermissions, programId, locationId, teamId)) ?
            <IconButton className={classes.clickable} aria-label="delete" onClick={_ => {
              setDeleteGuardian(guardian)
            }}>
              { guardians.length > 1 ? <DeleteIcon fontSize="small"/> : [] }
            </IconButton>
            : <></>}
          {
            (!teamId && validateLocationPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
            (teamId && validateTeamPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId)) ?
              <IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={guardianEditLink}>
                <EditIcon fontSize="small"/>
              </IconButton>
              : <></>}
        </ListItemSecondaryAction>
      </ListItem>
    })
    return <List component="nav">{items}</List>
  }, [athleteId, classes, guardians, locationId, teamId, programId, programPermissions, superUser, permissionCodes])

  const headerContents = () => {
    if (athlete?.name) {
      return `Manage Athlete - ${athlete.name}`
    } else {
      return "Select Athlete to Continue"
    }
  }
  const addGuardianLink = teamId ? `/Location/${locationId}/Team/${teamId}/Athlete/${athleteId}/AddGuardian` : `/Location/${locationId}/Athlete/${athleteId}/AddGuardian`
  const editAthleteLink = teamId ? `/Location/${locationId}/Team/${teamId}/EditAthlete/${athleteId}` : `/Location/${locationId}/EditAthlete/${athleteId}`

  const editAthleteIcon = useMemo(() => {
    if (
      (!teamId && validateLocationPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
      (teamId && validateTeamPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
    ) {
      return (
        <IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={editAthleteLink}>
          <EditIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [classes.clickable, editAthleteLink, locationId, programId, programPermissions, superUser, teamId, permissionCodes])

  const deleteAthleteIcon = useMemo(() => {
    if (
      (!teamId && validateLocationPermission(permissionCodes.athleteDelete, permissionCodes, superUser, programPermissions, programId, locationId)) ||
      (teamId && validateTeamPermission(permissionCodes.athleteDelete, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
    ) {
      return (
        <IconButton className={classes.clickable} aria-label="delete" onClick={() => setDeleteAthleteIsOpen(athleteId)}>
          <DeleteIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [classes.clickable, athleteId, locationId, programId, programPermissions, superUser, teamId, permissionCodes])

  const addGuardianIcon = useMemo(() => {
    if (
      (!teamId && validateLocationPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId)) ||
      (teamId && validateTeamPermission(permissionCodes.athleteEdit, permissionCodes, superUser, programPermissions, programId, locationId, teamId))
    ) {
      return (
        <IconButton className={classes.clickable} aria-label="add guardian" component={RouterLink}
          to={addGuardianLink}>
          <AddIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [addGuardianLink, classes.clickable, programId, locationId, teamId, programPermissions, superUser, permissionCodes])

  return ( athlete?.person?.id && permissionCodes?.denyAccess.length ?
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>
            {headerContents()}
            {editAthleteIcon}
            {deleteAthleteIcon}
          </Typography>
        </Grid>
        <Grid item xs={1}/>
        <Grid item container justify="flex-start" xs={5}>
          <Card key={`names-${athlete.id}`} className={classes.cardSpacing}>
            <Grid container>
              <Grid item md={7}>
                <CardContent>
                  <Typography>First Name: {athlete.person.legalFirstName}</Typography>
                  <Typography>Middle Name: {athlete.person.legalMiddleName}</Typography>
                  <Typography>Last Name: {athlete.person.legalLastName}</Typography>
                  <Typography>Suffix: {athlete.person.suffix}</Typography>
                  <Typography>Nick Name: {athlete.person.nickName}</Typography>
                </CardContent>
              </Grid>
              <Grid item md={5}>
                {athlete.person.usasfProfilePicture ?
                  <img src={athlete.person.usasfProfilePicture} style={{ width: "120px", border: "1px solid red", marginTop: "20px" }} alt={`${athlete.person.name} USASF Profile`} />
                  : <></>
                }
              </Grid>
            </Grid>
          </Card>
          <Card key={`address-${athlete.id}`} className={classes.cardSpacing}>
            <CardContent>
              <Typography>Address: {athlete.person.address1}</Typography>
              <Typography>Address2: {athlete.person.address2}</Typography>
              <Typography>City: {athlete.person.city}</Typography>
              <Typography>State: {athlete.person.state}</Typography>
              <Typography>Postal Code: {athlete.person.postalCode}</Typography>
              <Typography>Country: {athlete.person.country}</Typography>
            </CardContent>
          </Card>
          <Card key={`contact-${athlete.id}`} className={classes.cardSpacing}>
            <CardContent>
              <Typography>Email: <a className={classes.cleanLink} target="_blank" rel="noreferrer" href={`mailto:${athlete.person.email}`}>{athlete.person.email}</a> </Typography>
              <Typography>Phone: <a className={classes.cleanLink} target="_blank" rel="noreferrer" href={`tel:${athlete.person.cellPhone}`}>{athlete.person.cellPhone}</a></Typography>
              <Typography>Home Phone: {athlete.person.homePhone}</Typography>
              <Typography>Gender: {(genderOptions.find((gender) => gender.value === athlete?.person?.gender))?.name}</Typography>
              <Typography>Birth Date: {athlete.person?.birthDate ? format(parseISO(athlete.person.birthDate), "MM/dd/yyyy") : ""}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5} container justify="flex-start">
          <Grid item xs={12} container justify="flex-start">
            <div className={classes.paper}>
              <Typography variant="h2">
                Guardians
                {addGuardianIcon}
              </Typography>
              {guardianList}
            </div>
          </Grid>
          <Grid item xs={12} container justify="flex-start">
            <div className={classes.paper}>
              <Typography variant="h2">Teams</Typography>
              <List>
                {teamList}
              </List>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <YesNoDialog
        title={`Remove Guardian ${guardianToDelete?.name}?`}
        question={`Are you sure you want to remove ${guardianToDelete?.name} from ${athlete?.name}?`}
        isOpen={!!guardianToDelete?.id}
        onClose={clearDeleteGuardian}
        buttonActions={[
          { name: "No", color: "primary", callback: clearDeleteGuardian },
          { name: "Yes", color: "primary", callback: handleRemoveGuardian },
        ]}

      />
      <YesNoDialog
        title={`Remove ${athlete.name} entirely from ${location?.name}?`}
        question={"This cannot be undone. Are you sure?"}
        isOpen={!!deleteAthleteIsOpen}
        onClose={() => {
          setDeleteAthleteIsOpen(0)
        }}
        buttonActions={[
          {
            name: "No", color: "primary", callback: () => {
              setDeleteAthleteIsOpen(0)
            }
          },
          { name: "Yes", color: "primary", callback: handleRemoveAthlete },
        ]}

      />
    </Container> : <></>)
}

export default Athlete
