import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { Grid, Switch, Typography } from '@material-ui/core'
import themis_common from "store/themis_common_pb"
import { getAthletePayReport } from "../../../../store/producer/eventActions"
import MUIDataTable from "mui-datatables"

interface AthletePayReportProps {
  eventId: number
  brandId: number
  producerId: number
}

interface TeamData {
  teamId: number
  programName: string
  teamName: string
  eventDivision: string
  fullyPaid: string
  totalOwed: number
  totalPaid: number
}

interface AthleteData {
  programName: string
  teamName: string
  eventDivision: string
  athleteName: string
  dob: string
  gender: string
  fullyPaid: string
  guardian1Name: string
  guardian1Cell: string
  guardian1Email: string
  guardian2Name: string
  guardian2Cell: string
  guardian2Email: string
  totalOwed: number
  totalPaid: number
}

const AthletePayReport: React.FC<AthletePayReportProps> = ({ eventId, brandId, producerId }): ReactElement => {
  const [reportData, setReportData] = useState<themis_common.Event.AsObject>()
  const [asTeam, setAsTeam] = useState(true)

  const teamData = useMemo<TeamData[]>(() => {
    if (!reportData?.teamsList) return []
    const teamData: TeamData[] = reportData.teamsList.sort((a, b) => a.name > b.name ? 1 : -1).map((team) => {
      const totalOwed = team.registrationTransactionsList.reduce((total, tx) => {
        return total + tx.amount
      }, 0)
      const totalPaid = team.registrationTransactionsList.reduce((total, tx) => {
        const payments = tx.paymentsList.reduce((payTotal, payment) => {
          if (payment.paidOrPending) {
            return payTotal + payment.amount
          } else {
            return payTotal
          }
        }, 0)
        return total + payments
      }, 0)
      return {
        programName: team.program?.name || "No Program Name",
        teamName: team.name,
        eventDivision: team.eventDivision?.name ? team.eventDivision?.name : (team.eventDivision?.division?.name ? team.eventDivision?.division?.name : "No Event Division Name"),
        teamId: team.id,
        fullyPaid: totalPaid >= totalOwed ? "YES" : "no",
        totalOwed: totalOwed,
        totalPaid: totalPaid
      }
    })
    return teamData
  }, [reportData])

  const athleteData = useMemo<AthleteData[]>(() => {
    let totalOwed = 0
    let totalPaid = 0
    if (!reportData?.teamsList) return []
    const athleteData: AthleteData[] = []
    reportData.teamsList.forEach((team) => {
      const teamPricing = !!team.eventDivision?.eventDivisionPricing?.teamPriceInt
      let paid: boolean | undefined = undefined
      if (teamPricing) {
        const teamXData = teamData.find(teamX => teamX.teamId === team.id)
        paid = teamXData?.fullyPaid === "YES" || false
        totalOwed = teamXData?.totalOwed || 0
        totalPaid = teamXData?.totalPaid || 0
      }
      team.athletesList.forEach((athlete) => {
        if (!teamPricing) {
          const theRegItem = team.registrationTransactionsList.find(regItem => regItem.athlete?.id === athlete.id)
          totalOwed = theRegItem?.amount || 0
          if (!theRegItem) paid = false
          else {
            const paidAmount = theRegItem.paymentsList.reduce((current, item) => {
              if (item.paidOrPending) return current + item.amount
              return current
            }, 0)
            paid = paidAmount >= theRegItem.amount
            totalPaid = paidAmount
          }
        }
        const guardian1 = athlete.athleteGuardiansList[0]
        const guardian2 = athlete.athleteGuardiansList[1]

        athleteData.push ({
          programName: team.program?.name || "No Program Name",
          teamName: team.name,
          eventDivision: team.eventDivision?.name ? team.eventDivision?.name : (team.eventDivision?.division?.name ? team.eventDivision?.division?.name : "No Event Division Name"),
          athleteName: `${athlete.person?.legalLastName}, ${athlete.person?.legalFirstName}`,
          dob: athlete.person?.birthDate || "",
          gender: athlete.person?.gender ? "Female" : "Male",
          fullyPaid: paid ? "YES" : "no",
          guardian1Name: guardian1?.name || "None Listed",
          guardian1Cell: guardian1?.person?.cellPhone || "",
          guardian1Email: guardian1?.person?.email || "",
          guardian2Name: guardian2?.name || "None Listed",
          guardian2Cell: guardian2?.person?.cellPhone || "",
          guardian2Email: guardian2?.person?.email || "",
          totalOwed: totalOwed,
          totalPaid: totalPaid
        })
      })
    })

    return athleteData.sort((a, b) => a.athleteName > b.athleteName ? 1 : -1)
  }, [reportData, teamData])

  const reportTitle = useMemo(() => asTeam ? "Team Pay Report" : "Athlete Pay Report", [asTeam])

  const columns = useMemo(() => asTeam ?
    [
      { label: "Program", name: "programName" },
      { label: "ID", name: "teamId" },
      { label: "Team", name: "teamName" },
      { label: "Event Division", name: "eventDivision" },
      { label: "Fully Paid", name: "fullyPaid" },
      { label: "Owed", name: "totalOwed" },
      { label: "Paid", name: "totalPaid" }
    ] : [
      { label: "Program", name: "programName" },
      { label: "Team", name: "teamName" },
      { label: "Event Division", name: "eventDivision" },
      { label: "Athlete Last, First", name: "athleteName", options: { sort: true } },
      { label: "DOB", name: "dob" },
      { label: "Gender", name: "gender" },
      { label: "Fully Paid", name: "fullyPaid" },
      { label: "Owed", name: "totalOwed" },
      { label: "Paid", name: "totalPaid" },
      { label: "Guardian 1", name: "guardian1Name" },
      { label: "Cell", name: "guardian1Cell" },
      { label: "Email", name: "guardian1Email" },
      // { label: "Guardian 2", name: "guardian2Name" },
      // { label: "Cell", name: "guardian2Cell" },
      // { label: "Email", name: "guardian2Email" }
    ]
  , [asTeam])

  const options = useMemo(() => { return {
    filter: true,
    print: true,
    rowsPerPage: 100,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: { filename: asTeam ? "TeamPayReport.csv" : "AthletePayReport.csv", separator: ',' }
  }}, [asTeam])

  const newData = useMemo(() => {
    if (asTeam) return teamData
    return athleteData
  }, [asTeam, teamData, athleteData])

  useEffect(() => {
    const getReportData = async () => {
      setReportData(await getAthletePayReport(eventId, brandId, producerId))
    }
    getReportData()
  }, [eventId, brandId, producerId])

  const report = useMemo(() => <Grid container spacing={3} justify="flex-start">
    <Grid item xs={12}>
      <span style={{ cursor: "pointer" }} onClick={(_) => { setAsTeam(false) }}>View As Athletes</span>
      <Switch checked={asTeam} onChange={(e) => { setAsTeam(e.target.checked)}} />
      <span style={{ cursor: "pointer" }} onClick={(_) => { setAsTeam(true) }}>View As Team</span>
    </Grid>
    <Grid item xs={12}>
      { newData ?
        <MUIDataTable
          title={reportTitle}
          data={newData}
          columns={columns}
          options={options}
        /> :
        <Typography>Loading data...</Typography> }

    </Grid>
  </Grid>, [asTeam, columns, newData, options, reportTitle])

  return report
}

export default AthletePayReport
