import React, { ReactElement } from 'react'
import { TextField } from '@material-ui/core'
import { AsFields, FormFields } from 'lib/constants'
import { handleInputChange } from "lib/validators"

interface FormTextFieldProps {
  formFields: FormFields,
  errorText: { [key: string]: string },
  setErrorText: Function,
  name: string,
  autoFocus?: boolean,
  sliceSize?: number
  updatedObject: AsFields,
  setUpdateObject: Function,
  inputProps?: object
  formId?: number
  disabled?: boolean
  type?: string | undefined
}

const FormTextField: React.FC<FormTextFieldProps> = ({
  formFields, name, autoFocus, sliceSize,
  errorText, setErrorText, updatedObject,
  setUpdateObject, inputProps, formId, disabled,
  type
}): ReactElement => {

  return (
    <TextField
      variant="outlined"
      margin="normal"
      required={formFields[name].required}
      label={formFields[name].label}
      error={!!errorText[name]}
      helperText={errorText[name]}
      fullWidth
      id={`${name}-${formId}`}
      value={updatedObject[name]}
      name={name}
      autoComplete={name}
      onChange={(e) => { handleInputChange(e, formFields, updatedObject, setUpdateObject, errorText, setErrorText) }}
      autoFocus={autoFocus}
      inputProps={{ ...inputProps, autoComplete: "new-password" }}
      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (sliceSize && sliceSize > 0) e.target.value = e.target.value.slice(0, sliceSize)
      }}
      disabled={disabled}
      type={type}
    />
  )

}

export default FormTextField
