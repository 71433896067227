import React, { ChangeEvent, ReactElement, useState, useMemo, useEffect, useCallback } from 'react'
import { useStyles } from "lib/theme"
import { Link as RouterLink } from "react-router-dom"
import {
  AppBar, Button,
  Container,
  Grid,
  IconButton,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import EventRegCodes from "./EventRegCodes"
import EventDates from "./EventDates"
import EventFloors from "./EventFloors"
import { TabPanel } from "lib/pieces"
import YesNoDialog from "components/util/YesNoModal"
import UserGrid from "components/user/user_grid/UserGrid"
import { PermissionLists } from 'store/user/userActions'
import { ProducerPermissions } from 'store/user/userReducer'
import EventDivisionGrid from "./EventDivisionGrid"
import themis_common from "store/themis_common_pb"
import EventPricing from "./EventPricing"
import { validateEventPermission } from "lib/permissions"
import { Alert } from "@material-ui/lab"
import EventRegistrationActivity from "./EventRegistrationActivity"
import ReportsContainer from "../reports/ReportsContainer"

import EventRegistrations from "./EventRegistrations"
import EventSchedule from "./EventSchedule"
import DocumentsContainer from "../documents/DocumentsContainer"
import { useDispatch } from "react-redux"
import { AnyDispatchType } from 'lib/constants'
import { generateTestTeamsForEvent } from "../../../store/producer/eventActions"
import { teamDivisionError } from 'lib/validators'
import { CSVDownload } from 'react-csv'
import { getAthletesAndGuardians } from '../../../store/producer/eventActions'
import { render } from '@testing-library/react'
import { useCsvUploadState } from 'components/util/CsvUploadContext'
import EventReports from "./reports/EventReports"
import { IDivision } from './EventContainer'

interface EventProps {
  superUser: boolean
  producerPermissions: ProducerPermissions
  permissionLists: PermissionLists
  brandId: number
  eventId: number
  producer: themis_common.Producer.AsObject
  brand: themis_common.Brand.AsObject
  brands: themis_common.Brand.AsObject[]
  event: themis_common.Event.AsObject
  events: themis_common.Event.AsObject[]
  currentTab: number
  eventDivisions: themis_common.EventDivision.AsObject[],
  eventDivisionUses: themis_common.EventDivisionUsage.AsObject[],
  setEventDivisionUses: Function,
  handleChangeTab: (event: ChangeEvent<{}>, value: number)=>void
  handleDelete: Function
  isEventOpen: boolean
  setIsEventOpen?: (value: boolean | ((prevVar: boolean) => boolean)) => void,
  eventSeasons: themis_common.Season.AsObject[], // Currently unused
  currentDivisions: themis_common.Division.AsObject[],
  unusedDivisions: themis_common.Division.AsObject[],
  refreshDivisions: number,
  addDivision: Function,
  editEventDivision: Function,
  removeDivision: Function,
  addAllDivisions: Function,
  removeAllDivisions: Function,
  setRefreshDivisions: React.Dispatch<React.SetStateAction<number>>,
  refreshEvent: Function,
  setRegistrationLive: Function
  permissionCodes: themis_common.PermissionCodes.AsObject
  dispatch: AnyDispatchType
  currentTeam: themis_common.EventTeam.AsObject | undefined
  setCurrentTeam: React.Dispatch<React.SetStateAction<themis_common.EventTeam.AsObject | undefined>>
  submitChangeDivision: () => void
  setSelectedEventTeamDivision: React.Dispatch<React.SetStateAction<themis_common.EventDivision.AsObject>>
  setSelectedEventTeamRegCode: React.Dispatch<React.SetStateAction<string>>
  setFoundRegistrationDiscountCode: React.Dispatch<React.SetStateAction<themis_common.EventRegistrationCode.AsObject>>
  setChangeDivisionDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  foundRegistrationDiscountCode: themis_common.EventRegistrationCode.AsObject
  selectedEventTeamDivision: themis_common.EventDivision.AsObject
  changeDivisionDialogOpen: boolean
  selectedEventTeamRegCode: string
  changeDivisionError: boolean
  setChangeDivisionError: React.Dispatch<React.SetStateAction<boolean>>
  teamErrors: teamDivisionError[] | undefined
  // rubrics: themis_common.Rubric.AsObject[] | undefined
  getEventDivisions: (eventId: number, brandId: number, producerId: number) => Promise<themis_common.EventDivision.AsObject[]>
  setEventDivisions: React.Dispatch<React.SetStateAction<themis_common.EventDivision.AsObject[]>>
  setDivision:React.Dispatch<React.SetStateAction<IDivision>>
}

interface personData {
  lastName: string | undefined
  firstName: string | undefined
  cellPhoneNumber: string | undefined
  homePhoneNumber: string | undefined
  email: string | undefined
  address: string | undefined
  city: string | undefined
  state: string | undefined
  postalCode: string | undefined
}

const EventPage: React.FC<EventProps> = ({
  superUser, producerPermissions,
  permissionLists, brandId, eventId, producer,
  brand, brands, event, events, currentTab, eventDivisions, eventDivisionUses, setEventDivisionUses, handleChangeTab,
  handleDelete, isEventOpen, setIsEventOpen, eventSeasons,
  currentDivisions, unusedDivisions, addDivision, editEventDivision, removeDivision, addAllDivisions, removeAllDivisions, refreshDivisions,
  setRefreshDivisions, refreshEvent, setRegistrationLive, permissionCodes,
  currentTeam, submitChangeDivision, setCurrentTeam, setSelectedEventTeamDivision, setSelectedEventTeamRegCode,
  setFoundRegistrationDiscountCode, setChangeDivisionDialogOpen, foundRegistrationDiscountCode, selectedEventTeamDivision,
  changeDivisionDialogOpen, selectedEventTeamRegCode, changeDivisionError, setChangeDivisionError, teamErrors, getEventDivisions, setEventDivisions,
  setDivision,
}): ReactElement => {  //rubrics
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const headerContents = () => {
    if (event?.name ) {
      return `Manage Event - ${event.name}`
    } else {
      return "Select Event to Continue"
    }
  }

  const userCanViewEvent = useMemo(() => {
    return validateEventPermission(permissionCodes.eventView, permissionCodes, superUser, producerPermissions, producer.id, brandId, eventId)
  }, [superUser, producerPermissions, producer.id, brandId, eventId, permissionCodes])

  const userCanEditEvent = useMemo(() => {
    return validateEventPermission(permissionCodes.eventEdit, permissionCodes, superUser, producerPermissions, producer.id, brandId, eventId)
  }, [superUser, producerPermissions, producer.id, brandId, eventId, permissionCodes])

  const userCanDeleteEvent = useMemo(() => {
    return validateEventPermission(permissionCodes.eventDelete, permissionCodes, superUser, producerPermissions, producer.id, brandId, eventId)
  }, [superUser, producerPermissions, producer.id, brandId, eventId, permissionCodes])

  const userCanEditFinances = useMemo(() => {
    return validateEventPermission(permissionCodes.financesEdit, permissionCodes, superUser, producerPermissions, producer.id, brandId, eventId)
  }, [superUser, producerPermissions, producer.id, brandId, eventId, permissionCodes])

  const userCanViewFinances = useMemo(() => {
    return validateEventPermission(permissionCodes.financesView, permissionCodes, superUser, producerPermissions, producer.id, brandId, eventId)
  }, [superUser, producerPermissions, producer.id, brandId, eventId, permissionCodes])

  const [isErrorOpen, setIsErrorOpen] = useState(false)
  const [teamsToGen, setTeamsToGen] = useState(0)
  const [isGenerating, setIsGenerating] = useState(false)
  const [downloadCsv, setDownloadCsv] = useState(false)
  const [renderCsvTrigger, setRenderCsvTrigger] = useState(false)
  const [data, setData] = useState<personData[]>([])

  const editEventIcon = useMemo(() => {
    // If check: Producer level > Brand level > Event level
    if (userCanEditEvent) {
      // if(producer && brandId && (permissions[producer.id]?.perms.includes("AEE") || permissions[producer.id]?.perms.includes("S") || permissions[producer.id]?.brands[brandId]?.perms.includes("EE") || permissions[producer.id]?.brands[brandId]?.perms.includes("S") || permissions[producer.id]?.brands[brandId]?.events[eventId]?.perms.includes("E") || permissions[producer.id]?.brands[brandId]?.events[eventId]?.perms.includes("S"))) {
      return (
        <IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={`/Brand/${brand?.id}/EditEvent/${event?.id}`}>
          <EditIcon fontSize="small" />
        </IconButton>
      )
    } else {
      return []
    }
  }, [userCanEditEvent, classes.clickable, brand?.id, event?.id])

  const deleteEventIcon = useMemo(() => {
    // If check: Producer level > Brand level > Event level
    if (userCanDeleteEvent) {
      // if(producer && brandId && (permissions[producer.id]?.perms.includes("AED") || permissions[producer.id]?.perms.includes("S") || permissions[producer.id]?.brands[brandId]?.perms.includes("ED") || permissions[producer.id]?.brands[brandId]?.perms.includes("S") || permissions[producer.id]?.brands[brandId]?.events[eventId]?.perms.includes("D") || permissions[producer.id]?.brands[brandId]?.events[eventId]?.perms.includes("S"))) {
      return (
        <IconButton className={classes.clickable} aria-label="delete" onClick={() => { if (setIsEventOpen) setIsEventOpen(true) }}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )
    } else {
      return []
    }
  }, [userCanDeleteEvent, classes.clickable, setIsEventOpen])

  const eventIssues = useMemo<string[]>(() => {
    const outArray: string[] = []
    if (event.eventDivisionList.length === 0) outArray.push("All events must have at least one division.")

    const allPriced = event.eventDivisionList.reduce((prev: boolean, curr) => {
      return (curr.eventDivisionPricing !== undefined && (Number(curr.eventDivisionPricing.teamPriceString) > 0 || Number(curr.eventDivisionPricing?.athletePriceString) > 0)) && prev
    }, true)

    if (!allPriced && !(event.eventPricing && Number(event.eventPricing.athletePriceString) > 0)) outArray.push("Events must have pricing or eventwide pricing must be set.")

    if (event.eventDatesList.length === 0) outArray.push("All events must have at least one date scheduled.")
    return outArray
  }, [event.eventDivisionList, event.eventPricing, event.eventDatesList])

  const generateTeamData = useCallback(() => {
    const doGenerate = async () => {
      setIsGenerating(true)
      await generateTestTeamsForEvent(eventId, teamsToGen)
      setIsGenerating(false)
    }
    doGenerate()
  }, [eventId, teamsToGen])

  const generateTestTeamData = useMemo(() => {
    return <>
      <Typography style={{ marginTop: 8, marginLeft: 16, marginRight: 16 }}>Generate Test Team Data: </Typography>
      <TextField type="number" style = {{ width: 80 }} onChange={ (e) => {setTeamsToGen(+e.target.value) }} />
      <Button disabled={ false } variant="contained" size="small" style={{ marginLeft: 16 }} onClick={ () => { generateTeamData() } } >{isGenerating ? "Generating, This could take up to half an hour." : "Generate" }</Button>
    </>
  }, [generateTeamData, isGenerating])

  useEffect (() => {
    if (downloadCsv){
      setTimeout(() => {
        setDownloadCsv(false)
      }, 3000)
    }
  }, [downloadCsv])

  const downloadRender = useMemo(() => {
    const headers = [
      { label: "Last Name", key: "lastName" },
      { label: "First Name", key: "firstName" },
      { label: "Cell Phone Number", key: "cellPhoneNumber" },
      { label: "Home Phone Number", key: "homePhoneNumber" },
      { label: "Email", key: "email" },
      { label: "Address", key: "address" },
      { label: "City", key: "city" },
      { label: "State", key: "state" },
      { label: "Postal Code", key: "postalCode" },
    ]
    if (!data.length) return <Typography>Loading Data...</Typography>
    return downloadCsv ? <CSVDownload data={data} headers={headers} target="_blank"/> : []
  },[data, downloadCsv])

  useEffect(() => {
    const getAtheletData = async () => {
      const csvData = await getAthletesAndGuardians(event.id, brand.id, producer.id)
      const localData: personData[] = []
      csvData.athletesList.forEach(athlete => {
        localData.push({
          lastName: athlete.person?.legalLastName,
          firstName: athlete.person?.legalFirstName,
          cellPhoneNumber: athlete.person?.cellPhone,
          homePhoneNumber: athlete.person?.homePhone,
          email: athlete.person?.email,
          address: athlete.person?.address1,
          city: athlete.person?.city,
          state: athlete.person?.state,
          postalCode: athlete.person?.postalCode,
        })
        if (!!athlete.athleteGuardiansList.length){
          athlete.athleteGuardiansList.forEach(guardian => {
            localData.push({
              lastName: " -" + guardian.person?.legalLastName,
              firstName: guardian.person?.legalFirstName,
              cellPhoneNumber: guardian.person?.cellPhone,
              homePhoneNumber: guardian.person?.homePhone,
              email: guardian.person?.email,
              address: guardian.person?.address1,
              city: guardian.person?.city,
              state: guardian.person?.state,
              postalCode: guardian.person?.postalCode,
            })
          })
        }
      })
      setData(localData)
    }
    getAtheletData()
  }, [downloadCsv])

  return userCanViewEvent && permissionCodes?.denyAccess.length ?
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid container item xs={12} alignItems="flex-start">
          <Grid item container xs={8} justify="flex-start">
            <Typography variant="h1" className={classes.headerTypo}>{headerContents()}</Typography>
            {editEventIcon}
            {deleteEventIcon}
            { superUser ? generateTestTeamData : [] }
          </Grid>
          <Grid item container xs={1}>
            {downloadRender}
            <Button size="small" variant="outlined" disabled={downloadCsv} onClick={() => {setDownloadCsv(true)}}>Athlete Info</Button>
          </Grid>
          <Grid item container xs={3} justify="flex-end">
            {eventIssues.length === 0 ?
              <>
                <Typography className={classes.openRegTitle}>Registration Open?</Typography>
                <span className={classes.openRegOption} onClick={(_) => {
                  if (eventIssues.length === 0) setRegistrationLive(eventId, false)
                }}>No</span>
                <Switch disabled={eventIssues.length > 0} checked={event.registrationLive} color="primary" onChange={(e) => {
                  setRegistrationLive(eventId, e.target.checked)
                }}/>
                <span className={classes.openRegOption} onClick={(_) => {
                  if (eventIssues.length === 0) setRegistrationLive(eventId, true)
                }}>Yes</span>
              </> :
              <>
                { eventIssues.map((issue, i) => <Alert key={i} severity="warning">{issue}</Alert>)}
              </>
            }
          </Grid>
          <AppBar position="static" style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }}>
            <Tabs value={currentTab} onChange={handleChangeTab} variant="scrollable">
              <Tab label="Registrations" id="tab-0" />
              <Tab label="Dates" id="tab-1" />
              <Tab label="Floors" id="tab-2" />
              <Tab label="Add Divisions" id="tab-3" />
              <Tab label="Divisions" id="tab-4" />
              <Tab label="Discount Codes" id="tab-5" />
              <Tab label="Documents" id="tab-6" />
              <Tab label="Users" id="tab-7" disabled={!validateEventPermission(permissionCodes.eventUserView, permissionCodes, superUser, producerPermissions, producer.id, brandId, eventId)} />
              <Tab label="Activity Feed" id="tab-8" aria-label="activity feed" disabled={!validateEventPermission(permissionCodes.eventUserView, permissionCodes, superUser, producerPermissions, producer.id, brandId, eventId)} />
              <Tab label="Reports" id="tab-9" aria-label="reports"  disabled={!validateEventPermission(permissionCodes.eventUserView, permissionCodes, superUser, producerPermissions, producer.id, brandId, eventId)} />
              <Tab label="Schedule" id="tab-10" />
            </Tabs>
          </AppBar>
          <TabPanel value={currentTab} index={0}>
            <EventRegistrations
              eventDivisions={eventDivisions}
              event={event}
              userCanViewEvent={userCanViewEvent}
              userCanEditEvent={userCanEditEvent}
              currentTeam={currentTeam}
              dispatch={dispatch}
              setCurrentTeam={setCurrentTeam}
              submitChangeDivision={submitChangeDivision}
              setSelectedEventTeamDivision={setSelectedEventTeamDivision}
              setSelectedEventTeamRegCode={setSelectedEventTeamRegCode}
              setFoundRegistrationDiscountCode={setFoundRegistrationDiscountCode}
              setChangeDivisionDialogOpen={setChangeDivisionDialogOpen}
              foundRegistrationDiscountCode={foundRegistrationDiscountCode}
              selectedEventTeamDivision={selectedEventTeamDivision}
              changeDivisionDialogOpen={changeDivisionDialogOpen}
              selectedEventTeamRegCode={selectedEventTeamRegCode}
              changeDivisionError={changeDivisionError}
              setChangeDivisionError={setChangeDivisionError}
              userCanEditFinances={userCanEditFinances}
              userCanViewFinances={userCanViewFinances}
              brandId={brandId}
              producerId={producer.id}
              setRefreshDivisions={setRefreshDivisions}
              teamErrors={teamErrors}
              producer={producer}
              setEventDivisions={setEventDivisions}
              setDivision={setDivision}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <EventDates producerId={producer?.id || 0} brandId={Number(brandId)} eventId={Number(eventId)} userCanViewEvent={userCanViewEvent} userCanEditEvent={userCanEditEvent} event={event}/>
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <EventFloors producerId={producer?.id || 0} brandId={Number(brandId)} eventId={Number(eventId)} userCanViewEvent={userCanViewEvent} userCanEditEvent={userCanEditEvent} />
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <EventDivisionGrid currentDivisions={currentDivisions} unusedDivisions={unusedDivisions} addDivision={addDivision} removeDivision={removeDivision} eventSeasons={eventSeasons} setRefreshDivisions={setRefreshDivisions} refreshDivisions={refreshDivisions} userCanViewEvent={userCanViewEvent} userCanEditEvent={userCanEditEvent}  addAllDivisions={addAllDivisions} removeAllDivisions={removeAllDivisions}/>
          </TabPanel>
          <TabPanel value={currentTab} index={4}>
            <EventPricing event={event} brandId={brandId} producerId={producer.id} refreshEvent={refreshEvent} editEventDivision={editEventDivision} userCanViewEvent={userCanViewEvent} userCanEditEvent={userCanEditEvent} dispatch={dispatch} /> 
            {/* rubrics={rubrics} */}
          </TabPanel>
          <TabPanel value={currentTab} index={5}>
            <EventRegCodes producerId={producer?.id || 0} brandId={Number(brandId)} eventId={Number(eventId)} currency={event.currency} userCanViewEvent={userCanViewEvent} userCanEditEvent={userCanEditEvent} />
          </TabPanel>
          <TabPanel value={currentTab} index={6}>
            <DocumentsContainer producer={producer} brands={brands} events={events} brandId={brandId} eventId={eventId} />
          </TabPanel>
          <TabPanel value={currentTab} index={7}>
            {!permissionLists || !validateEventPermission(permissionCodes.eventUserView, permissionCodes, superUser, producerPermissions, producer.id, brandId, eventId) ? [] :
              <UserGrid type="event" superDuperObjectId={producer?.id} superObjectId={brandId} objectId={eventId}
                permissionLists={permissionLists} />
            }
          </TabPanel>
          <TabPanel index={8} value={currentTab}>
            <EventRegistrationActivity eventId={eventId} brandId={brandId} producerId={producer.id} eventName={event?.name + ` (${event?.firstDay})`} />
          </TabPanel>
          <TabPanel index={9} value={currentTab}>
            <EventReports eventId={eventId} brandId={brandId} producerId={producer.id} />
          </TabPanel>
          <TabPanel index={10} value={currentTab}>
            <EventSchedule producerId={producer?.id || 0} brandId={Number(brandId)} eventId={Number(eventId)} userCanViewEvent={userCanViewEvent} userCanEditEvent={userCanEditEvent} eventDivisions={eventDivisions} eventDivisionUses={eventDivisionUses} setEventDivisionUses={setEventDivisionUses} event={event} />
          </TabPanel>
        </Grid>
        <YesNoDialog
          title={`Delete ${event?.name}?`}
          question={`Deleting an event can not be undone, are you sure you want to delete ${event?.name}?`}
          isOpen={isEventOpen}
          onClose={() => {if (setIsEventOpen) setIsEventOpen(false)}}
          buttonActions={[
            { name: "No", color: "primary", callback: () => {if (setIsEventOpen) setIsEventOpen(false) } },
            { name: "Yes", color: "primary", callback: handleDelete },
          ]}
        />
        <YesNoDialog
          title={"Error"}
          question={"There was an error, please contact us if this continues to be a problem."}
          isOpen={isErrorOpen}
          onClose={() => setIsErrorOpen(false)}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
          ]}
        />
      </Grid>
    </Container> : <></>
}

export default EventPage
