import { useEffect, useState } from "react"
import RubricScoringForm from "./rubricScoring/RubricScoring"
import { Button } from "@material-ui/core"
import { useStyles } from "lib/theme"

interface JudgeCoordinatorContainerProps {
    judgeCoordinatorData: any;
    handleReceivedResponse: Function;
    handleRubricScore: Function;
}

const JudgeCoordinatorContainer: React.FC<JudgeCoordinatorContainerProps> = ({
  judgeCoordinatorData,
  handleReceivedResponse,
  handleRubricScore
}) => {
  const classes = useStyles()

  const [formsByJudge, setFormsByJudge] = useState({})

  // useEffect hook to compute formsByJudge whenever judgeCoordinatorData changes
  useEffect(() => {
    if (!judgeCoordinatorData || !judgeCoordinatorData.scoreAndJudgeTypesList) return

    const formsByJudgeTemp: any = {}

    // Iterate through each rubric area
    judgeCoordinatorData.scoreAndJudgeTypesList.forEach((rubricArea: any) => {
      // Extract unique judge names
      const uniqueJudgeNames = Array.from(new Set(rubricArea.judgeTypesList.map((judgeType: any) => judgeType.eventJudgeShift.judgeId)))

      // For each unique judge name, create an array to hold the scoring forms
      uniqueJudgeNames.forEach((judgeId: any) => {
        // Check if the judge name exists in the object, if not, initialize an empty array
        if (!formsByJudgeTemp[judgeId]) {
          formsByJudgeTemp[judgeId] = []
        }

        // Push the RubricScoringForm component for the current judge name
        formsByJudgeTemp[judgeId].push(
          <RubricScoringForm
            key={rubricArea.score} // You might want to use a unique key here
            sheetData={JSON.parse(rubricArea.rubricDynamicFromJson)}
            judgeType={rubricArea.judgeTypesList.map((judgeType: any) => judgeType?.producerJudgeType?.name)}
            initialJsonValues={JSON.parse(rubricArea.dynamicJson)}
            responseData={handleReceivedResponse}
          />
        )
      })
    })

    // Set the formsByJudge state
    setFormsByJudge(formsByJudgeTemp)
  }, [judgeCoordinatorData])

  const findJudgeById = (judgeId: number) => {
    // Iterate through the scoreAndJudgeTypesList array
    for (const rubricArea of judgeCoordinatorData.scoreAndJudgeTypesList) {
      // Search for the judgeId within the judgeTypesList array of each rubricArea
      for (const judgeType of rubricArea.judgeTypesList) {
        // If the judgeId matches, return the judge obj
        if (judgeType.eventJudgeShift.judgeId === judgeId) {
          return judgeType
        }
      }
    }
    // Return null if no matching judgeId is found
    return null
  }

  // Render fieldsets for each judge with associated scoring forms
  return (
    <div>
      {Object.entries(formsByJudge).map(([judgeId, forms]) => {
        const judge = findJudgeById(parseInt(judgeId))

        return (
          <fieldset key={judgeId}>
            <legend style={{ fontSize: '20px' }}>{judge?.eventJudgeShift?.judgeName}</legend>
            {forms}
            <Button
              variant="contained"
              className={classes.divisionSubmit}
              style={{ width: '100%' }}
              onClick={() => {
                handleRubricScore(judge)
              }}
            >
                            Submit Score
            </Button>
          </fieldset>
        )
      })}
    </div>
  )
}

export default JudgeCoordinatorContainer
