import { Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { useStyles } from "lib/theme";

interface HelperTextComponentProps {
    element: any
}
const HelperTextComponent: React.FC<HelperTextComponentProps> = ({ element }) => {
    const classes = useStyles();
    return (element.showHelpText) ?
        (<Tooltip title={<span className={classes.helperText}>{element.helpText}</span>} >
            <InfoOutlined
                color="action"
                className={classes.infoIcon}
            />
        </Tooltip>) : null;
};

export default HelperTextComponent;
