import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from 'App'
import { dark } from 'theme'
import configureStore from 'store/store'
import Splash from "components/Splash"
import Login from "components/user/Login"
import AddEditProgramContainer from "components/program/AddEditProgramContainer"
import AddEditLocationContainer from "components/program/location/AddEditLocationContainer"
import AddEditProducerContainer from "components/producer/AddEditProducerContainer"
import ScrapeUSASF from "components/program/ScrapeUSASF"
import Account from "components/user/Account"
import AddEditBrand from "components/producer/brand/AddEditBrand"
import AddEditEvent from "components/producer/event/AddEditEvent"
import AddEditTeamContainer from "components/program/team/AddEditTeamContainer"
import Athlete from "components/program/athlete/Athlete"
import Guardian from "components/program/athlete/guardian/Guardian"
import CoachContainer from "components/program/coach/CoachContainer"
import AddEditAthlete from "components/program/athlete/AddEditAthlete"
import AddAthleteList from "components/program/athlete/AddAthleteList"
import AddEditGuardian from "components/program/athlete/guardian/AddEditGuardian"
import AddEditCoach from "components/program/coach/AddEditCoach"
import AddEditPersonnel from "components/program/personnel/AddEditPersonnel"
import AddEditUser from "components/user/AddEditUser"
import Personnel from "components/program/personnel/Personnel"
import ProgramContainer from "components/program/ProgramContainer"
import LocationContainer from "components/program/location/LocationContainer"
import TeamContainer from 'components/program/team/TeamContainer'
import ProducerContainer from 'components/producer/ProducerContainer'
import BrandContainer from 'components/producer/brand/BrandContainer'
import EventContainer from 'components/producer/event/EventContainer'
import AddEditJudge from "components/producer/judge/AddEditJudge"
import Judge from "components/producer/judge/Judge"
import RegisterContainer from 'components/user/RegisterContainer'
import EventRegistrationContainer from "components/program/event_registration/EventRegistrationContainer"
import Onboard from "components/user/Onboard"
import EventProducers from 'components/producer/EventProducers'
import CreateStripeProducerAccount from 'components/producer/producerStripeAccount/CreateStripeProducerAccount'
import ShareLogin from 'components/user/ShareLogin'
import AccessCodeLogin from 'components/user/AccessCodeLogin'
import ScoreCardContainer from 'components/judge/rubricScoring/ScoreCardContainer'
import "typeface-open-sans"

import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import EventRegistrationPaymentContainer from 'components/program/payment/EventRegistrationPaymentContainer'
import LoadStripe from 'LoadStripe'
import ManagePaymentSources from 'components/program/payment/ManagePaymentSources'
import AddPaymentSource from 'components/program/payment/AddPaymentSource'
import SignDocumentContainer from "./components/signature/SignDocumentContainer"
import { PasswordReset } from "./components/user/PasswordReset"
import Fix18 from "./components/super/Fix18"
import JudgeEventContainer from "./components/judge/JudgeEventContainer"
import ShiftReport from "./components/judge/reports/ShiftReport"
import TeamReport from "./components/judge/reports/TeamReport"
import EventScheduleReport from 'components/producer/event/EventScheduleReport'
import NotFoundPage from 'components/NotFoundPage'
import SampleRubricScoringComponent from 'components/judge/rubricScoring/SampleRubricScoring'
import FormBuilder from 'components/judge/rubricScoring/formBuilder/FormBuilder'

const store = configureStore()
const darkState = localStorage.getItem('darkState') || 'dark'
const theme = darkState === 'dark' ? dark : dark

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LoadStripe>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Router>
          <App />
          <div className="App">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Switch>
                /* Super User routes */
                <Route path="/super/fix18" exact component={Fix18} />

                /* Regular routes */
                <Route path="/" exact component={Splash} />
                <Route path="/Login" exact component={Login} />
                <Route path="/Register" exact component={RegisterContainer} />
                <Route path="/Account" exact component={Account} />
                <Route path="/ManagePaymentMethods" exact component={ManagePaymentSources} />
                <Route path="/AddPaymentMethod" exact component={AddPaymentSource} />
                <Route path="/ShareLogin" exact component={ShareLogin} />
                <Route path="/AccessCodeLogin" exact component={AccessCodeLogin} />
                <Route path="/PasswordReset" exact component={PasswordReset} />
                <Route path="/PasswordReset/:code" exact component={PasswordReset} />
                <Route path="/ScheduleReport/Event/:eventId" exact component={EventScheduleReport} />
                <Route path="/JudgeEvent/:eventId" exact component={JudgeEventContainer} />

                <Route path="/AddProgram" exact component={AddEditProgramContainer} />
                <Route path="/EditProgram/:programId" exact component={AddEditProgramContainer} />
                <Route path="/Program/:programId" exact component={ProgramContainer} />

                <Route path="/ScoreCard" exact component={ScoreCardContainer} />

                <Route path="/Program/:programId/AddLocation" exact component={AddEditLocationContainer} />
                <Route path="/EditLocation/:locationId" exact component={AddEditLocationContainer} />
                <Route path="/Location/:locationId" exact component={LocationContainer} />

                <Route path="/Location/:locationId/AddTeam" exact component={AddEditTeamContainer} />
                <Route path="/Location/:locationId/EditTeam/:teamId" exact component={AddEditTeamContainer} />
                <Route path="/Location/:locationId/Team/:teamId" exact component={TeamContainer} />

                <Route path="/Location/:locationId/AddAthlete" exact component={AddEditAthlete} />
                <Route path="/Location/:locationId/EditAthlete/:athleteId" exact component={AddEditAthlete} />
                <Route path="/Location/:locationId/Team/:teamId/AddAthlete" exact component={AddEditAthlete} />
                <Route path="/Location/:locationId/Team/:teamId/EditAthlete/:athleteId" exact component={AddEditAthlete} />
                <Route path="/Location/:locationId/Athlete/:athleteId" exact component={Athlete} />
                <Route path="/Location/:locationId/Team/:teamId/Athlete/:athleteId" exact component={Athlete} />
                <Route path="/Location/:locationId/Team/:teamId/AddTeamAthletes" exact component={AddAthleteList} />

                <Route path="/Location/:locationId/Athlete/:athleteId/Guardian/:guardianId" exact component={Guardian} />
                <Route path="/Location/:locationId/Team/:teamId/Athlete/:athleteId/Guardian/:guardianId" exact component={Guardian} />
                <Route path="/Location/:locationId/Athlete/:athleteId/AddGuardian" exact component={AddEditGuardian} />
                <Route path="/Location/:locationId/Team/:teamId/athlete/:athleteId/AddGuardian" exact component={AddEditGuardian} />
                <Route path="/Location/:locationId/Athlete/:athleteId/EditGuardian/:guardianId" exact component={AddEditGuardian} />
                <Route path="/Location/:locationId/Team/:teamId/Athlete/:athleteId/EditGuardian/:guardianId" exact component={AddEditGuardian} />

                <Route path="/Location/:locationId/AddCoach" exact component={AddEditCoach} />
                <Route path="/Location/:locationId/EditCoach/:coachId" exact component={AddEditCoach} />
                <Route path="/Location/:locationId/Team/:teamId/AddCoach" exact component={AddEditCoach} />
                <Route path="/Location/:locationId/Team/:teamId/EditCoach/:coachId" exact component={AddEditCoach} />
                <Route path="/Location/:locationId/Coach/:coachId" exact component={CoachContainer} />
                <Route path="/Location/:locationId/Team/:teamId/coach/:coachId" exact component={CoachContainer} />

                <Route path="/Location/:locationId/AddPersonnel" exact component={AddEditPersonnel} />
                <Route path="/Location/:locationId/EditPersonnel/:personnelId" exact component={AddEditPersonnel} />
                <Route path="/Location/:locationId/Team/:teamId/AddPersonnel" exact component={AddEditPersonnel} />
                <Route path="/Location/:locationId/Team/:teamId/EditPersonnel/:personnelId" exact component={AddEditPersonnel} />
                <Route path="/Location/:locationId/Personnel/:personnelId" exact component={Personnel} />
                <Route path="/Location/:locationId/Team/:teamId/Personnel/:personnelId" exact component={Personnel} />

                <Route path="/AddProducer" exact component={AddEditProducerContainer} />
                <Route path="/EditProducer/:producerId" exact component={AddEditProducerContainer} />
                <Route path="/Producer/:producerId" exact component={ProducerContainer} />
                <Route path="/Producer/:producerId/CreateMerchantAccount" exact component={CreateStripeProducerAccount} />

                <Route path="/Producer/:producerId/AddBrand" exact component={AddEditBrand} />
                <Route path="/EditBrand/:brandId" exact component={AddEditBrand} />
                <Route path="/Brand/:brandId" exact component={BrandContainer} />

                <Route path="/Brand/:brandId/AddEvent" exact component={AddEditEvent} />
                <Route path="/Brand/:brandId/EditEvent/:eventId" exact component={AddEditEvent} />
                <Route path="/Brand/:brandId/Event/:eventId" exact component={EventContainer} />

                <Route path="/Brand/:brandId/AddJudge" exact component={AddEditJudge} />
                <Route path="/Brand/:brandId/EditJudge/:judgeId" exact component={AddEditJudge} />
                <Route path="/Producer/:producerId/AddJudge" exact component={AddEditJudge} />
                <Route path="/Producer/:producerId/EditJudge/:judgeId" exact component={AddEditJudge} />

                <Route path="/Producer/:producerId/Judge/:judgeId" exact component={Judge} />
                <Route path="/Brand/:brandId/Judge/:judgeId" exact component={Judge} />

                <Route path="/AddUser/:objectType/:objectId/:superObjectId?/:superDuperObjectId?" exact component={AddEditUser} />
                <Route path="/EditUser/:objectType/:objectId/:superObjectId?/:superDuperObjectId?/:userId" exact component={AddEditUser} />

                <Route path="/Register/:locationId/Team/:teamId/:eventId" exact component={EventRegistrationContainer} />
                <Route path="/Registration/:locationId/Team/:teamId/:eventId/:eventTeamId" exact component={EventRegistrationContainer} />
                <Route path="/Program/:programId/RegistrationPayment" exact component={EventRegistrationPaymentContainer} />

                <Route path="/USASFUpdate" exact component={ScrapeUSASF} />
                <Route path="/USASFUpdate/:programId" exact component={ScrapeUSASF} />
                <Route path="/OnBoard" exact component={Onboard} />

                <Route path="/eventproducers" exact component={EventProducers} />
                <Route path="/connect/:connectUrl" exact component={EventProducers} />

                <Route path="/documentsign/:signCode" exact component={SignDocumentContainer} />

                <Route path="/shiftReport/:shiftId" exact component={ShiftReport} />
                <Route path="/teamReport/:eventDivisionShiftId" exact component={TeamReport} />
                <Route path="/RubricScoring" exact component={SampleRubricScoringComponent} />
                <Route path="/Producer/:producerId/ScoreSheetBuilder/:rubricId?" exact component={FormBuilder} />

                <Route path="*" exact component={NotFoundPage} />

              </Switch>
            </MuiPickersUtilsProvider>
          </div>
        </Router>
      </LoadStripe>
    </ThemeProvider>
  </Provider>,
  document.querySelector('#root'),
)
