import { Athlete, Coach, Personnel } from "../store/themis_common_pb"
import { countryDateFormats, currencySymbols, FormValues, judgePersonFields } from "./constants"
import themis_common from 'store/themis_common_pb'
export const textGender = (genderId: number): string => {
  switch (genderId) {
    case 0: return "male"
    case 1: return "female"
    case 2: return "other"
    case 3: return "unknown"
  }
  return "unknown"
}

export const sortAthletes = (athletes: Athlete.AsObject[]): Athlete.AsObject[] => {
  if (!athletes) return []
  const sortAthletes = [...athletes]
  sortAthletes.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  return sortAthletes
}

export const sortCoaches = (coaches: Coach.AsObject[]): Coach.AsObject[] => {
  if (!coaches) return []
  const sortCoaches = [...coaches]
  sortCoaches.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  return sortCoaches
}

export const sortPersonnel = (personnel: Personnel.AsObject[]): Personnel.AsObject[] => {
  if (!personnel) return []
  const sortPersonnel = [...personnel]
  sortPersonnel.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  return sortPersonnel
}

export const addCommas = (nStr: string): string => {
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export const getCountryDateFormat = (date: Date): string => {
  const language = navigator.language
  const format: string = (language in countryDateFormats) ? countryDateFormats[language] : "MM/dd/yyyy"
  return format
}

export const makeMoney = (amountInPennies: number, currency: string): string => {
  const symbol = currencySymbols.find(cs => cs.cc === currency)
  const value = addCommas((amountInPennies / 100).toFixed(2))
  return symbol?.symbol + value
}

export const locationApiCall = async (longitude: number, latitude: number): Promise<any> => {
  const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
  return response.json()
}

export const titleCase = (str: string): string => `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`

export const formatDateString = (inputDate: string): string => {
  const [datePart, timePart] = inputDate.split("|");
  const [year, month, day] = datePart.split("-");
  const [hours, minutes] = timePart.split(":");

  const targetDate = new Date(+year, +month - 1, +day, +hours, +minutes);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    weekday: "long",
    month: "short",
    day: "numeric",
  };
  const formattedDate: string = targetDate.toLocaleDateString("en-US", options);

  return formattedDate;
};
interface IShift {
  startTs: string;
  endTs: string;
}
export const calculateOverallTimeRange = (shifts: IShift[]) => {
  if (!shifts || shifts.length === 0) {
    return null;
  }
  const parseTimeString = (timeStr: string) => {
    const [date, time] = timeStr.split("|");
    return new Date(`${date}T${time}`);
  };
  const formatTime = (date: any) => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  let startTime = parseTimeString(shifts[0].startTs);
  let endTime = parseTimeString(shifts[0].endTs);
  for (let i = 1; i < shifts.length; i++) {
    const shift = shifts[i];
    const shiftStartTime = parseTimeString(shift.startTs);
    const shiftEndTime = parseTimeString(shift.endTs);
    // Compare start times
    if (shiftStartTime < startTime) {
      startTime = shiftStartTime;
    }
    // Compare end times
    if (shiftEndTime > endTime) {
      endTime = shiftEndTime;
    }
  }
  return `${formatTime(startTime).toLowerCase()} - ${formatTime(
    endTime
  ).toLowerCase()}`;
};

interface TimeRange {
  start: string;
  end: string;
}

export const nestedCalculateOverallTimeRange = (
  input: TimeRange[] | (string | null)[]
): TimeRange[] => {
  const parseTimeRange = (range: any) => {
    const [start, end] = range.split("-").map((time: any) => time.trim());
    return {
      start,
      end,
    };
  };

  const convertToMinutes = (time: any) => {
    const [hours, minutes] = time
      .match(/(\d+):(\d+) ([APMapm]{2})/)
      .slice(1, 3)
      .map(Number);
    const isPM = time.toLowerCase().includes("pm");
    const adjustedHours = isPM && hours !== 12 ? hours + 12 : hours % 12;
    return adjustedHours * 60 + minutes;
  };

  const timeRanges = input.map(parseTimeRange);

  const overallStart = Math.min(
    ...timeRanges.map((range) => convertToMinutes(range.start))
  );
  const overallEnd = Math.max(
    ...timeRanges.map((range) => convertToMinutes(range.end))
  );

  const formatTime = (minutes: any) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours < 12 ? "AM" : "PM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${formattedHours}:${mins.toString().padStart(2, "0")} ${period}`;
  };

  return [
    {
      start: formatTime(overallStart),
      end: formatTime(overallEnd),
    },
  ];
};

export const convertTo12HourFormat = (time24: string) => {
  if (time24 !== undefined) {
    // Splitting the input time into hours and minutes
    const [hours, minutes] = time24?.split(":");
    // Parsing hours to a number
    let parsedHours = parseInt(hours, 10);
    // Determining AM or PM
    const period = parsedHours >= 12 ? "PM" : "AM";
    // Adjusting hours for 12-hour format
    parsedHours = parsedHours % 12 || 12;
    // Formatting the result
    const time12 = `${parsedHours
      .toString()
      .padStart(2, "0")}:${minutes} ${period}`;
    return time12;
  } else {
    return "";
  }
};

export const sortByTimeOnly = (
  a: themis_common.EventFloorDateJudgingPanel.AsObject,
  b: themis_common.EventFloorDateJudgingPanel.AsObject
): number => {
  const startTimeA = a.shiftsList[0]?.startTs.split("|")[1] || "";
  const startTimeB = b.shiftsList[0]?.startTs.split("|")[1] || "";
  return startTimeA.localeCompare(startTimeB);
};

export const formatTimeAndSort = (
  data: themis_common.EventFloorDateJudgingPanel.AsObject[]
) => {
  // Format time
  data.forEach((item) => {
    item.shiftsList.forEach((shift) => {
      const startParts = shift.startTs.split("|");
      const endParts = shift.endTs.split("|");

      // Check for undefined, null, or empty strings
      const startDate = startParts[0] || "";
      const startTime = startParts[1] || "";
      const endDate = endParts[0] || "";
      const endTime = endParts[1] || "";

      shift.startTs = `${startDate}|${startTime}`;
      shift.endTs = `${endDate}|${endTime}`;
    });
  });

  // Sort by start time
  data.sort(sortByTimeOnly);

  return data;
};

export const filterPanelsByShifts = (
  judgingPanelsList: themis_common.EventFloorDateJudgingPanel.AsObject[]
): themis_common.EventFloorDateJudgingPanel.AsObject[] => {
  const allShifts = extractAllShifts(judgingPanelsList);
  const sortedShifts = sortShiftsByStartTime(allShifts);
  const uniqueShifts = getUniqueShifts(sortedShifts);
  const revampedPanels = mapToRevampedPanels(judgingPanelsList, uniqueShifts);
  return revampedPanels as themis_common.EventFloorDateJudgingPanel.AsObject[];
};

const extractAllShifts = (
  judgingPanelsList: themis_common.EventFloorDateJudgingPanel.AsObject[]
): themis_common.EventFloorDateJudgingPanelShift.AsObject[] => {
  return judgingPanelsList.flatMap((panel) => panel.shiftsList);
};

const sortShiftsByStartTime = (
  shifts: themis_common.EventFloorDateJudgingPanelShift.AsObject[]
): themis_common.EventFloorDateJudgingPanelShift.AsObject[] => {
  return shifts.sort((a, b) => {
    const timeA = a.startTs.split("|")[1];
    const timeB = b.startTs.split("|")[1];
    return timeA.localeCompare(timeB);
  });
};

const getUniqueShifts = (
  shifts: themis_common.EventFloorDateJudgingPanelShift.AsObject[]
): themis_common.EventFloorDateJudgingPanelShift.AsObject[] => {
  return shifts.filter((shift, shiftIndex, self) => {
    const firstIndex = self.findIndex(
      (s) => s.startTs === shift.startTs && s.endTs === shift.endTs
    );
    return shiftIndex === firstIndex;
  });
};

const mapToShiftsList = (
  shiftsList: themis_common.EventFloorDateJudgingPanelShift.AsObject[],
  uniqueShifts: themis_common.EventFloorDateJudgingPanelShift.AsObject[]
) => {
  return uniqueShifts.map((uniqueShift) => {
    let startTs = uniqueShift.startTs;
    const matchingShift = shiftsList.find(
      (shift) => shift.startTs === uniqueShift.startTs
    );
    return matchingShift !== undefined
      ? matchingShift
      : { startTs, eventDivisionsList: [] };
  });
};

const mapToRevampedPanels = (
  judgingPanelsList: themis_common.EventFloorDateJudgingPanel.AsObject[],
  uniqueShifts: themis_common.EventFloorDateJudgingPanelShift.AsObject[]
): themis_common.EventFloorDateJudgingPanel.AsObject[] | {}[] => {
  return judgingPanelsList.map((panel) => {
    const shiftsList = mapToShiftsList(panel.shiftsList, uniqueShifts);
    return { shiftsList };
  });
};

export const findMaxEventTeamsAndDivision = (
  panelsList: themis_common.EventFloorDateJudgingPanel.AsObject[]
) => {
  const maxTeamsLengthPerStartTime: { [key: string]: number } = {};
  let maxDivInShift: { [key: string]: number } = {};
  panelsList?.forEach((currentPanel) => {
    currentPanel?.shiftsList?.forEach((shift) => {
      const startTs = shift?.startTs;
      if (startTs) {
        const eventDivisionList = shift?.eventDivisionsList;
        let maxDivisionLength = 0;
        if (eventDivisionList && eventDivisionList?.length) {
          eventDivisionList?.forEach((division) => {
            const teamsLength = division?.eventTeamsList?.length;
            maxDivisionLength =
              teamsLength > maxDivisionLength ? teamsLength : maxDivisionLength;
          });
        }

        if (eventDivisionList && eventDivisionList?.length) {
          maxTeamsLengthPerStartTime[startTs] =
            maxDivisionLength > (maxTeamsLengthPerStartTime[startTs] || 0)
              ? maxDivisionLength
              : maxTeamsLengthPerStartTime[startTs] || 0;
        }

        if (maxDivInShift.hasOwnProperty(startTs)) {
          let shiftDivLength = maxDivInShift[startTs];
          if (eventDivisionList.length > shiftDivLength) {
            maxDivInShift[startTs] = eventDivisionList.length;
          }
        } else {
          maxDivInShift[startTs] = eventDivisionList.length;
        }
      }
    });
  });
  return { maxTeamsLengthPerStartTime, maxDivInShift };
};
export const calculateMaxTeamTrCountForRow = (teamRowRef: any, event: any) => {
  const allContentDivs = document.querySelectorAll(".content-div");
  let newDivisionHeight: any = {};
  let divisionTrCounts: any = {};
  let divisionTrMaxCounts: any = {};
 
  // Iterate over each team reference and store its height
  if (teamRowRef && teamRowRef.current !== (null || undefined)) {
    Object.keys(teamRowRef.current).forEach((key) => {
      const ref = teamRowRef.current[key];
      if (ref) {
        const height = ref.clientHeight;
        newDivisionHeight[key] = height;
      }
    });
  }
 
  // Store the height of each element with class 'content-div' in newDivisionHeight object
  allContentDivs.forEach((div, index) => {
    const height = div.clientHeight;
    newDivisionHeight[index] = height;
 
    let maxTrCount = 0;
 
    // Find all tables within the current .content-div
    const tables = div.querySelectorAll('table');
 
    // Iterate over each table and find the maximum number of <tr> elements
    tables.forEach((table) => {
      const trCount = table.querySelectorAll('tr').length;
      if (trCount > maxTrCount) {
        maxTrCount = trCount;
      }
    });
 
    // Store the maximum number of <tr> elements for the current division
  });
 
  const divisionLength = Object.keys(newDivisionHeight).length;
  const panelLength = event?.eventFloorsList?.flatMap((floor: any) =>
    floor?.daysList?.flatMap((days: any) => days?.judgingPanelsList?.length)
  );
  const length = panelLength ? panelLength[0] : 0;
  const totalRows = Math.ceil(divisionLength / length);
 
  // Store the maximum number of <tr> elements for each row in divisionTrCounts object
  for (let i = 0; i < totalRows; i++) {
    let tempRowTrCounts: number[] = [];
    for (let panelIndex = 0; panelIndex < length; panelIndex++) {
      const divisionIndex = i + totalRows * panelIndex;
 
      // Find the current division (content-div) based on its index
      const currentDivision = allContentDivs[divisionIndex];
 
      // Find all tables within the current division
      const tables = currentDivision.querySelectorAll('table');
      let maxTrCount = 0;
 
      // Iterate over each table and find the maximum number of <tr> elements
      tables.forEach((table) => {
        const trCount = table.querySelectorAll('tr').length;
        if (trCount > maxTrCount) {
          maxTrCount = trCount;
        }
      });
 
      tempRowTrCounts.push(maxTrCount);
    }
 
    // Find the maximum number of <tr> elements for the current row
    const divisionKey = `row${i}`;
    const maxTrCountForRow = tempRowTrCounts;
    divisionTrCounts[divisionKey] = maxTrCountForRow;
    divisionTrMaxCounts[divisionKey] = Math.max(...maxTrCountForRow);
  }
 
  // Calculate and return result
  const result: any = {};
  for (let i = 0; i < totalRows; i++) {
    const divisionKey = `row${i}`;
    const maxValue: any = Object.values(divisionTrMaxCounts)[i];
    const value: any = Object.values(divisionTrCounts)[i];
    result[divisionKey] = value.flatMap((n: any) => maxValue - n);
  }
  return result;
};

export const revampedPanelWithEmptyTeamsAndDivisionsObject = (
  revampedPanels: themis_common.EventFloorDateJudgingPanel.AsObject[],
  maxDivInShift: {
    [key: string]: number;
  },
  eventDivisionListObj: {
    [key: string]: number;
  }
): themis_common.EventFloorDateJudgingPanel.AsObject[] => {
  revampedPanels?.forEach((panel) => {
    panel?.shiftsList?.forEach((shift) => {
      const startTimestamp = shift?.startTs;
      let maxShiftDiv = maxDivInShift[shift.startTs];
      let shiftDivLength = shift.eventDivisionsList.length;
      if (shift?.eventDivisionsList && shift?.eventDivisionsList?.length) {
        const maxTeamsForThatShift = eventDivisionListObj[startTimestamp];
        shift.eventDivisionsList.forEach((eventDiv: any) => {
          if (eventDiv?.eventTeamsList && eventDiv?.eventTeamsList?.length) {
            const eventTeamsLength = eventDiv?.eventTeamsList?.length;

            if (eventTeamsLength < maxTeamsForThatShift) {
              const noOfTeamsToAdd = maxTeamsForThatShift - eventTeamsLength;
              const updatedEventTeamsList = eventDiv?.eventTeamsList
              eventDiv.eventTeamsList = updatedEventTeamsList;
            }
          }
        });
      }
      if (maxShiftDiv > shiftDivLength) {
        const noOfDivToAdd = maxShiftDiv - shiftDivLength;
        const emptyDivisions = Array.from({ length: noOfDivToAdd }, () => ({}));
        (shift.eventDivisionsList as any) = [...(shift.eventDivisionsList || []), ...emptyDivisions];
      }
    });
  });
  return revampedPanels;
};

export const revampedPanelsSplitEventDivisionIntoObject = (
  revampedPanels: themis_common.EventFloorDateJudgingPanel.AsObject[]
) => {
  let updatedPanels: themis_common.EventFloorDateJudgingPanel.AsObject[] = [];

  revampedPanels.forEach((panel) => {
    let updatedShiftsList: themis_common.EventFloorDateJudgingPanelShift.AsObject[] =
      [];

    panel.shiftsList.forEach((shift) => {
      if (shift.eventDivisionsList) {
        shift.eventDivisionsList.forEach((division) => {
          let updatedShift = {
            ...shift,
            eventDivisionsList: [{ ...division }],
          };
          updatedShiftsList.push(updatedShift);
        });
      }
    });

    updatedPanels.push({
      shiftsList: updatedShiftsList,
      id: 0,
      name: "",
      order: 0,
      eventFloorDateId: 0,
    });
  });

  return updatedPanels;
};

export const calculateDivisionHeight = (
  teamRef: any,
  getEvent: themis_common.Event.AsObject | undefined
) => {
  // Select all elements with class 'content-div'
  const allContentDivs = document.querySelectorAll(".content-div");

  // Store the height of each referenced element in newDivisionHeight object
  let newDivisionHeight: any = {};
  Object.keys(teamRef.current).forEach((key) => {
    const ref = teamRef.current[key];
    if (ref) {
      const height = ref.clientHeight;
      newDivisionHeight[key] = height;
    }
  });

  // Store the height of each element with class 'content-div' in newDivisionHeight object
  allContentDivs.forEach((div, index) => {
    const height = div.clientHeight;
    newDivisionHeight[index] = height;
  });

  // Calculate division length and panel length
  const divisionLength = Object.keys(newDivisionHeight).length;
  const panelLength = getEvent?.eventFloorsList?.flatMap((floor) =>
    floor?.daysList?.flatMap((days) => days?.judgingPanelsList?.length)
  );
  const length = panelLength ? panelLength[0] : 0;
  const totalRows = Math.ceil(divisionLength / length);

  // Store the maximum height for each row in divisionHeight object
  let divisionHeight: any = {};
  for (let i = 0; i < totalRows; i++) {
    let tempRowHeights: number[] = [];
    for (let panelIndex = 0; panelIndex < length; panelIndex++) {
      tempRowHeights.push(newDivisionHeight[i + totalRows * panelIndex]);
    }
    const maxValue = Math.max(...tempRowHeights);
    divisionHeight[`row${i}`] = maxValue;
  }
  return divisionHeight;
};
export const formateTime = (inputDate:string) => {
  const [datePart,timePart]=inputDate.split('|');
  return timePart;
}
export const getMinutesDiff = (startTime: any, endTime: any) => {
  const startDate: any = new Date(startTime);
  const endDate: any = new Date(endTime);
  const timeDifference = endDate - startDate;
  return timeDifference / (1000 * 60);
}

export const addMinutes = (timestamp: string, duration: number) => {
  // Split the timestamp into date and time
  const [datePart, timePart] = timestamp.split('|');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');
  const originalDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes));
  originalDate.setMinutes(originalDate.getMinutes() + duration);
  // Format the result as "YYYY-MM-DD|HH:mm"
  const result = `${originalDate.getFullYear()}-${String(originalDate.getMonth() + 1).padStart(2, '0')}-${String(originalDate.getDate()).padStart(2, '0')}|${String(originalDate.getHours()).padStart(2, '0')}:${String(originalDate.getMinutes()).padStart(2, '0')}`;
  return result
}

export const convertToNumber = (value: any) => {
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? 0 : parsedValue;
}

export const camelCase = (str: string) => {
  return str.replace(/\s(.)/g, function (match, group1) {
      return group1.toUpperCase();
  }).replace(/\s/g, '').replace(/^(.)/, function (match, group1) {
      return group1.toLowerCase();
  });
};