/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState, CSSProperties, useRef, ReactElement, MutableRefObject } from 'react'
import ReactDOM from "react-dom"
import { Link as RouterLink } from "react-router-dom"
import { useStyles } from "lib/theme"
import { Box, Grid, Typography } from '@material-ui/core'
import { startOfMonth, setDay, getDay, parseISO, addDays, format, addWeeks, getDate, parse, sub, add, addMonths, subMonths } from "date-fns"
import themis_common from 'store/themis_common_pb'
import EventGridWeekendBox from "./EventGridWeekendBox"
import { ProgramPermissions } from "store/user/userReducer"
import { validateTeamPermission } from "lib/permissions"
import { Alert } from '@material-ui/lab'
import HelpIcon from 'components/util/HelpIcon'

interface EventGridProps {
  eventGridType: string
  permissionCodes: themis_common.PermissionCodes.AsObject
  programPermissions: ProgramPermissions
  superUser: boolean
  programId: number
  teams: ListTeam[]
  eventTeams: themis_common.EventTeam.AsObject[]
  approvedEvents: themis_common.Event.AsObject[]
  setTeams: React.Dispatch<React.SetStateAction<ListTeam[]>>
  setEventTeams: React.Dispatch<React.SetStateAction<themis_common.EventTeam.AsObject[]>>
  setApprovedEvents: React.Dispatch<React.SetStateAction<themis_common.Event.AsObject[]>>
  locationId?: number
  teamId?: number
  program: themis_common.Program.AsObject
  location?: themis_common.Location.AsObject
  team?: themis_common.Team.AsObject
  showEventGrid: boolean
}

interface ListTeam {
  teamId: number
  teamName: string
  locationId: number
  locationName: string
  team: themis_common.Team.AsObject
}

interface Week {
  saturday: number;
  scheduleList: themis_common.EventTeam.AsObject[];
  EventsList: themis_common.Event.AsObject[];
}

interface Team {
  teamId: number
  weeks: Week[]
}

interface Month {
  month: string;
  year: string;
  teams: Team[]
}

type EventGridSeason = Array<Month>

const dateFormat = "yyyy-MM-dd"

const keyInfoMarkdown = `
### Events
If there are no event producers connected to your program,  
click "View More Producers".

*Each icon represents a one week span. Click on a week icon to see more details*

#### Icon Key

![event-grid-icon](/gray_icon.png)
No events are available

![event-grid-icon](/blue_icon.png)
Event(s) are available

![event-grid-icon](/green_icon.png)
Team is registered for event(s) this week and you are ready to go.

![event-grid-icon](/yellow_icon.png) ![event-grid-icon](/orange_icon.png) ![event-grid-icon](/red_icon.png)
Team is registered for event(s) this week and there are things to handle (event forms and payments).

![event-grid-icon](/pink_icon.png)
Team has registerd in the past, or has missed an event, these events are expired, but you may view historic information.

`

const EventGrid: React.FC<EventGridProps> = ({
  eventGridType, programPermissions, programId, locationId, teamId, program, location, team, superUser, permissionCodes,
  teams, eventTeams, approvedEvents, setTeams, setEventTeams, setApprovedEvents, showEventGrid
}): ReactElement => {
  const classes = useStyles()

  const [season, setSeason] = useState<EventGridSeason>([])
  const [offsetHeight, setOffsetHeight] = useState(300)

  const divRef = useRef<HTMLDivElement>(null)

  const defaultSeason = {
    name: `${format(subMonths(
      new Date(),
      6,
    ), "yy")}-${format(addMonths(
      new Date(),
      12,
    ), "yy")}`,
    yearStart: format(subMonths(
      new Date(),
      6,
    ), "yyyy-MM-dd"),
    seasonStart: format(subMonths(
      new Date(),
      6,
    ), "yyyy-MM-dd"),
    seasonEnd: format(addMonths(
      new Date(),
      12,
    ), "yyyy-MM-dd"),
    yearEnd: format(addMonths(
      new Date(),
      12,
    ), "yyyy-MM-dd")
  }

  // Generate season weeks and add events
  useEffect(() => {
    if (!Object.keys(teams)?.length) return

    // ---------------------------
    // Generate array of season days
    // ---------------------------
    const wednesday = 3
    const seasonDays: Date[] = []
    const firstDayOfYear = addWeeks(startOfMonth(parseISO(defaultSeason.yearStart)), -1)
    let seasonDay = setDay(firstDayOfYear, wednesday, { weekStartsOn: getDay(firstDayOfYear) })
    while (seasonDay <= parseISO(defaultSeason.yearEnd)) {
      seasonDays.push(seasonDay)
      seasonDay = addWeeks(seasonDay, 1)
    }

    // ---------------------------
    // Generate full empty season
    // ---------------------------
    // const editSeason = [...season]
    const emptySeason: EventGridSeason = []
    seasonDays.forEach((currentWeek) => {
      const month = format(addDays(currentWeek, 3), 'LLLL')
      const year = format(addDays(currentWeek, 3), 'yyyy')
      // If no matching month, add month.
      let findExistingMonthIndex = emptySeason.findIndex(m => m.month + '-' + m.year === month + '-' + year)
      if (findExistingMonthIndex === -1) {
        const newMonthListLength = emptySeason.push({ month: month, year: year, teams: [] })
        findExistingMonthIndex = newMonthListLength - 1
      }

      teams.forEach((locationTeam) => {
        // If no matching team, add team.
        let findExistingTeamIndex = emptySeason[findExistingMonthIndex].teams.findIndex(t => t.teamId === locationTeam.teamId)
        if (findExistingTeamIndex === -1) {
          const newTeamListLength = emptySeason[findExistingMonthIndex].teams.push({ teamId: locationTeam.teamId, weeks: [] })
          findExistingTeamIndex = newTeamListLength - 1
        }

        // Push each weekend
        const defaultWeekend: Week = {
          saturday: getDate(addDays(currentWeek, 3)),
          scheduleList: [],
          EventsList: [],
        }
        emptySeason[findExistingMonthIndex].teams[findExistingTeamIndex].weeks.push(defaultWeekend)
      })
    })

    setSeason(emptySeason)

    // ---------------------------
    // Add approved events and existing events
    // ---------------------------
    const editSeason = [...emptySeason]
    seasonDays.forEach((currentWeek2) => {
      teams.forEach((locationTeam) => {
        const scheduleList: themis_common.EventTeam.AsObject[] = []
        // Add existing events if team matches
        eventTeams.forEach((eventTeam) => {
          if (eventTeam.event && eventTeam.teamId === locationTeam.teamId) eventTeam.event.eventDatesList.forEach((date) => {
            if (!scheduleList.includes(eventTeam)
              && format(parseISO(date.startDay.substr(0, 10)), dateFormat) >= format(currentWeek2, dateFormat)
              && format(parseISO(date.startDay.substr(0, 10)), dateFormat) < format(addWeeks(currentWeek2, 1), dateFormat)) {
              scheduleList.push(eventTeam)
            }
          })
        })

        // Add approved events to all teams
        const EventsList: themis_common.Event.AsObject[] = []
        approvedEvents.forEach((event) => {
          let inWeekend = false
          event.eventDatesList.forEach((date) => {
            if (format(parseISO(date.startDay.substr(0, 10)), dateFormat) >= format(currentWeek2, dateFormat)
              && format(parseISO(date.startDay.substr(0, 10)), dateFormat) < format(addWeeks(currentWeek2, 1), dateFormat)) {
              inWeekend = true
            }
          })
          if (inWeekend) EventsList.push(event)
        })

        const currentWeekObject: Week = {
          saturday: getDate(addDays(currentWeek2, 3)),
          scheduleList: scheduleList,
          EventsList: EventsList,
        }

        // Find the current month, team, and week,
        const month = format(addDays(currentWeek2, 3), 'LLLL')
        const year = format(addDays(currentWeek2, 3), 'yyyy')
        const findExistingMonthIndex = editSeason.findIndex(m => m.month + '-' + m.year === month + '-' + year)
        const findExistingTeamIndex = editSeason[findExistingMonthIndex].teams.findIndex(t => t.teamId === locationTeam.teamId)
        const findExistingWeekIndex = editSeason[findExistingMonthIndex].teams[findExistingTeamIndex].weeks.findIndex(w => w.saturday === getDate(addDays(currentWeek2, 3)))
        // Push the update week with events
        editSeason[findExistingMonthIndex].teams[findExistingTeamIndex].weeks[findExistingWeekIndex] = currentWeekObject
      })
    })

    setSeason(editSeason)
  }, [teams, approvedEvents, eventTeams, defaultSeason.yearStart, defaultSeason.yearEnd])

  // Get top offset
  const inputRef: MutableRefObject<any> = useRef()
  useEffect(() => {
    setOffsetHeight((inputRef.current?.getBoundingClientRect()).top)
  }) // TODO: Find a way to use dependencies at the right time to get the right offset height

  // CSS
  const matchWindowHeight: CSSProperties = {
    height: `calc(100vh - ${offsetHeight}px - 24px)`, // 24px is the Material Tab padding
  }

  const teamNameRow: React.CSSProperties = {
    // height: 36,
    height: 60,
    // width: "30vw",
    margin: 0,
    padding: 2,
  }
  const locationName: React.CSSProperties = {
  }
  const teamName: React.CSSProperties = {
  }
  const gridContainer: React.CSSProperties = {
    display: "grid",
    overflow: "scroll",
    width: "100%",
    border: "1px solid gray",
  }
  const grid: React.CSSProperties = {
    display: "flex",
    flexWrap: "nowrap",
  }
  const gridCol: React.CSSProperties = {
    // width: "150px",
    // minWidth: "150px",
  }
  const gridItemHeader: React.CSSProperties = {
    // height: "50px",
    // minHeight: "100px",
    position: "sticky",
    // position: "-webkit-sticky",
    alignContent: "center",
    justifyContent: "center",
    top: 0,
    zIndex: 197,
  }
  const gridColFixedLeft: React.CSSProperties = {
    position: "sticky",
    left: 0,
    zIndex: 198,
    width: "30vw",
    minWidth: 180,
    maxWidth: 400,
  }
  const gridItem: React.CSSProperties = {
    // height: "50px",
    border: "1px solid gray",
    placeSelf: "center",
  }
  const oneHundredHeight: React.CSSProperties = {
    height: "100%",
  }

  // const monthWidthCSS = `${2.55 * theWeeks.length}VW`
  // const monthWidthCSS = `${50 * month.weeks.length}px`
  const monthWidthCSS = `100%`

  const monthHeaderOuterStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: monthWidthCSS,
    height: "4VW",
    minHeight: 65,
    overflowY: "hidden"
  }
  const monthNameStyle: React.CSSProperties = {
    width: monthWidthCSS,
    display: "flex",
    justifyContent: "center",
    paddingTop: 8,
    // height: "1VW"
  }
  const monthNumberListStyle: React.CSSProperties = {
    width: monthWidthCSS,
    // height: "1VW",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  }
  const satBoxStyle: React.CSSProperties = {
    position: "relative",
    display: "grid",
    justifyContent: "center",
    alignContent: "Center",
    // maxWidth: 100,
    // minWidth: 30,
    // maxHeight: 100,
    // minHeight: 30,
    width: 30,
    height: 30,
    // margin: 2,
  }
  const weekendBoxListStyle: React.CSSProperties = {
    width: monthWidthCSS,
    height: 58,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  }

  const scrollToDivRef = () => {
    const node = ReactDOM.findDOMNode(divRef.current) as Element
    if (node) node.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  const seasonGrid = useMemo(() => {
    if (!programId) return <></>
    if (!Object.keys(teams)?.length) return <></>
    if (season?.length < 12) return <></>

    const today = new Date()
    const todayDate = parseISO(`${today.toISOString()}`)

    let todayWeek = add(todayDate, { months: 2 })
    let todayPlusWeek = add(todayDate, { months: 2, weeks: 1 })
    const yearEnd = parseISO(defaultSeason.yearEnd)
    if (todayPlusWeek > yearEnd) {
      todayPlusWeek = yearEnd
      todayWeek = sub(yearEnd, { weeks: 1 })
    }

    const teamNameColumn = teams.map((columnTeam) => {
      if (validateTeamPermission(permissionCodes.teamView, permissionCodes, superUser, programPermissions, programId, columnTeam.locationId, columnTeam.teamId)) {
        return (
          <div style={{ ...gridItem, ...teamNameRow }} key={`teamName-${columnTeam.teamId}`}>
            <Grid container>
              <Grid item xs={12} xl={6}>
                <RouterLink style={locationName} className={classes.cleanLink}
                  to={`/Location/${columnTeam.locationId}`}>
                  <Typography variant="h3">{columnTeam.locationName}</Typography>
                </RouterLink>
              </Grid>
              <Grid item xs={12} xl={6}>
                <RouterLink style={teamName} className={classes.cleanLink}
                  to={`/Location/${columnTeam.locationId}/Team/${columnTeam.teamId}`}>
                  <Typography variant="h3">{columnTeam.teamName}</Typography>
                </RouterLink>
              </Grid>
            </Grid>
          </div>
        )
      } else return []
    })

    const monthsColumnList = season.map((month) => {
      const theSats = month.teams[0]?.weeks.map((week) => {
        const targetDate = parse(`${month.month} ${week.saturday} ${month.year}`, 'MMMM d yyyy', new Date())
        return <div ref={targetDate > todayWeek && targetDate <= todayPlusWeek ? divRef : undefined} style={satBoxStyle} key={`sats-${week.saturday}-${month.month}`}>{week.saturday}</div>
      })
      const monthColumn = teams.map((monthTeam) => {
        const theWeeks = month.teams.find(t => t.teamId === monthTeam.teamId)?.weeks.map((week, i: number) => {
          const weekendId = defaultSeason.name + month.month + i
          return <EventGridWeekendBox locationId={monthTeam.locationId} teamId={monthTeam.teamId} weekendId={weekendId} scheduleList={week.scheduleList} waiverStatus={true} errorStatus={true} availableEvents={week.EventsList} key={weekendId} programId={programId} programPermissions={programPermissions} superUser={superUser} permissionCodes={permissionCodes} />
        })
        if (validateTeamPermission(permissionCodes.teamView, permissionCodes, superUser, programPermissions, programId, monthTeam.locationId, monthTeam.teamId)) {
          return (
            <div style={gridItem} key={`weeks${month.month}-team${monthTeam.teamId}`}>
              <div style={weekendBoxListStyle}>
                {theWeeks}
              </div>
            </div>
          )
        }
        return <></>
      })
      return (
        <div style={gridCol} key={`column-${month.month}-${month.year}`}>
          <div style={{ ...monthHeaderOuterStyle, ...gridItem, ...gridItemHeader }} className={classes.primaryBackgroundColor}>
            <div style={monthNameStyle} key={`upper${month.month}`}>{month.month}</div>
            <div style={monthNumberListStyle} key={`sats${month.month}`}>
              {theSats}
            </div>
          </div>
          {monthColumn}
        </div>
      )
    })
    return (
      <div style={grid}>
        {/* Fixed left column */}
        <div style={{ ...gridCol, ...gridColFixedLeft }} className={classes.primaryBackgroundColor}>
          {/* Location Box */}
          <div style={{ ...monthHeaderOuterStyle, ...gridItem, ...gridItemHeader }} className={classes.primaryBackgroundColor}>
            <Typography variant="h3" color="primary" align="center">Teams</Typography>
          </div>
          {/* Team Names */}
          {teamNameColumn}
        </div>
        {/* Event Grid */}
        {monthsColumnList}
      </div>
    )
  }, [locationName, programId, programPermissions, season, superUser, teamName, teamNameRow, teams, permissionCodes]) // Don't add css dependencies for now

  useEffect(() => {
    // Scroll to DivRef on load
    scrollToDivRef()
    // Scroll to DivRef after switching from EventList
    if (showEventGrid) scrollToDivRef()
  }, [season, showEventGrid])

  return (permissionCodes?.denyAccess.length ?
    <>
      <Box position="relative">
        {showEventGrid ?
          (
            <Box position="absolute" top={5} left={5} zIndex="modal">
              <HelpIcon
                markdownText={keyInfoMarkdown}
                iconType="info"
                tooltipText="Key"
              />
            </Box>
          ) : <></>
        }
        <div style={oneHundredHeight}>
          <div ref={inputRef} style={{ ...matchWindowHeight, ...gridContainer }}>
            {teams.length === 0 ? <Alert severity="warning">There are no teams. Add a team to a location to see the season grid.</Alert> : seasonGrid}
          </div>
        </div>
      </Box>
    </> : <></>)
}

export default EventGrid
