import { Button, Dialog, Grid, TextField, Tooltip, Typography, IconButton, DialogTitle, DialogContent, DialogActions, FormGroup, Checkbox, FormControlLabel, Switch, Popper, Paper } from "@material-ui/core";
import { FormValues } from "lib/constants";
import { useStyles } from "lib/theme";
import { useEffect, useState } from "react";
import { Add as AddIcon, Edit as EditIcon, InfoOutlined, Remove as RemoveIcon } from '@material-ui/icons';
import LabelComponent from "../rubricComponents/Label";
import { Autocomplete } from "@material-ui/lab";
import { camelCase } from "lib/functions";

interface Form1Props {
    index: number,
    responseData: any;
    objectName: string;
    formData: any;
    rubricId: number;
}
const Form1: React.FC<Form1Props> = ({ index, responseData, objectName, formData, rubricId }) => {
    const classes = useStyles();

    const dataValues = {
        "0": [
            {
                "title": "Difficulty",
                "type": "staticLabel",
                "visible": true
            },
            {
                "key": "difficulty",
                "title": "Difficulty",
                "type": "dropdown",
                "required": true,
                "visible": true,
                "showHelpText": true,
                "helpText": "HelpText",
                "enums": [
                    {
                        "value": "2.0",
                        "label": "2.0-2.5 | BELOW"
                    },
                    {
                        "value": "3.0",
                        "label": "3.0-3.5 | BELOW"
                    }
                ]
            },
            {
                "key": "difficultyValue",
                "title": "Difficulty Value",
                "type": "textboxRange",
                "rangeToValidateWith": "difficulty",
                "required": true,
                "visible": true
            },
            {
                "key": "isComment",
                "title": "Comment",
                "type": "switch",
                "visible": true,
                "value": true
            },
            {
                "key": "comment",
                "title": "Comment",
                "type": "textboxarea",
                "required": true,
                "visible": true
            }
        ]
    }

    // Dynamically initialize the response state with an empty object for each item in dataValues
    const initialResponseState = Object.keys(dataValues).reduce((acc: any, key: any) => {
        acc = dataValues
        return acc;
    }, {});

    const [response, setResponse] = useState<any>({
        data: {
            rows: initialResponseState
        }
    });
    const [data, setData] = useState<any>(dataValues);
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [editedElement, setEditedElement] = useState<any | null>(null);
    const [dialogDropdownOptions, setDialogDropdownOptions] =  useState([{ value: "2.0", label: "2.0-2.5 | BELOW" }]);
    const [dialogDropdownRangeOptions, setDialogDropdownRangeOptions] = useState([{ min: "1", max: "5", const: "1", title: "difficulty test" }]);
    const [isComment, setIsComment] = useState<boolean>(true);
    const [isDropdownRange, setIsDropdownRange] = useState<boolean>(false);
    const [idx, setIdx] = useState<number>(0);
    const [helpTextObj, setHelpTextObj] = useState<{ showHelpText: boolean, helpText: string }>({ showHelpText: true, helpText: "" });
    const [errorText, setErrorText] = useState<string[]>([]);
    const [errorConstText, setErrorConstText] = useState<string[]>([]);
    const [titleError, setTitleError] = useState<string>('');

    // Update dropdown, dropdownRange and textboxRange keys as objectName 
    useEffect(() => {    
        // Create a new copy of response
        const updatedResponse = { ...response };
    
        Object.values(updatedResponse.data.rows).forEach((row: any) => {
            row.forEach((field: any) => {
                if (field.type === "dropdown" || field.type === "dropdownRange") {
                    field.key = objectName;
                }
                if (field.type === "textboxRange") {
                    field.key = `${objectName}Value`;
                }
            });
        });
    
        // Update the state with the new response
        responseData(updatedResponse, index);
    }, [response, objectName]);

    // Set data for Edit form
    useEffect(() => {
        if (formData) {
            if (objectName) {
                const obj = formData.find((obj: any) => {
                    return obj.data.items[0].objectName === objectName
                })
                setData(obj.data.items[0].data.rows);

                const dropdownRangeItem = obj.data.items[0].data.rows[0].find((item: any) => item.type === FormValues.DROPDOWN_RANGE || item.type === FormValues.DROPDOWN);
                setHelpTextObj({ showHelpText: dropdownRangeItem.showHelpText, helpText: dropdownRangeItem.helpText});

                setResponse({
                    data: {
                        rows: obj.data.items[0].data.rows
                    }
                });
            }
        }
    }, []);

    // Extracting enums from the response
    useEffect(() => {
        let enums;
        const template1Item = formData.find((item: any) => item.data.items[0].templateName === 'template-1');
        if (rubricId > 0) {            
            if (template1Item) {
                const isDropdownItem = template1Item.data.items[0].data.rows[0].find((field: any) => field.type === 'dropdown');
                if (isDropdownItem) {
                    enums = template1Item.data.items[0].data.rows[0].find((field: any) => field.type === 'dropdown')?.enums;
                } else {
                    enums = template1Item.data.items[0].data.rows[0].find((field: any) => field.type === 'dropdownRange')?.enums;
                    setIsDropdownRange(true)
                }
            }
        } else {
            enums = response?.data?.rows[0]?.find((field: any) => field.type === 'dropdown')?.enums;
        }
        
        // Formatting enums into required format
        if (template1Item.data.items[0].data.rows[0].find((field: any) => field.type === 'dropdownRange')) {
            if (enums && enums.length > 0) {
                const dropdownOptions = enums.map((enumItem: any) => ({
                    min: enumItem.min,
                    max: enumItem.max,
                    const: enumItem.const,
                    title: enumItem.title
                }));
                // Setting dropdown options into state
                setDialogDropdownRangeOptions(dropdownOptions);
            }
        } else {
            if (enums && enums.length > 0) {
                const dropdownOptions = enums.map((enumItem: any) => ({
                    value: enumItem.value,
                    label: enumItem.label
                }));

                // Setting dropdown options into state
                setDialogDropdownOptions(dropdownOptions);
            }
        }
      }, []); 

    const handleAddOptionDialog = () => {
        if (isDropdownRange) {
            setDialogDropdownRangeOptions([...dialogDropdownRangeOptions, { min: "", max: "", const: "", title: "" }]);
        } else {
            setDialogDropdownOptions([...dialogDropdownOptions, { value: "", label: "" }]);
        }
    };

    const handleRemoveOptionDialog = (index: number) => {
        if (isDropdownRange) {
            const updatedRangeOptions = [...dialogDropdownRangeOptions];
            updatedRangeOptions.splice(index, 1);
            setDialogDropdownRangeOptions(updatedRangeOptions);
        } else {
            const updatedOptions = [...dialogDropdownOptions];
            updatedOptions.splice(index, 1);
            setDialogDropdownOptions(updatedOptions);
        }
    }

    const handleOptionChangeDialog = (index: number, key: string, value: any) => {
        // Create a copy of the current options array
        const optionsCopy: any = isDropdownRange ? [...dialogDropdownRangeOptions] : [...dialogDropdownOptions];

        // Update the value of the option at the specified index
        optionsCopy[index][key] = value;

        // Check for duplicates based on label or value
        const newOptionValue = optionsCopy[index][key];
        let isDuplicate, isConstDuplicate;
        if (key === 'value' || key === 'title') {
            isDuplicate = isDropdownRange ? optionsCopy.some((option: any, i: number) => i !== index && option.title === newOptionValue) : optionsCopy.some((option: any, i: number) => i !== index && option.value === newOptionValue);
        }

        // Update the error text accordingly
        const err = [...errorText];
        err[index] = isDuplicate ? `${key} should be unique` : '';
        setErrorText(err);

        if (key === 'const') {
            isConstDuplicate = optionsCopy.some((option: any, i: number) => i !== index && option.const === newOptionValue);
            // Update the error text accordingly
            const err = [...errorConstText];
            err[index] = isConstDuplicate ? `${key} should be unique` : '';
            setErrorConstText(err);
        }

        // Update the state with the modified options array if no duplicates found
        if (!isDuplicate) {
            if (isDropdownRange) {
                setDialogDropdownRangeOptions(optionsCopy);
            } else {
                setDialogDropdownOptions(optionsCopy);
            }
        }

        // Update the edited element if it's a dropdown or dropdown range
        setEditedElement((prevEditedElement: any) => {
            if (prevEditedElement && (prevEditedElement.type === FormValues.DROPDOWN || prevEditedElement.type === FormValues.DROPDOWN_RANGE)) {
                return {
                    ...prevEditedElement,
                    enums: optionsCopy,
                };
            }
            return prevEditedElement;
        });
    };

    const addDropdownOptionsDialog = (
        <>
            {dialogDropdownOptions.map((option, index) => (
                <Grid container key={index} style={{ marginTop: '5px' }} className={classes.grouper}>
                    <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                        <TextField
                            label="Value"
                            name="value"
                            id="value"
                            variant="outlined"
                            fullWidth
                            value={option.value}
                            onChange={(event) => handleOptionChangeDialog(index, event.target.name, event.target.value)}
                            error={(errorText[index] ? true : false)}
                            helperText={(index !== undefined) ? errorText[index] : errorText}
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} className={classes.rubricScoring}>
                        <TextField
                            label="Label"
                            name="label"
                            id="label"
                            variant="outlined"
                            fullWidth
                            value={option.label}
                            onChange={(event) => handleOptionChangeDialog(index, event.target.name, event.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={1} className={classes.rubricScoring}>
                        {index > 0 && (
                            <IconButton onClick={() => handleRemoveOptionDialog(index)}>
                                <RemoveIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    );

    const addDropdownRangeOptionsDialog = (
        <>
            {dialogDropdownRangeOptions.map((option, index) => (
                <Grid container key={index} style={{ marginTop: '5px' }} className={classes.grouper}>
                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring}>
                        <TextField
                            label="Min"
                            name="min"
                            id="min"
                            variant="outlined"
                            fullWidth
                            value={option.min}
                            onChange={(event) => handleOptionChangeDialog(index, event.target.name, event.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring}>
                        <TextField
                            label="Max"
                            name="max"
                            id="max"
                            variant="outlined"
                            fullWidth
                            value={option.max}
                            onChange={(event) => handleOptionChangeDialog(index, event.target.name, event.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring}>
                        <TextField
                            label="Const"
                            name="const"
                            id="const"
                            variant="outlined"
                            fullWidth
                            value={option.const}
                            onChange={(event) => handleOptionChangeDialog(index, event.target.name, event.target.value)}
                            error={(errorConstText[index] ? true : false)}
                            helperText={(index !== undefined) ? errorConstText[index] : errorText}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={11} className={classes.rubricScoring}>
                        <TextField
                            label="Title"
                            name="title"
                            id="title"
                            variant="outlined"
                            fullWidth
                            value={option.title}
                            onChange={(event) => handleOptionChangeDialog(index, event.target.name, event.target.value)}
                            style={{ marginTop: '5px' }}
                            error={(errorText[index] ? true : false)}
                            helperText={(index !== undefined) ? errorText[index] : errorText}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={1} className={classes.rubricScoring} alignItems="center">
                        {index > 0 && (
                            <IconButton onClick={() => handleRemoveOptionDialog(index)}>
                                <RemoveIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    );

    const handleOpenDialog = (element: any, idx: number) => {
        setIdx(idx)
        setEditedElement(element);
        setEditDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setEditDialogOpen(false);
        setEditedElement(null);
        setErrorText([]);
        setErrorConstText([]);
        setTitleError('');
    };

    const handleSaveEditedElement = () => {
        if (!titleError && errorText.every(text => !text) && errorConstText.every(text => !text)) {
            let updatedEditedElement = editedElement;

            if (editedElement.type === FormValues.DROPDOWN || editedElement.type === FormValues.DROPDOWN_RANGE) {
                if (isDropdownRange) {
                    updatedEditedElement = { ...editedElement, type: FormValues.DROPDOWN_RANGE, enums: dialogDropdownRangeOptions, showHelpText: helpTextObj.showHelpText, helpText: helpTextObj.helpText };
                } else {
                    updatedEditedElement = { ...editedElement, type: FormValues.DROPDOWN, enums: dialogDropdownOptions };
                }
                setEditedElement(updatedEditedElement);
            }

            setResponse((prevResponse: any) => {
                const updatedRow = [...(prevResponse?.data?.rows[idx] || [])];
                const existingIndex = updatedRow.findIndex((item: any) => item.type === updatedEditedElement.type);

                const updatedRows = { ...prevResponse?.data?.rows };
                updatedRows[idx] = updatedRow;

                if (existingIndex !== -1) {
                    // If element with the same type exists, update it
                    updatedRow[existingIndex] = { ...updatedEditedElement };
                } else {
                    // If element with the same type doesn't exist, add the new element
                    const dropdownOrDropdownRangeIndex = updatedRow.findIndex((item: any) => item.type === FormValues.DROPDOWN || item.type === FormValues.DROPDOWN_RANGE);
                    if(dropdownOrDropdownRangeIndex) {
                        updatedRows[idx][dropdownOrDropdownRangeIndex] = updatedEditedElement;
                    } else {
                        updatedRow.push({ ...updatedEditedElement });
                    }
                }

                return {
                    data: {
                        rows: updatedRows
                    }
                };
            });

            setData((prevData: any) => {
                const updatedData = [...prevData[idx]];
                const index = updatedData.findIndex((item) => item.type === updatedEditedElement.type);

                if (index !== -1) {
                    updatedData[index] = { ...updatedEditedElement };
                } else {
                    // If element with the same type doesn't exist, add the new element
                    const dropdownOrDropdownRangeIndex = updatedData.findIndex((item: any) => item.type === FormValues.DROPDOWN || item.type === FormValues.DROPDOWN_RANGE);
                    if(dropdownOrDropdownRangeIndex) {
                        updatedData[dropdownOrDropdownRangeIndex] = updatedEditedElement;
                    } else {
                        updatedData.push({ ...updatedEditedElement });
                    }
                }
                return { ...prevData, [idx]: updatedData };
            });

            handleCloseDialog();
        }
    };

    const handleSetEditedElement = (updatedElement: any) => {
        // Automatically update the key field based on the title field
        const camelCaseKey = camelCase(updatedElement.title);
        setEditedElement({ ...updatedElement, key: camelCaseKey });
    };

    const renderDialogContent = () => {
        if (!editedElement) return null;
        switch (editedElement.type) {
            case FormValues.STATIC_LABEL:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    handleSetEditedElement({ ...editedElement, [event.target.name]: newTitle });
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <FormGroup>
                            <FormControlLabel
                                label="Visible"
                                name="visible"
                                control={<Checkbox defaultChecked={editedElement.visible}
                                    onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                />}
                            />
                        </FormGroup>
                    </Grid>
                );

            case FormValues.DROPDOWN:
            case FormValues.DROPDOWN_RANGE:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    setEditedElement({ ...editedElement, [event.target.name]: newTitle });
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                fullWidth
                                disabled
                                value={objectName}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Required"
                                    name="required"
                                    control={<Checkbox defaultChecked={editedElement.required}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />

                                <FormControlLabel
                                    label="Range"
                                    name="Range"
                                    control={<Checkbox defaultChecked={isDropdownRange}
                                        onChange={(event) => setIsDropdownRange(event.target.checked)}
                                    />}
                                />
                            </FormGroup>
                        </Grid>

                        {isDropdownRange &&
                            <Grid container>
                                <FormGroup>
                                    <FormControlLabel
                                        label="ShowHelpText"
                                        name="showHelpText"
                                        id="showHelpText"
                                        control={<Checkbox defaultChecked={editedElement.showHelpText}
                                            onChange={(event) => {
                                                setHelpTextObj(prevState => ({
                                                    ...prevState,
                                                    showHelpText: event.target.checked
                                                }));
                                                setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })
                                            }}
                                        />}
                                    />
                                </FormGroup>

                                <Grid item xs={12} sm={12} md={12} className={classes.rubricScoring}>
                                    <TextField
                                        label="HelpText"
                                        name="helpText"
                                        id="helpText"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows="4"
                                        rowsMax="4"
                                        value={editedElement.helpText}
                                        onChange={(event) => {
                                            setHelpTextObj(prevState => ({
                                                ...prevState,
                                                helpText: event.target.value
                                            }));
                                            setEditedElement({ ...editedElement, [event.target.name]: event.target.value })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        }

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <Typography variant="h2">
                                Options
                                <IconButton onClick={handleAddOptionDialog}>
                                    <AddIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                        {isDropdownRange ? addDropdownRangeOptionsDialog : addDropdownOptionsDialog}

                    </Grid>
                );

            case FormValues.SWITCH:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    handleSetEditedElement({ ...editedElement, [event.target.name]: newTitle });
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Default Value"
                                    name="value"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                );

            case FormValues.TEXTBOXAREA:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    handleSetEditedElement({ ...editedElement, [event.target.name]: newTitle });
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={editedElement.key}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Required"
                                    name="required"
                                    control={<Checkbox defaultChecked={editedElement.required}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                );

            case FormValues.TEXTBOX_RANGE:
                return (
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Title"
                                name="title"
                                id="title"
                                variant="outlined"
                                fullWidth
                                value={editedElement.title}
                                onChange={(event) => {
                                    const newTitle = event.target.value;
                                    if (newTitle.trim() === '') {
                                        setTitleError('Title cannot be empty');
                                    } else {
                                        setTitleError('');
                                    }
                                    setEditedElement({ ...editedElement, [event.target.name]: newTitle });
                                }}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="Key"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={`${objectName}Value`}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.rubricScoring}>
                            <TextField
                                label="rangeToValidateWith"
                                name="rangeToValidateWith"
                                id="rangeToValidateWith"
                                variant="outlined"
                                fullWidth
                                value={editedElement.rangeToValidateWith}
                                onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.value })}
                            />
                        </Grid>

                        <Grid container>
                            <FormGroup>
                                <FormControlLabel
                                    label="Required"
                                    name="required"
                                    control={<Checkbox defaultChecked={editedElement.required}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                                <FormControlLabel
                                    label="Visible"
                                    name="visible"
                                    control={<Checkbox defaultChecked={editedElement.visible}
                                        onChange={(event) => setEditedElement({ ...editedElement, [event.target.name]: event.target.checked })}
                                    />}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                );

            default:
                return null;
        }
    };

    const dialog = (
        <Dialog open={editDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Edit Element</DialogTitle>
            <DialogContent>
                {renderDialogContent()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSaveEditedElement} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );

    const renderFormElements = () => {
        return Object.values(data).map((elements: any, idx: number) => {
            return elements.map((value: any, index: number) => {
                switch (value.type) {
                    case FormValues.STATIC_LABEL:
                        return (
                            <Grid container item xs={12} sm={6} md={1} alignItems="center" key={`row_${index}`}>
                                {/* Label Component */}
                                <Grid item>
                                    <LabelComponent title={value.title} />
                                </Grid>
                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.DROPDOWN:
                    case FormValues.DROPDOWN_RANGE:
                        const gridSize = isDropdownRange ? 2 : 4;
                        const options: any[] = isDropdownRange ? dialogDropdownRangeOptions : dialogDropdownOptions;
                        return (
                            <Grid container xs={12} sm={gridSize} md={gridSize} alignItems='center'>
                                {/* Dropdown and Dropdown Range Component */}
                                <Grid item xs={8}>
                                    <Autocomplete
                                        options={options}
                                        fullWidth
                                        getOptionLabel={isDropdownRange ? (obj: any) => obj.title : (obj: any) => obj.label}
                                        disableClearable
                                        id={value.key}
                                        renderInput={(params: any) => (
                                            <TextField
                                                style={{ marginTop: 20, marginBottom: 10 }}
                                                {...params}
                                                autoComplete="none"
                                                label={value.title}
                                                name={value.key}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>

                                {isDropdownRange && helpTextObj.showHelpText ? (
                                    (<Tooltip title={<span className={classes.helperText}>{helpTextObj.helpText ? helpTextObj.helpText : value.helpText}</span>} >
                                        <InfoOutlined
                                            color="action"
                                            className={classes.infoIcon}
                                        />
                                    </Tooltip>)
                                ) : null}


                                {/* Edit Icon */}
                                <Grid item xs={1}>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.SWITCH:
                        return (
                            <Grid container xs={12} sm={4} md={2} className={classes.rubricScoring} key={`row_${index}`}>
                                {/* Switch Component */}
                                <Grid item>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Switch
                                                name={value.title}
                                                defaultChecked={true}
                                                onChange={(event) => {
                                                    setIsComment(event.target.checked)
                                                }}
                                            />}
                                            label={value.title} />
                                    </FormGroup>
                                </Grid>

                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.TEXTBOXAREA:
                        return (isComment) && (
                            <Grid container xs={12} sm={6} md={4} className={classes.rubricScoring} key={`row_${index}`}>
                                {/* TextBoxArea Component */}
                                <Grid item xs={12} sm={6} md={10}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows="2"
                                        rowsMax="2"
                                        id={value.key}
                                        name={value.key}
                                        label={value.title}
                                        inputProps={{
                                            autoComplete: 'off',
                                            tabIndex: -1,
                                        }}
                                    />
                                </Grid>

                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );

                    case FormValues.TEXTBOX_RANGE:
                        return isDropdownRange ? (
                            <div style={{ marginLeft: '2%', display: 'flex', alignItems: 'center', width: '18%' }} key={`row_${index}`}>
                                {/* TextBox Range Component */}
                                <Grid item xs={12} sm={2} md={8}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id={value.key}
                                        name={value.key}
                                        label={value.title}
                                        inputProps={{
                                            autoComplete: 'off',
                                            tabIndex: -1,
                                        }}
                                    />
                                </Grid>

                                {/* Edit Icon */}
                                <Grid item>
                                    <Tooltip title={`Edit_${data[idx][index].title}`}>
                                        <IconButton onClick={() => handleOpenDialog(data[idx][index], idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </div>
                        ) : null

                    default:
                        return null;
                }
            })
        });
    };

    return (
        <Grid container alignItems="center" className={classes.grouperForm} style={{ marginTop: '10px' }}>
            {renderFormElements()}
            {dialog}
        </Grid>
    );
}

export default Form1;