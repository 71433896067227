import { IAppState } from "store/store"
import themis_common from 'store/themis_common_pb'

export const selectCurrentProducer = (state: IAppState): themis_common.Producer.AsObject| undefined =>
  state.producerState?.currentProducer

export const selectCurrentBrands = (state: IAppState): themis_common.Brand.AsObject[] =>
  (state.producerState?.currentProducer.brandsList) ? state.producerState.currentProducer.brandsList : []

export const selectCurrentBrand = (state: IAppState): themis_common.Brand.AsObject | undefined =>
  state.producerState?.currentBrand

export const selectCurrentEvents = (state: IAppState): themis_common.Event.AsObject[] =>
  (state.producerState?.currentBrand.eventsList) ? state.producerState.currentBrand.eventsList : []

export const selectCurrentEvent = (state: IAppState): themis_common.Event.AsObject | undefined =>
  state.producerState?.currentEvent

export const selectCurrentEventDates = (state: IAppState): themis_common.EventDate.AsObject[] | undefined =>
  state.producerState?.currentEvent.eventDatesList

export const selectCurrentEventRegCodes = (state: IAppState): themis_common.EventRegistrationCode.AsObject[] | undefined =>
  state.producerState?.currentEvent.registrationCodesList

export const selectCurrentEventFloors = (state: IAppState): themis_common.EventFloor.AsObject[] | undefined =>
  state.producerState?.currentEvent.eventFloorsList

export const selectProducerJudges = (state: IAppState): themis_common.Judge.AsObject[] =>
  state.producerState?.currentProducer?.judgesList || []

export const selectCurrentEventDivisions = (state: IAppState): themis_common.EventDivision.AsObject[] | undefined =>
  state.producerState?.currentEvent.eventDivisionList

export const selectBrandJudges =(state: IAppState): themis_common.Judge.AsObject[] =>
  state.producerState.currentBrand.judgesList || []
