import React, { ReactElement, useEffect, useState } from 'react'
import { useStyles } from "lib/theme"
import { Container, Grid, Typography } from '@material-ui/core'
import YesNoDialog from 'components/util/YesNoModal'
import FormTextField from 'components/util/FormTextField'
import SubmitButton from 'components/util/SubmitButton'
import { buildErrors, capitalizeFirstLetter } from "lib/validators"
import FindDivision from "components/program/team/FindDivision"
import { AsFields, FormFields } from 'lib/constants'
import themis_common from "store/themis_common_pb"
import { Categories } from 'store/user/userActions'

interface AddEditTeamProps {
  team: themis_common.Team.AsObject | undefined
  division: themis_common.Division.AsObject | null
  updatedTeam: AsFields
  formFields: FormFields
  checkTeam: boolean
  setNoDivisionError: React.Dispatch<React.SetStateAction<boolean>>
  setUpdatedTeam: React.Dispatch<React.SetStateAction<AsFields>>
  setCheckTeam: React.Dispatch<React.SetStateAction<boolean>>
  divisionList: JSX.Element | JSX.Element[]
  teamId: number
  submitForm: (e: { preventDefault: () => void }) => Promise<void>
  setDivision: React.Dispatch<React.SetStateAction<themis_common.Division.AsObject | null>>
  categories: Categories | undefined
  addNewScoringAuthority: (division: themis_common.Division.AsObject) => void
  noDivisionError: boolean
  setIsErrorOpen: React.Dispatch<React.SetStateAction<boolean>>
  isErrorOpen: boolean
}

const AddEditTeam: React.FC<AddEditTeamProps> = ({
  team, division, updatedTeam, formFields, checkTeam, setNoDivisionError, setUpdatedTeam, setCheckTeam,
  divisionList, teamId, submitForm, setDivision, categories, addNewScoringAuthority, noDivisionError,
  setIsErrorOpen, isErrorOpen
}): ReactElement => {
  const classes = useStyles()

  const create = !team?.id ? 'Create' : 'Edit'
  const creating = !team?.id ? 'creating' : 'editing'

  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})


  useEffect(() => {if (division?.id) setNoDivisionError(false)}, [division?.id])

  useEffect(() => {
    if (team && team.id) {
      setUpdatedTeam(team)
    }
  }, [team])


  useEffect(() => {
    if (checkTeam) {
      setErrorText(buildErrors(updatedTeam, formFields, setUpdatedTeam))
      setCheckTeam(false)
    }
  }, [checkTeam, formFields, updatedTeam, division])


  const headerContents = () => {
    if (team?.name) {
      return `${create} Team - ${team.name}`
    } else {
      return `${create} Team`
    }
  }

  if (!categories) return <></>
  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.fullWidth}>{headerContents()}</Typography>
        </Grid>
      </Grid>

      <Container component="main" maxWidth="md">
        {/* Name row */}
        <Grid container>
          <Grid item xs={12} sm={6} md={4} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedTeam}
              setUpdateObject={setUpdatedTeam}
              name="name"
              autoFocus
              sliceSize={100}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8} className={classes.gridForm}>
            <FormTextField
              formFields={formFields}
              errorText={errorText}
              setErrorText={setErrorText}
              updatedObject={updatedTeam}
              setUpdateObject={setUpdatedTeam}
              name="description"
              sliceSize={255}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridForm}>
            <SubmitButton title={`${create} Team`} ariaLabel="submit-team-form" submitForm={submitForm} />
          </Grid>
          {teamId ?
            <>
              <Grid container item xs={12}>
                {divisionList}
              </Grid>
              <Grid container item xs={12}>
                <Typography>Add Division</Typography>
              </Grid>
              <Grid container item xs={12}>
                <FindDivision setDivision={setDivision} categories={categories} classes={classes} addNewScoringAuthority={addNewScoringAuthority} currentList={team?.scoringAuthoritiesList} />
              </Grid>
            </>
            :
            <>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "1rem", fontWeight: "bold", margin: 5 }}>Select Default Division</Typography>
                <Typography variant={"subtitle1"} style={{ color: "#ff1744", fontWeight: "bold", fontSize: ".75rem", margin: 5 }} hidden={!noDivisionError}>Division Required</Typography>
              </Grid>
              <FindDivision setDivision={setDivision} categories={categories} classes={classes} addNewScoringAuthority={null} currentList={team?.scoringAuthoritiesList} />
            </>
          }
        </Grid>
      </Container>
      <YesNoDialog
        title={`Error ${capitalizeFirstLetter(creating)} Team`}
        question={`There was an error ${creating} your team. Please contact us if this continues to be a problem.`}
        isOpen={isErrorOpen}
        onClose={() => setIsErrorOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
        ]}
      />
    </Container>
  )
}

export default AddEditTeam


