import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { getRubricById } from 'store/producer/judgeActions'
import themis_common from 'store/themis_common_pb'
import { Container, Box, Button } from '@material-ui/core'
import  RubricAreaItem  from './RubricAreaItem'
import RubricArea from './RubricArea'
import { useStyles } from "lib/theme"
import { IDivisionApprovalStatus, IDivisionOpenStatus } from '../JudgeEvent'

interface ScoreValue {
  value: string
  comment: string
}

interface ScoreCardContainerProps {
  rubricId: number
  judgeId: number
  currentTeam: themis_common.EventDivisionShiftTeam.AsObject | undefined
  saveScores: Function
  divisionApprovalStatus:IDivisionApprovalStatus[];
  divisionOpenStatus:IDivisionOpenStatus[];
  currentDivisionIndex:number;
}

const ScoreCardContainer: React.FC<ScoreCardContainerProps> = ({ rubricId, currentTeam, judgeId, saveScores,divisionApprovalStatus,divisionOpenStatus,currentDivisionIndex}): ReactElement => {
  const [rubric, setRubric] = useState<themis_common.Rubric.AsObject | null>(null)
  const [formValues, setFormValues] = useState<Record<number,ScoreValue>>({})
  const [rubricAreaScoreTotals, setRubricAreaScoreTotals] = useState<Map<number,number>>(new Map())
  const [totalScore, setTotalScore] = useState("0") // cumulative score of all areas
  const [rubricItemIdToAreaMap, setRubricItemIdToAreaMap] = useState<Map<number,number>>(new Map())
  const [complete, setComplete] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    let noZeros = true
    let countScores = 0

    Object.entries(formValues).forEach((value) => {
      if (Number(value[1].value) === 0) {
        noZeros = false
      }
      countScores ++
    })
    setComplete(noZeros && countScores === 10)

  }, [rubricAreaScoreTotals]) // do not change what fires this

  useEffect(() => {
    load()
    async function load() {
      const localrubric = await getRubricById(rubricId)
      const localRubricIdMap = new Map<number,number>()
      localrubric.rubricAreasList.forEach((area) => {
        const areaId = area.id
        area.rubricAreaItemsList.forEach((item) => {
          localRubricIdMap.set(item.id, areaId)
        })
      })
      setRubricItemIdToAreaMap(localRubricIdMap)
      setRubric(localrubric)
    }
  }, [rubricId, currentTeam?.id])

  useEffect(() => {
    // This sets the initial values when a new team is pulled up
    setRubricAreaScoreTotals(new Map())
    const localValues: Record<number,ScoreValue> = { }
    // Loop through the values incoming and add them up.
    let totalScore = "0"
    const localRubricAreaScores = new Map()
    currentTeam?.eventDivisionShiftTeamRubricAreasList.forEach(area => {
      localRubricAreaScores.set(area.rubricArea?.id, 0)
      area.eventDivisionShiftTeamRubricAreaScoresList.forEach(score => {
        localValues[score.rubricAreaItemId] = { ...localValues[score.rubricAreaItemId], value: Number(score.score) + "" }
        localRubricAreaScores.set(area.rubricArea?.id,localRubricAreaScores.get(area.rubricArea?.id) + Number(score.score) )
        totalScore += score.score
      })
    })
    setRubricAreaScoreTotals(localRubricAreaScores)
    setTotalScore(totalScore)
    setFormValues(localValues)
  }, [currentTeam?.id]) // do not change what fires this

  const handleChange = (id: number, newValue: string) => {
    const localValues = { ...formValues }
    localValues[id] = { ...localValues[id], value: Number(newValue) + "" }
    const localTotal  = Object.entries(localValues).map((localValue) => {
      return localValue[1].value
    }).reduce((prev, next) => {
      return prev + next
    })
    const localRubricAreaScores = new Map()

    // build out rubricAreas base scores
    rubric?.rubricAreasList.forEach((area) => {
      localRubricAreaScores.set(area.id, 0)
    })

    Object.entries(localValues).forEach(([rubricItemId, valObj]) => {
      const areaId = rubricItemIdToAreaMap.get(Number(rubricItemId))
      localRubricAreaScores.set(areaId,localRubricAreaScores.get(areaId) + Number(valObj.value) )
    })

    setRubricAreaScoreTotals(localRubricAreaScores)
    setTotalScore(localTotal)
    setFormValues(localValues)
  }

  const handleCommentChange = (id: number, newValue: string) => {
    const localValues = { ...formValues }
    localValues[id] = { ...localValues[id], comment: newValue }
    setFormValues(localValues)
  }

  const getRubricAreaItems = useCallback((areaItemsList: themis_common.RubricAreaItem.AsObject[]) => {
    return areaItemsList.map((item) => {
      let initialScore: themis_common.EventDivisionShiftTeamRubricAreaScore.AsObject = {
        comment: "",
        id: 0,
        itemsValues: "",
        rubricAreaId: 0,
        rubricAreaItemId: 0,
        score: "0",
        dynamicFormJsonValues: ""
      }
      currentTeam?.eventDivisionShiftTeamRubricAreasList.map(area => area.eventDivisionShiftTeamRubricAreaScoresList.map(score => {
        if (score.rubricAreaItemId === item.id) {
          initialScore = score
        }
      }) )
      return <RubricAreaItem key={item.id} rubricAreaItem={item} handleInputChange={handleChange} 
      handleCommentChange={handleCommentChange} formValues={formValues} 
      initialScore={initialScore} currentTeamId={currentTeam?.id || 0}
      divisionApprovalStatus={divisionApprovalStatus} divisionOpenStatus={divisionOpenStatus} 
      currentDivisionIndex={currentDivisionIndex} />
    })
  }, [currentTeam?.eventDivisionShiftTeamRubricAreasList, formValues, handleChange, handleCommentChange])

  const getRubicAreas = () => {
    if (rubric === null) {
      return null
    }
    return rubric.rubricAreasList.map((rubricArea: themis_common.RubricArea.AsObject) => {
      const areaScoreTotal = rubricAreaScoreTotals.has(rubricArea.id) ? rubricAreaScoreTotals.get(rubricArea.id): 0
      return (
        <RubricArea key={rubricArea.id} name={rubricArea.name} score={areaScoreTotal}>
          {getRubricAreaItems(rubricArea.rubricAreaItemsList)}
        </RubricArea>
      )
    })
  }

  const doSaveScores = useCallback(() => {
    const scores: themis_common.EventDivisionShiftTeamRubricAreaScore.AsObject[] = []
    // Get the scores
    const localValues = { ...formValues }
    Object.entries(localValues).forEach(([rubricItemId, valObj]) => {
      const area = rubric?.rubricAreasList.find((area) => area.rubricAreaItemsList.find(item => item.id === Number(rubricItemId)))

      const judge: themis_common.Judge.AsObject = {
        brandId: 0,
        id: judgeId,
        judgeProducersList: [],
        name: "",
        producerId: 0
      }
      const score: themis_common.EventDivisionShiftTeamRubricAreaScore.AsObject = {
        rubricAreaId: area?.id || 0,
        comment: valObj.comment,
        id: 0,
        itemsValues: "",
        judge: judge,
        rubricAreaItemId: Number(rubricItemId),
        score: valObj.value,
        dynamicFormJsonValues: ""
      }

      scores.push(score)
    })
    saveScores(scores)
  }, [formValues, saveScores, rubric?.rubricAreasList, judgeId])

  return (
    <>
      <Container>
        <Box>{getRubicAreas()}</Box>
        <hr />
        <h3>Total Score: {totalScore}</h3>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={ classes.submit }
          disabled={ !complete }
          onClick={() => { doSaveScores() }}
        >
              Submit Scores
        </Button>
      </Container>

    </>
  )
}

export default ScoreCardContainer
