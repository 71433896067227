import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import themis_api from 'store/themis_api_pb'
import { getShiftReportData } from "../../../store/user/userActions"
import { Container, Grid, Typography } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { convertToNumber } from 'lib/functions'


const ShiftReport: React.FC = (): ReactElement => {

  const { shiftId: inputShiftId } = useParams<{ shiftId?: string }>()
  const shiftId = Number(inputShiftId)


  const [data, setData] = useState<themis_api.ShiftScores.AsObject>()

  useEffect(() => {
    const getReportData = async () => {
      const data = await getShiftReportData(shiftId)
      console.log(data)
      setData(data)
    }
    getReportData()
  }, [shiftId])

  const shiftReport = useMemo(() => {
    if (data?.shift) {
      return (<Container><Grid container>
        <Grid item xs={12}>
          <Typography variant="h2">Shift Report {data.panel?.name} - {data.shift.startTs}</Typography>
        </Grid>
        { data.divisionScoresList.map((division) => (
          <Grid container item xs={12} spacing={2} style={{ marginTop: 36 }}>
            <Typography variant="h3">Division: {division.division?.name} - {division.division?.division?.tier}</Typography>
            <Grid container><Grid item xs={2}><Typography variant="h4">Rank</Typography></Grid><Grid item xs={2}><Typography variant="h4">Percent</Typography></Grid><Grid item xs={4}><Typography variant="h4">Program</Typography></Grid><Grid item xs={4}><Typography variant="h4">Name</Typography></Grid></Grid>
            { division.teamScores?.teamScoresList.map(team => (
              <Grid container><Grid item xs={2}>{team.rank}</Grid><Grid item xs={2}>{Math.round(convertToNumber(team.score) * 100) / 100}</Grid><Grid item xs={4}>{team.program}</Grid><Grid item xs={4}>{team.name}</Grid></Grid>
            ))}
          </Grid>
        ))}
      </Grid></Container>)
    } else {
      return (<Typography variant="h2">Loading Data...</Typography>)
    }
  }, [data])

  return shiftReport
}

export default ShiftReport
