import { client } from "store/themisClient"
import themis_api from "store/themis_api_pb"
import themis_common from "store/themis_common_pb"

export async function getUserPaymentSources(): Promise<themis_common.StripeSources.AsObject> {
  const request = new themis_api.EmptyMessage()

  const response: themis_common.StripeSources.AsObject = (await client.getUserPaymentSources(request, {})).toObject()
  return response
}

export async function addACHSource(token: string): Promise<themis_common.StripeBankAccount.AsObject> {
  const request = new themis_api.AddStripeSourceRequest()
  request.setToken(token)

  const response: themis_common.StripeBankAccount.AsObject = (await client.addACHSource(request, {})).toObject()
  return response
}

export async function verifyACHSource(verifyACHSourceRequest: themis_api.VerifyACHSourceRequest.AsObject): Promise<themis_common.StripeBankAccount.AsObject> {
  const request = new themis_api.VerifyACHSourceRequest()
  request.setId(verifyACHSourceRequest.id)
  request.setAmount1(verifyACHSourceRequest.amount1)
  request.setAmount2(verifyACHSourceRequest.amount2)

  const response: themis_common.StripeBankAccount.AsObject = (await client.verifyACHSource(request, {})).toObject()
  return response
}

export async function addCreditCardSource(token: string): Promise<themis_common.StripeCreditCard.AsObject> {
  const request = new themis_api.AddStripeSourceRequest()
  request.setToken(token)

  const response: themis_common.StripeCreditCard.AsObject = (await client.addCreditCardSource(request, {})).toObject()
  return response
}
