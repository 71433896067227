import { Categories, PermissionLists } from "./userActions"

import {
  SET_CATEGORIES,
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
  SET_PERMISSIONS,
  SET_REGISTER_PROGRAM,
  SET_TRY_LOGIN,
  SET_PERMISSION_CODES,
} from "lib/constants"

import themis_common from "store/themis_common_pb"

export interface Permission {
  id: number
  name: string
  logo?: string
  role?: string
}

export interface UserProgram {
  permission: Permission
  locationsList?: Permission[]
}

// export interface EventPermission {
//   id: number;
//   name: string;
//   perms: string[];
// }
export type EventPermission = string[]

export interface EventPermissions {
  [key: string]: EventPermission
}

export interface BrandPermission {
  id: number;
  name: string;
  logo: string;
  perms: string[];
  events: EventPermissions;
}

export interface BrandPermissions {
  [key: string]: BrandPermission
}

export interface ProducerPermission {
  id: number;
  name: string;
  logo: string;
  perms: string[];
  brands: BrandPermissions;
}

export interface ProducerPermissions {
  [key: string]: ProducerPermission
}



export interface JudgeShift {
  shiftId: number
  floorName: string
  panelName: string
  startTime: string
  divs: number
  day: string
  judgeId: number
  judgeTypes: any
  eventJudgeShiftId: number
}

export interface JudgeEventPermissions {
  [key: number]: JudgeEventPermission
}

export interface JudgeEventPermission {
  name: string;
  logo: string;
  id: number;
  eventStartDate: string;
  shifts: { [key: number]: JudgeShift }
  judgeId: number
}

export type TeamPermission = string[]

export interface TeamPermissions {
  [key: string]: TeamPermission
}

export interface LocationPermission {
  id: number;
  name: string;
  perms: string[];
  teams: TeamPermissions;
}

export interface LocationPermissions {
  [key: string]: LocationPermission
}

export interface ProgramPermission {
  id: number;
  name: string;
  logo: string;
  perms: string[];
  locations: LocationPermissions;
}

export interface ProgramPermissions {
  [key: string]: ProgramPermission
}

export interface IUserState {
  user: any;
  loggedIn: boolean;
  tryLogin: boolean;
  registerProgram: boolean;
  permissions: {
    programs: ProgramPermissions;
    producers: ProducerPermissions;
    judges: JudgeEventPermissions;
  }
  program: boolean;
  producer: boolean;
  judge: boolean;
  categories: Categories;
  permissionLists: PermissionLists;
  permissionCodes: themis_common.PermissionCodes.AsObject
}

export const emptyCategories: Categories = {
  scoringAuthorities: [],
  allStarTypes: [],
  tiers: [],
  levels: [],
  divisions: [],
}

export const emptyPermissions: PermissionLists = {
  program: [],
  location: [],
  team: [],
  producer: [],
  brand: [],
  event: [],
}

export const emptyPermissionCodes: themis_common.PermissionCodes.AsObject = (new themis_common.PermissionCodes()).toObject()

export const initialUserState: IUserState = {
  user: null,
  loggedIn: false,
  tryLogin: true,
  registerProgram: false,
  permissions: {
    programs: {},
    producers: {},
    judges: [],
  },
  program: false,
  producer: false,
  judge: false,
  categories: emptyCategories,
  permissionLists: emptyPermissions,
  permissionCodes: emptyPermissionCodes,
}

interface UserAction {
  type: string;
  payload: any; // NB this one is okay
}

export function userReducer(state: IUserState = initialUserState, action: UserAction): IUserState {
  switch (action.type) {
    case SET_LOGGED_IN: {
      delete action.payload.user.ProducerPermissions
      delete action.payload.user.ProgramPermissions
      return {
        ...state,
        user: action.payload.user,
        permissions: action.payload.permissions,
        program: action.payload.program,
        producer: action.payload.producer,
        judge: action.payload.judge,
        loggedIn: true,
        tryLogin: action.payload.tryLogin,
      }
    }
    case SET_LOGGED_OUT: {
      return {
        ...state,
        ...initialUserState,
        tryLogin: false,
        loggedIn: false,
      }
    }
    case SET_TRY_LOGIN: {
      return {
        ...state,
        tryLogin: action.payload
      }
    }
    case SET_REGISTER_PROGRAM: {
      return {
        ...state,
        registerProgram: action.payload
      }
    }
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      }
    }
    case SET_PERMISSIONS: {
      return {
        ...state,
        permissionLists: action.payload
      }
    }
    case SET_PERMISSION_CODES: {
      return {
        ...state,
        permissionCodes: action.payload
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}
