import { client } from "store/themisClient"
import themis_common, { Athlete, Coach, EventTeam, Personnel } from "store/themis_common_pb"

import { AnyDispatchType } from "lib/constants"
import themis_api from "../themis_api_pb"
import { emptyEventTeam, emptyPerson } from "./programReducer"
import { buildPerson } from "./programBuilders"
export interface RemoveEventTeamAthlete {
  id: number | null;
  teamId: number | null;
  programId: number | null;
  locationId: number | null;
  event: {
    id: number | null;
  };
  athletesList: themis_common.Athlete.AsObject[];
}


export async function getEventTeam(eventTeamId: number, programId: number, locationId: number, teamId: number): Promise<themis_common.EventTeam.AsObject> {
  const eventTeamRequest = new themis_common.EventTeam()
  eventTeamRequest.setId(eventTeamId)
  eventTeamRequest.setProgramId(programId)
  eventTeamRequest.setLocationId(locationId)
  eventTeamRequest.setTeamId(teamId)
  return (await client.getEventTeam(eventTeamRequest, {})).toObject()
}

export async function updateEventTeamDivision(eventTeamId: number, eventId: number, programId: number, locationId: number, teamId: number, newDivision: themis_common.EventDivision.AsObject, newRegistrationCode: themis_common.EventRegistrationCode.AsObject, athletes: themis_common.Athlete.AsObject[], isProducerUpdate: boolean, eventTeamnName: string, programName: string): Promise<themis_common.EventTeam.AsObject> {
  const eventTeamRequest = new themis_common.EventTeam()

  eventTeamRequest.setId(eventTeamId)
  eventTeamRequest.setProgramId(programId)
  eventTeamRequest.setLocationId(locationId)
  eventTeamRequest.setTeamId(teamId)
  eventTeamRequest.setIsProducerUpdate(isProducerUpdate)
  eventTeamRequest.setName(eventTeamnName)
  eventTeamRequest.setProgramName(programName)

  const athletesList: themis_common.Athlete[] = []
  athletes.forEach((a) => {
    const athlete = new themis_common.Athlete()
    athlete.setId(a.id)
    athletesList.push(athlete)
  })
  eventTeamRequest.setAthletesList(athletesList)

  const event = new themis_common.Event()
  event.setId(eventId)
  eventTeamRequest.setEvent(event)

  const eventDivision = new themis_common.EventDivision()
  eventDivision.setId(newDivision.id)
  const division = new themis_common.Division()
  division.setId(newDivision.division?.id || 0)
  eventDivision.setDivision(division)

  const eventRegistrationCode = new themis_common.EventRegistrationCode()
  eventRegistrationCode.setId(newRegistrationCode.id)

  eventTeamRequest.setEventDivision(eventDivision)
  eventTeamRequest.setEventRegistrationCode(eventRegistrationCode)


  return (await client.updateEventTeamDivision(eventTeamRequest, {})).toObject()
}

export async function getEventTeamSignStatus(eventTeamId: number, programId: number, locationId: number, teamId: number): Promise<themis_api.EventTeamSignStatus.AsObject> {
  const eventTeamRequest = new themis_common.EventTeam()
  eventTeamRequest.setId(eventTeamId)
  eventTeamRequest.setLocationId(locationId)
  eventTeamRequest.setProgramId(programId)
  eventTeamRequest.setTeamId(teamId)
  return (await client.getEventTeamSignStatus(eventTeamRequest, {})).toObject()
}

export async function getEventTeamTransactions(eventTeamId: number, programId: number, locationId: number, teamId: number, producerId: number): Promise<themis_api.GetEventTeamRegistrationTransactionsResponse.AsObject> {
  const eventTeamRequest = new themis_common.EventTeam()
  eventTeamRequest.setId(eventTeamId)
  eventTeamRequest.setProgramId(programId)
  eventTeamRequest.setLocationId(locationId)
  eventTeamRequest.setTeamId(teamId)
  const event = new themis_common.Event()
  event.setProducerId(producerId)
  eventTeamRequest.setEvent(event)
  return (await client.getEventTeamRegistrationTransactions(eventTeamRequest, {})).toObject()
}

export async function resendEmail(athleteId: number, eventId: number, programId: number, locationId: number, teamId: number): Promise<void> {
  const resendEmailRequest = new themis_api.ResendEmailRequest()
  resendEmailRequest.setAthleteId(athleteId)
  resendEmailRequest.setEventId(eventId)
  resendEmailRequest.setProgramId(programId)
  resendEmailRequest.setLocationId(locationId)
  resendEmailRequest.setTeamId(teamId)
  await client.resendEmailToAthleteGuardians(resendEmailRequest, {})
}

export async function checkForCrossovers(athletes: themis_common.Athlete.AsObject[], eventTeamId: number, eventId: number, programId: number, locationId: number, teamId: number): Promise<themis_common.Athlete.AsObject[]> {
  if (athletes.length === 0 || !eventId || !programId || !locationId || !teamId) return []
  eventTeamId = eventTeamId || 0
  const eventTeam = new themis_common.EventTeam()
  const as: themis_common.Athlete[] = []
  athletes.forEach(athlete => {
    const a = new themis_common.Athlete()
    a.setId(athlete.id)
    as.push(a)
  })
  eventTeam.setAthletesList(as)
  eventTeam.setId(eventTeamId)
  eventTeam.setProgramId(programId)
  eventTeam.setLocationId(locationId)
  eventTeam.setTeamId(teamId)
  const event = new themis_common.Event()
  event.setId(eventId || 0)
  eventTeam.setEvent(event)
  const returnVal = (await client.checkForCrossovers(eventTeam, {})).toObject()
  return returnVal.athletesList
}

export const findEventRegistrationCodeForEvent = async (eventRegistrationCode: string, eventId: number): Promise<themis_common.EventRegistrationCode.AsObject> => {
  const eventRegistrationCodeRequest = new themis_common.EventRegistrationCode()
  eventRegistrationCodeRequest.setCode(eventRegistrationCode)
  eventRegistrationCodeRequest.setEventId(eventId)
  return (await client.findEventRegistrationCodeForEvent(eventRegistrationCodeRequest, {})).toObject()
}

export async function getEventTeamsForEventGridByProgramId(programId: number, fromDate: string): Promise<themis_common.EventTeam.AsObject[]> {
  const program = new themis_common.Program()
  program.setId(programId)
  // Build EventGridRequest
  const request = new themis_api.EventGridRequest()
  request.setProgram(program)
  request.setFromDate(fromDate)
  const returnEventTeams: themis_api.EventGridResponse.AsObject = (await client.getEventTeamsForEventGridByProgramID(request, {})).toObject()
  return returnEventTeams.eventTeamsList
}

export async function getEventTeamsForEventGridByLocationId(programId: number, locationId: number, fromDate: string): Promise<themis_common.EventTeam.AsObject[]> {
  const program = new themis_common.Program()
  program.setId(programId)
  const location = new themis_common.Location()
  location.setId(locationId)
  // Build EventGridRequest
  const request = new themis_api.EventGridRequest()
  request.setProgram(program)
  request.setLocation(location)
  request.setFromDate(fromDate)
  const returnEventTeams: themis_api.EventGridResponse.AsObject = (await client.getEventTeamsForEventGridByLocationID(request, {})).toObject()
  return returnEventTeams.eventTeamsList
}

export async function getEventTeamsForEventGridByTeamId(programId: number, locationId: number, teamId: number, fromDate: string): Promise<themis_common.EventTeam.AsObject[]> {
  const program = new themis_common.Program()
  program.setId(programId)
  const location = new themis_common.Location()
  location.setId(locationId)
  const team = new themis_common.Team()
  team.setId(teamId)
  // Build EventGridRequest
  const request = new themis_api.EventGridRequest()
  request.setProgram(program)
  request.setLocation(location)
  request.setTeam(team)
  request.setFromDate(fromDate)
  const returnEventTeams: themis_api.EventGridResponse.AsObject = (await client.getEventTeamsForEventGridByTeamID(request, {})).toObject()
  return returnEventTeams.eventTeamsList
}

export const doUnregisterEventTeam = async (dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, eventTeamId: number, eventId: number, producerId: number, brandId: number): Promise<void> => {
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setProgramId(programId)
  eventTeam.setLocationId(locationId)
  eventTeam.setTeamId(teamId)
  eventTeam.setId(eventTeamId)
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setProducerId(producerId)
  event.setBrandId(brandId)
  eventTeam.setEvent(event)
  await client.removeEventTeam(eventTeam, {})
}

// TODO:  BE should check that calculated price on register is same as the one displayed
export const doRegisterEventTeam = async (
  dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, eventId: number, brandId: number, producerId: number, programName: string, locationName: string, teamName: string, eventDivisionData: themis_common.EventDivision.AsObject,
  athletes: themis_common.Athlete.AsObject[], coaches: themis_common.Coach.AsObject[], personnel: themis_common.Personnel.AsObject[], registrationDivisionPriceId: number, eventData: themis_common.Event.AsObject, eventRegistrationCodeData: themis_common.EventRegistrationCode.AsObject): Promise<themis_common.EventTeam.AsObject> => {
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setProgramId(programId)
  eventTeam.setLocationId(locationId)
  eventTeam.setTeamId(teamId)
  eventTeam.setName(teamName)
  eventTeam.setLocationName(locationName)
  eventTeam.setProgramName(programName)
  const event = new themis_common.Event()
  event.setId(eventId)
  event.setName(eventData.name)
  event.setCurrency(eventData.currency)
  // Don't think this is being used now
  const eventPricing = new themis_common.EventPricing()
  if (eventData.eventPricing?.id) {
    eventPricing.setId(eventData.eventPricing.id)
    eventPricing.setAthletePriceString(eventData.eventPricing.athletePriceString)
    eventPricing.setCrossoverPriceString(eventData.eventPricing.crossoverPriceString)
  }
  event.setEventPricing(eventPricing)
  // Get the producer Id
  event.setProducerId(producerId)
  event.setBrandId(brandId)
  eventTeam.setEvent(event)
  const eventDivision = new themis_common.EventDivision()
  eventDivision.setId(eventDivisionData.id)
  const division = new themis_common.Division()
  division.setId(eventDivisionData.division?.id || 0)
  eventDivision.setDivision(division)
  // Don't think this is being used now
  const eventDivisionPricing = new themis_common.EventDivisionPricing()
  if (registrationDivisionPriceId) {
    eventDivisionPricing.setId(registrationDivisionPriceId)
    eventDivisionPricing.setAthletePriceString(eventDivisionData.eventDivisionPricing?.athletePriceString || "0")
    eventDivisionPricing.setTeamPriceString(eventDivisionData.eventDivisionPricing?.teamPriceString || "0")
  }
  eventDivision.setEventDivisionPricing(eventDivisionPricing)
  eventTeam.setEventDivision(eventDivision)

  const eventRegistrationCode = new themis_common.EventRegistrationCode()
  eventRegistrationCode.setId(eventRegistrationCodeData.id > 0 ? eventRegistrationCodeData.id : 0)
  eventTeam.setEventRegistrationCode(eventRegistrationCode)

  const as: themis_common.Athlete[] = []
  athletes.forEach(athlete => {
    const a = new themis_common.Athlete()
    a.setId(athlete.id)
    a.setName(athlete.name)
    as.push(a)
  })
  eventTeam.setAthletesList(as)

  const cs: themis_common.Coach[] = []
  coaches.forEach(coach => {
    const c = new themis_common.Coach()
    c.setId(coach.id)
    cs.push(c)
  })
  eventTeam.setCoachesList(cs)

  const ps: themis_common.Personnel[] = []
  personnel.forEach(personnel => {
    const p = new themis_common.Personnel()
    p.setId(personnel.id)
    ps.push(p)
  })
  eventTeam.setPersonnelList(ps)

  const ret = await client.addEventTeam(eventTeam, {})
  return ret.toObject()
}

export const removeEventTeamCoach = async (dispatch: AnyDispatchType, currentEventTeam: EventTeam.AsObject, currentCoach: Coach.AsObject): Promise<themis_common.EventTeam.AsObject> => {
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setId(currentEventTeam.id)
  eventTeam.setProgramId(currentEventTeam.programId)
  eventTeam.setLocationId(currentEventTeam.locationId)
  eventTeam.setTeamId(currentEventTeam.teamId)

  const cs: themis_common.Coach[] = []
  const coach = new themis_common.Coach()
  coach.setId(currentCoach.id)
  cs.push(coach)
  eventTeam.setCoachesList(cs)

  const ret = await client.removeEventTeamCoach(eventTeam, {})
  // dispatch()
  return ret.toObject()
}

export const addEventTeamCoach = async (dispatch: AnyDispatchType, currentEventTeam: EventTeam.AsObject, currentCoach: Coach.AsObject): Promise<themis_common.EventTeam.AsObject> => {
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setId(currentEventTeam.id)
  eventTeam.setProgramId(currentEventTeam.programId)
  eventTeam.setLocationId(currentEventTeam.locationId)
  eventTeam.setTeamId(currentEventTeam.teamId)

  const cs: themis_common.Coach[] = []
  const coach = new themis_common.Coach()
  coach.setId(currentCoach.id)
  cs.push(coach)
  eventTeam.setCoachesList(cs)

  const ret = await client.addEventTeamCoach(eventTeam, {})
  // dispatch()
  return ret.toObject()
}

export const addEventTeamPersonnel = async (dispatch: AnyDispatchType, currentEventTeam: EventTeam.AsObject, currentPersonnel: Personnel.AsObject): Promise<themis_common.EventTeam.AsObject> => {
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setId(currentEventTeam.id)
  eventTeam.setProgramId(currentEventTeam.programId)
  eventTeam.setLocationId(currentEventTeam.locationId)
  eventTeam.setTeamId(currentEventTeam.teamId)

  const ps: themis_common.Personnel[] = []
  const personnel = new themis_common.Personnel()
  personnel.setId(currentPersonnel.id)
  ps.push(personnel)
  eventTeam.setPersonnelList(ps)

  const ret = await client.addEventTeamPersonnel(eventTeam, {})
  // dispatch()
  return ret.toObject()
}

export const removeEventTeamPersonnel = async (dispatch: AnyDispatchType, currentEventTeam: EventTeam.AsObject, currentPersonnel: Personnel.AsObject): Promise<themis_common.EventTeam.AsObject> => {
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setId(currentEventTeam.id)
  eventTeam.setProgramId(currentEventTeam.programId)
  eventTeam.setLocationId(currentEventTeam.locationId)
  eventTeam.setTeamId(currentEventTeam.teamId)

  const cs: themis_common.Personnel[] = []
  const personnel = new themis_common.Personnel()
  personnel.setId(currentPersonnel.id)
  cs.push(personnel)
  eventTeam.setPersonnelList(cs)

  const ret = await client.removeEventTeamPersonnel(eventTeam, {})
  // dispatch()
  return ret.toObject()
}

export const addEventTeamAthlete = async (dispatch: AnyDispatchType, currentEventTeam: EventTeam.AsObject, currentAthlete: Athlete.AsObject): Promise<themis_common.EventTeam.AsObject> => {
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setId(currentEventTeam.id)
  eventTeam.setProgramId(currentEventTeam.programId)
  eventTeam.setLocationId(currentEventTeam.locationId)
  eventTeam.setTeamId(currentEventTeam.teamId)

  const as: themis_common.Athlete[] = []
  const athlete = new themis_common.Athlete()
  athlete.setId(currentAthlete.id)
  athlete.setName(currentAthlete.name)
  as.push(athlete)
  eventTeam.setAthletesList(as)

  const ret = await client.addEventTeamAthlete(eventTeam, {})
  return ret.toObject()

}

export const removeEventTeamAthlete = async (dispatch: AnyDispatchType, currentEventTeam: EventTeam.AsObject, currentAthlete: Athlete.AsObject): Promise<themis_common.EventTeam.AsObject> => {
  if (!currentEventTeam.event?.id) return emptyEventTeam
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setId(currentEventTeam.id)
  eventTeam.setProgramId(currentEventTeam.programId)
  eventTeam.setLocationId(currentEventTeam.locationId)
  eventTeam.setTeamId(currentEventTeam.teamId)

  const event = new themis_common.Event()
  event.setId(currentEventTeam.event.id)
  eventTeam.setEvent(event)

  const as: themis_common.Athlete[] = []
  const athlete = new themis_common.Athlete()
  athlete.setId(currentAthlete.id)
  as.push(athlete)
  eventTeam.setAthletesList(as)

  const ret = await client.removeEventTeamAthlete(eventTeam, {})
  return ret.toObject()
}

export const getEventTeamOutstanding = async (dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, eventTeamId: number): Promise<themis_common.EventTeamBalance.AsObject> => {
  const eventTeam = new themis_common.EventTeam()
  eventTeam.setId(eventTeamId)
  eventTeam.setProgramId(programId)
  eventTeam.setLocationId(locationId)
  eventTeam.setTeamId(teamId)
  const res: themis_common.EventTeamBalance = await client.getTotalOutstandingForEventTeam(eventTeam, {})
  return res.toObject()
}

export const getCalculatedDisplayPricing = async (dispatch: AnyDispatchType, programId: number, locationId: number, teamId: number, eventId: number, eventData: themis_common.Event.AsObject, eventDivisionData: themis_common.EventDivision.AsObject, athletesData: themis_common.Athlete.AsObject[], registrationCodeId: number): Promise<themis_common.EventTeamDisplayPricing.AsObject> => {
  const prospectEventTeam = new themis_common.EventTeam()
  prospectEventTeam.setProgramId(programId)
  prospectEventTeam.setLocationId(locationId)
  prospectEventTeam.setTeamId(teamId)

  const event = new themis_common.Event()
  event.setId(eventId)
  const eventPricing = new themis_common.EventPricing()
  if (eventData.eventPricing?.id) {
    eventPricing.setId(eventData.eventPricing.id)
  }
  event.setEventPricing(eventPricing)
  prospectEventTeam.setEvent(event)

  const eventDivision = new themis_common.EventDivision()
  eventDivision.setId(eventDivisionData.id)
  prospectEventTeam.setEventDivision(eventDivision)

  const eventRegistrationCode = new themis_common.EventRegistrationCode()
  eventRegistrationCode.setId(registrationCodeId)
  prospectEventTeam.setEventRegistrationCode(eventRegistrationCode)

  const athletes: themis_common.Athlete[] = []
  athletesData.forEach(athlete => {
    const a = new themis_common.Athlete()
    a.setId(athlete.id)
    athletes.push(a)
  })
  prospectEventTeam.setAthletesList(athletes)

  const res: themis_common.EventTeamDisplayPricing = await client.getCalculatedDisplayPricing(prospectEventTeam, {})
  return res.toObject()
}

export const addTeamAndEventTeam = async (producerId: number, brandId: number, eventId: number, programId: number, locationId: number, teamName: string, athleteCount: number, programName: string, locationName: string, eventDivisionId: number, eventPricingId: number, eventDivisionPricingId: number, eventRegCodeId: number, crossoverAthleteCount: number): Promise<void> => {
  const producerAddTeamRequest = new themis_api.ProducerAddTeamRequest()
  producerAddTeamRequest.setProducerId(producerId)
  producerAddTeamRequest.setBrandId(brandId)
  producerAddTeamRequest.setEventId(eventId)
  producerAddTeamRequest.setProgramId(programId)
  producerAddTeamRequest.setLocationId(locationId)
  producerAddTeamRequest.setTeamName(teamName)

  const athletes: themis_common.Athlete[] = []
  for (let i = 0; i < athleteCount; i++) {
    const athlete = new themis_common.Athlete()
    athlete.setName("Created Athlete #" + (i + 1))
    athlete.setProgramId(programId)
    athlete.setLocationId(locationId)
    athlete.setPerson(buildPerson(emptyPerson))
    athletes.push(athlete)
  }
  producerAddTeamRequest.setAthletesList(athletes)

  producerAddTeamRequest.setProgramName(programName)
  producerAddTeamRequest.setLocationName(locationName)

  const eventDivision = new themis_common.EventDivision()
  eventDivision.setId(eventDivisionId)
  producerAddTeamRequest.setEventDivision(eventDivision)

  const event = new themis_common.Event()
  event.setId(eventId)
  producerAddTeamRequest.setEvent(event)

  const eventDivisionPricing = new themis_common.EventDivisionPricing()
  eventDivisionPricing.setId(eventDivisionPricingId)
  producerAddTeamRequest.setEventDivisionPricing(eventDivisionPricing)

  const eventPricing = new themis_common.EventPricing()
  eventPricing.setId(eventPricingId)
  producerAddTeamRequest.setEventPricing(eventPricing)

  producerAddTeamRequest.setEventRegCodeId(eventRegCodeId)
  producerAddTeamRequest.setCrossoverAthleteCount(crossoverAthleteCount)

  await client.addProducerGeneratedTeamAndEventTeam(producerAddTeamRequest, {})
}

export const registrationRemoveEventTeamAthlete = async (
  payload: RemoveEventTeamAthlete
) => {
  const {
    id,
    athletesList,
    event,
    locationId,
    programId,
    teamId,
  } = payload;
  const eventTeam = new themis_common.EventTeam();
  eventTeam.setId(id as number);
  eventTeam.setProgramId(programId as number);
  eventTeam.setLocationId(locationId as number);
  eventTeam.setTeamId(teamId as number);
  const athleteListArray: themis_common.Athlete[] = [];
  const newEvent = new themis_common.Event();
  athletesList.forEach((athlete) => {
    const { id } = athlete;
    const athleteObject = new themis_common.Athlete();
    athleteObject.setId(id);
    athleteListArray.push(athleteObject);
  });
  eventTeam.setAthletesList(athleteListArray);
  newEvent.setId(event.id as number);
  eventTeam.setEvent(newEvent);
  await client.removeEventTeamAthlete(eventTeam, {});
};