/*  Imports from Redux:
 applyMiddleware: Applies middleware to the dispatch method of the Redux store
 combineReducers: Merges reducers into one
 createStore: Creates a Redux store that holds the state tree
 Store: The TS Type used for the store, or state tree
 */
import {
  applyMiddleware,
  combineReducers,
  createStore,
  Store
} from 'redux'

/*  Thunk
Redux Thunk middleware allows you to write action creators that return a function instead of an
action. The thunk can be used to delay the dispatch of an action, or to dispatch only if a certain
condition is met. The inner function receives the store methods dispatch and getState as parameters.
*/

import thunk from 'redux-thunk'
// Import reducers and state type
import { composeWithDevTools } from 'redux-devtools-extension'
import { IUserState, userReducer, initialUserState } from './user/userReducer'
import { IProgramState, programReducer, initialProgramState } from './program/programReducer'
import { IProducerState, producerReducer, initialProducerState } from './producer/producerReducer'

// Create an interface for the application state
export interface IAppState {
  userState: IUserState;
  programState: IProgramState;
  producerState: IProducerState;
}

export const initialState: IAppState = {
  userState: initialUserState,
  programState: initialProgramState,
  producerState: initialProducerState,
}

// Create the root reducer
const rootReducer = combineReducers<IAppState>({
  userState: userReducer,
  programState: programReducer,
  producerState: producerReducer,
})

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 100 })

let appliedMiddleWare = applyMiddleware(thunk)

if (process.env.NODE_ENV === 'development') {
  appliedMiddleWare = composeEnhancers(
    applyMiddleware(thunk)
  )
}


// Create a configure store function of type `IAppState`
export default function configureStore(startingState?: IAppState): Store<IAppState, any> {
  const store = createStore(rootReducer, startingState || initialState, appliedMiddleWare)
  return store
}
