import React, { ReactNode, useMemo, useState, useEffect, ReactElement } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Transition, useStyles } from "lib/theme"
import { addDays, addHours, format, parse } from "date-fns"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, Typography } from '@material-ui/core'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { Add as AddIcon, Delete as DeleteIcon, AccessTime as AccessTimeIcon } from '@material-ui/icons'
import { IAppState } from "store/store"
import { selectCurrentEventDates } from "store/producer/producerSelectors"
import { EventDate } from "store/producer/producerReducer"
import { addEventDate, deleteEventDate, formatEventDates } from "store/producer/eventActions"
import themis_common from "store/themis_common_pb"
import { getCountryDateFormat } from 'lib/functions'
import HelpIcon from 'components/util/HelpIcon'

interface eventDatesProps {
  producerId: number,
  brandId: number,
  eventId: number,
  userCanViewEvent: boolean,
  userCanEditEvent: boolean,
  event: themis_common.Event.AsObject
}

const EventDates: React.FC<eventDatesProps> = ({ producerId, brandId, eventId, userCanViewEvent, userCanEditEvent, event }): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const defaultDate = useMemo(() => event?.firstDay, [event])

  const eventDates = useSelector((state: IAppState) => selectCurrentEventDates(state))

  const [newEventDate, setNewEventDate] = useState(defaultDate)
  const [newDateStart, setNewDateStart] = useState('08:00')
  const [newDateEnd, setNewDateEnd] = useState('17:00')

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedStartTime, setSelectedStartTime] = useState<Date | null>(null)
  const [selectedEndTime, setSelectedEndTime] = useState<Date | null>(null)
  const [countryDateFormat, setCountryDateFormat] = useState<string>("MM/dd/yyyy")

  const addEventDateIcon = useMemo(() => {
    if (userCanEditEvent) {
      return (
        <IconButton className={classes.clickable} aria-label="add" onClick={_e => {setAddDateDialogOpen(true)}}>
          <AddIcon fontSize="small"/>
        </IconButton>
      )
    } else {
      return []
    }
  }, [classes.clickable, userCanEditEvent])

  useEffect(() => {
    if (defaultDate) {
      setSelectedDate(addDays(new Date(defaultDate), 1))
      setSelectedStartTime(addHours(new Date(defaultDate + " 08:00:00 GMT-0800 (Pacific Standard Time)"), -1))
      setSelectedEndTime(addHours(new Date(defaultDate + " 17:00:00 GMT-0800 (Pacific Standard Time)"), -1))
      setNewEventDate(defaultDate)
    }
  }, [defaultDate])

  useEffect(() => {
    setCountryDateFormat(getCountryDateFormat(new Date()))
  }, [])

  const handleDateChange = (date: Date | null) => {
    // Checking if date is valid before setting it
    if (date && (date instanceof Date && !isNaN(date.valueOf()))) {
      setSelectedDate(date)
      setNewEventDate(format(date, "yyyy-MM-dd"))
    } return
  }

  const handleStartChange = (date: Date | null) => {
    if (date) {
      setSelectedStartTime(date)
      const slicedDate = date.toString().slice(16,21)
      setNewDateStart(slicedDate)
    }
  }

  const handleEndChange = (date: Date | null) => {
    if (date) {
      setSelectedEndTime(date)
      const slicedDate = date.toString().slice(16,21)
      setNewDateEnd(slicedDate)
    }
  }

  const dateList = useMemo(() => {
    if (!eventDates) return
    const handleDeleteDate = async (eventDateId: number) => {
      await deleteEventDate(dispatch, eventDateId, producerId, Number(brandId), Number(eventId))
    }

    const outList: ReactNode[] = []
    const fixedEventDates = formatEventDates(eventDates)
    fixedEventDates.forEach((date: EventDate) => {
      const start = parse(date.start, "yyyy-MM-dd|HH:mm", new Date())
      const end = parse(date.end, "yyyy-MM-dd|HH:mm", new Date())
      outList.push(<div key={date.id}>
        <div className={classes.listItem}>{format(start, "yyyy iii MMM d h:mm aaaa")} to {format(end, "iii MMM d h:mm aaaa")}</div>
        {
          userCanEditEvent ?
            (<IconButton className={classes.clickable} aria-label="delete" style={{ float: 'right', padding: '4px' }} onClick={_e => {handleDeleteDate(date.id)}}>
              <DeleteIcon fontSize="small"/>
            </IconButton>)
            : []
        }
      </div>)
    })

    return outList
  },[eventDates, dispatch, producerId, brandId, eventId, classes.listItem, classes.clickable, userCanEditEvent])

  const [addDateDialogOpen, setAddDateDialogOpen] = useState(false)

  const handleCloseAddDateDialog = () => {
    setAddDateDialogOpen(false)
  }

  const handleAddDate = async () => {
    const combinedDateStart = newEventDate + '|' + newDateStart
    let combinedDateEnd = newEventDate + '|' + newDateEnd
    // Set end time after start time, next day if needed
    if (newDateStart > newDateEnd) {
      const nextDay = format(addDays(parse(newEventDate, "yyyy-MM-dd", new Date()), 1), "yyyy-MM-dd")
      combinedDateEnd = nextDay + '|' + newDateEnd
    }

    await addEventDate(dispatch, Number(eventId), Number(brandId), producerId, combinedDateStart, combinedDateEnd)
    setAddDateDialogOpen(false)
  }

  const addDateDialog = (
    <Dialog
      open={addDateDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseAddDateDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Add Date"}</DialogTitle>
      <DialogContent>
        <form className={classes.dateForm} noValidate>
          <Grid container>
            <Grid item xs={12} className={classes.gridForm}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={countryDateFormat}
                margin="normal"
                autoOk={true}
                id="date"
                label="Day to add"
                value={selectedDate}
                required={true}
                inputVariant="outlined"
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.gridForm}>
              <KeyboardTimePicker
                placeholder="08:00 AM"
                variant="inline"
                margin="normal"
                autoOk={true}
                id="start_time"
                label="Start Time"
                value={selectedStartTime}
                required={true}
                inputVariant="outlined"
                onChange={handleStartChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<AccessTimeIcon/>}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridForm}>
              <KeyboardTimePicker
                placeholder="05:00 PM"
                variant="inline"
                margin="normal"
                autoOk={true}
                id="end_time"
                label="End Time"
                value={selectedEndTime}
                required={true}
                inputVariant="outlined"
                onChange={handleEndChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<AccessTimeIcon/>}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAddDateDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddDate} color="primary">
          Add Date
        </Button>
      </DialogActions>
    </Dialog>
  )

  return !userCanViewEvent ? <></> : (
    <>
      <Typography variant="h2">
        Dates
        {addEventDateIcon}
        <HelpIcon
          markdownText={`### Event Dates\nAll event times are in the timezone of the event location`}
          boxSize="small"
          iconSize="small"
        />
      </Typography>
      {dateList}
      {addDateDialog}
    </>)
}

export default EventDates
