import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useStyles } from "lib/theme"
import { Button, Container, CssBaseline, Typography } from '@material-ui/core'
import PasswordFields from 'components/util/PasswordFields'
import { passwordStrength } from 'check-password-strength'
import { AsFields, passwordStrengthOptions } from 'lib/constants'
import { updatePassword } from 'store/user/userActions'
import { useHistory } from 'react-router'

const Account: React.FC = (): ReactElement => {
  const classes = useStyles()
  const history = useHistory()

  const accountPasswordFields = useMemo(() => {
    return {
      password: true,
      password2: true
    }
  }, [])

  const emptyAccountPasswords = {
    password: "",
    password2: "",
  }

  const [accountPasswords, setAccountPasswords] = useState<AsFields>(emptyAccountPasswords)
  const [passwordMatch, setPasswordMatch] = useState(true)
  const [formReady, setFormReady] = useState(false)
  const [alertSeverity, setAlertSeverity] = useState<'error'| 'info' | 'success' | 'warning' | undefined>('warning')
  const [passwordStrongEnough, setPasswordStrongEnough] = useState<boolean>(false)
  const [passwordStrengthText, setPasswordStrengthText] = useState<string>("")
  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})
  const [checkAccount, setCheckAccount] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined)

  const passStrength: string = useMemo(() => { return passwordStrength(accountPasswords.password, passwordStrengthOptions).value }, [accountPasswords])

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setAccountPasswords({ ...accountPasswords, [name]: value })
    if (!value) {
      setErrorText({ ...errorText, [name]: `${name.charAt(0).toUpperCase() + name.slice(1).replace(/([^0-9])([0-9])/g, '$1 $2')} cannot be empty` })
    } else {
      setErrorText({ ...errorText, [name]: "" })
    }
  }

  useEffect(() => {
    setFormReady(passwordMatch && accountPasswords.password.length >= 3 && accountPasswords.password2.length >= 3 && passwordStrongEnough)
    // errorText should not be included (causes infinite rerender)
  }, [classes.warning, passwordMatch,  passwordStrongEnough, setFormReady, setErrorText, passStrength, accountPasswords])

  // Check fields when user tries to submit
  useEffect(() => {
    if (checkAccount) {
      const errors:{[key: string]: string} = {}

      Object.keys(accountPasswordFields).forEach((key) => {
        if (accountPasswords[key] === "") errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1).replace(/([^0-9])([0-9])/g, '$1 $2')} cannot be empty`
      })
      Object.keys(accountPasswordFields).forEach((key) => {
        if (!passwordMatch) errors[key] = "Passwords do not match"
      })
      if (!passwordStrongEnough) setAlertSeverity("error")
      setErrorText(errors)
      setCheckAccount(false)
    }
  }, [accountPasswordFields, accountPasswords, checkAccount, passwordMatch, passwordStrongEnough])

  const submitForm: any = async (event: any) => {
    setCheckAccount(true)
    event.preventDefault()
    if (formReady) {
      await updatePassword(accountPasswords.password)
      setSuccessMessage("Your password was updated successfully.")
    }
  }

  return (
    <>
      <CssBaseline/>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography variant="h2">Update Password</Typography>
          <form className={classes.form} noValidate onSubmit={submitForm}>
            <PasswordFields
              errorText={errorText}
              handleInputChange={handleInputChange}
              fields={accountPasswordFields}
              password={accountPasswords.password}
              setErrorText={setErrorText}
              passStrength={passStrength}
              alertSeverity={alertSeverity}
              setObject={setAccountPasswords}
              password2={accountPasswords.password2}
              setAlertSeverity={setAlertSeverity}
              setPasswordMatch={setPasswordMatch}
              setPasswordStrongEnough={setPasswordStrongEnough}
              setPasswordStrengthText={setPasswordStrengthText}
              passwordMatch={passwordMatch}
              passwordStrongEnough={passwordStrongEnough}
              passwordStrengthText={passwordStrengthText}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Update Password
            </Button>
          </form>
          { successMessage ?
            (
              <>
                <Typography variant="h3">{successMessage}</Typography>
                <Button
                  variant="contained"
                  className={classes.submit}
                  onClick={(e: React.MouseEvent<HTMLElement>) => history.replace('/')}>
                Ok
                </Button>
              </>
            ) : <></>
          }
        </div>
      </Container>
    </>)
}

export default Account
