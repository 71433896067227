import React, { ReactElement, useMemo, useState } from 'react'
import { useStyles } from "lib/theme"
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import YesNoDialog from "components/util/YesNoModal"
import { shareAuth } from 'store/user/userActions'
import { useCallback } from 'react'


const ShareLogin: React.FC = (): ReactElement => {

  const classes = useStyles()

  const [hasAccessCode, setHasAccessCode] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [accessCode, setAccessCode] = useState<string>("")
  const [codeValidUntil, setCodeValidUntil] = useState<string>("")

  // Adds twenty minutes to date at point of code generation
  const getCodeExpirationTime = () => {
    const dateObject = new Date()
    const twentyMinutesLater = new Date(dateObject.getTime() + (20 * 60 * 1000)).toLocaleTimeString()
    setCodeValidUntil(twentyMinutesLater)
  }

  const submitForm = useCallback(async () => {
    try {
      const accessCodeResponse = await shareAuth(email, password)
      setAccessCode(accessCodeResponse)
      setHasAccessCode(true)
      getCodeExpirationTime()
    } catch (e) {
      setDialogOpen(true)
      console.error(e)
    }
  }, [email, password])

  const login = useMemo(() => {
    return (
      <Container>
        {/* Do we want CssBaseline again? */}
        <CssBaseline/>
        <Grid container>
          <Grid item xs={1}/>
          <Grid item xs={10}>
            <Typography variant="h1">Generate Access Code</Typography>
          </Grid>
        </Grid>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <form className={classes.form} noValidate onSubmit={submitForm}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={e => setEmail(e.target.value)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => setPassword(e.target.value)}
              />
              <Button
                onClick={() => submitForm()}
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Generate Access Code
              </Button>
            </form>
          </div>
        </Container>
        <YesNoDialog
          title={"Invalid Email or Password"}
          question={`The email and password you entered is an invalid combination, please try again.`}
          isOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => setDialogOpen(false) },
          ]}
        />
      </Container>
    )
  }, [dialogOpen, submitForm, classes])


  return (
    <Container>
      <CssBaseline/>
      <Grid container>
        <Grid item xs={1}/>
        <Grid item xs={10}>
          <Typography variant="h1">Remote Account Access</Typography>
          <Typography>You may share your account to allow remote access for tech support or event producers.
            This code will remain active for 20 minutes. By sharing this access code you are consenting full access to your account for a maximum of 24 hours.
            Once the 24 hour period has elapsed, remote access will be revoked, however you may request a new access code.
          </Typography>
          <br></br>
          <Typography>
            For Verification purposes, please enter your account credentials to generate an access code.
          </Typography>

        </Grid>
      </Grid>
      <br></br>
      {
        hasAccessCode ? <Grid container>
          <Grid item xs={1}/>
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h2">Access Code: <span style={{ fontWeight: "lighter" }}>{accessCode}</span></Typography>
                <Typography variant="h2">Code valid until: <span style={{ fontWeight: "lighter" }}>{codeValidUntil}</span></Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  onClick={() => submitForm()}
                  variant="contained"
                  className={classes.submit}
                >
                  Generate New Code
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid> : login
      }

      <Container component="main" maxWidth="xs">
      </Container>
    </Container>
  )
}

export default ShareLogin
