// Common Imports
import React, { ReactElement, useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from 'lib/theme'
import { FormValues } from 'lib/constants'

// Components Imports
import SwitchComponent from '../rubricComponents/Switch'
import TextboxAreaComponent from '../rubricComponents/Textboxarea'
import ButtonComponent from '../rubricComponents/Button'
import DropdownComponent from '../rubricComponents/Dropdown'
import LabelComponent from '../rubricComponents/Label'
import DropdownRangeComponent from '../rubricComponents/DropdownRange'
import TextboxComponent from '../rubricComponents/Textbox'

interface TemplateThreeProps {
    mode: string;
    templateData: any;
    responseData: any;
    judgeType?: string[];
    initialValues?: any;
}

const TemplateThree: React.FC<TemplateThreeProps> = ({
  mode,
  templateData,
  responseData,
  judgeType,
  initialValues
}): ReactElement => {
  const classes = useStyles()
  const [objectName, setObjectName] = useState<string>(templateData.objectName) // To show objectName from templateData
  const [dropdownCount, setDropdownCount] = useState<number>(0)  //To show number of reductions

  const [selectedDropdwonValue, setSelectedDropdwonValue] = useState<string[]>([]) //To show label of selected value
  const [isComment, setIsComment] = useState<boolean[]>([])  //To show commentbox based on switch comment
  const [selectedTextboxValue, setSelectedTextboxValue] = useState<string[]>([])  //To show comment textboxarea values
  const [selectedDropdownRange, setSelectedDropdownRange] = useState<any[]>([]) //Hold dropdownRange values
  const [selectedRangeValue, setSelectedRangeValue] = useState<any[]>([])  //Hold textbox range values
  const [errorText, setErrorText] = useState<string[]>([]) //Manage errorText accorind its index

  const [executionThresholdObject, setExecutionThresholdObject] = useState<{cutOffValue: number, allowNegative: false}>({ cutOffValue: 0, allowNegative: false }) //Extract threshold object withing templateData
  const [executionThresholdValue, setExecutionThresholdValue] = useState<number>(0) //To show score summary value

  const [selectedData, setSelectedData] = useState<any>([])  //Hold all the values of template
  const [response, setResponse] = useState<any>({
    [objectName]: {}
  })

  const [initialData, setInitialData] = useState<any>([])

  useEffect(() => {
    if (mode == 'Edit') {
      // Set initialValues according objectName
      setInitialData(initialValues?.[objectName])

      //Extracting count
      const count = initialValues?.[objectName]?.data?.length || 0
      setDropdownCount(count)

      // Extracting dropdown values into a separate array
      const defaultdrodpwonValues = initialValues?.[objectName]?.data.map((item: any) => item[objectName])
      setSelectedDropdwonValue(defaultdrodpwonValues)

      // Extracting isComment values into a separate array
      const defaultComments = initialValues?.[objectName]?.data.map((item: any) => item.isComment)
      setIsComment(defaultComments)

      // Extracting Comment values into a separate array
      const defaultCommentsText = initialValues?.[objectName]?.data.map((item: any) => item.comment)
      setSelectedTextboxValue(defaultCommentsText)

      // Update selectedData state with the objects inside the data property of initialValues
      setSelectedData(initialValues?.[objectName]?.data || [])

      // Update thresholdValue state with the objects inside the value property of initialValues
      setExecutionThresholdValue(initialValues?.[objectName]?.cutOffValue)

      // Extract default dropdown range values
      const defaultDropdownRangeValues = initialValues?.[objectName]?.data.map((item: any) => {
        const option = templateData.data.rows[0].find((row: any) => row.key === objectName)
        return option?.enums?.find((enumItem: any) => enumItem.title === item[objectName])
      })
      // Set the defaultDropdownRange based on the extracted values
      setSelectedDropdownRange(defaultDropdownRangeValues)

      // Set defaultTextboxRange value to the state
      const defaultTextboxRangeValue = initialValues?.[objectName]?.data.map((item: any) => item[`${objectName}Value`])
      setSelectedRangeValue(defaultTextboxRangeValue)

      // Setting initialValues to the response
      setResponse({
        [objectName]: initialValues?.[objectName]
      })
    }
  }, [initialValues, objectName, mode])

  useEffect(() => {
    responseData(response)
  }, [response])

  useEffect(() => {
    // extract redution maximum value
    const extractExecutionValue = () => {
      setExecutionThresholdObject({
        cutOffValue: templateData?.cutOffValue,
        allowNegative: templateData?.allowNegative
      })
      setExecutionThresholdValue(initialValues ? initialValues?.[objectName]?.cutOffValue || templateData?.cutOffValue : templateData?.cutOffValue)
    }
    extractExecutionValue()
  }, [])

  const handleExecutionButtonClick = () => {
    setDropdownCount((prevCount) => prevCount + 1) // Increment dropdown count on button click
  }

  // Calculate Reduction score
  const calculateScore = (data: number[]) => {
    const sumOfArray = data.reduce((accumulator: any, currentValue: any) => parseFloat(accumulator) + parseFloat(currentValue), 0)
    const roundedSum = parseFloat(sumOfArray.toFixed(2))

    // Subtract the sum of array values from maxValue
    const result = parseFloat((executionThresholdObject.cutOffValue - roundedSum).toFixed(2))

    //If result is greather than 0 or allowNegative true then negative value also will be returned
    if (result > 0 || executionThresholdObject.allowNegative) {
      setExecutionThresholdValue(result)
      return result
    } else {
      setExecutionThresholdValue(0)
      return 0
    }
  }

  // handles dropdown event
  const handleDropdownChange = (
    value: any,
    element: any,
    skillIndex: number
  ) => {
    const updatedTypes: any = selectedDropdwonValue ? [...selectedDropdwonValue] : []

    updatedTypes[skillIndex] = value.value
    const score = calculateScore(updatedTypes)

    const updatedExecution: any = [...selectedData]
    updatedExecution[skillIndex] = {
      ...updatedExecution[skillIndex],
      [element.key]: value.value
    }

    setSelectedDropdwonValue(updatedTypes)
    setSelectedData(updatedExecution)

    setResponse((prevData: any) => ({
      ...prevData,
      [objectName]: {
        cutOffValue: score,
        data: updatedExecution
      },
    }))
  }

  // handles dropdown event
  const handleDropdownRangeChange = (
    value: any,
    element: any,
    skillIndex: number
  ) => {
    const updatedTypes: any = Array.isArray(selectedDropdownRange) ? [...selectedDropdownRange] : []
    updatedTypes[skillIndex] = value
    setSelectedDropdownRange(updatedTypes)

    const updatedExecution: any = selectedData ? [...selectedData] : []
    updatedExecution[skillIndex] = {
      ...updatedExecution[skillIndex],
      [element.key]: value.title
    }
    setSelectedData(updatedExecution)

    setResponse((prevData: any) => ({
      ...prevData,
      [objectName]: {
        // value: score,
        data: updatedExecution
      },
    }))
  }

  // handles switch event
  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    skillIndex: number
  ) => {
    const { name, checked } = event.target

    const updatedIsComment = isComment ? [...isComment] : []
    updatedIsComment[skillIndex] = checked
    setIsComment(updatedIsComment)

    const updatedExecution: any = [...selectedData]

    updatedExecution[skillIndex] = {
      ...updatedExecution[skillIndex],
      [name]: checked
    }

    setSelectedData(updatedExecution)
    setResponse((prevData: any) => ({
      ...prevData,
      [objectName]: {
        ...(prevData[objectName] || {}),
        data: updatedExecution
      }
    }))
  }

  // handles textbox event
  const handleTextboxAreaChange = (event: React.ChangeEvent<HTMLInputElement>, skillIndex: number) => {
    const { name, value } = event.target

    const updatedValue: any = selectedTextboxValue ? [...selectedTextboxValue] : []
    updatedValue[skillIndex] = value
    setSelectedTextboxValue(updatedValue)

    const updatedExecution: any = [...selectedData]

    updatedExecution[skillIndex] = {
      ...updatedExecution[skillIndex],
      [name]: value
    }

    setSelectedData(updatedExecution)
    setResponse((prevData: any) => ({
      ...prevData,
      [objectName]: {
        ...(prevData[objectName] || {}),
        data: updatedExecution
      }
    }))
  }

  const handleTextboxChange = (event: React.ChangeEvent<HTMLInputElement>, skillIndex: number) => {
    const { name, value } = event.target

    const isInvalid = validateTextBoxRange(selectedDropdownRange[skillIndex], parseFloat(value), skillIndex)
    if (!isInvalid) {
      const err = [...errorText]
      err[skillIndex] = ''
      setErrorText(err)
    }
    const updatedValue: any = selectedRangeValue ? [...selectedRangeValue] : []
    updatedValue[skillIndex] = value
    const score = calculateScore(updatedValue)

    setSelectedRangeValue(updatedValue)
    const updatedExecution: any = [...selectedData]

    updatedExecution[skillIndex] = {
      ...updatedExecution[skillIndex],
      [name]: value
    }

    setSelectedData(updatedExecution)
    setResponse((prevData: any) => ({
      ...prevData,
      [objectName]: {
        cutOffValue: score,
        data: updatedExecution
      },
    }))
  }

  // Validate textbox value which falls selected dropdown range or not
  const validateTextBoxRange = (selectedRange: any, enteredValue: number, index?: number) => {
    if (index || index === 0) {
      if (selectedRange) {
        if (
          enteredValue < selectedRange.min ||
                    enteredValue > selectedRange.max ||
                    enteredValue !== parseFloat(enteredValue.toFixed(2))
        ) {
          const error = [...errorText]
          error[index] = `Value should be between ${selectedRange.min} and ${selectedRange.max}`
          setErrorText(error)
          return true
        }
      }
      return false
    }
  }

  // handles delete execution
  const handleDeleteExecution = (skillIndex: number) => {
    let updatedData: any
    let updatedPyramidValues: any
    let stuntScoreValue: number

    setDropdownCount(prevCount => Math.max(0, prevCount - 1))

    setIsComment(prevIsComment => {
      const updatedIsComment = [...prevIsComment]
      updatedIsComment.splice(skillIndex, 1)
      return updatedIsComment
    })

    setSelectedDropdownRange((prevData: any) => {
      const updateddropdown = prevData ? [...prevData] : []
      updateddropdown.splice(skillIndex, 1)
      return updateddropdown
    })

    setSelectedRangeValue((prevData: any) => {
      const updatedrange = prevData ? [...prevData] : []
      updatedrange.splice(skillIndex, 1)
      stuntScoreValue = calculateScore(updatedrange)
      return updatedrange
    })

    setSelectedTextboxValue((prevData: any) => {
      const test = prevData.filter((item: any, index: number) => index !== skillIndex)
      return test
    })

    setErrorText((prevValue: any) => {
      const updatedErrorText = [...prevValue]
      updatedErrorText.splice(skillIndex, 1)
      return updatedErrorText
    })

    setSelectedDropdwonValue((prevData: any) => {
      updatedPyramidValues = [...prevData]
      updatedPyramidValues.splice(skillIndex, 1)
      stuntScoreValue = calculateScore(updatedPyramidValues)
      return updatedPyramidValues
    })

    setSelectedData((prevData: any) => {
      updatedData = [...prevData]
      updatedData.splice(skillIndex, 1)
      return updatedData
    })

    setResponse((prevData: any) => ({
      ...prevData,
      [objectName]: {
        cutOffValue: stuntScoreValue,
        data: updatedData
      }
    }))
  }

  const renderFormButton = () => {
    return Object.values(templateData?.data?.rows).map((row: any, index: number) => {
      return (
        <Grid container key={`row_${index}`}>
          {row.map((value: any, key: number) => {
            if (value.visible) {
              switch (value.type) {
                case FormValues.STATIC_LABEL:
                  return (
                    <Grid item xs={12} sm={6} md={1}>
                      <LabelComponent title={value.title} />
                    </Grid>
                  )

                case FormValues.BUTTON:
                  return (value.visible &&
                                        <Grid item xs={12} sm={6} md={4} >
                                          <ButtonComponent
                                            element={value}
                                            handleButtonClick={handleExecutionButtonClick}
                                          />
                                        </Grid>
                  )

                default:
                  return null
              }
            }
          })}
        </Grid>
      )
    })
  }

  const renderForm = () => {
    const dropdowns: any = []

    for (let i = 0; i < dropdownCount; i++) {
      const gridItems: any = []

      Object.values(templateData?.data?.rows).forEach((row: any, index: number) => {
        row.forEach((element: any, key: number) => {
          if (element.visible) {
            switch (element.type) {
              case FormValues.DROPDOWN:
                gridItems.push(
                  <>
                    <Grid item xs={12} sm={6} md={1}></Grid>
                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                      <DropdownComponent
                        element={element}
                        index={i}
                        handleDropdownChange={handleDropdownChange}
                        selectedData={selectedData?.[i]?.[element.key]}
                      />
                    </Grid>
                  </>
                )
                break

              case FormValues.DROPDOWN_RANGE:
                const mdValueDropdown = selectedDropdownRange ? 2 : 4
                gridItems.push(
                  <>
                    <Grid item xs={12} sm={6} md={mdValueDropdown} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                      <DropdownRangeComponent
                        element={element}
                        handleDropdownChange={handleDropdownRangeChange}
                        options={element.enums}
                        selectedDropdownValue={selectedDropdownRange && selectedDropdownRange[i]}
                        index={i}
                      />
                    </Grid>
                  </>
                )
                break

              case FormValues.TEXTBOX_RANGE:
                return (selectedDropdownRange && selectedDropdownRange[i]) &&
                                    gridItems.push(
                                      <Grid item xs={12} sm={6} md={2} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                                        <TextboxComponent
                                          element={element}
                                          handleTextFieldChange={handleTextboxChange}
                                          values={selectedRangeValue && selectedRangeValue[i]}
                                          errorText={errorText}
                                          index={i}
                                        />
                                      </Grid>
                                    )
                break


              case FormValues.SWITCH:
                gridItems.push(
                  <Grid item xs={12} sm={4} md={2} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                    <SwitchComponent
                      element={element}
                      handleSwitchChange={handleSwitchChange}
                      isComment={isComment && isComment[i]}
                      index={i}
                    />
                  </Grid>
                )
                break

              case FormValues.TEXTBOXAREA:
                if (isComment && isComment[i]) {
                  gridItems.push(
                    <Grid item xs={12} sm={6} md={4} className={classes.rubricScoring} key={`row_${index}_col_${key}`}>
                      <TextboxAreaComponent
                        element={element}
                        handleTextFieldChange={handleTextboxAreaChange}
                        selectedTextboxValue={selectedTextboxValue && selectedTextboxValue[i]}
                        index={i}
                      />
                    </Grid>
                  )
                } else {
                  gridItems.push(<Grid item xs={12} sm={6} md={4}></Grid>)
                }
                break

              case FormValues.DELETE_BUTTON:
                gridItems.push(
                  <Grid item xs={12} sm={6} md={1} key={`row_${index}_col_${key}`}>
                    <ButtonComponent
                      element={element}
                      index={i}
                      handleButtonClick={handleDeleteExecution}
                    />
                  </Grid>
                )
                break

              case FormValues.LABEL:
                if (selectedDropdwonValue && selectedDropdwonValue[i]) {
                  gridItems.push(
                    <>
                      <Grid xs={12} sm={6} md={1}></Grid>
                      <Grid item xs={12} sm={6} md={3} className={classes.rubricScoring}>
                        <LabelComponent title={element.title} data={-selectedDropdwonValue[i]} />
                      </Grid>
                    </>
                  )
                }
                break

              default:
                break
            }
          }
        })
      })

      dropdowns.push(
        <Grid container key={`dropdown_${i}`} alignItems="center">
          {gridItems}
        </Grid>
      )
    }

    return dropdowns
  }

  const renderForSummaryLabel = () => {
    return Object.values(templateData?.data?.rows).map((row: any, index: number) => {
      return (
        <Grid container key={`row_${index}`}>
          {row.map((value: any, key: number) => {
            if (value.visible) {
              switch (value.type) {
                case FormValues.END_LABEL:
                  return (value.visible &&
                                        <Grid container>
                                          <Grid xs={12} sm={6} md={1}></Grid>
                                          <Grid item xs={12} sm={6} md={4} key={index} >
                                            <LabelComponent title={value.title} data={executionThresholdValue} />
                                          </Grid>
                                        </Grid>
                  )
              }
            }
          })}
        </Grid>
      )
    })
  }

  const renderFormElements = () => {
    const shouldDisplayElement = !templateData.judgeType || judgeType?.includes(templateData.judgeType)
    if (shouldDisplayElement) {
      return (
        <Grid container alignItems="center" className={classes.grouperForm}>
          {renderFormButton()}
          {renderForm()}

          {/* Add horizontal line */}
          <hr className={classes.horizontalLine} />

          {renderForSummaryLabel()}
        </Grid>
      )
    }
  }

  return (
    <>
      {renderFormElements()}
    </>
  )
}

export default TemplateThree
