
import { createContext, useContext } from 'react'
import { ADD_LOGO_PRODUCER } from "lib/constants"

export const logoUploadDefault = {
  id: 0,
  addLogoType: ADD_LOGO_PRODUCER,
  oldLogo: "",
  fileSelected: false,
  base64File: "",
  fileName: "",
  maxFileSizeExceeded: false
}

export type LogoUploadContent = {
    logoState: typeof logoUploadDefault
    setLogoState:(l: typeof logoUploadDefault) => void
}

export const logoUploadContext = createContext<LogoUploadContent>({
  logoState: logoUploadDefault,
  setLogoState: () => {
    return useLogoUploadState
  }
})

export const useLogoUploadState = () => useContext(logoUploadContext)
