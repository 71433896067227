import React, { useCallback, useMemo } from "react"
import { Box, Grid, Paper, Typography, Checkbox } from "@material-ui/core"
import { DoneOutline as DoneOutlineIcon, RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import themis_common from "store/themis_common_pb"

interface CreditCardDetailsProps {
  useCase: "manage-payment-source" | "select-payment-source"
  creditCard: themis_common.StripeCreditCard.AsObject
  sourceListId?: number
  sourceListIdSelected?: number
  selectSource?: (sourceListId: number, checked: boolean, stripeSourceType: "bank-account" | "credit-card", stripeSourceId: string, sourceDetails: themis_common.StripeBankAccount.AsObject | themis_common.StripeCreditCard.AsObject) => void
}

const CreditCardDetails: React.FC<CreditCardDetailsProps> = ({ useCase, creditCard, sourceListId, sourceListIdSelected, selectSource }): React.ReactElement => {
  const isCurrentlySelected: boolean = useMemo(() => {
    return sourceListId === sourceListIdSelected
  }, [sourceListId, sourceListIdSelected])

  const handleSourceSelect = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (selectSource) selectSource(sourceListId ?? 0, checked, "credit-card", creditCard.id, creditCard)
  }, [selectSource, sourceListId, creditCard])

  const cvcCheckSection = useMemo(() => {
    if (useCase === "manage-payment-source") {
      if (creditCard.cvcCheck === "pass" || creditCard.cvcCheck === "unavailable") {
        return (<Box textAlign="center" width="100%">
          <Box style={{ color: "green" }}>CVC Valid</Box>
          <DoneOutlineIcon fontSize="large" style={{ color: "green" }}></DoneOutlineIcon>
        </Box>)
      } else {
        return (<Box textAlign="center" width="100%">
         CVC Not Valid
        </Box>)
      }
    } else if (useCase === "select-payment-source") {
      if (creditCard.cvcCheck === "pass" || creditCard.cvcCheck === "unavailable") {
        return (<Checkbox
          icon={<RadioButtonUnchecked />}
          checkedIcon={<RadioButtonChecked />}
          checked={isCurrentlySelected}
          onChange={handleSourceSelect}
          inputProps={{ 'aria-label': 'checkbox' }}
        />)
      } else {
        return (<Box textAlign="center" width="100%">
          CVC Not Valid
        </Box>)
      }
    }
  }, [useCase, creditCard.cvcCheck, isCurrentlySelected, handleSourceSelect])


  return <>
    <Paper elevation={isCurrentlySelected ? 8 : 2} key={`bank-account-details`}>
      <Box m={1} p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">Credit Card</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={12} lg={9} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Brand:</Box>
                <Box>{creditCard.brand}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Country:</Box>
                <Box>{creditCard.country}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Expiration Month:</Box>
                <Box>{creditCard.expMonth}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Expiration Year:</Box>
                <Box>{creditCard.expYear}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>Last 4:</Box>
                <Box>{creditCard.last4}</Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" p={1}>
                <Box fontWeight="fontWeightBold" fontStyle="italic" mr={2}>CVC Check:</Box>
                <Box>{creditCard.cvcCheck}</Box>
              </Box>
            </Grid>

          </Grid>
          {
            creditCard.cvcCheck === "pass" || creditCard.cvcCheck === "unavailable" ? 
            <Grid container item xs={12} lg={3} justify="center" alignItems="center">
            {cvcCheckSection}
            </Grid> : <></>           

          }

        </Grid>
      </Box>
    </Paper>
  </>
}

export default CreditCardDetails
