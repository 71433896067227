import {
  SET_LOCATION,
  SET_LOCATIONS,
  SET_TEAM,
  SET_TEAMS,
  SET_PROGRAM,
  RESET_PROGRAM,
  SET_ATHLETES,
  SET_GUARDIANS,
  SET_COACHES,
  SET_PERSONNEL,
  SET_TEAM_SA,
  SET_SELECTED_REGISTRATIONS_FOR_PAYMENT,
  SET_EVENT_REGISTRATION_LOGO,
} from "lib/constants"
import themis_common from "store/themis_common_pb"

export const emptyProgram: themis_common.Program.AsObject = {
  address: "",
  address2: "",
  dba: "",
  description: "",
  legalName: "",
  phone: "",
  postalCode: "",
  programClassification: "",
  id: 0,
  name: "",
  country: "",
  state: "",
  city: "",
  website: "",
  logo: "",
  lastScrape: "",
  usasfProgramId: "",
  title: "",
  secondaryPhone: "",
  cellPhone: "",
  email: "",
  allStarTypesList: [],
  locationsList: [],
  expireDate: "",
  allowedProducersList: []
}

export const emptyEventTeam: themis_common.EventTeam.AsObject = {
  totalPaid: 0,
  athletesList: [],
  coachesList: [],
  id: 0,
  locationId: 0,
  name: "",
  locationName: "",
  personnelList: [],
  programId: 0,
  programName: "",
  teamId: 0,
  isProducerUpdate: false,
  paid: 0,
  remainingAmount: 0,
  remainingDeposit: 0,
  allPaid: false,
  deposit: false,
  signed: false,
  registrationTransactionsList: [],
  warmUpTime: "",
  performanceTime: "",
  order: 0
}

export const emptyLocation: themis_common.Location.AsObject = {
  id: 0,
  name: "",
  country: "",
  state: "",
  city: "",
  address: "",
  address2: "",
  postalCode: "",
  phone: "",
  email: "",
  cellPhone: "",
  secondaryPhone: "",
  lastScrape: "",
  usasfLocationId: "",
  teamsList: [],
  athletesList: [],
  coachesList: [],
  personnelList: [],
  guardiansList: [],
  programId: 0,
}

export const emptyTeam: themis_common.Team.AsObject = {
  athletesList: [],
  coachesList: [],
  lastScrape: "",
  locationId: 0,
  personnelList: [],
  programId: 0,
  usasfTeamId: "",
  id: 0,
  description: "",
  name: "",
  allStarType: undefined,
  tier: undefined,
  eventTeamsList: [],
  scoringAuthoritiesList: [],
  isProducerTeam: false,
}

export const emptyPerson: themis_common.Person.AsObject = {
  address1: "",
  address2: "",
  birthDate: "",
  cellPhone: "",
  city: "",
  country: "US",
  email: "",
  gender: 1,
  hasBirthCertificate: false,
  homePhone: "",
  id: 0,
  isInternal: false,
  isUsasfGuardian: false,
  lastScrape: "",
  legalFirstName: "",
  legalLastName: "",
  legalMiddleName: "",
  name: "",
  nickName: "",
  postalCode: "",
  preferredFirstName: "",
  state: "AL",
  suffix: "",
  title: "",
  usasfPersonId: "",
  usasfProfilePicture: "",
  isBirthdayEdited: false
}

export interface HiddenFields {
  nickName?: boolean;
  birthDate?: boolean;
  address?: boolean;
  title?: boolean;
  gender?: boolean;
  suffix?: boolean;
  homePhone?: boolean;
}

export interface EventTeamInfo {
  eventTeamId: number
  teamName: string
  eventName: string
}

export interface RegistrationPaymentData {
  programId: number
  selectedRegistrationsForPayment: number[]
  displayBalanceAmount: number
  sourcePath: string
  eventTeamInfo: EventTeamInfo[]
  currency?: string
  deposit?: boolean
}

export const emptyRegistrationPaymentData: RegistrationPaymentData = {
  programId: 0,
  selectedRegistrationsForPayment: [],
  displayBalanceAmount: 0,
  eventTeamInfo: [],
  sourcePath: "",
}

export interface EventTeamPayments {
  payment: number
  credit: number
  deposit: number
  total: number
  availableCredit: number
  transactions: themis_common.RegistrationTransaction.AsObject[]
}

// export interface ObjectUserPermissions {
//   user: themis_common.User.AsObject
//   permissionsList: themis_common.Permission.AsObject[]
// }
export interface ObjectUserPermissions extends themis_common.UserPermissions.AsObject{
  user: themis_common.User.AsObject
}

export interface IProgramState {
  currentProgram: themis_common.Program.AsObject
  currentLocation: themis_common.Location.AsObject
  currentTeam: themis_common.Team.AsObject
  registrationPaymentData: RegistrationPaymentData
  eventRegistrationLogo: string
}

export const initialProgramState: IProgramState = {
  currentProgram: emptyProgram,
  currentLocation: emptyLocation,
  currentTeam: emptyTeam,
  registrationPaymentData: emptyRegistrationPaymentData,
  eventRegistrationLogo: ""
}

interface ProgramAction {
  type: string;
  payload: any;
}

export type CheckboxOptions = 0 | 1 | 2
export type PaidOptions = 0 | 1 | 2 | 3

export function programReducer(state: IProgramState = initialProgramState, action: ProgramAction): IProgramState {
  switch (action.type) {
    case RESET_PROGRAM: {
      return initialProgramState
    }
    case SET_PROGRAM: {
      return {
        ...state,
        currentProgram: action.payload || emptyProgram,
      }
    }
    case SET_LOCATIONS: {
      return {
        ...state,
        currentProgram: { ...state.currentProgram, locationsList: action.payload },
        currentLocation: emptyLocation,
      }
    }
    case SET_LOCATION: {
      if (action.payload) {
        action.payload.athletesList?.sort((a: themis_common.Athlete.AsObject, b: themis_common.Athlete.AsObject) => (a.name !== undefined && b.name !== undefined && a.name > b.name) ? 1 : -1)
        action.payload.coachesList?.sort((a: themis_common.Coach.AsObject, b: themis_common.Coach.AsObject) => (a.name !== undefined && b.name !== undefined && a.name > b.name) ? 1 : -1)
        action.payload.personnelList?.sort((a: themis_common.Personnel.AsObject, b: themis_common.Personnel.AsObject) => (a.name !== undefined && b.name !== undefined && a.name > b.name) ? 1 : -1)
      } else {
        action.payload = emptyLocation
      }
      return {
        ...state,
        currentLocation: action.payload,
      }
    }
    case SET_TEAMS: {
      const locationListIndex = state.currentProgram.locationsList.findIndex((location) => location.id === state.currentLocation.id)
      return {
        ...state,
        currentProgram: {
          ...state.currentProgram,
          locationsList: Object.assign([], state.currentProgram.locationsList, {
            [locationListIndex]: { ...state.currentProgram.locationsList[locationListIndex], teamsList: action.payload }
          }),
        },
        currentLocation: { ...state.currentLocation, teamsList: action.payload },
        currentTeam: emptyTeam,
      }
    }
    case SET_TEAM: {
      const team: themis_common.Team.AsObject = action.payload
      team.athletesList?.sort((a, b) => (a.name !== undefined && b.name !== undefined && a.name > b.name) ? 1 : -1)
      return {
        ...state,
        currentTeam: team
      }
    }
    case SET_TEAM_SA: {
      return {
        ...state,
        currentTeam: { ...state.currentTeam, scoringAuthoritiesList: action.payload }
      }

    }
    case SET_ATHLETES: {
      return {
        ...state,
        currentLocation: { ...state.currentLocation, athletesList: action.payload }
      }
    }
    case SET_GUARDIANS: {
      return {
        ...state,
        currentLocation: { ...state.currentLocation, guardiansList: action.payload }
      }
    }
    case SET_COACHES: {
      return {
        ...state,
        currentLocation: { ...state.currentLocation, coachesList: action.payload }
      }
    }
    case SET_PERSONNEL: {
      return {
        ...state,
        currentLocation: { ...state.currentLocation, personnelList: action.payload }
      }
    }
    case SET_SELECTED_REGISTRATIONS_FOR_PAYMENT: {
      return {
        ...state,
        registrationPaymentData: action.payload
      }
    }
    case SET_EVENT_REGISTRATION_LOGO: {
      return {
        ...state,
        eventRegistrationLogo: action.payload
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}
