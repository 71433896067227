import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon, ListItemText,
  Paper,
  TextField,
  Typography
} from '@material-ui/core'

import {
  ChevronRight as RightIcon,
  ChevronLeft as LeftIcon,

} from '@material-ui/icons'
import { useStyles } from "lib/theme"
import themis_common from "store/themis_common_pb"
import { divisionSortFunction } from "lib/validators"
import { Autocomplete } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { IAppState } from 'store/store'
import { selectCurrentEventDates, selectCurrentProducer } from 'store/producer/producerSelectors'
import { getSeasons } from 'store/producer/eventActions'
import { format } from "date-fns"
import { Clear as ClearIcon } from "@material-ui/icons"

interface eventDivisionGridProps {
  currentDivisions: any[],
  unusedDivisions: themis_common.Division.AsObject[],
  addDivisions: Function,
  removeDivisions: Function,
  eventSeasons: themis_common.Season.AsObject[],
  userCanViewEvent: boolean,
  userCanEditEvent: boolean,
}

const DivisionsList: FunctionComponent<eventDivisionGridProps> = ({ currentDivisions, unusedDivisions, addDivisions, removeDivisions, eventSeasons, userCanEditEvent, userCanViewEvent }) => {
  const classes = useStyles()
  const [allLock, setAllLock] = useState(false)
  const [firstDate, setFirstDate] = useState<Date>(new Date())
  const eventDates = useSelector((state: IAppState) => selectCurrentEventDates(state))
  const producer = useSelector((state: IAppState) => selectCurrentProducer(state))
  const [addDivisionData, setAddDivisionData] = useState<any[]>([]);
  const [removeDivisionData, setRemoveDivisionData] = useState<any[]>([]);
  const [addInputFilterValue, setAddInputFilterValue] = useState<any>([])
  const [removeInputFilterValue, setRemoveInputFilterValue] = useState<any>([])
  const [addFilter, setAddFilter] = useState("")
  const [removeFilter, setRemoveFilter] = useState("")
  const addSearchRef = useRef<string>('');
  const removeSearchRef = useRef<string>('');
  const exclusiveFilterSeasons: themis_common.Season.AsObject[] = []

  useEffect(() => {
    setAddDivisionData(unusedDivisions);
    setRemoveDivisionData(currentDivisions);
  }, [unusedDivisions, currentDivisions])

  useEffect(() => {
    const doGetSeasons = async () => {
      const eventDate = eventDates?.length ? eventDates[0].startDay.split("|")[0] : format(firstDate, 'yyyy-MM-dd')
      const seasons = await getSeasons(eventDate)
      seasons.forEach(season => {
        if (season.isExclusive) {
          if (season.exclusiveProducerId === producer?.id ?? 0) {
            exclusiveFilterSeasons.push(season)
          }
        } else {
          exclusiveFilterSeasons.push(season)
        }
      })
    }
    doGetSeasons()
  }, [firstDate, eventDates])

  const highlightMatch = (name: string, direction: string) => {
    let query = '';
    if (direction === "right") query = addSearchRef.current;
    else query = removeSearchRef.current;

    const lowerName = name.toLowerCase();
    const lowerQuery = query.toLowerCase();

    if (lowerName.includes(lowerQuery)) {
      const index = lowerName.indexOf(lowerQuery);
      return (
        <>
          {name.substring(0, index)}
          <span style={{ background: 'yellow' }}>{name.substring(index, index + query.length)}</span>
          {name.substring(index + query.length)}
        </>
      )
    }
    return name;
  }

  const buildListItems = useCallback((divisionList: any, direction: string) => {
    if (divisionList && userCanViewEvent) {
      divisionList.sort(divisionSortFunction)
      return divisionList.map((division: any) => {
        const item = division?.name ? division?.name : (division?.division?.name) ? division?.division?.name: ""
        return <ListItem key={`${direction}-${division?.id}`} role="listitem" button onClick={() => {
          if (userCanEditEvent && direction === 'right') {
            addDivisions([division]);
            setAddFilter('');
            addSearchRef.current = '';
            setAddInputFilterValue([]);
          } else if (userCanEditEvent) {
            removeDivisions([division])
            setRemoveFilter('');
            removeSearchRef.current = '';
            setRemoveInputFilterValue([]);
          }
        }}>
          {direction === 'left' && userCanEditEvent &&
            <ListItemIcon>
              <LeftIcon />
            </ListItemIcon>}
          <ListItemText id={`division-${division?.id}`} primary={highlightMatch(item, direction)} secondary={division?.season?.name} style={direction === 'left' ? { textAlign: 'right' } : {}} />
          {direction === 'right' && userCanEditEvent &&
            <ListItemIcon>
              <RightIcon />
            </ListItemIcon>}
        </ListItem>
      })
    } else return []
  }, [addDivisionData, removeDivisionData, addDivisions, removeDivisions, userCanEditEvent, userCanViewEvent])

  const filterDivisions = (inputValue: themis_common.Season.AsObject[] | null, divisions: themis_common.Division.AsObject[], setDataFunction: any) => {
    if (inputValue && inputValue.length > 0) {
      let combinedData: any = [];
      for (const season of inputValue) {
        const filteredData = divisions?.filter((item) => {
          return item?.season?.name.toLowerCase().includes(season?.name.toLowerCase());
        });
        combinedData = [...combinedData, ...filteredData];
      }
      setDataFunction(combinedData);
    } else {
      setDataFunction(divisions);
    }
  };

  const onchangeAddDivisionFilter = (v: themis_common.Season.AsObject[] | null) => {
    setAddInputFilterValue(v);
    filterDivisions(v, unusedDivisions, setAddDivisionData);
  };

  const onchangeRemoveDivisionFilter = (v: themis_common.Season.AsObject[] | null) => {
    setRemoveInputFilterValue(v);
    filterDivisions(v, currentDivisions, setRemoveDivisionData);
  };

  const searchDivisions = (value: string, divisions: themis_common.Division.AsObject[], setFilterFunction: any, setDataFunction: any) => {
    setFilterFunction(value);

    if (!value) {
      setDataFunction(divisions);
    } else {
      const filteredData = divisions?.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setDataFunction(filteredData);
    }
  };

  const onAddDivisionSearch = (value: string) => {
    addSearchRef.current = value;
    searchDivisions(value, unusedDivisions, setAddFilter, setAddDivisionData);
  };

  const onRemoveDivisionSearch = (value: string) => {
    removeSearchRef.current = value;
    searchDivisions(value, currentDivisions, setRemoveFilter, setRemoveDivisionData);
  };


  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container justify="flex-start">
        <Grid item xs={8} >
          <Typography style={{ marginRight: "25px" }} variant="h2">Divisions</Typography>
        </Grid>
        {!userCanEditEvent ? <></> :
          <Grid container item md={5}>
            <Paper className={classes.paper} style={{ width: "95%" }}>
              <Box p={2}><Typography variant="h3">Available Divisions</Typography></Box>
              <Autocomplete
                multiple
                filterSelectedOptions
                options={eventSeasons}
                disableClearable
                style={{ marginTop: 16 }}
                getOptionLabel={(option: themis_common.Season.AsObject) => option.name || "Unknown"}
                getOptionSelected={(option, value) => option.id === value.id}
                value={addInputFilterValue}
                renderInput={(params) => (
                  <TextField {...params} label="Season" variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off'
                    }}
                    required={true}
                    style={{ width: "350px" }}
                  />
                )}
                onChange={(e, v: themis_common.Season.AsObject[] | null) => {
                  onchangeAddDivisionFilter(v)
                }}
                id="setSeason"
                className={classes.programSelect}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={"Filter Divisions"}
                value={addFilter}
                style={{ width: '350px' }}
                onChange={(e) => {
                  onAddDivisionSearch(e.target.value)
                }}
                InputProps={{
                  endAdornment: (<InputAdornment position="end"><ClearIcon style={{ cursor: "pointer" }} onClick={() => { setAddFilter(""); setAddDivisionData(unusedDivisions); addSearchRef.current = '' }} /> </InputAdornment>)
                }}
              />
              <List dense component="div" role="list">
                <ListItem key={`AddAll`} role="listitem" button disabled={allLock} onClick={() => {
                  if (addDivisionData.length > 0) {
                    setAllLock(true)
                    addDivisions(addDivisionData)
                    setTimeout(() => {
                      setAllLock(false)
                      setAddInputFilterValue([]);
                      setAddFilter('');
                      addSearchRef.current = '';
                    }, 2000)
                  }
                }}>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.divisionSubmit}
                    style={{ marginBottom: 15, width: '100%' }}
                    disabled={!(addDivisionData.length > 0)}
                  >
                    <ListItemText id={`Add All`} primary={addFilter.length > 0 || addInputFilterValue.length > 0 ? `Add All Filtered Divisions` : `Add All Divisions`} />
                    <ListItemIcon>
                      <RightIcon style={addDivisionData.length > 0 ? { color: 'white' } : { color: '#C0C0C0' }} />
                    </ListItemIcon>
                  </Button>
                </ListItem>
                {buildListItems(addDivisionData, 'right')}
                {addDivisionData.length === 0 && <Box p={2}><Typography variant="h3">No Divisions Found</Typography></Box>}
              </List>
            </Paper>
          </Grid>
        }
        <Grid container item md={5}>
          <Paper className={classes.paper} style={{ width: "95%" }}>
            <Box p={2}><Typography variant="h3">Selected Divisions</Typography></Box>
            <Autocomplete
              multiple
              filterSelectedOptions
              options={eventSeasons}
              disableClearable
              style={{ marginTop: 16 }}
              getOptionLabel={(option: themis_common.Season.AsObject) => option.name || "Unknown"}
              getOptionSelected={(option, value) => option.id === value.id}
              value={removeInputFilterValue}
              renderInput={(params) => (
                <TextField {...params} label="Season" variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off'
                  }}
                  style={{ width: "350px" }}
                  required={true}
                />
              )}
              onChange={(e, v: themis_common.Season.AsObject[] | null) => {
                onchangeRemoveDivisionFilter(v)
              }}
              id="setSeason"
              className={classes.programSelect}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label={"Filter Divisions"}
              value={removeFilter}
              style={{ width: "350px" }}
              onChange={(e) => {
                onRemoveDivisionSearch(e.target.value)
              }}
              InputProps={{
                endAdornment: (<InputAdornment position="end"><ClearIcon style={{ cursor: "pointer" }} onClick={() => { setRemoveFilter(""); setRemoveDivisionData(currentDivisions); removeSearchRef.current = '' }} /> </InputAdornment>)
              }}
            />
            <List dense component="div" role="list">
              {!userCanEditEvent ? <></> :
                <ListItem key={`RemoveAll`} role="listitem" button disabled={allLock} onClick={() => {
                  if (removeDivisionData.length > 0) {
                    setAllLock(true)
                    removeDivisions(removeDivisionData)
                    setTimeout(() => {
                      setAllLock(false)
                      setRemoveInputFilterValue([]);
                      setRemoveFilter('');
                      removeSearchRef.current = ''
                    }, 2000)
                  }
                }}>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.divisionSubmit}
                    style={{ marginBottom: 15, width: '100%' }}
                    disabled={!(removeDivisionData.length > 0)}
                  >
                    <ListItemIcon>
                      <LeftIcon style={removeDivisionData.length > 0 ? { color: 'white' } : { color: '#C0C0C0' }} />
                    </ListItemIcon>
                    <ListItemText id={`Remove All`} primary={removeFilter.length > 0 || removeInputFilterValue.length > 0 ? `Remove All Filtered Divisions` : `Remove All Divisions`} />
                  </Button>
                </ListItem>
              }
              {buildListItems(removeDivisionData, 'left')}
              {removeDivisionData.length === 0 && <Box p={2}><Typography variant="h3">No Divisions Found</Typography></Box>}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>)
}

export default DivisionsList
