import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { useDispatch } from "react-redux"
import { Transition, useStyles } from "lib/theme"
import { Link } from "react-router-dom"
import themis_common from 'store/themis_common_pb'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core'
import { addProducerLink } from "store/producer/producerActions"

interface producerConnectLinksProps { producerId: number, connectLinks: themis_common.ProducerUrl.AsObject[] }

const ProducerConnectLinks: React.FC<producerConnectLinksProps> = (props: producerConnectLinksProps): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const producerId = props.producerId
  const connectLinks = props.connectLinks

  const [addLinkDialogOpen, setAddLinkDialogOpen] = useState(false)
  const [link, setLink] = useState("")
  const [formKey, setFormKey] = useState(0)

  const [localhostOrDomain, setLocalhostOrDomain] = useState<string>("")

  useEffect(() => {
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? setLocalhostOrDomain(`http://${window.location.host}`) : setLocalhostOrDomain(`https://${window.location.hostname}`)
  }, [])

  const linkList = useMemo(() => {
    const outList: ReactNode[] = []
    if (!connectLinks) return outList

    connectLinks.forEach((link: themis_common.ProducerUrl.AsObject) => {
      outList.push(<div key={link.id}>
        <Link className={classes.listItem} to={`/Connect/${link.connectUrl}`}>{localhostOrDomain}/connect/{link.connectUrl}</Link>
      </div>)
    })

    return outList
  },[connectLinks, classes.listItem, localhostOrDomain])

  const handleAddLink = async () => {
    await addProducerLink(dispatch, producerId, link)
    setLink("")
    setAddLinkDialogOpen(false)
    setFormKey(formKey+1)
  }

  const handleCloseAddLinkDialog = () => {
    setAddLinkDialogOpen(false)
  }

  const addLinkDialog = (
    <Dialog
      open={addLinkDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseAddLinkDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Add Link"}</DialogTitle>
      <DialogContent>
        <form className={classes.dateForm}>
          <TextField
            id="link"
            key={formKey}
            label="Link to Add"
            type="text"
            focused
            required
            defaultValue={link}
            className={classes.fullWidthField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setLink(e.target.value)
            }}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.slice(0,40)
              e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '').toLowerCase()
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAddLinkDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddLink} color="primary">
          Add Link
        </Button>
      </DialogActions>
    </Dialog>
  )


  return (
    <>
      <Typography variant="h2">
        Connect Links
      </Typography>
      {linkList}
      {addLinkDialog}
    </>)
}

export default ProducerConnectLinks
