import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Button, Checkbox, Collapse, Grid, IconButton, Switch, TextField } from '@material-ui/core'
import { BalanceTotals, PaymentDetails } from "./BalanceContainer"
import { Autocomplete } from "@material-ui/lab"
import { addCommas, makeMoney } from "lib/functions"
import { useStyles } from "lib/theme"
import CheckboxMulti from "components/util/CheckboxMulti"
import { CheckboxOptions, EventTeamInfo, PaidOptions } from "store/program/programReducer"
import { validateLocationPermission } from 'lib/permissions'
import { ProgramPermissions } from 'store/user/userReducer'
import themis_common from 'store/themis_common_pb'
import { Delete as DeleteIcon } from "@material-ui/icons"
import YesNoDialog from 'components/util/YesNoModal'

interface BalanceDetailsProps {
  balanceTotals: BalanceTotals
  sortedPayData: PaymentDetails
  setSortedPayData: React.Dispatch<React.SetStateAction<PaymentDetails>>
  selectedToPay: string
  paid: PaidOptions
  currentProgramId: number
  superUser: boolean
  permissionCodes: themis_common.PermissionCodes.AsObject
  programPermissions: ProgramPermissions
  setSelectedRegistrationsForPayment: React.Dispatch<React.SetStateAction<number[]>>
  setSelectedEventTeams: React.Dispatch<React.SetStateAction<EventTeamInfo[]>>
  payRegistration: (amountSelectedToPay: number, currency: string) => Promise<void>
  deleteEventTeamAthlete: () => void
  setAthleteToDeleteEventTeamObj: React.Dispatch<React.SetStateAction<themis_common.EventTeam.AsObject>>
  setAthleteToDeleteObj: React.Dispatch<React.SetStateAction<themis_common.Athlete.AsObject>>
  setEntryToDeleteIndex: React.Dispatch<React.SetStateAction<number>>
  setIsRemoveEventTeamAthleteOpen: React.Dispatch<React.SetStateAction<boolean>>
  isRemoveEventTeamAthleteOpen: boolean
  entryToDeleteIndex: number
}

const BalanceDetails: React.FC<BalanceDetailsProps> = ({ balanceTotals, sortedPayData, setSortedPayData, selectedToPay, paid, currentProgramId, superUser, programPermissions, permissionCodes, setSelectedRegistrationsForPayment, setSelectedEventTeams, payRegistration, deleteEventTeamAthlete, setAthleteToDeleteEventTeamObj, setAthleteToDeleteObj, setEntryToDeleteIndex, setIsRemoveEventTeamAthleteOpen, isRemoveEventTeamAthleteOpen, entryToDeleteIndex }): ReactElement => {
  const classes = useStyles()

  const currencies: string[] = balanceTotals.balances.map((balance) => balance.currency)

  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0] || "USD")
  const [refreshLocation, setRefreshLocation] = useState(false)
  const [amountSelectedToPay, setAmountSelectedToPay] = useState(0)
  const [selectedRegistrationTransactions, setSelectedRegistrationTransactions] = useState<number[]>([])
  const [show, setShow] = useState<boolean[]>([])
  const [eventTeamAthleteWasRemoved, setEventTeamAthleteWasRemoved] = useState<boolean>(false)

  useEffect(() => {
    setSelectedRegistrationsForPayment(selectedRegistrationTransactions)
  }, [selectedRegistrationTransactions, setSelectedRegistrationsForPayment])

  const isAllTeamChecked = useCallback((locationIdx: number, teamIdx: number): CheckboxOptions => {
    const checked =  sortedPayData.locations[locationIdx].teams[teamIdx].entries.reduce<number>((prevValue, value) => value.checked ? prevValue + 1 : prevValue , 0)
    if (checked === 0) return 0
    if (checked === sortedPayData.locations[locationIdx].teams[teamIdx].entries.length) return 2
    return 1
  }, [sortedPayData.locations])

  const isAllLocationChecked = useCallback((locationIdx: number): CheckboxOptions => {
    let checked = 0
    sortedPayData.locations[locationIdx].teams.forEach((team, teamIdx) => checked = isAllTeamChecked(locationIdx, teamIdx) > 0 ? checked + 1 : checked )
    if (checked === 0) return 0
    if (checked === sortedPayData.locations[locationIdx].teams.length) return 2
    return 1
  }, [isAllTeamChecked, sortedPayData.locations])

  const checkAllTeam = (locationIdx: number, teamIdx: number, targetState = !isAllTeamChecked(locationIdx, teamIdx)): void => {
    setSortedPayData((previous) => {
      const toReturn = { ...previous }
      toReturn.locations[locationIdx].teams[teamIdx].entries.forEach((entry) => entry.checked = targetState)
      return toReturn
    })
  }

  const checkAllLocation = (locationIdx: number): void => {
    const targetState = !isAllLocationChecked(locationIdx)
    sortedPayData.locations[locationIdx].teams.forEach((team, teamIdx) => {
      checkAllTeam(locationIdx, teamIdx, targetState)
    })
    setRefreshLocation((refresh) => !refresh)
  }

  const setCheckedEntry = (locationIdx: number, teamIdx: number, entryIdx: number, newValue: boolean): void => {
    setSortedPayData((previous) => {
      const toReturn = { ...previous }
      toReturn.locations[locationIdx].teams[teamIdx].entries[entryIdx].checked = newValue
      return toReturn
    })
  }

  const setTeamShow = (teamId: number) => {
    if (eventTeamAthleteWasRemoved) return
    setShow((prevState) => {
      prevState[teamId] = !prevState[teamId]
      return prevState
    })
    setRefreshLocation((refresh) => !refresh)
  }

  useEffect(() => { // Must initialize the show array or we get errors
    const initialShow: boolean[] = []
    sortedPayData.locations.forEach((location) => {
      location.teams.forEach((team) => {
        initialShow[team.id] = false
      })
    })
    setShow(initialShow)
  }, [sortedPayData.locations])

  const removeEventTeamAthleteModal = useMemo(() => {
    return (
      <>
        <Typography variant="body2">
          {`Are you sure you want to do this?`}
        </Typography>
      </>
    )
  }, [])

  const handleRemoveEventTeamAthleteOpen = (eventTeam: themis_common.EventTeam.AsObject, athlete: themis_common.Athlete.AsObject, entryToDeleteIndex: number) => {
    setAthleteToDeleteEventTeamObj(eventTeam)
    setAthleteToDeleteObj(athlete)
    setEntryToDeleteIndex(entryToDeleteIndex)
    setIsRemoveEventTeamAthleteOpen(true)
  }

  const payGrid = useMemo(() => {
    if (!selectedCurrency || show.length === 0) return []
    return sortedPayData.locations.map((location, i) => {
      const validatedToPayForLocation = validateLocationPermission(permissionCodes.financesDo, permissionCodes, superUser, programPermissions, currentProgramId, location.id)
      const locationBalance = location.balances.find((balance) => balance.currency === selectedCurrency)
      if (locationBalance) {
        const amount = addCommas((locationBalance.balance / 100).toFixed(2))
        return <Grid key={`locations-${i}`} item container xs={12}>
          <Grid item xs={12}><Typography><CheckboxMulti checkboxId={`cbx-locations-${i}`} checked={isAllLocationChecked(i)} onClick={() => checkAllLocation(i)} disabled={paid !== 1 || !validatedToPayForLocation} /> {location.name} - {`${amount} ${locationBalance.currency}`}</Typography></Grid>
          {location?.teams.map((team, j) => {
            const teamBalance = team.balances.find((balance) => balance.currency === selectedCurrency)
            if (teamBalance) {
              const amount = addCommas((teamBalance.balance / 100).toFixed(2))
              return <Grid key={`teams-${i}-${j}`} container>
                <Grid item xs={1}>&nbsp;</Grid>
                <Grid item xs={11}>
                  <Typography>
                    <CheckboxMulti checkboxId={`cbx-teams-${i}-${j}`} checked={isAllTeamChecked(i, j)} onClick={() => { checkAllTeam(i, j) }} disabled={paid !== 1 || !validatedToPayForLocation} />
                    {team.name} - {`${amount} ${teamBalance.currency}`}
                    <Switch checked={show[team.id] || eventTeamAthleteWasRemoved} onChange={(e) => {setEventTeamAthleteWasRemoved(false); setTeamShow(team.id)}} />
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Collapse in={show[team.id] || eventTeamAthleteWasRemoved} style={{ width: "100%" }}>
                    {team.entries.map((entry, k) => {
                      const entryBalance = entry.balances.find((balance) => balance.currency === selectedCurrency)
                      if (entryBalance) {
                        const amount = addCommas((entryBalance.balance / 100).toFixed(2))
                        return <Grid key={`entry-${i}-${j}-${k}`} container>
                          <Grid item xs={2}>&nbsp;</Grid>
                          <Grid item xs={10}>
                            <Typography style={{ fontSize: "10px" }}><Checkbox checked={entry.checked} onClick={(e) => { setCheckedEntry(i, j, k, !entry.checked) }} disabled={paid !== 1 || !validatedToPayForLocation} /> {entry.name} - {`${amount} ${entryBalance.currency}`}
                              <IconButton size="small" className={classes.clickable} aria-label="remove-event-team-athlete" onClick={() => {if (entry.eventTeam && entry.athlete) {handleRemoveEventTeamAthleteOpen(entry.eventTeam, entry.athlete, k)}}}>
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Typography>
                          </Grid>
                        </Grid>
                      } else {
                        return []
                      }
                    })
                    }
                  </Collapse>
                </Grid>
              </Grid>
            }
            return []
          })}
        </Grid>
      } else {
        return <></>
      }
    })
  }, [selectedCurrency, sortedPayData.locations, isAllLocationChecked, checkAllLocation, isAllTeamChecked, show, checkAllTeam, setCheckedEntry, refreshLocation, paid, eventTeamAthleteWasRemoved])

  useEffect(() => {
    let totalAmount = 0
    const selectedTransactions: number[] = []
    sortedPayData.locations.forEach((location) => {
      location.teams.forEach((team) => {
        team.entries.forEach((entry) => {
          if (entry.checked) {
            const balance = entry.balances.find(balance => balance.currency === selectedCurrency)
            totalAmount += balance?.balance || 0
            selectedTransactions.push(entry.id)
          }
        })
      })
    })
    setAmountSelectedToPay(totalAmount)
    setSelectedRegistrationTransactions(selectedTransactions)
  }, [selectedCurrency, sortedPayData])

  useEffect(() => {
    let totalAmount = 0
    const eventTeamsInfo: EventTeamInfo[] = []
    sortedPayData.locations.forEach((location) => {
      location.teams.forEach((team) => {
        team.entries.forEach((entry) => {
          if (entry.checked) {
            const balance = entry.balances.find(balance => balance.currency === selectedCurrency)
            totalAmount += balance?.balance || 0
          }
        })
      })
    })
    setAmountSelectedToPay(totalAmount)
    setSelectedEventTeams(eventTeamsInfo)
  }, [selectedCurrency, sortedPayData, setSelectedEventTeams])

  const removeAthleteEntry = () => {
    sortedPayData.locations.map((location, i) => {
      const locationBalance = location.balances.find((balance) => balance.currency === selectedCurrency)
      if (locationBalance) {
        location?.teams.map((team, j) => {
          const teamBalance = team.balances.find((balance) => balance.currency === selectedCurrency)
          if (teamBalance) {
            team.entries.map((entry, k) => {
              const entryBalance = entry.balances.find((balance) => balance.currency === selectedCurrency)
              if (entryBalance) {
                delete team.entries[entryToDeleteIndex]
              }
            })
          }
        })
      }
    })
  }

  return (
    <Grid item container xs={6} alignContent="flex-start">
      <YesNoDialog title={'Are you sure you want to remove this athlete from the event team?'} question={removeEventTeamAthleteModal}
        isOpen={isRemoveEventTeamAthleteOpen} onClose={() => {setIsRemoveEventTeamAthleteOpen(false)}} buttonActions={[{name:"Cancel", color: "primary", callback: () => setIsRemoveEventTeamAthleteOpen(false) }, { name: "Remove", color: "primary", callback: () => {setEventTeamAthleteWasRemoved(true); deleteEventTeamAthlete(); removeAthleteEntry()} }]} />
      <Grid item xs={12} className={classes.paymentGridHeader}>
        <Typography variant="h2">Details</Typography>
        <Typography variant="h4">View details and pay here</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Autocomplete
            value={selectedCurrency}
            renderInput={(params) => <TextField style={{ marginTop: 16, marginBottom: 8 }} {...params} label="Currency to View" variant="outlined"/>}
            options={currencies}
            onChange={(e, v) => {
              v && setSelectedCurrency(v)
            }}
          />
        </Grid>
        <Grid item container xs={6} justify="center" alignContent="center">
          <Button variant="outlined" color="inherit" style={{ marginLeft: "16px" }} disabled={!amountSelectedToPay || paid !== 1}
            onClick={e => {
              payRegistration(amountSelectedToPay, selectedCurrency)
            }} >
            Pay {makeMoney(amountSelectedToPay, selectedCurrency)}
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {balanceTotals.producers.length > 0 && selectedToPay > "" ? payGrid : <></>}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BalanceDetails
