import React, { ChangeEvent, ReactElement, useState, useMemo } from 'react'
import { useStyles } from "lib/theme"
import { countries } from "lib/constants"
import { Link as RouterLink } from "react-router-dom"
import { AppBar, Container, Grid, IconButton, MenuItem, Tab, Tabs, Typography, useTheme } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon, } from '@material-ui/icons'
import { TabPanel } from "lib/pieces"
import AthleteGrid from "components/program/athlete/AthleteGrid"
import CoachGrid from "components/program/coach/CoachGrid"
import PersonnelGrid from "components/program/personnel/PersonnelGrid"
import YesNoDialog from "components/util/YesNoModal"
import UserGrid from "components/user/user_grid/UserGrid"
import { PermissionLists } from "store/user/userActions"
import themis_common from "store/themis_common_pb"
import { ProgramPermissions } from 'store/user/userReducer'
import { validateTeamPermission } from 'lib/permissions'
import ViewProducersButton from 'components/util/ViewProducersButton'
import EventGridContainer from '../event_grid/EventGridContainer'


const countryItems: any[] = []
for (const country of countries) {
  countryItems.push(<MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>)
}

interface TeamProps {
  superUser: boolean
  permissionCodes: themis_common.PermissionCodes.AsObject
  programPermissions: ProgramPermissions
  permissionLists: PermissionLists
  locationId: number
  teamId: number
  program: themis_common.Program.AsObject | undefined
  currentLocation: themis_common.Location.AsObject
  team: themis_common.Team.AsObject | undefined
  currentTab: number
  handleChangeTab: (event: ChangeEvent<{}>, value: number)=>void
  handleDeleteTeam: Function
  handleDeleteAthleteFromTeam: (athleteId: number) => void
  handleDeleteCoachFromTeam: (coachId: number) => void
  handleDeletePersonnelFromTeam: (personnelId: number) => void
  handleAddAthleteToTeam: (athleteId: number) => void
  handleAddCoachToTeam: (coachId: number) => void
  handleAddPersonnelToTeam: (personnelId: number) => void
  locationAthletes: themis_common.Athlete.AsObject[]
  locationCoaches: themis_common.Coach.AsObject[]
  locationPersonnels: themis_common.Personnel.AsObject[]
  handleAddAthletesToTeam: () => void
  athletesToAdd: themis_common.Athlete.AsObject[]
  setAthletesToAdd: React.Dispatch<React.SetStateAction<themis_common.Athlete.AsObject[]>>
}

const Team: React.FC<TeamProps> = ({
  superUser, programPermissions, permissionCodes,
  permissionLists, locationId, teamId, program, currentLocation, team,
  currentTab, handleChangeTab, handleDeleteTeam, handleDeleteAthleteFromTeam, handleDeleteCoachFromTeam, handleDeletePersonnelFromTeam,
  handleAddAthleteToTeam, handleAddCoachToTeam, handleAddPersonnelToTeam, locationAthletes, locationCoaches, locationPersonnels,
  handleAddAthletesToTeam, athletesToAdd, setAthletesToAdd
}): ReactElement => {
  const classes = useStyles()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteTeamDialogOpen, setDeleteTeamDialogOpen] = useState(false)

  const theme = useTheme()
  const editTeamIcon = useMemo(() => {
    if (program?.id && validateTeamPermission(permissionCodes.teamEdit, permissionCodes, superUser, programPermissions, program.id, locationId, teamId)) {
      return (
        <IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={`/Location/${currentLocation?.id}/EditTeam/${team?.id}`}>
          <EditIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [classes.clickable, currentLocation?.id, team?.id, locationId, programPermissions, program, teamId, superUser, permissionCodes])

  const deleteTeamIcon = useMemo(() => {
    if (program?.id && validateTeamPermission(permissionCodes.teamDelete, permissionCodes, superUser, programPermissions, program.id, locationId, teamId)) {
      return (
        <IconButton className={classes.clickable} aria-label="delete" onClick={(e) => { setDeleteTeamDialogOpen(true) }}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes.clickable, locationId, programPermissions, program, teamId, superUser, permissionCodes])

  const headerContents = () => {
    if (team?.name) {
      return `Manage Team - ${team.name}`
    } else {
      return "Select Team to Continue"
    }
  }

  const userCanViewTeam = useMemo((): boolean | undefined => {
    if (program?.id) return validateTeamPermission(permissionCodes.teamView, permissionCodes, superUser, programPermissions, program.id, locationId, teamId)
  }, [superUser, programPermissions, locationId, program?.id, teamId, permissionCodes])

  if (!currentLocation || !program) return (<></>)
  return userCanViewTeam ? (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid container item xs={12} alignItems="flex-start">
          <Typography variant="h1" className={classes.headerTypo}>{headerContents()}</Typography>
          <Grid item xs={2}>
            {editTeamIcon}{deleteTeamIcon}
          </Grid>
          <Grid item xs={3}>
            {currentTab === 3 ? <ViewProducersButton></ViewProducersButton> : <></>}
          </Grid>
          <AppBar position="static" style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }}>
            <Tabs value={currentTab} onChange={handleChangeTab} variant="scrollable">
              <Tab label="Athletes" id="tab-1" disabled={!validateTeamPermission(permissionCodes.athleteView, permissionCodes, superUser, programPermissions, program.id, locationId, teamId)} />
              <Tab label="Coaches" id="tab-2" disabled={!validateTeamPermission(permissionCodes.coachView, permissionCodes, superUser, programPermissions, program.id, locationId, teamId)}/>
              <Tab label="Personnel" id="tab-3" disabled={!validateTeamPermission(permissionCodes.personnelView, permissionCodes, superUser, programPermissions, program.id, locationId, teamId)} />
              <Tab label="Events" id="tab-4" />
              <Tab label="Users" id="tab-5" disabled={!validateTeamPermission(permissionCodes.teamUserView, permissionCodes, superUser, programPermissions, program.id, locationId, teamId)}/>
            </Tabs>
          </AppBar>
          <TabPanel value={currentTab} index={0}>
            { team?.athletesList !== undefined &&
              <AthleteGrid
                athletes={team?.athletesList}
                programId={program.id}
                locationId={currentLocation.id}
                teamId={teamId}
                superUser={superUser}
                programPermissions={programPermissions}
                currentTeam={team}
                handleDeleteAthlete={handleDeleteAthleteFromTeam}
                handleAddAthleteDropdown={handleAddAthleteToTeam}
                locationAthletes={locationAthletes}
                permissionCodes={permissionCodes}
                handleAddAthletesToTeam={handleAddAthletesToTeam}
                athletesToAdd={athletesToAdd}
                setAthletesToAdd={setAthletesToAdd}
              />
            }
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            {team?.coachesList !== undefined &&
              <CoachGrid
                coaches={team?.coachesList}
                programId={program.id}
                locationId={currentLocation.id}
                teamId={teamId}
                superUser={superUser}
                programPermissions={programPermissions}
                currentTeam={team}
                handleDeleteCoach={handleDeleteCoachFromTeam}
                handleAddCoachDropdown={handleAddCoachToTeam}
                locationCoaches={locationCoaches}
                permissionCodes={permissionCodes}
              />
            }
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            {team?.personnelList !== undefined &&
              <PersonnelGrid
                personnel={team?.personnelList}
                programId={program.id}
                locationId={currentLocation.id}
                teamId={teamId}
                superUser={superUser}
                programPermissions={programPermissions}
                currentTeam={team}
                handleDeletePersonnel={handleDeletePersonnelFromTeam}
                handleAddPersonnelDropdown={handleAddPersonnelToTeam}
                locationPersonnels={locationPersonnels}
                permissionCodes={permissionCodes}
              />
            }
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <EventGridContainer
              eventGridType={"team"}
              programPermissions={programPermissions}
              programId={program.id}
              locationId={currentLocation.id}
              teamId={teamId}
              program={program}
              location={currentLocation}
              team={team}
              superUser={superUser}
              permissionCodes={permissionCodes} />
          </TabPanel>
          <TabPanel value={currentTab} index={4}>
            {!permissionLists ? [] :
              <UserGrid type={"team"} superDuperObjectId={program.id} superObjectId={currentLocation.id}
                objectId={teamId} permissionLists={permissionLists}/>
            }
          </TabPanel>
        </Grid>
      </Grid>
      <YesNoDialog
        title={"Error"}
        question={"There was an error. Please contact us if this continues to be a problem."}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        buttonActions={[
          { name: "Okay", color: "primary", callback: () => setDialogOpen(false) },
        ]}
      />
      <YesNoDialog
        title={`Delete ${team?.name}?`}
        question={`Deleting a team will not delete the athletes, coaches, or personnel that are on the team. However, deleting a team can not be undone. Are you sure you want to delete ${team?.name}?`}
        isOpen={deleteTeamDialogOpen}
        onClose={() => setDeleteTeamDialogOpen(false)}
        buttonActions={[
          { name: "No", color: "primary", callback: () => setDeleteTeamDialogOpen(false) },
          { name: "Yes", color: "primary", callback: handleDeleteTeam },
        ]}
      />
    </Container>
  ) : <></>
}

export default Team
