import React, { ReactElement, useState } from 'react'
import { AsFields } from 'lib/constants'
import { addBugReport } from "store/user/userActions"
import { selectCurrentState, selectCurrentUserId } from 'store/user/userSelectors'
import { IAppState } from 'store/store'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import BugReport from './BugReport'

const BugReportContainer: React.FC = (): ReactElement => {

  const emptyBugReport = {
    type: "",
    description: ""
  }

  const location = useLocation()

  const currentState = useSelector((state: IAppState) => selectCurrentState(state))
  const currentUserId = useSelector((state: IAppState) => selectCurrentUserId(state))

  const [checkBugReport, setCheckBugReport] = useState<boolean>(false)
  const [userGeoLocation, setUserGeoLocation] = useState<GeolocationPosition | undefined>()
  const [formReady, setFormReady] = useState<boolean>(false)
  const [bugReport, setBugReport] = useState<AsFields>(emptyBugReport)
  const [showBugReport, setShowBugReport] = useState<boolean>(false)
  const [reportIssue, setReportIssue] = useState<string>("")
  const [errorText, setErrorText] = useState<{ [key: string]: string; }>({})
  const [isSubmissionSuccessOpen, setIsSubmissionSuccessOpen] = useState<boolean>(false)

  const resetForm = () => {
    setShowBugReport(false)
    setReportIssue("")
    setBugReport(emptyBugReport)
    setErrorText({})
  }

  const submitForm: any = async (event: any) => {
    setCheckBugReport(true)
    event.preventDefault()
    if (formReady) {
      await addBugReport(bugReport, currentState, location.pathname, currentUserId, userGeoLocation?.coords)
      resetForm()
      setIsSubmissionSuccessOpen(true)
    }
  }

  return (
    <BugReport
      bugReport={bugReport}
      setBugReport={setBugReport}
      errorText={errorText}
      setErrorText={setErrorText}
      checkBugReport={checkBugReport}
      setCheckBugReport={setCheckBugReport}
      setFormReady={setFormReady}
      reportIssue={reportIssue}
      showBugReport={showBugReport}
      setShowBugReport={setShowBugReport}
      setUserGeoLocation={setUserGeoLocation}
      setReportIssue={setReportIssue}
      resetForm={resetForm}
      submitForm={submitForm}
      isSubmissionSuccessOpen={isSubmissionSuccessOpen}
      setIsSubmissionSuccessOpen={setIsSubmissionSuccessOpen}
    />
  )
}

export default BugReportContainer
