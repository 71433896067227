import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import { format, parse } from "date-fns"
import themis_common from 'store/themis_common_pb'
import { Transition } from "../../lib/theme"
import { KeyboardTimePicker } from "@material-ui/pickers"
import { AccessTime as AccessTimeIcon } from "@material-ui/icons"
import YesNoDialog from "./YesNoModal"
import { useStyles } from "lib/theme"
import { editFloorDate, updateEvent } from 'store/producer/eventActions'
import { useDispatch } from 'react-redux'
import { regexpNumber } from 'lib/constants'

interface FloorDateSelectProps {
  currentDate:  themis_common.EventDate.AsObject | undefined
  currentFloor:  themis_common.EventFloor.AsObject | undefined
  setCurrentDate: Function
  filteredEventDates: themis_common.EventDate.AsObject[]
  setCurrentFloor: Function,
  filteredEventFloors: themis_common.EventFloor.AsObject[],
  onlyTeams: boolean,
  setOnlyTeams: Function,
  handleCopyPreviousDay: Function,
  event: themis_common.Event.AsObject,
  userCanEditEvent: boolean,
  currentFloorDate: themis_common.EventFloorDate.AsObject,
  setCurrentFloorDate: React.Dispatch<React.SetStateAction<themis_common.EventFloorDate.AsObject | undefined>>

}

const FloorDateSelect: React.FC<FloorDateSelectProps> = ({
  currentDate, currentFloor, setCurrentDate, filteredEventDates, setCurrentFloor, filteredEventFloors, onlyTeams, setOnlyTeams, handleCopyPreviousDay, event, userCanEditEvent, currentFloorDate, setCurrentFloorDate
}): ReactElement => {
  const classes = useStyles()

  const [showCopyDialog, setShowCopyDialog] = useState(false)
  const [firstDate, setFirstDate] = useState(true)
  const [thePrevDate, setThePrevDate] = useState<themis_common.EventDate.AsObject | undefined>()
  const [warmupTime, setWarmupTime] = useState<number>(0)
  const [rotationTime, setRotationTime] = useState<number>(0)
  const [loadingWarmupTime, setLoadingWarmupTime] = useState(false);
  const [loadingRotationTime, setLoadingRotationTime] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    filteredEventDates.find((date, i) => {
      if (date.id === currentDate?.id) {
        setFirstDate(i === 0)
        if (!firstDate) {
          setThePrevDate(filteredEventDates[i-1])
        }
        return true
      }
      return false
    })
  }, [currentDate?.id, filteredEventDates, firstDate])

  useEffect(() => {
    if (currentFloorDate.rotationTime || currentFloorDate.warmUpTime) {
      setRotationTime(currentFloorDate.rotationTime / 60);
      setWarmupTime(currentFloorDate.warmUpTime / 60);
    } else {
      setRotationTime(currentFloorDate.rotationTime / 60);
      setWarmupTime(currentFloorDate.warmUpTime / 60);
    }
  }, [event, currentFloorDate]);
  

  const updateEventWarmupTime = useCallback(async () => {
    if (!userCanEditEvent) return;
    setLoadingWarmupTime(true);
    try {
      if (event.brand && event.brand.producer) {
        await editFloorDate(
          { ...currentFloorDate, warmUpTime: warmupTime * 60 },
          event.brand.producer.id,
          event.brand.id,
          event.id,
          dispatch
        );
        const eventFloors: themis_common.EventFloor.AsObject[] =
          await editFloorDate(
            { ...currentFloorDate, warmUpTime: warmupTime * 60 },
            event?.brand?.producer?.id,
            event.brand.id,
            event.id,
            dispatch
          );
          "";
        const foundEventFloor: themis_common.EventFloor.AsObject | undefined =
          eventFloors.find((eventFloor) => eventFloor.id === currentFloor?.id);
        const foundFloorDate: themis_common.EventFloorDate.AsObject | undefined =
          foundEventFloor?.daysList.find((day) => day.id === currentFloorDate.id);
        if (foundFloorDate) {
          setCurrentFloorDate(foundFloorDate);
          setWarmupTime(foundFloorDate.warmUpTime / 60);
        }
      }
    } catch (error) {
      console.log("error while update warmup time", error);
    } finally {
      setLoadingWarmupTime(false);
    }
  }, [userCanEditEvent, currentFloorDate, warmupTime, dispatch]);
  
  const updateEventFloorDateRotationTime = useCallback(async () => {
    if (!userCanEditEvent) return;
    setLoadingRotationTime(true);
    try {
      if (event.brand && event.brand.producer) {
        await editFloorDate(
          { ...currentFloorDate, rotationTime: rotationTime * 60 },
          event.brand.producer.id,
          event.brand.id,
          event.id,
          dispatch
        );
        // Not sure if it's best to update the state through redux here
        const eventFloors: themis_common.EventFloor.AsObject[] =
          await editFloorDate(
            { ...currentFloorDate, rotationTime: rotationTime * 60 },
            event.brand.producer.id,
            event.brand.id,
            event.id,
            dispatch
          );
        const foundEventFloor: themis_common.EventFloor.AsObject | undefined =
          eventFloors.find((eventFloor) => eventFloor.id === currentFloor?.id);
        const foundFloorDate: themis_common.EventFloorDate.AsObject | undefined =
          foundEventFloor?.daysList.find((day) => day.id === currentFloorDate.id);
        if (foundFloorDate) {
          setCurrentFloorDate(foundFloorDate);
          setRotationTime(foundFloorDate.rotationTime / 60);
        }
      }
    } catch (error) {
      console.log("error while update rotation time", error);
    } finally {
      setLoadingRotationTime(false);
    }
  }, [userCanEditEvent, currentFloorDate, rotationTime, dispatch]);  
  
  return !currentDate || !currentFloor || filteredEventDates.length === 0 || filteredEventFloors.length === 0? <></> : (
    <>
      <Grid item xs={6} md={2} lg={2} xl={2}>
        <InputLabel id="selectDate">Date:&nbsp;
          <Select labelId="selectDate" id="select" defaultValue={currentDate?.id} onChange={(e) => {
            setCurrentDate(filteredEventDates?.find(date => date.id === e.target.value))
          }}>
            {filteredEventDates?.map((date) => <MenuItem key={`selectDate-${date.id}`}
              value={date.id}>{format(parse(date.startDay, "yyyy-MM-dd|HH:mm", new Date()), "LLL d, yyyy")}</MenuItem>)}
          </Select>
        </InputLabel>
      </Grid>
      <Grid item xs={6} md={2} lg={2} xl={2}>
        <InputLabel id="selectFloors">Floor:&nbsp;
          <Select labelId="selectFloors" id="select" defaultValue={currentFloor?.id} onChange={(e) => {
            setCurrentFloor(filteredEventFloors?.find(floor => floor.id === e.target.value))
          }}>
            {filteredEventFloors?.map((floor) => <MenuItem key={`selectFloor-${floor.id}`}
              value={floor.id}>{floor.name}</MenuItem>)}
          </Select>
        </InputLabel>
      </Grid>
      <Grid item xs={6} md={2} lg={2} xl={2} style={{display:loadingWarmupTime ? "flex" : ""}}>
        <TextField
          variant="outlined"
          id="warmupTime"
          margin="dense"
          label="Warmup Time"
          style={{width: "125px"}}
          value={warmupTime}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">Mins</InputAdornment>,
          }}
          onChange={(e) => {
            if (regexpNumber.test(e.target.value)) {
              setWarmupTime(Number(e.target.value))
            }
          }}
        />
          {!userCanEditEvent ? <></> :
          loadingWarmupTime ?  
          <Typography style={{marginLeft:"5px",marginTop:"14px"}}>
           Loading...
          </Typography> : 
            <Button
              type="submit"
              size="small"
              disabled={(loadingWarmupTime || Object.keys(currentFloorDate).length ? currentFloorDate.warmUpTime / 60 === warmupTime : false)}
              variant="contained"
              className={classes.scheduleSaveButton}
              onClick={updateEventWarmupTime}
            >Save</Button>
          }  
      </Grid>
      <Grid item xs={6} md={2} lg={2} xl={2} style={{display:loadingRotationTime ? "flex" : ""}}>
        <TextField
          variant="outlined"
          id="rotationTime"
          margin="dense"
          label="Rotation Time"
          style={{width: "125px"}}
          value={rotationTime}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">Mins</InputAdornment>,
          }}
          onChange={(e) => {
            if (regexpNumber.test(e.target.value)) {
              setRotationTime(Number(e.target.value))
            }
          }}
          />
          {!userCanEditEvent ? <></> :
            loadingRotationTime ? 
            <Typography style={{marginLeft:"5px",marginTop:"14px"}}>
              Loading...
            </Typography> : 
            <Button
              type="submit"
              size="small"
              variant="contained"
              disabled={(loadingRotationTime || Object.keys(currentFloorDate).length ? currentFloorDate.rotationTime / 60 === rotationTime : false)}
              className={classes.scheduleSaveButton}

              onClick={updateEventFloorDateRotationTime}
            >Save</Button>
          }      
      </Grid>
      <Grid item xs={6} md={2} lg={2} xl={2}>
        Show only divisions with registrations: <Checkbox checked={onlyTeams} onChange={() => {
          setOnlyTeams((current: boolean) => !current)
        }}/>
      </Grid>
      <Grid item xs={6} md={5} lg={3} xl={2}>
        {firstDate ? [] : <Button size="small" variant="contained" onClick={() => { setShowCopyDialog(true) }}>Copy Panels, Shifts, and Divisions From Previous Day</Button>}
      </Grid>      
      <YesNoDialog
        title={`Are You Sure`}
        question={`If you continue the system will delete all current panels, shifts, and divisions for this floor and date and replace them with the panels, shifts, and divisions from the previous date.  Are you sure?`}
        isOpen={showCopyDialog}
        onClose={() => {
          if (setShowCopyDialog) setShowCopyDialog(false)
        }}
        buttonActions={[
          {
            name: "No", color: "primary", callback: () => {
              if (setShowCopyDialog) setShowCopyDialog(false)
            }
          },
          {
            name: "Yes", color: "primary", callback: () => {
              if (!firstDate && thePrevDate) handleCopyPreviousDay(thePrevDate.id)
              if (setShowCopyDialog) setShowCopyDialog(false)
            }
          },
        ]}
      />
    </>)
}

export default FloorDateSelect
