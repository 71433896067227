
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { IAppState } from "store/store"
import { useStyles } from "lib/theme"
import { Button, Container, Tab, Grid, Paper, Typography, AppBar, useTheme } from '@material-ui/core'
import { getAllProducerLinks } from "store/producer/producerActions"
import { listProducerLink } from "store/program/programActions"
import themis_common from 'store/themis_common_pb'
import { selectCurrentLoginState, selectUserProgramPermissions } from "store/user/userSelectors"
import { useSelector } from 'react-redux'
import { connectProducerLink } from 'store/producer/producerActions'
import { useParams } from 'react-router-dom'

const EventProducers: React.FC = (): ReactElement => {
  const theme = useTheme()
  const classes = useStyles()

  const { connectUrl } = useParams<{ connectUrl: string }>()

  const loggedIn = useSelector((state: IAppState) => selectCurrentLoginState(state))
  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))

  const [allProducerLinks, setAllProducerLinks] = useState<themis_common.ProducerUrl.AsObject[]>([])
  const [programIds, setProgramIds] = useState<number[]>([])
  const [receivedProgramIds, setReceivedProgramIds] = useState<boolean>(false)
  const [programAllowedProducers, setProgramAllowedProducers] = useState<themis_common.ProgramAllowedProducers.AsObject[]>([])

  const getProducerLinks = useCallback(() => {
    const getProducerLinks = async () => {
      const producerLinks = await getAllProducerLinks()
      // If clicking an email invite and logging in only give the producer from the invite link
      if (connectUrl) {
        const producerMatchArray: themis_common.ProducerUrl.AsObject[] = []
        const connectProducerMatch: themis_common.ProducerUrl.AsObject | undefined = producerLinks.find((producer => producer.connectUrl === connectUrl))
        if (connectProducerMatch) {
          producerMatchArray.push(connectProducerMatch)
        }
        setAllProducerLinks(producerMatchArray)
      } else {
        setAllProducerLinks(producerLinks)
      }
    }
    getProducerLinks()
  }, [connectUrl])

  useEffect(() => {
    Object.keys(programPermissions).forEach((key) => {
      const program = programPermissions[key]
      setProgramIds(currentProgramIds => { return [ ...currentProgramIds, program.id ] })
      setReceivedProgramIds(true)
    })
  }, [programPermissions])

  const listProducerLinks = useCallback(() => {
    const getConnections = async() => {
      const producerLinks = await listProducerLink(programIds)
      setProgramAllowedProducers(producerLinks)
    }
    if (receivedProgramIds) {
      getConnections()
    }
  }, [programIds, receivedProgramIds])

  useEffect(() => {
    getProducerLinks()
    listProducerLinks()
  }, [getProducerLinks, listProducerLinks])

  const runConnect = useCallback(async(connectUrl: string, programId: number, programIds: number[], producerName: string) => {
    if (!connectUrl) return
    await connectProducerLink(connectUrl, programId, programIds, producerName)
    listProducerLinks()
  }, [listProducerLinks])

  const producerLinkHeader = useMemo(() => {
    return (
      <Grid container style={{ borderBottom: "1px solid #545252", paddingTop: "15px", paddingBottom: "15px" }}>
        <Grid item xs={6} md={3}>
          Program(s)
        </Grid>
        <Grid item xs={6} md={9}>
          Event Producer(s)
        </Grid>
      </Grid>
    )
  }, [])

  const generateProducerList = useCallback((programId: number) => {
    const producerOutlist: ReactNode[] = []
    allProducerLinks.forEach((link) => {
      const producerName = link.producer?.name
      const brandNames = link.producer?.brandsList.map((key) => key.name).join(", ")

      const programAllowedProducerList: any[] = []
      if (programAllowedProducers) {
        Object.values(programAllowedProducers).forEach((item) => {
          Object.values(item).forEach((programAllowedProducer) => {
            programAllowedProducerList.push(programAllowedProducer)
          })
        })
      }
      const found = !!programAllowedProducerList.find(element => element.programId === programId && element.producerId === link.producerId)

      producerOutlist.push(
        <Grid container key={link.id} style={{ paddingBottom: 15, paddingTop: 10, borderBottom: "1px solid #545252" }}>
          <Grid item xs={12} md={8}>
            {producerName}
            { brandNames ?
              <Grid item>
                <Typography variant="caption">
                  {`- Brands: ${brandNames}`}
                </Typography>
              </Grid> : <></>
            }
          </Grid>
          <Grid item xs={12} md={4}>
            {found ?
              (
                <Button size="small" variant="contained" style={{ color:"white", backgroundColor: "green", width: '110px' }} disabled>
                  Connected
                </Button>) :
              (
                <Button size="small" variant="contained" style={{ width: '110px' }} disabled={!loggedIn} onClick={() => {if (link.producer) runConnect(link.connectUrl, programId, programIds, link.producer?.name)}}>
                  Connect
                </Button>
              )
            }
          </Grid>
        </Grid>
      )
    })
    return producerOutlist
  }, [allProducerLinks, loggedIn, programIds, programAllowedProducers, runConnect])

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid container item xs={12} alignItems="flex-start">
          <Typography variant="h1" className={classes.headerTypo}>Event Producers</Typography>
          <AppBar position="static" style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white, marginBottom: 20 }}>
            <Tab id="tab-0" disabled/>
          </AppBar>
          <Container component={Paper} maxWidth="md">
            {producerLinkHeader}
            {Object.keys(programPermissions).map((key) => {
              const program = programPermissions[key]
              if (program) {
                return (
                  <Grid container key={program.id} style={{ borderBottom: "1px solid #545252", paddingTop: "25px", paddingBottom: "25px" }}>
                    <Grid item xs={6} md={3}>
                      {program.name}
                    </Grid>
                    <Grid item xs={6} md={9}>
                      {generateProducerList(program.id)}
                    </Grid>
                  </Grid>
                )
              }
              return <></>
            })}
          </Container>
        </Grid>
      </Grid>
    </Container>)
}

export default EventProducers

