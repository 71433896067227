import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Box, Button, Grid, Typography, } from "@material-ui/core"
import { useStripe } from "@stripe/react-stripe-js"
import { addACHSource } from "store/user/userStripeActions"
import themis_common from "store/themis_common_pb"
import { CreateTokenBankAccountData, StripeError } from "@stripe/stripe-js"
import BankAccountForm from "./BankAccountForm"

const AddBankAccount: React.FC = (): React.ReactElement => {
  const history = useHistory()
  const stripe = useStripe()
  const [success, setSuccess] = useState(false)

  const addBankAccount = async (bankAccountData: CreateTokenBankAccountData): Promise<[themis_common.StripeBankAccount.AsObject, StripeError | undefined]> => {
    const stripeResponse = await stripe?.createToken('bank_account', bankAccountData)
    if (!stripeResponse) {
      console.error("Error adding Bank Account")
      return [new themis_common.StripeBankAccount().toObject(), undefined]
    } else if (stripeResponse?.error) {
      if (stripeResponse?.error?.message && stripeResponse.error.message.length > 0) {
        console.error(stripeResponse?.error.message)
      }
      console.error("Error adding Bank Account")
      return [new themis_common.StripeBankAccount().toObject(), stripeResponse?.error]
    } else {
      try {
        const response = await addACHSource(stripeResponse.token.id)
        if (response.id.length > 0) {
          setSuccess(true)
          history.push("/ManagePaymentMethods")
          return [response, undefined]
        }
        return [new themis_common.StripeBankAccount().toObject(), undefined]
      } catch (error) {
        const errorResponseObject: StripeError | undefined = JSON.parse(error["message"])
        if (errorResponseObject?.message && errorResponseObject.message.length > 0) {
          console.error(errorResponseObject.message)
        }
        console.error("Error adding Bank Account")
        return [new themis_common.StripeBankAccount().toObject(), errorResponseObject]
      }
    }
  }

  return (
    <Box mt={2} textAlign="center" key={`add-credit-card-form`}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          {!success ? (<>
            <Typography variant="h2">Add a Bank Account (ACH)</Typography>
            <BankAccountForm handleAddBankAccount={addBankAccount}/>
          </>) : (<>
            <Typography variant="h2">Credit Card Added Successfully</Typography>
            <Button variant="outlined" onClick={() => history.push("/ManagePaymentMethods")}>{"Back to Payment Methods"}</Button>
          </>)}
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddBankAccount
