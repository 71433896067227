import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid, IconButton,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import themis_common from 'store/themis_common_pb'
import themis_api from 'store/themis_api_pb'
import { Delete as DeleteIcon, Add as AddIcon, HighlightOff as DisabledIcon, PictureAsPdfRounded as PdfIcon } from '@material-ui/icons'
import MUIRichTextEditor from 'mui-rte'
import { convertToRaw, EditorState, Modifier } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"
import SubmitButton from "../../util/SubmitButton"
import { addDocumentToAllFutureEvents, addProducerDocument, deleteProducerDocument, saveProducerDocument } from "../../../store/producer/producerActions"
import {
  addEventDocument,
  deleteEventDocument,
  getSignedEventDocuments,
  resendDocumentToEvent,
  saveEventDocument
} from "../../../store/producer/eventActions"
import { useStyles } from "../../../lib/theme"
import { Page, View, Document, Text, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer'
import Html from "react-pdf-html"

interface reportsProps {
  producerId: number
  brandId?: number
  eventId?: number
  documents: themis_common.EventDocument.AsObject[]
  setRefreshDocuments: React.Dispatch<React.SetStateAction<boolean>>
  producer: themis_common.Producer.AsObject
  brands: themis_common.Brand.AsObject[]
  events?: themis_common.Event.AsObject[]
  addDocument: Function
}

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: 20,
        backgroundColor: "#CCC",
        color: "black"
      },
      editor: {
        borderBottom: "1px solid gray",
        backgroundColor: "#FFF",
        height: "30VH",
        overflow: "auto",
      }
    }
  }
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  section: {
    width: '100%',
    margin: 5,
    padding: 5,
    flexGrow: 1
  },
  sigline: {
    fontSize: '16px',
    marginTop: '700px',
    textAlign: 'left',
    paddingLeft: -565,
    width: '100%'
  },
  sig: {
    fontFamily: 'handlee',
    fontSize: '16px',
    marginTop: '-20px',
    textAlign: 'left',
    paddingLeft: -565,
    left: 90,
  },
  sigDate: {
    fontFamily: 'handlee',
    fontSize: '16px',
    marginTop: '-20px',
    textAlign: 'left',
    paddingLeft: -565,
    left: 450,
  },
  sigInfo: {
    fontSize: '12px',
    textAlign: 'left',
    paddingLeft: -575,
    color: 'grey',
    left: 10,
  }

})

Font.register({ family: 'handlee', src: '/fonts/Handlee-Regular.ttf' })

const Documents: React.FC<reportsProps> = ({ producerId, brandId, eventId, documents, setRefreshDocuments, producer, brands, events, addDocument }): ReactElement => {

  const [currentDocument, setCurrentDocument] = useState<themis_common.EventDocument.AsObject>({ id: 0, name: "", producerId: producerId, brandId: brandId || 0, eventId: eventId || 0, status: true, text: "", signed: 0 })
  const [sigs, setSigs] = useState<themis_api.EventDocumentDetail.AsObject[]>([])
  const [currentText, setCurrentText] = useState<string>("")
  const [currentIndex, setCurrentIndex] = useState<number>(-1)
  const [showPDF, setShowPdf] = useState<boolean>(false)

  const classes = useStyles()

  const brand = useMemo(() => brandId ? brands.find(brand => brand.id = brandId) : brands[0], [brands, brandId])

  const handleEditorChange = (state: EditorState) => {
    const rteContent = convertToRaw(state.getCurrentContent()) // for rte content with text formatting
    setCurrentDocument((current) => {
      if (current.text === JSON.stringify(rteContent)) return current
      return { ...current, text: JSON.stringify(rteContent) }
    })
  }

  const replaceText = useCallback((html: string): string => {
    let out = html
    const theEvent = events?.find(event => event.id === eventId)
    const venueAddress = theEvent?.address ? `${theEvent.address} ${theEvent.address2}, ${theEvent.city} ${theEvent.state} ${theEvent.postalCode}` :  `1234 Main St, ${producer.city} ${producer.state} ${producer.postalCode}`
    const venueName = theEvent?.venueName ? theEvent.venueName : `${producer.city} Event Center`
    const eventName = theEvent?.name ? theEvent.name : "Some Event"

    const sortedDates = theEvent?.eventDatesList.sort((a, b) => {
      const date1 = a.startDay.split('|')[0]
      const date2 = b.startDay.split('|')[0]
      if (date1 > date2) return 1
      else return -1
    })

    let eventDates
    if (!sortedDates?.length) {
      eventDates = "October X, 2021"
    } else if (sortedDates.length === 1) {
      eventDates = `${sortedDates[0]?.startClockTime?.formattedDateTime?.monthLong} ${sortedDates[0]?.startClockTime?.formattedDateTime?.dayNumber}, ${sortedDates[0]?.startClockTime?.formattedDateTime?.year} `
    } else {
      const lastDate = sortedDates[sortedDates.length-1]
      if (lastDate?.startClockTime?.formattedDateTime?.monthLong === sortedDates[0]?.startClockTime?.formattedDateTime?.monthLong) {
        eventDates = `${lastDate?.startClockTime?.formattedDateTime?.monthLong} ${sortedDates[0]?.startClockTime?.formattedDateTime?.dayNumber}-${lastDate?.startClockTime?.formattedDateTime?.dayNumber}, ${lastDate?.startClockTime?.formattedDateTime?.year}`
      } else {
        eventDates = `${sortedDates[0]?.startClockTime?.formattedDateTime?.monthLong} ${sortedDates[0]?.startClockTime?.formattedDateTime?.dayNumber} - ${lastDate?.startClockTime?.formattedDateTime?.monthLong} ${lastDate?.startClockTime?.formattedDateTime?.dayNumber}, ${lastDate?.startClockTime?.formattedDateTime?.year}`
      }
    }
    out = out.replaceAll("{{ .Athlete }}", "Jane Doe")
    out = out.replaceAll("{{ .Venue }}", venueName)
    out = out.replaceAll("{{ .VenueAddress }}", venueAddress)
    out = out.replaceAll("{{ .Event }}", eventName)
    out = out.replaceAll("{{ .Producer }}", producer.name)
    out = out.replaceAll("{{ .Brand }}", brand?.name || 'Some Brand')
    out = out.replaceAll("{{ .Dates }}", eventDates)
    out = out.replaceAll("{{ .Guardian }}", "Joseph Doe")

    return out
  }, [brand?.name, eventId, events, producer])

  useEffect(() => {
    if (currentDocument.id === -1) return
    const getSigs = async () => {
      if (brandId && eventId && currentDocument?.id > 0) {
        const theResponse = await getSignedEventDocuments(currentDocument, producerId, brandId, eventId)
        if (!theResponse) {
          setSigs([])
        } else {
          setSigs(theResponse.pagesList)
        }
      }
    }
    getSigs()
  }, [brandId, currentDocument, eventId, producerId])

  // Update Document Preview
  useEffect(() => {
    if (currentDocument.text.length > 0) {
      setCurrentText(theCurrentText => {
        const newText = replaceText(draftToHtml(JSON.parse(currentDocument.text)))
        if (newText === theCurrentText) return theCurrentText
        return newText
      })
    } else {
      setCurrentText("")
    }
  }, [currentDocument.text, replaceText])

  useEffect(() => {
    documents.forEach((theDocument, i) => {
      if (theDocument.id === -1) {
        setCurrentIndex(i)
        setCurrentDocument(theDocument)
      }
    })
  }, [documents])

  const saveAndAddDocument = useCallback(() => {
    const doSaveDocument = async () => {
      const newDocument = await addProducerDocument(currentDocument)
      if (newDocument?.id) {
        setCurrentDocument(newDocument)
        await addDocumentToAllFutureEvents(newDocument)
      }
      setRefreshDocuments((current: boolean) => !current )
    }
    doSaveDocument()
  }, [currentDocument, setRefreshDocuments])

  const saveDocument = useCallback((): Promise<void> => {
    const doSaveDocument = async () => {
      if (currentDocument.id > 0) {
        if (currentDocument.eventId) {
          if (brandId && eventId) await saveEventDocument(currentDocument, producerId, brandId, eventId)
        } else {
          await saveProducerDocument(currentDocument)
        }
      } else {
        if (currentDocument.eventId) {
          if (brandId && eventId) {
            const newDocument = await addEventDocument(currentDocument, producerId, brandId, eventId)
            if (newDocument?.id) {
              setCurrentDocument(newDocument)
              // Send this to everyone registered
              await resendDocumentToEvent(currentDocument, producerId, brandId, eventId)
            }
          }
        } else {
          const newDocument = await addProducerDocument(currentDocument)
          if (newDocument?.id) setCurrentDocument(newDocument)
        }
      }
      setRefreshDocuments((current: boolean) => !current )
    }
    return doSaveDocument()
  }, [brandId, currentDocument, eventId, producerId, setRefreshDocuments])

  const doDelete = useCallback(() => {
    const deleteDoc = async () => {
      if (currentDocument.id !== -1) {
        if (currentDocument.eventId) {
          if (brandId && eventId) await deleteEventDocument(currentDocument, producerId, brandId, eventId)
        } else {
          await deleteProducerDocument(currentDocument)
        }
      }
      setRefreshDocuments((current: boolean) => !current)
      setCurrentDocument({ brandId: 0, eventId: 0, name: "", producerId: 0, status: false, text: "", id: 0, signed: 0 })
    }
    deleteDoc()
  }, [brandId, currentDocument, eventId, producerId, setRefreshDocuments])

  const documentsList = useMemo(() => {
    return documents.map((theDocument, i) => <Grid item xs={12} key={`documentButton-${theDocument.id}`}>
      <Button
        fullWidth
        endIcon={ theDocument.status ? undefined : <DisabledIcon /> }
        variant="outlined" color={currentDocument?.id === theDocument.id ? "inherit" : undefined}
        onClick={(_) => {
          setCurrentIndex(i)
          setCurrentDocument(theDocument)
          setCurrentText(replaceText(draftToHtml(JSON.parse(theDocument.text))))
          setShowPdf(false)
        }}
      >{theDocument.name}</Button></Grid> )
  }, [currentDocument?.id, documents, replaceText])

  const preview = useMemo(() => <Grid item xs={12} style={{ backgroundColor: "white", color: "black", height: "30VH", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: currentText }} />, [currentText])

  const addText = useCallback((editorState, name) => {
    let text = ""
    switch (name) {
      case 'add-athlete':
        text = "{{ .Athlete }}"
        break
      case 'add-venue':
        text = "{{ .Venue }}"
        break
      case 'add-venue-address':
        text = "{{ .VenueAddress }}"
        break
      case 'add-event':
        text = "{{ .Event }}"
        break
      case 'add-producer':
        text = "{{ .Producer }}"
        break
      case 'add-brand':
        text = "{{ .Brand }}"
        break
      case 'add-dates':
        text = "{{ .Dates }}"
        break
      case 'add-guardian':
        text = "{{ .Guardian }}"
        break
    }
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection()
    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    )

    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters')
    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())

  }, [])

  const PDFDocument = useCallback(() => (
    <Document>
      { sigs.map(sig => {
        return <Page size="LETTER" style={styles.page}>
          <View style={{ width: 10000, padding: 10, margin: 10 }}>
            <Html>{ `<html>
              <body>
                <style>
                  p {
                    padding: 10px 10px 0 10px;
                    margin: 0;
                  }
                </style>
              ${sig.pageInfo}
              </body>
            </html>` }</Html>
          </View>
          <View>
            <Text style={styles.sigline}>Signature: ___________________________________Date: _____________</Text>
            <Text style={styles.sig}>{sig.signature}</Text>
            <Text style={styles.sigDate}>09/01/2021</Text>
            <Text style={styles.sigInfo}>IP Address: {sig.ipAddress}</Text>
          </View>
        </Page>})
      }
    </Document>
  ), [currentText, sigs])  // Do not change these no matter what the linter says

  const editor = useMemo(() => {
    return currentIndex === -1 ? [] :
      <MuiThemeProvider theme={defaultTheme}>
        <MUIRichTextEditor
          label="Create or paste permission document here..."
          defaultValue={currentDocument.text}
          onChange={ handleEditorChange }
          controls={ ["title", "bold", "italic", "underline", "link", "numberList", "bulletList", "quote", "clear", "add-athlete", "add-venue", "add-venue-address", "add-event", "add-producer", "add-brand", "add-dates", "add-guardian"] }
          customControls={ [
            { name: "add-athlete", type: "callback", icon: <Typography variant="h6">Athlete Name</Typography>, onClick: addText },
            { name: "add-venue", type: "callback", icon: <Typography variant="h6">Venue Name</Typography>, onClick: addText },
            { name: "add-venue-address", type: "callback", icon: <Typography variant="h6">Venue Address</Typography>, onClick: addText },
            { name: "add-event", type: "callback", icon: <Typography variant="h6">Event Name</Typography>, onClick: addText },
            { name: "add-producer", type: "callback", icon: <Typography variant="h6">Producer Name</Typography>, onClick: addText },
            { name: "add-brand", type: "callback", icon: <Typography variant="h6">Brand Name</Typography>, onClick: addText },
            { name: "add-dates", type: "callback", icon: <Typography variant="h6">Event Dates</Typography>, onClick: addText },
            { name: "add-guardian", type: "callback", icon: <Typography variant="h6">Guardian Name</Typography>, onClick: addText },
          ] } />
      </MuiThemeProvider>
  }, [currentIndex]) // Do not add the dependencies that the editor wants to here!  Will break if you do.

  return <Container style={{ maxWidth: "none" }}>
    <Grid container spacing={2}>
      <Grid container item xs={12} alignItems="flex-start">
        <Typography variant="h2">Documents</Typography>
      </Grid>
      <Grid container item xs={4} md={2} alignItems="flex-start" alignContent="flex-start" spacing={2}>
        {documentsList}
        <Grid item xs={12} key="documentButton-ADD" >
          <Button fullWidth variant="outlined" color={currentDocument?.id === -1 ? "inherit" : undefined} startIcon={<AddIcon />} onClick={(_) => {
            addDocument()
          }}>Add New Document</Button>
        </Grid>
      </Grid>
      { currentDocument.id === 0 ? [] :
        <Grid container item xs={8} md={8} alignContent="flex-start">
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <Grid container spacing={2} >
                <Grid item xs={8} md={4}>
                  <TextField disabled={currentDocument.signed > 0} fullWidth label="Document Name" value={currentDocument.name} onChange={(e) => {
                    if (e?.target) setCurrentDocument((current) => { return { ...current, name: e.target.value }})
                  }}/>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormControlLabel  disabled={currentDocument.signed > 0}
                    style={{ paddingTop: 12, marginRight: 12 }}
                    control={<Switch  disabled={currentDocument.signed > 0} checked={currentDocument.status} onChange={(e) => {
                      if (e?.target) setCurrentDocument((current) => { return { ...current, status: e.target.checked }})
                    }} color="primary" name="Enabled" />}
                    label="Enabled"
                  />
                  <IconButton disabled={currentDocument.signed > 0} onClick={_ => {
                    if (!currentDocument.signed) doDelete()
                  }} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                {currentDocument.id === -1 && !currentDocument.eventId && currentDocument.status ?
                  <Grid item container spacing={1} xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <SubmitButton ariaLabel="Save" submitForm={saveDocument} title="Save"/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={saveAndAddDocument}
                      >Save And Add To All Events</Button>
                    </Grid>
                  </Grid>
                  :
                  <Grid item xs={12} md={6}>
                    <SubmitButton disabled={currentDocument.signed > 0} ariaLabel="Save" submitForm={saveDocument} title="Save"/>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={2}>
                { sigs.length > 0  ?
                  <Grid item xs={12} md={6}>
                    <Typography style={{ marginBottom: 40 }}>This document has been signed and can not be changed.</Typography>
                  </Grid>  :
                  <Grid item xs={12}>
                    {editor}
                  </Grid>
                }
                { sigs.length > 0 ? <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<PdfIcon />}
                    onClick={(_) => {
                      setShowPdf(true)
                    }}
                  >Generate PDF of Signed Documents ({sigs.length})</Button>
                </Grid> : [] }
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12}><Typography variant="h2">Preview</Typography></Grid>
          { showPDF ? <PDFViewer width="100%" height={500} >
            <PDFDocument />
          </PDFViewer> : preview }
        </Grid>
      }
    </Grid>
  </Container>
}

export default Documents
