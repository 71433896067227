import {
  SET_BRAND,
  SET_BRANDS,
  SET_EVENT,
  SET_EVENTS,
  SET_PRODUCER,
  SET_EVENT_DATES,
  SET_EVENT_REG_CODES,
  SET_EVENT_FLOORS,
  SET_JUDGES,
  RESET_PRODUCER,
  SET_PRODUCER_LINKS, SET_EVENT_PRICING, SET_EVENT_DIVISION_PRICING, SET_EVENT_FLOOR_PANELS
} from "lib/constants"
import themis_common from "store/themis_common_pb"

export const emptyProducer: themis_common.Producer.AsObject = {
  id: 0,
  name: '',
  address: '',
  address2: '',
  postalCode: '',
  country: '',
  state: '',
  city: '',
  website: '',
  email: '',
  phone: '',
  logo: '',
  urlsList: [],
  allowedProgramsList: [],
  brandsList: [],
  accountEmail: "",
  stripeAccount: "",
  stripeAccountActive: false,
  judgesList:[],
}

export const emptyBrand: themis_common.Brand.AsObject = {
  id: 0,
  address: "",
  address2: "",
  city: "",
  country: "",
  email: "",
  logo: "",
  name: "",
  phone: "",
  postalCode: "",
  state: "",
  website: "",
  currency: "",
  producerId: 0,
  eventsList: [],
  judgesList: [],
  guardianRequired: true,
}

export const emptyEventPricing = new themis_common.EventPricing().toObject()

export const emptyEventDivision: themis_common.EventDivision.AsObject = {
  id: 0,
  division: undefined,
  divisionSplit: undefined,
  event: undefined,
  eventTeamsList: [],
  divisionsList: [],
  paid: 0,
  remainingAmount: 0,
  remainingDeposit: 0,
  sortOrder: 0,
  nonCrossover: false,
  rubricId: 0,
  name: "",
  eventShiftsList: [],
  newdescription: ""
}

export const emptyEvent: themis_common.Event.AsObject = {
  eventDivisionList: [],
  eventDocumentsList: [],
  venueName: "",
  eventPricing: undefined,
  registrationLive: false,
  seasonsList: [],
  address: "",
  address2: "",
  brand: emptyBrand,
  brandId: 0,
  city: "",
  country: "",
  currency: "",
  email: "",
  eventDatesList: [],
  eventFloorsList: [],
  id: 0,
  info: "",
  lat: 0,
  lng: 0,
  logo: "",
  name: "",
  phone: "",
  postalCode: "",
  producerId: 0,
  firstDay: "",
  registrationCodesList: [],
  registrationPrice: 0,
  state: "",
  teamsList: [],
  timeZone: "",
  website: "",
  warmupTime: 0,
  rotationTime: 0,
  notifyTeamScheduleUpdates: false,
  finalTabulation: "",
  daysPercentageJson: "",
  scoreDisplay: "",
}

export const emptySeason: themis_common.Season.AsObject = {
  divisionsList: [],
  country: "",
  endDate: "",
  id: 0,
  name: "",
  scoringAuthority: undefined,
  startDate: "",
  isExclusive: false,
  exclusiveProducerId: 0
}

export const emptyDivision: themis_common.Division.AsObject = {
  ageCategoryDescriptor: "",
  competitionTypeDescriptor: "",
  danceCategoryDescriptor: "",
  divisionDescriptor: "",
  divisionSplitsList: [],
  id: 0,
  isDance: false,
  lastScrape: "",
  levelDescriptor: "",
  name: "",
  usasfDivisionId: ""
}

export const emptyPerson: themis_common.Person.AsObject = {
  address1: "",
  address2: "",
  birthDate: "",
  cellPhone: "",
  city: "",
  country: "",
  email: "",
  gender: 0,
  hasBirthCertificate: false,
  homePhone: "",
  id: 0,
  isInternal: false,
  isUsasfGuardian: false,
  lastScrape: "",
  legalFirstName: "",
  legalLastName: "",
  legalMiddleName: "",
  name: "",
  nickName: "",
  postalCode: "",
  preferredFirstName: "",
  state: "",
  suffix: "",
  title: "",
  usasfPersonId: "",
  usasfProfilePicture: "",
  isBirthdayEdited: false,
}

export interface EventDate {
  id: number;
  start: string;
  end: string;
}

export interface IProducerState {
  currentProducer: themis_common.Producer.AsObject
  currentBrand: themis_common.Brand.AsObject
  currentEvent: themis_common.Event.AsObject
}

export const initialProducerState: IProducerState = {
  currentProducer: emptyProducer,
  currentBrand: emptyBrand,
  currentEvent: emptyEvent,
}

interface ProducerAction {
  type: string
  payload: any
}

export function producerReducer(state: IProducerState = initialProducerState, action: ProducerAction): IProducerState {
  switch (action.type) {
    case RESET_PRODUCER: {
      return initialProducerState
    }
    case SET_PRODUCER: {
      const producer = action.payload ? action.payload : emptyProducer
      return {
        ...state,
        currentProducer: producer,
      }
    }
    case SET_BRANDS: {
      return {
        ...state,
        currentBrand: emptyBrand,
        currentProducer: { ...state.currentProducer, brandsList: action.payload },
      }
    }
    case SET_BRAND: {
      return {
        ...state,
        currentBrand: action.payload,
      }
    }
    case SET_EVENT: {
      const eventId = action.payload
      const foundEvent = state.currentBrand.eventsList.find((event: themis_common.Event.AsObject) => event.id === eventId)
      const event = foundEvent ? foundEvent : emptyEvent
      return {
        ...state,
        currentEvent: event,
      }
    }
    case SET_EVENTS: {
      const brandListIndex = state.currentProducer.brandsList.findIndex((brand) => brand.id === state.currentBrand.id)
      return {
        ...state,
        currentProducer: {
          ...state.currentProducer,
          brandsList: Object.assign([], state.currentProducer.brandsList, {
            [brandListIndex]: { ...state.currentProducer.brandsList[brandListIndex], eventsList: action.payload }
          }),
        },
        currentBrand: { ...state.currentBrand, eventsList: action.payload },
        currentEvent: emptyEvent,
      }
    }
    case SET_EVENT_DATES: {
      return {
        ...state,
        currentEvent: { ...state.currentEvent, eventDatesList: action.payload }
      }
    }
    case SET_EVENT_FLOORS: {
      return {
        ...state,
        currentEvent: { ...state.currentEvent, eventFloorsList: action.payload }
      }
    }
    case SET_EVENT_FLOOR_PANELS: {
      let floorDayIndex = 0
      const floorListIndex = state.currentEvent.eventFloorsList.findIndex(floor =>
        floor.daysList.find((floorDay, i) => {
          if (floorDay.id === action.payload.eventFloorDateId) {
            floorDayIndex = i
            return true
          }
          return false
        })
      )
      return {
        ...state,
        currentEvent: { ...state.currentEvent, eventFloorsList: state.currentEvent.eventFloorsList.map((floor, i) => {
          if (i === floorListIndex ) {
            return { ...floor, daysList: floor.daysList.map((day, j) => {
              if (j === floorDayIndex) {
                return { ...day, judgingPanelsList: action.payload.panels }
              }
              return day
            })
            }
          }
          return floor
        })
        }
      }
    }
    case SET_EVENT_REG_CODES: {
      return {
        ...state,
        currentEvent: { ...state.currentEvent, registrationCodesList: action.payload }
      }
    }
    case SET_PRODUCER_LINKS: {
      return {
        ...state,
        currentProducer: { ...state.currentProducer, urlsList: action.payload },
      }
    }
    case SET_JUDGES: {
      return {
        ...state,
        currentProducer: { ...state.currentProducer, judgesList: action.payload }
      }
    }
    case SET_EVENT_PRICING: {
      return {
        ...state,
        currentEvent: { ...state.currentEvent, eventPricing: {
          id: state.currentEvent.eventPricing?.id || 0,
          athletePriceInt: Number(action.payload.athletePrice),
          athletePriceString: action.payload.athletePrice,
          crossoverPriceInt: Number(action.payload.crossoverPrice),
          crossoverPriceString: action.payload.crossoverPrice } }
      }
    }
    case SET_EVENT_DIVISION_PRICING: {
      const divisionIndex = state.currentEvent.eventDivisionList.findIndex(division => division.id === action.payload.eventDivisionId)
      const newEventDivision = {
        ...state.currentEvent.eventDivisionList[divisionIndex],
        eventDivisionPricing: {
          id: state.currentEvent.eventDivisionList[divisionIndex].eventDivisionPricing?.id || 0,
          athletePriceInt: Number(action.payload.athletePrice),
          athletePriceString: action.payload.athletePrice,
          teamPriceInt: Number(action.payload.teamPrice),
          teamPriceString: action.payload.teamPrice
        }
      }

      return {
        ...state,
        currentEvent: {
          ...state.currentEvent,
          eventDivisionList: state.currentEvent.eventDivisionList.map((eventDivision, i) => i === divisionIndex ? newEventDivision : eventDivision)
        }
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}
