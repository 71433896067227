import JudgeType from "./judgeType";
import themis_common from "store/themis_common_pb";

interface IJudgeTypeProps {
  producer: themis_common.Producer.AsObject;
}
const JudgeTypeContainer = ({ producer }: IJudgeTypeProps) => {
  return <JudgeType producer={producer} />;
};

export default JudgeTypeContainer;
