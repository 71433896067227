import React, { ReactElement, useMemo, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Container, Grid, Hidden, IconButton,
  Paper, TextField, Typography
} from '@material-ui/core'
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Remove as RemoveIcon } from '@material-ui/icons'
import { useStyles } from "lib/theme"
import themis_common from "store/themis_common_pb"
import { Link as RouterLink } from "react-router-dom"
import YesNoDialog from 'components/util/YesNoModal'
import { validateBrandPermission, validateProducerPermission } from 'lib/permissions'
import { ProducerPermissions } from 'store/user/userReducer'

interface JudgeGridProps {
  producerId: number,
  brandId?: number
  judges?: themis_common.Judge.AsObject[] | undefined,
  superUser: boolean,
  permissionCodes: themis_common.PermissionCodes.AsObject
  producerJudges?: themis_common.Judge.AsObject[],
  producerPermissions?: ProducerPermissions,
  handleDeleteJudge?: (judgeId: number) => void,
  handleAddJudgeToBrand?: (judgeId: number) => void
  handleAddJudgeDropdown?: (judgeId: number) => void
  currentBrand?: themis_common.Brand.AsObject
  currentProducer?: themis_common.Producer.AsObject,
}

const JudgeGrid: React.FC<JudgeGridProps> = ({
  judges,
  producerId,
  brandId,
  producerJudges,
  superUser,
  producerPermissions,
  handleDeleteJudge,
  handleAddJudgeToBrand,
  handleAddJudgeDropdown,
  permissionCodes,
  currentBrand,
  currentProducer
}): ReactElement => {

  const classes = useStyles()

  const [judgeToDelete, setJudgeToDelete] = useState<themis_common.Judge.AsObject | null>(null)
  const [rerenderAutocomplete, setRerenderAutoComplete] = useState(false)

  const availableJudges = useMemo(() => {
    if (!producerJudges || !judges) return
    return producerJudges.filter((pJudge: themis_common.Judge.AsObject) => !judges.find((judge: themis_common.Judge.AsObject) => judge.id === pJudge.id))
  }, [judges, producerJudges])

  const gridHeader = useMemo(() => {
    return (
      <Grid container spacing={3} className={classes.personGridRow}>
        <Hidden only={['xs']}>
          <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
            <Paper className={classes.paperGridItem}>&nbsp;</Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={4} md={4} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>Name</Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>City</Paper>
        </Grid>
        <Hidden only={['xs', 'md', 'lg', 'xl']}>
          <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
            <Paper className={classes.paperGridItem}>&nbsp;</Paper>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={4} md={3} className={classes.personGridHeader}>
          <Paper className={classes.paperGridItem}>Country</Paper>
        </Grid>
      </Grid>
    )
  }, [classes.paperGridItem, classes.personGridHeader, classes.personGridRow])

  const addJudgeIcon = useMemo(() => {
    if (
      (brandId && producerPermissions && validateBrandPermission(permissionCodes.judgeAdd, permissionCodes, superUser, producerPermissions, producerId, brandId) && validateProducerPermission(permissionCodes.judgeAdd, permissionCodes, superUser, producerPermissions, producerId))
      ||
      (!brandId && producerPermissions && validateProducerPermission(permissionCodes.judgeAdd, permissionCodes, superUser, producerPermissions, producerId))
    ) {
      let addJudgeLink = `/Producer/${producerId}/AddJudge`
      if (brandId && brandId > 0) {
        addJudgeLink = `/Brand/${brandId}/AddJudge`
      }
      return (
        <IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={addJudgeLink}>
          <AddIcon fontSize="small" />
        </IconButton>
      )
    } else {
      return []
    }
  }, [classes.clickable, producerId, producerPermissions, brandId, superUser, permissionCodes])

  const sortedJudges = useMemo(() => {
    if (!judges) return []
    const sortJudges = [...judges]
    sortJudges.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    return sortJudges
  }, [judges])

  const [isOpen, setIsOpen] = useState(false)
  const handleDialogOpen = () => {
    setIsOpen(true)
  }
  const handleDialogClose = () => {
    setIsOpen(false)
  }

  function handleDelete() {
    if (!judgeToDelete?.id || !handleDeleteJudge) return
    handleDeleteJudge(judgeToDelete.id)
    handleDialogClose()
  }

  const userCanViewJudges = useMemo(() => {
    if (!brandId && producerPermissions) return validateProducerPermission(permissionCodes.judgeView, permissionCodes, superUser, producerPermissions, producerId)
    if (brandId && producerPermissions) return validateBrandPermission(permissionCodes.judgeView, permissionCodes, superUser, producerPermissions, producerId, brandId)
  }, [superUser, producerPermissions, producerId, brandId, permissionCodes])

  const removeName = useMemo(() => currentBrand?.name ? currentBrand.name : currentProducer?.name, [currentProducer, currentBrand])
  const deleteJudgeTitle = useMemo(() => currentProducer ? `Remove ${judgeToDelete?.name} entirely from ${removeName}?` : `Delete ${judgeToDelete?.name}?`, [currentProducer, removeName, judgeToDelete?.name])
  const deleteJudgeQuestion = useMemo(() => currentProducer ? `This cannot be undone. Are you sure?`: `Are you sure you want to remove ${judgeToDelete?.name} from ${removeName}?`, [currentProducer, removeName, judgeToDelete?.name])

  const theGrid = useMemo(() => {
    if (!sortedJudges) return (<></>)
    return userCanViewJudges ? (
      <Container component={Paper}>
        {gridHeader}
        {sortedJudges.map((judge) => {
          const judgeLink = brandId ? `/Brand/${brandId}/Judge/${judge.id}` : `/Producer/${producerId}/Judge/${judge.id}`
          const editJudgeLink = brandId ? `/Brand/${brandId}/EditJudge/${judge?.id}` : `/Producer/${producerId}/EditJudge/${judge?.id}`
          return (
            <Grid container spacing={2} className={classes.personGridRow} key={judge.id}>
              <Grid item xs={12} sm={4} md={2} className={classes.personGridItem}>
                <Paper style={{ boxShadow: "none", textAlign: "center" }}>
                  {
                    (!brandId && producerPermissions && validateProducerPermission(permissionCodes.judgeEdit, permissionCodes, superUser, producerPermissions, producerId)) ||
                    (brandId && producerPermissions && validateBrandPermission(permissionCodes.judgeEdit, permissionCodes, superUser, producerPermissions, producerId, brandId))
                      ?
                      (<IconButton aria-label="edit" component={RouterLink}
                        to={editJudgeLink}
                      >
                        <EditIcon className={classes.personGridButton} />
                      </IconButton>)
                      : []
                  }
                  {
                    (!brandId && producerPermissions && validateProducerPermission(permissionCodes.judgeDelete, permissionCodes, superUser, producerPermissions, producerId)) ||
                    (brandId && producerPermissions && validateBrandPermission(permissionCodes.judgeDelete, permissionCodes, superUser, producerPermissions, producerId, brandId))
                      ?
                      (<IconButton  aria-label="delete" onClick={e => { setJudgeToDelete(judge); handleDialogOpen() }}>
                        {currentProducer ?
                          <DeleteIcon className={classes.personGridButton}/> : <RemoveIcon className={classes.personGridButton}/>
                        }
                      </IconButton>)
                      : []
                  }
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.personGridItem}>
                { judge.person?.usasfProfilePicture ?
                  (<Grid container style={{ justifyContent: "center" }}>
                    <Grid item md={8}>
                      <Paper className={classes.paperGridItem}>
                        <RouterLink to={judgeLink} className={classes.cleanLink}>
                          {judge.name}{judge.person.nickName ? ' (' + judge.person.nickName + ')' : ''}
                        </RouterLink>
                      </Paper>
                    </Grid>
                  </Grid>
                  ) :
                  <Paper className={classes.paperGridItem}>
                    <RouterLink to={judgeLink} className={classes.cleanLink}>
                      {judge.name}{judge.person?.nickName ? ' (' + judge.person.nickName + ')' : ''}
                    </RouterLink>
                  </Paper>
                }
              </Grid>
              <Grid item xs={12} sm={4} md={3} className={classes.personGridItem}>
                <Paper className={classes.paperGridItem}>
                  {judge.person?.city}
                </Paper>
              </Grid>
              <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <Grid item xs={12} sm={4} md={2} className={classes.personGridHeader}>
                  <Paper className={classes.paperGridItem}>&nbsp;</Paper>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={4} md={3} className={classes.personGridItem}>
                <Paper className={classes.paperGridItem}>
                  {judge.person?.country}
                </Paper>
              </Grid>
            </Grid>
          )
        })}
      </Container>
    ) : <></>
  }, [gridHeader, classes, producerId, sortedJudges, producerPermissions, brandId, superUser, userCanViewJudges, permissionCodes, currentProducer])

  return (
    <Container>
      <Grid container>
        <Typography style={{ marginRight: "25px" }} variant="h2">
          Judges
        </Typography>
        {(!availableJudges || availableJudges.length === 0) ?
          <></>
          : (
            <Autocomplete
              key={rerenderAutocomplete ? '1' : '0'}
              id="Add_New_Judge"
              size="small"
              options={availableJudges}
              getOptionLabel={(judge: themis_common.Judge.AsObject) => (judge.name || 'No Name Found') + ' ' + (judge.person?.nickName ? ' (' + judge.person?.nickName + ')' : '')}
              style={{ width: 300, marginBottom: "15px" }}
              renderInput={(params) => <TextField {...params} label="Add judge" variant="outlined" />}
              onChange={(e, v) => {
                if (handleAddJudgeDropdown) {
                  v && handleAddJudgeDropdown(v?.id)
                  setRerenderAutoComplete(!rerenderAutocomplete)
                }
              }}
              hidden={!(producerPermissions &&
                validateProducerPermission(permissionCodes.judgeView, permissionCodes, superUser, producerPermissions, producerId) &&
                validateBrandPermission(permissionCodes.judgeAdd, permissionCodes, superUser, producerPermissions, producerId, brandId || 0)
              )}
            />)
        }
        {addJudgeIcon}
        {theGrid}
      </Grid>
      <YesNoDialog
        title={deleteJudgeTitle}
        question={deleteJudgeQuestion}
        isOpen={isOpen}
        onClose={handleDialogClose}
        buttonActions={[
          { name: "No", color: "primary", callback: handleDialogClose },
          { name: "Yes", color: "primary", callback: handleDelete },
        ]}
      />
    </Container>)
}

export default JudgeGrid
