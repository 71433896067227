import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { Transition, useStyles } from "lib/theme";
import {
  addProducerJudgeType,
  deleteProducerJudgeType,
  getProducerJudgeTypeByProducerID,
  updateProducerJudgeType,
} from "store/producer/producerActions";
import { Producer, ProducerJudgeType } from "store/themis_common_pb";

interface JudgeTypeProps {
  producer: Producer.AsObject;
}

const ActionType = {
  ADD: "Add",
  EDIT: "Edit",
};

const ErrorMessage = {
  JUDGE_TYPE_EMPTY: "Judge type cannot be empty",
  LOADING: "Loading...",
};

const JudgeType = ({ producer }: JudgeTypeProps) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [actionType, setActionType] = useState<string>(ActionType.ADD);
  const [judgeType, setJudgeType] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [judgeTypeList, setJudgeTypeList] =
    useState<ProducerJudgeType.AsObject[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateType, setUpdateType] = useState<ProducerJudgeType.AsObject>();
  const [deleteType, setDeleteType] = useState<ProducerJudgeType.AsObject>();

  useEffect(() => {
    setIsLoading(true);
    const fetchProducerJudgeTypes = async () => {
      try {
        const response = await getProducerJudgeTypeByProducerID(producer.id);
        setJudgeTypeList(response.producerJudgeTypesList);
      } catch (error) {
        console.error("Error fetching producer judge types:", error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    };
    if (producer && producer.id !== undefined) {
      fetchProducerJudgeTypes();
    }
  }, [producer]);

  const handleOpenDialog = (type: string) => {
    setActionType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setError(false);
    setJudgeType("");
    setDeleteType(undefined); // Reset delete confirmation state
  };

  const handleChangeJudgeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setJudgeType(value);
    setError(value.trim() === "");
  };

  const handleDeleteJudgeType = async (
    judgeType: ProducerJudgeType.AsObject
  ) => {
    setDeleteType(judgeType);
  };

  const confirmDeleteJudgeType = async () => {
    if (deleteType && deleteType.id && producer && producer.id !== undefined) {
      try {
        await deleteProducerJudgeType({
          id: deleteType.id,
          producerId: producer.id,
        });
        const response = await getProducerJudgeTypeByProducerID(producer.id);
        setJudgeTypeList(response.producerJudgeTypesList);
        setDeleteType(undefined);
      } catch (error) {
        console.error("Error deleting judge type:", error);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (producer && producer.id) {
      try {
        const producerId = producer.id;
        if (actionType === ActionType.ADD) {
          await addJudgeType(producerId);
        } else {
          await updateJudgeType(producerId);
        }
      } catch (error) {
        console.error(
          `Error ${
            actionType === ActionType.ADD ? "adding" : "updating"
          } judge type:`,
          error
        );
      }
    }
  };

  const addJudgeType = async (producerId: number) => {
    const payload = { judgeType, producer };
    await addProducerJudgeType(payload);
    refreshJudgeTypeList(producerId);
  };

  const updateJudgeType = async (producerId: number) => {
    if (updateType && updateType.id) {
      const findJudgeType = judgeTypeList?.find(
        ({ id }) => id === updateType.id
      );
      const id = findJudgeType !== undefined ? findJudgeType.id : 0;
      const payload = { id, name: judgeType, producerId };
      await updateProducerJudgeType(payload);
      refreshJudgeTypeList(producerId);
    }
  };

  const refreshJudgeTypeList = async (producerId: number) => {
    const response = await getProducerJudgeTypeByProducerID(producerId);
    setJudgeTypeList(response.producerJudgeTypesList);
    setJudgeType("");
    handleCloseDialog();
  };

  const renderJudgeTypes = () =>
    judgeTypeList?.map((info) => (
      <Grid container key={info.id}>
        <Grid item xs={3} style={{ marginTop: "20px" }}>
          <Typography variant="body1">{info.name ?? ""}</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              handleOpenDialog(ActionType.EDIT);
              setJudgeType(info.name);
              setUpdateType(info);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => handleDeleteJudgeType(info)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    ));

  const hasDisable =
    error ||
    (actionType === ActionType.EDIT &&
      updateType &&
      updateType.name === judgeType);

  return (
    <React.Fragment>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {actionType === ActionType.ADD ? "Add" : "Edit"} Judge Type
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              id="judge_type"
              label="Type"
              style={{ width: "400px" }}
              type="string"
              required
              value={judgeType}
              variant="outlined"
              error={error}
              onChange={handleChangeJudgeType}
            />
            <Typography
              variant="body2"
              style={{ color: "red", marginLeft: "2px" }}
            >
              {error ? ErrorMessage.JUDGE_TYPE_EMPTY + "!" : ""}
            </Typography>
            <DialogActions>
              <Button color="primary" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button color="primary" type="submit" disabled={hasDisable}>
                {actionType === ActionType.ADD ? "Add" : "Edit"} Judge Type
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      {deleteType && (
        <Dialog
          open={!!deleteType}
          TransitionComponent={Transition}
          onClose={() => setDeleteType(undefined)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to delete judge type{" "}
              <Typography style={{ fontWeight: "bold" }}>
                {deleteType.name ? `${deleteType.name}` : ""}?
              </Typography>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteType(undefined)} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDeleteJudgeType} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isLoading || judgeTypeList === undefined ? (
        <Typography variant="h2">{ErrorMessage.LOADING}</Typography>
      ) : (
        <>
          <Grid style={{ display: "flex" }}>
            <Typography variant="h2" style={{ marginTop: "15px" }}>
              Judge Type
            </Typography>
            <IconButton
              className={classes.clickable}
              aria-label="add"
              style={{ marginTop: "10px" }}
              onClick={() => handleOpenDialog(ActionType.ADD)}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Grid>
          {renderJudgeTypes()}
        </>
      )}
    </React.Fragment>
  );
};

export default JudgeType;
